@import "../../libs/bootstrap.css";
@import "../../libs/materialadmin.css";
/*!
 * Font Awesome Pro 5.3.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@import url('/assets/fonts/lato/lato.css');
@import '../partials/~font-awesome/css/all.css';
@import '../modern/~font-awesome/css/all.css';
.fa,
.fas,
.far,
.fal,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
/* makes the font 33% larger relative to the icon container */
.fa-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}
.fa-xs {
  font-size: 0.75em;
}
.fa-sm {
  font-size: 0.875em;
}
.fa-1x {
  font-size: 1em;
}
.fa-2x {
  font-size: 2em;
}
.fa-3x {
  font-size: 3em;
}
.fa-4x {
  font-size: 4em;
}
.fa-5x {
  font-size: 5em;
}
.fa-6x {
  font-size: 6em;
}
.fa-7x {
  font-size: 7em;
}
.fa-8x {
  font-size: 8em;
}
.fa-9x {
  font-size: 9em;
}
.fa-10x {
  font-size: 10em;
}
.fa-fw {
  text-align: center;
  width: 1.25em;
}
.fa-ul {
  list-style-type: none;
  margin-left: 10em/4;
  padding-left: 0;
}
.fa-ul > li {
  position: relative;
}
.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}
.fa-border {
  border-radius: 0.1em;
  border: solid 0.08em #eee;
  padding: 0.2em 0.25em 0.15em;
}
.fa-pull-left {
  float: left;
}
.fa-pull-right {
  float: right;
}
.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: 0.3em;
}
.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: 0.3em;
}
.fa-spin {
  animation: fa-spin 2s infinite linear;
}
.fa-pulse {
  animation: fa-spin 1s infinite steps(8);
}
@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg);
}
.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg);
}
.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg);
}
.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scale(-1, 1);
}
.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(1, -1);
}
.fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(-1, -1);
}
:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
  filter: none;
}
.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2em;
}
.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}
.fa-stack-1x {
  line-height: inherit;
}
.fa-stack-2x {
  font-size: 2em;
}
.fa-inverse {
  color: #fff;
}
/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */
.fa-500px:before {
  content: "\f26e";
}
.fa-abacus:before {
  content: "\f640";
}
.fa-accessible-icon:before {
  content: "\f368";
}
.fa-accusoft:before {
  content: "\f369";
}
.fa-ad:before {
  content: "\f641";
}
.fa-address-book:before {
  content: "\f2b9";
}
.fa-address-card:before {
  content: "\f2bb";
}
.fa-adjust:before {
  content: "\f042";
}
.fa-adn:before {
  content: "\f170";
}
.fa-adversal:before {
  content: "\f36a";
}
.fa-affiliatetheme:before {
  content: "\f36b";
}
.fa-air-freshener:before {
  content: "\f5d0";
}
.fa-alarm-clock:before {
  content: "\f34e";
}
.fa-algolia:before {
  content: "\f36c";
}
.fa-align-center:before {
  content: "\f037";
}
.fa-align-justify:before {
  content: "\f039";
}
.fa-align-left:before {
  content: "\f036";
}
.fa-align-right:before {
  content: "\f038";
}
.fa-alipay:before {
  content: "\f642";
}
.fa-allergies:before {
  content: "\f461";
}
.fa-amazon:before {
  content: "\f270";
}
.fa-amazon-pay:before {
  content: "\f42c";
}
.fa-ambulance:before {
  content: "\f0f9";
}
.fa-american-sign-language-interpreting:before {
  content: "\f2a3";
}
.fa-amilia:before {
  content: "\f36d";
}
.fa-analytics:before {
  content: "\f643";
}
.fa-anchor:before {
  content: "\f13d";
}
.fa-android:before {
  content: "\f17b";
}
.fa-angellist:before {
  content: "\f209";
}
.fa-angle-double-down:before {
  content: "\f103";
}
.fa-angle-double-left:before {
  content: "\f100";
}
.fa-angle-double-right:before {
  content: "\f101";
}
.fa-angle-double-up:before {
  content: "\f102";
}
.fa-angle-down:before {
  content: "\f107";
}
.fa-angle-left:before {
  content: "\f104";
}
.fa-angle-right:before {
  content: "\f105";
}
.fa-angle-up:before {
  content: "\f106";
}
.fa-angry:before {
  content: "\f556";
}
.fa-angrycreative:before {
  content: "\f36e";
}
.fa-angular:before {
  content: "\f420";
}
.fa-ankh:before {
  content: "\f644";
}
.fa-app-store:before {
  content: "\f36f";
}
.fa-app-store-ios:before {
  content: "\f370";
}
.fa-apper:before {
  content: "\f371";
}
.fa-apple:before {
  content: "\f179";
}
.fa-apple-alt:before {
  content: "\f5d1";
}
.fa-apple-pay:before {
  content: "\f415";
}
.fa-archive:before {
  content: "\f187";
}
.fa-archway:before {
  content: "\f557";
}
.fa-arrow-alt-circle-down:before {
  content: "\f358";
}
.fa-arrow-alt-circle-left:before {
  content: "\f359";
}
.fa-arrow-alt-circle-right:before {
  content: "\f35a";
}
.fa-arrow-alt-circle-up:before {
  content: "\f35b";
}
.fa-arrow-alt-down:before {
  content: "\f354";
}
.fa-arrow-alt-from-bottom:before {
  content: "\f346";
}
.fa-arrow-alt-from-left:before {
  content: "\f347";
}
.fa-arrow-alt-from-right:before {
  content: "\f348";
}
.fa-arrow-alt-from-top:before {
  content: "\f349";
}
.fa-arrow-alt-left:before {
  content: "\f355";
}
.fa-arrow-alt-right:before {
  content: "\f356";
}
.fa-arrow-alt-square-down:before {
  content: "\f350";
}
.fa-arrow-alt-square-left:before {
  content: "\f351";
}
.fa-arrow-alt-square-right:before {
  content: "\f352";
}
.fa-arrow-alt-square-up:before {
  content: "\f353";
}
.fa-arrow-alt-to-bottom:before {
  content: "\f34a";
}
.fa-arrow-alt-to-left:before {
  content: "\f34b";
}
.fa-arrow-alt-to-right:before {
  content: "\f34c";
}
.fa-arrow-alt-to-top:before {
  content: "\f34d";
}
.fa-arrow-alt-up:before {
  content: "\f357";
}
.fa-arrow-circle-down:before {
  content: "\f0ab";
}
.fa-arrow-circle-left:before {
  content: "\f0a8";
}
.fa-arrow-circle-right:before {
  content: "\f0a9";
}
.fa-arrow-circle-up:before {
  content: "\f0aa";
}
.fa-arrow-down:before {
  content: "\f063";
}
.fa-arrow-from-bottom:before {
  content: "\f342";
}
.fa-arrow-from-left:before {
  content: "\f343";
}
.fa-arrow-from-right:before {
  content: "\f344";
}
.fa-arrow-from-top:before {
  content: "\f345";
}
.fa-arrow-left:before {
  content: "\f060";
}
.fa-arrow-right:before {
  content: "\f061";
}
.fa-arrow-square-down:before {
  content: "\f339";
}
.fa-arrow-square-left:before {
  content: "\f33a";
}
.fa-arrow-square-right:before {
  content: "\f33b";
}
.fa-arrow-square-up:before {
  content: "\f33c";
}
.fa-arrow-to-bottom:before {
  content: "\f33d";
}
.fa-arrow-to-left:before {
  content: "\f33e";
}
.fa-arrow-to-right:before {
  content: "\f340";
}
.fa-arrow-to-top:before {
  content: "\f341";
}
.fa-arrow-up:before {
  content: "\f062";
}
.fa-arrows:before {
  content: "\f047";
}
.fa-arrows-alt:before {
  content: "\f0b2";
}
.fa-arrows-alt-h:before {
  content: "\f337";
}
.fa-arrows-alt-v:before {
  content: "\f338";
}
.fa-arrows-h:before {
  content: "\f07e";
}
.fa-arrows-v:before {
  content: "\f07d";
}
.fa-assistive-listening-systems:before {
  content: "\f2a2";
}
.fa-asterisk:before {
  content: "\f069";
}
.fa-asymmetrik:before {
  content: "\f372";
}
.fa-at:before {
  content: "\f1fa";
}
.fa-atlas:before {
  content: "\f558";
}
.fa-atom:before {
  content: "\f5d2";
}
.fa-atom-alt:before {
  content: "\f5d3";
}
.fa-audible:before {
  content: "\f373";
}
.fa-audio-description:before {
  content: "\f29e";
}
.fa-autoprefixer:before {
  content: "\f41c";
}
.fa-avianex:before {
  content: "\f374";
}
.fa-aviato:before {
  content: "\f421";
}
.fa-award:before {
  content: "\f559";
}
.fa-aws:before {
  content: "\f375";
}
.fa-backpack:before {
  content: "\f5d4";
}
.fa-backspace:before {
  content: "\f55a";
}
.fa-backward:before {
  content: "\f04a";
}
.fa-badge:before {
  content: "\f335";
}
.fa-badge-check:before {
  content: "\f336";
}
.fa-badge-dollar:before {
  content: "\f645";
}
.fa-badge-percent:before {
  content: "\f646";
}
.fa-balance-scale:before {
  content: "\f24e";
}
.fa-balance-scale-left:before {
  content: "\f515";
}
.fa-balance-scale-right:before {
  content: "\f516";
}
.fa-ban:before {
  content: "\f05e";
}
.fa-band-aid:before {
  content: "\f462";
}
.fa-bandcamp:before {
  content: "\f2d5";
}
.fa-barcode:before {
  content: "\f02a";
}
.fa-barcode-alt:before {
  content: "\f463";
}
.fa-barcode-read:before {
  content: "\f464";
}
.fa-barcode-scan:before {
  content: "\f465";
}
.fa-bars:before {
  content: "\f0c9";
}
.fa-baseball:before {
  content: "\f432";
}
.fa-baseball-ball:before {
  content: "\f433";
}
.fa-basketball-ball:before {
  content: "\f434";
}
.fa-basketball-hoop:before {
  content: "\f435";
}
.fa-bath:before {
  content: "\f2cd";
}
.fa-battery-bolt:before {
  content: "\f376";
}
.fa-battery-empty:before {
  content: "\f244";
}
.fa-battery-full:before {
  content: "\f240";
}
.fa-battery-half:before {
  content: "\f242";
}
.fa-battery-quarter:before {
  content: "\f243";
}
.fa-battery-slash:before {
  content: "\f377";
}
.fa-battery-three-quarters:before {
  content: "\f241";
}
.fa-bed:before {
  content: "\f236";
}
.fa-beer:before {
  content: "\f0fc";
}
.fa-behance:before {
  content: "\f1b4";
}
.fa-behance-square:before {
  content: "\f1b5";
}
.fa-bell:before {
  content: "\f0f3";
}
.fa-bell-school:before {
  content: "\f5d5";
}
.fa-bell-school-slash:before {
  content: "\f5d6";
}
.fa-bell-slash:before {
  content: "\f1f6";
}
.fa-bezier-curve:before {
  content: "\f55b";
}
.fa-bible:before {
  content: "\f647";
}
.fa-bicycle:before {
  content: "\f206";
}
.fa-bimobject:before {
  content: "\f378";
}
.fa-binoculars:before {
  content: "\f1e5";
}
.fa-birthday-cake:before {
  content: "\f1fd";
}
.fa-bitbucket:before {
  content: "\f171";
}
.fa-bitcoin:before {
  content: "\f379";
}
.fa-bity:before {
  content: "\f37a";
}
.fa-black-tie:before {
  content: "\f27e";
}
.fa-blackberry:before {
  content: "\f37b";
}
.fa-blanket:before {
  content: "\f498";
}
.fa-blender:before {
  content: "\f517";
}
.fa-blind:before {
  content: "\f29d";
}
.fa-blogger:before {
  content: "\f37c";
}
.fa-blogger-b:before {
  content: "\f37d";
}
.fa-bluetooth:before {
  content: "\f293";
}
.fa-bluetooth-b:before {
  content: "\f294";
}
.fa-bold:before {
  content: "\f032";
}
.fa-bolt:before {
  content: "\f0e7";
}
.fa-bomb:before {
  content: "\f1e2";
}
.fa-bone:before {
  content: "\f5d7";
}
.fa-bone-break:before {
  content: "\f5d8";
}
.fa-bong:before {
  content: "\f55c";
}
.fa-book:before {
  content: "\f02d";
}
.fa-book-alt:before {
  content: "\f5d9";
}
.fa-book-heart:before {
  content: "\f499";
}
.fa-book-open:before {
  content: "\f518";
}
.fa-book-reader:before {
  content: "\f5da";
}
.fa-bookmark:before {
  content: "\f02e";
}
.fa-books:before {
  content: "\f5db";
}
.fa-bowling-ball:before {
  content: "\f436";
}
.fa-bowling-pins:before {
  content: "\f437";
}
.fa-box:before {
  content: "\f466";
}
.fa-box-alt:before {
  content: "\f49a";
}
.fa-box-check:before {
  content: "\f467";
}
.fa-box-fragile:before {
  content: "\f49b";
}
.fa-box-full:before {
  content: "\f49c";
}
.fa-box-heart:before {
  content: "\f49d";
}
.fa-box-open:before {
  content: "\f49e";
}
.fa-box-up:before {
  content: "\f49f";
}
.fa-box-usd:before {
  content: "\f4a0";
}
.fa-boxes:before {
  content: "\f468";
}
.fa-boxes-alt:before {
  content: "\f4a1";
}
.fa-boxing-glove:before {
  content: "\f438";
}
.fa-braille:before {
  content: "\f2a1";
}
.fa-brain:before {
  content: "\f5dc";
}
.fa-briefcase:before {
  content: "\f0b1";
}
.fa-briefcase-medical:before {
  content: "\f469";
}
.fa-broadcast-tower:before {
  content: "\f519";
}
.fa-broom:before {
  content: "\f51a";
}
.fa-browser:before {
  content: "\f37e";
}
.fa-brush:before {
  content: "\f55d";
}
.fa-btc:before {
  content: "\f15a";
}
.fa-bug:before {
  content: "\f188";
}
.fa-building:before {
  content: "\f1ad";
}
.fa-bullhorn:before {
  content: "\f0a1";
}
.fa-bullseye:before {
  content: "\f140";
}
.fa-bullseye-arrow:before {
  content: "\f648";
}
.fa-bullseye-pointer:before {
  content: "\f649";
}
.fa-burn:before {
  content: "\f46a";
}
.fa-buromobelexperte:before {
  content: "\f37f";
}
.fa-bus:before {
  content: "\f207";
}
.fa-bus-alt:before {
  content: "\f55e";
}
.fa-bus-school:before {
  content: "\f5dd";
}
.fa-business-time:before {
  content: "\f64a";
}
.fa-buysellads:before {
  content: "\f20d";
}
.fa-cabinet-filing:before {
  content: "\f64b";
}
.fa-calculator:before {
  content: "\f1ec";
}
.fa-calculator-alt:before {
  content: "\f64c";
}
.fa-calendar:before {
  content: "\f133";
}
.fa-calendar-alt:before {
  content: "\f073";
}
.fa-calendar-check:before {
  content: "\f274";
}
.fa-calendar-edit:before {
  content: "\f333";
}
.fa-calendar-exclamation:before {
  content: "\f334";
}
.fa-calendar-minus:before {
  content: "\f272";
}
.fa-calendar-plus:before {
  content: "\f271";
}
.fa-calendar-times:before {
  content: "\f273";
}
.fa-camera:before {
  content: "\f030";
}
.fa-camera-alt:before {
  content: "\f332";
}
.fa-camera-retro:before {
  content: "\f083";
}
.fa-cannabis:before {
  content: "\f55f";
}
.fa-capsules:before {
  content: "\f46b";
}
.fa-car:before {
  content: "\f1b9";
}
.fa-car-alt:before {
  content: "\f5de";
}
.fa-car-battery:before {
  content: "\f5df";
}
.fa-car-bump:before {
  content: "\f5e0";
}
.fa-car-crash:before {
  content: "\f5e1";
}
.fa-car-garage:before {
  content: "\f5e2";
}
.fa-car-mechanic:before {
  content: "\f5e3";
}
.fa-car-side:before {
  content: "\f5e4";
}
.fa-car-tilt:before {
  content: "\f5e5";
}
.fa-car-wash:before {
  content: "\f5e6";
}
.fa-caret-circle-down:before {
  content: "\f32d";
}
.fa-caret-circle-left:before {
  content: "\f32e";
}
.fa-caret-circle-right:before {
  content: "\f330";
}
.fa-caret-circle-up:before {
  content: "\f331";
}
.fa-caret-down:before {
  content: "\f0d7";
}
.fa-caret-left:before {
  content: "\f0d9";
}
.fa-caret-right:before {
  content: "\f0da";
}
.fa-caret-square-down:before {
  content: "\f150";
}
.fa-caret-square-left:before {
  content: "\f191";
}
.fa-caret-square-right:before {
  content: "\f152";
}
.fa-caret-square-up:before {
  content: "\f151";
}
.fa-caret-up:before {
  content: "\f0d8";
}
.fa-cart-arrow-down:before {
  content: "\f218";
}
.fa-cart-plus:before {
  content: "\f217";
}
.fa-cc-amazon-pay:before {
  content: "\f42d";
}
.fa-cc-amex:before {
  content: "\f1f3";
}
.fa-cc-apple-pay:before {
  content: "\f416";
}
.fa-cc-diners-club:before {
  content: "\f24c";
}
.fa-cc-discover:before {
  content: "\f1f2";
}
.fa-cc-jcb:before {
  content: "\f24b";
}
.fa-cc-mastercard:before {
  content: "\f1f1";
}
.fa-cc-paypal:before {
  content: "\f1f4";
}
.fa-cc-stripe:before {
  content: "\f1f5";
}
.fa-cc-visa:before {
  content: "\f1f0";
}
.fa-centercode:before {
  content: "\f380";
}
.fa-certificate:before {
  content: "\f0a3";
}
.fa-chalkboard:before {
  content: "\f51b";
}
.fa-chalkboard-teacher:before {
  content: "\f51c";
}
.fa-charging-station:before {
  content: "\f5e7";
}
.fa-chart-area:before {
  content: "\f1fe";
}
.fa-chart-bar:before {
  content: "\f080";
}
.fa-chart-line:before {
  content: "\f201";
}
.fa-chart-line-down:before {
  content: "\f64d";
}
.fa-chart-pie:before {
  content: "\f200";
}
.fa-chart-pie-alt:before {
  content: "\f64e";
}
.fa-check:before {
  content: "\f00c";
}
.fa-check-circle:before {
  content: "\f058";
}
.fa-check-double:before {
  content: "\f560";
}
.fa-check-square:before {
  content: "\f14a";
}
.fa-chess:before {
  content: "\f439";
}
.fa-chess-bishop:before {
  content: "\f43a";
}
.fa-chess-bishop-alt:before {
  content: "\f43b";
}
.fa-chess-board:before {
  content: "\f43c";
}
.fa-chess-clock:before {
  content: "\f43d";
}
.fa-chess-clock-alt:before {
  content: "\f43e";
}
.fa-chess-king:before {
  content: "\f43f";
}
.fa-chess-king-alt:before {
  content: "\f440";
}
.fa-chess-knight:before {
  content: "\f441";
}
.fa-chess-knight-alt:before {
  content: "\f442";
}
.fa-chess-pawn:before {
  content: "\f443";
}
.fa-chess-pawn-alt:before {
  content: "\f444";
}
.fa-chess-queen:before {
  content: "\f445";
}
.fa-chess-queen-alt:before {
  content: "\f446";
}
.fa-chess-rook:before {
  content: "\f447";
}
.fa-chess-rook-alt:before {
  content: "\f448";
}
.fa-chevron-circle-down:before {
  content: "\f13a";
}
.fa-chevron-circle-left:before {
  content: "\f137";
}
.fa-chevron-circle-right:before {
  content: "\f138";
}
.fa-chevron-circle-up:before {
  content: "\f139";
}
.fa-chevron-double-down:before {
  content: "\f322";
}
.fa-chevron-double-left:before {
  content: "\f323";
}
.fa-chevron-double-right:before {
  content: "\f324";
}
.fa-chevron-double-up:before {
  content: "\f325";
}
.fa-chevron-down:before {
  content: "\f078";
}
.fa-chevron-left:before {
  content: "\f053";
}
.fa-chevron-right:before {
  content: "\f054";
}
.fa-chevron-square-down:before {
  content: "\f329";
}
.fa-chevron-square-left:before {
  content: "\f32a";
}
.fa-chevron-square-right:before {
  content: "\f32b";
}
.fa-chevron-square-up:before {
  content: "\f32c";
}
.fa-chevron-up:before {
  content: "\f077";
}
.fa-child:before {
  content: "\f1ae";
}
.fa-chrome:before {
  content: "\f268";
}
.fa-church:before {
  content: "\f51d";
}
.fa-circle:before {
  content: "\f111";
}
.fa-circle-notch:before {
  content: "\f1ce";
}
.fa-city:before {
  content: "\f64f";
}
.fa-clipboard:before {
  content: "\f328";
}
.fa-clipboard-check:before {
  content: "\f46c";
}
.fa-clipboard-list:before {
  content: "\f46d";
}
.fa-clipboard-prescription:before {
  content: "\f5e8";
}
.fa-clock:before {
  content: "\f017";
}
.fa-clone:before {
  content: "\f24d";
}
.fa-closed-captioning:before {
  content: "\f20a";
}
.fa-cloud:before {
  content: "\f0c2";
}
.fa-cloud-download:before {
  content: "\f0ed";
}
.fa-cloud-download-alt:before {
  content: "\f381";
}
.fa-cloud-upload:before {
  content: "\f0ee";
}
.fa-cloud-upload-alt:before {
  content: "\f382";
}
.fa-cloudscale:before {
  content: "\f383";
}
.fa-cloudsmith:before {
  content: "\f384";
}
.fa-cloudversify:before {
  content: "\f385";
}
.fa-club:before {
  content: "\f327";
}
.fa-cocktail:before {
  content: "\f561";
}
.fa-code:before {
  content: "\f121";
}
.fa-code-branch:before {
  content: "\f126";
}
.fa-code-commit:before {
  content: "\f386";
}
.fa-code-merge:before {
  content: "\f387";
}
.fa-codepen:before {
  content: "\f1cb";
}
.fa-codiepie:before {
  content: "\f284";
}
.fa-coffee:before {
  content: "\f0f4";
}
.fa-cog:before {
  content: "\f013";
}
.fa-cogs:before {
  content: "\f085";
}
.fa-coins:before {
  content: "\f51e";
}
.fa-columns:before {
  content: "\f0db";
}
.fa-comment:before {
  content: "\f075";
}
.fa-comment-alt:before {
  content: "\f27a";
}
.fa-comment-alt-check:before {
  content: "\f4a2";
}
.fa-comment-alt-dollar:before {
  content: "\f650";
}
.fa-comment-alt-dots:before {
  content: "\f4a3";
}
.fa-comment-alt-edit:before {
  content: "\f4a4";
}
.fa-comment-alt-exclamation:before {
  content: "\f4a5";
}
.fa-comment-alt-lines:before {
  content: "\f4a6";
}
.fa-comment-alt-minus:before {
  content: "\f4a7";
}
.fa-comment-alt-plus:before {
  content: "\f4a8";
}
.fa-comment-alt-slash:before {
  content: "\f4a9";
}
.fa-comment-alt-smile:before {
  content: "\f4aa";
}
.fa-comment-alt-times:before {
  content: "\f4ab";
}
.fa-comment-check:before {
  content: "\f4ac";
}
.fa-comment-dollar:before {
  content: "\f651";
}
.fa-comment-dots:before {
  content: "\f4ad";
}
.fa-comment-edit:before {
  content: "\f4ae";
}
.fa-comment-exclamation:before {
  content: "\f4af";
}
.fa-comment-lines:before {
  content: "\f4b0";
}
.fa-comment-minus:before {
  content: "\f4b1";
}
.fa-comment-plus:before {
  content: "\f4b2";
}
.fa-comment-slash:before {
  content: "\f4b3";
}
.fa-comment-smile:before {
  content: "\f4b4";
}
.fa-comment-times:before {
  content: "\f4b5";
}
.fa-comments:before {
  content: "\f086";
}
.fa-comments-alt:before {
  content: "\f4b6";
}
.fa-comments-alt-dollar:before {
  content: "\f652";
}
.fa-comments-dollar:before {
  content: "\f653";
}
.fa-compact-disc:before {
  content: "\f51f";
}
.fa-compass:before {
  content: "\f14e";
}
.fa-compass-slash:before {
  content: "\f5e9";
}
.fa-compress:before {
  content: "\f066";
}
.fa-compress-alt:before {
  content: "\f422";
}
.fa-compress-wide:before {
  content: "\f326";
}
.fa-concierge-bell:before {
  content: "\f562";
}
.fa-connectdevelop:before {
  content: "\f20e";
}
.fa-container-storage:before {
  content: "\f4b7";
}
.fa-contao:before {
  content: "\f26d";
}
.fa-conveyor-belt:before {
  content: "\f46e";
}
.fa-conveyor-belt-alt:before {
  content: "\f46f";
}
.fa-cookie:before {
  content: "\f563";
}
.fa-cookie-bite:before {
  content: "\f564";
}
.fa-copy:before {
  content: "\f0c5";
}
.fa-copyright:before {
  content: "\f1f9";
}
.fa-couch:before {
  content: "\f4b8";
}
.fa-cpanel:before {
  content: "\f388";
}
.fa-creative-commons:before {
  content: "\f25e";
}
.fa-creative-commons-by:before {
  content: "\f4e7";
}
.fa-creative-commons-nc:before {
  content: "\f4e8";
}
.fa-creative-commons-nc-eu:before {
  content: "\f4e9";
}
.fa-creative-commons-nc-jp:before {
  content: "\f4ea";
}
.fa-creative-commons-nd:before {
  content: "\f4eb";
}
.fa-creative-commons-pd:before {
  content: "\f4ec";
}
.fa-creative-commons-pd-alt:before {
  content: "\f4ed";
}
.fa-creative-commons-remix:before {
  content: "\f4ee";
}
.fa-creative-commons-sa:before {
  content: "\f4ef";
}
.fa-creative-commons-sampling:before {
  content: "\f4f0";
}
.fa-creative-commons-sampling-plus:before {
  content: "\f4f1";
}
.fa-creative-commons-share:before {
  content: "\f4f2";
}
.fa-creative-commons-zero:before {
  content: "\f4f3";
}
.fa-credit-card:before {
  content: "\f09d";
}
.fa-credit-card-blank:before {
  content: "\f389";
}
.fa-credit-card-front:before {
  content: "\f38a";
}
.fa-cricket:before {
  content: "\f449";
}
.fa-crop:before {
  content: "\f125";
}
.fa-crop-alt:before {
  content: "\f565";
}
.fa-cross:before {
  content: "\f654";
}
.fa-crosshairs:before {
  content: "\f05b";
}
.fa-crow:before {
  content: "\f520";
}
.fa-crown:before {
  content: "\f521";
}
.fa-css3:before {
  content: "\f13c";
}
.fa-css3-alt:before {
  content: "\f38b";
}
.fa-cube:before {
  content: "\f1b2";
}
.fa-cubes:before {
  content: "\f1b3";
}
.fa-curling:before {
  content: "\f44a";
}
.fa-cut:before {
  content: "\f0c4";
}
.fa-cuttlefish:before {
  content: "\f38c";
}
.fa-d-and-d:before {
  content: "\f38d";
}
.fa-dashcube:before {
  content: "\f210";
}
.fa-database:before {
  content: "\f1c0";
}
.fa-deaf:before {
  content: "\f2a4";
}
.fa-delicious:before {
  content: "\f1a5";
}
.fa-deploydog:before {
  content: "\f38e";
}
.fa-deskpro:before {
  content: "\f38f";
}
.fa-desktop:before {
  content: "\f108";
}
.fa-desktop-alt:before {
  content: "\f390";
}
.fa-deviantart:before {
  content: "\f1bd";
}
.fa-dharmachakra:before {
  content: "\f655";
}
.fa-diagnoses:before {
  content: "\f470";
}
.fa-diamond:before {
  content: "\f219";
}
.fa-dice:before {
  content: "\f522";
}
.fa-dice-five:before {
  content: "\f523";
}
.fa-dice-four:before {
  content: "\f524";
}
.fa-dice-one:before {
  content: "\f525";
}
.fa-dice-six:before {
  content: "\f526";
}
.fa-dice-three:before {
  content: "\f527";
}
.fa-dice-two:before {
  content: "\f528";
}
.fa-digg:before {
  content: "\f1a6";
}
.fa-digital-ocean:before {
  content: "\f391";
}
.fa-digital-tachograph:before {
  content: "\f566";
}
.fa-diploma:before {
  content: "\f5ea";
}
.fa-directions:before {
  content: "\f5eb";
}
.fa-discord:before {
  content: "\f392";
}
.fa-discourse:before {
  content: "\f393";
}
.fa-divide:before {
  content: "\f529";
}
.fa-dizzy:before {
  content: "\f567";
}
.fa-dna:before {
  content: "\f471";
}
.fa-do-not-enter:before {
  content: "\f5ec";
}
.fa-dochub:before {
  content: "\f394";
}
.fa-docker:before {
  content: "\f395";
}
.fa-dollar-sign:before {
  content: "\f155";
}
.fa-dolly:before {
  content: "\f472";
}
.fa-dolly-empty:before {
  content: "\f473";
}
.fa-dolly-flatbed:before {
  content: "\f474";
}
.fa-dolly-flatbed-alt:before {
  content: "\f475";
}
.fa-dolly-flatbed-empty:before {
  content: "\f476";
}
.fa-donate:before {
  content: "\f4b9";
}
.fa-door-closed:before {
  content: "\f52a";
}
.fa-door-open:before {
  content: "\f52b";
}
.fa-dot-circle:before {
  content: "\f192";
}
.fa-dove:before {
  content: "\f4ba";
}
.fa-download:before {
  content: "\f019";
}
.fa-draft2digital:before {
  content: "\f396";
}
.fa-drafting-compass:before {
  content: "\f568";
}
.fa-draw-circle:before {
  content: "\f5ed";
}
.fa-draw-polygon:before {
  content: "\f5ee";
}
.fa-draw-square:before {
  content: "\f5ef";
}
.fa-dribbble:before {
  content: "\f17d";
}
.fa-dribbble-square:before {
  content: "\f397";
}
.fa-dropbox:before {
  content: "\f16b";
}
.fa-drum:before {
  content: "\f569";
}
.fa-drum-steelpan:before {
  content: "\f56a";
}
.fa-drupal:before {
  content: "\f1a9";
}
.fa-dumbbell:before {
  content: "\f44b";
}
.fa-dyalog:before {
  content: "\f399";
}
.fa-ear:before {
  content: "\f5f0";
}
.fa-earlybirds:before {
  content: "\f39a";
}
.fa-ebay:before {
  content: "\f4f4";
}
.fa-edge:before {
  content: "\f282";
}
.fa-edit:before {
  content: "\f044";
}
.fa-eject:before {
  content: "\f052";
}
.fa-elementor:before {
  content: "\f430";
}
.fa-ellipsis-h:before {
  content: "\f141";
}
.fa-ellipsis-h-alt:before {
  content: "\f39b";
}
.fa-ellipsis-v:before {
  content: "\f142";
}
.fa-ellipsis-v-alt:before {
  content: "\f39c";
}
.fa-ello:before {
  content: "\f5f1";
}
.fa-ember:before {
  content: "\f423";
}
.fa-empire:before {
  content: "\f1d1";
}
.fa-empty-set:before {
  content: "\f656";
}
.fa-engine-warning:before {
  content: "\f5f2";
}
.fa-envelope:before {
  content: "\f0e0";
}
.fa-envelope-open:before {
  content: "\f2b6";
}
.fa-envelope-open-dollar:before {
  content: "\f657";
}
.fa-envelope-open-text:before {
  content: "\f658";
}
.fa-envelope-square:before {
  content: "\f199";
}
.fa-envira:before {
  content: "\f299";
}
.fa-equals:before {
  content: "\f52c";
}
.fa-eraser:before {
  content: "\f12d";
}
.fa-erlang:before {
  content: "\f39d";
}
.fa-ethereum:before {
  content: "\f42e";
}
.fa-etsy:before {
  content: "\f2d7";
}
.fa-euro-sign:before {
  content: "\f153";
}
.fa-exchange:before {
  content: "\f0ec";
}
.fa-exchange-alt:before {
  content: "\f362";
}
.fa-exclamation:before {
  content: "\f12a";
}
.fa-exclamation-circle:before {
  content: "\f06a";
}
.fa-exclamation-square:before {
  content: "\f321";
}
.fa-exclamation-triangle:before {
  content: "\f071";
}
.fa-expand:before {
  content: "\f065";
}
.fa-expand-alt:before {
  content: "\f424";
}
.fa-expand-arrows:before {
  content: "\f31d";
}
.fa-expand-arrows-alt:before {
  content: "\f31e";
}
.fa-expand-wide:before {
  content: "\f320";
}
.fa-expeditedssl:before {
  content: "\f23e";
}
.fa-external-link:before {
  content: "\f08e";
}
.fa-external-link-alt:before {
  content: "\f35d";
}
.fa-external-link-square:before {
  content: "\f14c";
}
.fa-external-link-square-alt:before {
  content: "\f360";
}
.fa-eye:before {
  content: "\f06e";
}
.fa-eye-dropper:before {
  content: "\f1fb";
}
.fa-eye-slash:before {
  content: "\f070";
}
.fa-facebook:before {
  content: "\f09a";
}
.fa-facebook-f:before {
  content: "\f39e";
}
.fa-facebook-messenger:before {
  content: "\f39f";
}
.fa-facebook-square:before {
  content: "\f082";
}
.fa-fast-backward:before {
  content: "\f049";
}
.fa-fast-forward:before {
  content: "\f050";
}
.fa-fax:before {
  content: "\f1ac";
}
.fa-feather:before {
  content: "\f52d";
}
.fa-feather-alt:before {
  content: "\f56b";
}
.fa-female:before {
  content: "\f182";
}
.fa-field-hockey:before {
  content: "\f44c";
}
.fa-fighter-jet:before {
  content: "\f0fb";
}
.fa-file:before {
  content: "\f15b";
}
.fa-file-alt:before {
  content: "\f15c";
}
.fa-file-archive:before {
  content: "\f1c6";
}
.fa-file-audio:before {
  content: "\f1c7";
}
.fa-file-certificate:before {
  content: "\f5f3";
}
.fa-file-chart-line:before {
  content: "\f659";
}
.fa-file-chart-pie:before {
  content: "\f65a";
}
.fa-file-check:before {
  content: "\f316";
}
.fa-file-code:before {
  content: "\f1c9";
}
.fa-file-contract:before {
  content: "\f56c";
}
.fa-file-download:before {
  content: "\f56d";
}
.fa-file-edit:before {
  content: "\f31c";
}
.fa-file-excel:before {
  content: "\f1c3";
}
.fa-file-exclamation:before {
  content: "\f31a";
}
.fa-file-export:before {
  content: "\f56e";
}
.fa-file-image:before {
  content: "\f1c5";
}
.fa-file-import:before {
  content: "\f56f";
}
.fa-file-invoice:before {
  content: "\f570";
}
.fa-file-invoice-dollar:before {
  content: "\f571";
}
.fa-file-medical:before {
  content: "\f477";
}
.fa-file-medical-alt:before {
  content: "\f478";
}
.fa-file-minus:before {
  content: "\f318";
}
.fa-file-pdf:before {
  content: "\f1c1";
}
.fa-file-plus:before {
  content: "\f319";
}
.fa-file-powerpoint:before {
  content: "\f1c4";
}
.fa-file-prescription:before {
  content: "\f572";
}
.fa-file-signature:before {
  content: "\f573";
}
.fa-file-spreadsheet:before {
  content: "\f65b";
}
.fa-file-times:before {
  content: "\f317";
}
.fa-file-upload:before {
  content: "\f574";
}
.fa-file-user:before {
  content: "\f65c";
}
.fa-file-video:before {
  content: "\f1c8";
}
.fa-file-word:before {
  content: "\f1c2";
}
.fa-fill:before {
  content: "\f575";
}
.fa-fill-drip:before {
  content: "\f576";
}
.fa-film:before {
  content: "\f008";
}
.fa-film-alt:before {
  content: "\f3a0";
}
.fa-filter:before {
  content: "\f0b0";
}
.fa-fingerprint:before {
  content: "\f577";
}
.fa-fire:before {
  content: "\f06d";
}
.fa-fire-extinguisher:before {
  content: "\f134";
}
.fa-firefox:before {
  content: "\f269";
}
.fa-first-aid:before {
  content: "\f479";
}
.fa-first-order:before {
  content: "\f2b0";
}
.fa-first-order-alt:before {
  content: "\f50a";
}
.fa-firstdraft:before {
  content: "\f3a1";
}
.fa-fish:before {
  content: "\f578";
}
.fa-flag:before {
  content: "\f024";
}
.fa-flag-checkered:before {
  content: "\f11e";
}
.fa-flask:before {
  content: "\f0c3";
}
.fa-flickr:before {
  content: "\f16e";
}
.fa-flipboard:before {
  content: "\f44d";
}
.fa-flushed:before {
  content: "\f579";
}
.fa-fly:before {
  content: "\f417";
}
.fa-folder:before {
  content: "\f07b";
}
.fa-folder-minus:before {
  content: "\f65d";
}
.fa-folder-open:before {
  content: "\f07c";
}
.fa-folder-plus:before {
  content: "\f65e";
}
.fa-folder-times:before {
  content: "\f65f";
}
.fa-folders:before {
  content: "\f660";
}
.fa-font:before {
  content: "\f031";
}
.fa-font-awesome:before {
  content: "\f2b4";
}
.fa-font-awesome-alt:before {
  content: "\f35c";
}
.fa-font-awesome-flag:before {
  content: "\f425";
}
.fa-font-awesome-logo-full:before {
  content: "\f4e6";
}
.fa-fonticons:before {
  content: "\f280";
}
.fa-fonticons-fi:before {
  content: "\f3a2";
}
.fa-football-ball:before {
  content: "\f44e";
}
.fa-football-helmet:before {
  content: "\f44f";
}
.fa-forklift:before {
  content: "\f47a";
}
.fa-fort-awesome:before {
  content: "\f286";
}
.fa-fort-awesome-alt:before {
  content: "\f3a3";
}
.fa-forumbee:before {
  content: "\f211";
}
.fa-forward:before {
  content: "\f04e";
}
.fa-foursquare:before {
  content: "\f180";
}
.fa-fragile:before {
  content: "\f4bb";
}
.fa-free-code-camp:before {
  content: "\f2c5";
}
.fa-freebsd:before {
  content: "\f3a4";
}
.fa-frog:before {
  content: "\f52e";
}
.fa-frown:before {
  content: "\f119";
}
.fa-frown-open:before {
  content: "\f57a";
}
.fa-fulcrum:before {
  content: "\f50b";
}
.fa-function:before {
  content: "\f661";
}
.fa-funnel-dollar:before {
  content: "\f662";
}
.fa-futbol:before {
  content: "\f1e3";
}
.fa-galactic-republic:before {
  content: "\f50c";
}
.fa-galactic-senate:before {
  content: "\f50d";
}
.fa-gamepad:before {
  content: "\f11b";
}
.fa-gas-pump:before {
  content: "\f52f";
}
.fa-gas-pump-slash:before {
  content: "\f5f4";
}
.fa-gavel:before {
  content: "\f0e3";
}
.fa-gem:before {
  content: "\f3a5";
}
.fa-genderless:before {
  content: "\f22d";
}
.fa-get-pocket:before {
  content: "\f265";
}
.fa-gg:before {
  content: "\f260";
}
.fa-gg-circle:before {
  content: "\f261";
}
.fa-gift:before {
  content: "\f06b";
}
.fa-gift-card:before {
  content: "\f663";
}
.fa-git:before {
  content: "\f1d3";
}
.fa-git-square:before {
  content: "\f1d2";
}
.fa-github:before {
  content: "\f09b";
}
.fa-github-alt:before {
  content: "\f113";
}
.fa-github-square:before {
  content: "\f092";
}
.fa-gitkraken:before {
  content: "\f3a6";
}
.fa-gitlab:before {
  content: "\f296";
}
.fa-gitter:before {
  content: "\f426";
}
.fa-glass-martini:before {
  content: "\f000";
}
.fa-glass-martini-alt:before {
  content: "\f57b";
}
.fa-glasses:before {
  content: "\f530";
}
.fa-glasses-alt:before {
  content: "\f5f5";
}
.fa-glide:before {
  content: "\f2a5";
}
.fa-glide-g:before {
  content: "\f2a6";
}
.fa-globe:before {
  content: "\f0ac";
}
.fa-globe-africa:before {
  content: "\f57c";
}
.fa-globe-americas:before {
  content: "\f57d";
}
.fa-globe-asia:before {
  content: "\f57e";
}
.fa-globe-stand:before {
  content: "\f5f6";
}
.fa-gofore:before {
  content: "\f3a7";
}
.fa-golf-ball:before {
  content: "\f450";
}
.fa-golf-club:before {
  content: "\f451";
}
.fa-goodreads:before {
  content: "\f3a8";
}
.fa-goodreads-g:before {
  content: "\f3a9";
}
.fa-google:before {
  content: "\f1a0";
}
.fa-google-drive:before {
  content: "\f3aa";
}
.fa-google-play:before {
  content: "\f3ab";
}
.fa-google-plus:before {
  content: "\f2b3";
}
.fa-google-plus-g:before {
  content: "\f0d5";
}
.fa-google-plus-square:before {
  content: "\f0d4";
}
.fa-google-wallet:before {
  content: "\f1ee";
}
.fa-gopuram:before {
  content: "\f664";
}
.fa-graduation-cap:before {
  content: "\f19d";
}
.fa-gratipay:before {
  content: "\f184";
}
.fa-grav:before {
  content: "\f2d6";
}
.fa-greater-than:before {
  content: "\f531";
}
.fa-greater-than-equal:before {
  content: "\f532";
}
.fa-grimace:before {
  content: "\f57f";
}
.fa-grin:before {
  content: "\f580";
}
.fa-grin-alt:before {
  content: "\f581";
}
.fa-grin-beam:before {
  content: "\f582";
}
.fa-grin-beam-sweat:before {
  content: "\f583";
}
.fa-grin-hearts:before {
  content: "\f584";
}
.fa-grin-squint:before {
  content: "\f585";
}
.fa-grin-squint-tears:before {
  content: "\f586";
}
.fa-grin-stars:before {
  content: "\f587";
}
.fa-grin-tears:before {
  content: "\f588";
}
.fa-grin-tongue:before {
  content: "\f589";
}
.fa-grin-tongue-squint:before {
  content: "\f58a";
}
.fa-grin-tongue-wink:before {
  content: "\f58b";
}
.fa-grin-wink:before {
  content: "\f58c";
}
.fa-grip-horizontal:before {
  content: "\f58d";
}
.fa-grip-vertical:before {
  content: "\f58e";
}
.fa-gripfire:before {
  content: "\f3ac";
}
.fa-grunt:before {
  content: "\f3ad";
}
.fa-gulp:before {
  content: "\f3ae";
}
.fa-h-square:before {
  content: "\f0fd";
}
.fa-h1:before {
  content: "\f313";
}
.fa-h2:before {
  content: "\f314";
}
.fa-h3:before {
  content: "\f315";
}
.fa-hacker-news:before {
  content: "\f1d4";
}
.fa-hacker-news-square:before {
  content: "\f3af";
}
.fa-hackerrank:before {
  content: "\f5f7";
}
.fa-hamsa:before {
  content: "\f665";
}
.fa-hand-heart:before {
  content: "\f4bc";
}
.fa-hand-holding:before {
  content: "\f4bd";
}
.fa-hand-holding-box:before {
  content: "\f47b";
}
.fa-hand-holding-heart:before {
  content: "\f4be";
}
.fa-hand-holding-seedling:before {
  content: "\f4bf";
}
.fa-hand-holding-usd:before {
  content: "\f4c0";
}
.fa-hand-holding-water:before {
  content: "\f4c1";
}
.fa-hand-lizard:before {
  content: "\f258";
}
.fa-hand-paper:before {
  content: "\f256";
}
.fa-hand-peace:before {
  content: "\f25b";
}
.fa-hand-point-down:before {
  content: "\f0a7";
}
.fa-hand-point-left:before {
  content: "\f0a5";
}
.fa-hand-point-right:before {
  content: "\f0a4";
}
.fa-hand-point-up:before {
  content: "\f0a6";
}
.fa-hand-pointer:before {
  content: "\f25a";
}
.fa-hand-receiving:before {
  content: "\f47c";
}
.fa-hand-rock:before {
  content: "\f255";
}
.fa-hand-scissors:before {
  content: "\f257";
}
.fa-hand-spock:before {
  content: "\f259";
}
.fa-hands:before {
  content: "\f4c2";
}
.fa-hands-heart:before {
  content: "\f4c3";
}
.fa-hands-helping:before {
  content: "\f4c4";
}
.fa-hands-usd:before {
  content: "\f4c5";
}
.fa-handshake:before {
  content: "\f2b5";
}
.fa-handshake-alt:before {
  content: "\f4c6";
}
.fa-hashtag:before {
  content: "\f292";
}
.fa-haykal:before {
  content: "\f666";
}
.fa-hdd:before {
  content: "\f0a0";
}
.fa-heading:before {
  content: "\f1dc";
}
.fa-headphones:before {
  content: "\f025";
}
.fa-headphones-alt:before {
  content: "\f58f";
}
.fa-headset:before {
  content: "\f590";
}
.fa-heart:before {
  content: "\f004";
}
.fa-heart-circle:before {
  content: "\f4c7";
}
.fa-heart-rate:before {
  content: "\f5f8";
}
.fa-heart-square:before {
  content: "\f4c8";
}
.fa-heartbeat:before {
  content: "\f21e";
}
.fa-helicopter:before {
  content: "\f533";
}
.fa-hexagon:before {
  content: "\f312";
}
.fa-highlighter:before {
  content: "\f591";
}
.fa-hips:before {
  content: "\f452";
}
.fa-hire-a-helper:before {
  content: "\f3b0";
}
.fa-history:before {
  content: "\f1da";
}
.fa-hockey-puck:before {
  content: "\f453";
}
.fa-hockey-sticks:before {
  content: "\f454";
}
.fa-home:before {
  content: "\f015";
}
.fa-home-heart:before {
  content: "\f4c9";
}
.fa-hooli:before {
  content: "\f427";
}
.fa-hornbill:before {
  content: "\f592";
}
.fa-hospital:before {
  content: "\f0f8";
}
.fa-hospital-alt:before {
  content: "\f47d";
}
.fa-hospital-symbol:before {
  content: "\f47e";
}
.fa-hot-tub:before {
  content: "\f593";
}
.fa-hotel:before {
  content: "\f594";
}
.fa-hotjar:before {
  content: "\f3b1";
}
.fa-hourglass:before {
  content: "\f254";
}
.fa-hourglass-end:before {
  content: "\f253";
}
.fa-hourglass-half:before {
  content: "\f252";
}
.fa-hourglass-start:before {
  content: "\f251";
}
.fa-houzz:before {
  content: "\f27c";
}
.fa-html5:before {
  content: "\f13b";
}
.fa-hubspot:before {
  content: "\f3b2";
}
.fa-i-cursor:before {
  content: "\f246";
}
.fa-id-badge:before {
  content: "\f2c1";
}
.fa-id-card:before {
  content: "\f2c2";
}
.fa-id-card-alt:before {
  content: "\f47f";
}
.fa-image:before {
  content: "\f03e";
}
.fa-images:before {
  content: "\f302";
}
.fa-imdb:before {
  content: "\f2d8";
}
.fa-inbox:before {
  content: "\f01c";
}
.fa-inbox-in:before {
  content: "\f310";
}
.fa-inbox-out:before {
  content: "\f311";
}
.fa-indent:before {
  content: "\f03c";
}
.fa-industry:before {
  content: "\f275";
}
.fa-industry-alt:before {
  content: "\f3b3";
}
.fa-infinity:before {
  content: "\f534";
}
.fa-info:before {
  content: "\f129";
}
.fa-info-circle:before {
  content: "\f05a";
}
.fa-info-square:before {
  content: "\f30f";
}
.fa-inhaler:before {
  content: "\f5f9";
}
.fa-instagram:before {
  content: "\f16d";
}
.fa-integral:before {
  content: "\f667";
}
.fa-internet-explorer:before {
  content: "\f26b";
}
.fa-intersection:before {
  content: "\f668";
}
.fa-inventory:before {
  content: "\f480";
}
.fa-ioxhost:before {
  content: "\f208";
}
.fa-italic:before {
  content: "\f033";
}
.fa-itunes:before {
  content: "\f3b4";
}
.fa-itunes-note:before {
  content: "\f3b5";
}
.fa-jack-o-lantern:before {
  content: "\f30e";
}
.fa-java:before {
  content: "\f4e4";
}
.fa-jedi:before {
  content: "\f669";
}
.fa-jedi-order:before {
  content: "\f50e";
}
.fa-jenkins:before {
  content: "\f3b6";
}
.fa-joget:before {
  content: "\f3b7";
}
.fa-joint:before {
  content: "\f595";
}
.fa-joomla:before {
  content: "\f1aa";
}
.fa-journal-whills:before {
  content: "\f66a";
}
.fa-js:before {
  content: "\f3b8";
}
.fa-js-square:before {
  content: "\f3b9";
}
.fa-jsfiddle:before {
  content: "\f1cc";
}
.fa-kaaba:before {
  content: "\f66b";
}
.fa-kaggle:before {
  content: "\f5fa";
}
.fa-key:before {
  content: "\f084";
}
.fa-keybase:before {
  content: "\f4f5";
}
.fa-keyboard:before {
  content: "\f11c";
}
.fa-keycdn:before {
  content: "\f3ba";
}
.fa-keynote:before {
  content: "\f66c";
}
.fa-khanda:before {
  content: "\f66d";
}
.fa-kickstarter:before {
  content: "\f3bb";
}
.fa-kickstarter-k:before {
  content: "\f3bc";
}
.fa-kidneys:before {
  content: "\f5fb";
}
.fa-kiss:before {
  content: "\f596";
}
.fa-kiss-beam:before {
  content: "\f597";
}
.fa-kiss-wink-heart:before {
  content: "\f598";
}
.fa-kiwi-bird:before {
  content: "\f535";
}
.fa-korvue:before {
  content: "\f42f";
}
.fa-lambda:before {
  content: "\f66e";
}
.fa-lamp:before {
  content: "\f4ca";
}
.fa-landmark:before {
  content: "\f66f";
}
.fa-language:before {
  content: "\f1ab";
}
.fa-laptop:before {
  content: "\f109";
}
.fa-laptop-code:before {
  content: "\f5fc";
}
.fa-laravel:before {
  content: "\f3bd";
}
.fa-lastfm:before {
  content: "\f202";
}
.fa-lastfm-square:before {
  content: "\f203";
}
.fa-laugh:before {
  content: "\f599";
}
.fa-laugh-beam:before {
  content: "\f59a";
}
.fa-laugh-squint:before {
  content: "\f59b";
}
.fa-laugh-wink:before {
  content: "\f59c";
}
.fa-layer-group:before {
  content: "\f5fd";
}
.fa-layer-minus:before {
  content: "\f5fe";
}
.fa-layer-plus:before {
  content: "\f5ff";
}
.fa-leaf:before {
  content: "\f06c";
}
.fa-leaf-heart:before {
  content: "\f4cb";
}
.fa-leanpub:before {
  content: "\f212";
}
.fa-lemon:before {
  content: "\f094";
}
.fa-less:before {
  content: "\f41d";
}
.fa-less-than:before {
  content: "\f536";
}
.fa-less-than-equal:before {
  content: "\f537";
}
.fa-level-down:before {
  content: "\f149";
}
.fa-level-down-alt:before {
  content: "\f3be";
}
.fa-level-up:before {
  content: "\f148";
}
.fa-level-up-alt:before {
  content: "\f3bf";
}
.fa-life-ring:before {
  content: "\f1cd";
}
.fa-lightbulb:before {
  content: "\f0eb";
}
.fa-lightbulb-dollar:before {
  content: "\f670";
}
.fa-lightbulb-exclamation:before {
  content: "\f671";
}
.fa-lightbulb-on:before {
  content: "\f672";
}
.fa-lightbulb-slash:before {
  content: "\f673";
}
.fa-line:before {
  content: "\f3c0";
}
.fa-link:before {
  content: "\f0c1";
}
.fa-linkedin:before {
  content: "\f08c";
}
.fa-linkedin-in:before {
  content: "\f0e1";
}
.fa-linode:before {
  content: "\f2b8";
}
.fa-linux:before {
  content: "\f17c";
}
.fa-lips:before {
  content: "\f600";
}
.fa-lira-sign:before {
  content: "\f195";
}
.fa-list:before {
  content: "\f03a";
}
.fa-list-alt:before {
  content: "\f022";
}
.fa-list-ol:before {
  content: "\f0cb";
}
.fa-list-ul:before {
  content: "\f0ca";
}
.fa-location:before {
  content: "\f601";
}
.fa-location-arrow:before {
  content: "\f124";
}
.fa-location-circle:before {
  content: "\f602";
}
.fa-location-slash:before {
  content: "\f603";
}
.fa-lock:before {
  content: "\f023";
}
.fa-lock-alt:before {
  content: "\f30d";
}
.fa-lock-open:before {
  content: "\f3c1";
}
.fa-lock-open-alt:before {
  content: "\f3c2";
}
.fa-long-arrow-alt-down:before {
  content: "\f309";
}
.fa-long-arrow-alt-left:before {
  content: "\f30a";
}
.fa-long-arrow-alt-right:before {
  content: "\f30b";
}
.fa-long-arrow-alt-up:before {
  content: "\f30c";
}
.fa-long-arrow-down:before {
  content: "\f175";
}
.fa-long-arrow-left:before {
  content: "\f177";
}
.fa-long-arrow-right:before {
  content: "\f178";
}
.fa-long-arrow-up:before {
  content: "\f176";
}
.fa-loveseat:before {
  content: "\f4cc";
}
.fa-low-vision:before {
  content: "\f2a8";
}
.fa-luchador:before {
  content: "\f455";
}
.fa-luggage-cart:before {
  content: "\f59d";
}
.fa-lungs:before {
  content: "\f604";
}
.fa-lyft:before {
  content: "\f3c3";
}
.fa-magento:before {
  content: "\f3c4";
}
.fa-magic:before {
  content: "\f0d0";
}
.fa-magnet:before {
  content: "\f076";
}
.fa-mail-bulk:before {
  content: "\f674";
}
.fa-mailchimp:before {
  content: "\f59e";
}
.fa-male:before {
  content: "\f183";
}
.fa-mandalorian:before {
  content: "\f50f";
}
.fa-map:before {
  content: "\f279";
}
.fa-map-marked:before {
  content: "\f59f";
}
.fa-map-marked-alt:before {
  content: "\f5a0";
}
.fa-map-marker:before {
  content: "\f041";
}
.fa-map-marker-alt:before {
  content: "\f3c5";
}
.fa-map-marker-alt-slash:before {
  content: "\f605";
}
.fa-map-marker-check:before {
  content: "\f606";
}
.fa-map-marker-edit:before {
  content: "\f607";
}
.fa-map-marker-exclamation:before {
  content: "\f608";
}
.fa-map-marker-minus:before {
  content: "\f609";
}
.fa-map-marker-plus:before {
  content: "\f60a";
}
.fa-map-marker-question:before {
  content: "\f60b";
}
.fa-map-marker-slash:before {
  content: "\f60c";
}
.fa-map-marker-smile:before {
  content: "\f60d";
}
.fa-map-marker-times:before {
  content: "\f60e";
}
.fa-map-pin:before {
  content: "\f276";
}
.fa-map-signs:before {
  content: "\f277";
}
.fa-markdown:before {
  content: "\f60f";
}
.fa-marker:before {
  content: "\f5a1";
}
.fa-mars:before {
  content: "\f222";
}
.fa-mars-double:before {
  content: "\f227";
}
.fa-mars-stroke:before {
  content: "\f229";
}
.fa-mars-stroke-h:before {
  content: "\f22b";
}
.fa-mars-stroke-v:before {
  content: "\f22a";
}
.fa-mastodon:before {
  content: "\f4f6";
}
.fa-maxcdn:before {
  content: "\f136";
}
.fa-medal:before {
  content: "\f5a2";
}
.fa-medapps:before {
  content: "\f3c6";
}
.fa-medium:before {
  content: "\f23a";
}
.fa-medium-m:before {
  content: "\f3c7";
}
.fa-medkit:before {
  content: "\f0fa";
}
.fa-medrt:before {
  content: "\f3c8";
}
.fa-meetup:before {
  content: "\f2e0";
}
.fa-megaphone:before {
  content: "\f675";
}
.fa-megaport:before {
  content: "\f5a3";
}
.fa-meh:before {
  content: "\f11a";
}
.fa-meh-blank:before {
  content: "\f5a4";
}
.fa-meh-rolling-eyes:before {
  content: "\f5a5";
}
.fa-memory:before {
  content: "\f538";
}
.fa-menorah:before {
  content: "\f676";
}
.fa-mercury:before {
  content: "\f223";
}
.fa-microchip:before {
  content: "\f2db";
}
.fa-microphone:before {
  content: "\f130";
}
.fa-microphone-alt:before {
  content: "\f3c9";
}
.fa-microphone-alt-slash:before {
  content: "\f539";
}
.fa-microphone-slash:before {
  content: "\f131";
}
.fa-microscope:before {
  content: "\f610";
}
.fa-microsoft:before {
  content: "\f3ca";
}
.fa-mind-share:before {
  content: "\f677";
}
.fa-minus:before {
  content: "\f068";
}
.fa-minus-circle:before {
  content: "\f056";
}
.fa-minus-hexagon:before {
  content: "\f307";
}
.fa-minus-octagon:before {
  content: "\f308";
}
.fa-minus-square:before {
  content: "\f146";
}
.fa-mix:before {
  content: "\f3cb";
}
.fa-mixcloud:before {
  content: "\f289";
}
.fa-mizuni:before {
  content: "\f3cc";
}
.fa-mobile:before {
  content: "\f10b";
}
.fa-mobile-alt:before {
  content: "\f3cd";
}
.fa-mobile-android:before {
  content: "\f3ce";
}
.fa-mobile-android-alt:before {
  content: "\f3cf";
}
.fa-modx:before {
  content: "\f285";
}
.fa-monero:before {
  content: "\f3d0";
}
.fa-money-bill:before {
  content: "\f0d6";
}
.fa-money-bill-alt:before {
  content: "\f3d1";
}
.fa-money-bill-wave:before {
  content: "\f53a";
}
.fa-money-bill-wave-alt:before {
  content: "\f53b";
}
.fa-money-check:before {
  content: "\f53c";
}
.fa-money-check-alt:before {
  content: "\f53d";
}
.fa-monitor-heart-rate:before {
  content: "\f611";
}
.fa-monument:before {
  content: "\f5a6";
}
.fa-moon:before {
  content: "\f186";
}
.fa-mortar-pestle:before {
  content: "\f5a7";
}
.fa-mosque:before {
  content: "\f678";
}
.fa-motorcycle:before {
  content: "\f21c";
}
.fa-mouse-pointer:before {
  content: "\f245";
}
.fa-music:before {
  content: "\f001";
}
.fa-napster:before {
  content: "\f3d2";
}
.fa-neos:before {
  content: "\f612";
}
.fa-neuter:before {
  content: "\f22c";
}
.fa-newspaper:before {
  content: "\f1ea";
}
.fa-nimblr:before {
  content: "\f5a8";
}
.fa-nintendo-switch:before {
  content: "\f418";
}
.fa-node:before {
  content: "\f419";
}
.fa-node-js:before {
  content: "\f3d3";
}
.fa-not-equal:before {
  content: "\f53e";
}
.fa-notes-medical:before {
  content: "\f481";
}
.fa-npm:before {
  content: "\f3d4";
}
.fa-ns8:before {
  content: "\f3d5";
}
.fa-nutritionix:before {
  content: "\f3d6";
}
.fa-object-group:before {
  content: "\f247";
}
.fa-object-ungroup:before {
  content: "\f248";
}
.fa-octagon:before {
  content: "\f306";
}
.fa-odnoklassniki:before {
  content: "\f263";
}
.fa-odnoklassniki-square:before {
  content: "\f264";
}
.fa-oil-can:before {
  content: "\f613";
}
.fa-oil-temp:before {
  content: "\f614";
}
.fa-old-republic:before {
  content: "\f510";
}
.fa-om:before {
  content: "\f679";
}
.fa-omega:before {
  content: "\f67a";
}
.fa-opencart:before {
  content: "\f23d";
}
.fa-openid:before {
  content: "\f19b";
}
.fa-opera:before {
  content: "\f26a";
}
.fa-optin-monster:before {
  content: "\f23c";
}
.fa-osi:before {
  content: "\f41a";
}
.fa-outdent:before {
  content: "\f03b";
}
.fa-page4:before {
  content: "\f3d7";
}
.fa-pagelines:before {
  content: "\f18c";
}
.fa-paint-brush:before {
  content: "\f1fc";
}
.fa-paint-brush-alt:before {
  content: "\f5a9";
}
.fa-paint-roller:before {
  content: "\f5aa";
}
.fa-palette:before {
  content: "\f53f";
}
.fa-palfed:before {
  content: "\f3d8";
}
.fa-pallet:before {
  content: "\f482";
}
.fa-pallet-alt:before {
  content: "\f483";
}
.fa-paper-plane:before {
  content: "\f1d8";
}
.fa-paperclip:before {
  content: "\f0c6";
}
.fa-parachute-box:before {
  content: "\f4cd";
}
.fa-paragraph:before {
  content: "\f1dd";
}
.fa-parking:before {
  content: "\f540";
}
.fa-parking-circle:before {
  content: "\f615";
}
.fa-parking-circle-slash:before {
  content: "\f616";
}
.fa-parking-slash:before {
  content: "\f617";
}
.fa-passport:before {
  content: "\f5ab";
}
.fa-pastafarianism:before {
  content: "\f67b";
}
.fa-paste:before {
  content: "\f0ea";
}
.fa-patreon:before {
  content: "\f3d9";
}
.fa-pause:before {
  content: "\f04c";
}
.fa-pause-circle:before {
  content: "\f28b";
}
.fa-paw:before {
  content: "\f1b0";
}
.fa-paypal:before {
  content: "\f1ed";
}
.fa-peace:before {
  content: "\f67c";
}
.fa-pen:before {
  content: "\f304";
}
.fa-pen-alt:before {
  content: "\f305";
}
.fa-pen-fancy:before {
  content: "\f5ac";
}
.fa-pen-nib:before {
  content: "\f5ad";
}
.fa-pen-square:before {
  content: "\f14b";
}
.fa-pencil:before {
  content: "\f040";
}
.fa-pencil-alt:before {
  content: "\f303";
}
.fa-pencil-paintbrush:before {
  content: "\f618";
}
.fa-pencil-ruler:before {
  content: "\f5ae";
}
.fa-pennant:before {
  content: "\f456";
}
.fa-people-carry:before {
  content: "\f4ce";
}
.fa-percent:before {
  content: "\f295";
}
.fa-percentage:before {
  content: "\f541";
}
.fa-periscope:before {
  content: "\f3da";
}
.fa-person-carry:before {
  content: "\f4cf";
}
.fa-person-dolly:before {
  content: "\f4d0";
}
.fa-person-dolly-empty:before {
  content: "\f4d1";
}
.fa-phabricator:before {
  content: "\f3db";
}
.fa-phoenix-framework:before {
  content: "\f3dc";
}
.fa-phoenix-squadron:before {
  content: "\f511";
}
.fa-phone:before {
  content: "\f095";
}
.fa-phone-office:before {
  content: "\f67d";
}
.fa-phone-plus:before {
  content: "\f4d2";
}
.fa-phone-slash:before {
  content: "\f3dd";
}
.fa-phone-square:before {
  content: "\f098";
}
.fa-phone-volume:before {
  content: "\f2a0";
}
.fa-php:before {
  content: "\f457";
}
.fa-pi:before {
  content: "\f67e";
}
.fa-pied-piper:before {
  content: "\f2ae";
}
.fa-pied-piper-alt:before {
  content: "\f1a8";
}
.fa-pied-piper-hat:before {
  content: "\f4e5";
}
.fa-pied-piper-pp:before {
  content: "\f1a7";
}
.fa-piggy-bank:before {
  content: "\f4d3";
}
.fa-pills:before {
  content: "\f484";
}
.fa-pinterest:before {
  content: "\f0d2";
}
.fa-pinterest-p:before {
  content: "\f231";
}
.fa-pinterest-square:before {
  content: "\f0d3";
}
.fa-place-of-worship:before {
  content: "\f67f";
}
.fa-plane:before {
  content: "\f072";
}
.fa-plane-alt:before {
  content: "\f3de";
}
.fa-plane-arrival:before {
  content: "\f5af";
}
.fa-plane-departure:before {
  content: "\f5b0";
}
.fa-play:before {
  content: "\f04b";
}
.fa-play-circle:before {
  content: "\f144";
}
.fa-playstation:before {
  content: "\f3df";
}
.fa-plug:before {
  content: "\f1e6";
}
.fa-plus:before {
  content: "\f067";
}
.fa-plus-circle:before {
  content: "\f055";
}
.fa-plus-hexagon:before {
  content: "\f300";
}
.fa-plus-octagon:before {
  content: "\f301";
}
.fa-plus-square:before {
  content: "\f0fe";
}
.fa-podcast:before {
  content: "\f2ce";
}
.fa-podium:before {
  content: "\f680";
}
.fa-poll:before {
  content: "\f681";
}
.fa-poll-h:before {
  content: "\f682";
}
.fa-poo:before {
  content: "\f2fe";
}
.fa-poop:before {
  content: "\f619";
}
.fa-portrait:before {
  content: "\f3e0";
}
.fa-pound-sign:before {
  content: "\f154";
}
.fa-power-off:before {
  content: "\f011";
}
.fa-pray:before {
  content: "\f683";
}
.fa-praying-hands:before {
  content: "\f684";
}
.fa-prescription:before {
  content: "\f5b1";
}
.fa-prescription-bottle:before {
  content: "\f485";
}
.fa-prescription-bottle-alt:before {
  content: "\f486";
}
.fa-presentation:before {
  content: "\f685";
}
.fa-print:before {
  content: "\f02f";
}
.fa-print-slash:before {
  content: "\f686";
}
.fa-procedures:before {
  content: "\f487";
}
.fa-product-hunt:before {
  content: "\f288";
}
.fa-project-diagram:before {
  content: "\f542";
}
.fa-pushed:before {
  content: "\f3e1";
}
.fa-puzzle-piece:before {
  content: "\f12e";
}
.fa-python:before {
  content: "\f3e2";
}
.fa-qq:before {
  content: "\f1d6";
}
.fa-qrcode:before {
  content: "\f029";
}
.fa-question:before {
  content: "\f128";
}
.fa-question-circle:before {
  content: "\f059";
}
.fa-question-square:before {
  content: "\f2fd";
}
.fa-quidditch:before {
  content: "\f458";
}
.fa-quinscape:before {
  content: "\f459";
}
.fa-quora:before {
  content: "\f2c4";
}
.fa-quote-left:before {
  content: "\f10d";
}
.fa-quote-right:before {
  content: "\f10e";
}
.fa-quran:before {
  content: "\f687";
}
.fa-r-project:before {
  content: "\f4f7";
}
.fa-racquet:before {
  content: "\f45a";
}
.fa-ramp-loading:before {
  content: "\f4d4";
}
.fa-random:before {
  content: "\f074";
}
.fa-ravelry:before {
  content: "\f2d9";
}
.fa-react:before {
  content: "\f41b";
}
.fa-readme:before {
  content: "\f4d5";
}
.fa-rebel:before {
  content: "\f1d0";
}
.fa-receipt:before {
  content: "\f543";
}
.fa-rectangle-landscape:before {
  content: "\f2fa";
}
.fa-rectangle-portrait:before {
  content: "\f2fb";
}
.fa-rectangle-wide:before {
  content: "\f2fc";
}
.fa-recycle:before {
  content: "\f1b8";
}
.fa-red-river:before {
  content: "\f3e3";
}
.fa-reddit:before {
  content: "\f1a1";
}
.fa-reddit-alien:before {
  content: "\f281";
}
.fa-reddit-square:before {
  content: "\f1a2";
}
.fa-redo:before {
  content: "\f01e";
}
.fa-redo-alt:before {
  content: "\f2f9";
}
.fa-registered:before {
  content: "\f25d";
}
.fa-rendact:before {
  content: "\f3e4";
}
.fa-renren:before {
  content: "\f18b";
}
.fa-repeat:before {
  content: "\f363";
}
.fa-repeat-1:before {
  content: "\f365";
}
.fa-repeat-1-alt:before {
  content: "\f366";
}
.fa-repeat-alt:before {
  content: "\f364";
}
.fa-reply:before {
  content: "\f3e5";
}
.fa-reply-all:before {
  content: "\f122";
}
.fa-replyd:before {
  content: "\f3e6";
}
.fa-researchgate:before {
  content: "\f4f8";
}
.fa-resolving:before {
  content: "\f3e7";
}
.fa-retweet:before {
  content: "\f079";
}
.fa-retweet-alt:before {
  content: "\f361";
}
.fa-rev:before {
  content: "\f5b2";
}
.fa-ribbon:before {
  content: "\f4d6";
}
.fa-road:before {
  content: "\f018";
}
.fa-robot:before {
  content: "\f544";
}
.fa-rocket:before {
  content: "\f135";
}
.fa-rocketchat:before {
  content: "\f3e8";
}
.fa-rockrms:before {
  content: "\f3e9";
}
.fa-route:before {
  content: "\f4d7";
}
.fa-route-highway:before {
  content: "\f61a";
}
.fa-route-interstate:before {
  content: "\f61b";
}
.fa-rss:before {
  content: "\f09e";
}
.fa-rss-square:before {
  content: "\f143";
}
.fa-ruble-sign:before {
  content: "\f158";
}
.fa-ruler:before {
  content: "\f545";
}
.fa-ruler-combined:before {
  content: "\f546";
}
.fa-ruler-horizontal:before {
  content: "\f547";
}
.fa-ruler-triangle:before {
  content: "\f61c";
}
.fa-ruler-vertical:before {
  content: "\f548";
}
.fa-rupee-sign:before {
  content: "\f156";
}
.fa-sad-cry:before {
  content: "\f5b3";
}
.fa-sad-tear:before {
  content: "\f5b4";
}
.fa-safari:before {
  content: "\f267";
}
.fa-sass:before {
  content: "\f41e";
}
.fa-save:before {
  content: "\f0c7";
}
.fa-scalpel:before {
  content: "\f61d";
}
.fa-scalpel-path:before {
  content: "\f61e";
}
.fa-scanner:before {
  content: "\f488";
}
.fa-scanner-keyboard:before {
  content: "\f489";
}
.fa-scanner-touchscreen:before {
  content: "\f48a";
}
.fa-schlix:before {
  content: "\f3ea";
}
.fa-school:before {
  content: "\f549";
}
.fa-screwdriver:before {
  content: "\f54a";
}
.fa-scribd:before {
  content: "\f28a";
}
.fa-scrubber:before {
  content: "\f2f8";
}
.fa-search:before {
  content: "\f002";
}
.fa-search-dollar:before {
  content: "\f688";
}
.fa-search-location:before {
  content: "\f689";
}
.fa-search-minus:before {
  content: "\f010";
}
.fa-search-plus:before {
  content: "\f00e";
}
.fa-searchengin:before {
  content: "\f3eb";
}
.fa-seedling:before {
  content: "\f4d8";
}
.fa-sellcast:before {
  content: "\f2da";
}
.fa-sellsy:before {
  content: "\f213";
}
.fa-server:before {
  content: "\f233";
}
.fa-servicestack:before {
  content: "\f3ec";
}
.fa-shapes:before {
  content: "\f61f";
}
.fa-share:before {
  content: "\f064";
}
.fa-share-all:before {
  content: "\f367";
}
.fa-share-alt:before {
  content: "\f1e0";
}
.fa-share-alt-square:before {
  content: "\f1e1";
}
.fa-share-square:before {
  content: "\f14d";
}
.fa-shekel-sign:before {
  content: "\f20b";
}
.fa-shield:before {
  content: "\f132";
}
.fa-shield-alt:before {
  content: "\f3ed";
}
.fa-shield-check:before {
  content: "\f2f7";
}
.fa-ship:before {
  content: "\f21a";
}
.fa-shipping-fast:before {
  content: "\f48b";
}
.fa-shipping-timed:before {
  content: "\f48c";
}
.fa-shirtsinbulk:before {
  content: "\f214";
}
.fa-shoe-prints:before {
  content: "\f54b";
}
.fa-shopping-bag:before {
  content: "\f290";
}
.fa-shopping-basket:before {
  content: "\f291";
}
.fa-shopping-cart:before {
  content: "\f07a";
}
.fa-shopware:before {
  content: "\f5b5";
}
.fa-shower:before {
  content: "\f2cc";
}
.fa-shredder:before {
  content: "\f68a";
}
.fa-shuttle-van:before {
  content: "\f5b6";
}
.fa-shuttlecock:before {
  content: "\f45b";
}
.fa-sigma:before {
  content: "\f68b";
}
.fa-sign:before {
  content: "\f4d9";
}
.fa-sign-in:before {
  content: "\f090";
}
.fa-sign-in-alt:before {
  content: "\f2f6";
}
.fa-sign-language:before {
  content: "\f2a7";
}
.fa-sign-out:before {
  content: "\f08b";
}
.fa-sign-out-alt:before {
  content: "\f2f5";
}
.fa-signal:before {
  content: "\f012";
}
.fa-signal-1:before {
  content: "\f68c";
}
.fa-signal-2:before {
  content: "\f68d";
}
.fa-signal-3:before {
  content: "\f68e";
}
.fa-signal-4:before {
  content: "\f68f";
}
.fa-signal-alt:before {
  content: "\f690";
}
.fa-signal-alt-1:before {
  content: "\f691";
}
.fa-signal-alt-2:before {
  content: "\f692";
}
.fa-signal-alt-3:before {
  content: "\f693";
}
.fa-signal-alt-slash:before {
  content: "\f694";
}
.fa-signal-slash:before {
  content: "\f695";
}
.fa-signature:before {
  content: "\f5b7";
}
.fa-simplybuilt:before {
  content: "\f215";
}
.fa-sistrix:before {
  content: "\f3ee";
}
.fa-sitemap:before {
  content: "\f0e8";
}
.fa-sith:before {
  content: "\f512";
}
.fa-skeleton:before {
  content: "\f620";
}
.fa-skull:before {
  content: "\f54c";
}
.fa-skyatlas:before {
  content: "\f216";
}
.fa-skype:before {
  content: "\f17e";
}
.fa-slack:before {
  content: "\f198";
}
.fa-slack-hash:before {
  content: "\f3ef";
}
.fa-sliders-h:before {
  content: "\f1de";
}
.fa-sliders-h-square:before {
  content: "\f3f0";
}
.fa-sliders-v:before {
  content: "\f3f1";
}
.fa-sliders-v-square:before {
  content: "\f3f2";
}
.fa-slideshare:before {
  content: "\f1e7";
}
.fa-smile:before {
  content: "\f118";
}
.fa-smile-beam:before {
  content: "\f5b8";
}
.fa-smile-plus:before {
  content: "\f5b9";
}
.fa-smile-wink:before {
  content: "\f4da";
}
.fa-smoking:before {
  content: "\f48d";
}
.fa-smoking-ban:before {
  content: "\f54d";
}
.fa-snapchat:before {
  content: "\f2ab";
}
.fa-snapchat-ghost:before {
  content: "\f2ac";
}
.fa-snapchat-square:before {
  content: "\f2ad";
}
.fa-snowflake:before {
  content: "\f2dc";
}
.fa-socks:before {
  content: "\f696";
}
.fa-solar-panel:before {
  content: "\f5ba";
}
.fa-sort:before {
  content: "\f0dc";
}
.fa-sort-alpha-down:before {
  content: "\f15d";
}
.fa-sort-alpha-up:before {
  content: "\f15e";
}
.fa-sort-amount-down:before {
  content: "\f160";
}
.fa-sort-amount-up:before {
  content: "\f161";
}
.fa-sort-down:before {
  content: "\f0dd";
}
.fa-sort-numeric-down:before {
  content: "\f162";
}
.fa-sort-numeric-up:before {
  content: "\f163";
}
.fa-sort-up:before {
  content: "\f0de";
}
.fa-soundcloud:before {
  content: "\f1be";
}
.fa-spa:before {
  content: "\f5bb";
}
.fa-space-shuttle:before {
  content: "\f197";
}
.fa-spade:before {
  content: "\f2f4";
}
.fa-speakap:before {
  content: "\f3f3";
}
.fa-spinner:before {
  content: "\f110";
}
.fa-spinner-third:before {
  content: "\f3f4";
}
.fa-splotch:before {
  content: "\f5bc";
}
.fa-spotify:before {
  content: "\f1bc";
}
.fa-spray-can:before {
  content: "\f5bd";
}
.fa-square:before {
  content: "\f0c8";
}
.fa-square-full:before {
  content: "\f45c";
}
.fa-square-root:before {
  content: "\f697";
}
.fa-square-root-alt:before {
  content: "\f698";
}
.fa-squarespace:before {
  content: "\f5be";
}
.fa-stack-exchange:before {
  content: "\f18d";
}
.fa-stack-overflow:before {
  content: "\f16c";
}
.fa-stamp:before {
  content: "\f5bf";
}
.fa-star:before {
  content: "\f005";
}
.fa-star-and-crescent:before {
  content: "\f699";
}
.fa-star-exclamation:before {
  content: "\f2f3";
}
.fa-star-half:before {
  content: "\f089";
}
.fa-star-half-alt:before {
  content: "\f5c0";
}
.fa-star-of-david:before {
  content: "\f69a";
}
.fa-star-of-life:before {
  content: "\f621";
}
.fa-staylinked:before {
  content: "\f3f5";
}
.fa-steam:before {
  content: "\f1b6";
}
.fa-steam-square:before {
  content: "\f1b7";
}
.fa-steam-symbol:before {
  content: "\f3f6";
}
.fa-steering-wheel:before {
  content: "\f622";
}
.fa-step-backward:before {
  content: "\f048";
}
.fa-step-forward:before {
  content: "\f051";
}
.fa-stethoscope:before {
  content: "\f0f1";
}
.fa-sticker-mule:before {
  content: "\f3f7";
}
.fa-sticky-note:before {
  content: "\f249";
}
.fa-stomach:before {
  content: "\f623";
}
.fa-stop:before {
  content: "\f04d";
}
.fa-stop-circle:before {
  content: "\f28d";
}
.fa-stopwatch:before {
  content: "\f2f2";
}
.fa-store:before {
  content: "\f54e";
}
.fa-store-alt:before {
  content: "\f54f";
}
.fa-strava:before {
  content: "\f428";
}
.fa-stream:before {
  content: "\f550";
}
.fa-street-view:before {
  content: "\f21d";
}
.fa-strikethrough:before {
  content: "\f0cc";
}
.fa-stripe:before {
  content: "\f429";
}
.fa-stripe-s:before {
  content: "\f42a";
}
.fa-stroopwafel:before {
  content: "\f551";
}
.fa-studiovinari:before {
  content: "\f3f8";
}
.fa-stumbleupon:before {
  content: "\f1a4";
}
.fa-stumbleupon-circle:before {
  content: "\f1a3";
}
.fa-subscript:before {
  content: "\f12c";
}
.fa-subway:before {
  content: "\f239";
}
.fa-suitcase:before {
  content: "\f0f2";
}
.fa-suitcase-rolling:before {
  content: "\f5c1";
}
.fa-sun:before {
  content: "\f185";
}
.fa-superpowers:before {
  content: "\f2dd";
}
.fa-superscript:before {
  content: "\f12b";
}
.fa-supple:before {
  content: "\f3f9";
}
.fa-surprise:before {
  content: "\f5c2";
}
.fa-swatchbook:before {
  content: "\f5c3";
}
.fa-swimmer:before {
  content: "\f5c4";
}
.fa-swimming-pool:before {
  content: "\f5c5";
}
.fa-synagogue:before {
  content: "\f69b";
}
.fa-sync:before {
  content: "\f021";
}
.fa-sync-alt:before {
  content: "\f2f1";
}
.fa-syringe:before {
  content: "\f48e";
}
.fa-table:before {
  content: "\f0ce";
}
.fa-table-tennis:before {
  content: "\f45d";
}
.fa-tablet:before {
  content: "\f10a";
}
.fa-tablet-alt:before {
  content: "\f3fa";
}
.fa-tablet-android:before {
  content: "\f3fb";
}
.fa-tablet-android-alt:before {
  content: "\f3fc";
}
.fa-tablet-rugged:before {
  content: "\f48f";
}
.fa-tablets:before {
  content: "\f490";
}
.fa-tachometer:before {
  content: "\f0e4";
}
.fa-tachometer-alt:before {
  content: "\f3fd";
}
.fa-tachometer-alt-average:before {
  content: "\f624";
}
.fa-tachometer-alt-fast:before {
  content: "\f625";
}
.fa-tachometer-alt-fastest:before {
  content: "\f626";
}
.fa-tachometer-alt-slow:before {
  content: "\f627";
}
.fa-tachometer-alt-slowest:before {
  content: "\f628";
}
.fa-tachometer-average:before {
  content: "\f629";
}
.fa-tachometer-fast:before {
  content: "\f62a";
}
.fa-tachometer-fastest:before {
  content: "\f62b";
}
.fa-tachometer-slow:before {
  content: "\f62c";
}
.fa-tachometer-slowest:before {
  content: "\f62d";
}
.fa-tag:before {
  content: "\f02b";
}
.fa-tags:before {
  content: "\f02c";
}
.fa-tally:before {
  content: "\f69c";
}
.fa-tape:before {
  content: "\f4db";
}
.fa-tasks:before {
  content: "\f0ae";
}
.fa-taxi:before {
  content: "\f1ba";
}
.fa-teamspeak:before {
  content: "\f4f9";
}
.fa-teeth:before {
  content: "\f62e";
}
.fa-teeth-open:before {
  content: "\f62f";
}
.fa-telegram:before {
  content: "\f2c6";
}
.fa-telegram-plane:before {
  content: "\f3fe";
}
.fa-tencent-weibo:before {
  content: "\f1d5";
}
.fa-tennis-ball:before {
  content: "\f45e";
}
.fa-terminal:before {
  content: "\f120";
}
.fa-text-height:before {
  content: "\f034";
}
.fa-text-width:before {
  content: "\f035";
}
.fa-th:before {
  content: "\f00a";
}
.fa-th-large:before {
  content: "\f009";
}
.fa-th-list:before {
  content: "\f00b";
}
.fa-the-red-yeti:before {
  content: "\f69d";
}
.fa-theater-masks:before {
  content: "\f630";
}
.fa-themeco:before {
  content: "\f5c6";
}
.fa-themeisle:before {
  content: "\f2b2";
}
.fa-thermometer:before {
  content: "\f491";
}
.fa-thermometer-empty:before {
  content: "\f2cb";
}
.fa-thermometer-full:before {
  content: "\f2c7";
}
.fa-thermometer-half:before {
  content: "\f2c9";
}
.fa-thermometer-quarter:before {
  content: "\f2ca";
}
.fa-thermometer-three-quarters:before {
  content: "\f2c8";
}
.fa-theta:before {
  content: "\f69e";
}
.fa-thumbs-down:before {
  content: "\f165";
}
.fa-thumbs-up:before {
  content: "\f164";
}
.fa-thumbtack:before {
  content: "\f08d";
}
.fa-ticket:before {
  content: "\f145";
}
.fa-ticket-alt:before {
  content: "\f3ff";
}
.fa-tilde:before {
  content: "\f69f";
}
.fa-times:before {
  content: "\f00d";
}
.fa-times-circle:before {
  content: "\f057";
}
.fa-times-hexagon:before {
  content: "\f2ee";
}
.fa-times-octagon:before {
  content: "\f2f0";
}
.fa-times-square:before {
  content: "\f2d3";
}
.fa-tint:before {
  content: "\f043";
}
.fa-tint-slash:before {
  content: "\f5c7";
}
.fa-tire:before {
  content: "\f631";
}
.fa-tire-flat:before {
  content: "\f632";
}
.fa-tire-pressure-warning:before {
  content: "\f633";
}
.fa-tire-rugged:before {
  content: "\f634";
}
.fa-tired:before {
  content: "\f5c8";
}
.fa-toggle-off:before {
  content: "\f204";
}
.fa-toggle-on:before {
  content: "\f205";
}
.fa-toolbox:before {
  content: "\f552";
}
.fa-tooth:before {
  content: "\f5c9";
}
.fa-toothbrush:before {
  content: "\f635";
}
.fa-torah:before {
  content: "\f6a0";
}
.fa-torii-gate:before {
  content: "\f6a1";
}
.fa-trade-federation:before {
  content: "\f513";
}
.fa-trademark:before {
  content: "\f25c";
}
.fa-traffic-cone:before {
  content: "\f636";
}
.fa-traffic-light:before {
  content: "\f637";
}
.fa-traffic-light-go:before {
  content: "\f638";
}
.fa-traffic-light-slow:before {
  content: "\f639";
}
.fa-traffic-light-stop:before {
  content: "\f63a";
}
.fa-train:before {
  content: "\f238";
}
.fa-transgender:before {
  content: "\f224";
}
.fa-transgender-alt:before {
  content: "\f225";
}
.fa-trash:before {
  content: "\f1f8";
}
.fa-trash-alt:before {
  content: "\f2ed";
}
.fa-tree:before {
  content: "\f1bb";
}
.fa-tree-alt:before {
  content: "\f400";
}
.fa-trello:before {
  content: "\f181";
}
.fa-triangle:before {
  content: "\f2ec";
}
.fa-tripadvisor:before {
  content: "\f262";
}
.fa-trophy:before {
  content: "\f091";
}
.fa-trophy-alt:before {
  content: "\f2eb";
}
.fa-truck:before {
  content: "\f0d1";
}
.fa-truck-container:before {
  content: "\f4dc";
}
.fa-truck-couch:before {
  content: "\f4dd";
}
.fa-truck-loading:before {
  content: "\f4de";
}
.fa-truck-monster:before {
  content: "\f63b";
}
.fa-truck-moving:before {
  content: "\f4df";
}
.fa-truck-pickup:before {
  content: "\f63c";
}
.fa-truck-ramp:before {
  content: "\f4e0";
}
.fa-tshirt:before {
  content: "\f553";
}
.fa-tty:before {
  content: "\f1e4";
}
.fa-tumblr:before {
  content: "\f173";
}
.fa-tumblr-square:before {
  content: "\f174";
}
.fa-tv:before {
  content: "\f26c";
}
.fa-tv-retro:before {
  content: "\f401";
}
.fa-twitch:before {
  content: "\f1e8";
}
.fa-twitter:before {
  content: "\f099";
}
.fa-twitter-square:before {
  content: "\f081";
}
.fa-typo3:before {
  content: "\f42b";
}
.fa-uber:before {
  content: "\f402";
}
.fa-uikit:before {
  content: "\f403";
}
.fa-umbrella:before {
  content: "\f0e9";
}
.fa-umbrella-beach:before {
  content: "\f5ca";
}
.fa-underline:before {
  content: "\f0cd";
}
.fa-undo:before {
  content: "\f0e2";
}
.fa-undo-alt:before {
  content: "\f2ea";
}
.fa-union:before {
  content: "\f6a2";
}
.fa-uniregistry:before {
  content: "\f404";
}
.fa-universal-access:before {
  content: "\f29a";
}
.fa-university:before {
  content: "\f19c";
}
.fa-unlink:before {
  content: "\f127";
}
.fa-unlock:before {
  content: "\f09c";
}
.fa-unlock-alt:before {
  content: "\f13e";
}
.fa-untappd:before {
  content: "\f405";
}
.fa-upload:before {
  content: "\f093";
}
.fa-usb:before {
  content: "\f287";
}
.fa-usd-circle:before {
  content: "\f2e8";
}
.fa-usd-square:before {
  content: "\f2e9";
}
.fa-user:before {
  content: "\f007";
}
.fa-user-alt:before {
  content: "\f406";
}
.fa-user-alt-slash:before {
  content: "\f4fa";
}
.fa-user-astronaut:before {
  content: "\f4fb";
}
.fa-user-chart:before {
  content: "\f6a3";
}
.fa-user-check:before {
  content: "\f4fc";
}
.fa-user-circle:before {
  content: "\f2bd";
}
.fa-user-clock:before {
  content: "\f4fd";
}
.fa-user-cog:before {
  content: "\f4fe";
}
.fa-user-crown:before {
  content: "\f6a4";
}
.fa-user-edit:before {
  content: "\f4ff";
}
.fa-user-friends:before {
  content: "\f500";
}
.fa-user-graduate:before {
  content: "\f501";
}
.fa-user-lock:before {
  content: "\f502";
}
.fa-user-md:before {
  content: "\f0f0";
}
.fa-user-minus:before {
  content: "\f503";
}
.fa-user-ninja:before {
  content: "\f504";
}
.fa-user-plus:before {
  content: "\f234";
}
.fa-user-secret:before {
  content: "\f21b";
}
.fa-user-shield:before {
  content: "\f505";
}
.fa-user-slash:before {
  content: "\f506";
}
.fa-user-tag:before {
  content: "\f507";
}
.fa-user-tie:before {
  content: "\f508";
}
.fa-user-times:before {
  content: "\f235";
}
.fa-users:before {
  content: "\f0c0";
}
.fa-users-class:before {
  content: "\f63d";
}
.fa-users-cog:before {
  content: "\f509";
}
.fa-users-crown:before {
  content: "\f6a5";
}
.fa-ussunnah:before {
  content: "\f407";
}
.fa-utensil-fork:before {
  content: "\f2e3";
}
.fa-utensil-knife:before {
  content: "\f2e4";
}
.fa-utensil-spoon:before {
  content: "\f2e5";
}
.fa-utensils:before {
  content: "\f2e7";
}
.fa-utensils-alt:before {
  content: "\f2e6";
}
.fa-vaadin:before {
  content: "\f408";
}
.fa-value-absolute:before {
  content: "\f6a6";
}
.fa-vector-square:before {
  content: "\f5cb";
}
.fa-venus:before {
  content: "\f221";
}
.fa-venus-double:before {
  content: "\f226";
}
.fa-venus-mars:before {
  content: "\f228";
}
.fa-viacoin:before {
  content: "\f237";
}
.fa-viadeo:before {
  content: "\f2a9";
}
.fa-viadeo-square:before {
  content: "\f2aa";
}
.fa-vial:before {
  content: "\f492";
}
.fa-vials:before {
  content: "\f493";
}
.fa-viber:before {
  content: "\f409";
}
.fa-video:before {
  content: "\f03d";
}
.fa-video-plus:before {
  content: "\f4e1";
}
.fa-video-slash:before {
  content: "\f4e2";
}
.fa-vihara:before {
  content: "\f6a7";
}
.fa-vimeo:before {
  content: "\f40a";
}
.fa-vimeo-square:before {
  content: "\f194";
}
.fa-vimeo-v:before {
  content: "\f27d";
}
.fa-vine:before {
  content: "\f1ca";
}
.fa-vk:before {
  content: "\f189";
}
.fa-vnv:before {
  content: "\f40b";
}
.fa-volleyball-ball:before {
  content: "\f45f";
}
.fa-volume:before {
  content: "\f6a8";
}
.fa-volume-down:before {
  content: "\f027";
}
.fa-volume-mute:before {
  content: "\f6a9";
}
.fa-volume-off:before {
  content: "\f026";
}
.fa-volume-slash:before {
  content: "\f2e2";
}
.fa-volume-up:before {
  content: "\f028";
}
.fa-vuejs:before {
  content: "\f41f";
}
.fa-walking:before {
  content: "\f554";
}
.fa-wallet:before {
  content: "\f555";
}
.fa-warehouse:before {
  content: "\f494";
}
.fa-warehouse-alt:before {
  content: "\f495";
}
.fa-watch:before {
  content: "\f2e1";
}
.fa-watch-fitness:before {
  content: "\f63e";
}
.fa-weebly:before {
  content: "\f5cc";
}
.fa-weibo:before {
  content: "\f18a";
}
.fa-weight:before {
  content: "\f496";
}
.fa-weight-hanging:before {
  content: "\f5cd";
}
.fa-weixin:before {
  content: "\f1d7";
}
.fa-whatsapp:before {
  content: "\f232";
}
.fa-whatsapp-square:before {
  content: "\f40c";
}
.fa-wheelchair:before {
  content: "\f193";
}
.fa-whistle:before {
  content: "\f460";
}
.fa-whmcs:before {
  content: "\f40d";
}
.fa-wifi:before {
  content: "\f1eb";
}
.fa-wifi-1:before {
  content: "\f6aa";
}
.fa-wifi-2:before {
  content: "\f6ab";
}
.fa-wifi-slash:before {
  content: "\f6ac";
}
.fa-wikipedia-w:before {
  content: "\f266";
}
.fa-window:before {
  content: "\f40e";
}
.fa-window-alt:before {
  content: "\f40f";
}
.fa-window-close:before {
  content: "\f410";
}
.fa-window-maximize:before {
  content: "\f2d0";
}
.fa-window-minimize:before {
  content: "\f2d1";
}
.fa-window-restore:before {
  content: "\f2d2";
}
.fa-windows:before {
  content: "\f17a";
}
.fa-wine-glass:before {
  content: "\f4e3";
}
.fa-wine-glass-alt:before {
  content: "\f5ce";
}
.fa-wix:before {
  content: "\f5cf";
}
.fa-wolf-pack-battalion:before {
  content: "\f514";
}
.fa-won-sign:before {
  content: "\f159";
}
.fa-wordpress:before {
  content: "\f19a";
}
.fa-wordpress-simple:before {
  content: "\f411";
}
.fa-wpbeginner:before {
  content: "\f297";
}
.fa-wpexplorer:before {
  content: "\f2de";
}
.fa-wpforms:before {
  content: "\f298";
}
.fa-wrench:before {
  content: "\f0ad";
}
.fa-x-ray:before {
  content: "\f497";
}
.fa-xbox:before {
  content: "\f412";
}
.fa-xing:before {
  content: "\f168";
}
.fa-xing-square:before {
  content: "\f169";
}
.fa-y-combinator:before {
  content: "\f23b";
}
.fa-yahoo:before {
  content: "\f19e";
}
.fa-yandex:before {
  content: "\f413";
}
.fa-yandex-international:before {
  content: "\f414";
}
.fa-yelp:before {
  content: "\f1e9";
}
.fa-yen-sign:before {
  content: "\f157";
}
.fa-yin-yang:before {
  content: "\f6ad";
}
.fa-yoast:before {
  content: "\f2b1";
}
.fa-youtube:before {
  content: "\f167";
}
.fa-youtube-square:before {
  content: "\f431";
}
.fa-zhihu:before {
  content: "\f63f";
}
.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
/*!
 * Font Awesome Pro 5.3.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  src: url('../../../fonts/fa-regular-400.eot');
  src: url('../../../fonts/fa-regular-400.eot?#iefix') format('embedded-opentype'), url('../../../fonts/fa-regular-400.woff2') format('woff2'), url('../../../fonts/fa-regular-400.woff') format('woff'), url('../../../fonts/fa-regular-400.ttf') format('truetype'), url('../../../fonts/fa-regular-400.svg#fontawesome') format('svg');
}
.far {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}
/*!
 * Font Awesome Pro 5.3.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 900;
  src: url('../../../fonts/fa-solid-900.eot');
  src: url('../../../fonts/fa-solid-900.eot?#iefix') format('embedded-opentype'), url('../../../fonts/fa-solid-900.woff2') format('woff2'), url('../../../fonts/fa-solid-900.woff') format('woff'), url('../../../fonts/fa-solid-900.ttf') format('truetype'), url('../../../fonts/fa-solid-900.svg#fontawesome') format('svg');
}
.fa,
.fas {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
}
/*!
 * Font Awesome Pro 5.3.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  src: url('../../../fonts/fa-light-300.eot');
  src: url('../../../fonts/fa-light-300.eot?#iefix') format('embedded-opentype'), url('../../../fonts/fa-light-300.woff2') format('woff2'), url('../../../fonts/fa-light-300.woff') format('woff'), url('../../../fonts/fa-light-300.ttf') format('truetype'), url('../../../fonts/fa-light-300.svg#fontawesome') format('svg');
}
.fal {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
}
/*!
 * Font Awesome Pro 5.3.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  src: url('../../../fonts/fa-brands-400.eot');
  src: url('../../../fonts/fa-brands-400.eot?#iefix') format('embedded-opentype'), url('../../../fonts/fa-brands-400.woff2') format('woff2'), url('../../../fonts/fa-brands-400.woff') format('woff'), url('../../../fonts/fa-brands-400.ttf') format('truetype'), url('../../../fonts/fa-brands-400.svg#fontawesome') format('svg');
}
.fab {
  font-family: 'Font Awesome 5 Brands';
}
html {
  background: #ffffff;
}
body {
  min-height: 100vh;
  height: auto;
  height: initial;
  position: relative;
  background: #f5f5f5;
  display: flex;
  flex-flow: column;
}
.progressColumn .progress {
  margin-bottom: 0;
  margin-top: 3px;
}
#menubar.mario {
  z-index: 1005;
}
#menubar .menubar-foot-panel.custom {
  background-color: #ffffff;
  padding: 16px 3px;
}
#menubar .menubar-foot-panel.custom img {
  padding-bottom: 10px;
}
.table .filters td {
  vertical-align: top;
}
.header-nav-profile > li {
  padding: 20px 0 7px 12px;
}
.header-nav-profile {
  margin-inline-end: 0;
  margin-inline-start: 0;
}
/*.form-group .form-control ~ label, .form-group > label, .form-group .control-label {
    font-size: 13px;
    opacity: 1;
}
.form-control {
    font-size: 13px;
}*/
.progress-bar {
  box-shadow: none;
}
/*#guide .form-group {
    margin-bottom: 5px;
}*/
#navigation .affix {
  top: 78px;
  max-width: 230px;
  transition: all 0.5s;
  -o-transition: all 0.5s;
  -webkit-transition: all 0.5s;
}
a.anchor {
  display: block;
  position: relative;
  top: -70px;
  visibility: hidden;
}
#toast-container > div {
  min-width: 400px;
}
/*.form-group .error ~ span.help-block
{
    color:#f44336;
}*/
blockquote footer::before,
blockquote small::before,
blockquote .small::before {
  content: "";
}
blockquote.no-margin {
  padding: 9px 19px;
}
blockquote small,
blockquote .small {
  line-height: 1.6;
}
.dashboard .affix {
  z-index: 999;
  top: 10px;
  inset-inline-end: 25px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.dashboard .affix-top {
  top: 15px;
}
#mario {
  opacity: 0.5;
}
#mario:hover {
  opacity: 1;
  transition: opacity 1s cubic-bezier(0.22, 0.99, 0.68, 0.98) 0.5s;
}
.mario .list .tile .tile-content {
  padding-inline-start: 12px;
}
.mario .list {
  margin: 0 0 12px;
}
.mario .gui-controls > li {
  margin-bottom: 2px;
}
#quick-menu {
  padding: 28px 0 39px;
}
#quick-menu a {
  padding: 8px 16px;
  font-size: 16px;
}
#quick-menu .dropdown-menu > li > a {
  padding: 0 16px;
}
#quick-menu .list .tile .tile-text {
  padding: 10px 0;
}
#quick-menu .list .tile .tile-icon {
  min-width: 36px;
}
.no-margin-bottom {
  margin-bottom: 0;
}
.no-padding-bottom {
  padding-bottom: 0;
}
.btn-gray-dark {
  color: #fff;
  background-color: #333333;
  border-color: #333333;
}
.btn-register {
  font-size: 14px;
}
.btn {
  letter-spacing: 0.4pt;
}
.modal-dialog {
  margin: 64px auto;
}
/* Resizable layer */
.modal.component-product-list-modal .modal-dialog.resizable-dialog .resizer {
  position: absolute;
  top: 0;
  bottom: 0;
  inset-inline-start: -12px;
  width: 12px;
  background: transparent;
  transition: all 0.3s;
  display: block;
  line-height: calc(100vh - 9.1rem);
  font-weight: 500;
  font-size: 1.325rem;
  color: transparent;
  text-align: center;
  z-index: 123457;
}
.modal.component-product-list-modal .modal-dialog.resizable-dialog .resizer span {
  display: block;
  height: 100%;
  background-image: linear-gradient(90deg, #efefef 20%, #333333 20%, #333333 40%, #efefef 40%, #efefef 60%, #333333 60%, #333333 80%, #efefef 80%, #efefef 100%);
  background-size: 9px 25px;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0;
}
.modal.component-product-list-modal .modal-dialog.resizable-dialog.resize-active .resizer,
.modal.component-product-list-modal .modal-dialog.resizable-dialog:active .resizer,
.modal.component-product-list-modal .modal-dialog.resizable-dialog:focus .resizer,
.modal.component-product-list-modal .modal-dialog.resizable-dialog:hover .resizer {
  background: #efefef;
  color: #b3b3b3;
  cursor: col-resize;
}
.modal.component-product-list-modal .modal-dialog.resizable-dialog.resize-active .resizer span,
.modal.component-product-list-modal .modal-dialog.resizable-dialog:active .resizer span,
.modal.component-product-list-modal .modal-dialog.resizable-dialog:focus .resizer span,
.modal.component-product-list-modal .modal-dialog.resizable-dialog:hover .resizer span {
  opacity: 1;
  cursor: col-resize;
}
.modal-content {
  border: none;
}
#demarche h6 {
  margin: 6px 0 0;
}
#demarche .plus {
  text-transform: none;
  font-size: 9px;
  width: 54px;
}
#home-spacer {
  margin: 0 0 17px;
}
.opacity-90 {
  opacity: 0.9;
}
#assistant ul.nav-tabs > li a {
  padding-inline-start: 50px;
}
#assistant ul.nav-tabs > li a {
  padding-inline-start: 50px;
}
#assistant ul.nav-tabs > li a:hover,
#assistant ul > li a:focus,
#assistant ul > li.active a {
  background-color: #A5A5A5;
  color: white;
  opacity: 1;
  border-radius: 0;
  border: 0;
}
/*.help-block {
    bottom: -15px;
    font-size: 10px;
}*/
.header-nav-profile .dropdown > a,
.header-nav-profile .dropdown.open > a {
  min-width: 180px;
  min-height: 64px;
  padding-block: 14px;
  padding-inline-start: 0;
  padding-inline-end: 8px;
}
.header-nav-profile .dropdown.open > a {
  margin-top: 0;
}
.header-nav-profile .dropdown .profile-info {
  padding: 0 13px 0 10px;
}
.header-nav-profile .dropdown > a:after {
  inset-inline-end: 9px;
  top: 24px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.header-nav-profile .dropdown.open > a:after {
  top: 24px;
}
.header-nav-profile .dropdown-menu > li > a {
  padding: 5px 10px;
}
.header-nav-profile .dropdown.open .dropdown-menu {
  margin-top: 0;
}
.margin-bottom {
  margin-bottom: 10px;
}
form.navbar-search .form-control {
  background-color: transparent;
}
.no-top-padding {
  padding-top: 0;
}
.top-padding {
  padding-top: 24px;
}
.padding-top-xs {
  padding-top: 6px;
}
#base {
  min-height: unset;
  min-height: initial;
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
}
#content {
  /*min-height: 100vh;*/
  padding-top: 0;
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
}
.secondary-menu {
  position: relative;
  width: 100%;
  margin: 0 auto;
  /*height: 32px;*/
  border-radius: 0;
}
.secondary-menu:after {
  display: block;
  background-color: #333;
}
/* End of Main navigation */
.navbar-search input.input-sm {
  height: 30px;
}
.step-header .drop-cap {
  float: left;
  margin: 0;
  font-size: 67px;
  line-height: 0.9;
  width: 45px;
}
.step-header .step-subtitle {
  font-size: 17px;
  margin-inline-start: 45px;
  line-height: 1.2;
  font-weight: normal;
  text-transform: none;
}
.imageAttachment .no-image {
  color: #777;
  font-weight: normal;
  font-size: 15px;
  padding-top: 20px;
  vertical-align: middle;
  display: block;
  text-align: center;
}
.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-inline-start: 0;
}
.radio-inline:not(:last-child),
.checkboxproduction -inline:not(:last-child) {
  margin-inline-end: 10px;
  top: 5px;
}
.radio-inline,
.checkbox-inline {
  top: 5px;
}
.form-field-type-htmlelement {
  margin-top: 5px;
}
.list .tile .checkbox-styled:not(ie8) input ~ span,
.list .tile .radio-styled:not(ie8) input ~ span {
  padding-inline-start: 24px;
}
#send .list .tile .radio-styled:not(ie8) label {
  padding-inline-start: 0;
}
#guide .radio-form .form-group {
  padding-top: 7px;
  padding-bottom: 7px;
  margin-top: 0;
  margin-bottom: 0;
}
#guide .radio-form .form-group:nth-child(n-1):after {
  background: none;
}
#guide .checkbox-styled:not(ie8),
#guide .radio-styled:not(ie8) {
  margin-top: 0;
  margin-bottom: 0;
}
#guide .radio label,
#guide .checkbox label {
  margin-bottom: 0;
}
.vertical-center {
  display: inline-block;
  vertical-align: middle;
  float: none;
}
table th {
  font-weight: bold;
}
.affixed-menu {
  top: 0;
  z-index: 6;
  width: 100%;
}
.affixed-menu-container {
  min-height: 58px;
}
.affix-menu-size-container {
  min-height: 76px;
}
.small-padding-top {
  padding: 12px 24px;
}
.small-padding {
  padding: 16px;
}
.input-group-addon {
  line-height: inherit;
}
/*textarea.form-control {
    padding: 4px 10px;
}*/
.bullet {
  width: 1.2em;
  height: 1.2em;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  font-size: 90%;
  line-height: 1.2;
  vertical-align: top;
}
.bullet-list {
  position: relative;
  top: 0;
  margin-inline-end: 10px;
}
.table thead > tr > th {
  color: #000000;
}
.no-padding-right {
  padding-inline-end: 0;
}
.list .tile div.tile-btn {
  display: block;
  padding-block: 16px;
  padding-inline-start: 0;
  padding-inline-end: 16px;
}
.list .tile .tile-text {
  padding: 16px 0;
}
.new span {
  color: white;
  text-decoration: none;
  padding: 3px 0 2px 5px;
  background: #b89367;
  position: relative;
  margin-inline-end: 15px;
  line-height: 26px;
}
.new span:after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-inline-start: 10px solid #b89367;
  position: absolute;
  top: 50%;
  margin-top: -9px;
  inset-inline-start: 100%;
  z-index: 2;
}
span.required-star:before {
  content: "*";
  color: red;
  font: inherit;
}
.has-success span.required-star:before {
  content: "\f299";
  color: inherit;
  display: inline-block;
  font: normal normal normal 14px/1 'Material Design Iconic Font';
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}
.has-error span.required-star:before {
  content: "\f0a1";
  color: inherit;
  display: inline-block;
  font: normal normal normal 14px/1 'Material Design Iconic Font';
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}
blockquote.withfooter {
  margin: 0;
  padding: 3px 0 3px 19px;
}
blockquote.small-padding {
  margin: 0;
  padding: 10px 18px;
}
#guide .form-group {
  margin-bottom: 12px;
}
.intl-tel-input {
  display: block;
  width: 100%;
}
a.btn-xs > i.md {
  vertical-align: middle;
}
.carousel-controls .carousel-control {
  background: #fff;
  font-size: 12px;
  width: 35px;
  height: 35px;
  top: 38px;
  margin-top: -27px;
  line-height: 35px;
  opacity: 1;
  text-shadow: none;
  z-index: 5;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
.carousel-controls .carousel-control.left {
  inset-inline-start: inherit;
  inset-inline-end: 0;
  margin-inline-end: 48px;
}
.carousel-controls .carousel-control.right {
  margin-inline-end: 11px;
}
/*
.carousel-controls .carousel-control:hover {
    background: #fff;
    color: #000;
}*/
.null {
  font-size: 0.9em;
  font-style: italic;
  opacity: 0.6;
  color: #FF1744;
}
table.th-width th {
  width: 50%;
}
.scroll-height {
  height: 700px;
}
.iframe {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top;
  display: block;
  width: 100%;
  height: 100%;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  color: #979B98;
}
.card-head .tools form,
.card-head .tools form .form-group,
.card-head .tools form .checkbox.checkbox-styled {
  margin-bottom: 0;
}
.fileupload-progress .progress {
  margin-bottom: 8px;
}
.form-horizontal .control-label.text-left {
  text-align: start;
}
.btn-margin-top {
  margin-top: 25px;
}
.label.label-xs {
  display: inline;
  padding: 0.1em 0.3em 0.1em;
  font-size: 70%;
  font-weight: bold;
  vertical-align: middle;
}
.table .title {
  padding-inline-start: 24px;
}
.table .summary-line .title {
  padding-inline-start: 12px;
  font-weight: bold;
  text-transform: uppercase;
}
.header-nav-profile .dropdown-menu {
  padding: 0;
  border-radius: 1px;
}
#entreprise-grid tbody > tr.no-pointer:hover {
  cursor: inherit;
}
.form-inline.editableform .btn {
  margin-top: inherit;
  margin-inline-end: inherit;
}
.btn-success.disabled,
.btn-success[disabled],
.btn-success[disabled]:focus,
.btn-success[disabled]:hover,
.btn-primary.disabled,
.btn-primary[disabled],
.btn-primary[disabled]:focus,
.btn-primary[disabled]:hover,
.btn-accent.disabled,
.btn-accent[disabled],
.btn-accent[disabled]:focus,
.btn-accent[disabled]:hover,
.btn-gray.disabled,
.btn-gray[disabled],
.btn-gray[disabled]:focus,
.btn-gray[disabled]:hover {
  border-color: transparent;
  background: rgba(0, 0, 0, 0.15);
  color: #313532;
  opacity: 0.4;
  filter: alpha(opacity=40);
}
.side-padding {
  padding: 0 10px;
}
.list .tile .tile-text {
  font-size: 13px;
  padding: 10px 0;
}
.background-photo {
  background-size: cover;
  background-position: top right;
  top: -50px;
  max-width: 1200px;
  max-height: 800px;
}
h2.title {
  margin-top: 50px;
  margin-bottom: 20px;
  margin-inline: 0;
  line-height: 1.2;
}
h3.subtitle {
  margin-top: 0;
  margin-bottom: 10px;
  margin-inline: 0;
  text-align: justify;
  font-size: 125%;
}
.slogan {
  margin-top: 18px;
  margin-bottom: 2px;
  font-size: 225%;
  text-align: center;
}
.table-responsive-container {
  overflow-x: auto;
}
#applications-list ul.warning-list {
  background: #f2dbd3 !important;
  box-shadow: -1000px 0 0 #f2dbd3, 1000px 0 0 #f2dbd3;
  margin-top: -48px;
  padding: 15px 0;
  margin-bottom: 28px;
}
#applications-list ul.warning-list li {
  background: #f2dbd3 !important;
  margin-bottom: 9px !important;
}
#applications-list ul.warning-list li > span:not(.close) {
  border-inline-start: solid 2px #c2afa9;
  padding: 2px 13px;
  display: inline-block;
  white-space: pre-line;
}
#applications-list ul.warning-list li.alert.alert-warning {
  padding-inline-start: 0 !important;
}
#applications-list ul.warning-list li.alert.alert-warning::after {
  display: none !important;
}
#applications-list ul.warning-list li:last-of-type {
  margin-bottom: 0 !important;
}
@media (max-width: 768px) {
  #secondary-menu {
    top: 54px;
  }
  h2.title {
    margin: 35px 0;
  }
  h3.subtitle {
    margin-top: 0;
    margin-bottom: 10px;
    margin-inline: 0;
    width: 45%;
  }
}
@media (max-width: 640px) {
  .background-photo {
    background-size: cover;
    background-position: top left;
    bottom: 0;
    width: 1200px;
  }
  .no-side-padding {
    padding: 10px 0;
  }
  h2.title {
    font-size: 22px;
    margin: 20px 0;
    line-height: 1.2;
  }
  h3.subtitle {
    margin-top: 0;
    margin-bottom: 10px;
    margin-inline: 0;
    width: 100%;
  }
  .slogan {
    margin-top: 18px;
    margin-bottom: 2px;
    font-size: 210%;
    text-align: center;
    line-height: 33px;
  }
}
@media (max-width: 480px) {
  .form-field-type-table > div > table.table > tbody > tr {
    display: block;
  }
  .form-field-type-table > div > table.table > tbody > tr > td {
    display: block;
  }
}
#menu-info .element {
  display: inline-block;
}
#menu-info .element:first-child::before {
  content: normal;
}
#menu-info .element::before {
  content: "|";
  padding-inline-end: 7px;
}
#menu-info .element:not(:last-child)::after {
  content: "";
  padding-inline-end: 5px;
}
.radio-styled.radio-lg:not(ie8) input:checked ~ span:after {
  inset-inline-start: 5px;
  top: 5px;
  width: 10px;
  height: 10px;
}
.radio-styled.radio-lg:not(ie8) input ~ span:before {
  width: 20px;
  height: 20px;
}
.form-inline .checkbox,
.form-inline .btn {
  margin-top: 4px;
}
li.step_disabled #progress_value_pay {
  fill: #666;
}
/************************/
/*   Angular pointer    */
/************************/
[ng-click],
[data-ng-click],
[x-ng-click] {
  cursor: pointer;
}
td.text-in-middle {
  vertical-align: middle !important;
}
.sortorder:after {
  content: '\25b2';
}
.sortorder.reverse:after {
  content: '\25bc';
}
.tabs-accent.nav-tabs > li.active > a,
.tabs-accent.nav-tabs > li.active > a:hover,
.tabs-accent.nav-tabs > li.active > a:focus {
  height: 47px;
}
/* Styling for the ngProgress itself */
#ngProgress {
  z-index: 999999998;
}
/* Styling for the ngProgress-container */
#ngProgress-container {
  z-index: 999999999;
}
.cms-debug-bar {
  display: none;
}
/* formio fix */
.form-group .form-field-type-select {
  display: block !important;
}
table.datagrid-table .field-required-inline {
  display: none;
}
.glyphicon-asterisk {
  font-family: "Open Sans", sans-serif, Helvetica, Arial, sans-serif;
  font-weight: 500;
}
.input-group .form-control:only-child {
  border-radius: 6px;
}
#form-type-A1 .input-group .form-control:first-child {
  border-radius: 6px;
}
.input-group-btn {
  width: 1%;
}
.form-field-type-datetime .input-group-btn {
  width: 1%;
}
.ui-select-match > .btn-default {
  border-color: #ccc;
}
.dropdown-menu {
  border-color: #ccc;
}
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  border-color: #ccc;
  background: #e5e6e5;
  color: #313532;
}
#form-type-A1 .glyphicon-asterisk:before {
  content: "";
}
.table-grouped {
  background-color: rgba(242, 243, 242, 0.35);
}
/* Styling table - no line on top of the row */
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  /*    border-top: none;*/
  vertical-align: top;
}
.content-middle > tbody > tr > th,
.content-middle > tbody > tr > td {
  vertical-align: middle;
}
.content-middle-padding > tbody > tr > th,
.content-middle-padding > tbody > tr > td {
  vertical-align: middle;
  padding: 10px 8px;
}
.no-margin-table .table {
  margin: 0;
}
.form-field-type-datagrid .datagrid-table {
  margin: 0 -12px;
}
.my-applications .table > tbody > tr > td {
  padding: 10px 8px;
}
.with-margin-top {
  margin-top: 24px;
}
.btn-menu,
.btn-menu:active,
.btn-menu:hover,
.btn-menu:focus,
.btn-menu:visited {
  color: #fff;
  background-color: #164271;
  border-color: #164271;
}
.padding {
  padding-inline-end: 14px;
}
.col-with-12 {
  width: 12%;
}
.form-field-type-table .form-group {
  margin-bottom: 0.5rem;
}
.no-margin-form-group .table {
  margin: 0;
}
.no-margin-form-group .table .form-group {
  margin: 0;
}
.no-margin-form-group .table > tbody > tr > td {
  vertical-align: middle;
}
.panel-body .panel-body {
  padding: 0;
}
.switch-button-label {
  float: left;
  font-size: 16pt;
  cursor: pointer;
  line-height: 1.4;
}
.switch-button-label.off {
  color: #adadad;
}
.switch-button-label.on {
  font-weight: bold;
}
.switch-button-label.on span.yes {
  color: #4caf50;
}
.switch-button-label.on span.no {
  color: #f44336;
}
.switch-button-background {
  float: left;
  position: relative;
  background: #ccc;
  border: 1px solid #aaa;
  margin: 0 12px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  cursor: pointer;
}
.switch-button-button {
  position: absolute;
  top: -1px;
  background: #fafafa;
  border: 1px solid #aaa;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  inset-inline-start: -1px;
}
.switch-button-background.checked .switch-button-button {
  inset-inline-start: 16px;
}
.formio-component-rejectionrow .switch-box .switch-button-label[ng-click="setSwitch(false)"] {
  background: #efefef !important;
  color: #333333;
}
.formio-component-rejectionrow .switch-box .switch-button-label[ng-click="setSwitch(false)"]:hover,
.formio-component-rejectionrow .switch-box .switch-button-label[ng-click="setSwitch(false)"].on {
  color: white !important;
  background-color: #d16e72 !important;
}
.formio-component-rejectionrow .form-group.form-field-type-myswitch .switch-box .switch-button-label[ng-click="setSwitch(true)"]:hover {
  color: white !important;
  background-color: #80c49b !important;
}
.red-nit {
  color: red;
}
.white-space-normal {
  white-space: normal;
}
.confirm-button > button {
  color: #fff;
  background-color: #535854;
  border-color: #535854;
}
.print-button > button {
  color: #fff;
  background-color: #969c97;
  border-color: #969c97;
}
.sworn-statement .field-required:after {
  content: "";
}
.has-success .form-control {
  border-color: #4caf50;
}
.has-error .field-required:after {
  content: "\f0a1";
  color: inherit;
  display: inline-block;
  font: normal normal normal 14px/1 'Material Design Iconic Font';
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}
.has-success .field-required:after {
  content: "\f299";
  color: inherit;
  display: inline-block;
  font: normal normal normal 14px/1 'Material Design Iconic Font';
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}
.sworn-statement .has-error .field-required:after {
  content: "";
}
.sworn-statement .has-success .field-required:after {
  content: "";
}
.has-success .ui-select-match > .btn-default {
  border: 1px solid #4caf50;
}
.validation-headers .col-sm-1 {
  width: 1%;
  padding: 0 0 0 12px;
}
.validation-headers .col-sm-3 {
  width: 10%;
  text-align: end;
  padding: 0 12px 0 0;
  float: right;
}
.validation-headers .col-sm-8 {
  padding: 0 0 0 12px;
  width: 80%;
}
.validation-headers .form-group {
  margin-bottom: 0;
}
.validation-headers .btn {
  letter-spacing: 0.4pt;
  color: inherit;
  font-size: unset;
  padding: 0 6px;
  border: 0px solid transparent;
  white-space: normal;
  text-align: start;
}
/* payment table */
#payment-tab .card-bordered,
#payment-tab .card-outlined {
  border: none;
  margin-bottom: 0px;
}
#payment-tab .table > tbody > tr > td {
  vertical-align: baseline;
  padding: 10px 8px;
  line-height: 1.84615385;
  border-top: 1px solid rgba(189, 193, 190, 0.2);
}
#payment-tab .table > tbody > tr > td p {
  margin: 0;
}
#payment-tab .table > tbody:first-child > tr:first-child > td {
  vertical-align: bottom;
  padding: 10px 8px;
  line-height: 1.84615385;
  border-top: 0;
  border-bottom: 2px solid rgba(189, 193, 190, 0.2);
}
#payment-tab .form-field-type-table .form-group {
  margin-bottom: 0px;
}
#payment-tab form .formio-component-totalPrice > .row > .col-sm-6:last-of-type .form-field-type-mycontent span {
  display: inline-block;
  margin-top: 6px;
}
#payment-tab form .default-pay-form-payments {
  border-top: none;
  border-bottom: 1px solid #efefef;
  padding-top: 10px;
}
#payment-tab form .default-pay-form-payments:last-of-type {
  border-bottom: none;
}
#payment-tab form .default-pay-form-payments:first-of-type {
  padding-top: 0;
}
#payment-tab form .default-pay-form-payments .row div[class^="col-"] {
  width: auto;
}
#payment-tab form .default-pay-form-payments .row div[class^="col-"] label {
  margin-top: 0;
}
.paginator-wrapper {
  margin-inline-start: 20px;
}
.nowrap {
  white-space: nowrap;
}
input.form-control:disabled {
  text-align: start;
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-ms-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes zoom-down {
  from {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-moz-keyframes zoom-down {
  from {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-o-keyframes zoom-down {
  from {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-ms-keyframes zoom-down {
  from {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@keyframes zoom-down {
  from {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-webkit-keyframes zoom-up {
  from {
    -webkit-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-moz-keyframes zoom-up {
  from {
    -webkit-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-o-keyframes zoom-up {
  from {
    -webkit-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-ms-keyframes zoom-up {
  from {
    -webkit-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@keyframes zoom-up {
  from {
    -webkit-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-webkit-keyframes slide-right {
  from {
    -webkit-transform: translate(-20px, 0);
    -ms-transform: translate(-20px, 0);
    -o-transform: translate(-20px, 0);
    transform: translate(-20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-moz-keyframes slide-right {
  from {
    -webkit-transform: translate(-20px, 0);
    -ms-transform: translate(-20px, 0);
    -o-transform: translate(-20px, 0);
    transform: translate(-20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-o-keyframes slide-right {
  from {
    -webkit-transform: translate(-20px, 0);
    -ms-transform: translate(-20px, 0);
    -o-transform: translate(-20px, 0);
    transform: translate(-20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-ms-keyframes slide-right {
  from {
    -webkit-transform: translate(-20px, 0);
    -ms-transform: translate(-20px, 0);
    -o-transform: translate(-20px, 0);
    transform: translate(-20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes slide-right {
  from {
    -webkit-transform: translate(-20px, 0);
    -ms-transform: translate(-20px, 0);
    -o-transform: translate(-20px, 0);
    transform: translate(-20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes slide-left {
  from {
    -webkit-transform: translate(20px, 0);
    -ms-transform: translate(20px, 0);
    -o-transform: translate(20px, 0);
    transform: translate(20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-moz-keyframes slide-left {
  from {
    -webkit-transform: translate(20px, 0);
    -ms-transform: translate(20px, 0);
    -o-transform: translate(20px, 0);
    transform: translate(20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-o-keyframes slide-left {
  from {
    -webkit-transform: translate(20px, 0);
    -ms-transform: translate(20px, 0);
    -o-transform: translate(20px, 0);
    transform: translate(20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-ms-keyframes slide-left {
  from {
    -webkit-transform: translate(20px, 0);
    -ms-transform: translate(20px, 0);
    -o-transform: translate(20px, 0);
    transform: translate(20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes slide-left {
  from {
    -webkit-transform: translate(20px, 0);
    -ms-transform: translate(20px, 0);
    -o-transform: translate(20px, 0);
    transform: translate(20px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes slide-down {
  from {
    -webkit-transform: translate(0, -20px);
    -ms-transform: translate(0, -20px);
    -o-transform: translate(0, -20px);
    transform: translate(0, -20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-moz-keyframes slide-down {
  from {
    -webkit-transform: translate(0, -20px);
    -ms-transform: translate(0, -20px);
    -o-transform: translate(0, -20px);
    transform: translate(0, -20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-o-keyframes slide-down {
  from {
    -webkit-transform: translate(0, -20px);
    -ms-transform: translate(0, -20px);
    -o-transform: translate(0, -20px);
    transform: translate(0, -20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-ms-keyframes slide-down {
  from {
    -webkit-transform: translate(0, -20px);
    -ms-transform: translate(0, -20px);
    -o-transform: translate(0, -20px);
    transform: translate(0, -20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes slide-down {
  from {
    -webkit-transform: translate(0, -20px);
    -ms-transform: translate(0, -20px);
    -o-transform: translate(0, -20px);
    transform: translate(0, -20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-webkit-keyframes slide-up {
  from {
    -webkit-transform: translate(0, 20px);
    -ms-transform: translate(0, 20px);
    -o-transform: translate(0, 20px);
    transform: translate(0, 20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-moz-keyframes slide-up {
  from {
    -webkit-transform: translate(0, 20px);
    -ms-transform: translate(0, 20px);
    -o-transform: translate(0, 20px);
    transform: translate(0, 20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-o-keyframes slide-up {
  from {
    -webkit-transform: translate(0, 20px);
    -ms-transform: translate(0, 20px);
    -o-transform: translate(0, 20px);
    transform: translate(0, 20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@-ms-keyframes slide-up {
  from {
    -webkit-transform: translate(0, 20px);
    -ms-transform: translate(0, 20px);
    -o-transform: translate(0, 20px);
    transform: translate(0, 20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes slide-up {
  from {
    -webkit-transform: translate(0, 20px);
    -ms-transform: translate(0, 20px);
    -o-transform: translate(0, 20px);
    transform: translate(0, 20px);
    opacity: 0;
  }
  to {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}
.labelmargin {
  margin-top: 24px;
}
.required {
  color: red;
}
.form-field-type-radio .form-control-feedback.field-required-inline {
  display: inline;
  position: relative;
}
.form-field-type-htmlelement {
  margin-top: 16px;
}
.user-steps-menu-wrapper {
  padding: 0;
}
.form-field-type-mypanel .panel-default > div.panel-heading,
.form-field-type-panel .panel-default > div.panel-heading {
  border: none;
}
.btn-lg,
.btn-group-lg > .btn {
  padding: 5px 16px;
}
.panel {
  border-color: #E3E3E3;
  border-radius: 5px;
}
.form-field-type-panel {
  padding-bottom: 0;
}
.form-field-type-panel .panel {
  margin-bottom: 0;
}
.form-group.form-field-type-columns .form-group.form-field-type-columns {
  margin: 10px 16px 0;
}
.form-group > label,
.form-group .control-label {
  color: #2E2E2E;
}
.page-title {
  margin-bottom: 10px;
}
.service-list-item img {
  max-width: 64px;
  max-height: 64px;
}
.list .tile .tile-content {
  display: flex;
  padding-inline-start: 16px;
}
.list .tile div.tile-btn {
  display: flex;
}
.list .tile .tile-text {
  font-size: 16px;
  padding: 1.75rem 0;
}
.list .tile .tile-text h3 {
  margin-top: 0px;
}
.list .tile .tile-text a {
  font-size: 14px;
}
.capital-first > div:first-child {
  font-weight: 500;
  color: #282666;
}
section .style-default-dark,
.offcanvas-pane .style-default-dark,
.card .style-default-dark,
section.style-default-dark,
.offcanvas-pane.style-default-dark,
.card.style-default-dark {
  background-color: transparent;
  border: none;
  color: #ffffff;
}
.checkbox-styled:not(ie8) input ~ span:before,
.radio-styled:not(ie8) input ~ span:before {
  top: 5px;
}
/* License Search */
.license-search-input-group input.form-control {
  border-radius: 3px 0 0 3px !important;
}
.license-search-input-group .button-license-search {
  background-color: #3C8FB9;
  border-color: #3C8FB9;
  border-radius: 0 3px 3px 0;
}
.license-search-input-group .button-license-search:hover {
  background-color: #3680a6;
  border-color: #3680a6;
}
.license-search-messages.alert {
  background: rgba(239, 239, 239, 0.5) !important;
  color: #404451 !important;
  padding: 12px 20px !important;
  margin-top: 20px !important;
}
/******************************************/
/*     Box Section in My Applications     */
/******************************************/
.box {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
}
.box-body {
  padding: 1rem;
}
.box-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 3.5rem;
  border-bottom: 1px solid #dae3ea;
  font-weight: bold;
}
.box-title {
  color: #404451;
  font-size: 2rem;
}
.box-body {
  display: flex;
  flex-wrap: wrap;
  max-height: 24rem;
  overflow-y: auto;
}
.box-tabbable {
  width: 100%;
  margin: 5px 25px;
}
.box-tabbable .box-tabs {
  border-bottom: 0;
}
.box-tabbable .box-tabs .box-tab {
  width: 50%;
  margin-bottom: 20px;
  text-align: center;
}
.box-tabbable .box-tabs .box-tab.active a {
  border: 0;
  border-bottom: 2px solid #186288;
}
.box-tabbable .box-tabs .box-tab.active a span {
  color: rgba(0, 0, 0, 0.87);
}
.box-tabbable .box-tabs .box-tab a {
  border-bottom: 2px solid rgba(154, 167, 183, 0.3);
}
.box-tabbable .box-tabs .box-tab a span {
  font-weight: bold;
  color: #9aa7b7;
}
.box-table {
  width: 100%;
  margin: 5px 25px;
}
.box-table thead tr {
  border-bottom: 1px solid rgba(154, 167, 183, 0.4);
}
.box-table thead tr th {
  padding-block: 5px;
  padding-inline-start: 0;
  padding-inline-end: 10px;
  text-align: start;
}
.box-table thead tr th:last-of-type {
  padding: 5px 0 5px 10px;
}
.box-table tbody tr {
  border-bottom: 1px solid rgba(154, 167, 183, 0.15);
}
.box-table tbody tr td {
  padding-block: 10px;
  padding-inline-start: 0;
  padding-inline-end: 10px;
  text-align: start;
  font-size: 14px;
}
.box-table tbody tr td:last-of-type {
  padding: 10px 0 10px 10px;
}
.box-item {
  flex: 0 0 25%;
  margin: 1.5rem 0;
}
.item-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  text-align: center;
  cursor: pointer;
  opacity: 1;
}
.item-link:hover {
  text-decoration: none;
  opacity: 0.8;
}
.item-img {
  height: 3rem;
}
.item-name {
  color: #404451;
  font-size: 1.4rem;
}
/***************************/
/*     Main navigation     */
/***************************/
nav.greedy ul.hidden-links.secondary-nav li {
  float: none;
}
.economic-activities {
  display: flex;
  flex-direction: column;
}
.economic-activities label {
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
}
.economic-activities button {
  align-self: flex-start;
  border: 1px solid #3C8FB9;
  color: #3C8FB9;
  background-color: #ffffff;
  border-radius: 2px !important;
  text-transform: uppercase;
  font-weight: 500;
  order: 2;
  margin-bottom: 1.5rem;
}
.economic-activities ul {
  padding: 0;
  margin-bottom: 10px;
  list-style: none;
}
.economic-activities ul:empty {
  display: none;
}
.economic-activities ul li {
  color: #2267a2;
}
.economic-activities .modal-header {
  display: flex;
  justify-content: space-between;
}
.economic-activities button.close {
  font-size: 42px;
  opacity: 1;
}
.input-group .input-group-btn button.btn-default {
  padding: 5px 14px;
  background-color: #fff;
  color: #9AA7B7;
}
.input-group .input-group-btn button.btn-default:hover {
  background-color: #EFEFEF;
}
.datetime-picker-dropdown {
  inset-inline-end: 0;
  inset-inline-start: auto !important;
}
.datetime-picker-dropdown li:focus {
  outline: none;
}
.datetime-picker-dropdown li:after,
.datetime-picker-dropdown li:before {
  display: none !important;
}
.datetime-picker-dropdown li div:focus {
  outline: none;
}
.datetime-picker-dropdown .uib-timepicker td button.btn-default {
  background-color: #fff;
  border-color: #fff;
  border-radius: 0;
}
.datetime-picker-dropdown .uib-timepicker td button.btn-default:hover {
  background-color: #2267a2;
  color: #FFFFFF;
}
.datetime-picker-dropdown .uib-timepicker td.uib-increment .glyphicon,
.datetime-picker-dropdown .uib-timepicker td.uib-decrement .glyphicon {
  color: #313335;
}
.datetime-picker-dropdown .uib-datepicker .uib-timepicker table th button.btn-default,
.datetime-picker-dropdown .uib-datepicker .uib-daypicker table th button.btn-default,
.datetime-picker-dropdown .uib-datepicker .uib-monthpicker table th button.btn-default,
.datetime-picker-dropdown .uib-datepicker .uib-yearpicker table th button.btn-default,
.datetime-picker-dropdown .uib-datepicker .uib-timepicker table td button.btn-default,
.datetime-picker-dropdown .uib-datepicker .uib-daypicker table td button.btn-default,
.datetime-picker-dropdown .uib-datepicker .uib-monthpicker table td button.btn-default,
.datetime-picker-dropdown .uib-datepicker .uib-yearpicker table td button.btn-default {
  background-color: #fff;
  border-color: #fff;
}
.datetime-picker-dropdown .uib-datepicker .uib-timepicker table th small,
.datetime-picker-dropdown .uib-datepicker .uib-daypicker table th small,
.datetime-picker-dropdown .uib-datepicker .uib-monthpicker table th small,
.datetime-picker-dropdown .uib-datepicker .uib-yearpicker table th small {
  color: #000;
}
.datetime-picker-dropdown .uib-datepicker .uib-timepicker table td button.btn-default,
.datetime-picker-dropdown .uib-datepicker .uib-daypicker table td button.btn-default,
.datetime-picker-dropdown .uib-datepicker .uib-monthpicker table td button.btn-default,
.datetime-picker-dropdown .uib-datepicker .uib-yearpicker table td button.btn-default {
  border-radius: 0;
}
.datetime-picker-dropdown .uib-datepicker .uib-timepicker table td button.btn-default:hover,
.datetime-picker-dropdown .uib-datepicker .uib-daypicker table td button.btn-default:hover,
.datetime-picker-dropdown .uib-datepicker .uib-monthpicker table td button.btn-default:hover,
.datetime-picker-dropdown .uib-datepicker .uib-yearpicker table td button.btn-default:hover {
  background-color: #EFEFEF;
}
.datetime-picker-dropdown .uib-datepicker .uib-timepicker table td button.btn-default span.text-info,
.datetime-picker-dropdown .uib-datepicker .uib-daypicker table td button.btn-default span.text-info,
.datetime-picker-dropdown .uib-datepicker .uib-monthpicker table td button.btn-default span.text-info,
.datetime-picker-dropdown .uib-datepicker .uib-yearpicker table td button.btn-default span.text-info {
  color: #ccc !important;
}
.datetime-picker-dropdown .uib-datepicker .uib-timepicker table td button.btn-default.active,
.datetime-picker-dropdown .uib-datepicker .uib-daypicker table td button.btn-default.active,
.datetime-picker-dropdown .uib-datepicker .uib-monthpicker table td button.btn-default.active,
.datetime-picker-dropdown .uib-datepicker .uib-yearpicker table td button.btn-default.active {
  background-color: #EFEFEF;
  color: #313335;
}
.datetime-picker-dropdown .uib-datepicker .uib-timepicker table td.uib-increment .glyphicon,
.datetime-picker-dropdown .uib-datepicker .uib-daypicker table td.uib-increment .glyphicon,
.datetime-picker-dropdown .uib-datepicker .uib-monthpicker table td.uib-increment .glyphicon,
.datetime-picker-dropdown .uib-datepicker .uib-yearpicker table td.uib-increment .glyphicon {
  color: #313335;
}
.datetime-picker-dropdown .btn-group.pull-left button.btn-default,
.datetime-picker-dropdown .btn-group.pull-right button.btn-default {
  background-color: #fff;
  border-color: #fff;
  border-radius: 0;
}
.datetime-picker-dropdown .btn-group.pull-left button.btn-default:hover,
.datetime-picker-dropdown .btn-group.pull-right button.btn-default:hover {
  background-color: #2267a2;
  color: #FFFFFF;
}
@media all and (max-width: 768px) {
  .datetime-picker-dropdown {
    inset-inline-end: auto;
  }
}
.payment-transaction-closer {
  position: absolute;
  inset-inline-end: 12px;
  top: 12px;
  font-size: 16px !important;
  cursor: pointer;
}
/*********************/
/*     Headerbar     */
/*********************/
.headerbar {
  display: flex;
}
.headerbar-left,
.headerbar-right {
  position: relative;
}
.headerbar-left {
  padding: 0;
}
.headerbar-left .header-nav {
  margin-inline-start: 0;
}
.headerbar-left .header-nav .header-nav-brand .brand-holder {
  height: 80px;
}
.headerbar-left .header-nav .header-nav-brand img {
  max-height: 62px;
}
.headerbar-right {
  display: flex;
  align-items: center;
  margin-inline-start: auto;
}
.headerbar-right .logout {
  color: #313335;
}
.headerbar-right .logout i {
  font-size: 16px;
}
.headerbar-right .links {
  margin-bottom: 0;
  padding-inline-start: 0;
  list-style: none;
  color: #313335;
  margin-inline-end: 4rem;
}
.headerbar-right .profile {
  margin: 0;
}
.headerbar-right .profile .user {
  display: inline-flex;
}
.headerbar-right .profile .user .dropdown-toggle {
  display: inline-flex;
  align-items: center;
  margin-inline-start: 24px;
}
.headerbar-right .profile .user .dropdown-toggle.left-part {
  border-radius: 24px 0 0 24px;
}
.headerbar-right .profile .user .dropdown-toggle.left-part:nth-last-child(2) {
  padding-inline-end: 1.5rem;
  cursor: default;
}
.headerbar-right .profile .user .dropdown-toggle i.user-icon {
  color: #B3D4E4;
  margin-inline-end: 0.6rem;
  font-size: 22px;
}
.headerbar-right .profile .user .dropdown-toggle.user-dropdown-toggle {
  border-radius: 0 24px 24px 0;
  border-inline-start: solid 1px #5B96B3;
  padding-inline-start: 10px;
  margin-inline-start: 0;
}
.headerbar-right .profile .user .dropdown-toggle.user-dropdown-toggle i.angle-icon {
  margin-inline-end: 0.6rem;
  font-size: 16px;
}
.headerbar-right .profile .user .dropdown-toggle.user-dropdown-toggle:last-child {
  display: none;
}
.headerbar-right .profile .user .dropdown-toggle .user-name-text {
  padding-inline-end: 10px;
  line-height: 1;
}
.headerbar-right .profile .user.open .dropdown-menu {
  min-width: calc(100% - 24px);
}
.headerbar-right .headerbar-right-situation {
  font-weight: 400;
  font-size: 2.8rem;
  text-transform: uppercase;
  margin-inline-end: 24px;
  color: #000;
}
.headerbar-right .headerbar-right-divider {
  border-inline-start: 1px solid #efefef;
  margin-block: 16px;
  margin-inline-start: 0;
  margin-inline-end: 24px;
  width: 1px;
  display: inline-block;
  height: 51px;
}
.headerbar-right .dropdown-menu {
  border-radius: 0;
  border: none;
  padding: 12px 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
  min-width: auto;
}
.header-nav-btn > li > a {
  font-size: 14px;
}
.header-nav-btn > li > a > .user-icon {
  margin-inline-end: 0.5rem;
  font-size: 16px;
  color: #313335;
}
.header-nav-btn > li > a[class*="btn-"]:hover,
.header-nav-btn > li > a[class*="btn-"]:focus {
  background-color: #313335;
  border-color: #313335;
  color: #ffffff;
}
.languages {
  margin-inline-end: 4rem;
}
.languages .dropdown-menu {
  position: absolute;
  border-radius: 6px;
  min-width: auto;
  border: none;
  box-shadow: 0 0 6px 0 #9AA7B7;
}
.languages .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #313335;
  text-align: inherit;
  white-space: nowrap;
  border: 0;
}
#header {
  box-shadow: none;
  height: auto;
  height: initial;
  position: relative;
  flex: 0 1 auto;
}
.header-nav > li {
  padding: 0;
}
.header-nav > li > a:hover,
.header-nav > li > a:focus {
  text-decoration: none;
  background-color: transparent;
}
@media (max-width: 768px) {
  #djDebugToolbarHandle,
  #cms-top {
    display: none;
  }
  .headerbar-left .header-nav .header-nav-brand .brand-holder {
    height: 64px;
  }
  .headerbar-left .header-nav .header-nav-brand .brand-holder > a {
    display: flex;
    align-items: center;
  }
  .headerbar-left .header-nav .header-nav-brand .brand-holder > a span {
    display: flex;
  }
  .headerbar-left .header-nav .header-nav-brand img {
    display: inline-block;
    margin-inline-end: 4px !important;
    height: 30px !important;
    vertical-align: baseline;
  }
  .header-nav-btn > li > a {
    padding: 2px 4px;
  }
  .header-nav-btn > li {
    padding: 17px 0;
  }
  #connect > li {
    padding: 15px 0;
    margin-inline-end: 1rem !important;
  }
  #connect > li span {
    display: none;
  }
  .languages {
    margin-inline-end: 2rem !important;
  }
  .links {
    margin-inline-end: 2rem !important;
  }
  .headerbar-right {
    box-shadow: none;
    float: right !important;
    inset-inline-start: auto;
    inset-inline-end: auto;
    top: auto;
    background-color: transparent;
  }
  .headerbar-right .profile .user {
    margin-inline-end: 0;
  }
  .header-nav-profile .dropdown > a,
  .header-nav-profile .dropdown.open > a {
    min-height: 38px;
    padding-block: 12px;
    padding-inline-start: 0;
    padding-inline-end: 8px;
  }
  .headerbar-right .dropdown-menu {
    inset-inline-end: auto;
    inset-inline-start: 0;
    background-color: #9AA7B7;
    border-radius: 0 0 3px 3px;
  }
}
.headerbar-right ul.links li.child:first-child > a {
  content: "";
  background-color: transparent;
  position: relative;
  font-family: var(--fontawesome-font);
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  transition: all 0.3s;
  font-size: 0;
  text-decoration: none;
}
.headerbar-right ul.links li.child:first-child > a:after {
  font-size: 1.5rem;
  content: '\f013';
}
html {
  margin-top: 0 !important;
}
body {
  background-color: #ffffff;
  overflow-y: scroll;
}
body.no-toolbar #cms-top {
  display: none;
}
/* Bootstrap overrides */
.row {
  flex: 1;
  display: flex;
  flex-flow: row;
  align-items: stretch;
}
.row > div {
  /*flex: 1 100%;*/
}
.row > div.col-init,
.row > div.col-sm-1 {
  flex: auto;
}
.row > div > .panel {
  height: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
  border-radius: 0;
  border: none;
}
.row > div > .panel > .panel-heading {
  position: relative;
}
.row > div > .panel > .panel-heading .see-more {
  text-transform: capitalize;
  font-size: 12px;
  color: rgba(60, 143, 185, 0.5);
  position: absolute;
  inset-inline-end: 1.5rem;
  top: 1.5rem;
  cursor: pointer;
  transition: all 0.3s;
}
.row > div > .panel > .panel-heading .see-more:hover {
  color: #3c8fb9;
  transition: all 0.3s;
  text-decoration: none;
}
.row > div > .panel > .panel-body {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 2rem;
  overflow-y: auto;
}
.row > div > .panel > .panel-body.panel-body--short {
  overflow: hidden;
}
.panel {
  border: none;
}
.panel > .panel-body {
  padding: 2rem 2rem 0.5rem;
}
.panel > .panel-body.tab-content {
  padding: 1rem 0;
}
.panel > .panel-body.tab-content .panel {
  box-shadow: none;
}
.panel > .panel-body.tab-content hr {
  display: none;
}
.panel > .panel-heading + .panel-body {
  padding: 1.5rem 2.5rem 1.9rem;
}
.page-title {
  color: #313335;
  font-size: 20px;
  font-weight: 400;
  border: none;
  position: relative;
  padding: 0.5rem 0;
}
section.section-body {
  padding: 0;
}
section.section-body > .contain-lg {
  position: relative;
}
#shoppingcart.service-cart > .regs-docs-fees-drawer {
  position: fixed;
  width: 20vw;
  min-width: 320px;
  z-index: 99999;
  box-shadow: -2px 0 12px rgba(0, 0, 0, 0.2);
  background: #f5f6f8;
  height: 100%;
  inset-inline-end: 0;
  top: 0;
}
#shoppingcart.service-cart > .regs-docs-fees-drawer .alert {
  display: none;
}
#shoppingcart.service-cart > .regs-docs-fees-drawer form.formio-form .form-group.form-field-type-mycontent {
  margin-top: 0;
}
#shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-header {
  padding: 2rem;
  color: #555555;
}
#shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-header span {
  font-size: 18px;
}
#shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-header span.cart-icon {
  color: #ffffff;
  margin-inline-end: 10px;
  background: #80c49b;
  padding-block: 5px;
  padding-inline-start: 0;
  padding-inline-end: 12px;
  border-radius: 0 20px 20px 0;
  box-shadow: -15px 0 #80c49b;
  margin-inline-start: -5px;
}
#shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-header i {
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  margin-inline-end: -8px;
  border-radius: 20px;
}
#shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-header i:hover {
  background: #e1e4eb;
  color: #f44336;
}
#shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-section-header {
  width: 100%;
  border: none;
  background: no-repeat;
  background-image: none;
  padding: 1rem 2rem;
  color: #555555;
  font-size: 16px;
  font-weight: 600;
}
#shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-section-header i {
  border: solid 1px #AAA;
  color: #AAA;
  border-radius: 20px;
  width: 16px;
  height: 16px;
  font-size: 14px;
}
#shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-section-header:hover {
  color: black;
}
#shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-section-header:hover i {
  color: black;
  border-color: black;
}
#shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-section-header:focus {
  outline: none;
}
#shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-section-header:not(.opened) i::before {
  content: "\F107";
}
#shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-section-header.opened + .drawer-section-content {
  /*display: block;*/
}
#shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-section-content {
  background: white;
  padding: 1rem 2rem;
  display: none;
  color: #555555;
}
#shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-section-content#guidemenuregs > div > div label {
  cursor: pointer;
}
#shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-section-content#guidemenuregs > div > div label input {
  position: relative;
  top: 2px;
  width: 14px;
  height: 14px;
  margin-inline-end: 6px;
  display: inline-block;
}
.financial-report {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
  background-color: white;
}
.financial-report .big-title {
  font-size: 22px;
  font-weight: 400;
}
.financial-report .export-format-buttons {
  align-items: center;
}
.financial-report .export-format-buttons a {
  background: #f7f7f8;
  padding: 0.5rem 1.5rem;
  border-radius: 2px;
  margin-inline-start: 8px;
  text-decoration: none;
  color: #222222;
}
.financial-report .export-format-buttons a:first-child {
  margin-inline-start: 0;
}
.financial-report .export-format-buttons a:hover,
.financial-report .export-format-buttons a:focus {
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  background-color: #efeff3;
}
.financial-report .export-format-buttons a:active {
  box-shadow: inset 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  background-color: #efeff3;
}
.financial-report .export-format-buttons a.red-icon i {
  color: #D56D72;
}
.financial-report .export-format-buttons a.green-icon i {
  color: #7BC39B;
}
.financial-report .export-format-buttons a i {
  margin-inline-end: 8px;
}
.gray-bg-no-print {
  background-color: #f0f2f4;
}
.alpha-50 {
  opacity: 0.5;
}
.align-items-center {
  align-items: center;
}
.pointer {
  cursor: pointer;
}
#content > .affix-menu-size-container {
  z-index: 2;
}
#content > .section-body {
  z-index: 1;
}
.user-steps-menu {
  padding: 0;
  margin: 0 0 0;
  background-color: #F5F6F8;
}
.user-steps-menu .user-steps-menu-wrapper {
  border-bottom: none;
}
.user-steps-menu .current-service-container .current-service-text .fa-chevron-right {
  font-size: 10px;
  margin-inline-end: 12px;
}
.user-steps-menu .current-service-container .regs-docs-fees-cart {
  background-color: #b9c0ce;
  padding: 4px 2rem;
  cursor: pointer;
  color: white;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
  font-size: 18px;
  line-height: 1.4;
}
.user-steps-menu .current-service-container .regs-docs-fees-cart .count {
  background-color: #3b8fb9;
  color: white;
  border-radius: 24px;
  display: inline-flex;
  position: relative;
  top: -8px;
  font-size: 10px;
  font-weight: 500;
  line-height: 1;
  align-items: center;
  inset-inline-start: -5px;
  width: 14px;
  height: 14px;
  justify-content: center;
  box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1);
  text-shadow: none;
}
.user-steps-menu .current-service-container .regs-docs-fees-cart .fees {
  font-weight: 600;
}
.user-steps-menu .current-service-container .regs-docs-fees-cart .fees .currency {
  font-weight: 400;
  font-size: 10px;
}
.user-steps-menu .current-service-container .regs-docs-fees-cart:hover {
  background-color: #3b8fb9;
  color: white;
  border-color: #3b8fb9;
}
.user-steps-menu .current-service-container .regs-docs-fees-cart:hover .count {
  background-color: #efefef;
  box-shadow: 1px 2px 2px #666;
  color: #157eb3;
  font-weight: 600;
}
.user-steps-menu .current-service-container .service-cart form > .form-group {
  margin: 0 !important;
}
.user-steps-menu.affix {
  -webkit-box-shadow: 1px 3px 10px 2px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 1px 3px 10px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 1px 3px 10px 2px rgba(0, 0, 0, 0.15);
  transition: all 0.3s;
  z-index: 23456;
}
.user-steps-menu.affix .user-steps-menu-wrapper {
  border-bottom: none !important;
}
.user-steps-menu.affix .current-service-container .current-service-text {
  font-size: 16px;
}
.user-steps-menu.affix .user-steps-menu-list li a {
  font-size: 1.5rem;
  padding: 1rem 2.5rem;
}
.user-steps-menu.affix .user-steps-menu-list li .chevron::before {
  height: 1.6rem;
  top: 0rem;
  width: 1.6rem;
}
.user-steps-menu .user-steps-menu-list {
  background-color: transparent;
}
.user-steps-menu .user-steps-menu-list li {
  margin: 0;
  background: transparent;
  border-radius: 0;
  position: relative;
  flex-basis: 0;
  flex-grow: 2;
  padding: 0 0 0 3rem;
  height: auto;
  text-align: center;
}
.user-steps-menu .user-steps-menu-list li:nth-child(1) {
  z-index: 5;
}
.user-steps-menu .user-steps-menu-list li:nth-child(2) {
  z-index: 4;
}
.user-steps-menu .user-steps-menu-list li:nth-child(3) {
  z-index: 3;
}
.user-steps-menu .user-steps-menu-list li:nth-child(4) {
  z-index: 2;
}
.user-steps-menu .user-steps-menu-list li:nth-child(5) {
  z-index: 1;
}
.user-steps-menu .user-steps-menu-list li > a {
  text-align: center;
  font-size: 2rem;
  padding: 1.8rem 2.5rem;
  text-transform: uppercase;
  font-weight: 600;
  color: rgba(49, 51, 53, 0.25);
  transition: all 0.3s;
}
.user-steps-menu .user-steps-menu-list li:after {
  content: '';
  display: none;
}
.user-steps-menu .user-steps-menu-list li .user-steps-menu-item-progress {
  height: 2px;
  top: 100%;
  width: 0;
  position: absolute;
  border-radius: 5px 0 0 5px;
  border-inline-end: none;
  opacity: 0.9;
  background: #7BC39B;
  margin-inline-start: -3rem;
}
.user-steps-menu .user-steps-menu-list li .chevron {
  position: absolute;
  inset-inline-end: -1.6rem;
  top: 1.5rem;
  color: rgba(49, 51, 53, 0.32);
  opacity: 0.5;
}
.user-steps-menu .user-steps-menu-list li .chevron::before {
  border-style: solid;
  border-width: 0.12em 0.12em 0 0;
  content: '';
  display: inline-block;
  height: 2.3rem;
  position: relative;
  top: 0.5rem;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 2.3rem;
  background: #F5F6F8;
}
.user-steps-menu .user-steps-menu-list li .chevron.right:before {
  inset-inline-start: 0;
  transform: rotate(45deg);
}
.user-steps-menu .user-steps-menu-list li:last-child .chevron {
  display: none;
}
.user-steps-menu .user-steps-menu-list li:focus,
.user-steps-menu .user-steps-menu-list li:active,
.user-steps-menu .user-steps-menu-list li:hover,
.user-steps-menu .user-steps-menu-list li.active {
  cursor: pointer;
}
.user-steps-menu .user-steps-menu-list li:focus > a,
.user-steps-menu .user-steps-menu-list li:active > a,
.user-steps-menu .user-steps-menu-list li:hover > a,
.user-steps-menu .user-steps-menu-list li.active > a {
  color: #313335;
}
.user-steps-menu .user-steps-menu-list li.user-steps-menu-step-completed .chevron {
  color: #7BC39B;
  opacity: 1;
}
.user-steps-menu .user-steps-menu-list li.user-steps-menu-step-completed .user-steps-menu-item-progress {
  width: 100% !important;
}
.finished-service-bar {
  background: #e1e4ea;
  box-shadow: -1000px 0 0 #e1e4ea, 1000px 0 0 #e1e4ea;
  margin: -24px 0 24px;
}
.finished-service-bar .current-service-text .fa-chevron-right {
  font-size: 10px;
  margin-inline-end: 12px;
}
.dropdown-menu-scroller {
  max-height: 180px;
  overflow-y: scroll;
  background: #fafafa;
}
.dropdown-menu-hint {
  background: #fafafa;
}
:root {
  --menu-dpd-tglr-text-color: var(--color-gray-800);
  --menu-dpd-tglr-bg-color: transparent;
  --menu-dpd-tglr-active-bg-color: var(--color-gray-100);
  --menu-dpd-menu-item-text-color: var(--color-gray-800);
  --menu-dpd-menu-item-bg-color: transparent;
  --menu-dpd-tglr-text-size: 14px;
}
.service-bar-user-lang-menu {
  margin-inline-start: auto;
  margin-inline-end: -1.5rem;
}
.service-bar-user-lang-menu .headerbar-right {
  height: var(--service-bar-height);
  flex-direction: row-reverse;
}
.service-bar-user-lang-menu .headerbar-right ul {
  padding-inline-start: 0;
}
@media screen and (max-width: 768px) {
  .service-bar-user-lang-menu .headerbar-right {
    background-color: transparent;
    top: initial;
    inset-inline-start: initial;
    inset-inline-end: initial;
    min-height: var(--service-bar-height);
  }
}
.service-bar-user-lang-menu .header-nav {
  height: 100%;
}
.service-bar-user-lang-menu .header-nav :is(.dropdown, .dropdown-user) {
  color: var(--menu-dpd-tglr-text-color);
  background-color: var(--menu-dpd-tglr-bg-color);
  font-size: var(--menu-dpd-tglr-text-size);
}
.service-bar-user-lang-menu .header-nav :is(.dropdown, .dropdown-user):is(:hover, .open) {
  --menu-dpd-tglr-bg-color: var(--menu-dpd-tglr-active-bg-color);
}
.service-bar-user-lang-menu .header-nav .dropdown-toggle {
  color: inherit;
  background-color: inherit;
  text-decoration: none;
  cursor: pointer;
  font-size: inherit;
}
.service-bar-user-lang-menu .header-nav .dropdown-menu li {
  color: var(--menu-dpd-menu-item-text-color);
  background-color: var(--menu-dpd-menu-item-bg-color);
}
.service-bar-user-lang-menu .header-nav .dropdown-menu li:hover {
  --menu-dpd-menu-item-bg-color: var(--color-gray-100);
}
.service-bar-user-lang-menu .header-nav .dropdown-menu a {
  color: inherit;
  background-color: inherit;
}
.service-bar-user-lang-menu #language_selector_container > li.dropdown-user a.dropdown-toggle {
  padding: 0 1.5rem;
  height: var(--service-bar-height);
  display: flex;
  align-items: center;
  gap: 6px;
  line-height: 1;
}
.service-bar-user-lang-menu #language_selector_container > li.dropdown-user a.dropdown-toggle i {
  font-size: 16px;
  margin-inline-start: 0.8rem;
}
.service-bar-user-lang-menu #language_selector_container > li.dropdown-user.open ul.dropdown-menu {
  padding: 0;
  inset-inline-end: 0;
  inset-inline-start: inherit;
  border-radius: 0 6px 6px 6px;
  min-width: 100%;
}
.service-bar-user-lang-menu #language_selector_container > li.dropdown-user.open ul.dropdown-menu li:first-child a {
  border-radius: 0 6px 0 0;
}
.service-bar-user-lang-menu #language_selector_container > li.dropdown-user.open ul.dropdown-menu li:last-child a {
  border-radius: 0 0 6px 6px;
}
@media screen and (max-width: 768px) {
  .service-bar-user-lang-menu #language_selector_container > li.dropdown-user {
    padding: 0;
  }
}
.service-bar-user-lang-menu #connect li.dropdown.user {
  height: 100%;
}
.service-bar-user-lang-menu #connect li.dropdown.user a {
  text-transform: none;
  font-weight: 600;
}
.service-bar-user-lang-menu #connect li.dropdown.user a.dropdown-toggle.left-part {
  margin: 0;
  border: none;
  padding-inline-start: 1.5rem;
  padding-inline-end: 0;
  border-radius: 0;
}
.service-bar-user-lang-menu #connect li.dropdown.user a.dropdown-toggle.left-part i.user-icon {
  display: none;
}
.service-bar-user-lang-menu #connect li.dropdown.user a.dropdown-toggle.left-part span.user-name-text {
  padding: 0;
}
@media screen and (max-width: 768px) {
  .service-bar-user-lang-menu #connect li.dropdown.user a.dropdown-toggle.left-part span.user-name-text {
    display: block;
  }
}
.service-bar-user-lang-menu #connect li.dropdown.user a.dropdown-toggle.left-part:nth-last-child(2) {
  padding-inline-end: 1.5rem;
  cursor: default;
}
.service-bar-user-lang-menu #connect li.dropdown.user a.dropdown-toggle.user-dropdown-toggle {
  border: none;
  padding-inline-end: 1.3rem;
  padding-inline-start: 1.3rem;
  border-radius: 0;
}
.service-bar-user-lang-menu #connect li.dropdown.user a.dropdown-toggle.user-dropdown-toggle i.angle-icon {
  margin-inline-end: 0;
  font-weight: 300;
  font-size: 16px;
}
.service-bar-user-lang-menu #connect li.dropdown.user a.dropdown-toggle.user-dropdown-toggle:last-child {
  display: none;
}
.service-bar-user-lang-menu #connect li.dropdown.user.open ul.dropdown-menu {
  width: 100%;
  min-width: fit-content;
  padding: 0;
  border-radius: 0 0 6px 6px;
}
.service-bar-user-lang-menu #connect li.dropdown.user.open ul.dropdown-menu li.divider {
  margin: 0;
  background-color: rgba(70, 81, 170, 0.1);
  height: 1px;
}
.service-bar-user-lang-menu #connect li.dropdown.user.open ul.dropdown-menu li.divider:first-child {
  display: none;
}
.service-bar-user-lang-menu #connect li.dropdown.user.open ul.dropdown-menu li a {
  padding: 7px 20px;
}
.service-bar-user-lang-menu #connect li.dropdown.user.open ul.dropdown-menu li:last-child a {
  border-radius: 0 0 6px 6px !important;
}
.service-bar-user-lang-menu #connect li:not(.dropdown) {
  height: 100%;
}
.service-bar-user-lang-menu #connect li:not(.dropdown) a {
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 0 !important;
  margin: 0 !important;
  padding: 0 2rem;
  text-transform: none;
  font-weight: 600;
  box-shadow: none !important;
}
.service-bar-user-lang-menu #connect a.user:hover {
  background-color: var(--menu-dpd-tglr-active-bg-color);
}
@media screen and (max-width: 1200px) {
  .service-bar-user-lang-menu {
    margin-inline-end: 0;
  }
}
@media screen and (max-width: 680px) {
  .service-bar-user-lang-menu .headerbar-right {
    display: none;
    position: absolute;
    z-index: 9999;
    inset-inline-end: 0;
    background: #4a6288;
    top: var(--service-bar-height);
  }
  .service-bar-user-lang-menu::after {
    content: "☰";
    display: block;
  }
  .service-bar-user-lang-menu:hover .headerbar-right,
  .service-bar-user-lang-menu:focus-within .headerbar-right {
    display: block;
  }
}
.part-b-menu-bar {
  --menu-bar-bg-color: var(--color-gray-100);
  --menu-dpd-tglr-active-bg-color: var(--color-gray-200);
}
@media (max-width: 768px) {
  .user-steps-menu .user-steps-menu-list {
    padding: 0;
  }
  .user-steps-menu .user-steps-menu-list .chevron {
    display: none;
  }
  .user-steps-menu .navbar-collapse .user-steps-menu-list li.active {
    display: none;
  }
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 1rem !important;
}
.mb-2 {
  margin-bottom: 2rem !important;
}
.mb-3 {
  margin-bottom: 3rem !important;
}
.mb-4 {
  margin-bottom: 4rem !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 1rem !important;
}
.mt-2 {
  margin-top: 2rem !important;
}
.mt-3 {
  margin-top: 3rem !important;
}
.mx-0 {
  margin-inline-start: 0 !important;
  margin-inline-end: 0 !important;
}
.ml-0 {
  margin-inline-start: 0 !important;
}
.ml-1 {
  margin-inline-start: 1rem !important;
}
.ml-2 {
  margin-inline-start: 2rem !important;
}
.mr-0 {
  margin-inline-end: 0 !important;
}
.mr-1 {
  margin-inline-end: 1rem !important;
}
.mr-2 {
  margin-inline-end: 2rem !important;
}
.mx-1 {
  margin-inline-start: 1rem !important;
  margin-inline-end: 1rem !important;
}
.mx-2 {
  margin-inline-start: 2rem !important;
  margin-inline-end: 2rem !important;
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.my-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.my-2 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}
.m-0 {
  margin: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pb-1 {
  padding-bottom: 1rem !important;
}
.pb-2 {
  padding-bottom: 2rem !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pt-1 {
  padding-top: 1rem !important;
}
.pt-2 {
  padding-top: 2rem !important;
}
.px-0 {
  padding-inline-start: 0 !important;
  padding-inline-end: 0 !important;
}
.px-1 {
  padding-inline-start: 1rem !important;
  padding-inline-end: 1rem !important;
}
.px-2 {
  padding-inline-start: 2rem !important;
  padding-inline-end: 2rem !important;
}
.px-3 {
  padding-inline-start: 3rem !important;
  padding-inline-end: 3rem !important;
}
.pl-0 {
  padding-inline-start: 0 !important;
}
.pl-1 {
  padding-inline-start: 1rem !important;
}
.pl-2 {
  padding-inline-start: 2rem !important;
}
.pr-0 {
  padding-inline-end: 0 !important;
}
.pr-1 {
  padding-inline-end: 1rem !important;
}
.pr-2 {
  padding-inline-end: 2rem !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.py-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.py-2 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}
.p-0 {
  padding: 0 !important;
}
.w-100 {
  width: 100%;
}
.h-auto {
  height: auto !important;
}
.d-inline-block {
  display: inline-block !important;
}
.btn-default {
  color: #313335;
}
.btn-default:not([data-toggle="tooltip"]) {
  border-color: rgba(64, 68, 81, 0.16);
}
#partb-fields-changed {
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 18px;
  text-align: center;
  line-height: 20px;
  position: fixed;
  top: calc(50% - 10px);
  z-index: 1041;
  inset-inline-end: 29px;
}
.button {
  text-decoration: none;
  border-radius: 2px !important;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16) !important;
  width: auto;
  padding: 0.5rem 1rem;
  display: block;
  text-align: center;
  transition: all 0.3s;
  line-height: 1;
}
.button.button-primary {
  color: #ffffff;
  background-color: #3C8FB9;
  border-color: #3C8FB9;
}
.button.button-primary:active,
.button.button-primary:focus,
.button.button-primary:hover {
  border-color: #3C8FB9 !important;
  background-color: #3680a6 !important;
  color: #ffffff;
}
.button.button-primary.disabled {
  color: #ffffff !important;
  background-color: rgba(60, 143, 185, 0.5) !important;
}
.button.button-success {
  color: #ffffff;
  background-color: #80C49B;
  border-color: #80C49B;
}
.button.button-success:active,
.button.button-success:focus,
.button.button-success:hover {
  border-color: #80C49B !important;
  background-color: #6fbc8d !important;
  color: #ffffff;
  outline: none;
}
.button.button-success.disabled {
  color: #ffffff !important;
  background-color: rgba(111, 188, 141, 0.5) !important;
}
.button.button-warning {
  color: #ffffff;
  background-color: #FFAF89;
  border-color: #FFAF89;
}
.button.button-warning:active,
.button.button-warning:focus,
.button.button-warning:hover {
  border-color: #FFAF89 !important;
  background-color: #ff9e6f !important;
  color: #ffffff;
  outline: none;
}
.button.button-warning.disabled {
  color: #ffffff !important;
  background-color: rgba(255, 158, 111, 0.5) !important;
}
.button.flying-button {
  position: fixed;
  inset-inline-start: calc(100% - 38px);
  top: 50%;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  z-index: 1040;
  padding: 2rem 2.5rem;
  border: none !important;
  transition: all 0.2s;
}
.button.flying-button.correction-drawer-opener {
  top: calc(50% - 42px);
}
.button.flying-button.correction-drawer-opener,
.button.flying-button.draft-save {
  align-items: baseline;
  border-radius: 32px 0 0 32px !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16) !important;
  display: flex;
  gap: 10px;
  line-height: 13px;
  padding: 8px 10px;
  text-transform: none;
  white-space: nowrap;
  background: white;
}
.button.flying-button.correction-drawer-opener span,
.button.flying-button.draft-save span {
  width: auto !important;
  transition: all 0.2s;
  margin-inline: 0;
}
.button.flying-button.correction-drawer-opener i,
.button.flying-button.draft-save i {
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 32px;
  color: white;
  transition: all 0.2s;
}
.button.flying-button.correction-drawer-opener i.bg-warning,
.button.flying-button.draft-save i.bg-warning {
  color: #FF7233;
  background: #FFF0EB;
}
.button.flying-button.correction-drawer-opener i.bg-success,
.button.flying-button.draft-save i.bg-success {
  background: #e8f7f1;
  color: #10b981;
}
.button.flying-button.correction-drawer-opener.loading i,
.button.flying-button.draft-save.loading i {
  animation: fa-spin 1s infinite steps(8);
}
.button.flying-button.correction-drawer-opener.loading i:before,
.button.flying-button.draft-save.loading i:before {
  content: "\f110";
}
.button.flying-button.correction-drawer-opener:where(:hover, :focus),
.button.flying-button.draft-save:where(:hover, :focus) {
  box-shadow: -10px 5px 30px rgba(162, 162, 162, 0.3) !important;
}
.button.flying-button.correction-drawer-opener:where(:hover, :focus) span,
.button.flying-button.draft-save:where(:hover, :focus) span {
  margin-inline: 0 !important;
}
.button.flying-button.correction-drawer-opener:where(:hover, :focus) i.bg-warning,
.button.flying-button.draft-save:where(:hover, :focus) i.bg-warning {
  background: #FF7233;
  color: #FFF0EB;
}
.button.flying-button.correction-drawer-opener:where(:hover, :focus) i.bg-success,
.button.flying-button.draft-save:where(:hover, :focus) i.bg-success {
  background: #10b981;
  color: #e8f7f1;
}
.button.flying-button.correction-drawer-opener::before,
.button.flying-button.draft-save::before {
  display: none;
}
.button.flying-button.btn2 {
  top: 30%;
  height: 55px;
}
.button.flying-button span {
  width: 0 !important;
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
  transition: all 0.2s;
  font-family: "Open Sans", sans-serif, Helvetica, Arial, sans-serif;
}
.button.flying-button:focus,
.button.flying-button:hover {
  border: none !important;
  outline: none;
  transition: all 0.2s;
  inset-inline-start: unset;
  inset-inline-end: 0px;
}
.button.flying-button:focus span,
.button.flying-button:hover span {
  width: auto !important;
  margin-inline-start: 1rem;
  transition: all 0.2s;
}
.button.flying-button:before {
  font-weight: 700;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  display: inline-block;
  vertical-align: baseline;
}
.button.flying-button.loading:before {
  content: "\f110";
  animation: fa-spin 1s infinite steps(8);
}
.button.button-grey {
  color: #ffffff;
  background-color: #9AA7B7;
  border-color: #9AA7B7;
}
.button.button-grey:active,
.button.button-grey:focus,
.button.button-grey:hover {
  color: #ffffff;
  border-color: #9AA7B7 !important;
  background-color: #8b9aac !important;
}
.button.button-grey.disabled {
  color: #ffffff;
  background-color: rgba(154, 167, 183, 0.5) !important;
}
@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.label-status {
  font-size: 12px;
  font-weight: 500;
  border: none;
  background-color: transparent !important;
  position: relative;
  padding: 0 !important;
  color: #313335;
  text-transform: uppercase;
}
.label-status::before {
  display: none;
}
.label-status.warning {
  color: #FFAF89;
}
.label-default:not(.label-status) {
  background-color: #c7ced7;
}
.label-info:not(.label-status) {
  background-color: #92C8DC;
}
.label-success:not(.label-status) {
  background-color: #7BC39B;
}
.label-warning:not(.label-status) {
  background-color: #FFAF89;
}
.label-danger:not(.label-status) {
  background-color: #D56D72;
}
.popover-file-statuses > span {
  display: inline-block;
  vertical-align: middle;
  margin-inline-end: 6px;
  margin-bottom: 1px;
}
table.table {
  width: 100%;
  margin: 0;
}
table.table > thead > tr > th {
  color: #186288;
  font-weight: 600;
  font-size: 11px;
  text-transform: uppercase;
  padding: 1rem 2rem;
  line-height: 1;
  text-align: start;
  letter-spacing: 0.125rem;
  position: relative;
}
table.table > thead > tr > th .sortorder {
  position: absolute;
  top: 0.775rem;
  inset-inline-end: 2rem;
}
table.table > thead > tr > th .sortorder:after {
  position: absolute;
  top: 0;
  content: "\f0d8";
  font-family: var(--fontawesome-font);
  font-weight: 700;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  color: #c7ced7;
  font-size: 1.5rem;
  border: none;
}
table.table > thead > tr > th .sortorder.reverse:after {
  content: "\f0d7";
}
table.table > thead > tr > th:hover {
  background-color: #efefef;
}
table.table > thead > tr.filters > td {
  vertical-align: middle;
  padding: 1rem 0.5rem 1rem;
}
table.table > thead > tr.filters > td:first-child {
  padding-inline-start: 1.5rem;
}
table.table > tbody > tr > td {
  padding: 1.5rem 2rem;
  line-height: 1.5;
  text-align: start;
  font-size: 1.5rem;
}
table.table > tbody > tr > td.small {
  font-size: 1.2rem;
}
table.table > tbody > tr > td.cell-description {
  font-size: 1.4rem;
  line-height: 1.5;
}
table.table > tbody > tr > td .ink-reaction {
  opacity: 0;
  transition: all 0.3s;
}
table.table > tbody > tr > td.cell-v-middle {
  vertical-align: middle;
}
table.table > tbody > tr > td.cell-no-border {
  border: none !important;
}
table.table > tbody > tr > td.cell-link {
  font-weight: 500;
  line-height: 1.5rem;
  font-size: 1.6rem;
  color: #3C8FB9;
}
table.table > tbody > tr > td.cell-img {
  padding-top: 0;
  padding-bottom: 0;
}
table.table > tbody > tr > td .icon-small {
  width: auto;
  height: 2rem;
}
table.table > tbody > tr.row-deleting {
  background: #efefef;
}
table.table > tbody > tr.row-deleting * {
  color: #9AA7B7;
  cursor: wait;
}
table.table > tbody > tr:hover .ink-reaction {
  opacity: 1;
  transition: all 0.3s;
}
table.table > tbody > tr:hover.cell-link {
  color: #186288;
}
table.table > tbody > tr:first-child > td {
  border-top: none;
}
table.table.table-panel > tbody > tr > td {
  padding-block: 1.5rem;
  padding-inline-start: 0;
  padding-inline-end: 0.5rem;
}
table.table.table-panel > tbody > tr > td span.label {
  display: inline-block;
  padding: 4px 5px;
}
table.table th.text-center,
table.table td.text-center {
  text-align: center;
}
table.table.table-condenced > tbody > tr > td {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
table.table.business-list-table > thead > tr > th {
  color: #444444;
  text-transform: none;
  padding: 1rem;
  letter-spacing: 0;
  vertical-align: middle;
}
table.table.business-list-table > tbody > tr.active > td {
  background: #F7F7F7;
}
table.table.business-list-table > tbody > tr.active > td:nth-child(2) {
  color: #3C8FB9;
}
table.table.business-list-table > tbody > tr > td {
  padding: 1.5rem 1rem;
}
table.table.business-list-table > tbody > tr > td .form-control {
  padding-inline-start: 10px;
  height: auto;
  background-color: white;
  margin-top: -2px;
}
table.table.business-list-table > tbody > tr > td.row-toggler {
  padding: 1.5rem 0 1.5rem 1rem;
}
table.table.business-list-table > tbody > tr > td.row-toggler .fa {
  margin-top: 0.25rem;
}
table.table.business-list-table > tbody > tr > td.business-files {
  background: #F7F7F7 !important;
  border-top-color: rgba(60, 143, 185, 0.25);
}
table.table.business-list-table > tbody > tr > td.business-files thead tr th {
  background: #F7F7F7;
}
table.table.business-list-table > tbody > tr > td.business-files thead tr th:hover {
  background: #F7F7F7;
}
table.table.business-list-table > tbody > tr > td.business-files tbody tr td {
  background: #F7F7F7;
}
table.table.business-list-table > tbody > tr > td.business-files tbody tr:hover td {
  background: #f2f2f2;
}
table.table.financial-table thead tr th {
  color: black;
  padding: 2rem 1.5rem;
  border: none;
}
table.table.financial-table thead tr th:last-child {
  text-align: end;
}
table.table.financial-table tbody tr td {
  font-size: 14px;
  font-weight: 400;
  border: solid 2px white;
  padding: 1rem 1.5rem;
}
table.table.financial-table tbody tr td:last-child {
  font-weight: 500;
  text-align: end;
}
.table-paging-container {
  text-align: center;
  background-color: #F7F7F8;
}
.table-paging-container ul.pagination {
  margin: 16px 0 8px;
  border-radius: 0;
}
.table-paging-container ul.pagination li a {
  border: none;
}
.table-paging-container ul.pagination li.disabled a {
  background-color: #eeeeee;
}
.table-paging-container ul.pagination li:first-child > a,
.table-paging-container ul.pagination li:last-child > a {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.alert {
  line-height: 1.4 !important;
  font-size: 14px;
  font-weight: 400 !important;
  padding: 0 1rem !important;
  margin: 0;
  margin-bottom: 1.5rem;
  position: relative !important;
  color: #313335 !important;
  border: none !important;
  background: transparent !important;
  display: block;
  width: 100%;
}
.alert.m-0 {
  margin: 0 !important;
}
.alert:after {
  content: " " !important;
  height: calc(100% - 0.48rem) !important;
  top: 0.325rem !important;
  width: 2px !important;
  inset-inline-start: 0 !important;
  position: absolute !important;
  background: #3C8FB9 !important;
  display: block !important;
}
.alert.alert-success:after {
  background: #80C49B !important;
}
.alert.alert-warning:after {
  background: #FFAF89 !important;
}
.alert.alert-danger:after {
  background: #D56D72 !important;
}
.alert p:only-child,
.alert p:last-child {
  margin: 0 !important;
}
.ui-notification {
  margin: 0;
  background: white;
  padding: 0;
  border-radius: 0 3px 3px 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
  position: fixed;
  z-index: 999999;
  top: auto !important;
  inset-inline-start: auto !important;
  inset-inline-end: 2.4rem !important;
  bottom: 2.4rem;
  font-size: 1.8rem;
}
.ui-notification .message.toast-info,
.ui-notification .toast.toast-info {
  border-inline-start: 1px solid #b58808;
  background: #FFF2AB;
  color: #b58808;
  padding: 0.6rem 1.2rem;
}
.ui-notification .message.toast-info .toast-close-button,
.ui-notification .toast.toast-info .toast-close-button {
  color: #b58808 !important;
}
.ui-notification .message.toast-error,
.ui-notification .toast.toast-error {
  border-inline-start: 1px solid #D56D72;
}
.ui-notification .message.toast-success,
.ui-notification .toast.toast-success {
  border-inline-start: 1px solid #80C49B;
}
.ui-notification.success .message {
  border-inline-start: 1px solid #80C49B;
  background: #7BC39B;
  color: #ffffff;
  padding: 0.6rem 1.2rem;
  font-size: 1.8rem !important;
}
.ui-notification.error .message {
  border-inline-start: 1px solid #b58808;
  background: #FFF2AB;
  color: #b58808;
  padding: 0.6rem 1.2rem;
}
.ui-notification.warning .message {
  border-inline-start: 1px solid #b58808;
  background: #FFF2AB;
  color: #b58808;
  padding: 0.6rem 1.2rem;
}
.ui-notification.killed .message {
  display: none;
}
.ui-notification .toast-close-button {
  position: relative;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #9AA7B7;
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.ui-notification button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
.callback-error {
  margin: 20px 0 !important;
  border-inline-start: 1px solid #b58808;
  background: #FFF2AB;
  color: #b58808;
  padding: 0.6rem 1.2rem !important;
}
.callback-error > .panel {
  background: transparent;
}
.callback-error > .panel > .panel-heading {
  padding: 0 !important;
  margin-bottom: 1.3rem !important;
}
.callback-error > .panel > .panel-heading > h3 {
  color: #b58808 !important;
  font-size: 1.8rem !important;
  line-height: 1.8 !important;
}
.callback-error > .panel > .panel-body {
  padding: 0 !important;
}
.callback-error > .panel > .panel-body .alert {
  padding: 0 !important;
  border: none !important;
  color: #b58808 !important;
  font-size: 1.8rem !important;
  line-height: 1.8 !important;
}
.callback-error > .panel > .panel-body .alert:after {
  display: none !important;
}
ul.nav.nav-tabs {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  background-color: #f5f5f6;
  border: none !important;
  border-radius: 0;
  min-height: 54px;
}
ul.nav.nav-tabs > li {
  flex: 1;
  text-align: center;
}
ul.nav.nav-tabs > li > a {
  outline: none;
  border: none;
  border-radius: 0;
  margin-inline-end: 0;
}
ul.nav.nav-tabs > li > a:hover {
  border: none;
  background: #f0f0f0;
}
ul.nav.nav-tabs > li.active a,
ul.nav.nav-tabs > li.active a:hover,
ul.nav.nav-tabs > li.active a:focus {
  font-weight: 600;
  border-color: transparent;
}
ul.nav.nav-tabs > li.active a:hover {
  background: #ffffff;
}
.tab-pane .tabs-above {
  padding: 0 2rem;
}
.tab-pane .tabs-above ul.nav.nav-tabs {
  display: block;
  background: white;
  border-bottom: 1px solid #efefef !important;
}
.tab-pane .tabs-above ul.nav.nav-tabs[role="tablist"] {
  border-bottom: none !important;
}
.tab-pane .tabs-above ul.nav.nav-tabs li {
  height: auto;
}
.tab-pane .tabs-above ul.nav.nav-tabs li a {
  line-height: 34px;
  padding: 1rem 4rem;
  border-bottom: 1px solid transparent;
}
.tab-pane .tabs-above ul.nav.nav-tabs li a:hover,
.tab-pane .tabs-above ul.nav.nav-tabs li a:focus,
.tab-pane .tabs-above ul.nav.nav-tabs li a:active,
.tab-pane .tabs-above ul.nav.nav-tabs li a.active {
  background: transparent;
}
.tab-pane .tabs-above ul.nav.nav-tabs li:hover a,
.tab-pane .tabs-above ul.nav.nav-tabs li:focus a,
.tab-pane .tabs-above ul.nav.nav-tabs li:active a,
.tab-pane .tabs-above ul.nav.nav-tabs li.active a {
  border-bottom-color: #3C8FB9;
  background: transparent;
}
.tab-pane .tabs-above .tab-content .card {
  box-shadow: none;
  border: none !important;
  background: #F5F6F8 !important;
}
.tab-pane .tabs-above .tab-content .card > .card-body {
  margin: 0 -2rem !important;
  padding: 0 !important;
  background: #F5F6F8 !important;
}
.tab-pane .tabs-above .tab-content .card > .card-body:after,
.tab-pane .tabs-above .tab-content .card > .card-body:before {
  display: none !important;
}
.tab-pane .tabs-above .tab-content .card > .card-body .form-field-type-panel .panel {
  border-inline-start: none !important;
}
#tabs-zone {
  margin-top: 4rem;
}
#tabs-zone.panel,
#tabs-zone > .panel {
  box-shadow: none;
  background-color: transparent;
}
#tabs-zone.panel ul.nav.nav-tabs,
#tabs-zone > .panel ul.nav.nav-tabs {
  background-color: transparent;
}
#tabs-zone.panel ul.nav.nav-tabs > li,
#tabs-zone > .panel ul.nav.nav-tabs > li {
  flex: none;
}
#tabs-zone.panel ul.nav.nav-tabs > li a,
#tabs-zone > .panel ul.nav.nav-tabs > li a {
  text-transform: none;
  font-size: 2rem;
  padding: 0 3rem;
}
#tabs-zone.panel ul.nav.nav-tabs > li.hover,
#tabs-zone > .panel ul.nav.nav-tabs > li.hover,
#tabs-zone.panel ul.nav.nav-tabs > li.active,
#tabs-zone > .panel ul.nav.nav-tabs > li.active {
  box-shadow: 0 4px 3px rgba(0, 0, 0, 0.16);
  z-index: 99;
}
#tabs-zone.panel ul.nav.nav-tabs > li.hover a,
#tabs-zone > .panel ul.nav.nav-tabs > li.hover a,
#tabs-zone.panel ul.nav.nav-tabs > li.active a,
#tabs-zone > .panel ul.nav.nav-tabs > li.active a {
  color: #404351;
  background-color: #F5F6F8;
}
#tabs-zone.panel > .panel-body,
#tabs-zone > .panel > .panel-body {
  padding: 0;
}
#tabs-zone.panel > .panel-body ul.nav.nav-tabs,
#tabs-zone > .panel > .panel-body ul.nav.nav-tabs {
  background-color: #F5F6F8;
}
#tabs-zone.panel > .panel-body ul.nav.nav-tabs > li a,
#tabs-zone > .panel > .panel-body ul.nav.nav-tabs > li a {
  text-transform: none;
  font-size: 1.4rem;
}
#tabs-zone.panel > .panel-body ul.nav.nav-tabs > li.active,
#tabs-zone > .panel > .panel-body ul.nav.nav-tabs > li.active {
  box-shadow: none;
}
#tabs-zone.panel > .panel-body ul.nav.nav-tabs > li.active a,
#tabs-zone > .panel > .panel-body ul.nav.nav-tabs > li.active a {
  color: #404351;
  box-shadow: inset 0 -2px 0 #3C8FB9 !important;
  border: none !important;
}
#tabs-zone.panel > .panel-body > .tab-pane:not(#form-type-role),
#tabs-zone > .panel > .panel-body > .tab-pane:not(#form-type-role) {
  background: #F5F6F8;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.16);
}
#tabs-zone.panel > .panel-body .formio-component-form .formio-component-panel > .card,
#tabs-zone > .panel > .panel-body .formio-component-form .formio-component-panel > .card {
  margin: 0;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.16);
  background: #F5F6F8;
}
.text-success {
  color: #80C49B !important;
}
.text-danger {
  color: #D56D72 !important;
}
.text-warning {
  color: #FFAF89 !important;
}
.text-info {
  color: #3C8FB9 !important;
}
.text-right {
  text-align: end !important;
}
h3 {
  color: #313335;
  font-size: 20px;
  font-weight: 500;
  border: none;
  position: relative;
  padding: 0.5rem 0;
  margin: 1rem 0;
}
.iconed-link {
  padding-inline-start: 16px;
  position: relative;
  display: block;
}
.iconed-link > i {
  position: absolute;
  inset-inline-start: 0;
  top: 3px;
}
input.form-control:-webkit-autofill {
  background: transparent !important;
  background-color: transparent !important;
  background-image: none !important;
}
input:-webkit-autofill {
  background: transparent !important;
  background-color: transparent !important;
  background-image: none !important;
}
.dashboard > .form-group.form-field-type-panel > .panel {
  border-inline-start: none;
}
.dashboard > .form-group.form-field-type-panel > .panel .panel-heading {
  padding-top: 2.5rem;
}
.dashboard > .form-group.form-field-type-panel > .panel .panel-body {
  padding-bottom: 1.9rem;
}
.glyphicon-question-sign:before {
  content: "\F059";
  font-family: var(--fontawesome-font);
  font-weight: 700;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  color: #9AA7B7;
  font-size: 1.6rem;
  margin: 0 0.5rem;
}
.ui-select-offscreen,
.form-field-type-hidden {
  display: none;
}
.checkbox {
  margin: 0;
  line-height: 1;
}
.checkbox > label {
  padding: 0;
  min-height: auto;
  margin-bottom: 0 !important;
}
.checkbox input[type="checkbox"]:not(:last-child) {
  position: absolute;
}
.checkbox input[type="checkbox"] + :where(label, span) {
  padding-inline-start: 2rem;
  position: relative;
  display: inline;
  font-size: 14px;
  line-height: 14px;
}
.checkbox input[type="checkbox"] + :where(label, span):before {
  position: absolute;
  inset-inline-start: 0;
  top: 0px;
  content: "\f0c8";
  font-family: var(--fontawesome-font);
  font-weight: 300;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 14px;
  color: #313335;
  font-size: 16px;
  border: none;
}
.checkbox input[type="checkbox"]:checked + label:before,
.checkbox input[type="checkbox"]:checked + span:before {
  content: "\f14a";
  transform: none;
}
.checkbox input[type="checkbox"].ng-pristine.ng-valid:not(.ng-empty) + label,
.checkbox input[type="checkbox"].ng-pristine.ng-valid:not(.ng-empty) + span {
  color: #313335;
}
.checkbox input[type="checkbox"].ng-pristine.ng-valid:not(.ng-empty) + label:before,
.checkbox input[type="checkbox"].ng-pristine.ng-valid:not(.ng-empty) + span:before {
  color: #80C49B;
  font-weight: 600;
}
.checkbox input[type="checkbox"]:disabled + :where(label, span) {
  cursor: not-allowed;
}
.checkbox input[type="checkbox"]:disabled + :where(label, span):before {
  cursor: not-allowed;
}
:where(.radio-inline > .label-control, .radio-inline label) {
  margin: 0;
}
:where(.radio-inline > .label-control, .radio-inline label) input[type="radio"] {
  margin-top: 0;
}
:where(.radio-inline > .label-control, .radio-inline label) input[type="radio"] + span {
  font-weight: 400;
}
.form-field-type-file .fileSelector {
  padding: 2rem;
  background-color: #efefef;
  text-align: center;
  color: #313335;
  margin-bottom: 2rem;
}
.form-field-type-file .fileSelector > a {
  text-decoration: underline;
}
.form-field-type-file .fileSelector .glyphicon {
  margin-inline-end: 1rem;
  font-size: 1.8rem;
  color: #9AA7B7;
}
.form-field-type-file .glyphicon-asterisk {
  display: none;
}
.has-success .field-required:after {
  content: "\f00c";
  font-family: var(--fontawesome-font);
  font-weight: 300;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  color: #80C49B;
  font-size: 1.25rem;
  border: none;
}
.has-success .ui-select-match > .btn-default {
  border-color: #80C49B;
}
.has-success :where(label, .control-label, .form-control-feedback) {
  color: #313335;
}
.has-success .checkbox input[type="checkbox"]:checked + :where(label, span):before {
  font-weight: 600;
  color: #80C49B;
}
.has-error .field-required:after {
  content: "\f05e";
  font-family: var(--fontawesome-font);
  font-weight: 300;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  color: #D56D72;
  font-size: 1.25rem;
  border: none;
}
.has-error .ui-select-match > .btn-default {
  border-color: #D56D72;
}
.has-error :where(label, .control-label, .help-block, .form-control-feedback) {
  color: #313335;
}
.has-error .checkbox input[type="checkbox"] + :where(label, span):before {
  font-weight: 600;
  color: #D56D72;
}
.panel {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
  border-radius: 0;
  margin-bottom: 0;
  padding: 0;
  background-color: #F5F6F8;
}
.panel .panel-heading {
  background-color: transparent;
  border-radius: 0;
  border: none;
  padding: 2.5rem 2.5rem 0;
}
.panel .panel-heading h3.panel-title {
  color: #313335;
  font-size: 2rem;
  font-weight: 500;
  border: none;
  position: relative;
  padding: 0 0 1rem;
}
.panel .panel-heading h3 .item-count {
  font-size: 1.6rem;
  color: #9AA7B7;
}
:where(.formio-component-yourPaymentIsConfirmed, blockquote) {
  line-height: 1.4 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  padding: 1.5rem 3rem !important;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  margin-inline: -3rem;
  position: relative !important;
  color: black !important;
  border: none !important;
  background: rgba(64, 68, 81, 0.16) !important;
}
:where(.formio-component-yourPaymentIsConfirmed, blockquote):after {
  content: " " !important;
  height: 100% !important;
  top: 0 !important;
  width: 3px !important;
  inset-inline-start: 0 !important;
  position: absolute !important;
  background: #3C8FB9 !important;
  display: block !important;
}
:where(.formio-component-yourPaymentIsConfirmed, blockquote) a {
  color: #3C8FB9;
}
:where(.formio-component-yourPaymentIsConfirmed, blockquote) :where(article, p):last-child {
  margin: 0 !important;
}
:where(.formio-component-yourPaymentIsConfirmed, blockquote).alert-danger:after {
  background: #D56D72 !important;
}
:where(.formio-component-yourPaymentIsConfirmed, blockquote):where(.formio-component-yourPaymentIsConfirmed, .alert-success):after {
  background: #80C49B !important;
}
:where(.formio-component-yourPaymentIsConfirmed, blockquote).alert-warning:after {
  background: #FFAF89 !important;
}
.formio-component-yourPaymentIsConfirmed * {
  font-family: "Open Sans", sans-serif, Helvetica, Arial, sans-serif;
  color: rgba(49, 51, 53, 0.5) !important;
  line-height: 1.4 !important;
  font-size: 13px !important;
  font-weight: 400 !important;
}
.formio-component-yourPaymentIsConfirmed *.glyphicon:before {
  display: none !important;
}
.form-field-type-mycard .card .card-body {
  margin: 0 !important;
}
button.formio-component-columnsPrint,
.formio-component-columnsPrint button {
  border-radius: 2px !important;
  background-color: #9AA7B7;
  border-color: #9AA7B7;
  font-size: 14px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16) !important;
  width: 100%;
  padding: 0.8rem 1.5rem;
  transition: all 0.3s;
  color: #ffffff;
  line-height: 1;
}
button.formio-component-columnsPrint:where(:active, :focus, :hover),
.formio-component-columnsPrint button:where(:active, :focus, :hover) {
  border-color: #7c8da2;
  background-color: #7c8da2;
  color: #ffffff;
}
button.formio-component-columnsPrint {
  width: auto !important;
}
.print-button button {
  width: auto;
  padding: 0.8rem 1.5rem;
}
.print-button.formio-component-printMyForm {
  text-align: start;
}
:where(.formio-component-btnValidateEnabled, .formio-component-columnsValidateandcontinue) {
  margin-bottom: 0 !important;
}
:where(.formio-component-btnValidateEnabled, .formio-component-columnsValidateandcontinue) :where(a, button) {
  color: #ffffff;
  text-decoration: none;
  border-radius: 2px;
  background-color: #3C8FB9;
  border-color: #3C8FB9;
  font-size: 16px;
  font-weight: 500;
  min-width: fit-content;
  padding: 1.5rem 1rem;
  display: inline-flex;
  text-align: center;
  transition: all 0.3s;
  line-height: 1;
}
:where(.formio-component-btnValidateEnabled, .formio-component-columnsValidateandcontinue) :where(a, button):where(:active, :focus, :hover) {
  background-color: #3680a6;
  color: #ffffff;
}
:where(.formio-component-btnValidateEnabled, .formio-component-columnsValidateandcontinue) :where(a, button).disabled {
  color: #ffffff !important;
  background-color: rgba(60, 143, 185, 0.5);
}
.formio-component-columnsValidateandcontinue button {
  justify-content: center;
}
.formio-component-btnSwornSave {
  margin-top: 1rem;
  margin-bottom: 0;
}
.formio-component-btnSwornSave button {
  color: #ffffff;
  text-decoration: none;
  border-radius: 2px !important;
  background-color: #80C49B;
  border-color: #80C49B;
  font-size: 1.6rem;
  font-weight: 500;
  text-transform: uppercase;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16) !important;
  width: auto;
  padding: 0.5rem 1rem;
  display: block;
  text-align: center;
  transition: all 0.3s;
  line-height: 1;
}
.formio-component-btnSwornSave button:where(:active, :focus, :hover) {
  border-color: #80C49B;
  background-color: #6fbc8d;
  color: #ffffff;
}
.formio-component-btnSwornSave button.disabled {
  color: #ffffff !important;
  background-color: rgba(128, 196, 155, 0.5);
}
.formio-data-grid > .datagrid-table {
  margin: 0;
}
.formio-data-grid > .datagrid-table + .datagrid-add {
  margin: 0 3rem !important;
}
.formio-data-grid > .panel {
  box-shadow: none;
}
.formio-data-grid > .panel .panel-body {
  padding: 0 3rem;
}
.formio-data-grid > .panel .panel-body .datagrid-add {
  margin: 1.5rem 0;
}
.formio-data-grid .list-group {
  overflow-x: auto;
  background-color: white;
  border-radius: 3px;
  border: solid 1px #D5D6D9;
  width: fit-content;
  max-width: 100%;
}
.formio-data-grid .list-group:empty {
  margin: 0;
}
.formio-data-grid .list-group .list-group-item {
  width: fit-content;
  display: block;
  margin-bottom: 0;
  background-color: white;
  border: none;
  border-top: 1px solid rgba(49, 51, 53, 0.15);
  padding: 0 2.5rem 0 2.5rem;
  line-height: 1;
  text-align: start;
  font-size: 16px;
  border-radius: 3px 3px 0 0 !important;
}
.formio-data-grid .list-group .list-group-item.list-group-header {
  font-weight: 500;
  font-size: 12px;
  padding: 0 2.5rem 0 2.5rem;
  letter-spacing: 0.5px;
  background: #eff0f1;
  border-top: none;
  border-bottom: 1px solid rgba(49, 51, 53, 0.15);
}
.formio-data-grid .list-group .list-group-item.list-group-header .row {
  display: grid;
  grid-auto-columns: 200px;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fit, 200px) 70px !important;
  min-width: min-content;
  max-width: max-content;
}
.formio-data-grid .list-group .list-group-item.list-group-header .row > div {
  width: auto;
  word-break: break-word;
  padding-top: 5px;
  padding-bottom: 5px;
  border-inline-end: solid 1px #D5D6D9;
}
.formio-data-grid .list-group .list-group-item.list-group-header .row > div:last-of-type {
  position: sticky;
  inset-inline-end: 0;
  background-color: #eff0f1;
  height: 100%;
  max-width: 100px;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  justify-self: flex-end;
  border-inline-end: none;
}
.formio-data-grid .list-group .list-group-item.list-group-header .row > div:empty {
  display: none;
}
.formio-data-grid .list-group .list-group-item.list-group-header .row::after,
.formio-data-grid .list-group .list-group-item.list-group-header .row::before {
  display: none !important;
}
.formio-data-grid .list-group .list-group-item.list-group-header .row > div:blank {
  display: none;
}
.formio-data-grid .list-group .list-group-item.list-group-header + .list-group-footer {
  display: none;
}
.formio-data-grid .list-group .list-group-item.list-group-header + .list-group-item {
  border-top: none;
  box-shadow: 0 1px 0 #e3e3e5;
}
.formio-data-grid .list-group .list-group-item:not(.list-group-header) > div > edit-grid-row > div > render-template > .row {
  display: grid;
  grid-auto-columns: 200px;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fit, 200px) 70px;
  min-width: min-content;
  max-width: max-content;
}
.formio-data-grid .list-group .list-group-item:not(.list-group-header) > div > edit-grid-row > div > render-template > .row > div {
  width: auto;
  word-break: break-word;
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 1.2;
}
.formio-data-grid .list-group .list-group-item:not(.list-group-header) > div > edit-grid-row > div > render-template > .row > div:last-of-type {
  position: sticky;
  inset-inline-end: 0;
  background-color: white;
  height: 100%;
  max-width: 100px;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  justify-self: flex-end;
}
.formio-data-grid .list-group .list-group-item:not(.list-group-header) > div > edit-grid-row > div > render-template > .row > div:empty {
  display: none;
}
.formio-data-grid .list-group .list-group-item:not(.list-group-header) > div > edit-grid-row > div > render-template > .row::after,
.formio-data-grid .list-group .list-group-item:not(.list-group-header) > div > edit-grid-row > div > render-template > .row::before {
  display: none;
}
.formio-data-grid .list-group .list-group-item:not(.list-group-header):last-of-type {
  border-bottom: none;
}
.formio-data-grid .list-group .list-group-item.list-group-footer .row {
  display: grid;
  grid-auto-columns: 200px;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fit, 200px) 70px !important;
  min-width: min-content;
  max-width: max-content;
}
.formio-data-grid .list-group .list-group-item.list-group-footer .row > div {
  width: auto;
  word-break: break-word;
  padding-top: 8px;
  padding-bottom: 8px;
}
.formio-data-grid .list-group .list-group-item.list-group-footer .row::after,
.formio-data-grid .list-group .list-group-item.list-group-footer .row::before {
  display: none;
}
.formio-data-grid .list-group .list-group-item:hover .btn-icon-toggle {
  opacity: 1;
}
.formio-data-grid .list-group .list-group-item .edit-body {
  margin-bottom: 0;
  padding-bottom: 1.5rem;
  margin-top: 0;
  padding-top: 1.5rem;
}
.formio-data-grid .list-group .list-group-item .editgrid-body {
  padding: 1.5rem 3rem;
  margin: -1.5rem -3rem;
}
.formio-data-grid .list-group .list-group-item .editgrid-body .form-group.form-field-type-columns > .row {
  align-items: baseline;
}
.formio-data-grid .list-group .list-group-item .editgrid-body .form-group.form-field-type-columns > .row > div {
  flex: 1 100%;
}
.formio-data-grid .list-group .btn-icon-toggle {
  width: auto;
  padding-inline-start: 0.5rem;
  padding-inline-end: 0.5rem;
  opacity: 0;
  transition: all 0.3s;
  color: #9AA7B7;
}
.formio-data-grid .list-group .btn-icon-toggle:where(:active, :focus, :hover) {
  background: transparent;
  color: #313335;
}
.formio-data-grid .datagrid-add {
  margin: 0.5rem 0rem 2rem;
}
.formio-data-grid .datagrid-add a {
  border: 1px solid #3C8FB9;
  color: #3C8FB9;
  background-color: transparent;
  border-radius: 2px !important;
  font-weight: 400;
  padding: 0.5rem 1rem;
  line-height: 1;
}
.formio-data-grid .datagrid-add a .glyphicon {
  display: none;
}
.formio-data-grid .datagrid-add a:where(:active, :focus, :hover) {
  border-color: #3680a6;
  color: #3680a6;
  background-color: #ffffff;
}
@media (max-width: 768px) {
  .form-field-type-columns > .row {
    flex-direction: column;
    position: relative;
  }
  .form-field-type-columns > .row > div + div {
    margin-top: 2rem;
  }
}
/* Inspector */
#entreprise-grid #services-select {
  width: initial;
  max-width: 240px;
}
.checkbox-styled:not(ie8) input ~ span:before,
.radio-styled:not(ie8) input ~ span:before {
  border-color: #9AA7B7;
  border-width: 1px;
}
.checkbox-styled:not(ie8).checkbox-info input:checked ~ span:before,
.radio-styled:not(ie8).checkbox-info input:checked ~ span:before,
.checkbox-styled:not(ie8).radio-info input:checked ~ span:before,
.radio-styled:not(ie8).radio-info input:checked ~ span:before {
  border-color: #3C8FB9;
  background: #3C8FB9;
}
.checkbox-styled:not(ie8).checkbox-info input ~ span:after,
.radio-styled:not(ie8).checkbox-info input ~ span:after,
.checkbox-styled:not(ie8).radio-info input ~ span:after,
.radio-styled:not(ie8).radio-info input ~ span:after {
  background-color: #ffffff;
}
.radio-styled:not(ie8) input:checked ~ span:after {
  inset-inline-start: 5px;
  top: 10px;
  width: 6px;
  height: 6px;
}
.formio-component-swearColumns > div > div:first-child {
  flex: 0 !important;
}
.formio-component-swearColumns > div > div .formio-component-swearCheckbox {
  padding-top: 0.2rem;
}
.swear-content {
  font-size: 1.8rem !important;
}
.ruler-xs hr {
  margin-top: 0.5rem !important;
  margin-bottom: 1.5rem !important;
  margin-inline: 0 !important;
}
.form-field-type-htmlelement hr {
  margin: 1.5rem 0;
}
input[disabled],
input[disabled] ~ .input-group-addon,
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  color: #313335;
  opacity: 1;
  background: #efefef;
  text-align: center;
}
input[disabled] ~ .input-group-addon {
  color: #9AA7B7 !important;
}
input[disabled] {
  text-align: start;
}
.ui-checkbox {
  position: relative;
  margin: 0 20px;
}
.ui-checkbox.ui-checkbox-nolabel {
  margin: 0;
}
.ui-checkbox.ui-checkbox-nolabel input[type="checkbox"] + span:before {
  margin: 0;
}
.ui-checkbox input[type="checkbox"] {
  display: none;
}
.ui-checkbox input[type="checkbox"] + span {
  font-weight: normal;
}
.ui-checkbox input[type="checkbox"] + span:hover {
  cursor: pointer;
}
.ui-checkbox input[type="checkbox"] + span:before {
  content: "\f205";
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  display: inline-block;
  vertical-align: middle;
  margin-inline-end: 10px;
  margin-inline-start: -20px;
  color: #9AA7B7;
  font-size: 2rem;
  transform: scale(-1, 1);
}
.ui-checkbox input[type="checkbox"]:disabled + span:before {
  opacity: 0.65;
  cursor: no-drop;
}
.ui-checkbox input[type="checkbox"]:disabled + span:hover {
  cursor: not-allowed;
}
.ui-checkbox input[type="checkbox"]:checked + span:before {
  content: "\f205";
  font-family: "Font Awesome 5 Pro";
  transform: initial;
  font-weight: 900;
  color: #80C49B;
}
.editable-page-title i {
  opacity: 0.25;
  transition: all 0.3s;
  font-size: 1.325rem;
  vertical-align: middle;
  margin-top: -2px;
}
.editable-page-title:hover i {
  opacity: 1;
  transition: all 0.3s;
}
.editable-page-title-edit-zone {
  position: relative;
}
.editable-page-title-edit-zone input {
  border: none;
  padding: 0 48px 0 0;
  width: 100%;
  background-color: transparent;
  border-bottom: 1px dotted #3C8FB9;
}
.editable-page-title-edit-zone input:focus {
  outline: none;
}
.editable-page-title-edit-zone i {
  position: absolute;
  top: 8px;
  font-size: 1.325rem;
}
.editable-page-title-edit-zone i.fa-times {
  inset-inline-end: 24px;
}
.editable-page-title-edit-zone i.fa-check {
  inset-inline-end: 4px;
}
.editable-page-title-edit-zone span[form="form"] a.fancy.img-thumbnail {
  width: auto;
  height: auto;
}
.editable-page-title-edit-zone span[form="form"] + span {
  position: relative;
  top: -2.7rem;
}
:where(.formio-component-costtable, .payment-table-form) p {
  margin: 0 !important;
}
:where(.formio-component-costtable, .payment-table-form) hr {
  border-top-color: #efefef !important;
}
:where(.formio-component-costtable, .payment-table-form) :where(.form-field-type-mycontent, .card-body, blockquote) {
  line-height: inherit !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  padding: 0 !important;
  margin: 0 !important;
  position: relative !important;
  border: none !important;
  background: transparent !important;
}
:where(.formio-component-costtable, .payment-table-form) :where(.form-field-type-mycontent, .card-body, blockquote):after {
  display: none !important;
}
:where(.formio-component-costtable, .payment-table-form) :where(.form-field-type-mycontent, .card-body, blockquote).text-lg {
  font-size: 1.75rem !important;
  font-weight: bold !important;
}
:where(.formio-component-costtable, .payment-table-form) > form > .form-field-type-panel:first-of-type .form-field-type-columns:last-of-type {
  border-top: 1px solid #efefef;
  margin-top: 1rem !important;
  padding-top: 0.5rem;
}
:where(.formio-component-costtable, .payment-table-form) #costtable {
  padding: 1rem 1rem 0;
}
:where(.formio-component-costtable, .payment-table-form) #costtable table tbody tr:first-of-type {
  border-bottom: solid 1px #efefef;
}
:where(.formio-component-costtable, .payment-table-form) #costtable table tbody tr:first-of-type td {
  padding: 6px 0 5px;
  font-size: 14px;
}
:where(.formio-component-costtable, .payment-table-form) #costtable table tbody tr:first-of-type + tr td {
  padding: 20px 0 0;
}
:where(.formio-component-costtable, .payment-table-form) #costtable table tbody tr td {
  padding: 6px 0 0;
}
:where(.formio-component-costtable, .payment-table-form) > form .form-group.form-field-type-panel .panel .panel-body .form-field-type-columns .form-field-type-checkbox {
  margin-top: 0;
}
fieldset {
  border: solid 1px rgba(64, 68, 81, 0.16);
  border-radius: 2px;
  padding: 16px 16px 10px;
}
fieldset legend {
  display: inline-block;
  width: auto;
  padding: 0 8px;
  color: #999;
  border-radius: 2px;
  margin-bottom: 0;
  margin-inline-start: -8px;
  font-weight: 400;
}
fieldset legend:after,
fieldset legend:before {
  display: none;
}
fieldset :where(.formio-component-yourPaymentIsConfirmed, .form-field-type-mycontent, blockquote) {
  margin: 0 !important;
  margin-bottom: 1.5rem !important;
}
.component-product-list-container button:not(.close) {
  align-self: flex-start;
  border: 1px solid #3C8FB9;
  color: #3C8FB9;
  background-color: #ffffff;
  border-radius: 6px;
  font-weight: 500;
  order: 2;
  margin-bottom: 1.5rem;
}
.component-product-list-container .component-product-list-modal {
  background: rgba(49, 51, 53, 0.5);
}
.component-product-list-container .component-product-list-modal .modal-dialog {
  position: fixed;
  top: 0;
  inset-inline-end: 0;
  bottom: 0;
  margin-top: 146px;
  margin-bottom: 0;
  height: calc(100vh - 146px);
  transition-duration: 0.15s;
  width: calc(100% / 3 * 2);
}
.component-product-list-container .component-product-list-modal .modal-dialog .modal-title {
  font-size: 18px;
  text-transform: uppercase;
}
.component-product-list-container .component-product-list-modal .modal-dialog .modal-content {
  border-radius: 2px 0 0 0;
  height: 100%;
}
.component-product-list-container .component-product-list-modal .modal-dialog .modal-content .modal-body {
  height: 100%;
}
.component-product-list-container .component-product-list-modal .modal-dialog .modal-content .modal-body iframe {
  width: 100% !important;
  height: calc(100% - 62px) !important;
}
.component-product-list-container .component-product-list-modal .modal-dialog .modal-header {
  display: flex;
  justify-content: space-between;
}
.component-product-list-container .component-product-list-modal.fade .modal-dialog {
  transform: translateX(100%);
}
.component-product-list-container .component-product-list-modal.fade.in .modal-dialog {
  transform: translateX(0%);
}
.component-product-list-container .component-product-list-modal button.close.mt-2 {
  margin-top: 0px !important;
  width: 40px;
  font-size: 34px;
  opacity: 0.8;
}
.component-product-list-container .component-product-list-modal button.close.mt-2 span {
  position: relative;
  top: -2px;
  font-weight: 400;
}
.affix-menu-size-container.affix ~ .section-body .component-product-list-container .modal-dialog {
  margin-top: 66px;
  height: calc(100vh - 66px);
}
.affix-menu-size-container.affix ~ .section-body .component-product-list-container .modal-dialog .modal-content .modal-body iframe {
  height: calc(100% - 62px) !important;
}
body:has(.component-product-list-modal.in) header#header {
  z-index: 1000;
}
.form-field-type-mytabs .form-group.form-field-type-panel > .panel > .panel-heading {
  border: none !important;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}
.form-field-type-mytabs table.table > tbody > tr > :where(th, td) {
  padding: 1rem 2rem;
  vertical-align: middle;
  line-height: 1.2;
}
.center-blocks .form-field-type-mycontent {
  top: 50%;
  transform: translate(0, -50%);
}
.center-blocks .form-field-type-radio {
  top: 50%;
  transform: translate(0, -50%);
  position: relative;
  max-height: 20px;
}
.address-block .form-field-type-columns {
  background: #F7F7F7;
  margin: 0 0 30px !important;
}
.address-block .form-field-type-columns .form-field-type-mycontent {
  height: 100%;
  max-width: 100%;
  margin: 0 !important;
  background: none !important;
}
.address-block .form-field-type-columns .col-sm-5 .form-field-type-mycontent:after {
  background: none !important;
}
.transactions-statuses .alert {
  color: white !important;
  text-transform: uppercase;
  font-size: 2rem !important;
  padding: 2.4rem !important;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
  margin-bottom: 3rem !important;
  font-weight: 500 !important;
}
.transactions-statuses .alert.alert-success {
  background-color: #84C29B !important;
}
.transactions-statuses .alert.alert-warning {
  background-color: #99C8DB !important;
}
.transactions-statuses .alert.alert-danger {
  background-color: #CD6E74 !important;
}
.transactions-statuses .alert::after {
  display: none;
}
/* New formio */
.card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
  border-radius: 0;
  margin-bottom: 0;
  padding: 0;
  background-color: #F5F6F8;
}
.card .card-header {
  background-color: transparent;
  border-radius: 0;
  border: none;
  padding: 2.5rem 2.5rem 0;
}
.card .card-header:only-child {
  padding-bottom: 2.5rem;
}
.card .card-header .card-title {
  color: #313335;
  font-size: 2rem;
  font-weight: 500;
  border: none;
  position: relative;
  padding: 0 0 1rem;
}
.card .card-header .item-count {
  font-size: 1.6rem;
  color: #9AA7B7;
}
.formio-component-modal-wrapper .formio-component,
.formio-form .formio-component {
  margin: 3rem 0 0;
  padding: 0;
  /* Custom Classes */
}
.formio-component-modal-wrapper .formio-component [data-tooltip].fa-question-circle::before,
.formio-form .formio-component [data-tooltip].fa-question-circle::before {
  left: inherit !important;
  margin-bottom: inherit !important;
  margin-left: inherit !important;
  right: inherit !important;
  top: inherit !important;
  position: inherit !important;
  bottom: inherit !important;
  pointer-events: all !important;
  transition: inherit !important;
  background: inherit !important;
  transform: inherit !important;
  border: inherit !important;
  transform-origin: inherit !important;
  box-shadow: inherit !important;
  content: "\f059" !important;
  opacity: inherit !important;
  height: inherit !important;
}
.formio-component-modal-wrapper .formio-component [data-tooltip].fa-question-circle::after,
.formio-form .formio-component [data-tooltip].fa-question-circle::after {
  margin-bottom: inherit !important;
  right: inherit !important;
  content: "" !important;
  left: inherit !important;
  top: inherit !important;
  transition: inherit !important;
  box-shadow: inherit !important;
  transform-origin: inherit !important;
  padding: inherit !important;
  opacity: inherit !important;
  pointer-events: all !important;
  border: inherit !important;
  transform: inherit !important;
  font: inherit !important;
}
.formio-component-modal-wrapper .formio-component.formio-hidden:first-child + .formio-component,
.formio-form .formio-component.formio-hidden:first-child + .formio-component {
  margin-top: 0;
}
.formio-component-modal-wrapper .formio-component p,
.formio-form .formio-component p {
  margin: 0 0 1.5rem;
  line-height: 1.5;
  font-weight: 400;
}
.formio-component-modal-wrapper .formio-component p:empty,
.formio-form .formio-component p:empty {
  display: none;
}
.formio-component-modal-wrapper .formio-component :where(label, .col-form-label),
.formio-form .formio-component :where(label, .col-form-label) {
  text-align: var(--label-text-align, start);
  width: 100% !important;
  margin-bottom: 0.75rem;
  font-size: 14px;
  color: #313335;
  text-transform: none !important;
  line-height: 1;
  margin-top: 0;
}
.formio-component-modal-wrapper .formio-component :where(label, .col-form-label)[style*="float: left; width: 30%; margin-inline-end: 3%; text-align: start;"],
.formio-form .formio-component :where(label, .col-form-label)[style*="float: left; width: 30%; margin-inline-end: 3%; text-align: start;"] {
  width: auto !important;
}
.formio-component-modal-wrapper .formio-component :where(label, .col-form-label)[style*="float: left; width: 30%; margin-inline-end: 3%; text-align: start;"] + .input-group[style*="width: 67%; margin-inline-start: 33%;"],
.formio-form .formio-component :where(label, .col-form-label)[style*="float: left; width: 30%; margin-inline-end: 3%; text-align: start;"] + .input-group[style*="width: 67%; margin-inline-start: 33%;"] {
  width: auto !important;
}
.formio-component-modal-wrapper .formio-component :where(label, .col-form-label)[style*="float: left; width: 30%; margin-inline-end: 3%; text-align: start;"] + .input-group[style*="width: 67%; margin-inline-start: 33%;"] > *:first-child,
.formio-form .formio-component :where(label, .col-form-label)[style*="float: left; width: 30%; margin-inline-end: 3%; text-align: start;"] + .input-group[style*="width: 67%; margin-inline-start: 33%;"] > *:first-child {
  display: block;
  margin-top: -6px;
}
.formio-component-modal-wrapper .formio-component :where(label, .col-form-label).field-required,
.formio-form .formio-component :where(label, .col-form-label).field-required {
  position: relative;
}
.formio-component-modal-wrapper .formio-component :where(label, .col-form-label).field-required:after,
.formio-form .formio-component :where(label, .col-form-label).field-required:after {
  content: "\F069";
  font-family: var(--fontawesome-font);
  font-weight: 700;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  color: #d56d72;
  /*@grey*/
  font-size: 0.7rem;
  vertical-align: super;
}
.formio-component-modal-wrapper .formio-component :where(label, .col-form-label) ~ :where(.formio-errors p, formio-errors p),
.formio-form .formio-component :where(label, .col-form-label) ~ :where(.formio-errors p, formio-errors p) {
  margin-top: 0.6rem;
}
.formio-component-modal-wrapper .formio-component :where(label, .col-form-label) + .ui-select-container .ui-select-choices.ui-select-dropdown.dropdown-menu,
.formio-form .formio-component :where(label, .col-form-label) + .ui-select-container .ui-select-choices.ui-select-dropdown.dropdown-menu {
  /*top: 57px !important;*/
}
.formio-component-modal-wrapper .formio-component .input-group,
.formio-form .formio-component .input-group {
  width: 100% !important;
  margin-inline-start: 0 !important;
}
.formio-component-modal-wrapper .formio-component .input-group-addon,
.formio-form .formio-component .input-group-addon {
  border-radius: 3px;
  border-color: transparent;
  /*@greylines*/
  background-color: #efefef;
  color: #9AA7B7;
  position: relative;
}
.formio-component-modal-wrapper .formio-component .input-group-addon:first-child,
.formio-form .formio-component .input-group-addon:first-child {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.formio-component-modal-wrapper .formio-component .input-group-addon:first-child::before,
.formio-form .formio-component .input-group-addon:first-child::before {
  content: ' ';
  position: absolute;
  top: 0;
  inset-inline-end: 0;
  height: 100%;
  border-inline-end: 1px solid rgba(64, 68, 81, 0.16);
  z-index: 3;
  display: none;
}
.formio-component-modal-wrapper .formio-component .input-group-addon:first-child + .form-control,
.formio-form .formio-component .input-group-addon:first-child + .form-control {
  border-inline-start: none;
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  position: relative;
}
.formio-component-modal-wrapper .formio-component .input-group-addon:last-child,
.formio-form .formio-component .input-group-addon:last-child {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
.formio-component-modal-wrapper .formio-component .input-group-addon:last-child::before,
.formio-form .formio-component .input-group-addon:last-child::before {
  content: ' ';
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  height: 98.5%;
  border-inline-start: 1px solid rgba(64, 68, 81, 0.16);
  z-index: 3;
  display: none;
}
.formio-component-modal-wrapper .formio-component .input-group-addon:last-child + .form-control,
.formio-form .formio-component .input-group-addon:last-child + .form-control {
  border-inline-end: none;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
  position: relative;
}
.formio-component-modal-wrapper .formio-component .input-group-addon + input:not(:last-child),
.formio-form .formio-component .input-group-addon + input:not(:last-child) {
  border-inline-end: none;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.formio-component-modal-wrapper .formio-component .help-block,
.formio-form .formio-component .help-block {
  font-size: 1.1rem;
  text-align: start;
  display: block;
  line-height: 1.2;
}
.formio-component-modal-wrapper .formio-component .help-block:not(:empty),
.formio-form .formio-component .help-block:not(:empty) {
  margin-bottom: 1rem;
  margin-top: 0.7rem;
}
.formio-component-modal-wrapper .formio-component .form-control,
.formio-form .formio-component .form-control {
  border-radius: 3px;
  border-color: rgba(64, 68, 81, 0.16);
  box-shadow: none;
  letter-spacing: 0.4pt;
  color: var(--main-text-color);
}
.formio-component-modal-wrapper .formio-component .form-control:where(:active, :focus, :hover),
.formio-form .formio-component .form-control:where(:active, :focus, :hover) {
  /*box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);*/
  outline: solid 1px #e1e4ea;
}
.formio-component-modal-wrapper .formio-component.formio-component-panel.text-primary,
.formio-form .formio-component.formio-component-panel.text-primary {
  color: #313335;
}
.formio-component-modal-wrapper .formio-component.formio-component-panel .card .card-heading,
.formio-form .formio-component.formio-component-panel .card .card-heading {
  padding-top: 2.5rem;
  padding-inline-start: 2.5rem;
  padding-inline-end: 2.5rem;
  margin: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-panel .card .card-heading .card-title,
.formio-form .formio-component.formio-component-panel .card .card-heading .card-title {
  padding: 0;
  line-height: 1;
}
.formio-component-modal-wrapper .formio-component.formio-component-panel .card .card-header .card-title,
.formio-form .formio-component.formio-component-panel .card .card-header .card-title {
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
}
.formio-component-modal-wrapper .formio-component.formio-component-panel .card .card-header .card-title i,
.formio-form .formio-component.formio-component-panel .card .card-header .card-title i {
  position: absolute;
  inset-inline-end: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-panel .card .card-body .formio-component-table:first-of-type,
.formio-form .formio-component.formio-component-panel .card .card-body .formio-component-table:first-of-type {
  margin-top: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-panel .card .card-body .formio-component-columns:first-of-type,
.formio-form .formio-component.formio-component-panel .card .card-body .formio-component-columns:first-of-type {
  margin-top: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-panel .card .card-body .formio-component.formio-component-htmlelement.notice,
.formio-form .formio-component.formio-component-panel .card .card-body .formio-component.formio-component-htmlelement.notice {
  padding: 0 2rem;
}
.formio-component-modal-wrapper .formio-component.formio-component-panel.payment-form-component > .card > .card-body > .formio-component,
.formio-form .formio-component.formio-component-panel.payment-form-component > .card > .card-body > .formio-component {
  margin-top: 0 !important;
}
.formio-component-modal-wrapper .formio-component.formio-component-panel.payment-form-component > .card > .card-body > .formio-component .formio-component.formio-component-htmlelement,
.formio-form .formio-component.formio-component-panel.payment-form-component > .card > .card-body > .formio-component .formio-component.formio-component-htmlelement {
  margin-top: 0 !important;
}
.formio-component-modal-wrapper .formio-component.formio-component-panel.payment-form-component > .card > .card-body > .formio-component .formio-component.formio-component-htmlelement p,
.formio-form .formio-component.formio-component-panel.payment-form-component > .card > .card-body > .formio-component .formio-component.formio-component-htmlelement p {
  margin-bottom: 3px;
}
.formio-component-modal-wrapper .formio-component.formio-component-panel.payment-form-component > .card > .card-body > .formio-component.formio-component-columns:last-child,
.formio-form .formio-component.formio-component-panel.payment-form-component > .card > .card-body > .formio-component.formio-component-columns:last-child {
  border-top: solid 1px #e1e4ea;
  align-items: center;
  margin-top: 1rem !important;
  padding-top: 1rem;
}
.formio-component-modal-wrapper .formio-component.formio-component-panel.payment-form-component > .card > .card-body > .formio-component.formio-component-columns:last-child h4,
.formio-form .formio-component.formio-component-panel.payment-form-component > .card > .card-body > .formio-component.formio-component-columns:last-child h4 {
  margin: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-panel.payment-form-component > .card > .card-body > .formio-component.formio-component-columns:last-child p,
.formio-form .formio-component.formio-component-panel.payment-form-component > .card > .card-body > .formio-component.formio-component-columns:last-child p {
  margin: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-panel.payment-form-component > .card > .card-body > .formio-component.formio-component-columns:last-child div[class^="col-"]:nth-of-type(2) .formio-component-htmlelement p,
.formio-form .formio-component.formio-component-panel.payment-form-component > .card > .card-body > .formio-component.formio-component-columns:last-child div[class^="col-"]:nth-of-type(2) .formio-component-htmlelement p {
  font-weight: 600;
  margin-bottom: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-panel.notice,
.formio-form .formio-component.formio-component-panel.notice {
  border-inline-start: solid 3px var(--color-primary-600);
}
.formio-component-modal-wrapper .formio-component.formio-component-panel.notice > .card,
.formio-form .formio-component.formio-component-panel.notice > .card {
  border: none !important;
  background-color: white;
  border-radius: 0 6px 6px 0 !important;
}
.formio-component-modal-wrapper .formio-component.formio-component-panel.notice > .card > .card-header,
.formio-form .formio-component.formio-component-panel.notice > .card > .card-header {
  background: white !important;
  border-radius: 0 6px 0 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-panel.notice > .card > .card-body,
.formio-form .formio-component.formio-component-panel.notice > .card > .card-body {
  border-radius: 0 0 6px 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-panel.notice > .card > .card-body:only-child,
.formio-form .formio-component.formio-component-panel.notice > .card > .card-body:only-child {
  border-radius: 0 6px 6px 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-button > button,
.formio-form .formio-component.formio-component-button > button {
  border-radius: 2px;
  border: none;
  box-shadow: var(--button-shadow, none);
  font-weight: 500;
  color: white;
  font-size: 14px;
  min-width: fit-content;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
}
.formio-component-modal-wrapper .formio-component.formio-component-button > button i,
.formio-form .formio-component.formio-component-button > button i {
  font-size: 14px;
}
.formio-component-modal-wrapper .formio-component.formio-component-button > button:hover,
.formio-form .formio-component.formio-component-button > button:hover {
  color: white;
}
.formio-component-modal-wrapper .formio-component.formio-component-button > button.btn-xs,
.formio-form .formio-component.formio-component-button > button.btn-xs {
  font-size: 12px;
  font-weight: 400;
  padding: 0.8rem 1rem;
}
.formio-component-modal-wrapper .formio-component.formio-component-button > button.btn-sm,
.formio-form .formio-component.formio-component-button > button.btn-sm {
  font-size: 12px;
  padding: 1rem;
  min-height: 36px;
}
.formio-component-modal-wrapper .formio-component.formio-component-button > button.btn-lg,
.formio-form .formio-component.formio-component-button > button.btn-lg {
  font-size: 16px;
  line-height: 1;
  padding: 15px;
}
.formio-component-modal-wrapper .formio-component.formio-component-button > button.btn-block,
.formio-form .formio-component.formio-component-button > button.btn-block {
  display: flex !important;
}
.formio-component-modal-wrapper .formio-component.formio-component-button.btn-green > button,
.formio-form .formio-component.formio-component-button.btn-green > button {
  background-color: #6fbc8d;
}
.formio-component-modal-wrapper .formio-component.formio-component-button.btn-green > button:hover,
.formio-form .formio-component.formio-component-button.btn-green > button:hover {
  background-color: #40a265;
}
.formio-component-modal-wrapper .formio-component.formio-component-button.btn-blue > button,
.formio-form .formio-component.formio-component-button.btn-blue > button {
  background-color: #3C8FB9;
}
.formio-component-modal-wrapper .formio-component.formio-component-button.btn-blue > button:hover,
.formio-form .formio-component.formio-component-button.btn-blue > button:hover {
  background-color: #3680A6;
}
.formio-component-modal-wrapper .formio-component.formio-component-button.btn-orange > button,
.formio-form .formio-component.formio-component-button.btn-orange > button {
  background-color: #F3A72E;
}
.formio-component-modal-wrapper .formio-component.formio-component-button.btn-orange > button:hover,
.formio-form .formio-component.formio-component-button.btn-orange > button:hover {
  background-color: #cc8b22;
}
.formio-component-modal-wrapper .formio-component.formio-component-button.btn-red > button,
.formio-form .formio-component.formio-component-button.btn-red > button {
  background-color: #EA3D2F;
}
.formio-component-modal-wrapper .formio-component.formio-component-button.btn-red > button:hover,
.formio-form .formio-component.formio-component-button.btn-red > button:hover {
  background-color: #ba3023;
}
.formio-component-modal-wrapper .formio-component.formio-component-button.action-progress button,
.formio-form .formio-component.formio-component-button.action-progress button {
  cursor: not-allowed;
  color: #404451 !important;
  opacity: 1 !important;
  background: #efefef !important;
  text-align: center !important;
  border-color: #ccc;
}
.formio-component-modal-wrapper .formio-component.formio-component-fieldset,
.formio-form .formio-component.formio-component-fieldset {
  padding: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-fieldset fieldset,
.formio-form .formio-component.formio-component-fieldset fieldset {
  padding: 21px 16px 16px;
}
.formio-component-modal-wrapper .formio-component.formio-component-fieldset fieldset legend,
.formio-form .formio-component.formio-component-fieldset fieldset legend {
  margin-bottom: -10px;
}
.formio-component-modal-wrapper .formio-component.formio-component-fieldset fieldset .fieldset-body .formio-component:first-child,
.formio-form .formio-component.formio-component-fieldset fieldset .fieldset-body .formio-component:first-child {
  margin-top: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-fieldset fieldset .fieldset-body .formio-component.formio-component-table table.table,
.formio-form .formio-component.formio-component-fieldset fieldset .fieldset-body .formio-component.formio-component-table table.table {
  background-color: transparent;
}
.formio-component-modal-wrapper .formio-component.formio-component-columns > div[class^="col-"]:first-of-type,
.formio-form .formio-component.formio-component-columns > div[class^="col-"]:first-of-type {
  padding-inline-start: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-columns > div[class^="col-"]:nth-last-of-type(2),
.formio-form .formio-component.formio-component-columns > div[class^="col-"]:nth-last-of-type(2) {
  padding-inline-end: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-columns > div[class^="col-"] .formio-component:first-of-type,
.formio-form .formio-component.formio-component-columns > div[class^="col-"] .formio-component:first-of-type {
  margin-top: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-columns > div[class^="col-"] .formio-component:first-of-type.top-label-space,
.formio-form .formio-component.formio-component-columns > div[class^="col-"] .formio-component:first-of-type.top-label-space {
  margin-top: 29px !important;
}
.formio-component-modal-wrapper .formio-component.formio-component-columns > div[class^="col-"] .formio-component:first-of-type.top-label-space.formio-component-button,
.formio-form .formio-component.formio-component-columns > div[class^="col-"] .formio-component:first-of-type.top-label-space.formio-component-button {
  margin-top: 29px !important;
}
.formio-component-modal-wrapper .formio-component.formio-component-columns > div[class^="col-"] .formio-component:first-of-type.top-label-space.formio-component-checkbox,
.formio-form .formio-component.formio-component-columns > div[class^="col-"] .formio-component:first-of-type.top-label-space.formio-component-checkbox {
  margin-top: calc(12px + 0.8rem) !important;
}
.formio-component-modal-wrapper .formio-component.formio-component-columns.default-pay-form-payments,
.formio-form .formio-component.formio-component-columns.default-pay-form-payments {
  margin-top: 0;
  align-items: center;
}
.formio-component-modal-wrapper .formio-component.formio-component-columns.default-pay-form-payments > .col-md-1,
.formio-form .formio-component.formio-component-columns.default-pay-form-payments > .col-md-1 {
  flex: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-columns.default-pay-form-payments .formio-component-checkbox.radio-info,
.formio-form .formio-component.formio-component-columns.default-pay-form-payments .formio-component-checkbox.radio-info {
  margin-top: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-columns.default-pay-form-payments .formio-component-checkbox .form-check.checkbox,
.formio-form .formio-component.formio-component-columns.default-pay-form-payments .formio-component-checkbox .form-check.checkbox {
  margin-top: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-columns.default-pay-form-payments .formio-component-checkbox .form-check.checkbox .form-check-label .form-check-input,
.formio-form .formio-component.formio-component-columns.default-pay-form-payments .formio-component-checkbox .form-check.checkbox .form-check-label .form-check-input {
  opacity: 1;
  z-index: 99;
  position: relative;
  margin: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-columns.default-pay-form-payments .formio-component-htmlelement,
.formio-form .formio-component.formio-component-columns.default-pay-form-payments .formio-component-htmlelement {
  margin-top: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-columns.formio-component-totalPrice .formio-component-htmlelement,
.formio-form .formio-component.formio-component-columns.formio-component-totalPrice .formio-component-htmlelement {
  margin-top: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-columns.i-swear div:first-child,
.formio-form .formio-component.formio-component-columns.i-swear div:first-child {
  flex: 0 0 26px;
}
.formio-component-modal-wrapper .formio-component.formio-component-columns.i-swear div:first-child .formio-component-checkbox,
.formio-form .formio-component.formio-component-columns.i-swear div:first-child .formio-component-checkbox {
  height: 100%;
}
.formio-component-modal-wrapper .formio-component.formio-component-columns.i-swear div:first-child .formio-component-checkbox .form-check,
.formio-form .formio-component.formio-component-columns.i-swear div:first-child .formio-component-checkbox .form-check {
  height: 100%;
  display: flex;
  align-items: center;
}
.formio-component-modal-wrapper .formio-component.formio-component-columns.i-swear div:first-child .formio-component-checkbox .form-check label.form-check-label,
.formio-form .formio-component.formio-component-columns.i-swear div:first-child .formio-component-checkbox .form-check label.form-check-label {
  display: flex;
  margin-top: -1px;
}
.formio-component-modal-wrapper .formio-component.formio-component-columns.i-swear div:first-child .formio-component-checkbox .form-check label.form-check-label input.form-check-input,
.formio-form .formio-component.formio-component-columns.i-swear div:first-child .formio-component-checkbox .form-check label.form-check-label input.form-check-input {
  margin: 0;
  position: relative;
}
.formio-component-modal-wrapper .formio-component.formio-component-columns.i-swear div:first-child + div,
.formio-form .formio-component.formio-component-columns.i-swear div:first-child + div {
  padding-inline-start: 0;
  flex: auto;
}
.formio-component-modal-wrapper .formio-component.formio-component-columns.adjust-columns > div[class^="col-"]:has(.formio-component-button),
.formio-form .formio-component.formio-component-columns.adjust-columns > div[class^="col-"]:has(.formio-component-button) {
  min-width: fit-content;
}
.formio-component-modal-wrapper .formio-component.formio-component-columns.adjust-columns > :is(.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12),
.formio-form .formio-component.formio-component-columns.adjust-columns > :is(.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12) {
  width: 100%;
  min-width: initial;
  max-width: var(--custom-sizing-max-width, initial);
}
@media (min-width: 992px) {
  .formio-component-modal-wrapper .formio-component.formio-component-columns.adjust-columns,
  .formio-form .formio-component.formio-component-columns.adjust-columns {
    flex-wrap: nowrap !important;
  }
}
.formio-component-modal-wrapper .formio-component.formio-component-columns.adjust-columns .col-md-1,
.formio-form .formio-component.formio-component-columns.adjust-columns .col-md-1 {
  --custom-sizing-max-width: 8.33333333%;
}
.formio-component-modal-wrapper .formio-component.formio-component-columns.adjust-columns .col-md-2,
.formio-form .formio-component.formio-component-columns.adjust-columns .col-md-2 {
  --custom-sizing-max-width: 16.66666667%;
}
.formio-component-modal-wrapper .formio-component.formio-component-columns.adjust-columns .col-md-3,
.formio-form .formio-component.formio-component-columns.adjust-columns .col-md-3 {
  --custom-sizing-max-width: 25%;
}
.formio-component-modal-wrapper .formio-component.formio-component-columns.adjust-columns .col-md-4,
.formio-form .formio-component.formio-component-columns.adjust-columns .col-md-4 {
  --custom-sizing-max-width: 33.33333333%;
}
.formio-component-modal-wrapper .formio-component.formio-component-columns.adjust-columns .col-md-5,
.formio-form .formio-component.formio-component-columns.adjust-columns .col-md-5 {
  --custom-sizing-max-width: 41.66666667%;
}
.formio-component-modal-wrapper .formio-component.formio-component-columns.adjust-columns .col-md-6,
.formio-form .formio-component.formio-component-columns.adjust-columns .col-md-6 {
  --custom-sizing-max-width: 50%;
}
.formio-component-modal-wrapper .formio-component.formio-component-columns.adjust-columns .col-md-7,
.formio-form .formio-component.formio-component-columns.adjust-columns .col-md-7 {
  --custom-sizing-max-width: 58.33333333%;
}
.formio-component-modal-wrapper .formio-component.formio-component-columns.adjust-columns .col-md-8,
.formio-form .formio-component.formio-component-columns.adjust-columns .col-md-8 {
  --custom-sizing-max-width: 66.66666667%;
}
.formio-component-modal-wrapper .formio-component.formio-component-columns.adjust-columns .col-md-9,
.formio-form .formio-component.formio-component-columns.adjust-columns .col-md-9 {
  --custom-sizing-max-width: 75%;
}
.formio-component-modal-wrapper .formio-component.formio-component-columns.adjust-columns .col-md-10,
.formio-form .formio-component.formio-component-columns.adjust-columns .col-md-10 {
  --custom-sizing-max-width: 83.33333333%;
}
.formio-component-modal-wrapper .formio-component.formio-component-columns.adjust-columns .col-md-11,
.formio-form .formio-component.formio-component-columns.adjust-columns .col-md-11 {
  --custom-sizing-max-width: 91.66666667%;
}
.formio-component-modal-wrapper .formio-component.formio-component-columns.adjust-columns .col-md-12,
.formio-form .formio-component.formio-component-columns.adjust-columns .col-md-12 {
  --custom-sizing-max-width: 100%;
}
.formio-component-modal-wrapper .formio-component.formio-component-columns.payment-fee .formio-component-htmlelement[class*="price"] p[ref="html"],
.formio-form .formio-component.formio-component-columns.payment-fee .formio-component-htmlelement[class*="price"] p[ref="html"] {
  display: flex;
  justify-content: flex-end;
}
.formio-component-modal-wrapper .formio-component.formio-component-mycontent,
.formio-form .formio-component.formio-component-mycontent {
  background: none !important;
  padding: 0 !important;
}
.formio-component-modal-wrapper .formio-component.formio-component-mycontent p,
.formio-form .formio-component.formio-component-mycontent p {
  margin: 0 0 2.6rem;
}
.formio-component-modal-wrapper .formio-component.formio-component-mycontent p:last-child,
.formio-form .formio-component.formio-component-mycontent p:last-child {
  margin-bottom: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-mycontent.alert.alert-danger,
.formio-form .formio-component.formio-component-mycontent.alert.alert-danger {
  padding: 15px !important;
  margin-bottom: 2rem !important;
  background: #f3e1e2 !important;
  border-inline-start: solid 4px #d56d72 !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
}
.formio-component-modal-wrapper .formio-component.formio-component-mycontent.alert.alert-danger::after,
.formio-form .formio-component.formio-component-mycontent.alert.alert-danger::after {
  display: none !important;
}
.formio-component-modal-wrapper .formio-component.formio-component-mycontent.notice,
.formio-form .formio-component.formio-component-mycontent.notice {
  background: #f5f6f8 !important;
  padding: 2rem !important;
  border-inline-start: solid 3px #3c8fb9;
}
.formio-component-modal-wrapper .formio-component.formio-component-mycontent + .formio-component-panel,
.formio-form .formio-component.formio-component-mycontent + .formio-component-panel {
  margin-top: 3rem;
}
.formio-component-modal-wrapper .formio-component.formio-component-htmlelement.notice,
.formio-form .formio-component.formio-component-htmlelement.notice {
  background: #f5f6f8 !important;
  padding: 2rem;
  border-inline-start: solid 3px #3c8fb9;
}
.formio-component-modal-wrapper .formio-component.formio-component-htmlelement + .formio-component-panel,
.formio-form .formio-component.formio-component-htmlelement + .formio-component-panel {
  margin-top: 3rem;
}
.formio-component-modal-wrapper .formio-component.formio-component-htmlelement p,
.formio-form .formio-component.formio-component-htmlelement p {
  margin: 2.6rem 0 0;
  font-size: 14px;
}
.formio-component-modal-wrapper .formio-component.formio-component-htmlelement p:first-child,
.formio-form .formio-component.formio-component-htmlelement p:first-child {
  margin-top: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-htmlelement p span.text-tiny,
.formio-form .formio-component.formio-component-htmlelement p span.text-tiny {
  font-size: 9px;
}
.formio-component-modal-wrapper .formio-component.formio-component-htmlelement p span.text-tiny span,
.formio-form .formio-component.formio-component-htmlelement p span.text-tiny span {
  background-color: transparent !important;
}
.formio-component-modal-wrapper .formio-component.formio-component-htmlelement p span.text-small,
.formio-form .formio-component.formio-component-htmlelement p span.text-small {
  font-size: 11px;
}
.formio-component-modal-wrapper .formio-component.formio-component-htmlelement p span.text-big,
.formio-form .formio-component.formio-component-htmlelement p span.text-big {
  font-size: 18px;
}
.formio-component-modal-wrapper .formio-component.formio-component-htmlelement p span.text-huge,
.formio-form .formio-component.formio-component-htmlelement p span.text-huge {
  font-size: 24px;
}
.formio-component-modal-wrapper .formio-component.formio-component-htmlelement ul,
.formio-form .formio-component.formio-component-htmlelement ul {
  margin-top: 13px;
  margin-bottom: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-htmlelement ul:first-child,
.formio-form .formio-component.formio-component-htmlelement ul:first-child {
  margin-top: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox,
.formio-form .formio-component.formio-component-checkbox {
  margin-bottom: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox .checkbox,
.formio-form .formio-component.formio-component-checkbox .checkbox {
  margin-top: 0;
  padding-top: 13px;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox .checkbox label,
.formio-form .formio-component.formio-component-checkbox .checkbox label {
  margin-top: 1px;
  padding-inline-start: 26px;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox .checkbox label span,
.formio-form .formio-component.formio-component-checkbox .checkbox label span {
  color: rgba(70, 81, 170, 0.5);
  font-size: 16px;
  font-weight: 400;
  padding-inline-start: 0px;
  line-height: 19px;
  margin-top: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox .checkbox label span::before,
.formio-form .formio-component.formio-component-checkbox .checkbox label span::before {
  color: rgba(70, 81, 170, 0.5);
  font-size: 16px;
  font-weight: 400;
  top: 1px;
  inset-inline-start: -26px;
  line-height: 20px;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox .checkbox label input[type="radio"],
.formio-form .formio-component.formio-component-checkbox .checkbox label input[type="radio"] {
  appearance: inherit;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox .checkbox label input[type="radio"]::before,
.formio-form .formio-component.formio-component-checkbox .checkbox label input[type="radio"]::before {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  font-size: 16px;
  content: "\f111";
  color: rgba(70, 81, 170, 0.5);
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox .checkbox label input[type="radio"] + span,
.formio-form .formio-component.formio-component-checkbox .checkbox label input[type="radio"] + span {
  font-size: 16px;
  font-weight: 600;
  color: rgba(70, 81, 170, 0.5);
  margin-inline-start: 3px;
  position: relative;
  line-height: 14px;
  padding-inline-start: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox .checkbox label input[type="radio"]:checked::before,
.formio-form .formio-component.formio-component-checkbox .checkbox label input[type="radio"]:checked::before {
  content: "\f192";
  font-weight: 900;
  color: var(--color-primary-600);
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox .checkbox label input[type="radio"]:checked + span,
.formio-form .formio-component.formio-component-checkbox .checkbox label input[type="radio"]:checked + span {
  color: var(--color-primary-600);
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox .checkbox label input[type="radio"]:focus,
.formio-form .formio-component.formio-component-checkbox .checkbox label input[type="radio"]:focus {
  outline: none;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox .checkbox label input:checked + span,
.formio-form .formio-component.formio-component-checkbox .checkbox label input:checked + span {
  color: var(--color-primary-600);
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox .checkbox label input:checked + span::before,
.formio-form .formio-component.formio-component-checkbox .checkbox label input:checked + span::before {
  color: var(--color-primary-600);
  font-weight: 600;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox .checkbox label input[type="checkbox"],
.formio-form .formio-component.formio-component-checkbox .checkbox label input[type="checkbox"] {
  transform: scale(0);
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox .checkbox label:hover span,
.formio-form .formio-component.formio-component-checkbox .checkbox label:hover span {
  color: var(--color-primary-600);
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox .checkbox label:hover span::before,
.formio-form .formio-component.formio-component-checkbox .checkbox label:hover span::before {
  color: var(--color-primary-600);
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox .checkbox label:hover input[type="radio"],
.formio-form .formio-component.formio-component-checkbox .checkbox label:hover input[type="radio"] {
  appearance: inherit;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox .checkbox label:hover input[type="radio"]::before,
.formio-form .formio-component.formio-component-checkbox .checkbox label:hover input[type="radio"]::before {
  color: var(--color-primary-600);
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox .checkbox label:hover input[type="radio"] + span,
.formio-form .formio-component.formio-component-checkbox .checkbox label:hover input[type="radio"] + span {
  color: var(--color-primary-600);
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox .checkbox label.field-required::after,
.formio-form .formio-component.formio-component-checkbox .checkbox label.field-required::after {
  content: " ";
  display: inline-block;
  background-color: rgba(255, 0, 59, 0.7);
  vertical-align: unset;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  position: relative;
  top: -1px;
  inset-inline-start: 8px;
  min-width: 6px;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox:is(.remove-top-space, .remove-inner-top-space) .checkbox,
.formio-form .formio-component.formio-component-checkbox:is(.remove-top-space, .remove-inner-top-space) .checkbox {
  padding-top: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox:is(.remove-top-space, .remove-inner-top-space) .checkbox .form-check-label,
.formio-form .formio-component.formio-component-checkbox:is(.remove-top-space, .remove-inner-top-space) .checkbox .form-check-label {
  margin-top: 2px;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.required label.field-required::after,
.formio-form .formio-component.formio-component-checkbox.required label.field-required::after {
  display: none;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.formio-component-label-hidden .checkbox label,
.formio-form .formio-component.formio-component-checkbox.formio-component-label-hidden .checkbox label {
  margin-top: 0px;
  padding-inline-start: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.formio-component-label-hidden .checkbox label input.form-check-input,
.formio-form .formio-component.formio-component-checkbox.formio-component-label-hidden .checkbox label input.form-check-input {
  transform: scale(1);
  appearance: none;
  position: initial;
  margin-inline-start: 0px;
  margin: 0;
  padding: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.formio-component-label-hidden .checkbox label input.form-check-input::after,
.formio-form .formio-component.formio-component-checkbox.formio-component-label-hidden .checkbox label input.form-check-input::after {
  content: "";
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 14px;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  font-size: 16px;
  content: "\F0C8";
  color: rgba(70, 81, 170, 0.5);
  top: -3px;
  position: relative;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.formio-component-label-hidden .checkbox label input.form-check-input:focus,
.formio-form .formio-component.formio-component-checkbox.formio-component-label-hidden .checkbox label input.form-check-input:focus {
  outline: none;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.formio-component-label-hidden .checkbox label input.form-check-input:checked::after,
.formio-form .formio-component.formio-component-checkbox.formio-component-label-hidden .checkbox label input.form-check-input:checked::after {
  content: "\F14A";
  font-weight: 900;
  color: var(--color-primary-600);
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox + .formio-component-checkbox,
.formio-form .formio-component.formio-component-checkbox + .formio-component-checkbox {
  margin-top: 1rem;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.check-button,
.formio-form .formio-component.formio-component-checkbox.check-button {
  --button-bg-color: var(--color-primary-600);
  --button-secondary-bg-color: rgba(70, 81, 170, 0.1);
  --button-shadow-y: 9px;
  --button-shadow-blur: 20px;
  --button-inline-padding: 25px;
  --button-block-padding: 12px;
  --button-font-size: 16px;
  --button-border-radius: 6px;
  --button-text-color: white;
  --button-text-color-hover: white;
  --button-outline-bg-color: transparent;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.check-button .checkbox label,
.formio-form .formio-component.formio-component-checkbox.check-button .checkbox label {
  display: inline-block;
  width: auto !important;
  min-width: fit-content;
  border-radius: var(--button-border-radius);
  outline: solid var(--button-border-width, 0px) var(--button-border-color, transparent);
  background-color: var(--button-bg-color);
  padding-inline-start: var(--button-inline-padding) !important;
  padding-inline-end: var(--button-inline-padding);
  padding-top: calc(var(--button-block-padding) - var(--button-border-width, 0px));
  padding-bottom: calc(var(--button-block-padding) - var(--button-border-width, 0px));
  font-size: var(--button-font-size) !important;
  font-weight: 500;
  color: var(--button-text-color) !important;
  text-transform: none;
  position: relative;
  z-index: 1;
  line-height: 1 !important;
  margin-top: 0px;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.check-button .checkbox label span,
.formio-form .formio-component.formio-component-checkbox.check-button .checkbox label span {
  color: inherit !important;
  line-height: inherit !important;
  font-size: inherit !important;
  font-weight: inherit;
  background: transparent;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.check-button .checkbox label span::before,
.formio-form .formio-component.formio-component-checkbox.check-button .checkbox label span::before {
  display: none;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.check-button .checkbox label:hover,
.formio-form .formio-component.formio-component-checkbox.check-button .checkbox label:hover {
  --button-shadow: var(--button-shadow-hover);
  opacity: 0.9;
  color: var(--button-text-color-hover);
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.check-button .checkbox label input,
.formio-form .formio-component.formio-component-checkbox.check-button .checkbox label input {
  position: absolute !important;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.check-button .checkbox label:has(input:checked),
.formio-form .formio-component.formio-component-checkbox.check-button .checkbox label:has(input:checked) {
  --button-border-width: 2px;
  --button-border-color: var(--color-gray-900);
  min-height: 40px;
  display: inline-flex;
  align-items: center;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.check-button .form-check.checkbox,
.formio-form .formio-component.formio-component-checkbox.check-button .form-check.checkbox {
  padding-top: 0px;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.check-button:is(.btn-secondary, .light-color),
.formio-form .formio-component.formio-component-checkbox.check-button:is(.btn-secondary, .light-color) {
  --button-text-color: var(--color-gray-800);
  --button-text-color-hover: var(--color-gray-800);
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.check-button:is(.btn-secondary, .light-color) .checkbox label,
.formio-form .formio-component.formio-component-checkbox.check-button:is(.btn-secondary, .light-color) .checkbox label {
  background-color: var(--button-secondary-bg-color);
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.check-button.outline-button,
.formio-form .formio-component.formio-component-checkbox.check-button.outline-button {
  --button-text-color: var(--button-bg-color);
  --button-text-color-hover: var(--button-bg-color);
  --button-border-width: 1px;
  --button-border-color: var(--button-bg-color);
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.check-button.outline-button:is(.btn-secondary, .light-color),
.formio-form .formio-component.formio-component-checkbox.check-button.outline-button:is(.btn-secondary, .light-color) {
  --button-border-color: var(--button-secondary-bg-color);
  --status-secondary-outline-border-color: rgba(70, 81, 170, 0.5);
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.check-button.outline-button .checkbox label,
.formio-form .formio-component.formio-component-checkbox.check-button.outline-button .checkbox label {
  background-color: var(--button-outline-bg-color);
  margin-top: 1px;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.check-button.outline-button .checkbox label:has(input:checked),
.formio-form .formio-component.formio-component-checkbox.check-button.outline-button .checkbox label:has(input:checked) {
  min-height: 38px;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.check-button.button-status,
.formio-form .formio-component.formio-component-checkbox.check-button.button-status {
  --button-border-radius: 50px;
  --button-shadow: none;
  --button-inline-padding: 20px;
  --status-bg-color: var(--color-primary-600);
  --status-secondary-bg-color: #E6E6EA;
  --status-outline-bg-color: transparent;
  --status-text-color: #FFFFFF;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.check-button.button-status .checkbox label,
.formio-form .formio-component.formio-component-checkbox.check-button.button-status .checkbox label {
  background-color: var(--status-bg-color);
  color: var(--status-text-color);
  font-weight: 600;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.check-button.button-status.outline-button,
.formio-form .formio-component.formio-component-checkbox.check-button.button-status.outline-button {
  --status-outline-border-color: #070B34;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.check-button.button-status.outline-button .checkbox label,
.formio-form .formio-component.formio-component-checkbox.check-button.button-status.outline-button .checkbox label {
  background-color: var(--status-outline-bg-color);
  border-color: var(--status-outline-border-color);
  color: var(--status-outline-border-color);
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.check-button.button-status.outline-button:is(.btn-secondary, .light-color),
.formio-form .formio-component.formio-component-checkbox.check-button.button-status.outline-button:is(.btn-secondary, .light-color) {
  --status-outline-secondary-bg-color: transparent;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.check-button.button-status.outline-button:is(.btn-secondary, .light-color) .checkbox label,
.formio-form .formio-component.formio-component-checkbox.check-button.button-status.outline-button:is(.btn-secondary, .light-color) .checkbox label {
  border-color: var(--status-secondary-outline-border-color);
  color: var(--status-outline-border-color);
  background-color: var(--status-outline-secondary-bg-color);
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.check-button.button-status:is(.btn-secondary, .light-color),
.formio-form .formio-component.formio-component-checkbox.check-button.button-status:is(.btn-secondary, .light-color) {
  --status-text-color: var(--main-text-color);
  --status-text-color-hover: var(--main-text-color);
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.check-button.button-status:is(.btn-secondary, .light-color) .checkbox label,
.formio-form .formio-component.formio-component-checkbox.check-button.button-status:is(.btn-secondary, .light-color) .checkbox label {
  background-color: var(--status-secondary-bg-color);
  color: var(--status-secondary-text-color);
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.check-button.btn-green,
.formio-form .formio-component.formio-component-checkbox.check-button.btn-green {
  --button-bg-color: #10B981;
  --button-secondary-bg-color: rgba(16, 185, 129, 0.35);
  --status-bg-color: #4BC394;
  --status-secondary-bg-color: #E1F0EE;
  --status-text-color: white;
  --status-secondary-text-color: #10B981;
  --status-secondary-outline-border-color: #9CCEC7;
  --status-outline-border-color: #10B981;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.check-button.btn-blue,
.formio-form .formio-component.formio-component-checkbox.check-button.btn-blue {
  --button-bg-color: #4CAAE5;
  --button-secondary-bg-color: rgba(76, 170, 229, 0.35);
  --status-bg-color: #4CAAE5;
  --status-secondary-bg-color: #E4F2FB;
  --status-text-color: white;
  --status-secondary-text-color: #1D86C8;
  --status-secondary-outline-border-color: #8CC7EE;
  --status-outline-border-color: #1D86C8;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.check-button.btn-orange,
.formio-form .formio-component.formio-component-checkbox.check-button.btn-orange {
  --button-bg-color: #FFA385;
  --button-secondary-bg-color: rgba(255, 163, 133, 0.5);
  --status-bg-color: #FFA385;
  --status-secondary-bg-color: #FFF0EB;
  --status-text-color: white;
  --status-secondary-text-color: #FF7233;
  --status-secondary-outline-border-color: #FFA385;
  --status-outline-border-color: #FF7233;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.check-button.btn-red,
.formio-form .formio-component.formio-component-checkbox.check-button.btn-red {
  --button-bg-color: #F75F5F;
  --button-secondary-bg-color: rgba(247, 95, 95, 0.35);
  --status-bg-color: #F75F5F;
  --status-secondary-bg-color: #FEE7E7;
  --status-text-color: white;
  --status-secondary-text-color: #F53D3D;
  --status-secondary-outline-border-color: #FA8585;
  --status-outline-border-color: #F53D3D;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.check-button.has-success .checkbox label,
.formio-form .formio-component.formio-component-checkbox.check-button.has-success .checkbox label {
  background-color: #3680A6;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.check-button:not(.has-success) .checkbox label input.ng-untouched.ng-not-empty + span,
.formio-form .formio-component.formio-component-checkbox.check-button:not(.has-success) .checkbox label input.ng-untouched.ng-not-empty + span {
  padding: 4.5px 14px;
  margin: -4.5px -14px;
  border-radius: 2px;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.check-switch,
.formio-form .formio-component.formio-component-checkbox.check-switch {
  display: flex;
  padding-inline-start: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.check-switch .checkbox label,
.formio-form .formio-component.formio-component-checkbox.check-switch .checkbox label {
  display: flex;
  width: 100%;
  align-items: center;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.check-switch .checkbox label span,
.formio-form .formio-component.formio-component-checkbox.check-switch .checkbox label span {
  padding-inline-start: 3rem;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.check-switch .checkbox label span::before,
.formio-form .formio-component.formio-component-checkbox.check-switch .checkbox label span::before {
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  display: inline-block;
  color: #A3A3A3;
  font-size: 2rem;
  transform: scale(-1, 1);
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.check-switch .checkbox label.form-check-label input.form-check-input:checked + span::before,
.formio-form .formio-component.formio-component-checkbox.check-switch .checkbox label.form-check-label input.form-check-input:checked + span::before {
  transform: scale(1, 1) !important;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.check-switch.has-success .checkbox label span::before,
.formio-form .formio-component.formio-component-checkbox.check-switch.has-success .checkbox label span::before {
  color: #6fbc8d;
  transform: scale(1, 1);
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.check-switch:not(.has-success) .checkbox label input.ng-untouched.ng-not-empty + span::before,
.formio-form .formio-component.formio-component-checkbox.check-switch:not(.has-success) .checkbox label input.ng-untouched.ng-not-empty + span::before {
  color: #6fbc8d;
  transform: scale(1, 1);
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.top-label-space .form-check,
.formio-form .formio-component.formio-component-checkbox.top-label-space .form-check {
  padding-top: 11px;
}
.formio-component-modal-wrapper .formio-component.formio-component-checkbox.remove-space-top .form-check.checkbox,
.formio-form .formio-component.formio-component-checkbox.remove-space-top .form-check.checkbox {
  margin-top: 10px;
}
.formio-component-modal-wrapper .formio-component.formio-component-textarea,
.formio-form .formio-component.formio-component-textarea {
  line-height: 1;
}
.formio-component-modal-wrapper .formio-component.formio-component-textarea.service-response-msg,
.formio-form .formio-component.formio-component-textarea.service-response-msg {
  height: 100%;
}
.formio-component-modal-wrapper .formio-component.formio-component-textarea.service-response-msg > div,
.formio-form .formio-component.formio-component-textarea.service-response-msg > div {
  height: 100%;
}
.formio-component-modal-wrapper .formio-component.formio-component-textarea.service-response-msg > div > .input-group,
.formio-form .formio-component.formio-component-textarea.service-response-msg > div > .input-group {
  height: 100%;
}
.formio-component-modal-wrapper .formio-component.formio-component-textarea.service-response-msg > div > .input-group textarea.form-control,
.formio-form .formio-component.formio-component-textarea.service-response-msg > div > .input-group textarea.form-control {
  position: absolute;
  display: table-cell;
  top: 31px;
  inset-inline-end: 0;
  bottom: 6px;
  inset-inline-start: 0;
  -webkit-appearance: menulist;
  border: none;
  border-inline-start: solid 3px #3C8FB9;
  border-radius: 0 !important;
  font-size: 13px;
  background: rgba(239, 239, 239, 0.5);
  font-weight: 600;
}
.formio-component-modal-wrapper .formio-component.formio-component-textarea.service-response-msg > div > .input-group textarea.form-control.ng-empty,
.formio-form .formio-component.formio-component-textarea.service-response-msg > div > .input-group textarea.form-control.ng-empty {
  display: none;
}
.formio-component-modal-wrapper .formio-component.formio-component-table table,
.formio-form .formio-component.formio-component-table table {
  border: none;
}
.formio-component-modal-wrapper .formio-component.formio-component-table table.table,
.formio-form .formio-component.formio-component-table table.table {
  table-layout: fixed;
}
.formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr > td,
.formio-form .formio-component.formio-component-table table.table > tbody > tr > td {
  border: none ! important;
  padding: 0 12px;
  vertical-align: top;
  line-height: 1;
}
.formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr > td :where(select, input[type="text"], input[type="number"], input[type="email"]),
.formio-form .formio-component.formio-component-table table.table > tbody > tr > td :where(select, input[type="text"], input[type="number"], input[type="email"]) {
  border-radius: 3px !important;
  height: 36px !important;
}
.formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr > td .print-button,
.formio-form .formio-component.formio-component-table table.table > tbody > tr > td .print-button {
  margin: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr > td .formio-component-radio .input-group .radio-inline,
.formio-form .formio-component.formio-component-table table.table > tbody > tr > td .formio-component-radio .input-group .radio-inline {
  margin-top: 10px;
}
.formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr > td .formio-component-panel,
.formio-form .formio-component.formio-component-table table.table > tbody > tr > td .formio-component-panel {
  margin-top: 3rem;
}
.formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr > td:empty,
.formio-form .formio-component.formio-component-table table.table > tbody > tr > td:empty {
  padding: 18px 0 !important;
  height: 36px;
}
.formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr:first-child > :where(td > .form-group, td > .formio-component):first-child:not(.ng-hide),
.formio-form .formio-component.formio-component-table table.table > tbody > tr:first-child > :where(td > .form-group, td > .formio-component):first-child:not(.ng-hide) {
  margin-top: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr:first-child > :where(td > .form-group, td > .formio-component):first-child:not(.ng-hide).top-label-space,
.formio-form .formio-component.formio-component-table table.table > tbody > tr:first-child > :where(td > .form-group, td > .formio-component):first-child:not(.ng-hide).top-label-space {
  margin-top: calc(14px + 0.8rem) !important;
}
.formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .formio-component:first-child:not(.ng-hide),
.formio-form .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .formio-component:first-child:not(.ng-hide) {
  margin-top: 3rem;
}
.formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .formio-component.formio-component-radio .form-radio:first-child,
.formio-form .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .formio-component.formio-component-radio .form-radio:first-child {
  margin-top: 53px;
}
.formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .formio-component.formio-component-radio .form-radio .form-check:first-child,
.formio-form .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .formio-component.formio-component-radio .form-radio .form-check:first-child {
  margin-top: 64px;
}
.formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .formio-component.formio-component-radio.remove-space-top .form-radio,
.formio-form .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .formio-component.formio-component-radio.remove-space-top .form-radio {
  margin-top: 30px;
}
.formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .formio-component.formio-component-selectboxes .form-radio:first-child,
.formio-form .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .formio-component.formio-component-selectboxes .form-radio:first-child {
  margin-top: 65px;
}
.formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .formio-component.formio-component-selectboxes.remove-space-top .form-radio:first-child,
.formio-form .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .formio-component.formio-component-selectboxes.remove-space-top .form-radio:first-child {
  margin-top: 42px;
}
.formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .formio-component.formio-component-checkbox .form-check.checkbox,
.formio-form .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .formio-component.formio-component-checkbox .form-check.checkbox {
  margin-top: 63px;
}
.formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .formio-component.formio-component-checkbox.remove-space-top .form-check.checkbox,
.formio-form .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .formio-component.formio-component-checkbox.remove-space-top .form-check.checkbox {
  margin-top: 40px;
}
.formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .formio-component.formio-hidden:first-child + .formio-component,
.formio-form .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .formio-component.formio-hidden:first-child + .formio-component {
  margin-top: 3rem;
}
.formio-component-modal-wrapper .formio-component.formio-component-table table.table.table-sm,
.formio-form .formio-component.formio-component-table table.table.table-sm {
  table-layout: auto;
}
.formio-component-modal-wrapper .formio-component.formio-component-table.layout-auto table,
.formio-form .formio-component.formio-component-table.layout-auto table {
  table-layout: auto;
}
.formio-component-modal-wrapper .formio-component.formio-component-table.table-responsive,
.formio-form .formio-component.formio-component-table.table-responsive {
  overflow-x: initial;
}
.formio-component-modal-wrapper .formio-component.formio-component-number,
.formio-form .formio-component.formio-component-number {
  line-height: 1;
}
.formio-component-modal-wrapper .formio-component.formio-component-number > div,
.formio-form .formio-component.formio-component-number > div {
  line-height: 1;
}
.formio-component-modal-wrapper .formio-component.formio-component-number > div .input-group,
.formio-form .formio-component.formio-component-number > div .input-group {
  display: flex;
  align-items: center;
  height: 36px;
}
.formio-component-modal-wrapper .formio-component.formio-component-number > div .input-group :where(.input-group-prepend, .input-group-append),
.formio-form .formio-component.formio-component-number > div .input-group :where(.input-group-prepend, .input-group-append) {
  flex: auto;
  white-space: nowrap;
  border: solid 1px rgba(64, 68, 81, 0.16);
  background-color: #efefef;
  color: #9AA7B7;
  display: flex;
  height: 100%;
  align-items: center;
  border-radius: 3px 0 0 3px;
}
.formio-component-modal-wrapper .formio-component.formio-component-number > div .input-group :where(.input-group-prepend, .input-group-append) + input.form-control,
.formio-form .formio-component.formio-component-number > div .input-group :where(.input-group-prepend, .input-group-append) + input.form-control {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-inline-start: none;
}
.formio-component-modal-wrapper .formio-component.formio-component-number > div .input-group :where(.input-group-prepend, .input-group-append) + input.form-control:nth-last-child(2),
.formio-form .formio-component.formio-component-number > div .input-group :where(.input-group-prepend, .input-group-append) + input.form-control:nth-last-child(2) {
  border-inline-end: none;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.formio-component-modal-wrapper .formio-component.formio-component-number > div .input-group :where(.input-group-prepend, .input-group-append) .input-group-text,
.formio-form .formio-component.formio-component-number > div .input-group :where(.input-group-prepend, .input-group-append) .input-group-text {
  font-size: 14px;
  padding: 4.5px 14px;
}
.formio-component-modal-wrapper .formio-component.formio-component-number > div .input-group .input-group-append,
.formio-form .formio-component.formio-component-number > div .input-group .input-group-append {
  border-radius: 0 3px 3px 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-number > div .input-group input.form-control,
.formio-form .formio-component.formio-component-number > div .input-group input.form-control {
  flex: auto;
}
.formio-component-modal-wrapper .formio-component.formio-component-number > div .input-group input.form-control:first-child,
.formio-form .formio-component.formio-component-number > div .input-group input.form-control:first-child {
  border-inline-end: none;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.formio-component-modal-wrapper .formio-component.formio-component-currency,
.formio-form .formio-component.formio-component-currency {
  line-height: 1;
}
.formio-component-modal-wrapper .formio-component.formio-component-currency > div,
.formio-form .formio-component.formio-component-currency > div {
  line-height: 1;
}
.formio-component-modal-wrapper .formio-component.formio-component-currency > div .input-group,
.formio-form .formio-component.formio-component-currency > div .input-group {
  display: flex;
  align-items: center;
  height: 36px;
}
.formio-component-modal-wrapper .formio-component.formio-component-currency > div .input-group :where(.input-group-prepend, .input-group-append),
.formio-form .formio-component.formio-component-currency > div .input-group :where(.input-group-prepend, .input-group-append) {
  flex: auto;
  white-space: nowrap;
  border: solid 1px rgba(64, 68, 81, 0.16);
  background-color: #efefef;
  color: #9AA7B7;
  display: flex;
  height: 100%;
  align-items: center;
  border-radius: 3px 0 0 3px;
}
.formio-component-modal-wrapper .formio-component.formio-component-currency > div .input-group :where(.input-group-prepend, .input-group-append) + input.form-control,
.formio-form .formio-component.formio-component-currency > div .input-group :where(.input-group-prepend, .input-group-append) + input.form-control {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-inline-start: none;
}
.formio-component-modal-wrapper .formio-component.formio-component-currency > div .input-group :where(.input-group-prepend, .input-group-append) + input.form-control:nth-last-child(2),
.formio-form .formio-component.formio-component-currency > div .input-group :where(.input-group-prepend, .input-group-append) + input.form-control:nth-last-child(2) {
  border-inline-end: none;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.formio-component-modal-wrapper .formio-component.formio-component-currency > div .input-group :where(.input-group-prepend, .input-group-append) .input-group-text,
.formio-form .formio-component.formio-component-currency > div .input-group :where(.input-group-prepend, .input-group-append) .input-group-text {
  font-size: 14px;
  padding: 4.5px 14px;
}
.formio-component-modal-wrapper .formio-component.formio-component-currency > div .input-group .input-group-append,
.formio-form .formio-component.formio-component-currency > div .input-group .input-group-append {
  border-radius: 0 3px 3px 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-currency > div .input-group input.form-control,
.formio-form .formio-component.formio-component-currency > div .input-group input.form-control {
  flex: auto;
}
.formio-component-modal-wrapper .formio-component.formio-component-currency > div .input-group input.form-control:first-child,
.formio-form .formio-component.formio-component-currency > div .input-group input.form-control:first-child {
  border-inline-end: none;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.formio-component-modal-wrapper .formio-component.formio-component-textfield,
.formio-form .formio-component.formio-component-textfield {
  line-height: 1;
}
.formio-component-modal-wrapper .formio-component.formio-component-textfield > div,
.formio-form .formio-component.formio-component-textfield > div {
  line-height: 1;
}
.formio-component-modal-wrapper .formio-component.formio-component-textfield > div label.col-form-label i.glyphicon-question-sign,
.formio-form .formio-component.formio-component-textfield > div label.col-form-label i.glyphicon-question-sign {
  margin-top: -2px;
}
.formio-component-modal-wrapper .formio-component.formio-component-textfield > div .input-group,
.formio-form .formio-component.formio-component-textfield > div .input-group {
  display: flex;
  align-items: center;
  height: 36px;
}
.formio-component-modal-wrapper .formio-component.formio-component-textfield > div .input-group :where(.input-group-prepend, .input-group-append),
.formio-form .formio-component.formio-component-textfield > div .input-group :where(.input-group-prepend, .input-group-append) {
  flex: auto;
  white-space: nowrap;
  border: solid 1px rgba(64, 68, 81, 0.16);
  background-color: #efefef;
  color: #9AA7B7;
  display: flex;
  height: 100%;
  align-items: center;
  border-radius: 3px 0 0 3px;
}
.formio-component-modal-wrapper .formio-component.formio-component-textfield > div .input-group :where(.input-group-prepend, .input-group-append) + input.form-control,
.formio-form .formio-component.formio-component-textfield > div .input-group :where(.input-group-prepend, .input-group-append) + input.form-control {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-inline-start: none;
}
.formio-component-modal-wrapper .formio-component.formio-component-textfield > div .input-group :where(.input-group-prepend, .input-group-append) + input.form-control:nth-last-child(2),
.formio-form .formio-component.formio-component-textfield > div .input-group :where(.input-group-prepend, .input-group-append) + input.form-control:nth-last-child(2) {
  border-inline-end: none;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.formio-component-modal-wrapper .formio-component.formio-component-textfield > div .input-group :where(.input-group-prepend, .input-group-append) .input-group-append,
.formio-form .formio-component.formio-component-textfield > div .input-group :where(.input-group-prepend, .input-group-append) .input-group-append {
  border-radius: 0 3px 3px 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-textfield > div .input-group :where(.input-group-prepend, .input-group-append) .input-group-text,
.formio-form .formio-component.formio-component-textfield > div .input-group :where(.input-group-prepend, .input-group-append) .input-group-text {
  font-size: 14px;
  padding: 4.5px 14px;
}
.formio-component-modal-wrapper .formio-component.formio-component-textfield > div .input-group input.form-control,
.formio-form .formio-component.formio-component-textfield > div .input-group input.form-control {
  flex: auto;
}
.formio-component-modal-wrapper .formio-component.formio-component-textfield > div .input-group input.form-control:first-child,
.formio-form .formio-component.formio-component-textfield > div .input-group input.form-control:first-child {
  border-inline-end: none;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.formio-component-modal-wrapper .formio-component.formio-component-select,
.formio-form .formio-component.formio-component-select {
  line-height: 1;
}
.formio-component-modal-wrapper .formio-component.formio-component-select .choices__list.is-active .choices__list .choices__item,
.formio-form .formio-component.formio-component-select .choices__list.is-active .choices__list .choices__item {
  color: #404451;
}
.formio-component-modal-wrapper .formio-component.formio-component-select.hidden-field,
.formio-form .formio-component.formio-component-select.hidden-field {
  display: none !important;
}
.formio-component-modal-wrapper .formio-component.formio-component-radio,
.formio-form .formio-component.formio-component-radio {
  line-height: 1;
}
.formio-component-modal-wrapper .formio-component.formio-component-radio label.col-form-label,
.formio-form .formio-component.formio-component-radio label.col-form-label {
  margin-top: 0 !important;
}
.formio-component-modal-wrapper .formio-component.formio-component-radio .form-radio,
.formio-form .formio-component.formio-component-radio .form-radio {
  margin: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-radio .form-radio .form-check,
.formio-form .formio-component.formio-component-radio .form-radio .form-check {
  line-height: 1;
  margin-bottom: 1rem;
}
.formio-component-modal-wrapper .formio-component.formio-component-radio .form-radio .form-check label.form-check-label,
.formio-form .formio-component.formio-component-radio .form-radio .form-check label.form-check-label {
  min-height: auto;
  position: relative;
  margin-bottom: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-radio .form-radio .form-check label.form-check-label > input.form-check-input,
.formio-form .formio-component.formio-component-radio .form-radio .form-check label.form-check-label > input.form-check-input {
  margin: 0;
  inset-inline-start: 0;
  top: -1px;
  width: 14px;
  height: 14px;
}
.formio-component-modal-wrapper .formio-component.formio-component-radio .form-radio .form-check:last-child,
.formio-form .formio-component.formio-component-radio .form-radio .form-check:last-child {
  margin-bottom: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-radio .form-radio .form-check:last-child label.form-check-label,
.formio-form .formio-component.formio-component-radio .form-radio .form-check:last-child label.form-check-label {
  margin-bottom: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-radio .form-radio .form-check:first-child,
.formio-form .formio-component.formio-component-radio .form-radio .form-check:first-child {
  margin-top: 11px;
}
.formio-component-modal-wrapper .formio-component.formio-component-radio .form-radio .form-check-inline,
.formio-form .formio-component.formio-component-radio .form-radio .form-check-inline {
  line-height: 16px;
  display: inline-flex;
  margin-inline-end: 28px;
}
.formio-component-modal-wrapper .formio-component.formio-component-radio .form-radio .form-check-inline label.form-check-label,
.formio-form .formio-component.formio-component-radio .form-radio .form-check-inline label.form-check-label {
  display: block;
  line-height: 1;
  min-height: auto;
  position: relative;
  margin-bottom: 0;
  margin-top: 1px;
}
.formio-component-modal-wrapper .formio-component.formio-component-radio .form-radio .form-check-inline label.form-check-label > input.form-check-input,
.formio-form .formio-component.formio-component-radio .form-radio .form-check-inline label.form-check-label > input.form-check-input {
  top: -1px;
  margin: 0;
  inset-inline-start: 0;
  width: 14px;
  height: 14px;
}
.formio-component-modal-wrapper .formio-component.formio-component-radio .form-radio .form-check-inline:nth-last-of-type(1),
.formio-form .formio-component.formio-component-radio .form-radio .form-check-inline:nth-last-of-type(1) {
  margin-inline-end: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-radio .form-radio:first-child,
.formio-form .formio-component.formio-component-radio .form-radio:first-child {
  margin-top: 23px;
}
.formio-component-modal-wrapper .formio-component.formio-component-radio.remove-space-top .form-radio,
.formio-form .formio-component.formio-component-radio.remove-space-top .form-radio {
  margin-top: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-editgrid,
.formio-form .formio-component.formio-component-editgrid {
  max-width: 100%;
  width: fit-content;
  --custom-editgrid-listgroup-bg-color: white;
}
.formio-component-modal-wrapper .formio-component.formio-component-editgrid > button,
.formio-form .formio-component.formio-component-editgrid > button {
  border: 1px solid #3C8FB9;
  color: #3C8FB9;
  background-color: transparent;
  border-radius: 2px !important;
  font-weight: 400;
  padding: 0.5rem 1rem;
  line-height: 1;
}
.formio-component-modal-wrapper .formio-component.formio-component-editgrid .editgrid-listgroup,
.formio-form .formio-component.formio-component-editgrid .editgrid-listgroup {
  background-color: var(--custom-editgrid-listgroup-bg-color, white);
  border-radius: 3px;
  border: solid 1px #D5D6D9;
  margin-bottom: 0px;
}
.formio-component-modal-wrapper .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item,
.formio-form .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item {
  width: fit-content;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  background-color: var(--custom-editgrid-listgroup-bg-color, white);
  border: none;
  padding: 0px !important;
  line-height: 1;
  text-align: start;
  font-size: 16px;
  border-radius: 3px 3px 0 0 !important;
}
.formio-component-modal-wrapper .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item.list-group-footer > .row > div:last-of-type,
.formio-form .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item.list-group-footer > .row > div:last-of-type {
  flex: 0;
  min-width: 64px;
  max-width: fit-content;
}
.formio-component-modal-wrapper .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item.list-group-header,
.formio-form .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item.list-group-header {
  font-weight: 500;
  font-size: 12px;
  padding: 0 2.5rem 0 2.5rem;
  letter-spacing: 0.5px;
  background: #eff0f1;
}
.formio-component-modal-wrapper .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item.list-group-header > .row,
.formio-form .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item.list-group-header > .row {
  align-items: flex-start;
}
.formio-component-modal-wrapper .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item.list-group-header > .row > div,
.formio-form .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item.list-group-header > .row > div {
  border-inline-end: solid 1px #D5D6D9;
}
.formio-component-modal-wrapper .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item.list-group-header > .row > div:last-of-type,
.formio-form .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item.list-group-header > .row > div:last-of-type {
  border-inline-end: none;
  flex: 0;
  min-width: 64px;
  width: 64px;
  padding: 0 !important;
  max-width: fit-content;
  align-self: flex-start;
  text-align: end;
}
.formio-component-modal-wrapper .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item:only-child,
.formio-form .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item:only-child {
  border-bottom: none;
}
.formio-component-modal-wrapper .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item > .row:not(.formio-component-columns),
.formio-form .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item > .row:not(.formio-component-columns) {
  display: flex;
  align-items: center;
}
.formio-component-modal-wrapper .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item > .row:not(.formio-component-columns) > div,
.formio-form .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item > .row:not(.formio-component-columns) > div {
  width: auto;
  word-break: break-word;
  flex: 1;
  min-width: 170px;
  max-width: 240px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.formio-component-modal-wrapper .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item > .row:not(.formio-component-columns)::after,
.formio-form .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item > .row:not(.formio-component-columns)::after,
.formio-component-modal-wrapper .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item > .row:not(.formio-component-columns)::before,
.formio-form .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item > .row:not(.formio-component-columns)::before {
  display: none;
}
.formio-component-modal-wrapper .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item > .row.formio-component-columns,
.formio-form .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item > .row.formio-component-columns {
  align-items: start;
}
.formio-component-modal-wrapper .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item > .row.formio-component-columns:first-of-type,
.formio-form .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item > .row.formio-component-columns:first-of-type {
  margin-top: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item > .row .btn-group .btn,
.formio-form .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item > .row .btn-group .btn {
  background: transparent;
  border: none;
}
.formio-component-modal-wrapper .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item > .row .btn-group .btn.removeRow,
.formio-form .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item > .row .btn-group .btn.removeRow {
  color: #f44336;
}
.formio-component-modal-wrapper .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item .editgrid-actions,
.formio-form .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item .editgrid-actions {
  margin-top: 30px;
}
.formio-component-modal-wrapper .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item:not(.list-group-header):not(.list-group-footer) > .row:not(.formio-component-columns) > div,
.formio-form .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item:not(.list-group-header):not(.list-group-footer) > .row:not(.formio-component-columns) > div {
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 1.2;
}
.formio-component-modal-wrapper .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item:not(.list-group-header):not(.list-group-footer) > .row:not(.formio-component-columns) > div:last-of-type .btn-group,
.formio-form .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item:not(.list-group-header):not(.list-group-footer) > .row:not(.formio-component-columns) > div:last-of-type .btn-group {
  float: none !important;
  display: flex;
  align-items: center;
  background: white;
  transition: all 0.3s;
}
.formio-component-modal-wrapper .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item:not(.list-group-header):not(.list-group-footer) > .row:not(.formio-component-columns) > div:last-of-type .btn-group .btn-icon-toggle,
.formio-form .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item:not(.list-group-header):not(.list-group-footer) > .row:not(.formio-component-columns) > div:last-of-type .btn-group .btn-icon-toggle {
  margin: 0;
  display: inline-flex;
  height: 100%;
  border-radius: 0 !important;
  align-items: center;
  justify-content: center;
  color: #9AA7B7;
}
.formio-component-modal-wrapper .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item:not(.list-group-header):not(.list-group-footer) > .row:not(.formio-component-columns) > div:last-of-type .btn-group .btn-icon-toggle:hover,
.formio-form .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item:not(.list-group-header):not(.list-group-footer) > .row:not(.formio-component-columns) > div:last-of-type .btn-group .btn-icon-toggle:hover {
  color: #404451;
}
.formio-component-modal-wrapper .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item .help-block,
.formio-form .formio-component.formio-component-editgrid .editgrid-listgroup .list-group-item .help-block {
  line-height: 2;
  margin: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-editgrid.search-result-list .editgrid-listgroup .list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry),
.formio-form .formio-component.formio-component-editgrid.search-result-list .editgrid-listgroup .list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) {
  cursor: pointer;
}
.formio-component-modal-wrapper .formio-component.formio-component-editgrid.search-result-list .editgrid-listgroup .list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry):where(:hover, .selected),
.formio-form .formio-component.formio-component-editgrid.search-result-list .editgrid-listgroup .list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry):where(:hover, .selected),
.formio-component-modal-wrapper .formio-component.formio-component-editgrid.search-result-list .editgrid-listgroup .list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) :where(:hover, .selected),
.formio-form .formio-component.formio-component-editgrid.search-result-list .editgrid-listgroup .list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) :where(:hover, .selected) {
  background-color: #f5f6f8 !important;
}
.formio-component-modal-wrapper .formio-component.formio-component-editgrid.search-result-list .editgrid-listgroup .list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry):where(:hover, .selected) .eg-action-col .btn-group,
.formio-form .formio-component.formio-component-editgrid.search-result-list .editgrid-listgroup .list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry):where(:hover, .selected) .eg-action-col .btn-group,
.formio-component-modal-wrapper .formio-component.formio-component-editgrid.search-result-list .editgrid-listgroup .list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) :where(:hover, .selected) .eg-action-col .btn-group,
.formio-form .formio-component.formio-component-editgrid.search-result-list .editgrid-listgroup .list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) :where(:hover, .selected) .eg-action-col .btn-group {
  background-color: #f5f6f8 !important;
}
.formio-component-modal-wrapper .formio-component.formio-component-editgrid.has-open-row > .editgrid-listgroup > .list-group-item:not(.list-group-header, .list-group-footer) > .row > .eg-action-col .editRow,
.formio-form .formio-component.formio-component-editgrid.has-open-row > .editgrid-listgroup > .list-group-item:not(.list-group-header, .list-group-footer) > .row > .eg-action-col .editRow {
  display: none !important;
}
.formio-component-modal-wrapper .formio-component.formio-component-editgrid.has-open-row > button.btn,
.formio-form .formio-component.formio-component-editgrid.has-open-row > button.btn {
  display: none !important;
}
.formio-component-modal-wrapper .formio-component.formio-component-editgrid.has-open-row:has(.list-group-header + .new-entry:last-child) > .editgrid-listgroup > .list-group-item:is(.list-group-header, .list-group-footer),
.formio-form .formio-component.formio-component-editgrid.has-open-row:has(.list-group-header + .new-entry:last-child) > .editgrid-listgroup > .list-group-item:is(.list-group-header, .list-group-footer),
.formio-component-modal-wrapper .formio-component.formio-component-editgrid.has-open-row:has(.list-group-header + .new-entry + .list-group-footer) > .editgrid-listgroup > .list-group-item:is(.list-group-header, .list-group-footer),
.formio-form .formio-component.formio-component-editgrid.has-open-row:has(.list-group-header + .new-entry + .list-group-footer) > .editgrid-listgroup > .list-group-item:is(.list-group-header, .list-group-footer) {
  display: none;
}
.formio-component-modal-wrapper .formio-component.formio-component-editgrid.no-rows .list-group-item:is(.list-group-header, .list-group-footer),
.formio-form .formio-component.formio-component-editgrid.no-rows .list-group-item:is(.list-group-header, .list-group-footer) {
  display: none !important;
}
.formio-component-modal-wrapper .formio-component.formio-component-datetime,
.formio-form .formio-component.formio-component-datetime {
  line-height: 1;
}
.formio-component-modal-wrapper .formio-component.formio-component-datetime .input-group input.form-control,
.formio-form .formio-component.formio-component-datetime .input-group input.form-control {
  border-radius: 3px 0 0 3px !important;
  display: table-cell;
}
.formio-component-modal-wrapper .formio-component.formio-component-datetime .input-group input.form-control ~ .input-group-append,
.formio-form .formio-component.formio-component-datetime .input-group input.form-control ~ .input-group-append {
  display: table-cell;
  vertical-align: middle;
}
.formio-component-modal-wrapper .formio-component.formio-component-datetime .input-group input.form-control ~ .input-group-append .input-group-text,
.formio-form .formio-component.formio-component-datetime .input-group input.form-control ~ .input-group-append .input-group-text {
  line-height: 25px;
  border-radius: 0 3px 3px 0;
  padding: 5px 14px;
  background-color: #fff;
  color: #9aa7b7;
  display: block;
  border: solid 1px rgba(64, 68, 81, 0.16);
  border-inline-start: 0;
  border-color: transparent;
}
.formio-component-modal-wrapper .formio-component.formio-component-datetime .input-group input.form-control ~ .input-group-append .input-group-text:where(:active, :focus),
.formio-form .formio-component.formio-component-datetime .input-group input.form-control ~ .input-group-append .input-group-text:where(:active, :focus) {
  border-color: transparent;
}
.formio-component-modal-wrapper .formio-component.formio-component-selectboxes,
.formio-form .formio-component.formio-component-selectboxes {
  line-height: 1;
}
.formio-component-modal-wrapper .formio-component.formio-component-selectboxes .form-radio,
.formio-form .formio-component.formio-component-selectboxes .form-radio {
  line-height: 1;
  margin: 13px 0 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-selectboxes .form-radio .form-check,
.formio-form .formio-component.formio-component-selectboxes .form-radio .form-check {
  margin-bottom: 1rem;
}
.formio-component-modal-wrapper .formio-component.formio-component-selectboxes .form-radio .form-check .form-check-label,
.formio-form .formio-component.formio-component-selectboxes .form-radio .form-check .form-check-label {
  line-height: 1;
  display: flex;
  min-height: auto;
  align-items: center;
  padding: 0;
  margin-bottom: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-selectboxes .form-radio .form-check .form-check-label input.form-check-input,
.formio-form .formio-component.formio-component-selectboxes .form-radio .form-check .form-check-label input.form-check-input {
  margin: 0;
  margin-inline-end: 0.7rem;
  position: relative;
  top: -1px;
  width: 14px;
  height: 14px;
}
.formio-component-modal-wrapper .formio-component.formio-component-selectboxes .form-radio .form-check:last-child,
.formio-form .formio-component.formio-component-selectboxes .form-radio .form-check:last-child {
  margin-bottom: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-selectboxes .form-radio .form-check:last-child label.form-check-label,
.formio-form .formio-component.formio-component-selectboxes .form-radio .form-check:last-child label.form-check-label {
  margin-bottom: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-selectboxes .form-radio .form-check-inline,
.formio-form .formio-component.formio-component-selectboxes .form-radio .form-check-inline {
  display: inline-flex;
  margin-inline-end: 28px;
}
.formio-component-modal-wrapper .formio-component.formio-component-selectboxes .form-radio .form-check-inline .form-check-label,
.formio-form .formio-component.formio-component-selectboxes .form-radio .form-check-inline .form-check-label {
  line-height: 1;
  display: flex;
  min-height: auto;
  align-items: center;
  padding: 0;
  margin-bottom: 8px;
}
.formio-component-modal-wrapper .formio-component.formio-component-selectboxes .form-radio .form-check-inline .form-check-label input.form-check-input,
.formio-form .formio-component.formio-component-selectboxes .form-radio .form-check-inline .form-check-label input.form-check-input {
  margin: 0;
  margin-inline-end: 0.7rem;
  position: relative;
  top: -1px;
  width: 14px;
  height: 14px;
}
.formio-component-modal-wrapper .formio-component.formio-component-selectboxes .form-radio:first-child,
.formio-form .formio-component.formio-component-selectboxes .form-radio:first-child {
  margin-top: 35px;
}
.formio-component-modal-wrapper .formio-component.formio-component-selectboxes.remove-space-top .form-radio,
.formio-form .formio-component.formio-component-selectboxes.remove-space-top .form-radio {
  margin-top: 12px;
}
.formio-component-modal-wrapper .formio-component.formio-component-url label.col-form-label,
.formio-form .formio-component.formio-component-url label.col-form-label {
  display: block;
}
.formio-component-modal-wrapper .formio-component.formio-component-url > div input.form-control,
.formio-form .formio-component.formio-component-url > div input.form-control {
  border-radius: 3px;
  border-color: rgba(64, 68, 81, 0.16);
  box-shadow: none;
  letter-spacing: 0.4pt;
  color: #404451;
}
.formio-component-modal-wrapper .formio-component.formio-component-url > div input.form-control:focus,
.formio-form .formio-component.formio-component-url > div input.form-control:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.formio-component-modal-wrapper .formio-component.formio-component-email,
.formio-form .formio-component.formio-component-email {
  line-height: 1;
}
.formio-component-modal-wrapper .formio-component.formio-component-hidden,
.formio-form .formio-component.formio-component-hidden {
  display: none;
}
.formio-component-modal-wrapper .formio-component.formio-hidden,
.formio-form .formio-component.formio-hidden {
  display: none;
}
.formio-component-modal-wrapper .formio-component.formio-error-wrapper,
.formio-form .formio-component.formio-error-wrapper {
  background-color: transparent;
}
.formio-component-modal-wrapper .formio-component.formio-error-wrapper .formio-errors .form-text,
.formio-form .formio-component.formio-error-wrapper .formio-errors .form-text {
  font-size: 11px;
  margin-top: 5px;
}
.formio-component-modal-wrapper .formio-component.formio-error-wrapper .form-control,
.formio-form .formio-component.formio-error-wrapper .form-control {
  border-color: #d56d72;
}
.formio-component-modal-wrapper .formio-component.formio-component-file,
.formio-form .formio-component.formio-component-file {
  margin-top: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-file .fileSelector,
.formio-form .formio-component.formio-component-file .fileSelector {
  color: #404451;
}
.formio-component-modal-wrapper .formio-component.formio-component-file .fileSelector a,
.formio-form .formio-component.formio-component-file .fileSelector a {
  color: #3b8fb9;
}
.formio-component-modal-wrapper .formio-component.formio-component-file .fileSelector i,
.formio-form .formio-component.formio-component-file .fileSelector i {
  font-size: 17px;
}
.formio-component-modal-wrapper .formio-component.formio-component-tabs > .card,
.formio-form .formio-component.formio-component-tabs > .card {
  background-color: transparent;
}
.formio-component-modal-wrapper .formio-component.formio-component-tabs > .card > .card-header,
.formio-form .formio-component.formio-component-tabs > .card > .card-header {
  padding: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-tabs > .card > .card-header .card-header-tabs,
.formio-form .formio-component.formio-component-tabs > .card > .card-header .card-header-tabs {
  min-height: auto;
}
.formio-component-modal-wrapper .formio-component.formio-component-tabs > .card > .card-header .card-header-tabs .nav-item:not(.active)::before,
.formio-form .formio-component.formio-component-tabs > .card > .card-header .card-header-tabs .nav-item:not(.active)::before {
  content: "";
  width: 2px;
  background-color: #c6c8cc;
  height: 60%;
  position: absolute;
  inset-inline-start: 0;
  top: calc(20% - 2px);
}
.formio-component-modal-wrapper .formio-component.formio-component-tabs > .card > .card-header .card-header-tabs .nav-item:not(.active):first-child::before,
.formio-form .formio-component.formio-component-tabs > .card > .card-header .card-header-tabs .nav-item:not(.active):first-child::before {
  display: none;
}
.formio-component-modal-wrapper .formio-component.formio-component-tabs > .card > .card-header .card-header-tabs .nav-item.active + .nav-item::before,
.formio-form .formio-component.formio-component-tabs > .card > .card-header .card-header-tabs .nav-item.active + .nav-item::before {
  display: none;
}
.formio-component-modal-wrapper .formio-component.formio-component-tabs > .card > .card-header .card-header-tabs .nav-item .nav-link,
.formio-form .formio-component.formio-component-tabs > .card > .card-header .card-header-tabs .nav-item .nav-link {
  opacity: 1;
}
.formio-component-modal-wrapper .formio-component.formio-component-tabs > .card > .card-header .card-header-tabs .nav-item .nav-link.active,
.formio-form .formio-component.formio-component-tabs > .card > .card-header .card-header-tabs .nav-item .nav-link.active {
  z-index: 9;
}
.formio-component-modal-wrapper .formio-component.formio-component-tabs > .card > .card-header .card-header-tabs .nav-item .nav-link:hover,
.formio-form .formio-component.formio-component-tabs > .card > .card-header .card-header-tabs .nav-item .nav-link:hover {
  color: #404451;
}
.formio-component-modal-wrapper .formio-component.formio-component-tabs > .card > .card-body.tab-pane,
.formio-form .formio-component.formio-component-tabs > .card > .card-body.tab-pane {
  padding: 2.5rem 2.5rem 0.8rem;
}
.formio-component-modal-wrapper .formio-component.formio-component-tabs > .card > .card-body.tab-pane > .formio-component:last-child,
.formio-form .formio-component.formio-component-tabs > .card > .card-body.tab-pane > .formio-component:last-child {
  margin-bottom: 0;
}
.formio-component-modal-wrapper .formio-component.formio-component-tabs > .card > .card-body.tab-pane > .formio-component:first-child,
.formio-form .formio-component.formio-component-tabs > .card > .card-body.tab-pane > .formio-component:first-child {
  margin-top: 0;
}
.formio-component-modal-wrapper .formio-component:where(.top-space-zero, .margin-top-zero),
.formio-form .formio-component:where(.top-space-zero, .margin-top-zero) {
  margin-top: 0 !important;
}
.formio-component-modal-wrapper .formio-component.top-space,
.formio-form .formio-component.top-space {
  margin-top: 3rem;
}
.formio-component-modal-wrapper .formio-component.remove-top-space.formio-component-radio .form-radio,
.formio-form .formio-component.remove-top-space.formio-component-radio .form-radio {
  margin-top: -10px;
}
.formio-component-modal-wrapper .formio-component.remove-top-space.formio-component-radio .form-radio .form-check-inline,
.formio-form .formio-component.remove-top-space.formio-component-radio .form-radio .form-check-inline {
  margin-top: 1px;
}
.formio-component-modal-wrapper .formio-component.remove-top-space.formio-component-selectboxes .form-radio,
.formio-form .formio-component.remove-top-space.formio-component-selectboxes .form-radio {
  margin-top: 1px;
}
.formio-component-modal-wrapper .formio-component.remove-top-space.formio-component-selectboxes .form-radio .form-check,
.formio-form .formio-component.remove-top-space.formio-component-selectboxes .form-radio .form-check {
  margin-top: 2px;
}
.formio-component-modal-wrapper .formio-component.remove-top-space.formio-component-selectboxes .form-radio .form-check-inline,
.formio-form .formio-component.remove-top-space.formio-component-selectboxes .form-radio .form-check-inline {
  display: inline-grid;
  margin-inline-end: 28px;
  position: relative;
}
.formio-component-modal-wrapper .formio-component.remove-top-space.formio-component-selectboxes .form-radio .form-check-inline .form-check-label,
.formio-form .formio-component.remove-top-space.formio-component-selectboxes .form-radio .form-check-inline .form-check-label {
  margin-bottom: 0;
}
.formio-component-modal-wrapper .formio-component.value-label-style input.form-control,
.formio-form .formio-component.value-label-style input.form-control {
  appearance: none;
  background: transparent;
  border: none;
  padding: 0;
  letter-spacing: 0 !important;
  font-size: 1.4rem;
  font-weight: 500;
}
#base #content .section-body > .contain-lg > i.fa-info {
  display: none;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card {
  box-shadow: none;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-header ul.card-header-tabs {
  background-color: white;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-header ul.card-header-tabs li.nav-item {
  max-width: fit-content;
  height: auto;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-header ul.card-header-tabs li.nav-item::before {
  display: none;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-header ul.card-header-tabs li.nav-item.active a.nav-link {
  background-color: #f5f6f8;
  box-shadow: 0 4px 3px rgba(0, 0, 0, 0.16);
  z-index: 1;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-header ul.card-header-tabs li.nav-item a.nav-link {
  border-radius: 3px 3px 0 0;
  text-transform: none;
  font-size: 2rem;
  padding-inline-start: 3rem;
  padding-inline-end: 3rem;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane {
  z-index: 2;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
  background: #f5f6f8;
  padding: 0;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body {
  padding: 2.7rem 2.5rem 2.5rem !important;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns {
  margin-top: 0;
  align-items: center;
  border: solid 1px #e3e3e6;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns:nth-child(odd) {
  background-color: #f2f2f2;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns + .formio-component.formio-component-columns {
  border-top: none;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div {
  height: 100%;
  padding: 6px 12px;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div:first-of-type {
  border-inline-end: solid 1px #e3e3e6;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div:first-of-type + div {
  border-inline-start: solid 1px #e3e3e6;
  margin-inline-start: -1px;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component.formio-component-htmlelement > p {
  margin-bottom: 0;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component:where(.formio-component-textfield, .formio-component-number) input.form-control {
  background-color: transparent !important;
  border: none;
  padding: 0;
  cursor: default;
  height: auto;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component:where(.formio-component-textfield, .formio-component-number) input.form-control:hover {
  box-shadow: none;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component.formio-component-select .form-control.dropdown {
  background: transparent;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  height: auto;
  min-height: auto;
  line-height: 1;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component.formio-component-select .form-control.dropdown .choices__item {
  cursor: default;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component.formio-component-datetime .input-group input.input.form-control {
  border: none;
  background: transparent !important;
  padding: 0;
  cursor: default;
  height: auto;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component.formio-component-datetime .input-group input.input.form-control:hover {
  box-shadow: none;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component.formio-component-datetime .input-group .input-group-append {
  display: none;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component.formio-component-textarea .card-body {
  padding: 0;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component.formio-component-textarea .card-body .formio-editor-read-only-content {
  font-weight: 500;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component.formio-component-radio > .form-radio {
  margin-top: 0;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component.formio-component-radio > .form-radio .form-check {
  display: none;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component.formio-component-radio > .form-radio .form-check.radio-selected {
  display: block;
  margin: 0;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component.formio-component-radio > .form-radio .form-check.radio-selected label.form-check-label {
  padding: 0;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component.formio-component-radio > .form-radio .form-check.radio-selected label.form-check-label input {
  display: none;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component.formio-component-radio > .form-radio .form-check.radio-selected label.form-check-label span {
  font-weight: 500;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component.formio-component-datagrid .datagrid-table {
  margin-top: 6px;
  margin-bottom: 6px;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component.formio-component-datagrid .datagrid-table > tbody > tr > td {
  padding: 0;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component.formio-component-datagrid .datagrid-table > tbody > tr > td .formio-component-table {
  margin: 0;
  padding: 0;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component.formio-component-datagrid .datagrid-table > tbody > tr > td .formio-component-table table.table {
  background-color: transparent;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component.formio-component-datagrid .datagrid-table > tbody > tr > td .formio-component-table table.table tbody tr.row {
  align-items: center;
  margin: 0;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component.formio-component-datagrid .datagrid-table > tbody > tr > td .formio-component-table table.table tbody tr.row td {
  min-height: 38px;
  display: flex;
  align-items: center;
  border: none !important;
  border-inline-end: solid 1px #e3e3e6 !important;
  border-bottom: solid 1px #e3e3e6 !important;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component.formio-component-datagrid .datagrid-table > tbody > tr > td .formio-component-table table.table tbody tr.row td:first-child {
  flex: 1;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component.formio-component-datagrid .datagrid-table > tbody > tr > td .formio-component-table table.table tbody tr.row td:first-child + td {
  flex: 3;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component.formio-component-datagrid .datagrid-table > tbody > tr > td .formio-component-table table.table tbody tr.row td input:hover {
  outline: none;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component.formio-component-datagrid .datagrid-table > tbody > tr > td .formio-component-table table.table tbody tr.row td .formio-component {
  margin-top: 0;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component.formio-component-datagrid .datagrid-table > tbody > tr > td .formio-component-table table.table tbody tr.row td:last-child {
  border-inline-end: none !important;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component.formio-component-datagrid .datagrid-table > tbody > tr > td .formio-component-table table.table tbody tr.row td .formio-component {
  margin-top: 0;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component.formio-component-datagrid .datagrid-table > tbody > tr > td .formio-component-table table.table tbody tr.row td .formio-component.formio-component-htmlelement > p {
  margin-bottom: 0;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component.formio-component-datagrid .datagrid-table > tbody > tr > td .formio-component-table table.table tbody tr.row td .formio-component:where(.formio-component-textfield, .formio-component-number) input.form-control {
  background-color: transparent !important;
  border: none;
  padding: 0;
  cursor: default;
  height: auto;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component.formio-component-datagrid .datagrid-table > tbody > tr > td .formio-component-table table.table tbody tr.row td .formio-component:where(.formio-component-textfield, .formio-component-number) input.form-control:hover {
  box-shadow: none;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component.formio-component-datagrid .datagrid-table > tbody > tr > td .formio-component-table table.table tbody tr.row td .formio-component.formio-component-select .form-control.dropdown {
  background: transparent;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  height: auto;
  min-height: auto;
  line-height: 1;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component.formio-component-datagrid .datagrid-table > tbody > tr > td .formio-component-table table.table tbody tr.row td .formio-component.formio-component-select .form-control.dropdown .choices__item {
  cursor: default;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component.formio-component-datagrid .datagrid-table > tbody > tr > td .formio-component-table table.table tbody tr.row td .formio-component.formio-component-datetime .input-group input.input.form-control {
  border: none;
  background: transparent !important;
  padding: 0;
  cursor: default;
  height: auto;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component.formio-component-datagrid .datagrid-table > tbody > tr > td .formio-component-table table.table tbody tr.row td .formio-component.formio-component-datetime .input-group input.input.form-control:hover {
  box-shadow: none;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component.formio-component-datagrid .datagrid-table > tbody > tr > td .formio-component-table table.table tbody tr.row td .formio-component.formio-component-datetime .input-group .input-group-append {
  display: none;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component.formio-component-datagrid .datagrid-table > tbody > tr > td .formio-component-table table.table tbody tr.row:nth-child(odd) td {
  background-color: #f2f2f2;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component.formio-component-datagrid .datagrid-table > tbody > tr > td .formio-component-table table.table tbody tr.row:nth-child(even) td {
  background-color: #f5f6f8;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div > .formio-component.formio-component-datagrid .datagrid-table > tbody > tr:last-child > td .formio-component-table > table.table > tbody > tr.row:last-child > td {
  border-bottom: none !important;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > div.formio-errors {
  display: none;
}
#base #content .section-body > .contain-lg #rolereview .formio-component-form .formio-form > .formio-component-tabs .card > .card-body.tab-pane > .formio-component-panel > .card::after {
  display: block;
  position: absolute;
  content: "";
  height: 3rem;
  background: white;
  width: 100%;
  z-index: -9;
  box-shadow: -10px 0 white, 10px 0 white, inset 1px 0 white, inset -1px 0 white, inset 0 1px 2px rgba(0, 0, 0, 0.1);
}
/* Default intuitive rules (new formio) */
.formio-form .formio-component.formio-component-panel > .card > .card-body > .formio-component:not(.formio-component-hidden, .ng-hide):first-of-type {
  margin-top: 0;
}
.formio-form .formio-component.formio-component-mycontent mycontent *:last-child {
  margin-bottom: 0;
}
.formio-form .formio-component.formio-component-mycontent mycontent ol li {
  margin-bottom: 1rem;
}
.formio-form .formio-component.formio-component-mycontent mycontent ol li:last-child {
  margin-bottom: 0;
}
.formio-form .formio-component.formio-component-table + .formio-component-panel {
  margin-top: 3rem;
}
.formio-form .formio-component.formio-component-htmlelement p:empty:first-child + p {
  margin-top: 0;
}
.formio-form .formio-component.formio-component-htmlelement p:empty:first-child + ul {
  margin-top: 0;
}
.formio-form .formio-component.required > label.field-required > i.fa-question-circle {
  margin-inline-end: -28px;
  margin-inline-start: 14px;
}
/* Temporary overrides (new formio) */
.formio-form .formio-component.formio-component-panel:where(.formio-component-initialquestionspanel, .formio-component-registrationspanel) > .card {
  border-inline-start: none;
}
.formio-form .formio-component.formio-component-panel:where(.formio-component-initialquestionspanel, .formio-component-registrationspanel) > .card > .card-body {
  padding-bottom: 0;
  margin-bottom: 0 !important;
}
.formio-form .formio-component.formio-component-panel:where(.formio-component-initialquestionspanel, .formio-component-registrationspanel) > .card > .card-body .card {
  border: none;
  padding: 0;
}
.formio-form .formio-component.formio-component-panel.formio-component-initialquestionspanel > .card > .card-body > .formio-component:first-of-type.formio-hidden + .formio-component:not(.formio-hidden) {
  margin-top: 0;
}
.formio-form .formio-component.formio-component-panel.formio-component-applicantFees > .card {
  border-inline-start: none !important;
}
.formio-form .formio-component.formio-component-panel.formio-component-applicantFees > .card .card-heading {
  padding: 0;
}
.formio-form .formio-component.formio-component-panel.formio-component-applicantFees > .card .card-body {
  padding: 0;
}
.formio-form .formio-component.formio-component-panel.formio-component-applicantFees > .card .card-body .formio-component-totalPrice {
  margin: 0;
}
.formio-form .formio-component.formio-component-panel.formio-component-applicantFees > .card .card-body .formio-component-totalPriceLabelapplicanttotalPricetotalCost {
  line-height: 1;
}
.formio-form .formio-component.formio-component-panel.formio-component-applicantFees > .card .card-body h4 {
  margin-bottom: 0;
}
.formio-form .formio-component.formio-component-columns.formio-component-questionscolumns > [class^="col-"] .formio-component-htmlelement + .formio-component-columns label.col-form-label:first-of-type {
  margin-top: 0;
}
.formio-form .formio-component.formio-component-columns.formio-component-questionscolumns > [class^="col-"] .formio-component-checkbox > div > label {
  margin-top: 0;
}
.formio-form .formio-component.formio-component-columns.formio-component-questionscolumns > [class^="col-"] .formio-component-initialquestionspanel > .card > .card-body {
  margin-bottom: 3rem !important;
}
.formio-form .formio-component.formio-component-columns.formio-component-questionscolumns > [class^="col-"] .formio-component-initialquestionspanel > .card > .card-body > .formio-component-columns {
  margin: 0;
}
.formio-form .formio-component.formio-component-columns.formio-component-questionscolumns > [class^="col-"] .formio-component-initialquestionspanel > .card > .card-body > .formio-component-htmlelement > p {
  padding: 0;
}
.formio-form .formio-component.formio-component-columns.formio-component-questionscolumns > [class^="col-"] .formio-component-initialquestionspanel .formio-component.formio-component-htmlelement + .formio-component-columns label.col-form-label {
  margin-top: 0;
}
.formio-form .formio-component.formio-component-columns.formio-component-questionscolumns > [class^="col-"] .formio-component-initialquestionspanel .formio-component.formio-component-htmlelement + .formio-component-columns ~ .form-group .radio-inline label.control-label {
  margin-top: 0;
}
.formio-form .formio-component.formio-component-columns.formio-component-questionscolumns > [class^="col-"] .formio-component-initialquestionspanel .formio-component.formio-component-htmlelement + .formio-component-columns ~ .formio-component-columns .formio-component-mytooltip {
  margin-top: 2.75rem;
}
.formio-form .formio-component.formio-component-columns.formio-component-questionscolumns > [class^="col-"] .formio-component-initialquestionspanel .formio-component.formio-component-htmlelement + .formio-component-columns ~ .formio-component-columns .formio-component-mytooltip a.btn-icon-toggle {
  top: auto !important;
}
.formio-form .formio-component.formio-component-columns.formio-component-questionscolumns > [class^="col-"] .formio-component-initialquestionspanel .formio-component.formio-component-htmlelement + .formio-component-columns ~ .formio-component-columns .formio-component-mytooltip .btn-icon-toggle {
  border: none;
}
.formio-form .formio-component.formio-component-columns.formio-component-questionscolumns > [class^="col-"] .formio-component-initialquestionspanel .formio-component.formio-component-htmlelement + .formio-component-columns ~ .formio-component-columns .formio-component-mytooltip .btn-icon-toggle i {
  font-family: var(--fontawesome-font);
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-size: 14px;
}
.formio-form .formio-component.formio-component-columns.formio-component-questionscolumns > [class^="col-"] .formio-component-initialquestionspanel .formio-component.formio-component-htmlelement + .formio-component-columns ~ .formio-component-columns .formio-component-mytooltip .btn-icon-toggle i::before {
  content: "\F05A";
}
.formio-form .formio-component.formio-component-columns.formio-component-questionscolumns > [class^="col-"] .formio-component-initialquestionspanel .formio-component.formio-component-htmlelement + .formio-component-columns ~ .formio-component-columns .formio-component-mytooltip .btn-icon-toggle:hover {
  background-color: transparent;
}
.formio-form .formio-component.formio-component-columns.formio-component-questionscolumns > [class^="col-"] .formio-component-initialquestionspanel .formio-component.formio-component-htmlelement {
  margin-top: 0;
}
.formio-form .formio-component.formio-component-columns.formio-component-questionscolumns > [class^="col-"] .formio-component-initialquestionspanel .formio-component.formio-component-htmlelement.formio-component-guideInitialDescription p {
  margin-bottom: 0;
}
.formio-form .formio-component.formio-component-columns.formio-component-questionscolumns > [class^="col-"] .formio-component-initialquestionspanel .formio-component.formio-component-select .ui-select-choices {
  max-height: 14.5rem;
}
.formio-form .formio-component.formio-component-columns.formio-component-questionscolumns > [class^="col-"] .formio-component-registrationspanel > .card > .card-body {
  margin-bottom: 3rem !important;
}
.formio-form .formio-component.formio-component-columns.formio-component-questionscolumns > [class^="col-"] .formio-component-registrationspanel > .card > .card-body > .formio-component-panel {
  margin-top: 0 !important;
}
.formio-form .formio-component.formio-component-columns.formio-component-questionscolumns > [class^="col-"] .formio-component-registrationspanel > .card > .card-body > .formio-component-panel > .card {
  border-inline-start: none;
}
.formio-form .formio-component.formio-component-columns.formio-component-questionscolumns > [class^="col-"] .formio-component-registrationspanel > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement {
  margin-bottom: 3rem;
  margin-top: 0;
  line-height: 1;
}
.formio-form .formio-component.formio-component-columns.formio-component-questionscolumns > [class^="col-"] .formio-component-registrationspanel > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement > .card-title {
  color: var(--color-gray-800);
  font-size: 2rem;
  font-weight: 500;
  border: none;
  position: relative;
  padding: 0 0 1rem;
}
.formio-form .formio-component.formio-component-columns.formio-component-questionscolumns > [class^="col-"] .formio-component-registrationspanel > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement p {
  padding: 0;
}
.formio-form .formio-component.formio-component-columns.formio-component-questionscolumns > [class^="col-"] .formio-component-registrationspanel > .card > .card-body > .formio-component-panel > .card > .card-body .formio-component-checkbox {
  margin-top: 1rem;
}
.formio-form .formio-component.formio-component-columns.formio-component-questionscolumns > [class^="col-"] .formio-component-registrationspanel > .card > .card-body > .formio-component-panel.formio-component-columnsPanel2Mandatory {
  margin-bottom: 0;
}
.formio-form .formio-component.formio-component-columns.formio-component-questionscolumns > [class^="col-"] .formio-component-registrationspanel > .card > .card-body > .formio-component-panel.formio-component-columnsPanel2Mandatory > .card > .card-body {
  padding: 0;
}
.formio-form .formio-component.formio-component-columns.formio-component-questionscolumns > [class^="col-"] .formio-component-registrationspanel > .card > .card-body > .formio-component-panel.formio-component-columnsPanel2Mandatory > .card > .card-body > .formio-component:last-of-type:not(.formio-hidden):not(.formio-component-hidden) {
  margin-bottom: 3rem;
}
.formio-form .formio-component.formio-component-columns.formio-component-questionscolumns > [class^="col-"] .formio-component-requirementsContainer .formio-component-requirementsRows {
  margin-top: 0;
}
.formio-form .formio-component.formio-component-columns.formio-component-questionscolumns > [class^="col-"] .formio-component-requirementsContainer .formio-component-requirementsRows .form-group.form-field-type-htmlelement {
  margin-top: 0;
}
.formio-form .formio-component.formio-component-columns.formio-component-questionscolumns > [class^="col-"].col-md-1 {
  align-items: center;
  display: flex;
  justify-content: center;
}
.formio-form .formio-component.formio-component-columns.formio-component-questionscolumns > [class^="col-"].col-md-1 > .formio-component-htmlelement {
  margin: 0;
}
.formio-form .formio-component.formio-component-columns.formio-component-questionscolumns > [class^="col-"].col-md-1 > .formio-component-htmlelement > p {
  margin: 0;
}
.formio-form .formio-component.formio-component-columns.formio-component-questionscolumns > [class^="col-"].col-md-1 > .formio-component-htmlelement > p > span {
  font-size: 5rem;
  color: #d4d8de;
}
.formio-form .formio-component.formio-component-columns .formio-component-collectionDateLabel {
  text-align: end;
}
.formio-form .formio-component.formio-component-columns .formio-component-collectionDateLabel > div {
  height: 36px;
  line-height: 36px;
}
.formio-form .formio-component.formio-component-columns.default-pay-form-payments {
  margin-top: 0;
}
.formio-form .formio-component.formio-component-columns.default-pay-form-payments .formio-component-checkbox.radio-info {
  margin-top: 0;
}
.formio-form .formio-component.formio-component-columns.default-pay-form-payments:first-of-type {
  padding-bottom: 10px;
}
a[href*="null"] {
  display: none;
}
#formio-form-wizard .formio-form > div .pagination {
  margin: 0 0 3rem;
  display: flex;
  background: var(--wizard-bar-bg-color);
  box-shadow: -800px 0 0 var(--wizard-bar-bg-color), 800px 0 0 var(--wizard-bar-bg-color);
  border-radius: 0;
  gap: var(--wizard-bar-items-gap, 0px);
}
#formio-form-wizard .formio-form > div .pagination li {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
}
#formio-form-wizard .formio-form > div .pagination li .page-link {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border: none;
  display: block;
  width: 100%;
  text-align: center;
  transition: all 0.3s;
  cursor: pointer;
  margin-inline: 0;
}
#formio-form-wizard .formio-form > div .pagination li .page-link::after {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  border: solid 1px rgba(64, 68, 81, 0.32);
  border-inline-start: none;
  border-bottom: none;
  position: absolute;
  inset-inline-end: 4px;
  top: calc(50% - 10px);
  transform: rotate(45deg);
}
#formio-form-wizard .formio-form > div .pagination li:last-child .page-link::after {
  display: none;
}
#formio-form-wizard .formio-form > div .pagination li.page-item {
  position: relative;
}
#formio-form-wizard .formio-form > div .pagination li.page-item .progress-bar {
  display: block;
  position: absolute;
  width: 0%;
  height: 5px;
  background: #80c49b;
  bottom: 0px;
  z-index: 99;
  inset-inline-start: 0;
  transition-duration: 0.2s;
  border: none;
  padding: 0;
}
#formio-form-wizard .formio-form > div .list-inline {
  display: none;
}
#formio-form-wizard .formio-form > div :where(.wizard-page .guide-form, .guide-form) > div[class^="col-"] {
  display: flex;
  flex-direction: column;
}
#formio-form-wizard .formio-form > div :where(.wizard-page .guide-form, .guide-form) > div[class^="col-"].col-md-3 {
  width: 35%;
  gap: 30px;
}
#formio-form-wizard .formio-form > div :where(.wizard-page .guide-form, .guide-form) > div[class^="col-"].col-md-3 .formio-component-panel + .formio-component-panel {
  margin-top: 0px !important;
}
#formio-form-wizard .formio-form > div :where(.wizard-page .guide-form, .guide-form) > div[class^="col-"].col-md-4 {
  align-self: flex-start !important;
  width: 35%;
  padding-inline-start: 60px;
}
#formio-form-wizard .formio-form > div :where(.wizard-page .guide-form, .guide-form) > div[class^="col-"].col-md-4::before {
  content: "22";
  display: flex;
  background: transparent;
  position: absolute;
  inset-inline-start: 0;
  width: 46px;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-family: var(--fontawesome-font);
  font-weight: 300;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  content: "\F105";
  font-size: 50px;
  color: #d4d8de;
}
#formio-form-wizard .formio-form > div :where(.wizard-page .guide-form, .guide-form) > div[class^="col-"].col-md-4 > .formio-component-panel .card .card-body .formio-component-checkbox ~ .formio-component-checkbox .form-check {
  margin-top: 0;
}
#formio-form-wizard .formio-form > div :where(.wizard-page .guide-form, .guide-form) .formio-component-FeesPanel {
  margin-bottom: 0;
  height: auto;
}
#formio-form-wizard .formio-form > div :where(.wizard-page .guide-form, .guide-form) .formio-component-FeesPanel .card .card-body .formio-component-container {
  border-top: solid 1px #93cdaa;
  margin-top: -1px !important;
  padding-top: 10px;
}
#formio-form-wizard .formio-form > div :where(.wizard-page .guide-form, .guide-form) .formio-component-FeesPanel .card .card-body :where(.formio-component-container, .formio-component-columns) {
  margin-top: 0;
}
#formio-form-wizard .formio-form > div :where(.wizard-page .guide-form, .guide-form) .formio-component-FeesPanel .card .card-body :where(.formio-component-container, .formio-component-columns) .formio-component-htmlelement {
  margin-top: 0;
  color: white;
  display: flex;
  align-items: center;
  padding: 6px 0;
}
#formio-form-wizard .formio-form > div :where(.wizard-page .guide-form, .guide-form) .formio-component-FeesPanel .card .card-body :where(.formio-component-container, .formio-component-columns) .formio-component-htmlelement p {
  color: white;
  font-weight: 500;
  margin: 0;
  display: block;
  width: 100%;
}
#formio-form-wizard .formio-form > div :where(.wizard-page .guide-form, .guide-form) .formio-component-FeesPanel .card .card-body :where(.formio-component-container, .formio-component-columns) .formio-component-htmlelement p span {
  font-size: 15px;
  margin-inline-start: auto;
}
#formio-form-wizard .formio-form > div :where(.wizard-page .guide-form, .guide-form) .formio-component-requiredDocumentsPanel {
  height: auto;
}
#formio-form-wizard .formio-form > div :where(.wizard-page .guide-form, .guide-form) .formio-component-requiredDocumentsPanel > .card {
  background-color: white;
}
#formio-form-wizard .formio-form > div :where(.wizard-page .guide-form, .guide-form) .formio-component-requiredDocumentsPanel > .card > .card-header {
  margin-bottom: 0 !important;
  width: 100%;
}
#formio-form-wizard .formio-form > div :where(.wizard-page .guide-form, .guide-form) .formio-component-requiredDocumentsPanel > .card > .card-header .card-title i {
  margin-inline-end: 0px;
}
#formio-form-wizard .formio-form > div :where(.wizard-page .guide-form, .guide-form) .formio-component-requiredDocumentsPanel > .card > .card-body > .formio-component.formio-component-htmlelement {
  margin: 0;
}
#formio-form-wizard .formio-form > div :where(.wizard-page .guide-form, .guide-form) .formio-component-requiredDocumentsPanel > .card > .card-body > .formio-component.formio-component-htmlelement > p {
  width: 100%;
  border-radius: 0;
  margin: 0!important;
  background: transparent;
  color: #404451;
  font-size: 14px;
  font-weight: 500;
  border: none;
  position: relative;
  line-height: 1.6;
  text-align: start;
  text-transform: capitalize;
  letter-spacing: -0.2pt;
}
#formio-form-wizard .formio-form > div :where(.wizard-page .guide-form, .guide-form) .formio-component-requiredDocumentsPanel > .card > .card-body > .formio-component.formio-component-htmlelement + .formio-component-container {
  margin-top: 1rem;
}
#formio-form-wizard .formio-form > div :where(.wizard-page .guide-form, .guide-form) .formio-component-requiredDocumentsPanel > .card > .card-body > .formio-component.formio-component-container:first-of-type {
  margin-top: 0;
  border-top: 0.1rem solid #dddddd;
}
#formio-form-wizard .formio-form > div :where(.wizard-page .guide-form, .guide-form) .formio-component-requiredDocumentsPanel > .card > .card-body > .formio-component.formio-component-container > div > .formio-component {
  margin-top: 0;
}
#formio-form-wizard .formio-form > div :where(.wizard-page .guide-form, .guide-form) .formio-component-requiredDocumentsPanel > .card > .card-body > .formio-component.formio-component-container > div > .formio-component.formio-component-htmlelement li {
  list-style: none;
  display: block;
  line-height: 2.3;
}
#formio-form-wizard .formio-form > div :where(.wizard-page .guide-form, .guide-form) .formio-component-requiredDocumentsPanel > .card > .card-body > .formio-component.formio-component-container > div > .formio-component.formio-component-htmlelement li > div {
  display: flex;
}
#formio-form-wizard .formio-form > div :where(.wizard-page .guide-form, .guide-form) .formio-component-requiredDocumentsPanel > .card > .card-body > .formio-component.formio-component-container + .formio-component-htmlelement {
  margin-top: 1rem;
}
#formio-form-wizard .formio-form > div :where(.wizard-page .guide-form, .guide-form) .formio-component-requiredDocumentsPanel .requirement-name {
  margin-inline-end: auto;
}
#formio-form-wizard .formio-form > div :where(.wizard-page .guide-form, .guide-form) .formio-component-submitButton {
  margin-top: 1.5rem;
}
#formio-form-wizard .formio-form > div :where(.wizard-page .guide-form, .guide-form) .formio-component.formio-component-panel > .card > .card-body > .formio-component:not(.formio-component-hidden, .ng-hide):first-of-type {
  margin-top: 0;
}
#formio-form-role > .alert.alert-danger,
#formio-form-wizard > .alert.alert-danger {
  margin: 0 !important;
  padding: 24px !important;
  background: white !important;
  border: solid 1px #ff003b !important;
  position: fixed !important;
  z-index: 99;
  bottom: 12px;
  width: 90%;
  max-width: 600px;
  inset-inline-end: 12px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
}
#formio-form-role > .alert.alert-danger::after,
#formio-form-wizard > .alert.alert-danger::after {
  display: none !important;
}
#formio-form-role > .alert.alert-danger p,
#formio-form-wizard > .alert.alert-danger p {
  font-size: 15px;
  font-weight: 500;
  color: #ff003b;
}
#formio-form-role > .alert.alert-danger ul li:hover,
#formio-form-wizard > .alert.alert-danger ul li:hover {
  background: #f3f0f0;
  padding-inline-start: 8px;
  transition-duration: 0.2s;
}
#formio-form-role > .alert.alert-danger ul li:hover span::after,
#formio-form-wizard > .alert.alert-danger ul li:hover span::after {
  content: "»";
  color: #999;
  margin-inline-start: 12px;
}
#formio-form-role > .alert.alert-danger ul li:focus,
#formio-form-wizard > .alert.alert-danger ul li:focus {
  outline: none;
  background-color: #e1e4ea;
}
.contain-lg {
  max-width: var(--page-wrapper-max-width, 1200px);
}
#header .contain-lg {
  --page-wrapper-max-width: var(--page-wrapper-header-width);
}
.user-steps-menu .contain-lg,
.modern-theme-dashboard-menu .contain-lg,
.secondary-menu .contain-lg,
.demo-page-menu .contain-lg,
.demo-mode-heading .contain-lg,
.modern-theme-be-list .contain-lg {
  --page-wrapper-max-width: var(--page-wrapper-menu-width);
}
.several-messages-container {
  --page-wrapper-max-width: var(--page-wrapper-menu-width);
}
.demo-mode-heading .contain-lg {
  --page-wrapper-max-width: calc(95% + 40px);
}
.section-body .contain-lg {
  --page-wrapper-max-width: var(--page-wrapper-content-width, 1200px);
}
.management_bar .contain-lg,
.management_bar + #base .contain-lg {
  --page-wrapper-max-width: var(--page-wrapper-form-width);
}
#content .full-bleed .contain-lg {
  --page-wrapper-max-width: 100%;
}
.wizard-page {
  max-width: var(--page-wrapper-wizard-width);
  margin-inline: auto;
}
.part-b-main-content-row,
.part-b-content {
  max-width: var(--page-wrapper-form-width);
  margin-inline: auto;
}
.part-b-main-content-row .col-lg-12 {
  padding-inline: 0;
}
#base > #content {
  padding-inline: 2.4rem;
}
#g2c-payment-iframe {
  width: 100%;
  height: 640px;
  margin-top: 30px;
  border: none;
  border-top: 60px solid white;
  border-radius: 6px;
}
.modal .modal-content .panel-heading .panel-title,
.modal .modal-content .panel-heading h2 {
  margin: 0;
}
#select-business-entity.in .modal-dialog {
  transform: translate(0, 35vh);
}
#select-business-entity a.button-grey {
  top: -37px !important;
  float: right;
  padding: 11px;
  border-radius: 0 6px 6px 0 !important;
}
#select-business-entity a.button-grey:hover,
#select-business-entity a.button-grey:active {
  text-decoration: none;
}
#select-business-entity .ui-select-container.open + a.button-grey {
  border-radius: 0 6px 0 0 !important;
}
#overlay {
  position: fixed;
  /* Sit on top of the page content */
  display: none;
  /* Hidden by default */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Black background with opacity */
  z-index: 125;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /* Add a pointer on hover */
}
.drawer {
  position: fixed;
  z-index: 123456789;
  top: 0;
  bottom: 0;
  width: 800px;
  transition: all 0.2s;
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: white;
}
.drawer.in-page {
  top: 140px;
  height: calc(100% - 140px);
}
.drawer.closed {
  transition: all 0.2s;
  inset-inline-start: 100%;
}
.drawer:not(.closed) {
  inset-inline-end: 0;
}
.drawer .drawer-header {
  padding-top: 0;
  padding-inline-start: 64px;
  padding-bottom: 24px;
  padding-inline-end: 24px;
  background: white;
  z-index: 1;
  flex: 1;
  flex-grow: 0;
}
.drawer .drawer-header h2 {
  font-weight: 600;
}
.drawer .drawer-header .drawer-closer {
  margin-inline-start: auto;
  display: block;
  font-size: 24px;
  width: 24px;
  height: 24px;
  text-align: center;
  margin-inline-end: 0;
  cursor: pointer;
}
.drawer .drawer-content {
  padding: 0 64px;
  z-index: 1;
  flex: 1;
  flex-grow: 1;
  overflow-y: auto;
}
.drawer-backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #111827;
  top: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  z-index: 123456788;
  opacity: 0.1;
  transition: all 0.2s;
}
.drawer-backdrop.closed {
  opacity: 0;
  inset-inline-start: 100%;
  transition: all 0.2s;
}
.drawer-opener {
  background: white;
  border-radius: 32px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16) !important;
  padding-top: 8px;
  padding-inline-end: 16px;
  padding-bottom: 8px;
  padding-inline-start: 8px;
  transition: all 0.3s ease-in-out;
}
.drawer-opener i {
  height: 24px;
  width: 24px;
  font-size: 12px;
  line-height: 24px;
  background: #edeef1;
  border-radius: 24px;
  text-align: center;
  display: inline-block;
  margin-inline-end: 8px;
  transition: all 0.3s ease-in-out;
}
.drawer-opener span {
  font-size: 14px;
  font-weight: 600;
}
.drawer-opener:hover {
  transition: all 0.3s ease-in-out;
  box-shadow: -10px 5px 30px rgba(162, 162, 162, 0.3) !important;
}
.drawer-opener:hover i {
  transition: all 0.3s ease-in-out;
  font-size: 14px;
}
.drawer-content .extra-history-options .process-box-toggler {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
}
.drawer-content .extra-history-options .process-box-toggler.process-box-toggler-advanced.closed {
  padding: 4px 8px 4px 0;
  height: 35px;
}
.drawer-content .extra-history-options .process-box-toggler .fa-times {
  display: none;
}
.drawer-content .extra-history-options .process-box-toggler span {
  color: var(--color-gray-800);
  line-height: 1.2;
  border-bottom: solid 1px;
}
.drawer-content .extra-history-options .process-box-toggler:not(.closed) .fa-times {
  display: inline-block;
  margin-inline-end: 10px;
}
.drawer-content .extra-history-options .process-box-toggler:not(.closed) .fa-cog {
  display: none;
}
.drawer-content .extra-history-options .process-box-toggler:not(.closed) span {
  display: none;
}
.drawer-content .extra-history-options .restart-process-box {
  margin-top: 0 !important;
  padding: 4px 8px;
  border-radius: 8px;
  background-color: var(--color-gray-75);
}
.drawer-content .extra-history-options .restart-process-box.closed {
  display: none;
}
.history-container .history-item {
  display: flex;
}
.history-container .history-item:first-child .history-item-rail:before {
  top: 8px;
}
.history-container .history-item:last-child .history-item-rail:before {
  height: 8px;
}
.history-container .history-item.closed .history-alert {
  display: none;
}
.history-container .history-item.clickable {
  cursor: pointer;
}
.history-container .history-item .history-item-rail {
  width: 24px;
  text-align: center;
  flex-grow: 0;
  position: relative;
  margin-inline-end: 12px;
}
.history-container .history-item .history-item-rail i {
  color: var(--color-gray-600);
  z-index: 2;
  position: relative;
}
.history-container .history-item .history-item-rail i.text-warning {
  font-size: 20px;
}
.history-container .history-item .history-item-rail:before {
  content: " ";
  position: absolute;
  inset-inline-start: 50%;
  width: 1px;
  background: var(--color-gray-300);
  top: 0;
  bottom: 0;
  z-index: 1;
}
.history-container .history-item .history-item-content {
  flex-grow: 1;
  margin-bottom: 24px;
}
.history-container .history-item .history-item-content small {
  display: flex;
  gap: 5px;
  color: #6b7280;
  margin-bottom: 8px;
}
.history-container .history-item .history-item-content .status-badge {
  display: inline-block;
}
.history-container .history-item .history-item-content .history-info {
  padding: 3px 8px;
  margin-inline-start: 8px;
}
.corrections-list {
  padding-inline-start: 16px !important;
}
.corrections-list:empty {
  display: none;
}
.history-alert {
  margin-top: 12px;
  padding: 24px;
  border-radius: 12px;
  background-color: #FFF0EB;
}
.history-alert:is(.status-default, .status-unavailable) {
  color: var(--main-text-color);
  background-color: #E6E6EA !important;
}
.history-alert:is(.status-info, .status-pending, .status-filepending) {
  color: #1D86C8;
  background-color: #E4F2FB !important;
}
.history-alert:is(.status-success, .status-processed, .status-filevalidated) {
  color: #10B981;
  background-color: #E8F7F1;
}
.history-alert:is(.status-warning, .status-correction, .status-filedecline) {
  color: #FF7233;
  background-color: #FFF0EB !important;
}
.history-alert:is(.status-error, .status-rejected, .status-filereject, .status-danger) {
  color: #F53D3D;
  background-color: #FEE7E7 !important;
}
.message-container {
  border: 1px solid #edeef1;
  padding: 24px;
  padding-inline-end: 48px;
  border-radius: 12px;
  position: relative;
}
.message-container:first-child {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-top: 1px solid #edeef1;
}
.message-container:last-child {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-bottom: 1px solid #edeef1;
}
.message-container h4 {
  margin-bottom: 4px;
  margin-top: 0;
}
.message-container .message-content {
  margin-top: 8px;
  margin-bottom: 12px;
}
.message-container .message-tags {
  display: flex;
  gap: 5px;
  color: #6b7280;
  margin-bottom: 0;
}
.message-container .message-toggler {
  position: absolute;
  cursor: pointer;
  right: 12px;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  font-size: 16px;
  top: calc(50% - 12px);
  border-radius: 32px;
  background: white;
  transition: all 0.3s ease-in-out;
}
.message-container .message-toggler:hover {
  transition: all 0.3s ease-in-out;
  font-size: 16px;
  background: #edeef1;
}
.homepage {
  @import url('/assets/fonts/opensans/open-sans.css');
}
.homepage .container {
  max-width: 960px;
}
.homepage .jumbotron {
  min-height: 468px;
  background-size: cover;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  padding: 14rem 2rem;
}
.homepage .home-description {
  margin-top: -88px;
}
.homepage .home-description .card {
  background-color: rgba(255, 255, 255, 0.9);
  border: none;
  border-radius: 0;
  box-shadow: none;
  height: 100%;
}
.homepage .home-description .card .card-title p {
  margin: 0;
  font-family: 'Open Sans', serif;
}
.homepage .ptms-node {
  position: relative;
  border: 1px dotted #ffc107;
  display: inline-block;
  width: auto;
  padding: 4px;
  border-radius: 4px;
}
.homepage .ptms-info {
  position: absolute;
  z-index: 24000;
  top: -10px;
  inset-inline-end: -10px;
  font-size: 10px;
  color: white;
}
.homepage .jumbotron * {
  color: white;
}
.homepage .jumbotron h1 {
  font-family: 'Open Sans', serif;
  font-weight: 700;
  font-size: 4rem;
}
.homepage .jumbotron p {
  margin: 0;
}
.homepage .page-pushes {
  margin-top: -11rem;
}
.homepage .page-pushes .push-card {
  background: white;
  border-inline-end: 1px solid rgba(90, 186, 205, 0.5);
}
.homepage .page-pushes .push-card:last-child {
  border-inline-end: none;
}
.homepage .page-pushes .card-title p {
  margin: 0;
  font-family: 'Open Sans', serif;
  font-size: 2rem;
}
.homepage .page-pushes ul {
  list-style: none;
  padding: 0;
}
.homepage .page-pushes ul li {
  padding-inline-start: 1.8em;
  margin-bottom: 12px;
}
.homepage .page-pushes ul li:before {
  content: "\f00c";
  font-family: 'Font Awesome\ 5 Pro';
  font-size: 1.5rem;
  color: #5abacd;
  display: inline-block;
  margin-inline-start: -1.8em;
  width: 1.8em;
  vertical-align: top;
  font-weight: bold;
}
.homepage .card {
  border: none;
  text-align: center;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
}
.homepage .card .card-body .card-text {
  font-size: 1.6rem;
}
.homepage .card .card-body:after {
  display: none !important;
}
.homepage .card img {
  height: 60px;
}
.homepage .btn-primary {
  color: white;
  background: linear-gradient(90deg, #79bc83 0, #5abacd);
  border: none;
  padding: 0.5rem 1.5rem;
  font-size: 2rem;
  line-height: 1.5;
  border-radius: 0.5rem;
}
@media (max-width: 768px) {
  .homepage .jumbotron {
    padding: 7rem 1rem;
  }
  .homepage .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.management_bar {
  padding-top: 60px;
  margin-inline-start: 2.4rem;
  margin-inline-end: 2.4rem;
}
.management_bar + #base {
  border-top: none;
}
.management_bar + #base #content .section-body:first-child {
  padding-top: 0;
  margin-top: 0;
  margin: 0;
}
.management_bar ul li.dropdown {
  margin-inline-start: auto;
  width: auto;
  text-decoration: none;
  /* display: inline-flex; */
}
.management_bar ul li.dropdown > a {
  align-items: center;
  padding: 6px 16px;
  border-radius: 24px;
  background: #fff;
  color: #333;
  font-weight: 300;
  text-align: center;
  margin-top: 9px;
  border: 1px solid #333;
  transition: all 0.3s;
}
.management_bar ul li.dropdown > a:hover {
  transition: all 0.3s;
  background: #333;
  color: white;
}
.management_bar ul li.dropdown > a .left-border-angle {
  padding: 0 0 0 10px;
  margin-inline-start: 10px;
  border-inline-start: solid 1px #333;
  line-height: 100%;
}
.management_bar ul li.dropdown .dropdown-menu {
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.13);
  width: 220px;
  border: none;
  padding: 0;
  border-radius: 0;
  max-height: 270px;
  overflow: auto;
}
.management_bar ul li.dropdown .dropdown-menu a {
  padding: 1.5rem 2rem;
  display: block;
  line-height: 1;
  font-size: 15px;
}
.management_bar ul li.dropdown .dropdown-menu a:hover {
  text-decoration: none;
  background-color: rgba(50, 50, 50, 0.025);
}
.management_bar ul li.dropdown .dropdown-menu a .menu-item-icon {
  margin-inline-end: 5px;
}
.management_bar ul li.dropdown .dropdown-menu a.bottom-sticky {
  background: #FFF;
  border-top: solid 1px #EFEFEF;
}
.management_bar ul li.dropdown.open > a {
  border-color: transparent;
  box-shadow: 0 0 1px rgba(50, 50, 50, 0.2);
  border-radius: 16px 16px 0 0;
  background: #efefef;
  color: #333;
}
.management_bar ul li.dropdown.open > .dropdown-menu {
  top: 40px;
  inset-inline-end: 0;
  inset-inline-start: auto;
}
.management_bar ul li > a {
  padding: 1.4rem 2rem;
  font-weight: 500;
  color: #999999;
  line-height: 1;
  font-size: 15px;
  border-radius: 0;
  border-top: solid 2px transparent;
  border-bottom: solid 2px transparent;
}
.management_bar ul li > a sup {
  color: #999999;
}
.management_bar ul li > a sup.red-badge-notif {
  background-color: #ED5858;
  color: white !important;
  padding: 1px 4px 0;
  border-radius: 20px;
}
.management_bar ul li > a:hover,
.management_bar ul li > a.active {
  color: #222222;
  border-bottom-color: #72adca;
}
.management_bar ul li.nav-item:not(.dropdown) > a {
  font-size: 20px;
  border-bottom: none;
}
.management_bar ul li.nav-item:not(.dropdown) > a.active {
  background: #3b8fb9;
  color: white;
  border-radius: 5px 5px 0 0;
}
.management_bar ul li.nav-item:not(.dropdown) > a.active sup {
  color: white;
  opacity: 0.5;
}
.management_bar ul li.nav-item:not(.dropdown) > a.active sup.red-badge-notif {
  background-color: white;
  color: #3b8fb9 !important;
}
.dashboard table > thead > tr > th {
  color: #222222;
  font-size: 12px;
  letter-spacing: 0;
}
.dashboard table.table-stripped tbody tr:nth-child(odd) {
  background: #FAFAFA;
}
.dashboard table.table-stripped tbody tr td.file-state_to-coreect::before {
  border-top: 26px solid transparent;
  border-bottom: 26px solid transparent;
  border-inline-start: 26px solid #FFAF89;
}
.dashboard .see-more {
  color: #4393bb;
  font-weight: 700;
  padding: 2rem 3.5rem;
  display: inline-block;
  line-height: 1;
}
.dashboard .big-title {
  font-weight: 700;
  color: #444444;
  font-size: 22px;
}
.dashboard .dashboard-tiles {
  padding-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
}
.dashboard .dashboard-tiles > a {
  background: #f5f6f8;
  padding: 2rem 3rem;
  transition-duration: 0.2s;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.dashboard .dashboard-tiles > a .service-name-container {
  position: relative;
  transition: all 0.3s;
  padding-inline-end: 1rem;
}
.dashboard .dashboard-tiles > a:hover {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  text-decoration: none;
}
.dashboard .dashboard-tiles > a .service-description {
  line-height: 1.4;
  text-align: start;
}
.dashboard .dashboard-tiles > a .service-label {
  display: block;
  font-size: 15px;
  color: #AAA;
  line-height: 1;
  margin-bottom: 5px;
}
.dashboard .dashboard-tiles > a .service-name {
  font-size: 18px;
  line-height: 1.4;
  font-weight: 700;
  color: #333333;
  display: inline-block;
  margin-bottom: 6px;
}
.dashboard .dashboard-tiles > a .service-icon {
  margin-inline-start: auto;
  color: #3b8fb9;
  font-size: 4rem;
  line-height: 1;
}
.dashboard .all-services-filters {
  display: none;
}
.dashboard .all-services-filters.show-filters {
  display: flex;
}
.dashboard .service-category {
  padding: 0 1em;
  display: inline-block;
}
.dashboard .service-category.active {
  background-color: #999;
  border-radius: 2em;
  color: #fff;
}
.dashboard ul.service-categories {
  display: flex;
  align-items: stretch;
  /* Default */
  justify-content: flex-start;
  width: 100%;
  margin: 0;
  padding: 0;
}
.dashboard ul.service-categories li {
  display: block;
  flex: 0 1 auto;
  margin-inline-end: 0.5em;
  list-style-type: none;
  line-height: 11px;
  font-size: 11px;
  font-weight: 400;
}
.dashboard .service-visibility-switch {
  padding: 0.33em 1em 0.22em;
  background-color: #f5f6f8;
  color: #AAA;
}
.dashboard .service-visibility-switch:hover,
.dashboard .service-visibility-switch.active {
  background-color: #999;
  color: #fff;
}
.dashboard .services-title-search-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1em;
  margin-bottom: 2em;
  margin-inline: 0;
}
.dashboard .services-title-search-container .services-search-icon {
  margin-inline-end: -27px;
  z-index: 99;
  margin-inline-start: 10px;
  color: #222222;
}
.dashboard .services-title-search-container .form-control {
  opacity: 0.3;
  transition-duration: 0.2s;
  height: 32px;
  padding-inline-start: 34px;
}
.dashboard .services-title-search-container .form-control:focus,
.dashboard .services-title-search-container .form-control:hover {
  opacity: 1;
  transition-duration: 0.2s;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
}
.dashboard .services-title-search-container .legend-info-icon {
  opacity: 0.7;
  transition-duration: 0.2s;
}
.dashboard .services-title-search-container .legend-info-icon:hover {
  opacity: 1;
}
.dashboard .services-title-search-container * {
  display: flex;
  width: auto;
  flex: 0 1 auto;
  margin: 0;
  margin-inline-end: 1em;
}
.dashboard .services-title-search-container *.form-control {
  border: solid 1px transparent;
  border-radius: 16px;
  background: rgba(229, 230, 230, 0.25);
}
.dashboard #applications-list,
.dashboard #tab-documents,
.dashboard #tab-messages {
  padding: 2rem;
  border: solid 1px #3b8fb9;
}
.dashboard #tab-documents .badge-valid {
  background-color: #8AD8B2;
}
.dashboard #tab-documents .badge-certified {
  background-color: #92C8DC;
}
.dashboard #applications-list ul.warning-list {
  margin-top: -21px;
}
.dashboard #applications-list .legend-info-icon {
  opacity: 0.7;
  transition-duration: 0.2s;
}
.dashboard #applications-list .legend-info-icon:hover {
  opacity: 1;
}
.dashboard #applications-list .table-paging-container {
  overflow: hidden;
}
.dashboard .d-table {
  display: table;
}
.dashboard .d-table-cell {
  display: table-cell;
}
.dashboard .h-100 {
  height: 100%;
}
.dashboard .v-align-middle {
  vertical-align: middle;
}
.dashboard .mb-6 {
  margin-bottom: 6rem;
}
.dashboard .w-30 {
  width: 30%;
}
.dashboard .w-22 {
  width: 22%;
}
.dashboard table thead.heading-with-filters tr th {
  text-align: start;
  vertical-align: top !important;
}
.dashboard table thead.heading-with-filters tr th input {
  display: block;
  width: 100%;
  border-radius: 2px;
  margin-top: 10px;
  border: solid 1px transparent;
  border-radius: 12px;
  padding: 4px 8px;
  transition: all 0.3s;
  font-weight: 400;
}
.dashboard table thead.heading-with-filters tr th input:where(:hover, :focus) {
  background-color: rgba(229, 230, 230, 0.5);
  border-color: rgba(229, 230, 230, 0.75);
  outline: none;
  transition: all 0.3s;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.messages-container {
  padding: 20px;
  background-color: #ddeeeb;
}
.messages-container .alert-callout {
  max-width: 1200px;
  margin-left: auto !important;
  margin-right: auto !important;
  padding-left: 2rem !important;
}
.messages-container .alert-callout::before {
  width: 6px;
  background: #389F8F;
  border-radius: 4px;
}
.messages-container .alert-callout::after {
  display: none !important;
}
/* Documents Tab revamp / redesign */
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base {
  background-color: white;
  border-top: none;
  overflow: visible;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) h2.big-title {
  font-weight: 700;
  color: #000000;
  display: flex;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) h2.big-title .docs-count {
  margin-inline-start: 6px;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) h2.big-title .add-document-from-list {
  margin-inline-start: auto;
  display: inline-block;
  background-color: #3F8EB9;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  padding: 10px 15px;
  border-radius: 2px;
  cursor: pointer;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) h2.big-title .add-document-from-list:hover {
  box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.1);
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table thead tr th {
  padding: 1.5rem 2.5rem;
  color: #000000;
  vertical-align: middle;
  border: none;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table thead tr th.date-col {
  width: 100px;
  padding: 1.5rem;
  text-align: center;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table thead tr th:hover {
  background-color: white;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table thead tr th.opt-col {
  width: 97px;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table thead tr th.msg-subj {
  padding-inline-start: 5.8rem;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table thead tr th.status-col {
  width: 256px;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table tbody tr td {
  border: solid 2px #ffffff;
  background-color: #F5F6F8;
  padding: 1.5rem 2.5rem;
  color: #313335;
  vertical-align: middle;
  position: relative;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table tbody tr td:not(.app-options) a {
  display: block;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table tbody tr td.no-border {
  border-inline-start: none;
  border-inline-end: none;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table tbody tr td.doc-status {
  border-inline-start: none;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table tbody tr td.doc-status.valid {
  box-shadow: inset 5px 0 0 #8AD8B2;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table tbody tr td.doc-status.expiring {
  box-shadow: inset 5px 0 0 #FFB99A;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table tbody tr td.doc-status.expired {
  box-shadow: inset 5px 0 0 #F88B8B;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table tbody tr td.date-col {
  width: 100px;
  padding: 1.5rem;
  text-align: center;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table tbody tr td.status-col {
  width: 256px;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table tbody tr td.msg-status {
  border-inline-start: none;
  box-shadow: inset 5px 0 0 #418DB9;
  font-weight: 500;
  padding-inline-start: 2.5rem;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table tbody tr td.msg-status i {
  margin-inline-end: 1.5rem;
  background-color: white;
  padding: 5px;
  font-size: 10px;
  border-radius: 20px;
  transform: scale(0);
  transition-duration: 0.1s;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table tbody tr td.msg-status.seen {
  box-shadow: inset 5px 0 0 #DCDEE3;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table tbody tr td.app-status {
  border-inline-start: none;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table tbody tr td.app-status.status-warning {
  box-shadow: inset 5px 0 0 #FFAF89;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table tbody tr td.app-status.status-success {
  box-shadow: inset 5px 0 0 #7BC39B;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table tbody tr td.app-status.status-info {
  box-shadow: inset 5px 0 0 #92C8DC;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table tbody tr td.app-status.status-danger {
  box-shadow: inset 5px 0 0 #D56D72;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table tbody tr td.app-status.status-default {
  box-shadow: inset 5px 0 0 #c7ced7;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table tbody tr td.results-col {
  font-size: 24px;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table tbody tr td .document-options {
  background: transparent;
  background-image: none;
  border: none;
  transition-duration: 0.1s;
  outline: none;
  display: inline-flex;
  align-items: center;
  height: 20px;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table tbody tr td .document-options:hover {
  transition-duration: 0.1s;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table tbody tr td .document-options:hover i {
  transform: scale(1.2);
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table tbody tr td .document-options:focus {
  transition-duration: 0.1s;
  background: white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table tbody tr td .document-options:focus ~ .document-options-modal {
  transition-duration: 0.1s;
  transition-delay: 0.1s;
  transition-timing-function: ease-in-out;
  transform: scale(1);
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table tbody tr td .document-options-modal {
  position: absolute;
  transition-delay: 0.3s;
  transition-duration: 0.1s;
  transition-timing-function: ease-in-out;
  transform: scale(0);
  z-index: 10;
  background: white;
  inset-inline-end: 3.5rem;
  top: calc(50% + 9px);
  width: 150px;
  margin: 0;
  padding: 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table tbody tr td .document-options-modal li {
  list-style: none;
  display: grid;
  grid-template-columns: 48px auto;
  align-items: center;
  padding: 1rem 0;
  text-align: start;
  text-transform: capitalize;
  cursor: pointer;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table tbody tr td .document-options-modal li i {
  text-align: center;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table tbody tr td .document-options-modal li:hover {
  background: #fafbfd;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table tbody tr td .document-options-modal li:active {
  background: #edeff1;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table tbody tr td .remove-msg {
  transform: scale(0);
  transition-duration: 0.1s;
  color: #ED5858;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table tbody tr td .badge.blue {
  background-color: #54B3E6;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table tbody tr:hover td {
  background-color: #fafbfd;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table tbody tr:hover td.msg-status i {
  transform: scale(1);
  transition-duration: 0.1s;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table tbody tr:hover td .remove-msg {
  transform: scale(1);
  transition-duration: 0.1s;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table tbody tr.active td {
  border-bottom: solid 2px #3C8FB9;
  color: #3C8FB9;
  background-color: #fafbfd;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table tbody tr.active td .remove-msg {
  transform: scale(1);
  transition-duration: 0.1s;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table tbody tr.active td.msg-status i {
  transform: scale(1);
  transition-duration: 0.1s;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) table.table tbody tr.message-body td {
  box-shadow: inset 5px 0 0 #DCDEE3;
  border: none;
  padding-inline-start: 5.8rem !important;
  background-color: white;
}
:where(.tab-documents-active, .tab-messages-active, .tab-applications-active) + #base .dashboard :where(#tab-documents, #tab-messages, #tab-applications) .table-paging-container {
  background-color: white;
}
.doc-preview-modal {
  position: fixed;
  z-index: 9999;
  top: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}
.doc-preview-modal .doc-preview-modal-grid {
  height: 80vh;
  width: 80%;
  margin: 10vh auto;
  display: grid;
  grid-template-rows: minmax(48px, max-content) auto;
  grid-gap: 20px;
  background-color: white;
  padding: 32px;
}
.doc-preview-modal .doc-preview-modal-grid .doc-preview-modal-header {
  position: relative;
  display: grid;
  align-items: center;
  grid-template-columns: auto auto;
}
.doc-preview-modal .doc-preview-modal-grid .doc-preview-modal-header .doc-preview-modal-title {
  font-size: 19px;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin: 0;
}
.doc-preview-modal .doc-preview-modal-grid .doc-preview-modal-header .doc-preview-modal-title i {
  font-size: 18px;
  margin-inline-end: 10px;
}
.doc-preview-modal .doc-preview-modal-grid .doc-preview-modal-header .doc-preview-modal-title .doc-status-label {
  line-height: 1;
  font-size: 10px;
  text-transform: uppercase;
  background: darkgray;
  color: white;
  padding: 3px 10px;
  border-radius: 10px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-inline-start: 15px;
}
.doc-preview-modal .doc-preview-modal-grid .doc-preview-modal-header .doc-preview-modal-title .doc-status-label span {
  line-height: 1;
}
.doc-preview-modal .doc-preview-modal-grid .doc-preview-modal-header .doc-preview-modal-title .doc-status-label.status-valid {
  background-color: #8AD8B2;
}
.doc-preview-modal .doc-preview-modal-grid .doc-preview-modal-header .doc-preview-modal-title .doc-status-label.status-expiring {
  background-color: #FFB99A;
}
.doc-preview-modal .doc-preview-modal-grid .doc-preview-modal-header .doc-preview-modal-title .doc-status-label.status-expired {
  background-color: #F88B8B;
}
.doc-preview-modal .doc-preview-modal-grid .doc-preview-modal-header .close-modal-btn {
  position: absolute;
  top: -86px;
  inset-inline-end: -30px;
  font-size: 32px;
  color: white;
}
.doc-preview-modal .doc-preview-modal-grid .doc-preview-modal-header .doc-preview-modal-opts {
  display: flex;
  justify-content: flex-end;
}
.doc-preview-modal .doc-preview-modal-grid .doc-preview-modal-header .doc-preview-modal-opts .doc-preview-modal-opt-item {
  padding: 1rem 2rem;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  text-transform: capitalize;
  display: inline-flex;
  align-items: center;
  margin-inline-start: 10px;
}
.doc-preview-modal .doc-preview-modal-grid .doc-preview-modal-header .doc-preview-modal-opts .doc-preview-modal-opt-item:first-child {
  margin-inline-start: 0;
}
.doc-preview-modal .doc-preview-modal-grid .doc-preview-modal-header .doc-preview-modal-opts .doc-preview-modal-opt-item i {
  margin-inline-end: 10px;
}
.doc-preview-modal .doc-preview-modal-grid .doc-preview-modal-header .doc-preview-modal-opts .doc-preview-modal-opt-item.results-paging .results-paging-text span + span {
  text-transform: lowercase;
}
.doc-preview-modal .doc-preview-modal-grid .doc-preview-modal-header .doc-preview-modal-opts .doc-preview-modal-opt-item.results-paging .paging-btn {
  padding: 0 8px;
}
.doc-preview-modal .doc-preview-modal-grid .doc-preview-modal-header .doc-preview-modal-opts .doc-preview-modal-opt-item.results-paging .paging-btn i {
  margin: 0;
}
.doc-preview-modal .doc-preview-modal-grid .doc-preview-modal-header .doc-preview-modal-opts .doc-preview-modal-opt-item.results-paging .paging-btn.previous {
  margin-inline-end: 6px;
}
.doc-preview-modal .doc-preview-modal-grid .doc-preview-modal-header .doc-preview-modal-opts .doc-preview-modal-opt-item.results-paging .paging-btn.next {
  margin-inline-start: 6px;
}
/* Dashboard tooltip */
.dashboard-tooltip-handler {
  z-index: 9;
  position: relative;
  padding-inline-end: 18px;
}
.dashboard-tooltip-handler .heading-label::before {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 3px;
  transform: rotate(45deg);
  background-color: #EDEEF7;
  position: absolute;
  inset-inline-start: 50px;
  transition-delay: 0.6s;
  transition-duration: 0.4s;
  top: 0;
  opacity: 0;
}
.dashboard-tooltip-handler .heading-label::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: var(--fontawesome-font);
  font-weight: 300;
  content: "\F059";
  z-index: 9999;
  margin-inline-start: 8px;
}
.dashboard-tooltip-handler:hover .heading-label::before {
  top: -14px;
  opacity: 1;
  transition-delay: 0.2s;
}
.dashboard-tooltip-handler:hover .dashboard-tooltip {
  opacity: 1;
  bottom: calc(100% + 8px);
  z-index: 1;
  transition-delay: 0.2s;
}
.dashboard-tooltip-handler .dashboard-tooltip {
  position: absolute;
  z-index: -1;
  opacity: 0;
  transition-duration: 0.4s;
  transition-delay: 0.6s;
  bottom: 0;
  inset-inline-end: -2px;
  background-color: #e7e9ed;
  color: #4a6288;
  padding: 12px;
  border-radius: 6px;
  text-transform: none;
  white-space: nowrap;
}
.dashboard-tooltip-handler .dashboard-tooltip::after {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 3px;
  transform: rotate(45deg);
  background-color: #e7e9ed;
  position: absolute;
  inset-inline-end: 8px;
  bottom: -5px;
  opacity: 0;
}
/* My Account */
#my-account-page {
  padding: 2.4rem 2.4rem 0;
  z-index: 1000;
  min-height: initial;
}
#my-account-page .formio-form {
  max-width: var(--page-wrapper-form-width, 1200px);
  margin-inline: auto;
  min-height: initial;
}
#my-account-page .formio-form .formio-wizard-nav-container {
  display: none;
}
#my-account-page > div > div > nav {
  display: none;
}
@media all and (max-width: 768px) {
  .tab-applications-active + #base .dashboard #tab-applications table.table thead tr th,
  .tab-applications-active + #base .dashboard #tab-applications table.table tbody tr th,
  .tab-applications-active + #base .dashboard #tab-applications table.table thead tr td,
  .tab-applications-active + #base .dashboard #tab-applications table.table tbody tr td {
    padding: 1.5rem;
  }
}
@media all and (max-width: 640px) {
  .dashboard .dashboard-tiles {
    grid-template-columns: 1fr;
  }
}
.guide {
  font-size: 13px;
}
.guide p {
  margin: 0;
}
.guide .formio-component-registrationspanel > .panel > .panel-body {
  padding-top: 0 !important;
}
.guide .formio-component-registrationspanel > .panel > .panel-body .panel#formio-panel-columnsPanel2Mandatory {
  margin-top: 0;
}
.guide .form-field-type-columns:not(.formio-component-questionscolumns) .panel-body .form-field-type-mycontent,
.guide .card-body,
.guide blockquote {
  margin-inline-start: -2.5rem !important;
  margin-inline-end: -2.5rem !important;
  padding: 1.5rem 2.5rem !important;
}
.guide .panel {
  border: none;
  border-radius: 0 !important;
  box-shadow: none;
}
.guide .form-field-type-panel {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
  border-radius: 0;
  margin-bottom: 0;
  padding: 0;
}
.guide .form-field-type-panel .form-field-type-panel {
  box-shadow: none;
  padding: 0;
}
.guide .form-field-type-panel .form-field-type-panel .panel-body {
  padding: 0 !important;
}
.guide .form-field-type-panel + .form-field-type-panel {
  margin-top: -1px;
}
.guide .form-field-type-panel .panel.panel-success {
  background-color: #80C49B;
  color: white;
}
.guide .form-field-type-panel .panel.panel-success .panel-heading h3.panel-title {
  font-weight: 500;
  color: white;
}
.guide .form-field-type-panel .panel.panel-success .panel-heading h3.panel-title .formio-collapse-icon {
  color: white !important;
}
.guide .form-field-type-panel .panel.panel-success .panel-heading h3.panel-title::after {
  display: none;
}
.guide .form-field-type-panel .panel.panel-success .panel-body .form-field-type-columns {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}
.guide .form-field-type-panel .panel.panel-success .panel-body .form-field-type-columns .form-group {
  margin: 0.5rem 0;
}
.guide .form-field-type-panel .panel.panel-success .panel-body .form-field-type-columns + .form-field-type-columns {
  margin-top: -1px !important;
}
.guide .form-field-type-panel .panel .panel-heading {
  background-color: transparent;
  border-radius: 0;
  border: none !important;
}
.guide .form-field-type-panel .panel .panel-heading h3.panel-title {
  color: #313335;
  font-size: 20px;
  font-weight: 500;
  border: none;
  position: relative;
  padding: 0;
}
.guide .form-field-type-panel .panel .panel-heading h3.panel-title .formio-collapse-icon {
  position: absolute;
  inset-inline-end: 0;
  border: none;
  top: 24px;
  font-size: 16px;
}
.guide .form-field-type-panel .panel .panel-heading h3.panel-title .formio-collapse-icon.fa-plus:before {
  content: "\f107";
}
.guide .form-field-type-panel .panel .panel-heading h3.panel-title .formio-collapse-icon.fa-minus:before {
  content: "\f106";
}
.guide .form-field-type-panel .panel .panel-body {
  padding: 0 2.5rem;
}
.guide .form-field-type-panel .panel .card-body {
  border-radius: 0 !important;
  border: none !important;
  padding: 0.5rem 0;
  line-height: 1.2;
  margin-top: 0 !important;
  margin-bottom: 3rem !important;
}
.guide .form-field-type-panel .panel .card-body .control-label:not(.form-check-label) {
  font-size: 12px;
  opacity: 0.85;
  font-weight: 700;
  margin-bottom: 0.2rem;
}
.guide .form-field-type-panel .panel .card-body .formio-component .card-body {
  border-inline-start: 2px solid #3C8FB9 !important;
  padding: 0 0.75rem;
  margin-bottom: 1.25rem;
  font-size: 13px;
}
.guide .form-field-type-panel.block-fees .panel .panel-heading h3.panel-title::after {
  display: none;
}
.guide .form-field-type-panel span.card-title {
  display: inline-block;
  margin: 2.5rem 0;
  color: #313335;
  font-size: 20px;
  font-weight: 500;
  border: none;
  position: relative;
  padding: 0;
  vertical-align: middle;
  line-height: 1;
}
.guide .block-fees {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
  border-radius: 0;
  margin-bottom: 0;
  padding: 0;
  color: #ffffff;
  background-color: #80C49B;
  margin-top: 0 !important;
}
.guide .block-fees .formio-component-feeTitleColumns {
  margin: 0 !important;
}
.guide .block-fees .formio-component-feeTitleColumns > .row {
  position: relative;
}
.guide .block-fees .formio-component-feeTitleColumns > .row > div:first-child {
  width: 100% !important;
  z-index: 123;
}
.guide .block-fees .formio-component-feeTitleColumns > .row > div:first-child .form-field-type-button {
  position: relative;
}
.guide .block-fees .formio-component-feeTitleColumns > .row > div:first-child .form-field-type-button:before {
  content: "\F078";
  font-family: 'Font Awesome 5 Pro';
  font-weight: 500;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  color: #ffffff;
  font-size: 1.25rem;
  position: absolute;
  top: calc(50% - 0.4rem);
  inset-inline-end: 1rem;
}
.guide .block-fees .formio-component-feeTitleColumns > .row > div:first-child .form-field-type-button.opened:before {
  content: "\F077";
}
.guide .block-fees .formio-component-feeTitleColumns > .row > div:last-child {
  width: auto !important;
  position: absolute;
  inset-inline-end: 0;
  height: 100%;
  vertical-align: middle;
  margin-inline-end: 2rem;
  font-size: 2.2rem;
  font-weight: 500;
  padding: 2rem;
  line-height: 1.6;
  margin-top: 0;
}
.guide .block-fees .form-field-type-htmlelement .card-body {
  margin-inline-start: 2rem !important;
  margin-inline-end: 2rem !important;
  margin-top: 0 !important;
  color: #ffffff !important;
  background: transparent !important;
}
.guide .block-fees .form-field-type-htmlelement .card-body:after {
  background: #ffffff !important;
}
.guide .block-fees .form-field-type-columns:not(.formio-component-feeTitleColumns) {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  margin-inline-start: 2rem !important;
  margin-inline-end: 2.75rem !important;
  margin-top: 0 !important;
  padding: 0.5rem;
}
.guide .block-fees .form-field-type-columns:not(.formio-component-feeTitleColumns) .form-group {
  margin: 0;
}
.guide .block-fees .form-field-type-columns:not(.formio-component-feeTitleColumns) .form-group p {
  padding-top: 5px;
  line-height: 16px;
  margin-bottom: 5px;
  font-weight: 500;
}
.guide .block-fees .form-field-type-columns:not(.formio-component-feeTitleColumns) .form-group span {
  font-size: 16px;
  font-weight: 500;
}
.guide .block-fees .form-field-type-button {
  margin: 0;
}
.guide .block-fees button {
  width: 100%;
  border-radius: 0;
  margin: 0!important;
  background: transparent;
  font-size: 20px;
  font-weight: 500;
  border: none;
  position: relative;
  padding: 2rem;
  line-height: 1.6;
  text-align: start;
  letter-spacing: -0.2pt;
}
.guide .block-fees .form-group {
  margin-top: 0 !important;
}
.guide .formio-component-columnsPrint {
  margin-top: 1.5rem !important;
}
.guide .block-conditions {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
  border-radius: 0;
  margin-bottom: 0;
  padding: 0;
  color: #313335;
  background-color: #ffffff;
  border-top: 1px solid rgba(154, 167, 183, 0.25);
}
.guide .block-conditions .form-field-type-columns:not(.formio-component-conditionTitleColumns) .form-group {
  margin: 0;
}
.guide .block-conditions .form-field-type-columns:not(.formio-component-conditionTitleColumns) .form-group p {
  padding-top: 5px;
  line-height: 16px;
  margin-bottom: 5px;
  font-weight: 100;
}
.guide .block-conditions .form-field-type-columns:not(.formio-component-conditionTitleColumns) .form-group span {
  font-size: 16px;
  font-weight: 500;
}
.guide .block-conditions .formio-component-ConditionsRows {
  margin-bottom: 0;
  padding: 0 2rem;
}
.guide .block-conditions .formio-component-ConditionsRows .card-body {
  margin: 1rem 0;
  padding: 0;
  margin-top: 0 !important;
}
.guide .block-conditions .formio-component-ConditionsRows .col-sm-4 {
  flex: 2 !important;
  border-top: 0.1rem solid #efefef;
  padding-inline-start: 0;
}
.guide .block-conditions .formio-component-ConditionsRows .col-sm-4 li {
  border-top: none !important;
}
.guide .block-conditions .formio-component-ConditionsRows .col-sm-2 {
  flex: 0 !important;
  border-top: 0.1rem solid #efefef;
  padding-inline-end: 0;
}
.guide .block-conditions .form-field-type-button {
  margin: 0;
}
.guide .block-conditions button {
  width: 100%;
  border-radius: 0;
  margin: 0!important;
  background: transparent;
  color: #313335;
  font-size: 20px;
  font-weight: 500;
  border: none;
  position: relative;
  padding: 2rem;
  line-height: 1.6;
  text-align: start;
  text-transform: capitalize;
  letter-spacing: -0.2pt;
}
.guide .block-conditions button.btn-link {
  font-size: 1.2rem;
  padding: 1rem 0;
  position: relative;
  line-height: 16px;
}
.guide .block-conditions .form-group.form-field-type-columns + .form-group.form-field-type-columns {
  margin: 0 !important;
}
.guide .block-conditions > div > .input-group > .formio-container-component .formio-component-conditionTitleColumns {
  padding: 0;
  margin: 0;
}
.guide .block-conditions > div > .input-group > .formio-container-component .formio-component-conditionTitleColumns > .row {
  position: relative;
}
.guide .block-conditions > div > .input-group > .formio-container-component .formio-component-conditionTitleColumns > .row > div:first-child {
  width: 100% !important;
  z-index: 123;
}
.guide .block-conditions > div > .input-group > .formio-container-component .formio-component-conditionTitleColumns > .row > div:first-child .form-field-type-button {
  position: relative;
}
.guide .block-conditions > div > .input-group > .formio-container-component .formio-component-conditionTitleColumns > .row > div:first-child .form-field-type-button:before {
  content: "\F078";
  font-family: 'Font Awesome 5 Pro';
  font-weight: 500;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  color: #313335;
  font-size: 1.25rem;
  position: absolute;
  top: calc(50% - 0.4rem);
  inset-inline-end: 1rem;
}
.guide .block-conditions > div > .input-group > .formio-container-component .formio-component-conditionTitleColumns > .row > div:first-child .form-field-type-button.opened:before {
  content: "\F077";
}
.guide .block-conditions > div > .input-group > .formio-container-component .formio-component-conditionTitleColumns > .row > div:first-child .form-field-type-button.opened button {
  color: #313335;
}
.guide .block-conditions > div > .input-group > .formio-container-component .formio-component-conditionTitleColumns > .row > div:first-child .form-field-type-button.opened button:after {
  display: block;
}
.guide .block-conditions > div > .input-group > .formio-container-component .formio-component-conditionTitleColumns > .row > div .form-field-type-mycontent {
  position: absolute;
  top: 0;
  inset-inline-end: 4rem;
  margin: 0;
  color: #404451;
  font-size: 1.7rem;
  font-weight: 500;
  height: 100%;
  display: flex;
  align-items: center;
}
.guide .block-conditions > div > .input-group > .formio-container-component .formio-component-conditionTitleColumns > .row > div .formio-component-conditionOpenCloseButton {
  margin-top: 0 !important;
}
.guide .block-conditions > div > .input-group > .formio-container-component .formio-component-conditionTitleColumns + .form-field-type-container {
  padding-bottom: 1rem;
}
.guide .block-conditions > div > .input-group > .formio-container-component .formio-component-conditionTitleColumns + .form-field-type-container .row {
  margin: 0;
}
.guide .block-conditions > div > .input-group > .formio-container-component .formio-component-ConditionsRows {
  margin: 0;
}
.guide .block-conditions > div > .input-group > .formio-container-component .formio-component-ConditionsRows > div > .input-group > .formio-container-component .form-field-type-columns {
  margin: 0;
}
.guide .block-conditions > div > .input-group > .formio-container-component .formio-component-ConditionsRows > div > .input-group > .formio-container-component .form-field-type-columns .form-field-type-button {
  margin-top: 0 !important;
}
.guide .block-conditions > div > .input-group > .formio-container-component .formio-component-ConditionsRows > div > .input-group > .formio-container-component .form-field-type-columns .form-field-type-button button {
  transition: none;
  padding: 0.5rem;
}
.guide .block-conditions > div > .input-group > .formio-container-component .formio-component-ConditionsRows > div > .input-group > .formio-container-component .form-field-type-columns > .row {
  justify-content: space-between;
}
.guide .block-conditions > div > .input-group > .formio-container-component .formio-component-ConditionsRows > div > .input-group > .formio-container-component .form-field-type-columns > .row > [class^="col-"] {
  align-items: center;
  display: flex;
  flex: auto !important;
  width: auto;
}
.guide .block-conditions > div > .input-group > .formio-container-component .formio-component-ConditionsRows > div > .input-group > .formio-container-component .form-field-type-columns > .row > [class^="col-"]:first-of-type {
  width: 100%;
}
.guide .block-requirements {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
  border-radius: 0;
  margin-bottom: 0;
  padding: 0;
  color: #313335;
  background-color: #ffffff;
  margin-top: 0 !important;
}
.guide .block-requirements .formio-component-requirementTitleColumns {
  padding: 0;
  margin: 0 !important;
  /*+.form-field-type-container {
                padding-bottom: 1rem;
            }*/
}
.guide .block-requirements .formio-component-requirementTitleColumns > .row {
  position: relative;
}
.guide .block-requirements .formio-component-requirementTitleColumns > .row > div:first-child {
  width: 100% !important;
  z-index: 123;
}
.guide .block-requirements .formio-component-requirementTitleColumns > .row > div:first-child .form-field-type-button {
  position: relative;
}
.guide .block-requirements .formio-component-requirementTitleColumns > .row > div:first-child .form-field-type-button:before {
  content: "\F078";
  font-family: 'Font Awesome 5 Pro';
  font-weight: 500;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  color: #313335;
  font-size: 1.25rem;
  position: absolute;
  top: calc(50% - 0.4rem);
  inset-inline-end: 1rem;
}
.guide .block-requirements .formio-component-requirementTitleColumns > .row > div:first-child .form-field-type-button.opened:before {
  content: "\F077";
}
.guide .block-requirements .formio-component-requirementTitleColumns > .row > div:first-child .form-field-type-button.opened button {
  color: #313335;
}
.guide .block-requirements .formio-component-requirementTitleColumns > .row > div:first-child .form-field-type-button.opened button:after {
  display: block;
}
.guide .block-requirements .formio-component-requirementTitleColumns > .row .form-field-type-mycontent {
  position: absolute;
  top: 2rem;
  inset-inline-end: 4rem;
  margin: 0;
  color: #313335;
  font-size: 1.7rem;
  font-weight: 500;
}
.guide .block-requirements .form-field-type-columns:not(.formio-component-requirementTitleColumns) {
  margin-inline-start: 2rem !important;
  margin-inline-end: 2.75rem !important;
  padding: 0.5rem;
}
.guide .block-requirements .form-field-type-columns:not(.formio-component-requirementTitleColumns) .form-group {
  margin: 0;
}
.guide .block-requirements .form-field-type-columns:not(.formio-component-requirementTitleColumns) .form-group p {
  padding-top: 5px;
  line-height: 16px;
  margin-bottom: 5px;
  font-weight: 100;
}
.guide .block-requirements .form-field-type-columns:not(.formio-component-requirementTitleColumns) .form-group span {
  font-size: 16px;
  font-weight: 500;
}
.guide .block-requirements .formio-component-requirementsRows {
  margin-bottom: 0;
  margin-top: 0;
  padding: 0 2rem;
}
.guide .block-requirements .formio-component-requirementsRows .card-body {
  margin: 0 -2rem !important;
  padding: 0;
  border-radius: 0;
}
.guide .block-requirements .form-field-type-button {
  margin: 0;
}
.guide .block-requirements button {
  width: 100%;
  border-radius: 0;
  margin: 0!important;
  background: transparent;
  color: #313335;
  font-size: 20px;
  font-weight: 500;
  border: none;
  position: relative;
  padding: 2rem;
  line-height: 1.6;
  text-align: start;
  text-transform: capitalize;
  letter-spacing: -0.2pt;
}
.guide .form-field-type-htmlelement {
  margin: 0;
}
.guide .form-field-type-htmlelement .card-body {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  margin-inline: 0;
  padding: 0 2rem;
  line-height: 1.2;
}
.guide .form-field-type-htmlelement .glyphicon-menu-right {
  position: absolute;
  top: 45%;
  inset-inline-start: 5%;
  color: #d4d8de;
}
.guide .form-field-type-htmlelement .glyphicon-menu-right:before {
  border-style: solid;
  border-width: 0.35em 0.35em 0 0;
  content: '';
  display: inline-block;
  height: 3em;
  inset-inline-start: 0.15em;
  position: relative;
  top: 0.15em;
  -webkit-transform: rotate(-45deg);
  transform: rotate(45deg);
  vertical-align: top;
  width: 3em;
}
.guide li {
  list-style: none;
  padding: 1rem 0;
  position: relative;
  border-top: 0.1rem solid #efefef;
  line-height: 1.6rem;
}
.guide li > div {
  display: inline-block;
}
.guide li > div.ui-select-choices-row {
  display: block;
}
.guide li > div a {
  width: auto !important;
  display: inline-block !important;
}
.guide .ui-select-container .dropdown-menu:nth-child(4) {
  /*top: 57px !important;*/
}
.guide #formio-panel-questionspanel .form-field-type-mytooltip a {
  position: absolute;
  top: -0.4rem;
  border: none;
}
.guide #formio-panel-questionspanel .form-field-type-mytooltip a > i:after {
  content: "\F059";
  font-family: 'Font Awesome 5 Pro';
  font-weight: 700;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  color: #9AA7B7;
  font-size: 1.8rem;
}
.guide #formio-panel-questionspanel .form-field-type-mytooltip a:focus {
  background-color: transparent;
}
.guide #formio-panel-questionspanel .form-field-type-mytooltip a:active,
.guide #formio-panel-questionspanel .form-field-type-mytooltip a:hover {
  background-color: transparent;
}
.guide #formio-panel-questionspanel .form-field-type-mytooltip a:active > i:after,
.guide #formio-panel-questionspanel .form-field-type-mytooltip a:hover > i:after {
  color: #313335;
  transition: all 0.2s;
}
.guide .guide-alert-danger {
  margin-top: 3rem;
  border-radius: 2px !important;
  overflow: hidden;
  height: auto !important;
}
.guide .guide-alert-danger > .panel {
  background: #D56D72 !important;
}
.guide .guide-alert-danger > .panel > .panel-heading {
  padding-bottom: 1.25rem !important;
}
.guide .guide-alert-danger > .panel > .panel-heading > h3.panel-title {
  color: #ffffff !important;
}
.guide .guide-alert-danger > .panel > .panel-body {
  padding-bottom: 2.5rem !important;
}
.guide .guide-alert-danger > .panel > .panel-body > .form-group.form-field-type-mycontent {
  margin: 0 !important;
  padding: 0 !important;
  background: transparent !important;
  color: #ffffff !important;
  font-size: 1.5rem !important;
}
.guide .guide-alert-danger > .panel > .panel-body > .form-group.form-field-type-mycontent:after {
  display: none !important;
}
.guide .formio-component-btnValidateEnabled:not(.formio-component-requirementOpenCloseButton),
.guide .formio-component-columnsValidateandcontinue {
  margin-top: 1.5rem !important;
}
.guide .formio-component-btnValidateEnabled:not(.formio-component-requirementOpenCloseButton).margin-top-zero,
.guide .formio-component-columnsValidateandcontinue.margin-top-zero {
  margin-top: 0 !important;
}
.guide .formio-component-requirementOpenCloseButton button {
  box-shadow: none !important;
}
.guide .form-group.form-field-type-radio {
  /*margin-bottom: -9px !important;*/
}
.guide > .form-group,
.guide form > .form-group {
  margin: 0;
}
.guide-with-cart .guide .formio-form .form-group.form-field-type-panel .form-field-type-panel .panel-body {
  padding: 2.7rem 2.5rem 2.5rem !important;
}
@media (max-width: 768px) {
  .col-sm-11 + .col-sm-1 {
    position: absolute;
    inset-inline-end: 0.5rem;
    top: 0;
    margin: 0 !important;
  }
  .col-sm-11 + .col-sm-1 .form-field-type-mytooltip a {
    top: 0 !important;
  }
  .guide .form-field-type-panel .panel .panel-body {
    padding: 0 2rem 2rem;
  }
}
.demo-mode-heading {
  display: block;
  padding: 2.4rem 2.4rem;
  background-color: #313335;
  color: #ffffff;
}
.demo-mode-heading + .section-body {
  margin-top: 2.4rem;
}
.demo-mode-heading .row {
  display: block;
}
.demo-mode-heading .row > div {
  flex: none;
}
.demo-mode-heading .row > div h4 {
  margin-top: 0;
  font-size: 2rem;
}
/* Theme vars */
/* Font */
@font-face {
  font-family: 'Open Sans';
  font-weight: 800;
  src: local('Open Sans ExtraBold'), url('/assets/fonts/opensans/opensans-extrabold-webfont.woff2') format('woff2'), url('/assets/fonts/opensans/opensans-extrabold-webfont.woff') format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 700;
  src: local('Open Sans Bold'), url('/assets/fonts/opensans/opensans-bold-webfont.woff2') format('woff2'), url('/assets/fonts/opensans/opensans-bold-webfont.woff') format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  src: local('Open Sans SemiBold'), url('/assets/fonts/opensans/opensans-semibold-webfont.woff2') format('woff2'), url('/assets/fonts/opensans/opensans-semibold-webfont.woff') format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 500;
  src: local('Open Sans Medium'), url('/assets/fonts/opensans/opensans-medium-webfont.woff2') format('woff2'), url('/assets/fonts/opensans/opensans-medium-webfont.woff') format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  src: local('Open Sans'), url('/assets/fonts/opensans/opensans-regular-webfont.woff2') format('woff2'), url('/assets/fonts/opensans/opensans-regular-webfont.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/inter/inter.woff2') format('woff2');
}
@font-face {
  font-family: 'Noto Sans Arabic';
  font-style: normal;
  font-weight: 100 900;
  font-stretch: 100%;
  font-display: swap;
  src: url('/assets/fonts/noto-sans/nwpCtLGrOAZMl5nJ_wfgRg3DrWFZWsnVBJ_sS6tlqHHFlj4wv4rqwTLJljE.woff2') format('woff2');
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0897-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC, U+102E0-102FB, U+10E60-10E7E, U+10EC2-10EC4, U+10EFC-10EFF, U+1EE00-1EE03, U+1EE05-1EE1F, U+1EE21-1EE22, U+1EE24, U+1EE27, U+1EE29-1EE32, U+1EE34-1EE37, U+1EE39, U+1EE3B, U+1EE42, U+1EE47, U+1EE49, U+1EE4B, U+1EE4D-1EE4F, U+1EE51-1EE52, U+1EE54, U+1EE57, U+1EE59, U+1EE5B, U+1EE5D, U+1EE5F, U+1EE61-1EE62, U+1EE64, U+1EE67-1EE6A, U+1EE6C-1EE72, U+1EE74-1EE77, U+1EE79-1EE7C, U+1EE7E, U+1EE80-1EE89, U+1EE8B-1EE9B, U+1EEA1-1EEA3, U+1EEA5-1EEA9, U+1EEAB-1EEBB, U+1EEF0-1EEF1, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* ANCHOR Colors */
/* Common colors */
/* file history points */
/* Text */
/* Notice colors */
/* Formio 3 Notifications */
/* Fields feedback */
/* Document status */
/* Theme colors*/
/* Blue color */
/* Dark blue color */
/* Gray color */
/* Violet color */
/* Theme color vars */
.violet-color {
  color: #4651aa;
}
.green-color {
  color: #389f8f;
}
.pink-color {
  color: #935074;
}
/* ANCHOR Theme partials */
:root {
  --service-bar-height: 64px;
  --menu-bar-bg-color: var(--color-white);
  --menu-bar-text-color: var(--color-gray-800);
  --menu-bar-text-weight: 600;
  --menu-bar-text-size: 18px;
  --menu-bar-be-text-color: var(--color-gray-500);
  --menu-bar-be-bg-color: var(--color-gray-50);
}
body.modern-theme {
  /* New Formio */
}
body.modern-theme :is(.modern-theme-dashboard-menu, .demo-page-menu, .affix-menu-size-container) {
  background-color: var(--menu-bar-bg-color);
  border-block: solid 1px var(--color-gray-200);
}
body.modern-theme .demo-page-menu {
  margin-inline: -2.4rem;
}
body.modern-theme .affix-menu-size-container {
  min-height: auto;
  height: auto;
}
body.modern-theme .current-service-text {
  color: var(--menu-bar-text-color);
  font-size: var(--menu-bar-text-size);
  font-weight: var(--menu-bar-text-weight);
  min-width: 100px;
  height: 100%;
  display: inline-flex;
  padding-inline-end: 1.5rem;
}
@media screen and (max-width: 480px) {
  body.modern-theme .current-service-text {
    line-height: 1;
    font-size: 12px;
  }
}
body.modern-theme .home-button {
  color: inherit;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 9px;
  margin-block: 0;
  margin-inline-start: -9px;
  margin-inline-end: 6px;
}
body.modern-theme .home-button:is(:hover, :active) {
  background-color: var(--color-gray-100);
  color: var(--color-gray-800);
  text-decoration: none;
}
body.modern-theme .be-title {
  font-size: 70%;
  color: var(--menu-bar-be-text-color);
  background-color: var(--menu-bar-be-bg-color);
  padding: 0 10px;
  margin-inline-start: 6px;
  border-radius: 6px;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu {
  background-color: transparent;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper {
  position: relative;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper .current-service-container {
  height: var(--service-bar-height);
}
body.modern-theme.formio-new #base #content .demo-page-menu .contain-lg .current-service-container .current-service-text .demo-heading-title {
  display: none;
}
body.modern-theme.formio-new #base #content .affix-menu-size-container.demo-modern {
  display: none;
}
body.modern-theme.formio-new #base #content .affix-menu-size-container.affix ~ .section-body .contain-lg #formio-form-wizard > .alert.alert-danger {
  top: 76px;
}
body.modern-theme.formio-new #base #content .affix-menu-size-container.affix ~ .section-body .contain-lg #formio-form-wizard > .alert.alert-danger ul {
  max-height: min(calc(100vh - 120px), 320px);
}
body.modern-theme ul {
  padding-inline-start: 0;
}
body.modern-theme .current-service-container {
  display: flex;
  justify-content: space-between;
  height: var(--service-bar-height);
}
body.modern-theme .current-service-container .current-service-text .fa-chevron-right {
  font-size: 10px;
  margin-inline-end: 12px;
}
body.modern-theme .current-service-container .current-service-text span {
  font-size: var(--menu-bar-page-name-size);
}
body.modern-theme .current-service-container .service-bar-user-lang-menu {
  margin-inline-start: auto;
  margin-inline-end: -1.5rem;
}
body.modern-theme .current-service-container .service-bar-user-lang-menu .headerbar-right {
  height: var(--service-bar-height);
  flex-direction: row-reverse;
}
body.modern-theme .current-service-container .service-bar-user-lang-menu .headerbar-right ul#language_selector_container > li.dropdown-user a.dropdown-toggle {
  padding: 0 1.5rem;
  height: var(--service-bar-height);
  display: flex;
  align-items: center;
  gap: 6px;
  line-height: 1;
}
body.modern-theme .current-service-container .service-bar-user-lang-menu .headerbar-right ul#language_selector_container > li.dropdown-user a.dropdown-toggle i {
  font-size: 16px;
  margin-inline-start: 0.8rem;
}
body.modern-theme .current-service-container .service-bar-user-lang-menu .headerbar-right ul#language_selector_container > li.dropdown-user.open ul.dropdown-menu {
  padding: 0;
  inset-inline-end: 0;
  inset-inline-start: initial;
  border-radius: 0 6px 6px 6px;
  min-width: 100%;
}
body.modern-theme .current-service-container .service-bar-user-lang-menu .headerbar-right ul#language_selector_container > li.dropdown-user.open ul.dropdown-menu li:first-child a {
  border-radius: 0 6px 0 0;
}
body.modern-theme .current-service-container .service-bar-user-lang-menu .headerbar-right ul#language_selector_container > li.dropdown-user.open ul.dropdown-menu li:last-child a {
  border-radius: 0 0 6px 6px;
}
@media screen and (max-width: 768px) {
  body.modern-theme .current-service-container .service-bar-user-lang-menu .headerbar-right ul#language_selector_container > li.dropdown-user {
    padding: 0;
  }
}
body.modern-theme .current-service-container .service-bar-user-lang-menu .headerbar-right ul#connect {
  height: 100%;
}
body.modern-theme .current-service-container .service-bar-user-lang-menu .headerbar-right ul#connect li.dropdown.user {
  height: 100%;
}
body.modern-theme .current-service-container .service-bar-user-lang-menu .headerbar-right ul#connect li.dropdown.user a {
  text-transform: none;
  font-weight: 600;
}
body.modern-theme .current-service-container .service-bar-user-lang-menu .headerbar-right ul#connect li.dropdown.user a.dropdown-toggle.left-part {
  margin: 0;
  border: none;
  padding-inline-start: 1.5rem;
  padding-inline-end: 0;
  border-radius: 0;
}
body.modern-theme .current-service-container .service-bar-user-lang-menu .headerbar-right ul#connect li.dropdown.user a.dropdown-toggle.left-part i.user-icon {
  display: none;
}
body.modern-theme .current-service-container .service-bar-user-lang-menu .headerbar-right ul#connect li.dropdown.user a.dropdown-toggle.left-part span.user-name-text {
  padding: 0;
}
body.modern-theme .current-service-container .service-bar-user-lang-menu .headerbar-right ul#connect li.dropdown.user a.dropdown-toggle.left-part span.user-name-text .user-initials {
  display: none;
}
@media screen and (max-width: 768px) {
  body.modern-theme .current-service-container .service-bar-user-lang-menu .headerbar-right ul#connect li.dropdown.user a.dropdown-toggle.left-part span.user-name-text {
    display: block;
  }
  body.modern-theme .current-service-container .service-bar-user-lang-menu .headerbar-right ul#connect li.dropdown.user a.dropdown-toggle.left-part span.user-name-text .user-initials {
    display: block;
  }
  body.modern-theme .current-service-container .service-bar-user-lang-menu .headerbar-right ul#connect li.dropdown.user a.dropdown-toggle.left-part span.user-name-text .user-initials span {
    display: inline;
  }
}
body.modern-theme .current-service-container .service-bar-user-lang-menu .headerbar-right ul#connect li.dropdown.user a.dropdown-toggle.left-part:nth-last-child(2) {
  padding-inline-end: 1.5rem;
  cursor: default;
}
body.modern-theme .current-service-container .service-bar-user-lang-menu .headerbar-right ul#connect li.dropdown.user a.dropdown-toggle.user-dropdown-toggle {
  border: none;
  padding-inline-end: 1.3rem;
  padding-inline-start: 1.3rem;
  border-radius: 0;
}
body.modern-theme .current-service-container .service-bar-user-lang-menu .headerbar-right ul#connect li.dropdown.user a.dropdown-toggle.user-dropdown-toggle i.angle-icon {
  margin-inline-end: 0;
  font-weight: 300;
  font-size: 16px;
}
body.modern-theme .current-service-container .service-bar-user-lang-menu .headerbar-right ul#connect li.dropdown.user a.dropdown-toggle.user-dropdown-toggle:last-child {
  display: none;
}
body.modern-theme .current-service-container .service-bar-user-lang-menu .headerbar-right ul#connect li.dropdown.user.open ul.dropdown-menu {
  width: 100%;
  min-width: fit-content;
  padding: 0;
  border-radius: 0 0 6px 6px;
}
body.modern-theme .current-service-container .service-bar-user-lang-menu .headerbar-right ul#connect li.dropdown.user.open ul.dropdown-menu li.divider {
  margin: 0;
  background-color: rgba(70, 81, 170, 0.1);
  height: 1px;
}
body.modern-theme .current-service-container .service-bar-user-lang-menu .headerbar-right ul#connect li.dropdown.user.open ul.dropdown-menu li.divider:first-child {
  display: none;
}
body.modern-theme .current-service-container .service-bar-user-lang-menu .headerbar-right ul#connect li.dropdown.user.open ul.dropdown-menu li a {
  padding: 7px 20px;
}
body.modern-theme .current-service-container .service-bar-user-lang-menu .headerbar-right ul#connect li.dropdown.user.open ul.dropdown-menu li:last-child a {
  border-radius: 0 0 6px 6px !important;
}
body.modern-theme .current-service-container .service-bar-user-lang-menu .headerbar-right ul#connect li:not(.dropdown) {
  height: 100%;
}
body.modern-theme .current-service-container .service-bar-user-lang-menu .headerbar-right ul#connect li:not(.dropdown) a {
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 0 !important;
  margin: 0 !important;
  padding: 0 2rem;
  text-transform: none;
  font-weight: 600;
  box-shadow: none !important;
}
@media screen and (max-width: 768px) {
  body.modern-theme .current-service-container .service-bar-user-lang-menu .headerbar-right {
    min-height: var(--service-bar-height);
  }
}
@media screen and (max-width: 1200px) {
  body.modern-theme .current-service-container .service-bar-user-lang-menu {
    margin-inline-end: 0;
  }
}
/* App status colors */
body.modern-theme .warning-list[hidden] {
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 0, 59, 0.3);
}
body.modern-theme .warning-list[hidden].modern-warning-list {
  background-color: var(--color-gray-100);
  box-shadow: -1200px 0 0 var(--color-gray-100), -2400px 0 0 var(--color-gray-100), 1200px 0 0 var(--color-gray-100), 2400px 0 0 var(--color-gray-100);
  padding: 20px 0;
}
body.modern-theme .warning-list[hidden].modern-warning-list li {
  color: var(--color-primary-600);
  font-weight: 600;
  width: 100%;
  display: flex;
  align-items: center;
}
body.modern-theme .warning-list[hidden].modern-warning-list li.several-msg {
  display: block;
  position: relative;
}
body.modern-theme .warning-list[hidden].modern-warning-list li.several-msg strong {
  margin-inline-start: 6px;
  margin-inline-end: 6px;
  font-weight: 600;
}
body.modern-theme .warning-list[hidden].modern-warning-list li.several-msg strong:hover {
  text-decoration: underline;
}
body.modern-theme .warning-list[hidden].modern-warning-list li.several-msg span.close {
  position: absolute;
  inset-inline-end: 0;
  top: calc(50% - 11px);
}
body.modern-theme .warning-list[hidden].modern-warning-list li:last-of-type {
  margin-bottom: 0 !important;
}
body.modern-theme .warning-list[hidden].modern-warning-list li .close {
  margin-inline-start: auto;
}
@media screen and (max-width: 1200px) {
  body.modern-theme .warning-list[hidden].modern-warning-list {
    padding-inline-start: 2.4rem;
    padding-inline-end: 2.4rem;
  }
}
body.modern-theme .management_bar {
  position: relative;
  z-index: 999;
}
body.modern-theme .management_bar.white-bg {
  background-color: var(--color-gray-75);
}
body.modern-theme .management_bar .contain-lg ul.nav li.nav-item a.nav-link {
  font-size: 20px;
  color: var(--color-gray-400);
  font-weight: 600;
  padding-inline-start: 24px;
  padding-inline-end: 24px;
  padding-block: 21px;
  border-top: none;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
body.modern-theme .management_bar .contain-lg ul.nav li.nav-item a.nav-link.active {
  background-color: white;
  box-shadow: 0px -6px 20px rgba(0, 0, 0, 0.02);
  color: var(--color-gray-800);
}
body.modern-theme .management_bar .contain-lg ul.nav li.nav-item a.nav-link small {
  opacity: 1;
  color: var(--color-gray-400);
  font-size: 16px;
  font-weight: 600;
  margin-inline-start: 4px;
}
@media screen and (max-width: 640px) {
  body.modern-theme .management_bar .contain-lg ul.nav li.nav-item a.nav-link small {
    position: absolute;
    inset-inline-end: 0;
    top: -12px;
    background: #e7e9ed;
    padding: 6px;
    border-radius: 12px;
  }
}
@media screen and (max-width: 840px) {
  body.modern-theme .management_bar .contain-lg ul.nav li.nav-item a.nav-link {
    padding: 1.4rem;
  }
  body.modern-theme .management_bar .contain-lg ul.nav li.nav-item a.nav-link span {
    font-size: 16px;
  }
  body.modern-theme .management_bar .contain-lg ul.nav li.nav-item a.nav-link small {
    font-size: 14px;
  }
}
body.modern-theme .management_bar .contain-lg ul.nav li.nav-item.dropdown {
  display: none;
}
@media screen and (max-width: 380px) {
  body.modern-theme .management_bar {
    margin-inline-start: 1.4rem;
    margin-inline-end: 1.4rem;
  }
}
body.modern-theme .management_bar.document-modal-open ~ #base {
  z-index: 99999;
}
body.modern-theme #header .headerbar .headerbar-right {
  display: none;
}
body.modern-theme #header .headerbar .headerbar-right.modern-theme-headerbar-right[hidden] {
  display: flex;
}
body.modern-theme #header .headerbar .headerbar-right.modern-theme-headerbar-right[hidden] .headerbar-logout {
  display: flex;
  gap: 16px;
  text-decoration: none;
  color: var(--color-primary-600);
  border-radius: 6px;
  transition-duration: 0.1s;
  align-items: center;
}
body.modern-theme #header .headerbar .headerbar-right.modern-theme-headerbar-right[hidden] .headerbar-logout i {
  font-size: 18px;
}
body.modern-theme #header .headerbar .headerbar-right.modern-theme-headerbar-right[hidden] .headerbar-logout span {
  font-size: 13px;
  font-weight: 500;
}
body.modern-theme #header .headerbar .headerbar-right.modern-theme-headerbar-right[hidden] .headerbar-logout:hover {
  background-color: rgba(70, 81, 170, 0.1);
  box-shadow: 0 0 0 10px rgba(70, 81, 170, 0.1);
}
body.modern-theme #base {
  background-color: var(--color-gray-75);
  position: relative;
  z-index: 999;
}
body.modern-theme #base #content .default-theme-demo-heading {
  margin-inline: -2.4rem;
}
body.modern-theme #base #content .default-theme-demo-heading .demo-mode-heading {
  background-color: var(--color-gray-100);
  box-shadow: -1200px 0 0 var(--color-gray-100), -2400px 0 0 var(--color-gray-100), 1200px 0 0 var(--color-gray-100), 2400px 0 0 var(--color-gray-100);
  padding: 20px;
}
body.modern-theme #base #content .default-theme-demo-heading .demo-mode-heading h4 {
  display: none;
}
body.modern-theme #base #content .default-theme-demo-heading .demo-mode-heading span {
  color: var(--color-primary-600);
  font-weight: 600;
}
body.modern-theme #base #content > .section-body .dashboard :where(#applications-list, #tab-documents, #tab-messages) {
  padding: 24px;
  background: white;
  border: none;
  border-radius: 6px;
  box-shadow: 0px 9px 20px rgba(0, 0, 0, 0.02);
}
body.modern-theme #base #content > .section-body .dashboard #applications-list {
  border-radius: 0 6px 6px 6px;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table thead {
  opacity: 1;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table thead tr th {
  padding: 0;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table thead tr th span:not(.sortorder) {
  background-color: var(--color-gray-75);
  text-transform: none;
  color: var(--color-gray-600);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  padding: 10px 20px;
  white-space: nowrap;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table thead tr th span.sortorder {
  top: 10px;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table thead tr th span.sortorder::after {
  content: "\f175";
  color: var(--color-gray-600);
  font-weight: 300;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table thead tr th:first-child span:not(.sortorder) {
  border-radius: 6px 0 0 6px;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table thead tr th.results-col {
  z-index: 9;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table thead tr th.opt-col {
  z-index: 10;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table thead tr th.opt-col::before {
  content: "";
  display: block;
  height: 34px;
  background-color: var(--color-gray-75);
  border-radius: 0 6px 6px 0;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table thead tr th input {
  border: solid 1px var(--fields-border-color, var(--color-gray-300));
  background-color: transparent;
  border-radius: 6px;
  margin-top: 18px;
  height: 34px;
  box-shadow: none;
  margin-inline-end: 20px;
  width: calc(100% - 20px);
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 13px;
  color: var(--color-gray-800);
  padding-inline-start: 20px;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table thead tr th.status-col {
  width: auto;
  position: relative;
  z-index: 4;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table thead tr th.results-col {
  position: relative;
  z-index: 3;
  isolation: isolate;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table thead.responsive-sorting tr td {
  padding: 0 0 10px;
  display: flex;
  align-items: center;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table thead.responsive-sorting tr td .sorting-label {
  background: rgba(70, 81, 170, 0.05);
  color: var(--color-primary-600);
  font-weight: 600;
  padding: 6px 12px;
  border-radius: 6px;
  display: inline-flex;
  white-space: nowrap;
  transition-duration: 0.3s;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table thead.responsive-sorting tr td .sorting-label .sort_by_label ~ span {
  margin-inline-start: 6px;
  font-weight: 600;
  position: relative;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table thead.responsive-sorting tr td .sorting-label .sort_by_label ~ span::after {
  content: "\f354";
  font-family: var(--fontawesome-font);
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-size: 1.1rem;
  margin-inline-start: 6px;
  border: none;
  opacity: 0.8;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table thead.responsive-sorting tr td .sorting-label .sort_by_label ~ span.reverse-ordering::after {
  content: "\f357";
  color: #4a6288;
  font-weight: 900;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table thead.responsive-sorting tr td .sorting-label:hover {
  background: rgba(70, 81, 170, 0.1);
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table thead.responsive-sorting tr td .cells-filter-toggler {
  background: rgba(70, 81, 170, 0.05);
  color: var(--color-primary-600);
  padding: 6px 12px;
  border-radius: 6px;
  transition-duration: 0.3s;
  display: inline-flex;
  height: 37px;
  align-items: center;
  margin-inline-start: 12px;
  cursor: pointer;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table thead.responsive-sorting tr td .cells-filter-toggler:hover {
  background: rgba(70, 81, 170, 0.1);
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table thead.heading-with-filters tr th.status-col {
  width: 20%;
  min-width: 170px;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table thead.heading-with-filters tr th.opt-col .show-hide-filters[hidden] {
  display: block;
  position: absolute;
  inset-inline-end: 15px;
  top: 6px;
  cursor: pointer;
  padding: 6px;
  border-radius: 6px;
  transition-duration: 0.2s;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table thead.heading-with-filters tr th.opt-col .show-hide-filters[hidden]:hover {
  background-color: var(--color-gray-200);
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr {
  background-color: transparent;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td {
  background-color: transparent;
  border: none;
  border-bottom: solid 1px rgba(70, 81, 170, 0.1);
  padding-inline-start: 20px;
  padding-inline-end: 20px;
  font-size: var(--custom-font-size, 15px);
  font-weight: 400;
  color: var(--color-gray-800);
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td.app-status {
  box-shadow: none;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td.app-status.status-default label.status-list-toggler span.status-list-text {
  color: rgba(27, 35, 49, 0.5);
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td.app-status:where(.status-default + td, .status-default ~ td.date-col) {
  color: rgba(27, 35, 49, 0.5);
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td.app-status:where(.status-default + td, .status-default ~ td.date-col) span.date-value,
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td.app-status:where(.status-default + td, .status-default ~ td.date-col) span:not(.responsive-label) {
  color: rgba(27, 35, 49, 0.5);
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td.date-col {
  font-size: 12px;
  font-weight: 500;
  text-align: start;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td.results-col {
  color: rgba(27, 35, 49, 0.6);
  font-size: 24px;
  cursor: pointer;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td.results-col:hover {
  color: var(--color-gray-800);
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td.results-col a {
  display: inline-block !important;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td.results-col a i {
  color: var(--color-gray-400);
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td.results-col a:hover i {
  color: var(--color-gray-600);
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td.results-col a + .tooltip.left {
  inset-inline-start: initial !important;
  inset-inline-end: calc(100% - 15px);
  width: fit-content;
  max-width: 200px;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td.results-col .results-dropdown .dropdown-toggle {
  position: relative;
  height: 24px;
  display: flex;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td.results-col .results-dropdown .dropdown-toggle i {
  position: absolute;
  z-index: 1;
  font-size: 20px;
  inset-inline-start: 10px;
  opacity: 0.6;
  margin-top: 2px;
  color: rgba(70, 81, 170, 0.5);
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td.results-col .results-dropdown .dropdown-toggle i + i {
  margin-top: 0;
  z-index: 2;
  font-size: 24px;
  inset-inline-start: 0;
  opacity: 1;
  background-color: white;
  padding-inline-end: 2px;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td.results-col .results-dropdown .dropdown-toggle:hover i {
  color: var(--color-primary-600);
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td.results-col .results-dropdown .dropdown-menu {
  padding: 0;
  border-radius: 6px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border: none;
  overflow: hidden;
  width: fit-content;
  max-width: 320px;
  min-width: 190px;
  border: solid 1px rgba(0, 0, 0, 0.05);
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td.results-col .results-dropdown .dropdown-menu .dropdown-item {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  line-height: 1.3;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td.results-col .results-dropdown .dropdown-menu .dropdown-item:hover {
  background-color: rgba(70, 81, 170, 0.05);
  text-decoration: none;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td.results-col .results-dropdown .dropdown-menu .dropdown-item i {
  color: #e84f73;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td.results-col .tooltip {
  width: max-content;
  max-width: 150px;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td.app-options {
  text-align: end;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td.app-options a.btn {
  opacity: 1;
  padding-inline: 0;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td.app-options a.btn i {
  display: none;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td.app-options a.btn span[hidden] {
  display: flex;
  font-size: 10.5px;
  font-weight: 500;
  border: solid 1px rgba(70, 81, 170, 0.3);
  color: var(--color-primary-600);
  border-radius: 3px;
  padding: 2px 6px;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list .apps-pagination {
  text-align: start;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list .apps-pagination dir-pagination-controls {
  display: block;
}
body.modern-theme #base #content > .section-body .dashboard #applications-list .warning-list {
  display: none;
}
@media screen and (max-width: 980px) {
  body.modern-theme #base #content > .section-body .dashboard #applications-list {
    padding: 24px;
  }
  body.modern-theme #base #content > .section-body .dashboard #applications-list table.table thead.responsive-sorting {
    display: block;
  }
  body.modern-theme #base #content > .section-body .dashboard #applications-list table.table thead.heading-with-filters {
    display: none;
    background-color: #EDEEF7;
    position: absolute;
    z-index: 999;
    border-radius: 0 0 6px 6px;
    transition-duration: 0.3s;
    overflow: hidden;
  }
  body.modern-theme #base #content > .section-body .dashboard #applications-list table.table thead.heading-with-filters tr {
    display: flex;
    flex-direction: column;
  }
  body.modern-theme #base #content > .section-body .dashboard #applications-list table.table thead.heading-with-filters tr th {
    flex: 1;
    width: 100%;
    background-color: transparent;
  }
  body.modern-theme #base #content > .section-body .dashboard #applications-list table.table thead.heading-with-filters tr th:where(.status-col, .status-col + th, .opt-col) {
    display: none;
  }
  body.modern-theme #base #content > .section-body .dashboard #applications-list table.table thead.heading-with-filters tr th input {
    display: none;
  }
  body.modern-theme #base #content > .section-body .dashboard #applications-list table.table thead.heading-with-filters tr th > span {
    background-color: transparent;
    border-radius: 0 !important;
    padding: 10px 12px;
  }
  body.modern-theme #base #content > .section-body .dashboard #applications-list table.table thead.heading-with-filters tr th > span:hover {
    background-color: rgba(70, 81, 170, 0.1);
  }
  body.modern-theme #base #content > .section-body .dashboard #applications-list table.table thead.heading-with-filters tr th .sortorder {
    display: none;
  }
  body.modern-theme #base #content > .section-body .dashboard #applications-list table.table.show-sorting thead.heading-with-filters {
    display: block;
    transform: translateY(-10px);
  }
  body.modern-theme #base #content > .section-body .dashboard #applications-list table.table.show-sorting thead.responsive-sorting tr td span.sorting-label {
    border-radius: 6px 6px 0 0;
    background-color: rgba(70, 81, 170, 0.1);
  }
  body.modern-theme #base #content > .section-body .dashboard #applications-list table.table.show-search thead.heading-with-filters {
    display: block;
    transform: translateY(-10px);
    width: 224px;
  }
  body.modern-theme #base #content > .section-body .dashboard #applications-list table.table.show-search thead.heading-with-filters tr th span {
    display: none;
  }
  body.modern-theme #base #content > .section-body .dashboard #applications-list table.table.show-search thead.heading-with-filters tr th input {
    display: block;
    margin: 12px !important;
    background: white;
    padding-inline-start: 12px;
    width: calc(100% - 24px);
  }
  body.modern-theme #base #content > .section-body .dashboard #applications-list table.table.show-search thead.heading-with-filters tr th:first-child input {
    margin: 12px 12px 0 !important;
  }
  body.modern-theme #base #content > .section-body .dashboard #applications-list table.table.show-search thead.responsive-sorting tr td span.cells-filter-toggler {
    border-radius: 6px 6px 0 0;
    background-color: rgba(70, 81, 170, 0.1);
  }
  body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 100%;
    gap: 12px;
  }
  body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr {
    display: grid;
    border-radius: 6px;
    border: solid 1px var(--fields-border-color, var(--color-gray-300));
    grid-template-rows: auto auto auto auto auto;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 1rem;
  }
  body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td {
    width: auto;
    border: none;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-weight: 600;
  }
  body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td.app-options {
    border: none;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td.app-options a {
    padding: 0;
  }
  body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td.app-status {
    font-weight: 600;
    display: flex;
    align-items: center;
    padding-bottom: 0;
    padding-top: 2rem;
  }
  body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td.app-status::before {
    width: 90% !important;
    height: 4px !important;
    top: 0 !important;
    inset-inline-start: 5% !important;
    border-radius: 0 0 3px 3px !important;
  }
  body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td.date-col {
    font-size: 14px;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
  body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td.date-col .date-value:empty::before {
    content: '-';
    display: block;
  }
  body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td.results-col.no-results::after {
    content: '-';
    display: block;
    font-size: 14px;
  }
  body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td.status-col {
    padding-bottom: 0.3rem;
  }
  body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td:nth-child(1) {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: 2;
    grid-column-end: 3;
  }
  body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td:nth-child(2) {
    grid-row-start: 2;
    grid-column-start: 1;
    grid-row-end: 3;
    grid-column-end: 3;
  }
  body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td:nth-child(3) {
    grid-row-start: 3;
    grid-column-start: 1;
    grid-row-end: 4;
    grid-column-end: 2;
  }
  body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td:nth-child(4) {
    grid-row-start: 3;
    grid-column-start: 2;
    grid-row-end: 4;
    grid-column-end: 3;
  }
  body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td:nth-child(5) {
    grid-row-start: 4;
    grid-column-start: 1;
    grid-row-end: 5;
    grid-column-end: 3;
  }
  body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td:nth-child(6) {
    grid-row-start: 5;
    grid-column-start: 1;
    grid-row-end: 6;
    grid-column-end: 2;
  }
  body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td:nth-child(7) {
    grid-row-start: 5;
    grid-column-start: 2;
    grid-row-end: 6;
    grid-column-end: 3;
  }
  body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr td .responsive-label[hidden] {
    display: block;
    font-size: 12px;
    font-weight: 400;
  }
  body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody tr:hover {
    background-color: rgba(70, 81, 170, 0.03);
  }
}
@media screen and (max-width: 880px) {
  body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 640px) {
  body.modern-theme #base #content > .section-body .dashboard #applications-list table.table tbody {
    grid-template-columns: 1fr;
  }
}
body.modern-theme #base #content > .section-body .dashboard #services-list .services-title-search-container {
  margin-bottom: 12px;
}
body.modern-theme #base #content > .section-body .dashboard #services-list .services-title-search-container h2.big-title {
  color: var(--color-gray-800);
  font-size: 24px;
}
@media screen and (max-width: 600px) {
  body.modern-theme #base #content > .section-body .dashboard #services-list .services-title-search-container h2.big-title {
    font-size: 18px;
  }
}
body.modern-theme #base #content > .section-body .dashboard #services-list .services-title-search-container h2.big-title label {
  font-weight: inherit;
}
@media screen and (max-width: 480px) {
  body.modern-theme #base #content > .section-body .dashboard #services-list .services-title-search-container {
    flex-direction: column;
    align-items: flex-start;
  }
  body.modern-theme #base #content > .section-body .dashboard #services-list .services-title-search-container .services-search-icon {
    margin-top: 25px;
  }
  body.modern-theme #base #content > .section-body .dashboard #services-list .services-title-search-container input.form-control {
    margin-top: -25px;
  }
}
@media screen and (max-width: 880px) {
  body.modern-theme #base #content > .section-body .dashboard #services-list > div.d-flex {
    display: block;
  }
}
body.modern-theme #base #content > .section-body .dashboard #services-list .service-categories {
  width: auto;
}
body.modern-theme #base #content > .section-body .dashboard #services-list .service-categories li {
  display: flex;
  flex: auto;
  margin: 0;
  margin-inline-end: 10px;
}
body.modern-theme #base #content > .section-body .dashboard #services-list .service-categories li span.service-category {
  white-space: nowrap;
  display: flex;
  height: 36px;
  align-items: center;
  font-size: 14px;
  color: var(--color-primary-600);
  font-weight: 400;
  border: solid 1px var(--fields-border-color, var(--color-gray-300));
  border-radius: 6px;
}
body.modern-theme #base #content > .section-body .dashboard #services-list .service-categories li span.service-category.text-uppercase {
  text-transform: none;
}
body.modern-theme #base #content > .section-body .dashboard #services-list .service-categories li span.service-category:where(.active, :hover) {
  background-color: rgba(70, 81, 170, 0.1);
  border-color: transparent;
}
body.modern-theme #base #content > .section-body .dashboard #services-list .service-categories li span.service-category.active::after {
  font-family: var(--fontawesome-font);
  font-weight: 400;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  content: "\f00d";
  margin-inline-start: 12px;
}
@media screen and (max-width: 880px) {
  body.modern-theme #base #content > .section-body .dashboard #services-list .service-categories {
    flex-wrap: wrap;
    margin-bottom: 12px;
  }
  body.modern-theme #base #content > .section-body .dashboard #services-list .service-categories > li {
    flex: 0;
    margin-bottom: 10px;
  }
  body.modern-theme #base #content > .section-body .dashboard #services-list .service-categories > li span.service-category {
    height: 36px;
  }
}
body.modern-theme #base #content > .section-body .dashboard #services-list .dashboard-tiles a.dashboard-tile {
  background-color: white;
  border-radius: 6px;
  padding: 20px;
}
body.modern-theme #base #content > .section-body .dashboard #services-list .dashboard-tiles a.dashboard-tile .service-name-container {
  padding-inline-end: 2rem;
}
body.modern-theme #base #content > .section-body .dashboard #services-list .dashboard-tiles a.dashboard-tile .service-name-container .service-name {
  color: var(--color-gray-800);
  font-size: 17px;
  font-weight: 600;
  margin: 0;
}
body.modern-theme #base #content > .section-body .dashboard #services-list .dashboard-tiles a.dashboard-tile .service-name-container .service-description {
  padding-top: 6px;
  color: var(--color-gray-700);
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
body.modern-theme #base #content > .section-body .dashboard #services-list .dashboard-tiles a.dashboard-tile .service-name-container .service-description:hover {
  -webkit-line-clamp: 100;
  animation-name: fullClamp;
  animation-delay: 0.75s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards;
  animation-duration: 0.3s;
}
body.modern-theme #base #content > .section-body .dashboard #services-list .dashboard-tiles a.dashboard-tile .service-icon span {
  font-weight: 300;
  color: var(--color-primary-600);
}
body.modern-theme #base #content > .section-body .dashboard #services-list .dashboard-tiles a.dashboard-tile .service-icon img.item-img {
  height: auto;
  max-height: 40px;
}
body.modern-theme #base #content > .section-body .dashboard #services-list .dashboard-tiles a.dashboard-tile:hover {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
}
@media screen and (max-width: 880px) {
  body.modern-theme #base #content > .section-body .dashboard #services-list .dashboard-tiles {
    grid-template-columns: 1fr 1fr;
  }
  body.modern-theme #base #content > .section-body .dashboard #services-list .dashboard-tiles a.dashboard-tile {
    padding: 24px;
  }
}
@media screen and (max-width: 480px) {
  body.modern-theme #base #content > .section-body .dashboard #services-list .dashboard-tiles {
    grid-template-columns: 1fr;
  }
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents #documents-others .big-title {
  display: none;
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents #documents-others .document-status {
  display: none;
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents #documents-others table.table thead {
  opacity: 1;
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents #documents-others table.table thead tr th {
  padding: 0;
  vertical-align: top;
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents #documents-others table.table thead tr th > span:not(.sortorder) {
  background-color: var(--color-gray-75);
  text-transform: none;
  color: var(--color-gray-600);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  padding: 10px 20px;
  white-space: nowrap;
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents #documents-others table.table thead tr th:first-child > span:not(.sortorder) {
  border-radius: 6px 0 0 6px;
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents #documents-others table.table thead tr th:last-child > span:not(.sortorder) {
  border-radius: 0 6px 6px 0;
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents #documents-others table.table thead tr th input {
  border: solid 1px var(--fields-border-color, var(--color-gray-300));
  background-color: transparent;
  border-radius: 6px;
  margin-top: 18px;
  height: 34px;
  box-shadow: none;
  margin-inline-end: 20px;
  width: calc(100% - 20px);
  margin-bottom: 12px;
  outline: none;
  padding: 4px 20px;
  font-weight: 500;
  font-size: 13px;
  color: var(--color-gray-800);
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents #documents-others table.table tbody tr {
  background-color: transparent;
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents #documents-others table.table tbody tr td {
  background-color: transparent;
  border: none;
  border-bottom: solid 1px rgba(70, 81, 170, 0.1);
  padding-inline-start: 20px;
  padding-inline-end: 20px;
  font-size: 15px;
  font-weight: 500;
  color: var(--color-gray-800);
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents #documents-others table.table tbody tr td.doc-status {
  box-shadow: none;
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents #documents-others table.table tbody tr td.doc-status:where(.expired, .expiring)::before {
  content: "";
  display: block;
  width: 4px;
  height: 36px;
  border-radius: 0 2px 2px 0;
  position: absolute;
  inset-inline-start: 0;
  top: calc(50% - 18px);
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents #documents-others table.table tbody tr td.doc-status.expired::before {
  background-color: #e84f73;
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents #documents-others table.table tbody tr td.doc-status.expiring::before {
  background-color: rgba(232, 79, 115, 0.5);
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents #documents-others table.table tbody tr td.date-col {
  font-size: 12px;
  font-weight: 500;
  text-align: start;
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents #documents-others table.table tbody tr td.expires-at.expired {
  color: #e84f73;
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents #documents-others table.table tbody tr td.expires-at.expiring {
  color: rgba(232, 79, 115, 0.5);
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents #documents-others table.table tbody tr td.text-center {
  text-align: start;
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents #documents-others table.table tbody tr td span.badge {
  background: transparent;
  color: var(--color-gray-800);
  padding: 0;
  display: flex;
  font-weight: 500;
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents #documents-others .docs-pagination {
  display: flex;
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents #documents-others .docs-pagination dir-pagination-controls {
  display: flex;
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents #documents-others .docs-pagination .document-status-modern {
  margin-inline-start: auto;
  padding-top: 32px;
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents #documents-others .docs-pagination .document-status-modern .documents-statuses span {
  font-weight: 500 !important;
  border: none;
  padding: 0 0 6px;
  text-transform: uppercase;
  margin-inline-start: 24px;
  font-size: 11px;
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents #documents-others .docs-pagination .document-status-modern .documents-statuses span:first-child {
  font-weight: 500;
  text-transform: none;
  color: var(--color-gray-800);
  letter-spacing: 0.55px;
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents #documents-others .docs-pagination .document-status-modern .documents-statuses span:first-child:hover {
  border-bottom: none;
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents #documents-others .docs-pagination .document-status-modern .documents-statuses span.status-expiring {
  color: rgba(232, 79, 115, 0.5);
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents #documents-others .docs-pagination .document-status-modern .documents-statuses span.status-expired {
  color: #e84f73;
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents #documents-others .docs-pagination .document-status-modern .documents-statuses span.active-filter {
  background-color: transparent;
  border-bottom: solid 1px;
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents #documents-others .docs-pagination .document-status-modern .documents-statuses span:hover {
  background-color: transparent;
  border-bottom: solid 1px;
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents .table-header-link {
  --header-link-color: hsl(219, 11%, 65%);
  color: var(--header-link-color, inherit) !important;
  border-bottom: solid 2px var(--header-link-border-color, transparent);
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
  margin-block: -10px;
  padding-block: 10px 8px;
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents .table-header-link:is(.active, :hover) {
  --header-link-color: hsl(235, 76%, 12%);
  --header-link-border-color: hsl(235, 76%, 12%);
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents .table-header-link + .table-header-link {
  margin-inline-start: 16px;
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents .table-header-link .counter {
  padding-inline-start: 4px;
  font-weight: 700;
  opacity: 0.5;
  font-size: 12px;
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents .filetype-col {
  font-size: 20px !important;
  padding-block: 1rem;
  width: 10%;
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents .filetype-icon::before {
  content: var(--filetype-content, "\f15b");
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents .filetype-icon[file-id$=".pdf"] {
  --filetype-content: "\f1c1";
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents .filetype-icon[file-id$=".txt"] {
  --filetype-content: "\f15c";
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents .filetype-icon:is([file-id$=".jpg"], [file-id$=".svg"], [file-id$=".png"], [file-id$=".jpeg"], [file-id$=".gif"]) {
  --filetype-content: "\f1c5";
}
body.modern-theme #base #content > .section-body .dashboard #tab-documents .filetype-icon:is([file-id$=".doc"], [file-id$=".docx"]) {
  --filetype-content: "\f1c2";
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages .big-title {
  display: none;
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages table.table thead {
  opacity: 1;
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages table.table thead tr th {
  padding: 0;
  vertical-align: top;
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages table.table thead tr th span:not(.sortorder) {
  background-color: var(--color-gray-75);
  text-transform: none;
  color: var(--color-gray-600);
  font-size: 14px;
  font-weight: 600;
  display: flex;
  padding: 10px 20px;
  white-space: nowrap;
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages table.table thead tr th:first-child span:not(.sortorder) {
  border-radius: 6px 0 0 6px;
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages table.table thead tr th:last-child span:not(.sortorder) {
  border-radius: 0 6px 6px 0;
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages table.table thead tr th input {
  border: solid 1px var(--fields-border-color, var(--color-gray-300));
  background-color: transparent;
  border-radius: 6px;
  margin-top: 18px;
  height: 34px;
  box-shadow: none;
  margin-inline-end: 20px;
  width: calc(100% - 20px);
  margin-bottom: 12px;
  outline: none;
  padding: 4px 20px;
  font-weight: 600;
  font-size: 13px;
  color: var(--color-gray-800);
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages table.table thead tr th.opt-col::before {
  content: "";
  display: block;
  height: 34px;
  background-color: rgba(70, 81, 170, 0.05);
  border-radius: 0 6px 6px 0;
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages table.table tbody tr {
  background-color: transparent;
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages table.table tbody tr td {
  border-bottom: solid 1px rgba(74, 98, 136, 0.1);
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages table.table tbody tr td span.badge {
  background: transparent;
  color: var(--color-gray-800);
  padding: 0;
  font-weight: 600;
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages table.table tbody tr td.seen ~ td span.badge {
  color: rgba(27, 35, 49, 0.6);
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages table.table tbody tr:first-child td {
  background-color: transparent;
  color: var(--color-gray-800);
  font-weight: 500;
  transition-duration: 0.3s;
  text-align: start;
  padding: 20px;
  font-size: 15px;
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages table.table tbody tr:first-child td.msg-status {
  box-shadow: none;
  padding-inline-start: 1.5rem;
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages table.table tbody tr:first-child td.msg-status::before {
  content: "";
  display: block;
  width: 4px;
  height: 36px;
  border-radius: 0 2px 2px 0;
  position: absolute;
  inset-inline-start: 0;
  top: calc(50% - 18px);
  background-color: var(--color-primary-600);
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages table.table tbody tr:first-child td.msg-status.seen::before {
  background-color: rgba(27, 35, 49, 0.3);
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages table.table tbody tr:first-child td.msg-status.seen ~ td {
  color: rgba(27, 35, 49, 0.6);
  transition-duration: 0.3s;
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages table.table tbody tr:first-child td.msg-status.seen > div > i {
  color: rgba(27, 35, 49, 0.6);
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages table.table tbody tr:first-child td.msg-status.seen > div > span {
  color: rgba(27, 35, 49, 0.6);
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages table.table tbody tr:first-child td.msg-status > div {
  display: flex;
  align-items: center;
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages table.table tbody tr:first-child td.msg-status > div > i {
  transform: none;
  font-weight: 400;
  font-size: 14px;
  transition-duration: 0.3s;
  padding: 0 5px;
  background: transparent;
  line-height: 8px;
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages table.table tbody tr:first-child td.msg-status > div > span {
  font-size: 15px;
  color: var(--color-gray-800);
  transition-duration: 0.3s;
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages table.table tbody tr:first-child td .remove-msg {
  font-size: 17px;
  color: #e84f73;
  font-weight: 400;
  transition-duration: 0.3s;
  display: flex;
  justify-content: center;
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages table.table tbody tr:first-child td .remove-msg::before {
  content: "\f00d";
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages table.table tbody tr:where(.active, :hover):first-child td {
  background-color: rgba(27, 35, 49, 0.02);
  color: var(--color-gray-800);
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages table.table tbody tr:where(.active, :hover):first-child td.seen > div > :where(span, i) {
  color: var(--color-gray-800);
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages table.table tbody tr:where(.active, :hover):first-child td.seen ~ td {
  color: var(--color-gray-800);
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages table.table tbody tr:where(.active, :hover):first-child td.seen ~ td span.badge {
  color: var(--color-gray-800);
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages table.table tbody tr.active td {
  border-bottom: solid 1px rgba(74, 98, 136, 0.1);
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages table.table tbody tr.message-body td {
  padding-inline-start: 5.2rem !important;
  background-color: rgba(27, 35, 49, 0.02);
  color: var(--color-gray-800);
  font-size: 14px;
  font-weight: 400;
  box-shadow: none;
  white-space: pre-line;
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages .msgs-pagination {
  display: flex;
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages .msgs-pagination dir-pagination-controls {
  display: flex;
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages .msgs-pagination .message-status-modern {
  margin-inline-start: auto;
  padding-top: 32px;
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages .msgs-pagination .message-status-modern .messages-statuses span {
  font-weight: 600 !important;
  border: none;
  padding: 0 0 6px;
  text-transform: uppercase;
  margin-inline-start: 24px;
  font-size: 11px;
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages .msgs-pagination .message-status-modern .messages-statuses span:first-child {
  font-weight: 600;
  text-transform: none;
  color: var(--color-gray-800);
  letter-spacing: 0.55px;
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages .msgs-pagination .message-status-modern .messages-statuses span:first-child:hover {
  border-bottom: none;
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages .msgs-pagination .message-status-modern .messages-statuses span.status-unread {
  color: rgba(27, 35, 49, 0.3);
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages .msgs-pagination .message-status-modern .messages-statuses span.status-read {
  color: var(--color-primary-600);
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages .msgs-pagination .message-status-modern .messages-statuses span.active-filter {
  background-color: transparent;
  border-bottom: solid 1px;
}
body.modern-theme #base #content > .section-body .dashboard #tab-messages .msgs-pagination .message-status-modern .messages-statuses span:hover {
  background-color: transparent;
  border-bottom: solid 1px;
}
body.modern-theme #base #content > .section-body .dashboard ul.pagination {
  background-color: var(--color-gray-75);
  border-radius: 6px;
  overflow: hidden;
  margin-top: 32px;
}
body.modern-theme #base #content > .section-body .dashboard ul.pagination li {
  display: inline-flex;
  margin-inline-end: 1px;
}
body.modern-theme #base #content > .section-body .dashboard ul.pagination li a {
  background-color: transparent;
  height: 46px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  color: var(--color-gray-800);
  font-weight: 600;
  font-size: 12px;
}
body.modern-theme #base #content > .section-body .dashboard ul.pagination li:where(.active, :hover) a {
  background-color: var(--color-gray-200);
}
body.modern-theme #base #content > .section-body .dashboard ul.pagination li:where(:first-child, :last-child) a {
  color: transparent;
  position: relative;
  width: 38px;
}
body.modern-theme #base #content > .section-body .dashboard ul.pagination li:where(:first-child, :last-child) a::before {
  font-family: var(--fontawesome-font);
  font-weight: 400;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-size: 18px;
  color: var(--color-gray-800);
  position: absolute;
}
body.modern-theme #base #content > .section-body .dashboard ul.pagination li:first-child a::before {
  content: "\f104";
}
body.modern-theme #base #content > .section-body .dashboard ul.pagination li:last-child a::before {
  content: "\f105";
}
body.modern-theme #base #content > .section-body .dashboard .doc-preview-modal .doc-preview-modal-grid .doc-preview-modal-header .doc-preview-service-name {
  font-weight: 600;
  color: rgba(70, 81, 170, 0.5);
}
body.modern-theme #base #content > .section-body .dashboard .doc-preview-modal .doc-preview-modal-grid .doc-preview-modal-header .doc-preview-modal-title {
  color: var(--color-gray-800);
  font-weight: 600;
}
body.modern-theme #base #content > .section-body .dashboard .doc-preview-modal .doc-preview-modal-grid .doc-preview-modal-header .doc-preview-modal-opts .doc-preview-modal-opt-item {
  background-color: rgba(70, 81, 170, 0.05);
  border-radius: 6px;
  box-shadow: none;
  transition-duration: 0.3s;
}
body.modern-theme #base #content > .section-body .dashboard .doc-preview-modal .doc-preview-modal-grid .doc-preview-modal-header .doc-preview-modal-opts .doc-preview-modal-opt-item span {
  font-weight: 600;
}
body.modern-theme #base #content > .section-body .dashboard .doc-preview-modal .doc-preview-modal-grid .doc-preview-modal-header .doc-preview-modal-opts .doc-preview-modal-opt-item.results-paging:hover {
  box-shadow: none;
}
body.modern-theme #base #content > .section-body .dashboard .doc-preview-modal .doc-preview-modal-grid .doc-preview-modal-header .doc-preview-modal-opts .doc-preview-modal-opt-item.results-paging .results-paging-text {
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
}
body.modern-theme #base #content > .section-body .dashboard .doc-preview-modal .doc-preview-modal-grid .doc-preview-modal-header .doc-preview-modal-opts .doc-preview-modal-opt-item.results-paging .paging-btn {
  line-height: 12px;
  height: 45px;
  margin-top: -1rem;
  margin-bottom: -1rem;
  align-items: center;
  display: inline-flex;
  padding: 0 16px;
}
body.modern-theme #base #content > .section-body .dashboard .doc-preview-modal .doc-preview-modal-grid .doc-preview-modal-header .doc-preview-modal-opts .doc-preview-modal-opt-item.results-paging .paging-btn.disabled {
  color: rgba(27, 35, 49, 0.3);
}
body.modern-theme #base #content > .section-body .dashboard .doc-preview-modal .doc-preview-modal-grid .doc-preview-modal-header .doc-preview-modal-opts .doc-preview-modal-opt-item.results-paging .paging-btn.disabled:hover {
  background-color: transparent;
}
body.modern-theme #base #content > .section-body .dashboard .doc-preview-modal .doc-preview-modal-grid .doc-preview-modal-header .doc-preview-modal-opts .doc-preview-modal-opt-item.results-paging .paging-btn.previous {
  margin-inline-start: -2rem;
  border-radius: 6px 0 0 6px;
}
body.modern-theme #base #content > .section-body .dashboard .doc-preview-modal .doc-preview-modal-grid .doc-preview-modal-header .doc-preview-modal-opts .doc-preview-modal-opt-item.results-paging .paging-btn.next {
  margin-inline-end: -2rem;
  border-radius: 0 6px 6px 0;
}
body.modern-theme #base #content > .section-body .dashboard .doc-preview-modal .doc-preview-modal-grid .doc-preview-modal-header .doc-preview-modal-opts .doc-preview-modal-opt-item.results-paging .paging-btn:hover {
  background-color: rgba(70, 81, 170, 0.05);
}
body.modern-theme #base #content > .section-body .dashboard .doc-preview-modal .doc-preview-modal-grid .doc-preview-modal-header .doc-preview-modal-opts .doc-preview-modal-opt-item:not(.results-paging):hover {
  background-color: rgba(70, 81, 170, 0.1);
  box-shadow: 0 9px 20px rgba(70, 81, 170, 0.4) !important;
}
body.modern-theme #base #content > .section-body .dashboard .doc-preview-modal .doc-preview-modal-grid .doc-preview-modal-header .doc-preview-modal-opts .doc-preview-modal-opt-item > i {
  font-weight: 400;
}
@media screen and (max-width: 380px) {
  body.modern-theme #base #content > .section-body {
    padding-inline-start: 1.4rem;
    padding-inline-end: 1.4rem;
  }
}
body.modern-theme #base #content .homepage-link-text {
  margin-bottom: 20px;
  display: none;
  align-items: center;
  gap: 8px;
}
body.modern-theme #base #content .homepage-link-text a {
  text-decoration: underline;
  font-weight: 600;
}
body.modern-theme #base #content .homepage-link-text a:hover {
  text-decoration: none;
  color: #4678aa;
}
body.modern-theme #base #content .homepage-link-text i {
  color: #4678aa;
}
body.modern-theme #base #content .table td.status-col {
  width: 15%;
}
body.modern-theme #base #content .table td.status-col file-status {
  padding-inline-end: 15px;
}
body.modern-theme #base #content .table td.status-col file-status:last-child {
  padding-inline-end: 0;
}
body.modern-theme #base #content .table td.status-col file-status:empty {
  display: none;
}
body.modern-theme #base #content .table td.status-col > i {
  padding: 6px;
  border-radius: 4px;
  color: var(--main-text-color);
}
body.modern-theme #base #content .table td.status-col > i:hover {
  background-color: var(--color-gray-200);
}
body.modern-theme #base .footer-copyright {
  color: var(--color-gray-600);
  font-weight: 600;
  font-size: 11px;
}
@media screen and (max-width: 768px) {
  body.modern-theme #base.demo-mode #content .demo-page-menu .contain-lg .current-service-container {
    flex-direction: column;
    height: 80px;
  }
  body.modern-theme #base.demo-mode #content .demo-page-menu .contain-lg .current-service-container .service-bar-user-lang-menu {
    margin-inline-start: initial;
  }
}
body.modern-theme :where(.demo-page-menu, .modern-theme-dashboard-menu) {
  background-color: var(--color-primary-600);
}
body.modern-theme :where(.demo-page-menu, .modern-theme-dashboard-menu)[hidden] {
  display: block;
}
body.modern-theme :where(.demo-page-menu, .modern-theme-dashboard-menu) .contain-lg {
  width: 100%;
}
body.modern-theme .demo-page-menu.affix + .default-theme-demo-heading {
  padding-top: 66px;
}
body.modern-theme .demo-page-menu.affix + .default-theme-demo-heading ~ .section-body {
  padding-top: 0px !important;
}
body.modern-theme .modern-theme-dashboard-menu {
  position: sticky;
  top: 0;
  z-index: 99999;
}
body.modern-theme .modern-theme-be-list[hidden] {
  display: block;
  z-index: 9999;
  height: 0;
}
body.modern-theme .modern-theme-be-list[hidden] .dropdown .dropdown-toggle {
  background-color: white;
  padding: 6px 12px;
  border-radius: 6px;
  gap: 12px;
  box-shadow: 0px 9px 20px rgba(0, 0, 0, 0.02);
  height: 40px;
  position: relative;
  top: calc(60px + ((62px - 40px) / 2));
  transition-duration: 0.3s;
}
body.modern-theme .modern-theme-be-list[hidden] .dropdown .dropdown-toggle:hover {
  background-color: rgba(70, 81, 170, 0.05);
  text-decoration: none;
}
body.modern-theme .modern-theme-be-list[hidden] .dropdown .dropdown-menu {
  inset-inline-end: 0;
  inset-inline-start: auto;
  padding: 0;
  border-radius: 6px 0 6px 6px;
  box-shadow: 0px 9px 20px rgba(0, 0, 0, 0.05);
  border: none;
  overflow: hidden;
  width: fit-content;
  max-width: 320px;
  min-width: 190px;
}
body.modern-theme .modern-theme-be-list[hidden] .dropdown .dropdown-menu .dropdown-menu-scroller {
  overflow-y: auto;
  display: grid;
}
body.modern-theme .modern-theme-be-list[hidden] .dropdown .dropdown-menu .dropdown-item {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  line-height: 1.3;
}
body.modern-theme .modern-theme-be-list[hidden] .dropdown .dropdown-menu .dropdown-item:hover {
  background-color: rgba(70, 81, 170, 0.05);
  text-decoration: none;
}
body.modern-theme .modern-theme-be-list[hidden] .dropdown.open .dropdown-toggle {
  background-color: rgba(70, 81, 170, 0.05);
  border-radius: 6px 6px 0 0;
}
body.modern-theme .dashboard-tooltip-handler {
  padding-inline-end: 0;
}
body.modern-theme .dashboard-tooltip-handler .dashboard-tooltip {
  background-color: #EDEEF7;
  color: var(--main-text-color);
  border-radius: 6px;
}
body.modern-theme .dashboard-tooltip-handler .dashboard-tooltip::after {
  background-color: #EDEEF7;
}
body.modern-theme .dashboard-tooltip-handler .heading-label::after {
  color: var(--main-text-color);
  font-weight: 400;
}
body.modern-theme .popover {
  border-color: rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}
body.modern-theme .popover .popover-title {
  font-size: 14px;
  padding: 14px 14px 0;
  background: none;
  border: none;
}
body.modern-theme .popover .popover-actions {
  display: flex;
  gap: 12px;
}
body.modern-theme .popover .popover-actions button {
  border-radius: 6px;
}
body.modern-theme .applications-entry-row.status-warning {
  background-color: var(--color-orange-50) !important;
}
body.modern-theme .applications-entry-row.status-warning .fa-chevron-right {
  opacity: 0;
  z-index: -1;
  position: relative;
  margin-inline-start: -15px;
  transition-duration: 0.3s;
}
body.modern-theme .applications-entry-row.status-warning .status-warning {
  --status-color-bg: var(--color-orange-200);
  cursor: pointer;
}
body.modern-theme .applications-entry-row.status-warning .status-warning:hover {
  --status-color-bg: var(--color-orange-300);
  --status-color: var(--color-white);
}
body.modern-theme .applications-entry-row.status-warning .status-warning:hover .fa-chevron-right {
  transition-duration: 0.3s;
  margin-inline-start: 7px;
  opacity: 1;
  z-index: 1;
}
.animate {
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
}
@keyframes slideInBE {
  0% {
    transform: translateY(70px);
    opacity: 0;
  }
  100% {
    transform: translateY(55px);
    opacity: 1;
  }
  0% {
    transform: translateY(70px);
    opacity: 0;
  }
}
@-webkit-keyframes slideInBE {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }
  100% {
    -webkit-transform: translateY(55px);
    -webkit-opacity: 1;
  }
  0% {
    -webkit-transform: translateY(70px);
    -webkit-opacity: 0;
  }
}
.slideInBE {
  -webkit-animation-name: slideInBE;
  animation-name: slideInBE;
}
@keyframes slideIn {
  0% {
    transform: translateY(0px);
    opacity: 0;
  }
  100% {
    transform: translateY(-15px);
    opacity: 1;
  }
  0% {
    transform: translateY(0px);
    opacity: 0;
  }
}
@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }
  100% {
    -webkit-transform: translateY(-15px);
    -webkit-opacity: 1;
  }
  0% {
    -webkit-transform: translateY(0px);
    -webkit-opacity: 0;
  }
}
.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}
@keyframes fullClamp {
  0% {
    -webkit-line-clamp: 3;
  }
  100% {
    -webkit-line-clamp: 100;
  }
}
@-webkit-keyframes fullClamp {
  0% {
    -webkit-line-clamp: 3;
  }
  100% {
    -webkit-line-clamp: 100;
  }
}
body.modern-theme #base #content .affix-menu-size-container {
  margin-inline: -2.4rem;
}
body.modern-theme #base #content .affix-menu-size-container.demo-modern {
  height: 186px;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper nav #user-steps ul.user-steps-menu-list {
  margin-top: var(--wizard-page-menu-top-margin, 3rem);
  height: var(--wizard-page-menu-height, 50px);
  background-color: var(--wizard-bar-bg-color);
  border-radius: 6px;
  border: none;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper nav #user-steps ul.user-steps-menu-list li {
  padding: 0;
  display: flex;
  justify-content: center;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper nav #user-steps ul.user-steps-menu-list li:first-child {
  border-radius: 6px 0 0 6px;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper nav #user-steps ul.user-steps-menu-list li:last-child {
  border-radius: 0 6px 6px 0;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper nav #user-steps ul.user-steps-menu-list li:where(.active, :hover) {
  background-color: rgba(70, 81, 170, 0.1);
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper nav #user-steps ul.user-steps-menu-list li:where(.active, :hover) a {
  color: var(--color-primary-600);
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper nav #user-steps ul.user-steps-menu-list li:where(.active, :hover) a .app-step-page-number {
  font-weight: 900;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper nav #user-steps ul.user-steps-menu-list li .chevron.right {
  display: none;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper nav #user-steps ul.user-steps-menu-list li a {
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.2rem 2.5rem;
  color: rgba(70, 81, 170, 0.4);
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper nav #user-steps ul.user-steps-menu-list li a .app-step-page-number {
  font-size: 14px;
  display: inline-flex;
  border: solid 2px rgba(70, 81, 170, 0.4);
  border-radius: 1in;
  width: 22px;
  min-width: 22px;
  height: 22px;
  line-height: 21px;
  justify-content: center;
  align-items: center;
  margin-inline-end: 10px;
  font-weight: 900;
  position: relative;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper nav #user-steps ul.user-steps-menu-list li a .app-step-page-number .progress-full-check {
  font-size: 2rem;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper nav #user-steps ul.user-steps-menu-list li a .app-step-page-number svg {
  position: absolute;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper nav #user-steps ul.user-steps-menu-list li a .app-step-page-number svg[hidden] {
  display: block;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper nav #user-steps ul.user-steps-menu-list li a .app-step-page-number svg circle.progress-ring__circle {
  stroke: var(--color-primary-600);
  transition: 0.35s stroke-dashoffset;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  stroke-dasharray: 62, 62;
  /*stroke-dashoffset: 62; 62 = 0% ... 0 = 100% */
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper nav #user-steps ul.user-steps-menu-list li a .app-step-page-number svg circle.progress-ring__circle:where([style="stroke-dashoffset: 0"], [style="stroke-dashoffset: 62"], [style="stroke-dashoffset: 0;"], [style="stroke-dashoffset: 62;"]) {
  display: none;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper nav #user-steps ul.user-steps-menu-list li a .app-step-page-number > span i {
  display: none;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper nav #user-steps ul.user-steps-menu-list li a .app-step-page-name {
  position: relative;
  top: 1px;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper nav #user-steps ul.user-steps-menu-list li a .app-step-page-name > span {
  text-transform: initial;
  font-weight: 600;
  font-size: 20px;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper nav #user-steps ul.user-steps-menu-list li > .user-steps-menu-item-progress {
  display: none;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper nav #user-steps ul.user-steps-menu-list li.user-steps-menu-step-completed a .app-step-page-number {
  border: none;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper nav #user-steps ul.user-steps-menu-list li.user-steps-menu-step-completed a .app-step-page-number > span {
  display: none;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper nav #user-steps ul.user-steps-menu-list li.user-steps-menu-step-completed a .app-step-page-number .progress-full-check.hidden {
  display: block !important;
  visibility: visible !important;
  color: #29bc8f;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper nav #user-steps .demo-mode-heading {
  background-color: var(--color-gray-100);
  box-shadow: -1200px 0 0 var(--color-gray-100), -2400px 0 0 var(--color-gray-100), 1200px 0 0 var(--color-gray-100), 2400px 0 0 var(--color-gray-100);
  padding: 20px 0;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper nav #user-steps .demo-mode-heading h4 {
  display: none;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper nav #user-steps .demo-mode-heading span {
  color: var(--color-primary-600);
  font-weight: 600;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper #shoppingcart.service-cart {
  display: none;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper #shoppingcart.service-cart > .regs-docs-fees-drawer {
  background-color: white;
  border-radius: 0 0 6px 6px;
  height: auto;
  position: absolute;
  inset-inline-end: 0;
  top: 100%;
  z-index: 999;
  width: calc(28% - 22px);
  box-shadow: 0px 11px 14px rgba(0, 0, 0, 0.3);
  min-width: 300px;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper #shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-header {
  display: none;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper #shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-body {
  max-height: 74vh;
  overflow-y: auto;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper #shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-body .drawer-section-header {
  color: var(--color-gray-800);
  border-top: solid 1px rgba(70, 81, 170, 0.1);
  font-size: 15px;
  padding-inline-end: 16px;
  /*.section-regs .section-fees .section-docs*/
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper #shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-body .drawer-section-header i {
  border: none;
  width: 18px;
  height: 18px;
  font-weight: 600;
  font-size: 14px;
  color: rgba(27, 35, 49, 0.8);
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper #shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-body .drawer-section-header i::before {
  line-height: 16px;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper #shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-body .drawer-section-header.opened {
  background-color: var(--color-primary-600);
  color: white;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper #shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-body .drawer-section-header.opened i {
  color: white;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper #shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-body .drawer-section-header.opened:where(.section-regs, .section-docs) > span > span:last-child {
  color: var(--color-primary-600);
  background: white;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper #shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-body .drawer-section-header.opened.section-fees span span:last-child {
  color: white;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper #shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-body .drawer-section-header:where(.section-regs, .section-docs) > span > span:last-child {
  font-size: 12px;
  min-width: 16px;
  height: 16px;
  font-weight: 600;
  background: var(--color-primary-600);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper #shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-body .drawer-section-header.section-docs:not(.opened) {
  border-radius: 0 0 6px 6px;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper #shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-body .drawer-section-header:last-of-type:not(.opened) {
  border-radius: 6px;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper #shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-body .drawer-section-header:first-of-type:not(.opened) {
  border-top: none;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper #shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-body .drawer-section-header > span {
  flex: auto;
  justify-content: space-between;
  text-align: start;
  display: flex;
  align-items: center;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper #shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-body .drawer-section-header > span > span:last-child {
  margin-inline-end: 1.6rem;
  color: var(--color-primary-600);
  font-weight: 900;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper #shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-body .drawer-section-content {
  padding: 0;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper #shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-body .drawer-section-content#guidemenurequirements {
  border-radius: 0 0 6px 6px;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper #shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-body .drawer-section-content#guidemenuregs label > div {
  position: relative;
  font-weight: 600;
  color: rgba(70, 81, 170, 0.5);
  display: flex;
  align-items: center;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper #shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-body .drawer-section-content#guidemenuregs label > div input[type="checkbox"] {
  appearance: none;
  position: initial;
  margin: 0;
  width: auto;
  height: auto;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper #shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-body .drawer-section-content#guidemenuregs label > div input[type="checkbox"]::after {
  font-weight: 400;
  content: "\F111";
  position: absolute;
  font-family: "Font Awesome 5 Pro";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-size: 16px;
  top: -1px;
  color: rgba(70, 81, 170, 0.5);
  inset-inline-end: 0;
  cursor: pointer;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper #shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-body .drawer-section-content#guidemenuregs label > div input[type="checkbox"]:checked::after {
  color: var(--color-primary-600);
  content: "\F058";
  font-weight: 900;
  top: calc(50% - 8px);
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper #shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-body .drawer-section-content#guidemenuregs label > div input[type="checkbox"]:checked + * {
  color: var(--color-primary-600);
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper #shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-body .drawer-section-content#guidemenuregs label > div input[type="checkbox"] + span {
  padding-inline-end: 24px;
  line-height: 1.3;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper #shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-body .drawer-section-content#guidemenuregs > div > div {
  padding-inline-end: 16px;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper #shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-body .drawer-section-content#guidemenuregs > div > div:hover label > div {
  color: var(--color-primary-600);
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper #shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-body .drawer-section-content#guidemenuregs > div > div:hover label > div input[type="checkbox"]::after {
  color: var(--color-primary-600);
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper #shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-body .drawer-section-content > div > div {
  color: var(--color-gray-800);
  border-top: solid 1px rgba(70, 81, 170, 0.1);
  font-size: 14px;
  font-weight: 600;
  padding: 15px 20px;
  line-height: 18px;
  background-color: rgba(70, 81, 170, 0.03);
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper #shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-body .drawer-section-content > div > div:empty {
  display: none;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper #shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-body .drawer-section-content > div:first-child > div {
  border-top: none;
}
body.modern-theme #base #content .affix-menu-size-container .user-steps-menu .user-steps-menu-wrapper #shoppingcart.service-cart > .regs-docs-fees-drawer form > .form-field-type-mycontent + .form-group {
  display: none;
}
body.modern-theme #base #content .affix-menu-size-container.affix {
  background-color: white;
  top: 0;
  width: 100%;
  z-index: 1050;
  transition-duration: 150ms;
  transition-property: top;
}
body.modern-theme #base #content .affix-menu-size-container.affix ~ .section-body {
  padding-top: 66px;
}
body.modern-theme #base #content .affix-menu-size-container.affix .user-steps-menu .user-steps-menu-wrapper #shoppingcart.service-cart > .regs-docs-fees-drawer {
  top: 84px;
}
body.modern-theme #base #content .affix-menu-size-container.affix .user-steps-menu .user-steps-menu-wrapper #shoppingcart.service-cart > .regs-docs-fees-drawer .drawer-body {
  max-height: 80vh;
}
body.modern-theme #base #content .affix-menu-size-container.affix .user-steps-menu .user-steps-menu-wrapper nav #user-steps ul.user-steps-menu-list {
  margin-top: 0;
  box-shadow: none;
  height: 44px;
  border: none;
}
body.modern-theme #base #content .affix-menu-size-container.affix .user-steps-menu .user-steps-menu-wrapper nav #user-steps ul.user-steps-menu-list li {
  border-radius: 0;
}
body.modern-theme #base #content .affix-menu-size-container.affix .user-steps-menu .user-steps-menu-wrapper nav #user-steps ul.user-steps-menu-list li a {
  padding: 1rem 2.5rem;
}
body.modern-theme #base #content .affix-menu-size-container.affix .user-steps-menu .user-steps-menu-wrapper nav #user-steps ul.user-steps-menu-list li a .app-step-page-number {
  font-size: 12px;
  width: 18px;
  height: 18px;
  line-height: 16px;
}
body.modern-theme #base #content .affix-menu-size-container.affix .user-steps-menu .user-steps-menu-wrapper nav #user-steps.has-fees-regs-docs-cart .service-cart.modern-theme-cart {
  height: 44px;
  margin-top: 0px;
  border-radius: 0px;
  box-shadow: none;
  border: none;
  background-color: transparent;
}
body.modern-theme #base #content .affix-menu-size-container.affix .user-steps-menu .user-steps-menu-wrapper nav #user-steps.has-fees-regs-docs-cart .service-cart.modern-theme-cart form {
  box-shadow: inset 50px 0;
}
body.modern-theme #base #content .affix-menu-size-container.affix .user-steps-menu .user-steps-menu-wrapper nav #user-steps.has-fees-regs-docs-cart .service-cart.modern-theme-cart form.formio-form > .form-field-type-mycontent .regs-docs-fees-cart :where(.regs, .docs) span.count {
  width: 16px;
  height: 16px;
  top: -3px;
  font-size: 1.2rem;
}
body.modern-theme #base #content .affix-menu-size-container.affix .user-steps-menu .user-steps-menu-wrapper nav #user-steps.has-fees-regs-docs-cart .service-cart.modern-theme-cart form.formio-form > .form-field-type-mycontent .regs-docs-fees-cart i {
  font-size: 2.4rem;
}
body.modern-theme #base #content .affix-menu-size-container.affix .user-steps-menu .user-steps-menu-wrapper nav #user-steps .demo-mode-heading {
  display: none;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav {
  display: flex;
  position: relative;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav ul.pagination {
  margin-top: var(--wizard-page-menu-top-margin, 30px);
  margin-bottom: var(--wizard-page-menu-bottom-margin, 30px);
  height: var(--wizard-page-menu-height, 50px);
  flex: auto;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav ul.pagination li.page-item {
  padding: 0;
  display: flex;
  justify-content: center;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav ul.pagination li.page-item:first-child {
  border-radius: 6px 0 0 6px;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav ul.pagination li.page-item:last-child {
  border-radius: 0 6px 6px 0;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav ul.pagination li.page-item .page-link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: initial;
  line-height: 1;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav ul.pagination li.page-item .page-link::after {
  display: none;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav ul.pagination li.page-item .page-link::before {
  font-size: 14px;
  display: inline-flex;
  border: solid 2px rgba(70, 81, 170, 0.4);
  border-radius: 1in;
  width: 22px;
  min-width: 22px;
  height: 22px;
  line-height: 21px;
  justify-content: center;
  align-items: center;
  margin-inline-end: 10px;
  font-weight: 900;
  position: relative;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav ul.pagination li.page-item .page-link svg {
  margin-inline-start: -33px;
  margin-inline-end: 10px;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav ul.pagination li.page-item .page-link svg[hidden] {
  display: block;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav ul.pagination li.page-item .page-link svg circle.progress-ring__circle {
  stroke: var(--color-primary-600);
  transition: 0.35s stroke-dashoffset;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  stroke-dasharray: 62, 62;
  /*stroke-dashoffset: 62; 62 = 0% ... 0 = 100% */
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav ul.pagination li.page-item .page-link svg circle.progress-ring__circle:where([style="stroke-dashoffset: 0"], [style="stroke-dashoffset: 62"], [style="stroke-dashoffset: 0;"], [style="stroke-dashoffset: 62;"]) {
  display: none;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav ul.pagination li.page-item .page-link.progress-complete::before {
  color: #29bc8f;
  border-color: #29bc8f;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav ul.pagination li.page-item .page-link.progress-complete svg {
  display: none !important;
}
@media screen and (max-width: 980px) {
  body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav ul.pagination li.page-item .page-link {
    font-size: 16px;
  }
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav ul.pagination li.page-item .progress-bar {
  display: none;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart ul.pagination {
  width: auto;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .cart-container {
  width: auto;
  min-width: 300px;
  display: flex;
  margin-inline-start: 22px;
  margin-bottom: var(--wizard-page-menu-bottom-margin, 30px);
  position: relative;
  justify-content: flex-end;
  user-select: none;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .cart-container .cart-title {
  display: none;
  color: var(--color-primary-600);
  font-weight: 600;
  font-size: 17px;
  flex: auto;
  align-items: center;
  white-space: nowrap;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .cart-container .cart-title::after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-flex;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  content: "\f106";
  width: 50px;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: var(--color-primary-600);
  margin-inline-start: auto;
  position: absolute;
  inset-inline-end: 0px;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .cart-container .regs-text {
  color: var(--color-primary-600);
  font-weight: 500;
  font-size: 15px;
  margin-inline-end: 6px;
  white-space: nowrap;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .cart-container .regs-docs-fees-cart {
  margin-inline-start: 0px;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .cart-container .regs-docs-fees-cart.open .cart-title {
  display: flex;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .cart-container .regs-docs-fees-drawer {
  width: 100%;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .cart-container .regs-docs-fees-drawer.open {
  display: flex;
  flex-direction: column;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .cart-container .regs-docs-fees-drawer .drawer-section-header {
  font-weight: 500;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .cart-container .regs-docs-fees-drawer .drawer-section-header span span:first-child {
  margin-inline-end: auto;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .cart-container .regs-docs-fees-drawer .drawer-section-header span span:last-of-type {
  margin-inline-end: 16px;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .cart-container .regs-docs-fees-drawer .drawer-section-header:where(.section-regs, .section-docs) span span:last-of-type {
  font-size: 12px;
  min-width: 16px;
  height: 16px;
  font-weight: 600;
  background: var(--color-primary-600);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .cart-container .regs-docs-fees-drawer .drawer-section-header:where(.section-regs, .section-docs).opened span span:last-of-type {
  color: var(--color-primary-600);
  background: white;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .cart-container .regs-docs-fees-drawer .drawer-section-header i.ml-auto {
  margin-inline-start: 0 !important;
  display: grid;
  place-content: center;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .cart-container .regs-docs-fees-drawer .drawer-section-content label {
  cursor: pointer;
  position: relative;
  font-weight: 600;
  color: rgba(70, 81, 170, 0.5);
  display: flex;
  align-items: center;
  flex: auto;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .cart-container .regs-docs-fees-drawer .drawer-section-content label input[type="checkbox"] {
  appearance: none;
  position: initial;
  margin: 0;
  width: auto;
  height: auto;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .cart-container .regs-docs-fees-drawer .drawer-section-content label input[type="checkbox"]::after {
  font-weight: 400;
  content: "\F111";
  position: absolute;
  font-family: "Font Awesome 5 Pro";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-size: 16px;
  top: calc(50% - 8px);
  color: rgba(70, 81, 170, 0.5);
  inset-inline-end: 0;
  cursor: pointer;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .cart-container .regs-docs-fees-drawer .drawer-section-content label input[type="checkbox"]:checked::after {
  color: var(--color-primary-600);
  content: "\F058";
  font-weight: 900;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .cart-container .regs-docs-fees-drawer .drawer-section-content label input[type="checkbox"]:checked + * {
  color: var(--color-primary-600);
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .cart-container .regs-docs-fees-drawer .drawer-section-content label input[type="checkbox"] + span {
  padding-inline-end: 24px;
  line-height: 1.3;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .cart-container .regs-docs-fees-drawer .drawer-section-content.opened {
  display: block;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .cart-container .regs-docs-fees-drawer .drawer-section-content > div {
  display: flex;
  align-items: center;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .cart-container .regs-docs-fees-drawer .currency {
  margin-inline-end: 4px;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .cart-container .regs-docs-fees-drawer .currency:last-of-type {
  margin-inline-start: 4px;
  margin-inline-end: 0;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .cart-container .regs-docs-fees-drawer .reg-name {
  margin-inline-end: auto;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-cart {
  width: auto;
  min-width: 300px;
  margin-inline-start: 22px;
  margin-top: var(--wizard-page-menu-top-margin, 3rem);
  height: 50px;
  border-radius: 6px;
  border: solid 2px rgba(70, 81, 170, 0.3);
  cursor: pointer;
  color: rgba(70, 81, 170, 0.1);
  box-shadow: inset 50px 0;
  transition-duration: 0.2s;
  display: flex;
  align-items: center;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-cart #shoppingcartmini {
  flex: auto;
  display: flex;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-cart #shoppingcartmini::before {
  content: "";
  display: block;
  width: 50px;
  min-width: 50px;
  height: 34px;
  background: transparent;
  position: absolute;
  top: 0;
  inset-inline-start: 0;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-cart::before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-flex;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  content: "\f07a";
  min-width: 50px;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: var(--color-primary-600);
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-cart span.cart-title[hidden] {
  color: var(--color-primary-600);
  font-weight: 600;
  font-size: 17px;
  flex: auto;
  align-items: center;
  white-space: nowrap;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-cart span.cart-title[hidden]::after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-flex;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  content: "\f106";
  width: 50px;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: var(--color-primary-600);
  margin-inline-start: auto;
  position: absolute;
  inset-inline-end: 0px;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-cart .docs {
  display: none;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-cart .regs {
  position: relative;
  padding-inline-end: 1.2rem;
  border-inline-end: solid 1px rgba(70, 81, 170, 0.3);
  display: flex;
  align-items: center;
  margin-inline-start: 1.5rem;
  padding-top: 2px;
  padding-bottom: 2px;
  line-height: 1;
  height: fit-content;
  align-self: center;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-cart .regs span.regs-text[hidden] {
  display: block;
  color: var(--color-primary-600);
  font-weight: 600;
  font-size: 15px;
  margin-inline-end: 6px;
  white-space: nowrap;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-cart .regs span.count {
  background-color: transparent;
  color: var(--color-primary-600);
  border: solid 1px var(--color-primary-600);
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  line-height: 16px;
  font-size: 10px;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-cart i {
  display: none;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-cart :where(.currency, .fees) {
  color: var(--color-primary-600);
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
  align-self: center;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-cart .fees {
  flex: 1;
  text-align: center;
  display: flex;
  gap: 4px;
  justify-content: center;
  padding-inline: 10px;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-cart .currency:empty {
  display: none;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-cart.open {
  border: none;
  box-shadow: inset 500px 0;
  border-radius: 6px 6px 0 0;
  height: 50px;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-cart.open #shoppingcartmini {
  flex: auto;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-cart.open span.cart-title[hidden] {
  display: flex;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-cart.open .regs {
  display: none;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-cart.open :where(.no-cost-msg, .fees) {
  padding-inline-end: 50px;
}
@media screen and (max-width: 640px) {
  body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-cart {
    min-width: auto;
    width: auto;
  }
  body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-cart #shoppingcartmini {
    position: absolute;
    width: 50px;
    height: 46px;
  }
  body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-cart #shoppingcartmini :where(.regs, .docs, .currency, .fees) {
    display: none;
  }
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-drawer {
  background-color: white;
  border-radius: 0 0 6px 6px;
  height: auto;
  position: absolute;
  inset-inline-end: 0;
  top: calc(50% + (var(--wizard-page-menu-height, 50px) / 2));
  z-index: 999;
  width: calc(28% - 22px);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  min-width: 300px;
  max-height: 74vh;
  overflow-y: auto;
  display: none;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-drawer #shoppingcart {
  display: block !important;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-drawer .drawer-section-header {
  border: none;
  background: no-repeat;
  background-image: none;
  padding: 1rem 2rem;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-gray-800);
  border-top: solid 1px rgba(70, 81, 170, 0.1);
  padding-inline-end: 16px;
  width: 100%;
  /*.section-regs .section-fees .section-docs*/
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-drawer .drawer-section-header i {
  border: none;
  width: 18px;
  height: 18px;
  font-weight: 600;
  font-size: 14px;
  color: rgba(27, 35, 49, 0.8);
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-drawer .drawer-section-header i::before {
  line-height: 16px;
  content: "\F107";
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-drawer .drawer-section-header.opened {
  background-color: var(--color-primary-600);
  color: white;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-drawer .drawer-section-header.opened i {
  color: white;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-drawer .drawer-section-header.opened i::before {
  content: "\F106";
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-drawer .drawer-section-header.opened:where(.section-regs, .section-docs) > span > span:last-child {
  color: var(--color-primary-600);
  background: white;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-drawer .drawer-section-header.opened.section-fees span span:last-child {
  color: white;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-drawer .drawer-section-header:where(.section-regs, .section-docs) > span > span:last-child {
  font-size: 12px;
  min-width: 16px;
  height: 16px;
  font-weight: 600;
  background: var(--color-primary-600);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-drawer .drawer-section-header.section-docs:not(.opened) {
  border-radius: 0 0 6px 6px;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-drawer .drawer-section-header:last-of-type:not(.opened) {
  border-radius: 6px;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-drawer .drawer-section-header:first-of-type:not(.opened) {
  border-top: none;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-drawer .drawer-section-header > span {
  flex: auto;
  justify-content: space-between;
  text-align: start;
  display: flex;
  align-items: center;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-drawer .drawer-section-header > span > span:last-child {
  margin-inline-end: 1.6rem;
  /*color: var(--color-primary-600);*/
  font-weight: 600;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-drawer .drawer-section-content {
  padding: 0;
  display: none;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-drawer .drawer-section-content#guidemenurequirements {
  border-radius: 0 0 6px 6px;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-drawer .drawer-section-content#guidemenuregs label {
  cursor: pointer;
  position: relative;
  font-weight: 600;
  color: rgba(70, 81, 170, 0.5);
  display: flex;
  align-items: center;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-drawer .drawer-section-content#guidemenuregs label input[type="checkbox"] {
  appearance: none;
  position: initial;
  margin: 0;
  width: auto;
  height: auto;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-drawer .drawer-section-content#guidemenuregs label input[type="checkbox"]::after {
  font-weight: 400;
  content: "\F111";
  position: absolute;
  font-family: "Font Awesome 5 Pro";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-size: 16px;
  top: -1px;
  color: rgba(70, 81, 170, 0.5);
  inset-inline-end: 0;
  cursor: pointer;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-drawer .drawer-section-content#guidemenuregs label input[type="checkbox"]:checked::after {
  color: var(--color-primary-600);
  content: "\F058";
  font-weight: 900;
  top: calc(50% - 8px);
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-drawer .drawer-section-content#guidemenuregs label input[type="checkbox"]:checked + * {
  color: var(--color-primary-600);
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-drawer .drawer-section-content#guidemenuregs label input[type="checkbox"] + span {
  padding-inline-end: 24px;
  line-height: 1.3;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-drawer .drawer-section-content#guidemenuregs > div {
  padding-inline-end: 16px;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-drawer .drawer-section-content#guidemenuregs > div:hover label {
  color: var(--color-primary-600);
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-drawer .drawer-section-content#guidemenuregs > div:hover label input[type="checkbox"]::after {
  color: var(--color-primary-600);
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-drawer .drawer-section-content#guidemenufees > div {
  display: flex;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-drawer .drawer-section-content#guidemenufees > div span {
  margin-inline-start: auto;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-drawer .drawer-section-content > div {
  color: var(--color-gray-800);
  border-top: solid 1px rgba(70, 81, 170, 0.1);
  font-size: 14px;
  font-weight: 600;
  padding: 15px 20px;
  line-height: 18px;
  background-color: rgba(70, 81, 170, 0.03);
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-drawer .drawer-section-content > div:empty {
  display: none;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form nav.has-cart .regs-docs-fees-drawer .drawer-section-content > div:first-child {
  border-top: none;
}
body.modern-theme .no-cost-msg {
  color: var(--color-primary-600);
  font-weight: 600;
  font-size: 15px;
  flex: 1;
  padding: 0 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  white-space: nowrap;
}
@-webkit-keyframes colorChange2x {
  0% {
    color: var(--color-primary-600);
  }
  100% {
    color: rgba(70, 81, 170, 0.1);
  }
}
@keyframes colorChange2x {
  0% {
    color: var(--color-primary-600);
  }
  100% {
    color: rgba(70, 81, 170, 0.1);
  }
}
html[dir="ltr"] {
  --corner-top-end-radius: 0 6px 0 0;
}
body.modern-theme {
  color: var(--color-gray-900);
  /* Guide */
  /* New Formio */
  /* Business entity modal */
  /* Readonly forms components */
  /* Test */
}
body.modern-theme .vnotify-container.vn-bottom-right {
  top: 12px !important;
  z-index: 99999;
  width: calc((calc(100vw - 1200px) / 2) - 24px);
  right: 16px;
  bottom: 24px;
  min-width: 320px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 10px;
  flex-direction: column;
}
body.modern-theme .vnotify-container.vn-bottom-right .vnotify-item {
  margin: 0;
  width: fit-content;
  max-width: 320px;
  padding-inline-start: 3rem;
  padding: 0px;
  padding-inline-start: 56px;
  background-color: rgba(var(--toastr-color), 0.85);
  transition-duration: 0.15s;
  border: solid 1px rgb(var(--toastr-color));
  border-radius: 6px;
  --shadow-color-rgb: var(--toastr-color);
  --shadow-opacity: 0.5;
  box-shadow: 0px 6px 20px rgba(var(--toastr-color), 0.5);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  min-height: 56px;
  overflow: hidden;
}
body.modern-theme .vnotify-container.vn-bottom-right .vnotify-item::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-flex;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: var(--fontawesome-font);
  font-weight: 700;
  position: absolute;
  inset-inline-start: 18px;
  top: calc(50% - 10px);
  font-size: 20px;
}
body.modern-theme .vnotify-container.vn-bottom-right .vnotify-item.vnotify-success {
  --toastr-color: 56, 159, 143;
}
body.modern-theme .vnotify-container.vn-bottom-right .vnotify-item.vnotify-success:hover {
  --toastr-color: 26, 137, 120;
}
body.modern-theme .vnotify-container.vn-bottom-right .vnotify-item.vnotify-success::before {
  content: "\F058";
}
body.modern-theme .vnotify-container.vn-bottom-right .vnotify-item.vnotify-error {
  --toastr-color: 247, 95, 95;
}
body.modern-theme .vnotify-container.vn-bottom-right .vnotify-item.vnotify-error:hover {
  --toastr-color: 244, 47, 47;
}
body.modern-theme .vnotify-container.vn-bottom-right .vnotify-item.vnotify-error::before {
  content: "\F057";
}
body.modern-theme .vnotify-container.vn-bottom-right .vnotify-item.vnotify-warning {
  --toastr-color: 255, 160, 118;
}
body.modern-theme .vnotify-container.vn-bottom-right .vnotify-item.vnotify-warning:hover {
  --toastr-color: 255, 124, 66;
}
body.modern-theme .vnotify-container.vn-bottom-right .vnotify-item.vnotify-warning::before {
  content: "\F071";
}
body.modern-theme .vnotify-container.vn-bottom-right .vnotify-item.vnotify-info {
  --toastr-color: 76, 170, 229;
}
body.modern-theme .vnotify-container.vn-bottom-right .vnotify-item.vnotify-info:hover {
  --toastr-color: 6, 156, 249;
}
body.modern-theme .vnotify-container.vn-bottom-right .vnotify-item.vnotify-info::before {
  content: "\F06A";
}
body.modern-theme .vnotify-container.vn-bottom-right .vnotify-item .vnotify-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  height: 100%;
  align-items: center;
  padding-inline: 20px;
  padding-block: 10px;
  background: white;
  color: rgb(var(--toastr-color));
}
body.modern-theme .vnotify-container.vn-bottom-right .vnotify-item .vn-close {
  top: 0;
  right: 4px;
  padding: 0;
  height: 28px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-flex;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: var(--fontawesome-font);
  font-weight: 400;
  align-items: center;
  opacity: 0;
  transition-duration: 0.15s;
  color: #f75f5f;
}
body.modern-theme .vnotify-container.vn-bottom-right .vnotify-item .vn-close::before {
  content: "\f00d";
  line-height: 1;
  transform: rotate(0deg);
  background: transparent;
  top: initial;
  height: auto;
}
body.modern-theme .vnotify-container.vn-bottom-right .vnotify-item .vn-close::after {
  display: none;
}
body.modern-theme .vnotify-container.vn-bottom-right .vnotify-item:hover {
  opacity: 1 !important;
}
body.modern-theme .vnotify-container.vn-bottom-right .vnotify-item:hover .vn-close {
  opacity: 1;
}
body.modern-theme .vnotify-container.vn-bottom-right .vnotify-text {
  border-radius: 0 calc(6px - 1px) calc(6px - 1px) 0;
}
body.modern-theme .vnotify-container:empty {
  display: none;
}
body.modern-theme .step-page-container #formBtn {
  border-radius: 6px !important;
  background-color: var(--color-primary-600);
  padding-inline-start: 25px;
  padding-inline-end: 25px;
  font-weight: 600;
  text-transform: none;
}
body.modern-theme .step-page-container #verify-email-to-send .modal-dialog {
  margin: 210px auto 0;
}
body.modern-theme .be-item-radio label {
  display: flex;
  align-items: center;
  gap: 6px;
  padding-inline-start: 0;
}
body.modern-theme .be-item-radio .be-item-input {
  margin: 0;
  position: relative;
  top: 1px;
}
body.modern-theme .status-badge {
  --status-color: var(--main-text-color);
  --status-color-bg: #E6E6EA;
  --status-font-weight: 500;
  font-size: var(--status-font-size, 12px);
  font-weight: var(--status-font-weight);
  color: var(--status-color);
  background-color: transparent;
  padding: 4px 12px;
  border-radius: 20px;
  border: none;
  line-height: 1.5;
  white-space: nowrap;
}
body.modern-theme .status-badge.status-bg,
body.modern-theme .status-badge.hover-bg:hover {
  background-color: var(--status-color-bg);
  white-space: nowrap;
}
body.modern-theme .status-badge.uppercase {
  text-transform: uppercase;
  padding-inline: 0px;
}
body.modern-theme .status-badge:is(.status-default, .status-unavailable) {
  --status-color: var(--main-text-color);
  --status-color-bg: #E6E6EA;
}
body.modern-theme .status-badge:is(.status-info, .status-pending, .status-filepending) {
  --status-color: #1D86C8;
  --status-color-bg: #E4F2FB;
}
body.modern-theme .status-badge:is(.status-success, .status-processed, .status-filevalidated) {
  --status-color: #10B981;
  --status-color-bg: #E8F7F1;
}
body.modern-theme .status-badge:is(.status-warning, .status-correction, .status-filedecline) {
  --status-color: #FF7233;
  --status-color-bg: #FFF0EB;
}
body.modern-theme .status-badge:is(.status-error, .status-rejected, .status-filereject, .status-danger) {
  --status-color: #F53D3D;
  --status-color-bg: #FEE7E7;
}
body.modern-theme .status-badge .tooltip-inner {
  white-space: break-spaces;
}
body.modern-theme .status-badge .exclamation-icon {
  margin-inline-start: 4px;
  margin-inline-end: -2px;
}
body.modern-theme .popover {
  font-family: var(--main-font-family);
  z-index: 99999;
}
body.modern-theme .popover file-status {
  display: inline-flex;
}
body.modern-theme .popover-file-roles {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 4px;
}
body.modern-theme .status-legend-filter {
  margin-top: 30px;
}
body.modern-theme .status-legend-filter .all-limited-button span {
  background-color: var(--color-gray-75);
  border-radius: 6px;
  color: var(--color-gray-800);
  height: 36px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  font-weight: 500;
}
body.modern-theme .status-legend-filter .all-limited-button span::after {
  font-family: var(--fontawesome-font);
  font-weight: 400;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  content: "\f107";
  margin-inline-start: 12px;
}
body.modern-theme .status-legend-filter .all-limited-button span:last-child::after {
  content: "\f106";
}
body.modern-theme .status-legend-filter .all-limited-button span:hover {
  text-decoration: none;
}
body.modern-theme .status-legend-filter .application-status {
  margin-inline-end: -2000px;
  height: 0;
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0, 0.18, 0, 1);
}
body.modern-theme .status-legend-filter .application-status .applications-statuses {
  height: 26px;
  gap: 8px;
}
body.modern-theme .status-legend-filter .application-status.show-legend {
  margin-inline-end: 0px;
  height: auto;
}
@media screen and (max-width: 480px) {
  body.modern-theme .status-legend-filter .application-status.show-legend .applications-statuses {
    display: grid;
  }
  body.modern-theme .status-legend-filter .application-status.show-legend .applications-statuses span {
    text-align: start;
  }
}
body.modern-theme .status-legend-filter span.btn-link {
  font-size: 12px;
}
body.modern-theme .status-legend-filter span.filter-by {
  margin-inline-end: 28px;
  font-size: 12px;
  color: var(--color-gray-800);
  font-weight: 500;
}
body.modern-theme .status-legend-filter .status-filter-toggler {
  padding: 0;
  color: var(--color-gray-800);
  border-bottom: solid 1px;
  line-height: 1.2;
}
body.modern-theme .status-legend-filter .status-filter-toggler:where(:hover, :focus) {
  border-bottom-color: transparent;
  text-decoration: none;
}
body.modern-theme .status-legend-filter.part-b-status-filter {
  margin-inline-start: auto;
}
body.modern-theme form.formio-form .form-group.form-field-type-panel {
  box-shadow: none;
}
body.modern-theme #form-type-GUIDE form .formio-component-questionscolumns > .row > .col-sm-4 > .form-field-type-panel > .panel {
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border-radius: 6px !important;
  padding: 24px;
}
body.modern-theme #form-type-GUIDE form .formio-component-questionscolumns > .row > .col-sm-4 > .form-field-type-panel > .panel > .panel-heading {
  padding-top: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-bottom: 35px;
}
body.modern-theme #form-type-GUIDE form .formio-component-questionscolumns > .row > .col-sm-4 > .form-field-type-panel > .panel > .panel-heading > .panel-title {
  font-size: 18px;
  font-weight: 600;
  color: var(--color-gray-800);
}
body.modern-theme #form-type-GUIDE form .formio-component-questionscolumns > .row > .col-sm-4 > .form-field-type-panel > .panel > .panel-body {
  padding: 0;
}
body.modern-theme #form-type-GUIDE form .formio-component-questionscolumns > .row > .col-sm-4 > .form-field-type-panel > .panel > .panel-body .panel {
  background-color: transparent;
}
body.modern-theme #form-type-GUIDE form .formio-component-questionscolumns > .row > .col-sm-4 + .col-sm-4 .form-field-type-htmlelement {
  margin-bottom: 0;
}
body.modern-theme #form-type-GUIDE form .formio-component-questionscolumns > .row > .col-sm-4 + .col-sm-4 .form-field-type-htmlelement:first-of-type div {
  line-height: 1;
}
body.modern-theme #form-type-GUIDE form .formio-component-questionscolumns > .row > .col-sm-4 + .col-sm-4 .form-field-type-htmlelement:first-of-type span.card-title {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: var(--color-gray-800);
  padding-bottom: 35px;
}
body.modern-theme #form-type-GUIDE form .formio-component-questionscolumns > .row > .col-sm-4 + .col-sm-4 .form-field-type-htmlelement + .form-field-type-checkbox {
  margin-top: 0;
}
body.modern-theme #form-type-GUIDE form .formio-component-questionscolumns > .row > .col-sm-4 + .col-sm-4 .form-field-type-htmlelement + .form-field-type-checkbox .checkbox {
  padding-top: 0;
}
body.modern-theme #form-type-GUIDE form .formio-component-questionscolumns > .row > .col-sm-4 + .col-sm-4 .form-field-type-checkbox .checkbox {
  margin-top: 0;
}
body.modern-theme #form-type-GUIDE form .formio-component-questionscolumns > .row > .col-sm-4 + .col-sm-4 .form-field-type-checkbox .checkbox label span {
  line-height: 1.2;
  padding-inline-start: 3rem;
  margin-top: -2px;
}
body.modern-theme #form-type-GUIDE form .formio-component-questionscolumns > .row > .col-sm-4 + .col-sm-4 .form-field-type-checkbox .checkbox label span::before {
  top: 2px;
}
body.modern-theme #form-type-GUIDE form .formio-component-questionscolumns > .row .block-fees {
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border-radius: 6px !important;
}
body.modern-theme #form-type-GUIDE form .formio-component-questionscolumns > .row .block-fees .form-field-type-button button {
  background-color: transparent;
  padding: 24px;
  color: var(--color-gray-800);
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
}
body.modern-theme #form-type-GUIDE form .formio-component-questionscolumns > .row .block-fees .formio-component-feeTitleColumns > .row > .col-sm-6:last-of-type {
  display: flex;
  align-items: center;
}
body.modern-theme #form-type-GUIDE form .formio-component-questionscolumns > .row .block-fees .formio-component-feeTitleColumns > .row > .col-sm-6:last-of-type .formio-component-feeTitleContent {
  color: var(--color-gray-800);
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
}
body.modern-theme #form-type-GUIDE form .formio-component-questionscolumns > .row .block-requirements {
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border-radius: 6px !important;
  padding: 24px;
  color: var(--color-gray-800);
  margin-top: 2rem !important;
}
body.modern-theme #form-type-GUIDE form .formio-component-questionscolumns > .row .block-requirements .formio-component-requirementTitleColumns .formio-component-requirementOpenCloseButton {
  line-height: 1;
}
body.modern-theme #form-type-GUIDE form .formio-component-questionscolumns > .row .block-requirements .formio-component-requirementTitleColumns .formio-component-requirementOpenCloseButton::before {
  top: 0.4rem;
  inset-inline-end: -2px;
}
body.modern-theme #form-type-GUIDE form .formio-component-questionscolumns > .row .block-requirements .formio-component-requirementTitleColumns .formio-component-requirementOpenCloseButton button {
  background-color: transparent;
  padding: 0 0 30px;
  line-height: 1;
  font-size: 18px;
  font-weight: 600;
}
body.modern-theme #form-type-GUIDE form .formio-component-questionscolumns > .row .block-requirements .formio-component-requirementTitleColumns .formio-component-requirementOpenCloseButton.closed button {
  padding: 0;
}
body.modern-theme #form-type-GUIDE form .formio-component-questionscolumns > .row .block-requirements .formio-component-requirementTitleColumns .formio-component-requirementTitleCount {
  top: 0;
  line-height: 1;
  inset-inline-end: 3rem;
  font-size: 18px;
  font-weight: 600;
}
body.modern-theme #form-type-GUIDE form .formio-component-questionscolumns > .row .block-requirements .formio-component-requirementsRows {
  padding: 0;
}
body.modern-theme #form-type-GUIDE form .formio-component-questionscolumns > .row .block-requirements .formio-component-requirementsRows .formio-container-component > .form-field-type-htmlelement:first-child:not(.ng-hide) li {
  border-top: none;
}
body.modern-theme #form-type-GUIDE form .formio-component-questionscolumns > .row .block-requirements .formio-component-requirementsRows .formio-container-component > .form-field-type-htmlelement:first-child.ng-hide + .form-field-type-htmlelement li {
  border-top: none;
}
body.modern-theme #form-type-GUIDE form .formio-component-questionscolumns > .row .block-requirements ~ .form-field-type-button {
  margin-top: 2rem !important;
}
body.modern-theme.formio-new #header {
  z-index: 999;
  max-height: 80px;
}
body.modern-theme.formio-new #base {
  overflow: initial;
}
body.modern-theme.formio-new #base #content section.affix-menu-size-container #shopping-cart-container {
  display: none;
}
body.modern-theme.formio-new #base #content section.affix-menu-size-container .service-bar-user-lang-menu[hidden] {
  display: block;
}
body.modern-theme.formio-new #base #content section.affix-menu-size-container.affix ~ .section-body .contain-lg #formio-form-wizard .formio-component-modal-wrapper.formio-component-modal-wrapper-datagrid .formio-dialog.formio-dialog-theme-default {
  padding-top: 55px;
  transition-duration: 0.15s;
  transition-property: padding-top;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg .transactions-statuses {
  position: relative;
  z-index: 1040;
  background: white;
  box-shadow: 45vw -3rem 0 white, -45vw -3rem 0 white, -50vw 0 0 white, 50vw 0 0 white;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg .transactions-statuses .alert {
  margin-top: 1rem !important;
  margin-bottom: 0 !important;
  border-radius: 0 6px 6px 0;
  box-shadow: none;
  border-inline-start: solid 4px rgb(var(--alert-color-rgb)) !important;
  background-color: rgba(var(--alert-color-rgb), 0.1) !important;
  color: rgb(var(--alert-color-rgb)) !important;
  padding: 1.5rem 2rem !important;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg .transactions-statuses .alert::after {
  display: none !important;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg .transactions-statuses .alert.alert-success {
  --alert-color-rgb: 16, 185, 129;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg .transactions-statuses .alert.alert-warning {
  --alert-color-rgb: 255, 163, 133;
}
body.modern-theme.formio-new #base #content .section-body .contain-lg .transactions-statuses .alert.alert-danger {
  --alert-color-rgb: 247, 95, 95;
}
body.modern-theme.formio-new .flatpickr-calendar {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border: solid 1px var(--color-gray-300);
  border-radius: 6px;
}
body.modern-theme.formio-new .flatpickr-calendar .flatpickr-months :where(.flatpickr-next-month, .flatpickr-prev-month) {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  border-radius: 6px 0 0 0;
  position: initial;
}
body.modern-theme.formio-new .flatpickr-calendar .flatpickr-months :where(.flatpickr-next-month, .flatpickr-prev-month) svg {
  fill: var(--color-primary-600);
}
body.modern-theme.formio-new .flatpickr-calendar .flatpickr-months :where(.flatpickr-next-month, .flatpickr-prev-month):hover {
  background-color: rgba(70, 81, 170, 0.1);
}
body.modern-theme.formio-new .flatpickr-calendar .flatpickr-months :where(.flatpickr-next-month, .flatpickr-prev-month):hover svg {
  fill: var(--color-primary-600);
}
body.modern-theme.formio-new .flatpickr-calendar .flatpickr-months .flatpickr-next-month {
  border-radius: 0 6px 0 0;
}
body.modern-theme.formio-new .flatpickr-calendar .flatpickr-months .flatpickr-month {
  position: initial;
}
body.modern-theme.formio-new .flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month {
  padding-top: 0;
  position: initial;
  display: flex;
  align-items: center;
  width: 100%;
}
body.modern-theme.formio-new .flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month .flatpickr-monthDropdown-months {
  flex: auto;
  line-height: 20px;
}
body.modern-theme.formio-new .flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month .numInputWrapper {
  flex: 1;
}
body.modern-theme.formio-new .flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month :where(.flatpickr-monthDropdown-months, .numInputWrapper) {
  font-weight: 600;
  font-size: 14px;
  color: var(--color-primary-600);
  display: inline-flex;
  height: 100%;
  padding: 0 12px;
}
body.modern-theme.formio-new .flatpickr-calendar .flatpickr-months .flatpickr-month .flatpickr-current-month :where(.flatpickr-monthDropdown-months, .numInputWrapper):hover {
  background-color: rgba(70, 81, 170, 0.1);
}
body.modern-theme.formio-new .flatpickr-calendar .flatpickr-innerContainer .flatpickr-rContainer .flatpickr-weekdays .flatpickr-weekdaycontainer .flatpickr-weekday {
  font-weight: 600;
  font-size: 14px;
  color: var(--color-primary-600);
}
body.modern-theme.formio-new .flatpickr-calendar .flatpickr-innerContainer .flatpickr-rContainer .flatpickr-days .dayContainer .flatpickr-day {
  font-size: 12px;
  border-radius: 0;
  height: 32px;
  line-height: 32px;
  color: var(--color-primary-600);
  border: none;
}
body.modern-theme.formio-new .flatpickr-calendar .flatpickr-innerContainer .flatpickr-rContainer .flatpickr-days .dayContainer .flatpickr-day:hover,
body.modern-theme.formio-new .flatpickr-calendar .flatpickr-innerContainer .flatpickr-rContainer .flatpickr-days .dayContainer .flatpickr-day:where(.today, .selected) {
  background-color: rgba(70, 81, 170, 0.1);
  border: none;
}
body.modern-theme.formio-new .flatpickr-calendar .flatpickr-innerContainer .flatpickr-rContainer .flatpickr-days .dayContainer .flatpickr-day.selected {
  font-weight: 600;
}
body.modern-theme.formio-new .flatpickr-calendar .flatpickr-innerContainer .flatpickr-rContainer .flatpickr-days .dayContainer .flatpickr-day:where(.prevMonthDay, .nextMonthDay) {
  color: rgba(70, 81, 170, 0.4);
}
body.modern-theme .formio-component-dropdown {
  position: relative;
  display: inline-block;
  border-radius: 4px;
  --button-border-style: none;
  --button-border-width: 0px;
  --button-border-color: transparent;
  --button-border-color-hover: transparent;
  --button-bg-color: transparent;
  --button-bg-color-hover: var(--color-primary-50);
  --button-secondary-bg-color: var(--color-primary-50);
  --button-secondary-bg-color-hover: var(--color-primary-75);
  --button-secondary-text-color: var(--color-gray-800);
  --button-text-color: var(--color-gray-900);
  --button-text-color-hover: var(--color-gray-800);
  --button-outline-text-color: var(--color-primary-600);
  --button-outline-text-color-hover: var(--color-primary-900);
  --button-outline-bg-color: transparent;
  --button-outline-bg-color-hover: var(--color-primary-50);
  --button-outline-light-border-color: var(--color-primary-75);
}
body.modern-theme .formio-component-dropdown > .card-header {
  padding: 0px 12px;
  min-width: 24px;
  min-height: 24px;
  cursor: pointer;
}
body.modern-theme .formio-component-dropdown > .card-header > .card-title {
  padding-bottom: 0px;
  font-size: inherit;
  line-height: 1;
}
body.modern-theme .formio-component-dropdown > .card-header > .card-title i {
  position: initial !important;
}
body.modern-theme .formio-component-dropdown > .dropdown-menu-container {
  position: fixed;
  z-index: 100;
  box-shadow: none;
  background-color: transparent;
}
body.modern-theme .formio-component-dropdown > .dropdown-menu-container > .card-body {
  position: relative;
  overflow-y: auto;
  max-height: 30vh;
  padding: 4px;
  border-radius: 6px;
  background-color: #FFFFFF;
  box-shadow: 0px 16px 24px 0px rgba(48, 49, 51, 0.09), 0px 0px 1px 0px rgba(48, 49, 51, 0.05);
}
body.modern-theme .formio-component-dropdown > .dropdown-menu-container > .card-body > .form-group {
  margin-bottom: 0;
  border-bottom: solid 1px rgba(255, 255, 255, 0.5);
}
body.modern-theme .formio-component-dropdown > .dropdown-menu-container > .card-body > .formio-component-dropdown {
  display: block;
  width: 100%;
}
body.modern-theme .formio-component-dropdown > .dropdown-menu-container > .card-body > .formio-component-dropdown > .dropdown-menu-container {
  position: inherit;
}
body.modern-theme .formio-component-dropdown > .dropdown-menu-container > .card-body > .formio-component-dropdown > .dropdown-menu-container > .card-body > .formio-component > .card-header {
  padding-left: calc(var(--depth-level)*15px);
}
body.modern-theme .formio-component-dropdown > .dropdown-menu-container .formio-component-button.dropdown-menu-item {
  border-bottom: none;
  margin-top: 0px !important;
}
body.modern-theme .formio-component-dropdown > .dropdown-menu-container .formio-component-button.dropdown-menu-item > button.btn {
  --xs-button-height: 40px !important;
  --sm-button-height: 40px !important;
  --md-button-height: 40px !important;
  --lg-button-height: 40px !important;
  --xl-button-height: 40px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  column-gap: 12px !important;
  width: 100%;
  border-radius: 0 !important;
  border: none !important;
  justify-content: flex-start;
  padding-inline: 16px !important;
  padding-block: 0px !important;
  --button-text-color: var(--color-gray-900);
  --button-shadow: none;
  --button-bg-color: transparent;
}
body.modern-theme .formio-component-dropdown > .dropdown-menu-container .formio-component-button.dropdown-menu-item > button.btn span {
  color: var(--color-gray-500);
  font-size: 16px;
  min-width: 20px;
}
body.modern-theme .formio-component-dropdown > .dropdown-menu-container .formio-component-button.dropdown-menu-item > button.btn:hover {
  --button-text-color-hover: var(--color-gray-900);
  --button-shadow-hover: none;
  --button-bg-color: var(--color-gray-50);
  opacity: 1 !important;
}
body.modern-theme .formio-component-dropdown > .dropdown-menu-container .formio-component-button.dropdown-separator-line {
  border-top: solid 1px var(--color-gray-100);
  margin-top: 4px !important;
  padding-top: 4px;
}
body.modern-theme .formio-component-dropdown.formio-component-label-hidden > .card-header {
  padding: 0px;
  max-width: 24px;
  max-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
body.modern-theme .formio-component-dropdown:not(.formio-component-label-hidden) {
  width: fit-content;
}
body.modern-theme .formio-component-dropdown:not(.formio-component-label-hidden) > .card-header {
  display: flex;
  align-items: center;
  min-height: 36px;
}
body.modern-theme .formio-component-dropdown:not(.formio-component-label-hidden) > .card-header > .card-title {
  display: flex;
  align-items: center;
  gap: 8px;
}
body.modern-theme .formio-component-dropdown:not(.formio-collapsed) {
  background-color: var(--color-gray-100);
}
body.modern-theme .formio-component-dropdown.formio-collapsed:hover {
  background-color: var(--color-gray-100);
}
body.modern-theme .formio-component-dropdown.formio-collapsed:is(.btn-green, .btn-blue, .btn-orange, .btn-red):hover {
  background-color: transparent;
}
body.modern-theme .formio-component-dropdown.formio-collapsed > .dropdown-menu-container {
  display: none;
}
body.modern-theme .formio-component-dropdown.horizontal-align-right {
  display: block;
  width: fit-content;
  margin-inline-start: auto;
}
body.modern-theme .formio-component-dropdown > .card-header {
  border-radius: 6px;
  background-color: var(--button-bg-color);
  border-style: var(--button-border-style);
  border-width: var(--button-border-width);
  border-color: var(--button-border-color);
  padding-inline: var(--md-button-inline-padding);
  padding-block: var(--md-button-block-padding);
  font-size: var(--md-button-font-size);
  min-height: var(--md-button-height);
  border-radius: var(--md-button-border-radius);
}
body.modern-theme .formio-component-dropdown > .card-header > .card-title {
  color: var(--button-text-color) !important;
}
body.modern-theme .formio-component-dropdown > .card-header:hover {
  background-color: var(--button-bg-color-hover);
}
body.modern-theme .formio-component-dropdown > .card-header:hover > .card-title {
  color: var(--button-text-color-hover) !important;
}
body.modern-theme .formio-component-dropdown:is(.btn-secondary, .light-color) > .card-header {
  background-color: var(--button-secondary-bg-color);
}
body.modern-theme .formio-component-dropdown:is(.btn-secondary, .light-color) > .card-header > .card-title {
  color: var(--button-secondary-text-color) !important;
}
body.modern-theme .formio-component-dropdown.outline-button {
  --button-border-style: solid;
  --button-border-width: 1px;
  --button-border-color: var(--color-primary-600);
  --button-border-color-hover: var(--color-primary-900);
  --button-bg-color: transparent;
  --button-bg-color-hover: var(--color-primary-50);
  --button-outline-bg-color: transparent;
}
body.modern-theme .formio-component-dropdown.outline-button > .card-header {
  border: solid 1px var(--button-border-color);
  background-color: var(--button-outline-bg-color);
}
body.modern-theme .formio-component-dropdown.outline-button > .card-header > .card-title {
  color: var(--button-outline-text-color) !important;
}
body.modern-theme .formio-component-dropdown.outline-button > .card-header:hover {
  background-color: var(--button-outline-bg-color-hover) !important;
}
body.modern-theme .formio-component-dropdown.outline-button > .card-header:hover > .card-title {
  color: var(--button-outline-text-color-hover);
}
body.modern-theme .formio-component-dropdown.outline-button:not(.formio-collapsed) {
  background-color: transparent;
}
body.modern-theme .formio-component-dropdown.outline-button:not(.formio-collapsed):hover {
  background-color: transparent;
}
body.modern-theme .formio-component-dropdown.outline-button:not(.formio-collapsed) > .card-header {
  background-color: var(--button-outline-bg-color);
}
body.modern-theme .formio-component-dropdown.outline-button:hover {
  background-color: transparent;
}
body.modern-theme .formio-component-dropdown.outline-button:is(.btn-secondary, .light-color) > .card-header {
  border-color: var(--button-outline-light-border-color);
}
body.modern-theme .formio-component-dropdown.btn-green {
  --button-border-color: #10B981;
  --button-outline-text-color: #10B981;
  --button-text-color: #FFFFFF;
  --button-text-color-hover: #FFFFFF;
  --button-secondary-bg-color: rgba(16, 185, 129, 0.35);
  --button-outline-bg-color-hover: rgba(16, 185, 129, 0.05);
  --button-bg-color-hover: rgba(16, 185, 129, 0.9);
}
body.modern-theme .formio-component-dropdown.btn-blue {
  --button-border-color: #4CAAE5;
  --button-outline-text-color: #4CAAE5;
  --button-text-color: #FFFFFF;
  --button-text-color-hover: #FFFFFF;
  --button-secondary-bg-color: rgba(76, 170, 229, 0.35);
  --button-outline-bg-color-hover: rgba(76, 170, 229, 0.05);
  --button-bg-color-hover: rgba(76, 170, 229, 0.9);
}
body.modern-theme .formio-component-dropdown.btn-orange {
  --button-border-color: #FFA385;
  --button-outline-text-color: #FFA385;
  --button-text-color: #FFFFFF;
  --button-text-color-hover: #FFFFFF;
  --button-secondary-bg-color: rgba(255, 163, 133, 0.5);
  --button-outline-bg-color-hover: rgba(255, 163, 133, 0.05);
  --button-bg-color-hover: rgba(255, 163, 133, 0.9);
}
body.modern-theme .formio-component-dropdown.btn-red {
  --button-border-color: #F75F5F;
  --button-outline-text-color: #F75F5F;
  --button-text-color: #FFFFFF;
  --button-text-color-hover: #FFFFFF;
  --button-secondary-bg-color: rgba(247, 95, 95, 0.35);
  --button-outline-bg-color-hover: rgba(247, 95, 95, 0.05);
  --button-bg-color-hover: rgba(247, 95, 95, 0.9);
}
body.modern-theme .formio-component-dropdown.btn-block {
  width: 100%;
}
body.modern-theme .formio-component-dropdown.size-xs > .card-header {
  padding-inline: var(--xs-button-inline-padding);
  padding-block: var(--xs-button-block-padding);
  font-size: var(--xs-button-font-size);
  min-height: var(--xs-button-height);
  border-radius: var(--xs-button-border-radius);
}
body.modern-theme .formio-component-dropdown.size-sm > .card-header {
  padding-inline: var(--sm-button-inline-padding);
  padding-block: var(--sm-button-block-padding);
  font-size: var(--sm-button-font-size);
  min-height: var(--sm-button-height);
  border-radius: var(--sm-button-border-radius);
}
body.modern-theme .formio-component-dropdown.size-md > .card-header {
  padding-inline: var(--md-button-inline-padding);
  padding-block: var(--md-button-block-padding);
  font-size: var(--md-button-font-size);
  min-height: var(--md-button-height);
  border-radius: var(--md-button-border-radius);
}
body.modern-theme .formio-component-dropdown.size-lg > .card-header {
  padding-inline: var(--lg-button-inline-padding);
  padding-block: var(--lg-button-block-padding);
  font-size: var(--lg-button-font-size);
  min-height: var(--lg-button-height);
  border-radius: var(--lg-button-border-radius);
}
body.modern-theme .formio-component-dropdown.size-xl > .card-header {
  padding-inline: var(--xl-button-inline-padding);
  padding-block: var(--xl-button-block-padding);
  font-size: var(--xl-button-font-size);
  min-height: var(--xl-button-height);
  border-radius: var(--xl-button-border-radius);
}
body.modern-theme .formio-component {
  --field-height: 40px;
  --field-font-size: 16px;
  color: var(--color-gray-900);
  margin-top: 0;
  margin-bottom: 0;
}
body.modern-theme .formio-component.top-space-05 {
  margin-top: 15px !important;
}
body.modern-theme .formio-component.top-space-05.top-label-space {
  margin-top: calc(15px + 29px) !important;
}
body.modern-theme .formio-component.top-space {
  margin-top: 30px !important;
}
body.modern-theme .formio-component.top-space.top-label-space {
  margin-top: calc(30px + 29px) !important;
}
body.modern-theme .formio-component.top-space-2x {
  margin-top: 60px !important;
}
body.modern-theme .formio-component.top-space-2x.top-label-space {
  margin-top: calc(60px + 29px) !important;
}
body.modern-theme .formio-component.top-space-3x {
  margin-top: 90px !important;
}
body.modern-theme .formio-component.top-space-3x.top-label-space {
  margin-top: calc(90px + 29px) !important;
}
body.modern-theme .formio-component.top-label-space {
  margin-top: 29px !important;
}
body.modern-theme .formio-component.margin-top-zero {
  margin-top: 0 !important;
}
body.modern-theme .formio-component.margin-top-zero.dropdown-separator-line {
  margin-top: 4px !important;
}
body.modern-theme .formio-component:is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component:not(.formio-hidden, .hide, .formio-component-hidden) {
  margin-top: 0px;
}
body.modern-theme .formio-component:is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component:not(.formio-hidden, .hide, .formio-component-hidden) ~ .formio-component {
  margin-top: 30px;
}
body.modern-theme .formio-component.formio-component-columns:has(>[class^="col-"]>.formio-component-textfield>label):has([class^="col-"] > .formio-component-button:not(.margin-top-zero,.top-space-05,.top-space,.top-space-2x,.top-space-3x)) > [class^="col-"] > .formio-component-button:not(.margin-top-zero,.top-space-05,.top-space,.top-space-2x,.top-space-3x) {
  margin-top: 29px;
}
body.modern-theme .formio-component.formio-component-checkbox:is(.hide, .formio-hidden):first-child ~ .formio-component-checkbox:not(.formio-hidden, .hide) {
  margin-top: 0px;
}
body.modern-theme .formio-component.formio-component-checkbox:is(.hide, .formio-hidden):first-child ~ .formio-component-checkbox:not(.formio-hidden, .hide) ~ .formio-component-checkbox {
  margin-top: 1rem;
}
body.modern-theme .formio-component.readonly-form-columns {
  margin-top: 0px;
}
body.modern-theme .formio-component.semibold {
  --field-font-weight: 600;
}
body.modern-theme .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert.alert-danger {
  border-radius: 6px;
  padding: 24px !important;
  background-color: #FEE7E7 !important;
  max-width: 100%;
  font-weight: 400 !important;
  font-size: 16px;
  color: #F75F5F !important;
}
body.modern-theme .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert.alert-danger p {
  font-size: 16px !important;
  color: var(--custom-text-color, var(--color-gray-800));
  margin-top: 0;
  margin-bottom: 0;
}
body.modern-theme .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert.alert-danger p span {
  font-size: 16px;
  color: var(--color-gray-800);
}
body.modern-theme .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert.alert-danger p span.text-tiny {
  font-size: 9px;
}
body.modern-theme .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert.alert-danger p span.text-tiny * {
  font-size: 9px;
}
body.modern-theme .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert.alert-danger p span.text-tiny span {
  background-color: transparent !important;
}
body.modern-theme .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert.alert-danger p span.text-small {
  font-size: 11px;
}
body.modern-theme .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert.alert-danger p span.text-small * {
  font-size: 11px;
}
body.modern-theme .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert.alert-danger p span.text-big {
  font-size: 18px;
}
body.modern-theme .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert.alert-danger p span.text-big * {
  font-size: 18px;
}
body.modern-theme .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert.alert-danger p span.text-huge {
  font-size: 24px;
}
body.modern-theme .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert.alert-danger p span.text-huge * {
  font-size: 24px;
}
body.modern-theme .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert.alert-danger p span.currency-prefixed {
  margin-inline-end: 5px;
}
body.modern-theme .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert.alert-danger p span.currency-suffixed:not(:empty) {
  margin-inline-start: 5px;
}
body.modern-theme .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert.alert-danger p:empty + p {
  margin-top: 0;
}
body.modern-theme .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert.alert-danger p:first-child {
  margin-top: 0;
}
body.modern-theme .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert.alert-danger p.correction-reason {
  --custom-text-color: #FF7233;
}
body.modern-theme .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert.alert-danger p.correction-reason span {
  color: inherit;
}
body.modern-theme .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert.alert-danger .separate-colon {
  margin-inline-end: 4px;
}
body.modern-theme .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert.alert-danger.general-rejection-reason {
  width: 100%;
  background-color: #FFF0EB !important;
}
body.modern-theme .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert.alert-danger.general-rejection-reason div:empty {
  display: none;
}
body.modern-theme .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert.alert-danger.general-rejection-reason div:last-of-type {
  margin-bottom: 0;
}
body.modern-theme .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert.alert-danger[class*="RejectionReasons"] {
  padding: 2rem !important;
  color: #F75F5F !important;
}
body.modern-theme .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert.alert-danger[class*="RejectionReasons"] div + div:not(:empty) {
  margin-top: 0.6rem;
}
body.modern-theme .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert.alert-danger[class*="documentRejectionReasons"] p + div {
  font-size: 16px;
  font-weight: 600;
}
body.modern-theme .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert.alert-danger::after {
  display: none !important;
}
body.modern-theme .formio-component .formio-errors.invalid-feedback:empty {
  display: none;
}
body.modern-theme :is(.formio-component-modal-wrapper, .formio-component) + :is(.formio-component-modal-wrapper, .formio-component) {
  margin-top: 30px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).tabbed-modern-nav {
  position: relative;
  z-index: 1030;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).tabbed-modern-nav > .formio-component-modal-wrapper > div > nav .pagination,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).tabbed-modern-nav > .formio-form > div > nav .pagination {
  border: none;
  flex-wrap: nowrap;
  overflow-x: auto;
  border-radius: 0;
  overflow-y: hidden;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).tabbed-modern-nav > .formio-component-modal-wrapper > div > nav .pagination li.page-item,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).tabbed-modern-nav > .formio-form > div > nav .pagination li.page-item {
  flex: 1;
  max-width: fit-content;
  min-width: fit-content;
  margin: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).tabbed-modern-nav > .formio-component-modal-wrapper > div > nav .pagination li.page-item.active,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).tabbed-modern-nav > .formio-form > div > nav .pagination li.page-item.active {
  position: sticky;
  inset-inline-start: 0px;
  inset-inline-end: 0px;
  z-index: 9;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).tabbed-modern-nav > .formio-component-modal-wrapper > div > nav .regs-docs-fees-cart,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).tabbed-modern-nav > .formio-form > div > nav .regs-docs-fees-cart {
  border: solid 1px rgba(70, 81, 170, 0.3);
  background-color: white;
  align-self: center;
  margin-top: 0;
  height: 36px;
  box-shadow: none;
  position: relative;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).tabbed-modern-nav > .formio-component-modal-wrapper > div > nav .regs-docs-fees-cart::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).tabbed-modern-nav > .formio-form > div > nav .regs-docs-fees-cart::before {
  border-inline-end: none;
  background-color: rgba(70, 81, 170, 0.1);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).tabbed-modern-nav > .formio-component-modal-wrapper > div > nav .regs-docs-fees-cart.open::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).tabbed-modern-nav > .formio-form > div > nav .regs-docs-fees-cart.open::before {
  border-inline-end: none;
  background-color: transparent;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).tabbed-modern-nav > .formio-component-modal-wrapper > div > nav .regs-docs-fees-drawer,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).tabbed-modern-nav > .formio-form > div > nav .regs-docs-fees-drawer {
  top: calc(50% + (var(--wizard-page-menu-height, 50px) / 2));
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-modal-wrapper-datagrid .formio-dialog.formio-dialog-theme-default,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-modal-wrapper-datagrid .formio-dialog.formio-dialog-theme-default,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-modal-wrapper-datagrid .formio-dialog.formio-dialog-theme-default,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-modal-wrapper-datagrid .formio-dialog.formio-dialog-theme-default,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-modal-wrapper-datagrid .formio-dialog.formio-dialog-theme-default,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-modal-wrapper-datagrid .formio-dialog.formio-dialog-theme-default {
  padding-top: 136px;
  transition-duration: 0.15s;
  transition-property: padding-top;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component a[href],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component a[href],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog a[href],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component a[href],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component a[href],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component a[href] {
  color: var(--color-primary-600);
  text-decoration: underline;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component a[href]:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component a[href]:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog a[href]:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component a[href]:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component a[href]:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component a[href]:hover {
  text-decoration: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component :where(label, label.col-form-label),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component :where(label, label.col-form-label),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog :where(label, label.col-form-label),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component :where(label, label.col-form-label),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component :where(label, label.col-form-label),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component :where(label, label.col-form-label) {
  color: rgba(27, 35, 49, 0.7);
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 0px;
  line-height: 19px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component :where(label, label.col-form-label) :where(input, select),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component :where(label, label.col-form-label) :where(input, select),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog :where(label, label.col-form-label) :where(input, select),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component :where(label, label.col-form-label) :where(input, select),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component :where(label, label.col-form-label) :where(input, select),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component :where(label, label.col-form-label) :where(input, select) {
  border-radius: 6px !important;
  border-color: transparent;
  display: block;
  width: 100%;
  margin-top: 10px;
  padding: 2px 4px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component :where(label, label.col-form-label) :where(input, select) + .formio-button-reset,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component :where(label, label.col-form-label) :where(input, select) + .formio-button-reset,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog :where(label, label.col-form-label) :where(input, select) + .formio-button-reset,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component :where(label, label.col-form-label) :where(input, select) + .formio-button-reset,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component :where(label, label.col-form-label) :where(input, select) + .formio-button-reset,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component :where(label, label.col-form-label) :where(input, select) + .formio-button-reset {
  color: transparent;
  font-size: 12px;
  font-weight: 400;
  padding: 0;
  background: transparent;
  position: absolute;
  inset-inline-end: 0;
  bottom: 6px;
  height: auto;
  line-height: 1;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component :where(label, label.col-form-label) :where(input, select) + .formio-button-reset::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component :where(label, label.col-form-label) :where(input, select) + .formio-button-reset::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog :where(label, label.col-form-label) :where(input, select) + .formio-button-reset::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component :where(label, label.col-form-label) :where(input, select) + .formio-button-reset::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component :where(label, label.col-form-label) :where(input, select) + .formio-button-reset::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component :where(label, label.col-form-label) :where(input, select) + .formio-button-reset::before {
  font-family: var(--fontawesome-font);
  font-weight: 300;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  content: "\f057";
  color: rgba(70, 81, 170, 0.4);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component :where(label, label.col-form-label).radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component :where(label, label.col-form-label).radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog :where(label, label.col-form-label).radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component :where(label, label.col-form-label).radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component :where(label, label.col-form-label).radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component :where(label, label.col-form-label).radio {
  padding-inline-start: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component :where(label, label.col-form-label).field-required::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component :where(label, label.col-form-label).field-required::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog :where(label, label.col-form-label).field-required::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component :where(label, label.col-form-label).field-required::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component :where(label, label.col-form-label).field-required::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component :where(label, label.col-form-label).field-required::after {
  content: " ";
  display: inline-block;
  background-color: rgba(255, 0, 59, 0.7);
  vertical-align: unset;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  position: relative;
  top: -1px;
  inset-inline-start: 2px;
  min-width: 6px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component :where(label, label.col-form-label).field-required i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component :where(label, label.col-form-label).field-required i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog :where(label, label.col-form-label).field-required i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component :where(label, label.col-form-label).field-required i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component :where(label, label.col-form-label).field-required i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component :where(label, label.col-form-label).field-required i {
  margin-inline-start: 11px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component :where(label, label.col-form-label).field-required i::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component :where(label, label.col-form-label).field-required i::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog :where(label, label.col-form-label).field-required i::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component :where(label, label.col-form-label).field-required i::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component :where(label, label.col-form-label).field-required i::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component :where(label, label.col-form-label).field-required i::before {
  margin-inline-start: 3px !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component :where(label, label.col-form-label).field-required.control-label--hidden,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component :where(label, label.col-form-label).field-required.control-label--hidden,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog :where(label, label.col-form-label).field-required.control-label--hidden,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component :where(label, label.col-form-label).field-required.control-label--hidden,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component :where(label, label.col-form-label).field-required.control-label--hidden,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component :where(label, label.col-form-label).field-required.control-label--hidden {
  position: absolute;
  top: calc(var(--field-height) / 2 + var(--custom-top-space, 0px));
  inset-inline-end: 0;
  right: initial;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component :where(label, label.col-form-label).field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component :where(label, label.col-form-label).field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog :where(label, label.col-form-label).field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component :where(label, label.col-form-label).field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component :where(label, label.col-form-label).field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component :where(label, label.col-form-label).field-required.control-label--hidden::after {
  inset-inline-end: 10px;
  inset-inline-start: initial;
  position: absolute;
  top: -3px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component :where(label, label.col-form-label) i.fa-question-circle,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component :where(label, label.col-form-label) i.fa-question-circle,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog :where(label, label.col-form-label) i.fa-question-circle,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component :where(label, label.col-form-label) i.fa-question-circle,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component :where(label, label.col-form-label) i.fa-question-circle,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component :where(label, label.col-form-label) i.fa-question-circle {
  margin-top: -4px;
  margin-inline-end: -31px;
  transform: translateY(1px);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component :where(label, label.col-form-label):not(.sr-only),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component :where(label, label.col-form-label):not(.sr-only),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog :where(label, label.col-form-label):not(.sr-only),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component :where(label, label.col-form-label):not(.sr-only),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component :where(label, label.col-form-label):not(.sr-only),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component :where(label, label.col-form-label):not(.sr-only) {
  position: relative;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component input.form-control:where([type="text"], [type="search"], [type="phoneNumber"], [type="email"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component input.form-control:where([type="text"], [type="search"], [type="phoneNumber"], [type="email"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog input.form-control:where([type="text"], [type="search"], [type="phoneNumber"], [type="email"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component input.form-control:where([type="text"], [type="search"], [type="phoneNumber"], [type="email"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component input.form-control:where([type="text"], [type="search"], [type="phoneNumber"], [type="email"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component input.form-control:where([type="text"], [type="search"], [type="phoneNumber"], [type="email"]) {
  height: var(--field-height);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component input.form-control:where([type="text"], [type="search"], [type="phoneNumber"], [type="email"]):where(:hover, :focus),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component input.form-control:where([type="text"], [type="search"], [type="phoneNumber"], [type="email"]):where(:hover, :focus),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog input.form-control:where([type="text"], [type="search"], [type="phoneNumber"], [type="email"]):where(:hover, :focus),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component input.form-control:where([type="text"], [type="search"], [type="phoneNumber"], [type="email"]):where(:hover, :focus),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component input.form-control:where([type="text"], [type="search"], [type="phoneNumber"], [type="email"]):where(:hover, :focus),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component input.form-control:where([type="text"], [type="search"], [type="phoneNumber"], [type="email"]):where(:hover, :focus) {
  outline: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component input.form-control:where([type="text"], [type="search"], [type="phoneNumber"], [type="email"]):disabled,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component input.form-control:where([type="text"], [type="search"], [type="phoneNumber"], [type="email"]):disabled,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog input.form-control:where([type="text"], [type="search"], [type="phoneNumber"], [type="email"]):disabled,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component input.form-control:where([type="text"], [type="search"], [type="phoneNumber"], [type="email"]):disabled,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component input.form-control:where([type="text"], [type="search"], [type="phoneNumber"], [type="email"]):disabled,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component input.form-control:where([type="text"], [type="search"], [type="phoneNumber"], [type="email"]):disabled {
  background-color: var(--color-gray-100);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component .fa-question-circle::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component .fa-question-circle::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog .fa-question-circle::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .fa-question-circle::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component .fa-question-circle::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component .fa-question-circle::before {
  font-weight: 400;
  font-size: 15px;
  color: var(--color-primary-600);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component .form-text.text-muted:not(:empty),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component .form-text.text-muted:not(:empty),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog .form-text.text-muted:not(:empty),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .form-text.text-muted:not(:empty),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component .form-text.text-muted:not(:empty),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component .form-text.text-muted:not(:empty) {
  margin-top: 6px;
  font-size: 12px;
  text-align: start;
  display: block;
  line-height: 1.2;
  color: var(--color-primary-600);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component .form-text.text-muted:not(:empty) + .formio-errors:not(:empty),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component .form-text.text-muted:not(:empty) + .formio-errors:not(:empty),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog .form-text.text-muted:not(:empty) + .formio-errors:not(:empty),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .form-text.text-muted:not(:empty) + .formio-errors:not(:empty),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component .form-text.text-muted:not(:empty) + .formio-errors:not(:empty),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component .form-text.text-muted:not(:empty) + .formio-errors:not(:empty) {
  margin-top: 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.has-error, .formio-error-wrapper),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.has-error, .formio-error-wrapper),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.has-error, .formio-error-wrapper),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.has-error, .formio-error-wrapper),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.has-error, .formio-error-wrapper),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.has-error, .formio-error-wrapper) {
  border-radius: 6px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.has-error, .formio-error-wrapper) .formio-errors .form-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.has-error, .formio-error-wrapper) .formio-errors .form-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.has-error, .formio-error-wrapper) .formio-errors .form-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.has-error, .formio-error-wrapper) .formio-errors .form-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.has-error, .formio-error-wrapper) .formio-errors .form-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.has-error, .formio-error-wrapper) .formio-errors .form-text {
  font-weight: 400;
  margin-top: 6px;
  margin-bottom: 0;
  font-size: 12px;
  text-align: start;
  display: block;
  line-height: 1.2;
  white-space: break-spaces;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.has-error, .formio-error-wrapper) .formio-errors .form-text.error,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.has-error, .formio-error-wrapper) .formio-errors .form-text.error,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.has-error, .formio-error-wrapper) .formio-errors .form-text.error,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.has-error, .formio-error-wrapper) .formio-errors .form-text.error,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.has-error, .formio-error-wrapper) .formio-errors .form-text.error,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.has-error, .formio-error-wrapper) .formio-errors .form-text.error {
  color: rgba(255, 0, 59, 0.7);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.has-error, .formio-error-wrapper):focus-within,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.has-error, .formio-error-wrapper):focus-within,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.has-error, .formio-error-wrapper):focus-within,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.has-error, .formio-error-wrapper):focus-within,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.has-error, .formio-error-wrapper):focus-within,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.has-error, .formio-error-wrapper):focus-within {
  animation: hglightComp 1s ease-out 0.15s;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component .formio-error-wrapper.formio-component-radio.radio-switch .form-radio.radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component .formio-error-wrapper.formio-component-radio.radio-switch .form-radio.radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog .formio-error-wrapper.formio-component-radio.radio-switch .form-radio.radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-error-wrapper.formio-component-radio.radio-switch .form-radio.radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component .formio-error-wrapper.formio-component-radio.radio-switch .form-radio.radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component .formio-error-wrapper.formio-component-radio.radio-switch .form-radio.radio {
  border-color: rgba(255, 0, 59, 0.7);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component .formio-error-wrapper:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component .formio-error-wrapper:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog .formio-error-wrapper:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-error-wrapper:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component .formio-error-wrapper:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component .formio-error-wrapper:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .form-control {
  border-color: rgba(255, 0, 59, 0.7);
  --fields-border-color: rgba(255, 0, 59, 0.7);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component .formio-error-wrapper.formio-component-select .form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component .formio-error-wrapper.formio-component-select .form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog .formio-error-wrapper.formio-component-select .form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-error-wrapper.formio-component-select .form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component .formio-error-wrapper.formio-component-select .form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component .formio-error-wrapper.formio-component-select .form-control {
  border-color: rgba(255, 0, 59, 0.7);
  --fields-border-color: rgba(255, 0, 59, 0.7);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component .formio-error-wrapper:is(.has-suffix, .has-prefix) :is(.form-control, .input-group-append, .input-group-prepend),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component .formio-error-wrapper:is(.has-suffix, .has-prefix) :is(.form-control, .input-group-append, .input-group-prepend),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog .formio-error-wrapper:is(.has-suffix, .has-prefix) :is(.form-control, .input-group-append, .input-group-prepend),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-error-wrapper:is(.has-suffix, .has-prefix) :is(.form-control, .input-group-append, .input-group-prepend),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component .formio-error-wrapper:is(.has-suffix, .has-prefix) :is(.form-control, .input-group-append, .input-group-prepend),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component .formio-error-wrapper:is(.has-suffix, .has-prefix) :is(.form-control, .input-group-append, .input-group-prepend) {
  border-color: rgba(255, 0, 59, 0.7) !important;
  --fields-border-color: rgba(255, 0, 59, 0.7);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.has-prefix, .has-suffix) :is(.input-group-prepend, .input-group-append),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.has-prefix, .has-suffix) :is(.input-group-prepend, .input-group-append),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.has-prefix, .has-suffix) :is(.input-group-prepend, .input-group-append),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.has-prefix, .has-suffix) :is(.input-group-prepend, .input-group-append),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.has-prefix, .has-suffix) :is(.input-group-prepend, .input-group-append),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.has-prefix, .has-suffix) :is(.input-group-prepend, .input-group-append) {
  transition: border-color ease-in-out 0.15s;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.has-prefix, .has-suffix):has(.form-control:focus) :is(.input-group-prepend, .input-group-append),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.has-prefix, .has-suffix):has(.form-control:focus) :is(.input-group-prepend, .input-group-append),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.has-prefix, .has-suffix):has(.form-control:focus) :is(.input-group-prepend, .input-group-append),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.has-prefix, .has-suffix):has(.form-control:focus) :is(.input-group-prepend, .input-group-append),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.has-prefix, .has-suffix):has(.form-control:focus) :is(.input-group-prepend, .input-group-append),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.has-prefix, .has-suffix):has(.form-control:focus) :is(.input-group-prepend, .input-group-append) {
  border-color: var(--color-gray-400);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component .field-wrapper,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component .field-wrapper,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog .field-wrapper,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .field-wrapper,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component .field-wrapper,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component .field-wrapper {
  align-items: center;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component .field-wrapper label.col-form-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component .field-wrapper label.col-form-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog .field-wrapper label.col-form-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .field-wrapper label.col-form-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component .field-wrapper label.col-form-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component .field-wrapper label.col-form-label {
  margin-bottom: 0 !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) input.form-control:where([type="text"], [type="phoneNumber"], [type="email"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) input.form-control:where([type="text"], [type="phoneNumber"], [type="email"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) input.form-control:where([type="text"], [type="phoneNumber"], [type="email"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) input.form-control:where([type="text"], [type="phoneNumber"], [type="email"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) input.form-control:where([type="text"], [type="phoneNumber"], [type="email"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) input.form-control:where([type="text"], [type="phoneNumber"], [type="email"]) {
  border: solid 1px var(--fields-border-color, var(--color-gray-300));
  border-radius: 6px !important;
  font-weight: 400;
  text-align: start;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) input.form-control:where([type="text"], [type="phoneNumber"], [type="email"]):where(:hover, :focus),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) input.form-control:where([type="text"], [type="phoneNumber"], [type="email"]):where(:hover, :focus),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) input.form-control:where([type="text"], [type="phoneNumber"], [type="email"]):where(:hover, :focus),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) input.form-control:where([type="text"], [type="phoneNumber"], [type="email"]):where(:hover, :focus),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) input.form-control:where([type="text"], [type="phoneNumber"], [type="email"]):where(:hover, :focus),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) input.form-control:where([type="text"], [type="phoneNumber"], [type="email"]):where(:hover, :focus) {
  outline: none !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) input.form-control:where([type="text"], [type="phoneNumber"], [type="email"]):focus,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) input.form-control:where([type="text"], [type="phoneNumber"], [type="email"]):focus,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) input.form-control:where([type="text"], [type="phoneNumber"], [type="email"]):focus,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) input.form-control:where([type="text"], [type="phoneNumber"], [type="email"]):focus,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) input.form-control:where([type="text"], [type="phoneNumber"], [type="email"]):focus,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) input.form-control:where([type="text"], [type="phoneNumber"], [type="email"]):focus {
  border-color: var(--color-gray-400);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) input.form-control + .form-text.text-right,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) input.form-control + .form-text.text-right,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) input.form-control + .form-text.text-right,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) input.form-control + .form-text.text-right,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) input.form-control + .form-text.text-right,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) input.form-control + .form-text.text-right {
  font-size: 1.2rem;
  padding-top: 4px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).strong,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).strong,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).strong,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).strong,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).strong,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).strong {
  --field-font-weight: 700;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group {
  height: var(--field-height);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group > div {
  border: solid 1px var(--fields-border-color, var(--color-gray-300));
  background-color: white !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group > div.input-group-prepend,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group > div.input-group-prepend,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group > div.input-group-prepend,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group > div.input-group-prepend,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group > div.input-group-prepend,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group > div.input-group-prepend {
  border-radius: 6px 0 0 6px !important;
  color: var(--color-gray-800);
  font-weight: 600;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group > div.input-group-prepend + input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group > div.input-group-prepend + input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group > div.input-group-prepend + input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group > div.input-group-prepend + input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group > div.input-group-prepend + input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group > div.input-group-prepend + input.form-control {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-inline-start: none !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group > div.input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group > div.input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group > div.input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group > div.input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group > div.input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group > div.input-group-append {
  border-radius: 0 6px 6px 0 !important;
  color: var(--color-gray-800);
  font-weight: 600;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group input.form-control:first-child:not(:last-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group input.form-control:first-child:not(:last-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group input.form-control:first-child:not(:last-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group input.form-control:first-child:not(:last-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group input.form-control:first-child:not(:last-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group input.form-control:first-child:not(:last-child) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-inline-end: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group input.form-control:not(:first-child):not(:last-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group input.form-control:not(:first-child):not(:last-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group input.form-control:not(:first-child):not(:last-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group input.form-control:not(:first-child):not(:last-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group input.form-control:not(:first-child):not(:last-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group input.form-control:not(:first-child):not(:last-child) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-inline-end: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group.formio-multiple-mask-container,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group.formio-multiple-mask-container,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group.formio-multiple-mask-container,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group.formio-multiple-mask-container,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group.formio-multiple-mask-container,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group.formio-multiple-mask-container {
  display: flex;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group.formio-multiple-mask-container .formio-multiple-mask-select,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group.formio-multiple-mask-container .formio-multiple-mask-select,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group.formio-multiple-mask-container .formio-multiple-mask-select,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group.formio-multiple-mask-container .formio-multiple-mask-select,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group.formio-multiple-mask-container .formio-multiple-mask-select,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group.formio-multiple-mask-container .formio-multiple-mask-select {
  width: fit-content;
  max-width: 50%;
  height: 40px !important;
  border-radius: 6px 0 0 6px !important;
  border-inline-end: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group.formio-multiple-mask-container .formio-multiple-mask-select + .formio-multiple-mask-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group.formio-multiple-mask-container .formio-multiple-mask-select + .formio-multiple-mask-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group.formio-multiple-mask-container .formio-multiple-mask-select + .formio-multiple-mask-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group.formio-multiple-mask-container .formio-multiple-mask-select + .formio-multiple-mask-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group.formio-multiple-mask-container .formio-multiple-mask-select + .formio-multiple-mask-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group.formio-multiple-mask-container .formio-multiple-mask-select + .formio-multiple-mask-input {
  border-radius: 0 6px 6px 0 !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group.formio-multiple-mask-container .formio-multiple-mask-select:where(:hover, :focus, :active),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group.formio-multiple-mask-container .formio-multiple-mask-select:where(:hover, :focus, :active),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group.formio-multiple-mask-container .formio-multiple-mask-select:where(:hover, :focus, :active),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group.formio-multiple-mask-container .formio-multiple-mask-select:where(:hover, :focus, :active),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group.formio-multiple-mask-container .formio-multiple-mask-select:where(:hover, :focus, :active),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) .input-group.formio-multiple-mask-container .formio-multiple-mask-select:where(:hover, :focus, :active) {
  box-shadow: none;
  outline: none;
  background-color: rgba(70, 81, 170, 0.05);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style input.form-control:is([type="text"], [type="phoneNumber"], [type="email"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style input.form-control:is([type="text"], [type="phoneNumber"], [type="email"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style input.form-control:is([type="text"], [type="phoneNumber"], [type="email"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style input.form-control:is([type="text"], [type="phoneNumber"], [type="email"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style input.form-control:is([type="text"], [type="phoneNumber"], [type="email"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style input.form-control:is([type="text"], [type="phoneNumber"], [type="email"]) {
  border: none !important;
  background-color: transparent !important;
  border-radius: 0px !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style.required label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style.required label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style.required label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style.required label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style.required label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style.required label.field-required.control-label--hidden::after {
  top: -1px;
  inset-inline-end: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style.required label.field-required.control-label--hidden + div input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style.required label.field-required.control-label--hidden + div input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style.required label.field-required.control-label--hidden + div input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style.required label.field-required.control-label--hidden + div input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style.required label.field-required.control-label--hidden + div input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style.required label.field-required.control-label--hidden + div input.form-control {
  padding-inline-end: 16px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style:is(.has-prefix, .has-suffix) :is(.input-group-prepend, .input-group-append),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style:is(.has-prefix, .has-suffix) :is(.input-group-prepend, .input-group-append),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style:is(.has-prefix, .has-suffix) :is(.input-group-prepend, .input-group-append),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style:is(.has-prefix, .has-suffix) :is(.input-group-prepend, .input-group-append),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style:is(.has-prefix, .has-suffix) :is(.input-group-prepend, .input-group-append),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style:is(.has-prefix, .has-suffix) :is(.input-group-prepend, .input-group-append) {
  border: none;
  --start-padding: 14px;
  --end-padding: 14px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style:is(.has-prefix, .has-suffix) :is(.input-group-prepend, .input-group-append) .input-group-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style:is(.has-prefix, .has-suffix) :is(.input-group-prepend, .input-group-append) .input-group-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style:is(.has-prefix, .has-suffix) :is(.input-group-prepend, .input-group-append) .input-group-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style:is(.has-prefix, .has-suffix) :is(.input-group-prepend, .input-group-append) .input-group-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style:is(.has-prefix, .has-suffix) :is(.input-group-prepend, .input-group-append) .input-group-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style:is(.has-prefix, .has-suffix) :is(.input-group-prepend, .input-group-append) .input-group-text {
  padding-inline-start: var(--start-padding);
  padding-inline-end: var(--end-padding);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style:is(.has-prefix, .has-suffix) .input-group-prepend,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style:is(.has-prefix, .has-suffix) .input-group-prepend,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style:is(.has-prefix, .has-suffix) .input-group-prepend,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style:is(.has-prefix, .has-suffix) .input-group-prepend,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style:is(.has-prefix, .has-suffix) .input-group-prepend,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style:is(.has-prefix, .has-suffix) .input-group-prepend {
  --start-padding: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style:is(.has-prefix, .has-suffix) .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style:is(.has-prefix, .has-suffix) .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style:is(.has-prefix, .has-suffix) .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style:is(.has-prefix, .has-suffix) .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style:is(.has-prefix, .has-suffix) .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).value-label-style:is(.has-prefix, .has-suffix) .input-group-append {
  --end-padding: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).field-plus-button input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).field-plus-button input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).field-plus-button input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).field-plus-button input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).field-plus-button input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).field-plus-button input.form-control {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).has-error .input-group > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).has-error .input-group > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).has-error .input-group > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).has-error .input-group > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).has-error .input-group > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).has-error .input-group > div {
  border-color: rgba(255, 0, 59, 0.3);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).has-error .input-group.formio-multiple-mask-container .formio-multiple-mask-select,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).has-error .input-group.formio-multiple-mask-container .formio-multiple-mask-select,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).has-error .input-group.formio-multiple-mask-container .formio-multiple-mask-select,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).has-error .input-group.formio-multiple-mask-container .formio-multiple-mask-select,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).has-error .input-group.formio-multiple-mask-container .formio-multiple-mask-select,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).has-error .input-group.formio-multiple-mask-container .formio-multiple-mask-select {
  border-color: rgba(255, 0, 59, 0.3) !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).has-error:focus-within :is(.input-group-append, .input-group-prepend),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).has-error:focus-within :is(.input-group-append, .input-group-prepend),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).has-error:focus-within :is(.input-group-append, .input-group-prepend),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).has-error:focus-within :is(.input-group-append, .input-group-prepend),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).has-error:focus-within :is(.input-group-append, .input-group-prepend),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).has-error:focus-within :is(.input-group-append, .input-group-prepend) {
  border-color: rgba(255, 0, 59, 0.3);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).has-error input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).has-error input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).has-error input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).has-error input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).has-error input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime).has-error input.form-control {
  border-color: rgba(255, 0, 59, 0.3) !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) label.col-form-label:not(:first-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) label.col-form-label:not(:first-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) label.col-form-label:not(:first-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) label.col-form-label:not(:first-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) label.col-form-label:not(:first-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) label.col-form-label:not(:first-child) {
  margin-bottom: 0;
  margin-top: 6px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-day input.formio-select-autocomplete-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-day input.formio-select-autocomplete-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-day input.formio-select-autocomplete-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-day input.formio-select-autocomplete-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-day input.formio-select-autocomplete-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-day input.formio-select-autocomplete-input {
  position: absolute;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-day .row .col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-day .row .col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-day .row .col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-day .row .col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-day .row .col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-day .row .col {
  flex: 1;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-day .row .col .form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-day .row .col .form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-day .row .col .form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-day .row .col .form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-day .row .col .form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-day .row .col .form-control {
  border-radius: 6px !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .formio-choices .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .formio-choices .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .formio-choices .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .formio-choices .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .formio-choices .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .formio-choices .selection.dropdown {
  border: solid 1px var(--fields-border-color, var(--color-gray-300));
  border-radius: 6px !important;
  height: var(--field-height);
  display: flex;
  align-items: center;
  padding-inline-end: 36px;
  padding-inline-start: 14px;
  padding-block: 6px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .formio-choices .selection.dropdown:where(:hover, :focus),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .formio-choices .selection.dropdown:where(:hover, :focus),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .formio-choices .selection.dropdown:where(:hover, :focus),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .formio-choices .selection.dropdown:where(:hover, :focus),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .formio-choices .selection.dropdown:where(:hover, :focus),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .formio-choices .selection.dropdown:where(:hover, :focus) {
  outline: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .formio-choices .selection.dropdown[disabled],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .formio-choices .selection.dropdown[disabled],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .formio-choices .selection.dropdown[disabled],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .formio-choices .selection.dropdown[disabled],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .formio-choices .selection.dropdown[disabled],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .formio-choices .selection.dropdown[disabled] {
  background-color: var(--color-gray-100);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .formio-choices .selection.dropdown[disabled] .choices__item--selectable,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .formio-choices .selection.dropdown[disabled] .choices__item--selectable,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .formio-choices .selection.dropdown[disabled] .choices__item--selectable,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .formio-choices .selection.dropdown[disabled] .choices__item--selectable,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .formio-choices .selection.dropdown[disabled] .choices__item--selectable,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .formio-choices .selection.dropdown[disabled] .choices__item--selectable {
  padding-inline-end: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .formio-choices.is-open .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .formio-choices.is-open .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .formio-choices.is-open .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .formio-choices.is-open .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .formio-choices.is-open .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .formio-choices.is-open .selection.dropdown {
  border-radius: 6px 6px 0 0 !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .formio-choices.is-open.is-flipped .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .formio-choices.is-open.is-flipped .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .formio-choices.is-open.is-flipped .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .formio-choices.is-open.is-flipped .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .formio-choices.is-open.is-flipped .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .formio-choices.is-open.is-flipped .selection.dropdown {
  border-radius: 0 0 6px 6px !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .formio-choices.is-open.is-flipped .choices__list.choices__list--dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .formio-choices.is-open.is-flipped .choices__list.choices__list--dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .formio-choices.is-open.is-flipped .choices__list.choices__list--dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .formio-choices.is-open.is-flipped .choices__list.choices__list--dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .formio-choices.is-open.is-flipped .choices__list.choices__list--dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .formio-choices.is-open.is-flipped .choices__list.choices__list--dropdown {
  border-radius: 6px 6px 0 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .formio-choices.is-open .choices__list.choices__list--dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .formio-choices.is-open .choices__list.choices__list--dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .formio-choices.is-open .choices__list.choices__list--dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .formio-choices.is-open .choices__list.choices__list--dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .formio-choices.is-open .choices__list.choices__list--dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .formio-choices.is-open .choices__list.choices__list--dropdown {
  border-color: var(--color-gray-400);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .formio-choices.is-focused .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .formio-choices.is-focused .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .formio-choices.is-focused .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .formio-choices.is-focused .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .formio-choices.is-focused .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .formio-choices.is-focused .selection.dropdown {
  border-color: var(--color-gray-400);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .formio-choices::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .formio-choices::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .formio-choices::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .formio-choices::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .formio-choices::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .formio-choices::after {
  color: rgba(70, 81, 170, 0.4);
  content: "\F078";
  font-family: var(--fontawesome-font);
  font-weight: 300;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  font-size: 16px;
  width: auto;
  border: none;
  margin-top: 0;
  height: 8px;
  line-height: 7px;
  top: calc(50% - 4px);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .formio-choices:hover::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .formio-choices:hover::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .formio-choices:hover::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .formio-choices:hover::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .formio-choices:hover::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .formio-choices:hover::after {
  color: var(--color-primary-600);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .formio-choices .choices__list.choices__list--dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown {
  border: solid 1px var(--color-gray-300);
  border-radius: 0 0 6px 6px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown input.choices__input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown input.choices__input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .formio-choices .choices__list.choices__list--dropdown input.choices__input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown input.choices__input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown input.choices__input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown input.choices__input {
  border-radius: 0 !important;
  padding: 10px 14px;
  font-size: 12px;
  border-bottom-color: var(--color-gray-300);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown input.choices__input:placeholder-shown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown input.choices__input:placeholder-shown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .formio-choices .choices__list.choices__list--dropdown input.choices__input:placeholder-shown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown input.choices__input:placeholder-shown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown input.choices__input:placeholder-shown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown input.choices__input:placeholder-shown {
  opacity: 0.5;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice {
  padding: 10px 14px;
  font-size: 13px;
  font-weight: 400;
  color: var(--color-gray-800);
  word-break: break-word;
  white-space: break-spaces;
  text-align: start;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice:where(.is-selected, :hover),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice:where(.is-selected, :hover),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice:where(.is-selected, :hover),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice:where(.is-selected, :hover),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice:where(.is-selected, :hover),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice:where(.is-selected, :hover) {
  background-color: rgba(70, 81, 170, 0.05);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice span {
  line-height: 1.2;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice span mark,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice span mark,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice span mark,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice span mark,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice span mark,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice span mark {
  background-color: #FEFF00;
  padding: 0;
  display: inline-block;
  line-height: 1.1;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice > span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice > span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice > span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice > span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice > span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice > span {
  display: flex;
  gap: 12px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice > span span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice > span span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice > span span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice > span span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice > span span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice > span span {
  min-width: 32px;
  flex: 1;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice > span span + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice > span span + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice > span span + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice > span span + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice > span span + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice > span span + span {
  width: auto;
  flex: 10;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice > span.value > span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice > span.value > span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice > span.value > span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice > span.value > span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice > span.value > span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice > span.value > span {
  width: 100%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice .tooltip,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice .tooltip,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice .tooltip,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice .tooltip,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice .tooltip,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice .tooltip {
  display: none !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice [ref="tooltip"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice [ref="tooltip"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice [ref="tooltip"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice [ref="tooltip"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice [ref="tooltip"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice [ref="tooltip"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice [ref="tooltip"]::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice [ref="tooltip"]::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice [ref="tooltip"]::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice [ref="tooltip"]::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice [ref="tooltip"]::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice [ref="tooltip"]::after {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.is-highlighted,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.is-highlighted,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.is-highlighted,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.is-highlighted,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.is-highlighted,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.is-highlighted {
  background-color: var(--color-gray-75);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__list,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__list,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__list,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__list,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__list,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__list {
  max-height: clamp(200px, 500px, calc(50vh - 110px));
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .formio-choices .choices__list--single,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .formio-choices .choices__list--single,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .formio-choices .choices__list--single,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .formio-choices .choices__list--single,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .formio-choices .choices__list--single,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .formio-choices .choices__list--single {
  padding-inline-start: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .formio-choices .choices__list--single .choices__item .value:not(:last-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .formio-choices .choices__list--single .choices__item .value:not(:last-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .formio-choices .choices__list--single .choices__item .value:not(:last-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .formio-choices .choices__list--single .choices__item .value:not(:last-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .formio-choices .choices__list--single .choices__item .value:not(:last-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .formio-choices .choices__list--single .choices__item .value:not(:last-child) {
  padding-inline-end: 15px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .formio-choices .choices__list--single .choices__item .value[ref="tooltip"]:hover::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .formio-choices .choices__list--single .choices__item .value[ref="tooltip"]:hover::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .formio-choices .choices__list--single .choices__item .value[ref="tooltip"]:hover::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .formio-choices .choices__list--single .choices__item .value[ref="tooltip"]:hover::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .formio-choices .choices__list--single .choices__item .value[ref="tooltip"]:hover::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .formio-choices .choices__list--single .choices__item .value[ref="tooltip"]:hover::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .formio-choices .choices__list--single .choices__item .value[ref="tooltip"]:hover::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .formio-choices .choices__list--single .choices__item .value[ref="tooltip"]:hover::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .formio-choices .choices__list--single .choices__item .value[ref="tooltip"]:hover::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .formio-choices .choices__list--single .choices__item .value[ref="tooltip"]:hover::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .formio-choices .choices__list--single .choices__item .value[ref="tooltip"]:hover::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .formio-choices .choices__list--single .choices__item .value[ref="tooltip"]:hover::before {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .formio-choices .choices__list--single .choices__item--selectable,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .formio-choices .choices__list--single .choices__item--selectable,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .formio-choices .choices__list--single .choices__item--selectable,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .formio-choices .choices__list--single .choices__item--selectable,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .formio-choices .choices__list--single .choices__item--selectable,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .formio-choices .choices__list--single .choices__item--selectable {
  padding-right: initial;
  padding-inline-end: 14px;
  position: relative;
  line-height: 1.5;
  text-align: start;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .formio-choices .choices__list--single .choices__item--selectable span.key,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .formio-choices .choices__list--single .choices__item--selectable span.key,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .formio-choices .choices__list--single .choices__item--selectable span.key,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .formio-choices .choices__list--single .choices__item--selectable span.key,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .formio-choices .choices__list--single .choices__item--selectable span.key,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .formio-choices .choices__list--single .choices__item--selectable span.key {
  font-size: 16px;
  font-weight: 600;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .formio-choices .choices__list--single .choices__item--selectable span.key + .value,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .formio-choices .choices__list--single .choices__item--selectable span.key + .value,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .formio-choices .choices__list--single .choices__item--selectable span.key + .value,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .formio-choices .choices__list--single .choices__item--selectable span.key + .value,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .formio-choices .choices__list--single .choices__item--selectable span.key + .value,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .formio-choices .choices__list--single .choices__item--selectable span.key + .value {
  padding-inline-start: 6px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .formio-choices .choices__list--single .choices__item--selectable .choices__button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .formio-choices .choices__list--single .choices__item--selectable .choices__button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .formio-choices .choices__list--single .choices__item--selectable .choices__button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .formio-choices .choices__list--single .choices__item--selectable .choices__button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .formio-choices .choices__list--single .choices__item--selectable .choices__button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .formio-choices .choices__list--single .choices__item--selectable .choices__button {
  inset-inline-end: 0px !important;
  margin-right: initial;
  margin-left: initial;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .formio-choices.is-disabled .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .formio-choices.is-disabled .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .formio-choices.is-disabled .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .formio-choices.is-disabled .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .formio-choices.is-disabled .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .formio-choices.is-disabled .selection.dropdown {
  background-color: var(--color-gray-100);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .formio-choices.is-disabled::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .formio-choices.is-disabled::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .formio-choices.is-disabled::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .formio-choices.is-disabled::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .formio-choices.is-disabled::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .formio-choices.is-disabled::after {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select input.formio-select-autocomplete-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select input.formio-select-autocomplete-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select input.formio-select-autocomplete-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select input.formio-select-autocomplete-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select input.formio-select-autocomplete-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select input.formio-select-autocomplete-input {
  position: absolute;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select.has-error .formio-choices .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select.has-error .formio-choices .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select.has-error .formio-choices .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select.has-error .formio-choices .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select.has-error .formio-choices .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select.has-error .formio-choices .selection.dropdown {
  border-color: rgba(255, 0, 59, 0.3) !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select.has-error .formio-choices .choices__list.choices__list--dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select.has-error .formio-choices .choices__list.choices__list--dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select.has-error .formio-choices .choices__list.choices__list--dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select.has-error .formio-choices .choices__list.choices__list--dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select.has-error .formio-choices .choices__list.choices__list--dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select.has-error .formio-choices .choices__list.choices__list--dropdown {
  border-color: rgba(255, 0, 59, 0.3);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select:not(.formio-modified) .control-label--hidden.field-required,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select:not(.formio-modified) .control-label--hidden.field-required,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select:not(.formio-modified) .control-label--hidden.field-required,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select:not(.formio-modified) .control-label--hidden.field-required,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select:not(.formio-modified) .control-label--hidden.field-required,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select:not(.formio-modified) .control-label--hidden.field-required {
  inset-inline-end: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown {
  min-height: 40px;
  height: auto;
  flex-wrap: wrap;
  padding-inline-end: 40px;
  gap: 6px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__input {
  flex: 1;
  background: transparent;
  height: 28px !important;
  margin-bottom: 0;
  min-width: 50px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple {
  display: contents;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple:empty,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple:empty,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple:empty,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple:empty,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple:empty,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple:empty {
  flex: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple :where(.choices__item--selectable, .choices__item.is-highlighted),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple :where(.choices__item--selectable, .choices__item.is-highlighted),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple :where(.choices__item--selectable, .choices__item.is-highlighted),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple :where(.choices__item--selectable, .choices__item.is-highlighted),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple :where(.choices__item--selectable, .choices__item.is-highlighted),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple :where(.choices__item--selectable, .choices__item.is-highlighted) {
  overflow: hidden;
  max-width: calc(100% - 28px);
  min-width: 0px;
  position: relative;
  background-color: #EDEEF7;
  color: var(--color-primary-600);
  font-weight: 400;
  border-radius: 6px;
  padding-block: 4px;
  padding-inline-start: 7px;
  padding-inline-end: 28px;
  border-color: rgba(70, 81, 170, 0.2);
  margin: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple :where(.choices__item--selectable, .choices__item.is-highlighted) > span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple :where(.choices__item--selectable, .choices__item.is-highlighted) > span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple :where(.choices__item--selectable, .choices__item.is-highlighted) > span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple :where(.choices__item--selectable, .choices__item.is-highlighted) > span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple :where(.choices__item--selectable, .choices__item.is-highlighted) > span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple :where(.choices__item--selectable, .choices__item.is-highlighted) > span {
  text-overflow: ellipsis;
  font-size: 15px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple :where(.choices__item--selectable, .choices__item.is-highlighted) button.choices__button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple :where(.choices__item--selectable, .choices__item.is-highlighted) button.choices__button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple :where(.choices__item--selectable, .choices__item.is-highlighted) button.choices__button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple :where(.choices__item--selectable, .choices__item.is-highlighted) button.choices__button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple :where(.choices__item--selectable, .choices__item.is-highlighted) button.choices__button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple :where(.choices__item--selectable, .choices__item.is-highlighted) button.choices__button {
  position: absolute;
  inset-inline-end: 0;
  margin: 0;
  border: none;
  border-radius: 0 4px 4px 0;
  display: flex;
  top: 0;
  height: 100%;
  box-shadow: -1px 0 0 rgba(70, 81, 170, 0.4);
  filter: brightness(0.5);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple :where(.choices__item--selectable, .choices__item.is-highlighted)[data-value="[]"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple :where(.choices__item--selectable, .choices__item.is-highlighted)[data-value="[]"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple :where(.choices__item--selectable, .choices__item.is-highlighted)[data-value="[]"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple :where(.choices__item--selectable, .choices__item.is-highlighted)[data-value="[]"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple :where(.choices__item--selectable, .choices__item.is-highlighted)[data-value="[]"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple :where(.choices__item--selectable, .choices__item.is-highlighted)[data-value="[]"] {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple .choices__item.is-highlighted,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple .choices__item.is-highlighted,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple .choices__item.is-highlighted,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple .choices__item.is-highlighted,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple .choices__item.is-highlighted,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple .choices__item.is-highlighted {
  background-color: var(--color-primary-600);
  color: white;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple .choices__item.is-highlighted button.choices__button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple .choices__item.is-highlighted button.choices__button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple .choices__item.is-highlighted button.choices__button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple .choices__item.is-highlighted button.choices__button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple .choices__item.is-highlighted button.choices__button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select.formio-component-multiple .formio-choices .selection.dropdown .choices__list--multiple .choices__item.is-highlighted button.choices__button {
  filter: brightness(1);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select.formio-component-multiple .formio-choices:not(.is-open) .selection.dropdown .choices__input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select.formio-component-multiple .formio-choices:not(.is-open) .selection.dropdown .choices__input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select.formio-component-multiple .formio-choices:not(.is-open) .selection.dropdown .choices__input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select.formio-component-multiple .formio-choices:not(.is-open) .selection.dropdown .choices__input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select.formio-component-multiple .formio-choices:not(.is-open) .selection.dropdown .choices__input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select.formio-component-multiple .formio-choices:not(.is-open) .selection.dropdown .choices__input {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select.formio-component-multiple .formio-choices::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select.formio-component-multiple .formio-choices::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select.formio-component-multiple .formio-choices::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select.formio-component-multiple .formio-choices::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select.formio-component-multiple .formio-choices::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select.formio-component-multiple .formio-choices::after {
  position: absolute;
  inset-inline-end: 14px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select.field-plus-button .formio-choices .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select.field-plus-button .formio-choices .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select.field-plus-button .formio-choices .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select.field-plus-button .formio-choices .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select.field-plus-button .formio-choices .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select.field-plus-button .formio-choices .selection.dropdown {
  border-radius: 6px 0 0 6px !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select.align-value-right .formio-choices .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select.align-value-right .formio-choices .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select.align-value-right .formio-choices .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select.align-value-right .formio-choices .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select.align-value-right .formio-choices .selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select.align-value-right .formio-choices .selection.dropdown {
  flex-direction: row-reverse;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select.align-value-right .formio-choices .selection.dropdown .choices__list--multiple,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select.align-value-right .formio-choices .selection.dropdown .choices__list--multiple,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select.align-value-right .formio-choices .selection.dropdown .choices__list--multiple,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select.align-value-right .formio-choices .selection.dropdown .choices__list--multiple,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select.align-value-right .formio-choices .selection.dropdown .choices__list--multiple,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select.align-value-right .formio-choices .selection.dropdown .choices__list--multiple {
  text-align: end;
  padding-inline-end: 25px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select.align-value-right .choices__item,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select.align-value-right .choices__item,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select.align-value-right .choices__item,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select.align-value-right .choices__item,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select.align-value-right .choices__item,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select.align-value-right .choices__item {
  text-align: end;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select.value-label-style .formio-choices .form-control.selection,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select.value-label-style .formio-choices .form-control.selection,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select.value-label-style .formio-choices .form-control.selection,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select.value-label-style .formio-choices .form-control.selection,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select.value-label-style .formio-choices .form-control.selection,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select.value-label-style .formio-choices .form-control.selection {
  border: none;
  padding: 0;
  height: auto;
  line-height: 1;
  height: 40px;
  background: transparent;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select.value-label-style .formio-choices .form-control.selection .choices__item.choices__item--selectable,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select.value-label-style .formio-choices .form-control.selection .choices__item.choices__item--selectable,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select.value-label-style .formio-choices .form-control.selection .choices__item.choices__item--selectable,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select.value-label-style .formio-choices .form-control.selection .choices__item.choices__item--selectable,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select.value-label-style .formio-choices .form-control.selection .choices__item.choices__item--selectable,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select.value-label-style .formio-choices .form-control.selection .choices__item.choices__item--selectable {
  overflow: visible;
  font-size: var(--field-font-size);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select.required .choices__button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select.required .choices__button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select.required .choices__button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select.required .choices__button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select.required .choices__button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select.required .choices__button {
  right: initial;
  inset-inline-end: 8px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select.required .choices__item--selectable,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select.required .choices__item--selectable,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select.required .choices__item--selectable,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select.required .choices__item--selectable,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select.required .choices__item--selectable,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select.required .choices__item--selectable {
  padding-inline-end: 40px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select.required label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select.required label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select.required label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select.required label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select.required label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select.required label.field-required.control-label--hidden::after {
  inset-inline-end: 70px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select.required:has(select:empty) label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select.required:has(select:empty) label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select.required:has(select:empty) label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select.required:has(select:empty) label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select.required:has(select:empty) label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select.required:has(select:empty) label.field-required.control-label--hidden::after {
  inset-inline-end: 44px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select.required .formio-choices .choices__list--single .choices__item--selectable,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select.required .formio-choices .choices__list--single .choices__item--selectable,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select.required .formio-choices .choices__list--single .choices__item--selectable,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select.required .formio-choices .choices__list--single .choices__item--selectable,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select.required .formio-choices .choices__list--single .choices__item--selectable,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select.required .formio-choices .choices__list--single .choices__item--selectable {
  padding-inline-end: 40px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .choices__heading,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .choices__heading,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .choices__heading,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .choices__heading,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .choices__heading,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .choices__heading {
  background-color: rgba(70, 81, 170, 0.075);
  min-height: 32px;
  padding: 8px 14px;
  color: var(--color-primary-600);
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1.3;
  align-items: center;
  display: flex;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-select .choices__heading .value,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-select .choices__heading .value,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-select .choices__heading .value,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select .choices__heading .value,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select .choices__heading .value,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select .choices__heading .value {
  font-size: inherit;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datetime .input-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datetime .input-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datetime .input-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datetime .input-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datetime .input-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datetime .input-group {
  display: flex;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datetime .input-group input.form-control.input:not([disabled]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datetime .input-group input.form-control.input:not([disabled]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datetime .input-group input.form-control.input:not([disabled]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datetime .input-group input.form-control.input:not([disabled]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datetime .input-group input.form-control.input:not([disabled]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datetime .input-group input.form-control.input:not([disabled]) {
  border: solid 1px var(--color-gray-300);
  border-radius: 6px 0 0 6px !important;
  border-inline-end: none;
  padding-inline-end: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datetime .input-group input.form-control.input:not([disabled]):focus,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datetime .input-group input.form-control.input:not([disabled]):focus,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datetime .input-group input.form-control.input:not([disabled]):focus,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datetime .input-group input.form-control.input:not([disabled]):focus,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datetime .input-group input.form-control.input:not([disabled]):focus,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datetime .input-group input.form-control.input:not([disabled]):focus {
  border-color: var(--color-gray-400);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datetime .input-group input.form-control.input:not([disabled]):focus ~ .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datetime .input-group input.form-control.input:not([disabled]):focus ~ .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datetime .input-group input.form-control.input:not([disabled]):focus ~ .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datetime .input-group input.form-control.input:not([disabled]):focus ~ .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datetime .input-group input.form-control.input:not([disabled]):focus ~ .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datetime .input-group input.form-control.input:not([disabled]):focus ~ .input-group-append {
  border-color: var(--color-gray-400);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datetime .input-group input.form-control.input[type="text"][disabled],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datetime .input-group input.form-control.input[type="text"][disabled],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datetime .input-group input.form-control.input[type="text"][disabled],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datetime .input-group input.form-control.input[type="text"][disabled],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datetime .input-group input.form-control.input[type="text"][disabled],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datetime .input-group input.form-control.input[type="text"][disabled] {
  border-radius: 6px !important;
  border-inline-end: solid 1px var(--color-gray-300);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datetime .input-group input.form-control.input[type="text"][disabled] ~ .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datetime .input-group input.form-control.input[type="text"][disabled] ~ .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datetime .input-group input.form-control.input[type="text"][disabled] ~ .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datetime .input-group input.form-control.input[type="text"][disabled] ~ .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datetime .input-group input.form-control.input[type="text"][disabled] ~ .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datetime .input-group input.form-control.input[type="text"][disabled] ~ .input-group-append {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datetime .input-group .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datetime .input-group .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datetime .input-group .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datetime .input-group .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datetime .input-group .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datetime .input-group .input-group-append {
  border: solid 1px var(--color-gray-300);
  border-radius: 0 6px 6px 0 !important;
  height: var(--field-height);
  border-inline-start: none;
  cursor: pointer;
  transition: border-color ease-in-out 0.15s;
  display: inline-flex;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datetime .input-group .input-group-append .input-group-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datetime .input-group .input-group-append .input-group-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datetime .input-group .input-group-append .input-group-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datetime .input-group .input-group-append .input-group-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datetime .input-group .input-group-append .input-group-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datetime .input-group .input-group-append .input-group-text {
  display: flex;
  height: 100%;
  align-items: center;
  border-radius: 0 6px 6px 0;
  border: none;
  padding: 0px 14px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datetime .input-group .input-group-append .input-group-text i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datetime .input-group .input-group-append .input-group-text i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datetime .input-group .input-group-append .input-group-text i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datetime .input-group .input-group-append .input-group-text i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datetime .input-group .input-group-append .input-group-text i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datetime .input-group .input-group-append .input-group-text i {
  margin-inline-end: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datetime .input-group .input-group-append .input-group-text i::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datetime .input-group .input-group-append .input-group-text i::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datetime .input-group .input-group-append .input-group-text i::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datetime .input-group .input-group-append .input-group-text i::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datetime .input-group .input-group-append .input-group-text i::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datetime .input-group .input-group-append .input-group-text i::before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  content: "\f073";
  color: rgba(70, 81, 170, 0.4);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datetime .input-group-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datetime .input-group-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datetime .input-group-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datetime .input-group-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datetime .input-group-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datetime .input-group-text {
  font-size: 14px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datetime:hover .input-group .input-group-append .input-group-text i::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datetime:hover .input-group .input-group-append .input-group-text i::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datetime:hover .input-group .input-group-append .input-group-text i::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datetime:hover .input-group .input-group-append .input-group-text i::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datetime:hover .input-group .input-group-append .input-group-text i::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datetime:hover .input-group .input-group-append .input-group-text i::before {
  color: var(--color-primary-600);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datetime.has-error .input-group input.form-control.input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datetime.has-error .input-group input.form-control.input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datetime.has-error .input-group input.form-control.input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datetime.has-error .input-group input.form-control.input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datetime.has-error .input-group input.form-control.input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datetime.has-error .input-group input.form-control.input {
  border-color: rgba(255, 0, 59, 0.3) !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datetime.has-error .input-group .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datetime.has-error .input-group .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datetime.has-error .input-group .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datetime.has-error .input-group .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datetime.has-error .input-group .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datetime.has-error .input-group .input-group-append {
  border-color: rgba(255, 0, 59, 0.3) !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datetime.value-label-style .input-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datetime.value-label-style .input-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datetime.value-label-style .input-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datetime.value-label-style .input-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datetime.value-label-style .input-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datetime.value-label-style .input-group {
  padding-inline-start: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datetime.value-label-style input:where(.form-control, .input),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datetime.value-label-style input:where(.form-control, .input),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datetime.value-label-style input:where(.form-control, .input),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datetime.value-label-style input:where(.form-control, .input),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datetime.value-label-style input:where(.form-control, .input),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datetime.value-label-style input:where(.form-control, .input) {
  border: none !important;
  border-radius: 0px !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datetime.value-label-style input:where(.form-control, .input):where([disabled], :disabled),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datetime.value-label-style input:where(.form-control, .input):where([disabled], :disabled),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datetime.value-label-style input:where(.form-control, .input):where([disabled], :disabled),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datetime.value-label-style input:where(.form-control, .input):where([disabled], :disabled),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datetime.value-label-style input:where(.form-control, .input):where([disabled], :disabled),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datetime.value-label-style input:where(.form-control, .input):where([disabled], :disabled) {
  background-color: transparent !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datetime.value-label-style .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datetime.value-label-style .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datetime.value-label-style .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datetime.value-label-style .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datetime.value-label-style .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datetime.value-label-style .input-group-append {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datetime label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datetime label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datetime label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datetime label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datetime label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datetime label.field-required.control-label--hidden::after {
  inset-inline-end: 36px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio .form-radio.radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio .form-radio.radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio .form-radio.radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio .form-radio.radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio .form-radio.radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio .form-radio.radio {
  padding-top: 14px;
  margin-top: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) {
  cursor: pointer;
  margin-top: 0;
  margin-bottom: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label {
  margin-top: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label::before {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"] {
  appearance: inherit;
  padding: 0;
  margin: -1px 0 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"]::before {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  font-size: 16px;
  content: "\f111";
  color: rgba(70, 81, 170, 0.5);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"] + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"] + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"] + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"] + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"] + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"] + span {
  font-size: 16px;
  font-weight: 500;
  color: rgba(70, 81, 170, 0.5);
  margin-inline-start: 6px;
  position: relative;
  top: 2px;
  line-height: 14px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"]:where([checked], :checked)::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"]:where([checked], :checked)::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"]:where([checked], :checked)::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"]:where([checked], :checked)::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"]:where([checked], :checked)::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"]:where([checked], :checked)::before {
  content: "\f192" !important;
  font-weight: 900;
  color: var(--color-primary-600) !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"]:where([checked], :checked) + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"]:where([checked], :checked) + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"]:where([checked], :checked) + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"]:where([checked], :checked) + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"]:where([checked], :checked) + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"]:where([checked], :checked) + span {
  color: var(--color-primary-600) !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"]:focus,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"]:focus,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"]:focus,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"]:focus,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"]:focus,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"]:focus {
  outline: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"][disabled],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"][disabled],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"][disabled],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"][disabled],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"][disabled],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"][disabled] {
  border-radius: 50%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"][disabled]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"][disabled]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"][disabled]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"][disabled]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"][disabled]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"][disabled]::before {
  color: var(--color-gray-300) !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"][disabled]:hover::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"][disabled]:hover::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"][disabled]:hover::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"][disabled]:hover::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"][disabled]:hover::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label input[type="radio"][disabled]:hover::before {
  color: var(--color-gray-300) !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label:hover input[type="radio"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label:hover input[type="radio"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label:hover input[type="radio"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label:hover input[type="radio"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label:hover input[type="radio"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label:hover input[type="radio"] {
  appearance: inherit;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label:hover input[type="radio"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label:hover input[type="radio"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label:hover input[type="radio"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label:hover input[type="radio"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label:hover input[type="radio"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label:hover input[type="radio"]::before {
  color: var(--color-primary-600) !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label:hover input[type="radio"] + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label:hover input[type="radio"] + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label:hover input[type="radio"] + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label:hover input[type="radio"] + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label:hover input[type="radio"] + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label:hover input[type="radio"] + span {
  color: var(--color-primary-600) !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label.label-position-left,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label.label-position-left,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label.label-position-left,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label.label-position-left,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label.label-position-left,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label.label-position-left {
  padding-inline-start: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label.label-position-left span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label.label-position-left span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label.label-position-left span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label.label-position-left span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label.label-position-left span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label.label-position-left span {
  margin-inline: 0px !important;
  margin-inline-end: 12px !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label.label-position-left input.form-check-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label.label-position-left input.form-check-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label.label-position-left input.form-check-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label.label-position-left input.form-check-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label.label-position-left input.form-check-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label.label-position-left input.form-check-input {
  position: initial;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label.label-position-right,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label.label-position-right,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label.label-position-right,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label.label-position-right,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label.label-position-right,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label.label-position-right {
  padding-inline-start: 26px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label.label-position-right span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label.label-position-right span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label.label-position-right span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label.label-position-right span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label.label-position-right span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline) label.label-position-right span {
  margin-inline: 0 !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline):last-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline):last-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline):last-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline):last-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline):last-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio .form-radio.radio :where(.form-check, .form-check-inline):last-of-type {
  padding-bottom: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio .form-radio.radio .radio.form-check label[class*="label-position-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio .form-radio.radio .radio.form-check label[class*="label-position-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio .form-radio.radio .radio.form-check label[class*="label-position-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio .form-radio.radio .radio.form-check label[class*="label-position-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio .form-radio.radio .radio.form-check label[class*="label-position-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio .form-radio.radio .radio.form-check label[class*="label-position-"] {
  display: flex;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio .form-radio.radio .radio.form-check label[class*="label-position-"] input.form-check-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio .form-radio.radio .radio.form-check label[class*="label-position-"] input.form-check-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio .form-radio.radio .radio.form-check label[class*="label-position-"] input.form-check-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio .form-radio.radio .radio.form-check label[class*="label-position-"] input.form-check-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio .form-radio.radio .radio.form-check label[class*="label-position-"] input.form-check-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio .form-radio.radio .radio.form-check label[class*="label-position-"] input.form-check-input {
  width: initial;
  margin: -1px 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.five-faces-rating .form-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.five-faces-rating .form-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.five-faces-rating .form-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.five-faces-rating .form-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.five-faces-rating .form-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.five-faces-rating .form-radio {
  column-gap: 16px !important;
  margin-top: 12px !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.five-faces-rating .form-radio .form-check-inline label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label {
  padding-inline-start: 0 !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.five-faces-rating .form-radio .form-check-inline label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.five-faces-rating .form-radio .form-check-inline label:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label:before {
  cursor: pointer;
  display: inline;
  font-family: "Font Awesome 6 Duotone";
  font-size: 42px !important;
  font-weight: 600;
  line-height: 19px;
  color: var(--color-gray-500) !important;
  transition: color 0.2s;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.five-faces-rating .form-radio .form-check-inline label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label:after {
  opacity: 0.4;
  position: absolute;
  inset-inline-start: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label:hover:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label:hover:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.five-faces-rating .form-radio .form-check-inline label:hover:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label:hover:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label:hover:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label:hover:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label:hover:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label:hover:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.five-faces-rating .form-radio .form-check-inline label:hover:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label:hover:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label:hover:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label:hover:before {
  color: var(--color-gray-700) !important;
  transition: color 0.2s;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.five-faces-rating .form-radio .form-check-inline label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label span {
  display: none !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.five-faces-rating .form-radio .form-check-inline label input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline label input {
  visibility: hidden;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(1) label:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(1) label:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(1) label:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(1) label:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(1) label:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(1) label:before {
  content: "\e376";
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(1) label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(1) label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(1) label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(1) label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(1) label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(1) label:after {
  content: "\e376\e376";
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(2) label:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(2) label:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(2) label:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(2) label:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(2) label:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(2) label:before {
  content: "\e47e";
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(2) label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(2) label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(2) label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(2) label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(2) label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(2) label:after {
  content: "\e47e\e47e";
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(3) label:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(3) label:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(3) label:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(3) label:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(3) label:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(3) label:before {
  content: "\f11a";
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(3) label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(3) label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(3) label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(3) label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(3) label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(3) label:after {
  content: "\f11a\f11a";
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(4) label:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(4) label:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(4) label:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(4) label:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(4) label:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(4) label:before {
  content: "\f118";
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(4) label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(4) label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(4) label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(4) label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(4) label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(4) label:after {
  content: "\f118\f118";
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(5) label:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(5) label:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(5) label:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(5) label:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(5) label:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(5) label:before {
  content: "\f599";
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(5) label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(5) label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(5) label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(5) label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(5) label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline:nth-child(5) label:after {
  content: "\f599\f599";
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline.radio-selected label:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline.radio-selected label:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.five-faces-rating .form-radio .form-check-inline.radio-selected label:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline.radio-selected label:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline.radio-selected label:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline.radio-selected label:before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline.radio-selected label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline.radio-selected label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.five-faces-rating .form-radio .form-check-inline.radio-selected label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline.radio-selected label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline.radio-selected label:after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.five-faces-rating .form-radio .form-check-inline.radio-selected label:after {
  color: var(--color-primary-600) !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.five-stars-rating .form-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.five-stars-rating .form-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.five-stars-rating .form-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.five-stars-rating .form-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.five-stars-rating .form-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.five-stars-rating .form-radio {
  column-gap: 0px !important;
  margin-top: 12px !important;
  flex-direction: row-reverse !important;
  justify-content: flex-end !important;
  /* Hover Styling (Highlights all previous stars) */
  /*Checked Styling (Highlights the checked star and all previous) */
  /* Styling for hover on checked stars.  Ensure the hovered one changes to a lighter gold */
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-inline,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-inline,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.five-stars-rating .form-radio .form-check-inline,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-inline,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-inline,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-inline {
  margin-right: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.five-stars-rating .form-radio .form-check-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-input {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.five-stars-rating .form-radio .form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-label {
  padding-inline-start: 0 !important;
  position: relative;
  width: 1em;
  height: 1em;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.five-stars-rating .form-radio .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-label::before {
  content: "\f005";
  /* Font Awesome star icon */
  font-family: 'Font Awesome 6 Pro';
  font-weight: 900;
  font-size: 2em;
  color: var(--color-amber-200);
  cursor: pointer;
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 1;
  transition: color 0.2s;
  /* Add a smooth transition */
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.five-stars-rating .form-radio .form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-label span {
  display: none !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-inline:hover ~ .form-check-inline .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-inline:hover ~ .form-check-inline .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.five-stars-rating .form-radio .form-check-inline:hover ~ .form-check-inline .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-inline:hover ~ .form-check-inline .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-inline:hover ~ .form-check-inline .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-inline:hover ~ .form-check-inline .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-inline:hover .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-inline:hover .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.five-stars-rating .form-radio .form-check-inline:hover .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-inline:hover .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-inline:hover .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-inline:hover .form-check-label::before {
  color: var(--color-amber-400);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-inline:has(.form-check-input:checked) ~ .form-check-inline .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-inline:has(.form-check-input:checked) ~ .form-check-inline .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.five-stars-rating .form-radio .form-check-inline:has(.form-check-input:checked) ~ .form-check-inline .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-inline:has(.form-check-input:checked) ~ .form-check-inline .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-inline:has(.form-check-input:checked) ~ .form-check-inline .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-inline:has(.form-check-input:checked) ~ .form-check-inline .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-inline:has(.form-check-input:checked) .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-inline:has(.form-check-input:checked) .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.five-stars-rating .form-radio .form-check-inline:has(.form-check-input:checked) .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-inline:has(.form-check-input:checked) .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-inline:has(.form-check-input:checked) .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-inline:has(.form-check-input:checked) .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-input:checked ~ .form-check-inline .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-input:checked ~ .form-check-inline .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.five-stars-rating .form-radio .form-check-input:checked ~ .form-check-inline .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-input:checked ~ .form-check-inline .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-input:checked ~ .form-check-inline .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-input:checked ~ .form-check-inline .form-check-label::before {
  color: var(--color-amber-400);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-input:checked + .form-check-inline .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-input:checked + .form-check-inline .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.five-stars-rating .form-radio .form-check-input:checked + .form-check-inline .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-input:checked + .form-check-inline .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-input:checked + .form-check-inline .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-input:checked + .form-check-inline .form-check-label::before {
  color: var(--color-amber-400);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-inline:hover > .form-check-input:checked ~ .form-check-inline .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-inline:hover > .form-check-input:checked ~ .form-check-inline .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.five-stars-rating .form-radio .form-check-inline:hover > .form-check-input:checked ~ .form-check-inline .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-inline:hover > .form-check-input:checked ~ .form-check-inline .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-inline:hover > .form-check-input:checked ~ .form-check-inline .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-inline:hover > .form-check-input:checked ~ .form-check-inline .form-check-label::before {
  color: var(--color-amber-300);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-inline:hover > .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-inline:hover > .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.five-stars-rating .form-radio .form-check-inline:hover > .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-inline:hover > .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-inline:hover > .form-check-label::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.five-stars-rating .form-radio .form-check-inline:hover > .form-check-label::before {
  color: var(--color-amber-300);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.radio-switch .form-radio.radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.radio-switch .form-radio.radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.radio-switch .form-radio.radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.radio-switch .form-radio.radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.radio-switch .form-radio.radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.radio-switch .form-radio.radio {
  height: var(--field-height);
  border-radius: 6px;
  border: solid 1px var(--color-gray-300);
  width: fit-content !important;
  width: -moz-fit-content !important;
  padding-top: 0;
  display: flex;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) {
  margin: 0;
  flex: auto;
  height: 100%;
  padding: 0;
  display: flex;
  align-items: center;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label {
  display: flex;
  justify-content: center;
  height: 100%;
  padding-inline-start: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label input[type="radio"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label input[type="radio"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label input[type="radio"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label input[type="radio"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label input[type="radio"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label input[type="radio"] {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label input[type="radio"]:checked + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label input[type="radio"]:checked + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label input[type="radio"]:checked + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label input[type="radio"]:checked + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label input[type="radio"]:checked + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label input[type="radio"]:checked + span {
  background-color: var(--color-primary-600);
  color: white !important;
  border: solid 1px var(--color-primary-600);
  margin: -1px;
  height: calc(100% + 2px);
  width: calc(100% + 2px);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label input[type="radio"][disabled] + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label input[type="radio"][disabled] + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label input[type="radio"][disabled] + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label input[type="radio"][disabled] + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label input[type="radio"][disabled] + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label input[type="radio"][disabled] + span {
  background-color: var(--color-gray-75);
  color: var(--color-gray-500) !important;
  cursor: not-allowed;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label input[type="radio"][disabled]:checked + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label input[type="radio"][disabled]:checked + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label input[type="radio"][disabled]:checked + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label input[type="radio"][disabled]:checked + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label input[type="radio"][disabled]:checked + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label input[type="radio"][disabled]:checked + span {
  background-color: var(--color-gray-200);
  color: var(--color-gray-500) !important;
  border: solid 1px var(--color-gray-300);
  border-inline-start: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label span {
  margin: 0;
  display: flex;
  height: 100%;
  align-items: center;
  cursor: pointer;
  padding: 1px 15px;
  width: 100%;
  top: 0;
  color: var(--color-gray-400);
  border-inline-end: solid 1px var(--color-gray-300);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label span:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label span:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label span:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label span:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label span:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check) label.form-check-label span:hover {
  background-color: var(--color-primary-50);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check):first-child label.form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check):first-child label.form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check):first-child label.form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check):first-child label.form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check):first-child label.form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check):first-child label.form-check-label span {
  border-radius: var(--radio-switch-first-border-radius, 6px 0 0 6px);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check):last-child label.form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check):last-child label.form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check):last-child label.form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check):last-child label.form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check):last-child label.form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check):last-child label.form-check-label span {
  border-radius: var(--radio-switch-last-border-radius, 0 6px 6px 0);
  border-inline-end: none !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check).radio-selected label.form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check).radio-selected label.form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check).radio-selected label.form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check).radio-selected label.form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check).radio-selected label.form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.radio-switch .form-radio.radio :where(.form-check-inline, .form-check).radio-selected label.form-check-label span {
  background-color: #4651aa;
  color: white !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.radio-switch.required label.field-required.control-label--hidden,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.radio-switch.required label.field-required.control-label--hidden,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.radio-switch.required label.field-required.control-label--hidden,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.radio-switch.required label.field-required.control-label--hidden,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.radio-switch.required label.field-required.control-label--hidden,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.radio-switch.required label.field-required.control-label--hidden {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.radio-switch.required label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.radio-switch.required label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.radio-switch.required label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.radio-switch.required label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.radio-switch.required label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.radio-switch.required label.field-required.control-label--hidden::after {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.radio-switch.required label.field-required:not(.control-label--hidden) + .form-radio.radio::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.radio-switch.required label.field-required:not(.control-label--hidden) + .form-radio.radio::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.radio-switch.required label.field-required:not(.control-label--hidden) + .form-radio.radio::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.radio-switch.required label.field-required:not(.control-label--hidden) + .form-radio.radio::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.radio-switch.required label.field-required:not(.control-label--hidden) + .form-radio.radio::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.radio-switch.required label.field-required:not(.control-label--hidden) + .form-radio.radio::before {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.radio-switch.required .form-radio.radio::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.radio-switch.required .form-radio.radio::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.radio-switch.required .form-radio.radio::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.radio-switch.required .form-radio.radio::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.radio-switch.required .form-radio.radio::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.radio-switch.required .form-radio.radio::before {
  content: " ";
  display: inline-block;
  background-color: rgba(255, 0, 59, 0.7);
  width: 6px;
  height: 6px;
  border-radius: 5px;
  position: absolute;
  inset-inline-end: -16px;
  top: calc(50% - 3px);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.radio-switch.required .form-radio.radio::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.radio-switch.required .form-radio.radio::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.radio-switch.required .form-radio.radio::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.radio-switch.required .form-radio.radio::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.radio-switch.required .form-radio.radio::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.radio-switch.required .form-radio.radio::after {
  display: none !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.radio-switch label.control-label + .input-group .radio-inline,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.radio-switch label.control-label + .input-group .radio-inline,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.radio-switch label.control-label + .input-group .radio-inline,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.radio-switch label.control-label + .input-group .radio-inline,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.radio-switch label.control-label + .input-group .radio-inline,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.radio-switch label.control-label + .input-group .radio-inline {
  margin-top: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.radio-switch label.control-label + .input-group .radio-inline label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.radio-switch label.control-label + .input-group .radio-inline label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.radio-switch label.control-label + .input-group .radio-inline label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.radio-switch label.control-label + .input-group .radio-inline label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.radio-switch label.control-label + .input-group .radio-inline label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.radio-switch label.control-label + .input-group .radio-inline label span {
  top: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.radio-switch label.form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.radio-switch label.form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.radio-switch label.form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.radio-switch label.form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.radio-switch label.form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.radio-switch label.form-check-label {
  margin-bottom: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio:not(.radio-switch) .form-radio.radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio {
  display: flex;
  flex-wrap: wrap;
  column-gap: 28px;
  row-gap: 18px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .form-check:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .form-check:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio:not(.radio-switch) .form-radio.radio .form-check:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .form-check:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .form-check:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .form-check:last-child {
  padding-bottom: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio:not(.radio-switch) .form-radio.radio .radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio {
  margin-inline-end: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio.form-check,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio.form-check,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio:not(.radio-switch) .form-radio.radio .radio.form-check,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio.form-check,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio.form-check,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio.form-check {
  width: 100%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label {
  min-height: 16px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label input:disabled,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label input:disabled,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label input:disabled,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label input:disabled,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label input:disabled,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label input:disabled {
  opacity: 1;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label input:disabled::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label input:disabled::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label input:disabled::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label input:disabled::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label input:disabled::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label input:disabled::before {
  color: var(--color-gray-300);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label span {
  color: var(--color-gray-500) !important;
  font-weight: 400 !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label.label-position-top span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label.label-position-top span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label.label-position-top span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label.label-position-top span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label.label-position-top span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label.label-position-top span {
  margin-inline-start: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label.label-position-top input.form-check-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label.label-position-top input.form-check-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label.label-position-top input.form-check-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label.label-position-top input.form-check-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label.label-position-top input.form-check-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label.label-position-top input.form-check-input {
  top: 0;
  margin-top: 12px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label.label-position-bottom span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label.label-position-bottom span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label.label-position-bottom span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label.label-position-bottom span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label.label-position-bottom span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio label.label-position-bottom span {
  margin-inline-start: 0px;
  top: 0;
  margin-top: 12px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio:hover label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio:hover label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio:not(.radio-switch) .form-radio.radio .radio:hover label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio:hover label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio:hover label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio:hover label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio.radio-selected label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio.radio-selected label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio:not(.radio-switch) .form-radio.radio .radio.radio-selected label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio.radio-selected label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio.radio-selected label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .radio.radio-selected label span {
  opacity: 1;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio:not(.radio-switch).required label.field-required.control-label--hidden + .form-radio.radio .radio.form-check label.form-check-label::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio:not(.radio-switch).required label.field-required.control-label--hidden + .form-radio.radio .radio.form-check label.form-check-label::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio:not(.radio-switch).required label.field-required.control-label--hidden + .form-radio.radio .radio.form-check label.form-check-label::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio:not(.radio-switch).required label.field-required.control-label--hidden + .form-radio.radio .radio.form-check label.form-check-label::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio:not(.radio-switch).required label.field-required.control-label--hidden + .form-radio.radio .radio.form-check label.form-check-label::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio:not(.radio-switch).required label.field-required.control-label--hidden + .form-radio.radio .radio.form-check label.form-check-label::after {
  content: " ";
  display: inline-block;
  background-color: rgba(255, 0, 59, 0.7);
  vertical-align: unset;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  margin-inline-start: 5px;
  min-width: 6px;
  align-self: center;
  position: relative;
  top: -2px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio:not(.radio-switch).required label.field-required.control-label--hidden + .form-radio.radio .radio.form-check label.form-check-label.label-position-left span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio:not(.radio-switch).required label.field-required.control-label--hidden + .form-radio.radio .radio.form-check label.form-check-label.label-position-left span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio:not(.radio-switch).required label.field-required.control-label--hidden + .form-radio.radio .radio.form-check label.form-check-label.label-position-left span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio:not(.radio-switch).required label.field-required.control-label--hidden + .form-radio.radio .radio.form-check label.form-check-label.label-position-left span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio:not(.radio-switch).required label.field-required.control-label--hidden + .form-radio.radio .radio.form-check label.form-check-label.label-position-left span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio:not(.radio-switch).required label.field-required.control-label--hidden + .form-radio.radio .radio.form-check label.form-check-label.label-position-left span {
  top: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio:not(.radio-switch).required label.field-required.control-label--hidden + .form-radio.radio .radio.form-check label.form-check-label.label-position-left::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio:not(.radio-switch).required label.field-required.control-label--hidden + .form-radio.radio .radio.form-check label.form-check-label.label-position-left::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio:not(.radio-switch).required label.field-required.control-label--hidden + .form-radio.radio .radio.form-check label.form-check-label.label-position-left::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio:not(.radio-switch).required label.field-required.control-label--hidden + .form-radio.radio .radio.form-check label.form-check-label.label-position-left::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio:not(.radio-switch).required label.field-required.control-label--hidden + .form-radio.radio .radio.form-check label.form-check-label.label-position-left::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio:not(.radio-switch).required label.field-required.control-label--hidden + .form-radio.radio .radio.form-check label.form-check-label.label-position-left::after {
  top: -1px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio:not(.radio-switch).required label.field-required.control-label--hidden + .form-radio.radio .radio.form-check-inline:last-child::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio:not(.radio-switch).required label.field-required.control-label--hidden + .form-radio.radio .radio.form-check-inline:last-child::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio:not(.radio-switch).required label.field-required.control-label--hidden + .form-radio.radio .radio.form-check-inline:last-child::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio:not(.radio-switch).required label.field-required.control-label--hidden + .form-radio.radio .radio.form-check-inline:last-child::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio:not(.radio-switch).required label.field-required.control-label--hidden + .form-radio.radio .radio.form-check-inline:last-child::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio:not(.radio-switch).required label.field-required.control-label--hidden + .form-radio.radio .radio.form-check-inline:last-child::after {
  content: " ";
  display: inline-block;
  background-color: rgba(255, 0, 59, 0.7);
  vertical-align: unset;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  margin-inline-start: 5px;
  min-width: 6px;
  position: absolute;
  inset-inline-end: -12px;
  top: 4px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio:not(.radio-switch).required label.field-required.control-label--hidden + .form-radio.radio .radio.form-check-inline:last-child:has(.label-position-bottom)::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio:not(.radio-switch).required label.field-required.control-label--hidden + .form-radio.radio .radio.form-check-inline:last-child:has(.label-position-bottom)::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio:not(.radio-switch).required label.field-required.control-label--hidden + .form-radio.radio .radio.form-check-inline:last-child:has(.label-position-bottom)::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio:not(.radio-switch).required label.field-required.control-label--hidden + .form-radio.radio .radio.form-check-inline:last-child:has(.label-position-bottom)::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio:not(.radio-switch).required label.field-required.control-label--hidden + .form-radio.radio .radio.form-check-inline:last-child:has(.label-position-bottom)::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio:not(.radio-switch).required label.field-required.control-label--hidden + .form-radio.radio .radio.form-check-inline:last-child:has(.label-position-bottom)::after {
  bottom: 1px;
  top: initial;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio:not(.radio-switch).required.formio-modified label.field-required.control-label--hidden + .form-radio.radio .radio.form-check label.form-check-label::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio:not(.radio-switch).required.formio-modified label.field-required.control-label--hidden + .form-radio.radio .radio.form-check label.form-check-label::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio:not(.radio-switch).required.formio-modified label.field-required.control-label--hidden + .form-radio.radio .radio.form-check label.form-check-label::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio:not(.radio-switch).required.formio-modified label.field-required.control-label--hidden + .form-radio.radio .radio.form-check label.form-check-label::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio:not(.radio-switch).required.formio-modified label.field-required.control-label--hidden + .form-radio.radio .radio.form-check label.form-check-label::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio:not(.radio-switch).required.formio-modified label.field-required.control-label--hidden + .form-radio.radio .radio.form-check label.form-check-label::after {
  content: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio:not(.radio-switch) .field-wrapper .field-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio:not(.radio-switch) .field-wrapper .field-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio:not(.radio-switch) .field-wrapper .field-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio:not(.radio-switch) .field-wrapper .field-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio:not(.radio-switch) .field-wrapper .field-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio:not(.radio-switch) .field-wrapper .field-label {
  padding-top: 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.remove-top-space .form-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.remove-top-space .form-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.remove-top-space .form-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.remove-top-space .form-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.remove-top-space .form-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.remove-top-space .form-radio {
  padding-top: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.remove-top-space .field-wrapper .field-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.remove-top-space .field-wrapper .field-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.remove-top-space .field-wrapper .field-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.remove-top-space .field-wrapper .field-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.remove-top-space .field-wrapper .field-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.remove-top-space .field-wrapper .field-label {
  padding-top: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio label.field-required.control-label--hidden::after {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-radio.payment-method .form-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-radio.payment-method .form-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-radio.payment-method .form-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio.payment-method .form-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio.payment-method .form-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio.payment-method .form-radio {
  padding-top: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button {
  --button-bg-color: var(--color-primary-600);
  --button-secondary-bg-color: rgba(70, 81, 170, 0.1);
  --button-shadow-y: 9px;
  --button-shadow-blur: 20px;
  --button-inline-padding: 25px;
  --button-block-padding: 12px;
  --button-font-size: 16px;
  --button-border-radius: 6px;
  --button-text-color: white;
  --button-text-color-hover: white;
  --button-outline-bg-color: transparent;
  --my-account-service-button-border-radius: 6px;
  --my-account-service-button-inline-padding: 24px;
  --my-account-service-button-block-padding: 16px;
  --my-account-service-button-bgcolor: var(--color-white);
  --my-account-service-button-disabled-bgcolor: var(--color-gray-200);
  --my-account-service-button-text-color: var(--color-gray-800);
  --my-account-service-button-disabled-text-color: var(--color-gray-900);
  --my-account-service-button-text-size: 15px;
  --my-account-service-button-extra-text-size: 12px;
  --my-account-service-button-font-weight: 600;
  --my-account-service-button-icon-bgcolor: var(--color-primary-50);
  --my-account-service-button-icon-container-size: 60px;
  --my-account-service-button-icon-size: 28px;
  --my-account-service-button-content-gap: 24px;
  --my-account-service-button-container-shadow: none;
  --my-account-service-button-container-shadow-hover: 0px 4px 16px var(--color-gray-100);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button button.btn {
  background-color: var(--button-bg-color);
  font-weight: 500;
  color: var(--button-text-color);
  text-transform: none;
  position: relative;
  z-index: 1;
  line-height: 1;
  text-align: center;
  gap: 8px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button button.btn .fa-cog,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button button.btn .fa-cog,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button button.btn .fa-cog,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button button.btn .fa-cog,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button button.btn .fa-cog,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button button.btn .fa-cog {
  position: absolute;
  animation: none;
  background: inherit;
  width: 100%;
  z-index: 9;
  color: transparent;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button button.btn .fa-cog + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button button.btn .fa-cog + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button button.btn .fa-cog + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button button.btn .fa-cog + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button button.btn .fa-cog + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button button.btn .fa-cog + span {
  position: absolute !important;
  top: -7px;
  z-index: 99;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
  color: #ffffff;
  font-size: 10px;
  margin: 0 auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button button.btn .fa-cog + span::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button button.btn .fa-cog + span::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button button.btn .fa-cog + span::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button button.btn .fa-cog + span::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button button.btn .fa-cog + span::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button button.btn .fa-cog + span::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button button.btn .fa-cog + span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button button.btn .fa-cog + span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button button.btn .fa-cog + span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button button.btn .fa-cog + span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button button.btn .fa-cog + span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button button.btn .fa-cog + span::before {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
  content: '';
  position: absolute;
  top: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button button.btn .fa-cog + span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button button.btn .fa-cog + span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button button.btn .fa-cog + span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button button.btn .fa-cog + span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button button.btn .fa-cog + span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button button.btn .fa-cog + span::before {
  inset-inline-start: -16px;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button button.btn .fa-cog + span::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button button.btn .fa-cog + span::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button button.btn .fa-cog + span::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button button.btn .fa-cog + span::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button button.btn .fa-cog + span::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button button.btn .fa-cog + span::after {
  inset-inline-start: 16px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button button.btn .fa-question-circle,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button button.btn .fa-question-circle,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button button.btn .fa-question-circle,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button button.btn .fa-question-circle,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button button.btn .fa-question-circle,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button button.btn .fa-question-circle {
  color: white;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button button.btn .fa-question-circle::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button button.btn .fa-question-circle::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button button.btn .fa-question-circle::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button button.btn .fa-question-circle::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button button.btn .fa-question-circle::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button button.btn .fa-question-circle::before {
  color: inherit;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button:is(.field-plus-button) button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button:is(.field-plus-button) button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button:is(.field-plus-button) button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button:is(.field-plus-button) button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button:is(.field-plus-button) button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button:is(.field-plus-button) button.btn {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  margin-inline-start: -24px;
  transition-duration: 0s;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.action-progress button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.action-progress button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.action-progress button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.action-progress button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.action-progress button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.action-progress button {
  font-family: var(--main-font-family) !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.action-progress.cog-during-form-submit button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.action-progress.cog-during-form-submit button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.action-progress.cog-during-form-submit button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.action-progress.cog-during-form-submit button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.action-progress.cog-during-form-submit button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.action-progress.cog-during-form-submit button {
  font-family: 'Nunito Sans', sans-serif !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.action-progress.cog-during-form-submit button::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.action-progress.cog-during-form-submit button::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.action-progress.cog-during-form-submit button::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.action-progress.cog-during-form-submit button::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.action-progress.cog-during-form-submit button::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.action-progress.cog-during-form-submit button::before {
  font-family: 'Font Awesome 5 Pro' !important;
  font-weight: 300;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  -webkit-animation: buttonProgress 2s linear infinite;
  animation: buttonProgress 2s linear infinite;
  margin-inline-end: 8px;
  content: "\f3f4";
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.action-progress:not(.cog-during-form-submit) button::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.action-progress:not(.cog-during-form-submit) button::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.action-progress:not(.cog-during-form-submit) button::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.action-progress:not(.cog-during-form-submit) button::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.action-progress:not(.cog-during-form-submit) button::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.action-progress:not(.cog-during-form-submit) button::before {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.action-success.success-disabled,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.action-success.success-disabled,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.action-success.success-disabled,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.action-success.success-disabled,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.action-success.success-disabled,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.action-success.success-disabled {
  --button-text-color: #313335;
  --button-bg-color: #efefef;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.action-success.success-disabled button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.action-success.success-disabled button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.action-success.success-disabled button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.action-success.success-disabled button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.action-success.success-disabled button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.action-success.success-disabled button {
  cursor: not-allowed;
  pointer-events: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button:is(.btn-secondary, .light-color),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button:is(.btn-secondary, .light-color),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button:is(.btn-secondary, .light-color),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button:is(.btn-secondary, .light-color),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button:is(.btn-secondary, .light-color),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button:is(.btn-secondary, .light-color) {
  --button-text-color: var(--color-gray-800);
  --button-text-color-hover: var(--color-gray-800);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button:is(.btn-secondary, .light-color) button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button:is(.btn-secondary, .light-color) button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button:is(.btn-secondary, .light-color) button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button:is(.btn-secondary, .light-color) button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button:is(.btn-secondary, .light-color) button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button:is(.btn-secondary, .light-color) button.btn {
  background-color: var(--button-secondary-bg-color);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.outline-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.outline-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.outline-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.outline-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.outline-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.outline-button {
  --button-text-color: var(--button-bg-color);
  --button-text-color-hover: var(--button-bg-color);
  --button-border-width: 1px;
  --button-border-color: var(--button-bg-color);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.outline-button:is(.btn-secondary, .light-color),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.outline-button:is(.btn-secondary, .light-color),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.outline-button:is(.btn-secondary, .light-color),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.outline-button:is(.btn-secondary, .light-color),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.outline-button:is(.btn-secondary, .light-color),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.outline-button:is(.btn-secondary, .light-color) {
  --button-border-color: var(--button-secondary-bg-color);
  --status-secondary-outline-border-color: rgba(70, 81, 170, 0.5);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.outline-button button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.outline-button button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.outline-button button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.outline-button button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.outline-button button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.outline-button button.btn {
  background-color: var(--button-outline-bg-color);
  border-color: var(--button-border-color);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.outline-button.btn-green,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.outline-button.btn-green,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.outline-button.btn-green,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.outline-button.btn-green,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.outline-button.btn-green,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.outline-button.btn-green {
  --button-border-color: #10B981;
  --button-text-color: #10B981;
  --button-text-color-hover: #10B981;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.outline-button.btn-blue,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.outline-button.btn-blue,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.outline-button.btn-blue,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.outline-button.btn-blue,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.outline-button.btn-blue,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.outline-button.btn-blue {
  --button-border-color: #4CAAE5;
  --button-text-color: #4CAAE5;
  --button-text-color-hover: #4CAAE5;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.outline-button.btn-orange,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.outline-button.btn-orange,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.outline-button.btn-orange,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.outline-button.btn-orange,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.outline-button.btn-orange,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.outline-button.btn-orange {
  --button-border-color: #FFA385;
  --button-text-color: #FFA385;
  --button-text-color-hover: #FFA385;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.outline-button.btn-red,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.outline-button.btn-red,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.outline-button.btn-red,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.outline-button.btn-red,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.outline-button.btn-red,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.outline-button.btn-red {
  --button-border-color: #F75F5F;
  --button-text-color: #F75F5F;
  --button-text-color-hover: #F75F5F;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.button-status,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.button-status,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.button-status,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.button-status,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.button-status,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.button-status {
  --button-border-radius: 50px;
  --button-shadow: none;
  --button-inline-padding: 20px;
  --status-bg-color: var(--color-primary-600);
  --status-secondary-bg-color: #E6E6EA;
  --status-outline-bg-color: transparent;
  --status-text-color: #FFFFFF;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.button-status button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.button-status button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.button-status button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.button-status button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.button-status button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.button-status button.btn {
  background-color: var(--status-bg-color);
  color: var(--status-text-color);
  font-weight: 600;
  border-radius: var(--button-border-radius);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.button-status.outline-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.button-status.outline-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.button-status.outline-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.button-status.outline-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.button-status.outline-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.button-status.outline-button {
  --status-outline-border-color: #070B34;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.button-status.outline-button button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.button-status.outline-button button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.button-status.outline-button button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.button-status.outline-button button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.button-status.outline-button button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.button-status.outline-button button.btn {
  background-color: var(--status-outline-bg-color);
  border-color: var(--status-outline-border-color);
  color: var(--status-outline-border-color);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.button-status.outline-button:is(.btn-secondary, .light-color),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.button-status.outline-button:is(.btn-secondary, .light-color),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.button-status.outline-button:is(.btn-secondary, .light-color),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.button-status.outline-button:is(.btn-secondary, .light-color),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.button-status.outline-button:is(.btn-secondary, .light-color),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.button-status.outline-button:is(.btn-secondary, .light-color) {
  --status-outline-secondary-bg-color: transparent;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.button-status.outline-button:is(.btn-secondary, .light-color) button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.button-status.outline-button:is(.btn-secondary, .light-color) button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.button-status.outline-button:is(.btn-secondary, .light-color) button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.button-status.outline-button:is(.btn-secondary, .light-color) button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.button-status.outline-button:is(.btn-secondary, .light-color) button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.button-status.outline-button:is(.btn-secondary, .light-color) button.btn {
  border-color: var(--status-secondary-outline-border-color);
  color: var(--status-outline-border-color);
  background-color: var(--status-outline-secondary-bg-color);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.button-status:is(.btn-secondary, .light-color),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.button-status:is(.btn-secondary, .light-color),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.button-status:is(.btn-secondary, .light-color),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.button-status:is(.btn-secondary, .light-color),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.button-status:is(.btn-secondary, .light-color),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.button-status:is(.btn-secondary, .light-color) {
  --status-text-color: var(--main-text-color);
  --status-text-color-hover: var(--main-text-color);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.button-status:is(.btn-secondary, .light-color) button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.button-status:is(.btn-secondary, .light-color) button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.button-status:is(.btn-secondary, .light-color) button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.button-status:is(.btn-secondary, .light-color) button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.button-status:is(.btn-secondary, .light-color) button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.button-status:is(.btn-secondary, .light-color) button.btn {
  background-color: var(--status-secondary-bg-color);
  color: var(--status-secondary-text-color);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.hover-feedback-off,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.hover-feedback-off,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.hover-feedback-off,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.hover-feedback-off,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.hover-feedback-off,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.hover-feedback-off {
  pointer-events: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.hover-feedback-off button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.hover-feedback-off button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.hover-feedback-off button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.hover-feedback-off button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.hover-feedback-off button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.hover-feedback-off button.btn {
  cursor: initial;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.formio-disabled-component button.btn[disabled],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.formio-disabled-component button.btn[disabled],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.formio-disabled-component button.btn[disabled],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.formio-disabled-component button.btn[disabled],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.formio-disabled-component button.btn[disabled],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.formio-disabled-component button.btn[disabled] {
  opacity: 0.4;
  --button-shadow: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.no-shadow button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.no-shadow button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.no-shadow button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.no-shadow button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.no-shadow button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.no-shadow button.btn {
  --button-shadow: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.my-account-service-button button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.my-account-service-button button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.my-account-service-button button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.my-account-service-button button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.my-account-service-button button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.my-account-service-button button.btn {
  border-radius: var(--my-account-service-button-border-radius);
  padding-inline: var(--my-account-service-button-inline-padding);
  padding-block: var(--my-account-service-button-block-padding);
  background-color: var(--my-account-service-button-bgcolor);
  color: var(--my-account-service-button-text-color);
  font-size: var(--my-account-service-button-text-size);
  font-weight: var(--my-account-service-button-font-weight);
  justify-content: start;
  display: grid !important;
  grid-template-columns: var(--my-account-service-button-icon-container-size) auto;
  grid-template-rows: auto auto;
  column-gap: var(--my-account-service-button-content-gap);
  row-gap: 0;
  box-shadow: var(--my-account-service-button-container-shadow);
  outline: var(--color-gray-75) solid 2px;
  transition-property: box-shadow;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.my-account-service-button button.btn .second-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.my-account-service-button button.btn .second-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.my-account-service-button button.btn .second-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.my-account-service-button button.btn .second-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.my-account-service-button button.btn .second-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.my-account-service-button button.btn .second-label {
  font-size: var(--my-account-service-button-extra-text-size);
  font-weight: inherit;
  grid-column-start: 2;
  grid-row-start: 2;
  grid-row-end: 3;
  align-self: start;
  padding-top: 5px;
  white-space: wrap;
  text-align: start;
  line-height: 1.4;
  opacity: 0.75;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.my-account-service-button button.btn span[class*="fa-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.my-account-service-button button.btn span[class*="fa-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.my-account-service-button button.btn span[class*="fa-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.my-account-service-button button.btn span[class*="fa-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.my-account-service-button button.btn span[class*="fa-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.my-account-service-button button.btn span[class*="fa-"] {
  background-color: var(--custom-my-account-service-button-icon-bgcolor, var(--my-account-service-button-icon-bgcolor));
  width: var(--my-account-service-button-icon-container-size);
  height: var(--my-account-service-button-icon-container-size);
  font-size: var(--my-account-service-button-icon-size);
  border-radius: var(--my-account-service-button-icon-container-size);
  place-content: center;
  grid-row-start: 1;
  grid-row-end: 3;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.my-account-service-button button.btn span[class*="fa-"] + span:not(:last-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.my-account-service-button button.btn span[class*="fa-"] + span:not(:last-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.my-account-service-button button.btn span[class*="fa-"] + span:not(:last-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.my-account-service-button button.btn span[class*="fa-"] + span:not(:last-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.my-account-service-button button.btn span[class*="fa-"] + span:not(:last-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.my-account-service-button button.btn span[class*="fa-"] + span:not(:last-child) {
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 2;
  align-self: end;
  white-space: break-spaces;
  text-align: start;
  line-height: 1.25;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.my-account-service-button button.btn span[class*="fa-"] + span:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.my-account-service-button button.btn span[class*="fa-"] + span:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.my-account-service-button button.btn span[class*="fa-"] + span:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.my-account-service-button button.btn span[class*="fa-"] + span:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.my-account-service-button button.btn span[class*="fa-"] + span:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.my-account-service-button button.btn span[class*="fa-"] + span:last-child {
  grid-row-start: 1;
  grid-row-end: 3;
  white-space: break-spaces;
  line-height: 1.4;
  text-align: start;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.my-account-service-button button.btn:has(span:not([class*="fa-"]):first-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.my-account-service-button button.btn:has(span:not([class*="fa-"]):first-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.my-account-service-button button.btn:has(span:not([class*="fa-"]):first-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.my-account-service-button button.btn:has(span:not([class*="fa-"]):first-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.my-account-service-button button.btn:has(span:not([class*="fa-"]):first-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.my-account-service-button button.btn:has(span:not([class*="fa-"]):first-child) {
  min-height: 92px;
  place-content: center flex-start;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.my-account-service-button button.btn span:not([class*="fa-"]):first-child + .second-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.my-account-service-button button.btn span:not([class*="fa-"]):first-child + .second-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.my-account-service-button button.btn span:not([class*="fa-"]):first-child + .second-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.my-account-service-button button.btn span:not([class*="fa-"]):first-child + .second-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.my-account-service-button button.btn span:not([class*="fa-"]):first-child + .second-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.my-account-service-button button.btn span:not([class*="fa-"]):first-child + .second-label {
  grid-column-start: 1;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.my-account-service-button button.btn span:not([class*="fa-"], .second-label):first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.my-account-service-button button.btn span:not([class*="fa-"], .second-label):first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.my-account-service-button button.btn span:not([class*="fa-"], .second-label):first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.my-account-service-button button.btn span:not([class*="fa-"], .second-label):first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.my-account-service-button button.btn span:not([class*="fa-"], .second-label):first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.my-account-service-button button.btn span:not([class*="fa-"], .second-label):first-child {
  white-space: break-spaces;
  line-height: 1.25;
  text-align: start;
  grid-column-start: 1;
  grid-column-end: 3;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.my-account-service-button button.btn:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.my-account-service-button button.btn:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.my-account-service-button button.btn:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.my-account-service-button button.btn:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.my-account-service-button button.btn:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.my-account-service-button button.btn:hover {
  box-shadow: var(--my-account-service-button-container-shadow-hover);
  outline: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.my-account-service-button button.btn [ref="tooltip"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.my-account-service-button button.btn [ref="tooltip"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.my-account-service-button button.btn [ref="tooltip"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.my-account-service-button button.btn [ref="tooltip"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.my-account-service-button button.btn [ref="tooltip"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.my-account-service-button button.btn [ref="tooltip"] {
  position: absolute;
  inset-inline-end: 0px;
  color: var(--color-gray-600);
  border-radius: 50px;
  width: 16px;
  height: 16px;
  top: 50%;
  translate: -16px -50%;
  font-family: var(--fontawesome-font);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.my-account-service-button button.btn [ref="tooltip"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.my-account-service-button button.btn [ref="tooltip"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.my-account-service-button button.btn [ref="tooltip"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.my-account-service-button button.btn [ref="tooltip"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.my-account-service-button button.btn [ref="tooltip"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.my-account-service-button button.btn [ref="tooltip"]::before {
  inset: initial !important;
  left: 0 !important;
  font-size: 18px;
  font-weight: 400;
  width: 9px;
  border-radius: 10px;
  height: 9px !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.my-account-service-button button.btn [ref="tooltip"]::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.my-account-service-button button.btn [ref="tooltip"]::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.my-account-service-button button.btn [ref="tooltip"]::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.my-account-service-button button.btn [ref="tooltip"]::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.my-account-service-button button.btn [ref="tooltip"]::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.my-account-service-button button.btn [ref="tooltip"]::after {
  content: attr(data-tooltip) !important;
  font-family: var(--main-font-family) !important;
  line-height: 1.5 !important;
  white-space: normal;
  background-color: var(--color-gray-900);
  color: white;
  border-radius: 10px;
  padding: 16px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  width: 300px;
  text-align: center;
  display: none;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05) !important;
  left: 50% !important;
  translate: -50% 16px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.my-account-service-button button.btn [ref="tooltip"]:hover::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.my-account-service-button button.btn [ref="tooltip"]:hover::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.my-account-service-button button.btn [ref="tooltip"]:hover::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.my-account-service-button button.btn [ref="tooltip"]:hover::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.my-account-service-button button.btn [ref="tooltip"]:hover::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.my-account-service-button button.btn [ref="tooltip"]:hover::after {
  display: table;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.my-account-service-button button.btn [ref="tooltip"]:hover::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.my-account-service-button button.btn [ref="tooltip"]:hover::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.my-account-service-button button.btn [ref="tooltip"]:hover::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.my-account-service-button button.btn [ref="tooltip"]:hover::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.my-account-service-button button.btn [ref="tooltip"]:hover::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.my-account-service-button button.btn [ref="tooltip"]:hover::before {
  box-shadow: 4px 20px 0px var(--color-gray-900) !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.my-account-service-button button.btn:has([ref="tooltip"]) button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.my-account-service-button button.btn:has([ref="tooltip"]) button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.my-account-service-button button.btn:has([ref="tooltip"]) button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.my-account-service-button button.btn:has([ref="tooltip"]) button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.my-account-service-button button.btn:has([ref="tooltip"]) button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.my-account-service-button button.btn:has([ref="tooltip"]) button.btn {
  padding-inline-end: 36px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.my-account-service-button.formio-component-disabled button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.my-account-service-button.formio-component-disabled button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.my-account-service-button.formio-component-disabled button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.my-account-service-button.formio-component-disabled button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.my-account-service-button.formio-component-disabled button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.my-account-service-button.formio-component-disabled button {
  filter: grayscale(1);
  opacity: 0.35;
  background-color: var(--my-account-service-button-disabled-bgcolor);
  color: var(--my-account-service-button-disabled-text-color);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.my-account-service-button:has([ref="tooltip"]):hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.my-account-service-button:has([ref="tooltip"]):hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.my-account-service-button:has([ref="tooltip"]):hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.my-account-service-button:has([ref="tooltip"]):hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.my-account-service-button:has([ref="tooltip"]):hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.my-account-service-button:has([ref="tooltip"]):hover {
  z-index: 9;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.my-account-service-button:has([ref="tooltip"]) .tooltip,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.my-account-service-button:has([ref="tooltip"]) .tooltip,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.my-account-service-button:has([ref="tooltip"]) .tooltip,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.my-account-service-button:has([ref="tooltip"]) .tooltip,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.my-account-service-button:has([ref="tooltip"]) .tooltip,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.my-account-service-button:has([ref="tooltip"]) .tooltip {
  display: none !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.inline-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.inline-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.inline-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.inline-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.inline-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.inline-button {
  display: inline-block;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.left-space,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.left-space,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.left-space,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.left-space,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.left-space,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.left-space {
  margin-inline-start: var(--inline-space);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.left-space-2x,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.left-space-2x,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.left-space-2x,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.left-space-2x,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.left-space-2x,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.left-space-2x {
  margin-inline-start: var(--inline-space-2x);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.left-space-3x,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.left-space-3x,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.left-space-3x,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.left-space-3x,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.left-space-3x,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.left-space-3x {
  margin-inline-start: var(--inline-space-3x);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.right-space,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.right-space,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.right-space,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.right-space,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.right-space,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.right-space {
  margin-inline-end: var(--inline-space);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.right-space-2x,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.right-space-2x,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.right-space-2x,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.right-space-2x,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.right-space-2x,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.right-space-2x {
  margin-inline-end: var(--inline-space-2x);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-button.right-space-3x,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-button.right-space-3x,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-button.right-space-3x,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-button.right-space-3x,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-button.right-space-3x,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-button.right-space-3x {
  margin-inline-end: var(--inline-space-3x);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) :is(h1, h2, h3, h4, h5, h6),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) :is(h1, h2, h3, h4, h5, h6),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) :is(h1, h2, h3, h4, h5, h6),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) :is(h1, h2, h3, h4, h5, h6),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) :is(h1, h2, h3, h4, h5, h6),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) :is(h1, h2, h3, h4, h5, h6) {
  line-height: 1.3;
  padding: 0;
  margin: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) h2,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) h2,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) h2,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) h2,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) h2,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) h2 {
  font-size: calc(12pt * 2);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) h3,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) h3,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) h3,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) h3,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) h3,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) h3 {
  font-size: calc(12pt * 1.8);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) h4,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) h4,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) h4,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) h4,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) h4,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) h4 {
  font-size: calc(12pt * 1.6);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) p,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) p,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) p,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) p,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) p,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) p {
  font-size: 16px !important;
  color: var(--custom-text-color, var(--color-gray-800)) !important;
  margin-top: 0;
  margin-bottom: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) p span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span {
  font-size: 16px;
  color: inherit;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-tiny,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-tiny,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) p span.text-tiny,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-tiny,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-tiny,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-tiny {
  font-size: 9px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-tiny *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-tiny *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) p span.text-tiny *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-tiny *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-tiny *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-tiny * {
  font-size: 9px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-tiny span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-tiny span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) p span.text-tiny span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-tiny span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-tiny span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-tiny span {
  background-color: transparent !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-small,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-small,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) p span.text-small,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-small,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-small,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-small {
  font-size: 11px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-small *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-small *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) p span.text-small *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-small *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-small *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-small * {
  font-size: 11px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-big,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-big,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) p span.text-big,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-big,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-big,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-big {
  font-size: 18px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-big *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-big *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) p span.text-big *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-big *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-big *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-big * {
  font-size: 18px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-huge,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-huge,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) p span.text-huge,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-huge,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-huge,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-huge {
  font-size: 24px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-huge *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-huge *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) p span.text-huge *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-huge *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-huge *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.text-huge * {
  font-size: 24px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.currency-prefixed,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.currency-prefixed,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) p span.currency-prefixed,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.currency-prefixed,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.currency-prefixed,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.currency-prefixed {
  margin-inline-end: 5px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.currency-suffixed:not(:empty),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.currency-suffixed:not(:empty),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) p span.currency-suffixed:not(:empty),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.currency-suffixed:not(:empty),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.currency-suffixed:not(:empty),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) p span.currency-suffixed:not(:empty) {
  margin-inline-start: 5px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) p:empty + p,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) p:empty + p,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) p:empty + p,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) p:empty + p,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) p:empty + p,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) p:empty + p {
  margin-top: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) p:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) p:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) p:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) p:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) p:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) p:first-child {
  margin-top: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) strong,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) strong,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) strong,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) strong,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) strong,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) strong {
  font-weight: 700;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) :is(ul, ol),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) :is(ul, ol),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) :is(ul, ol),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) :is(ul, ol),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) :is(ul, ol),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) :is(ul, ol) {
  margin-top: 0px;
  margin-bottom: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) ol,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol {
  list-style: none;
  counter-reset: li;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) ol li,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li {
  font-size: 16px;
  color: var(--color-gray-800);
  padding-inline-start: 2.4rem;
  position: relative;
  line-height: 1.5;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) ol li::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li::before {
  content: counter(li) ".";
  display: inline-block;
  width: 1em;
  inset-inline-start: 0;
  counter-increment: li;
  position: absolute;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) ol li span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span {
  font-size: 16px;
  color: var(--color-gray-800);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-tiny,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-tiny,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-tiny,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-tiny,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-tiny,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-tiny {
  font-size: 12px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-tiny *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-tiny *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-tiny *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-tiny *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-tiny *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-tiny * {
  font-size: 12px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-tiny span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-tiny span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-tiny span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-tiny span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-tiny span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-tiny span {
  background-color: transparent !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-small,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-small,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-small,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-small,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-small,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-small {
  font-size: 14px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-small *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-small *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-small *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-small *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-small *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-small * {
  font-size: 14px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-big,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-big,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-big,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-big,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-big,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-big {
  font-size: 20px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-big *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-big *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-big *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-big *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-big *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-big * {
  font-size: 20px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-huge,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-huge,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-huge,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-huge,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-huge,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-huge {
  font-size: 24px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-huge *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-huge *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-huge *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-huge *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-huge *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) ol li span.text-huge * {
  font-size: 24px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) ul,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) ul,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) ul,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) ul,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) ul,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) ul {
  margin-top: 0;
  padding-inline-start: 1.8rem;
  padding-block: 0.6rem;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) ul li,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) ul li,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) ul li,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) ul li,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) ul li,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) ul li {
  font-size: 16px;
  color: var(--color-gray-800);
  line-height: 1.5;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) ul li + li,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) ul li + li,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) ul li + li,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) ul li + li,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) ul li + li,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) ul li + li {
  margin-top: 0.5rem;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) p:not(:empty) + ul,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) p:not(:empty) + ul,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) p:not(:empty) + ul,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) p:not(:empty) + ul,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) p:not(:empty) + ul,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) p:not(:empty) + ul,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) p:not(:empty) + ol,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) p:not(:empty) + ol,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) p:not(:empty) + ol,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) p:not(:empty) + ol,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) p:not(:empty) + ol,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) p:not(:empty) + ol {
  margin-top: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) table,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) table,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) table,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) table,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) table,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) table {
  width: 100%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) table tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td {
  font-size: 16px;
  color: var(--color-gray-800);
  border: solid 1px #ebebeb;
  padding: 0 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-tiny,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-tiny,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-tiny,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-tiny,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-tiny,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-tiny {
  font-size: 9px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-tiny *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-tiny *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-tiny *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-tiny *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-tiny *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-tiny * {
  font-size: 9px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-tiny span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-tiny span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-tiny span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-tiny span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-tiny span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-tiny span {
  background-color: transparent !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-small,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-small,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-small,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-small,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-small,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-small {
  font-size: 11px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-small *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-small *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-small *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-small *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-small *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-small * {
  font-size: 11px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-big,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-big,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-big,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-big,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-big,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-big {
  font-size: 18px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-big *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-big *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-big *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-big *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-big *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-big * {
  font-size: 18px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-huge,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-huge,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-huge,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-huge,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-huge,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-huge {
  font-size: 24px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-huge *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-huge *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-huge *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-huge *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-huge *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) table tr td span.text-huge * {
  font-size: 24px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) blockquote,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) blockquote,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) blockquote,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) blockquote,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) blockquote,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) blockquote {
  background: white !important;
  border-inline-start: solid 3px;
  border-radius: 0 6px 6px 0;
  padding: 0 2rem !important;
  margin: 0;
  border-left-color: var(--color-primary-600) !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) blockquote::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) blockquote::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) blockquote::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) blockquote::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) blockquote::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) blockquote::after {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) p:empty + blockquote,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) p:empty + blockquote,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) p:empty + blockquote,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) p:empty + blockquote,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) p:empty + blockquote,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) p:empty + blockquote {
  border-top: solid 5px transparent !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content)[class*="notice"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content)[class*="notice"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content)[class*="notice"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content)[class*="notice"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content)[class*="notice"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content)[class*="notice"] {
  --panel-bg-color: rgba(27, 35, 49, 0.03);
  --notice-color-rgb: var(--color-primary-600);
  background-color: var(--panel-bg-color) !important;
  border-inline-start: solid 4px;
  border-inline-start-color: rgba(var(--notice-color-rgb), 1);
  border-radius: var(--notice-corner-radius, 0 6px 6px 0);
  padding: var(--colored-bg-content-padding-y, 15px) var(--colored-bg-content-padding-x, 20px);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content)[class*="notice"].fill-background,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content)[class*="notice"].fill-background,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content)[class*="notice"].fill-background,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content)[class*="notice"].fill-background,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content)[class*="notice"].fill-background,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content)[class*="notice"].fill-background {
  border-radius: var(--notice-corner-radius, 0 6px 6px 0);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content).notice-blue,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content).notice-blue,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content).notice-blue,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content).notice-blue,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content).notice-blue,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content).notice-blue {
  --notice-color-rgb: 76, 170, 229;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content).notice-green,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content).notice-green,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content).notice-green,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content).notice-green,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content).notice-green,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content).notice-green {
  --notice-color-rgb: 16, 185, 129;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content).notice-orange,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content).notice-orange,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content).notice-orange,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content).notice-orange,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content).notice-orange,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content).notice-orange {
  --notice-color-rgb: 255, 163, 133;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content).notice-red,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content).notice-red,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content).notice-red,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content).notice-red,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content).notice-red,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content).notice-red {
  --notice-color-rgb: 247, 95, 95;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content):is(.fill-background, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content):is(.fill-background, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content):is(.fill-background, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content):is(.fill-background, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content):is(.fill-background, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content):is(.fill-background, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) {
  background-color: var(--panel-bg-color);
  border-radius: var(--notice-corner-radius, 6px);
  padding: var(--colored-bg-content-padding-y, 15px) var(--colored-bg-content-padding-x, 20px) !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content):is(.fill-background, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white).notice,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content):is(.fill-background, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white).notice,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content):is(.fill-background, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white).notice,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content):is(.fill-background, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white).notice,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content):is(.fill-background, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white).notice,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content):is(.fill-background, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white).notice {
  border-radius: 0 6px 6px 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content):is(.fill-background, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white):is(.top-label-space, .top-space, .top-space-2x, .top-space-3x),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content):is(.fill-background, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white):is(.top-label-space, .top-space, .top-space-2x, .top-space-3x),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content):is(.fill-background, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white):is(.top-label-space, .top-space, .top-space-2x, .top-space-3x),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content):is(.fill-background, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white):is(.top-label-space, .top-space, .top-space-2x, .top-space-3x),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content):is(.fill-background, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white):is(.top-label-space, .top-space, .top-space-2x, .top-space-3x),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content):is(.fill-background, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white):is(.top-label-space, .top-space, .top-space-2x, .top-space-3x) {
  --colored-bg-content-padding-y: 15px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content):is(.background-red, .error-notice),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content):is(.background-red, .error-notice),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content):is(.background-red, .error-notice),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content):is(.background-red, .error-notice),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content):is(.background-red, .error-notice),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content):is(.background-red, .error-notice) {
  --panel-bg-color: #FEE7E7;
  --notice-color-rgb: 247, 95, 95;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content):is(.background-blue, .info-notice),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content):is(.background-blue, .info-notice),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content):is(.background-blue, .info-notice),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content):is(.background-blue, .info-notice),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content):is(.background-blue, .info-notice),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content):is(.background-blue, .info-notice) {
  --panel-bg-color: #E4F2FB;
  --notice-color-rgb: 76, 170, 229;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content):is(.background-green, .success-notice),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content):is(.background-green, .success-notice),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content):is(.background-green, .success-notice),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content):is(.background-green, .success-notice),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content):is(.background-green, .success-notice),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content):is(.background-green, .success-notice) {
  --panel-bg-color: #DBF5EC;
  --notice-color-rgb: 16, 185, 129;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content):is(.background-orange, .warning-notice),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content):is(.background-orange, .warning-notice),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content):is(.background-orange, .warning-notice),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content):is(.background-orange, .warning-notice),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content):is(.background-orange, .warning-notice),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content):is(.background-orange, .warning-notice) {
  --panel-bg-color: #FFF1ED;
  --notice-color-rgb: 255, 163, 133;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content).background-gray,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content).background-gray,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content).background-gray,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content).background-gray,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content).background-gray,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content).background-gray {
  --panel-bg-color: var(--color-gray-75);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content).background-primary,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content).background-primary,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content).background-primary,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content).background-primary,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content).background-primary,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content).background-primary {
  --panel-bg-color: rgba(70, 81, 170, 0.075);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content).background-white,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content).background-white,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content).background-white,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content).background-white,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content).background-white,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content).background-white {
  --panel-bg-color: #FFFFFF;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert[class*="reason"].editgrid-validation-reason,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert[class*="reason"].editgrid-validation-reason,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content).alert[class*="reason"].editgrid-validation-reason,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert[class*="reason"].editgrid-validation-reason,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert[class*="reason"].editgrid-validation-reason,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert[class*="reason"].editgrid-validation-reason {
  margin-bottom: -18px !important;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert[class*="reason"].editgrid-validation-reason::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert[class*="reason"].editgrid-validation-reason::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content).alert[class*="reason"].editgrid-validation-reason::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert[class*="reason"].editgrid-validation-reason::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert[class*="reason"].editgrid-validation-reason::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert[class*="reason"].editgrid-validation-reason::before {
  content: "" !important;
  display: block !important;
  width: 0px;
  height: 0px;
  border-width: 10px 8px 0px 8px;
  border-color: #fff7f7 transparent transparent;
  border-style: solid solid none;
  position: absolute;
  z-index: 9;
  bottom: -10px;
  filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.15));
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert[class*="reason"]:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert[class*="reason"]:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content).alert[class*="reason"]:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert[class*="reason"]:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert[class*="reason"]:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert[class*="reason"]:first-child {
  margin: 0 !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert[class*="reason"]:not(.formio-hidden) ~ .formio-component-file,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert[class*="reason"]:not(.formio-hidden) ~ .formio-component-file,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content).alert[class*="reason"]:not(.formio-hidden) ~ .formio-component-file,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert[class*="reason"]:not(.formio-hidden) ~ .formio-component-file,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert[class*="reason"]:not(.formio-hidden) ~ .formio-component-file,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert[class*="reason"]:not(.formio-hidden) ~ .formio-component-file {
  margin-top: 1rem !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert.alert-danger:not(.formio-hidden, .hide) ~ .guide-form,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert.alert-danger:not(.formio-hidden, .hide) ~ .guide-form,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content).alert.alert-danger:not(.formio-hidden, .hide) ~ .guide-form,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert.alert-danger:not(.formio-hidden, .hide) ~ .guide-form,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert.alert-danger:not(.formio-hidden, .hide) ~ .guide-form,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content).alert.alert-danger:not(.formio-hidden, .hide) ~ .guide-form {
  margin-top: 30px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) .image:only-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) .image:only-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) .image:only-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) .image:only-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) .image:only-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) .image:only-child {
  margin: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) .image:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) .image:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) .image:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) .image:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) .image:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) .image:first-child {
  margin-top: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) .image:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) .image:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) .image:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) .image:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) .image:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) .image:last-child {
  margin-bottom: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) p + a[href="#"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) p + a[href="#"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) p + a[href="#"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) p + a[href="#"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) p + a[href="#"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) p + a[href="#"] {
  margin-top: 5px;
  display: inline-block;
  text-decoration: none;
  font-weight: 600;
  border-bottom: solid 1px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-htmlelement, .formio-component-content) p + a[href="#"]:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-htmlelement, .formio-component-content) p + a[href="#"]:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-htmlelement, .formio-component-content) p + a[href="#"]:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-htmlelement, .formio-component-content) p + a[href="#"]:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-htmlelement, .formio-component-content) p + a[href="#"]:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-htmlelement, .formio-component-content) p + a[href="#"]:hover {
  color: var(--color-black);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-textarea textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-textarea textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-textarea textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-textarea textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-textarea textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-textarea textarea.form-control {
  border: solid 1px var(--color-gray-300);
  border-radius: 6px !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-textarea textarea.form-control:where(:hover, :focus),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-textarea textarea.form-control:where(:hover, :focus),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-textarea textarea.form-control:where(:hover, :focus),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-textarea textarea.form-control:where(:hover, :focus),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-textarea textarea.form-control:where(:hover, :focus),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-textarea textarea.form-control:where(:hover, :focus) {
  outline: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-textarea textarea.form-control::-webkit-resizer,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-textarea textarea.form-control::-webkit-resizer,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-textarea textarea.form-control::-webkit-resizer,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-textarea textarea.form-control::-webkit-resizer,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-textarea textarea.form-control::-webkit-resizer,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-textarea textarea.form-control::-webkit-resizer {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-textarea.has-error textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-textarea.has-error textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-textarea.has-error textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-textarea.has-error textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-textarea.has-error textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-textarea.has-error textarea.form-control {
  border-color: rgba(255, 0, 59, 0.3) !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-textarea .card.card-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-textarea .card.card-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-textarea .card.card-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-textarea .card.card-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-textarea .card.card-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-textarea .card.card-body {
  margin-bottom: 0 !important;
  padding: 0 !important;
  background-color: transparent;
  box-shadow: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-textarea .card.card-body .formio-editor-read-only-content,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-textarea .card.card-body .formio-editor-read-only-content,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-textarea .card.card-body .formio-editor-read-only-content,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-textarea .card.card-body .formio-editor-read-only-content,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-textarea .card.card-body .formio-editor-read-only-content,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-textarea .card.card-body .formio-editor-read-only-content {
  min-height: var(--field-height);
  line-height: 1.5;
  padding: 8px 14px;
  font-size: var(--field-font-size);
  font-weight: var(--field-font-weight, 400) !important;
  background-color: var(--color-gray-100);
  letter-spacing: 0.4pt !important;
  color: var(--main-text-color);
  border: solid 1px var(--color-gray-300);
  border-radius: 6px !important;
  align-items: center;
  display: flex;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-textarea.field-plus-button textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-textarea.field-plus-button textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-textarea.field-plus-button textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-textarea.field-plus-button textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-textarea.field-plus-button textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-textarea.field-plus-button textarea.form-control {
  border-top-right-radius: 0 !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-textarea.value-label-style textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-textarea.value-label-style textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-textarea.value-label-style textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-textarea.value-label-style textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-textarea.value-label-style textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-textarea.value-label-style textarea.form-control {
  border: none;
  background: transparent;
  resize: none;
  padding: 0;
  line-height: 1;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-textarea.value-label-style .card.card-body .formio-editor-read-only-content,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-textarea.value-label-style .card.card-body .formio-editor-read-only-content,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-textarea.value-label-style .card.card-body .formio-editor-read-only-content,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-textarea.value-label-style .card.card-body .formio-editor-read-only-content,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-textarea.value-label-style .card.card-body .formio-editor-read-only-content,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-textarea.value-label-style .card.card-body .formio-editor-read-only-content {
  border: none;
  padding-inline: 0px;
  background-color: transparent;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-selectboxes .form-radio.radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-selectboxes .form-radio.radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-selectboxes .form-radio.radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-selectboxes .form-radio.radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-selectboxes .form-radio.radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-selectboxes .form-radio.radio {
  padding-top: 7px;
  margin-top: 0;
  display: flex;
  flex-wrap: wrap;
  column-gap: 28px;
  row-gap: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) {
  cursor: pointer;
  padding-bottom: 10px;
  margin-top: 0;
  margin-bottom: 0;
  margin-inline-end: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label {
  margin-top: 0;
  gap: 12px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"] {
  appearance: inherit;
  inset-inline-start: initial !important;
  width: initial;
  padding: 0;
  margin: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"]::before {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  font-size: 16px;
  content: "\F0C8";
  color: rgba(70, 81, 170, 0.5);
  margin-top: 1px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"] + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"] + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"] + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"] + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"] + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"] + span {
  font-size: 16px;
  font-weight: 600;
  color: rgba(70, 81, 170, 0.5);
  margin-inline-start: 3px;
  position: relative;
  top: 1px;
  line-height: 1.5;
  padding-inline-start: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"] + span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"] + span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"] + span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"] + span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"] + span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"] + span::before {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"]:checked::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"]:checked::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"]:checked::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"]:checked::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"]:checked::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"]:checked::before {
  content: "\F14A";
  font-weight: 900;
  color: var(--color-primary-600);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"]:checked + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"]:checked + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"]:checked + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"]:checked + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"]:checked + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"]:checked + span {
  color: var(--color-primary-600);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"]:focus,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"]:focus,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"]:focus,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"]:focus,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"]:focus,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[type="checkbox"]:focus {
  outline: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[disabled],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[disabled],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[disabled],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[disabled],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[disabled],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label input[disabled] {
  background-color: transparent !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label:hover input[type="radio"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label:hover input[type="radio"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label:hover input[type="radio"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label:hover input[type="radio"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label:hover input[type="radio"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label:hover input[type="radio"] {
  appearance: inherit;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label:hover input[type="radio"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label:hover input[type="radio"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label:hover input[type="radio"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label:hover input[type="radio"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label:hover input[type="radio"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label:hover input[type="radio"]::before {
  color: var(--color-primary-600);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label:hover input[type="radio"] + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label:hover input[type="radio"] + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label:hover input[type="radio"] + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label:hover input[type="radio"] + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label:hover input[type="radio"] + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label:hover input[type="radio"] + span {
  color: var(--color-primary-600);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline) label span {
  color: black !important;
  font-weight: 400 !important;
  margin-inline-start: 0px !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline).radio-selected label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline).radio-selected label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline).radio-selected label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline).radio-selected label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline).radio-selected label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-selectboxes .form-radio.radio :where(.form-check, .form-check-inline).radio-selected label span {
  opacity: 1;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-selectboxes .form-radio.radio .form-check,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-selectboxes .form-radio.radio .form-check,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-selectboxes .form-radio.radio .form-check,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-selectboxes .form-radio.radio .form-check,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-selectboxes .form-radio.radio .form-check,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-selectboxes .form-radio.radio .form-check {
  width: 100%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-selectboxes.remove-top-space .form-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-selectboxes.remove-top-space .form-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-selectboxes.remove-top-space .form-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-selectboxes.remove-top-space .form-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-selectboxes.remove-top-space .form-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-selectboxes.remove-top-space .form-radio {
  padding-top: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid {
  scroll-margin-top: 68px;
  --editgrid-padding: 24px;
  --editgrid-border-color: var(--color-gray-200);
  --editgrid-border-width: 1px;
  --editgrid-bg-color: #FFFFFF;
  min-width: 100%;
  padding: var(--editgrid-padding, 0px);
  border-radius: 6px;
  border: solid var(--editgrid-border-width, 1px) var(--editgrid-border-color, #EDEEF7);
  background-color: var(--editgrid-bg-color, transparent);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid > label.col-form-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid > label.col-form-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid > label.col-form-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid > label.col-form-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid > label.col-form-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid > label.col-form-label {
  --label-font-size: 16px;
  --label-text-color: var(--color-gray-600);
  --label-bottom-margin: 24px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup {
  border: none;
  border-radius: 6px 6px 0 0;
  overflow-x: auto;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup > .scroll-padding,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup > .scroll-padding,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup > .scroll-padding,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup > .scroll-padding,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup > .scroll-padding,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup > .scroll-padding {
  display: block;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header {
  border-bottom: none;
  background-color: var(--color-gray-100);
  border-radius: 6px 6px 0 0 !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row {
  margin-inline: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div {
  border-inline-end: none;
  color: var(--color-primary-600);
  font-weight: 600;
  font-size: 13px;
  padding-top: 6px;
  padding-bottom: 6px;
  min-height: 32px;
  line-height: 19px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div.radio label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div.radio label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div.radio label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div.radio label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div.radio label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div.radio label {
  padding-inline-start: 0;
  min-height: auto;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more {
  background: transparent;
  text-align: center;
  padding: 2px 12px;
  box-shadow: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more .fa,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more .fa,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more .fa,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more .fa,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more .fa,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more .fa {
  margin: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more .fa[ref="tooltip"]::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more .fa[ref="tooltip"]::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more .fa[ref="tooltip"]::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more .fa[ref="tooltip"]::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more .fa[ref="tooltip"]::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more .fa[ref="tooltip"]::after {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more > span[ref="tooltip"]:not([data-position])::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more > span[ref="tooltip"]:not([data-position])::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more > span[ref="tooltip"]:not([data-position])::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more > span[ref="tooltip"]:not([data-position])::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more > span[ref="tooltip"]:not([data-position])::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more > span[ref="tooltip"]:not([data-position])::after {
  left: auto;
  right: 0;
  top: 100%;
  bottom: auto;
  transform: translateX(-30px) translateY(-30px);
  padding: 8px;
  margin-bottom: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more > span[ref="tooltip"]:not([data-position])::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more > span[ref="tooltip"]:not([data-position])::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more > span[ref="tooltip"]:not([data-position])::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more > span[ref="tooltip"]:not([data-position])::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more > span[ref="tooltip"]:not([data-position])::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more > span[ref="tooltip"]:not([data-position])::before {
  transform: rotate(-45deg) translateY(-50%) scale(1);
  left: calc(0px - 100% - 6px);
  transition-delay: 0.04s !important;
  transition: left 0.2s ease, opacity 0.2s ease;
  top: 50%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more > span[ref="tooltip"]:not([data-position]):hover::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more > span[ref="tooltip"]:not([data-position]):hover::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more > span[ref="tooltip"]:not([data-position]):hover::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more > span[ref="tooltip"]:not([data-position]):hover::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more > span[ref="tooltip"]:not([data-position]):hover::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more > span[ref="tooltip"]:not([data-position]):hover::after {
  transform: translateX(-36px) translateY(-30px) scale(1);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more > span[ref="tooltip"]:not([data-position]):hover::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more > span[ref="tooltip"]:not([data-position]):hover::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more > span[ref="tooltip"]:not([data-position]):hover::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more > span[ref="tooltip"]:not([data-position]):hover::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more > span[ref="tooltip"]:not([data-position]):hover::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more > span[ref="tooltip"]:not([data-position]):hover::before {
  left: calc(0px - 100% - 12px);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more > span[ref="tooltip"]:has(+.tooltip)::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more > span[ref="tooltip"]:has(+.tooltip)::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more > span[ref="tooltip"]:has(+.tooltip)::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more > span[ref="tooltip"]:has(+.tooltip)::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more > span[ref="tooltip"]:has(+.tooltip)::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more > span[ref="tooltip"]:has(+.tooltip)::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more > span[ref="tooltip"]:has(+.tooltip)::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more > span[ref="tooltip"]:has(+.tooltip)::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more > span[ref="tooltip"]:has(+.tooltip)::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more > span[ref="tooltip"]:has(+.tooltip)::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more > span[ref="tooltip"]:has(+.tooltip)::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .row > div .formio-button-more > span[ref="tooltip"]:has(+.tooltip)::before {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header + .list-group-footer,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header + .list-group-footer,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header + .list-group-footer,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header + .list-group-footer,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header + .list-group-footer,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header + .list-group-footer {
  margin-top: 8px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item > .row > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item > .row > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item > .row > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item > .row > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item > .row > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item > .row > div {
  padding-top: 16px;
  padding-bottom: 16px !important;
  color: inherit;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item > .row > div .btn-icon-toggle,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item > .row > div .btn-icon-toggle,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item > .row > div .btn-icon-toggle,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item > .row > div .btn-icon-toggle,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item > .row > div .btn-icon-toggle,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item > .row > div .btn-icon-toggle {
  color: var(--color-gray-500);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item > .row > div .btn-icon-toggle:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item > .row > div .btn-icon-toggle:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item > .row > div .btn-icon-toggle:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item > .row > div .btn-icon-toggle:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item > .row > div .btn-icon-toggle:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item > .row > div .btn-icon-toggle:hover {
  color: var(--color-gray-700);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item > .row > div .btn-icon-toggle.removeRow,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item > .row > div .btn-icon-toggle.removeRow,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item > .row > div .btn-icon-toggle.removeRow,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item > .row > div .btn-icon-toggle.removeRow,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item > .row > div .btn-icon-toggle.removeRow,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item > .row > div .btn-icon-toggle.removeRow {
  color: var(--color-trash);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item > .row > div .btn-icon-toggle.removeRow:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item > .row > div .btn-icon-toggle.removeRow:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item > .row > div .btn-icon-toggle.removeRow:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item > .row > div .btn-icon-toggle.removeRow:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item > .row > div .btn-icon-toggle.removeRow:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item > .row > div .btn-icon-toggle.removeRow:hover {
  color: var(--color-trash-hover);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item:is(.new-entry),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item:is(.new-entry),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item:is(.new-entry),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item:is(.new-entry),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item:is(.new-entry),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item:is(.new-entry) {
  --top-padding: 24px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item.list-group-footer > .row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item.list-group-footer > .row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item.list-group-footer > .row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item.list-group-footer > .row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item.list-group-footer > .row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item.list-group-footer > .row {
  margin-inline: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item.list-group-footer > .row > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item.list-group-footer > .row > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item.list-group-footer > .row > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item.list-group-footer > .row > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item.list-group-footer > .row > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item.list-group-footer > .row > div {
  color: var(--color-gray-800);
  font-weight: 600;
  padding-top: 16px;
  padding-bottom: 16px;
  min-height: 50px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item.list-group-footer > .row > div b,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item.list-group-footer > .row > div b,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item.list-group-footer > .row > div b,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item.list-group-footer > .row > div b,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item.list-group-footer > .row > div b,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item.list-group-footer > .row > div b {
  font-weight: inherit;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item.list-group-footer > .row > div.alert,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item.list-group-footer > .row > div.alert,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item.list-group-footer > .row > div.alert,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item.list-group-footer > .row > div.alert,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item.list-group-footer > .row > div.alert,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item.list-group-footer > .row > div.alert {
  flex: 1;
  margin-bottom: 8px !important;
  min-height: 20px;
  display: flex;
  align-items: center;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item.list-group-footer > .row > div.alert::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item.list-group-footer > .row > div.alert::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item.list-group-footer > .row > div.alert::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item.list-group-footer > .row > div.alert::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item.list-group-footer > .row > div.alert::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header ~ .list-group-item.list-group-footer > .row > div.alert::after {
  height: calc(100% - 4px) !important;
  top: 2px !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header + .list-group-item,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header + .list-group-item,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header + .list-group-item,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header + .list-group-item,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header + .list-group-item,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header + .list-group-item {
  border-top: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header + .list-group-item.new-entry,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header + .list-group-item.new-entry,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header + .list-group-item.new-entry,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header + .list-group-item.new-entry,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header + .list-group-item.new-entry,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header + .list-group-item.new-entry {
  --top-padding: 0px;
  --inline-padding: 0px;
  background-color: transparent;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header:only-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header:only-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header:only-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header:only-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header:only-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header:only-child {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .eg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .eg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .eg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .eg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .eg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .eg-action-col {
  background: var(--color-gray-100);
  justify-self: flex-end;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .datagrid-hide-column-label label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .datagrid-hide-column-label label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .datagrid-hide-column-label label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .datagrid-hide-column-label label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .datagrid-hide-column-label label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .datagrid-hide-column-label label {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .align-value-right,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .align-value-right,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .align-value-right,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .align-value-right,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .align-value-right,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-header .align-value-right {
  text-align: end;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > .row:not(.formio-component-columns) > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > .row:not(.formio-component-columns) > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > .row:not(.formio-component-columns) > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > .row:not(.formio-component-columns) > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > .row:not(.formio-component-columns) > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > .row:not(.formio-component-columns) > div {
  max-width: initial;
  margin: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > .row:not(.formio-component-columns) > div:is(.eg-action-col, :last-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > .row:not(.formio-component-columns) > div:is(.eg-action-col, :last-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > .row:not(.formio-component-columns) > div:is(.eg-action-col, :last-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > .row:not(.formio-component-columns) > div:is(.eg-action-col, :last-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > .row:not(.formio-component-columns) > div:is(.eg-action-col, :last-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > .row:not(.formio-component-columns) > div:is(.eg-action-col, :last-child) {
  border-inline-end: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > .row:not(.formio-component-columns).no-actions-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > .row:not(.formio-component-columns).no-actions-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > .row:not(.formio-component-columns).no-actions-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > .row:not(.formio-component-columns).no-actions-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > .row:not(.formio-component-columns).no-actions-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > .row:not(.formio-component-columns).no-actions-col {
  margin-inline: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > .row:not(.formio-component-columns).no-actions-col > div:last-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > .row:not(.formio-component-columns).no-actions-col > div:last-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > .row:not(.formio-component-columns).no-actions-col > div:last-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > .row:not(.formio-component-columns).no-actions-col > div:last-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > .row:not(.formio-component-columns).no-actions-col > div:last-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > .row:not(.formio-component-columns).no-actions-col > div:last-of-type {
  flex: 1;
  padding-inline: 12px !important;
  padding-block: 10px !important;
  position: initial;
  justify-content: initial;
  min-width: 170px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:has(.no-actions-col) ~ .list-group-footer .row > div:last-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:has(.no-actions-col) ~ .list-group-footer .row > div:last-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:has(.no-actions-col) ~ .list-group-footer .row > div:last-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:has(.no-actions-col) ~ .list-group-footer .row > div:last-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:has(.no-actions-col) ~ .list-group-footer .row > div:last-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:has(.no-actions-col) ~ .list-group-footer .row > div:last-of-type {
  flex: 1;
  padding-inline: 12px !important;
  padding-block: 10px !important;
  position: initial;
  justify-content: initial;
  min-width: 170px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-footer .alert::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-footer .alert::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-footer .alert::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-footer .alert::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-footer .alert::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-footer .alert::after {
  display: none !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-footer > [class="row"] > [class*="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-footer > [class="row"] > [class*="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-footer > [class="row"] > [class*="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-footer > [class="row"] > [class*="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-footer > [class="row"] > [class*="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-footer > [class="row"] > [class*="col-"] {
  border-bottom: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-footer > [class="row"] > [class*="col-"].eg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-footer > [class="row"] > [class*="col-"].eg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-footer > [class="row"] > [class*="col-"].eg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-footer > [class="row"] > [class*="col-"].eg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-footer > [class="row"] > [class*="col-"].eg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.list-group-footer > [class="row"] > [class*="col-"].eg-action-col {
  max-width: 64px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) {
  display: flex;
  margin-inline: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"] {
  height: 100%;
  max-width: 100%;
  min-width: var(--custom-min-width, 170px);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"] label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"] label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"] label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"] label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"] label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"] label {
  text-align: inherit !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"] label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"] label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"] label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"] label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"] label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"] label span {
  font-weight: 600;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].eg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].eg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].eg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].eg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].eg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].eg-action-col {
  padding: 0 !important;
  position: sticky;
  inset-inline-end: 0px;
  min-width: 64px;
  max-width: 64px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width5,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width5,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width5,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width5,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width5,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width5 {
  flex: 5;
  --custom-min-width: 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width10,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width10,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width10,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width10,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width10,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width10 {
  flex: 10;
  --custom-min-width: 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width15,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width15,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width15,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width15,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width15,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width15 {
  flex: 15;
  --custom-min-width: 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width20,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width20,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width20,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width20,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width20,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width20 {
  flex: 20;
  --custom-min-width: 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width25,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width25,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width25,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width25,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width25,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width25 {
  flex: 25;
  --custom-min-width: 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width30,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width30,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width30,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width30,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width30,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width30 {
  flex: 30;
  --custom-min-width: 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width35,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width35,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width35,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width35,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width35,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width35 {
  flex: 35;
  --custom-min-width: 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width40,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width40,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width40,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width40,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width40,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width40 {
  flex: 40;
  --custom-min-width: 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width45,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width45,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width45,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width45,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width45,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width45 {
  flex: 45;
  --custom-min-width: 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width50,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width50,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width50,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width50,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width50,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width50 {
  flex: 50;
  --custom-min-width: 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width55,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width55,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width55,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width55,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width55,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width55 {
  flex: 55;
  --custom-min-width: 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width60,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width60,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width60,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width60,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width60,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width60 {
  flex: 60;
  --custom-min-width: 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width65,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width65,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width65,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width65,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width65,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width65 {
  flex: 65;
  --custom-min-width: 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width70,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width70,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width70,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width70,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width70,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width70 {
  flex: 70;
  --custom-min-width: 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width75,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width75,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width75,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width75,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width75,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width75 {
  flex: 75;
  --custom-min-width: 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width80,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width80,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width80,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width80,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width80,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width80 {
  flex: 80;
  --custom-min-width: 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width85,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width85,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width85,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width85,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width85,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width85 {
  flex: 85;
  --custom-min-width: 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width90,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width90,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width90,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width90,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width90,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width90 {
  flex: 90;
  --custom-min-width: 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width95,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width95,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width95,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width95,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width95,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width95 {
  flex: 95;
  --custom-min-width: 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width100,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width100,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width100,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width100,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width100,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].width100 {
  flex: 100;
  --custom-min-width: 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) {
  border-bottom: solid 1px var(--color-gray-200);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) {
  margin-inline: 0;
  border-radius: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"] {
  min-height: 50px;
  border-bottom: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].select span.key,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].select span.key,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].select span.key,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].select span.key,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].select span.key,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].select span.key {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].select span.value,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].select span.value,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].select span.value,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].select span.value,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].select span.value,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].select span.value {
  display: -webkit-inline-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 19px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].select span.value:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].select span.value:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].select span.value:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].select span.value:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].select span.value:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].select span.value:hover {
  -webkit-line-clamp: 100;
  animation-name: fullClamp;
  animation-delay: 0.75s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards;
  animation-duration: 0.3s;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].select span.value[data-tooltip],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].select span.value[data-tooltip],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].select span.value[data-tooltip],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].select span.value[data-tooltip],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].select span.value[data-tooltip],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"].select span.value[data-tooltip] {
  display: inline-flex;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry):not(.new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry):not(.new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry):not(.new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry):not(.new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry):not(.new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry):not(.new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) {
  align-items: stretch;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry):not(.new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry):not(.new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry):not(.new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry):not(.new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry):not(.new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry):not(.new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"] {
  height: auto;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry):not(.new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"]:not(.hide),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry):not(.new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"]:not(.hide),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry):not(.new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"]:not(.hide),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry):not(.new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"]:not(.hide),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry):not(.new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"]:not(.hide),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry):not(.new-entry, .editing-entry) > :is([class="row"], [class="row "], [class="row selected"]) > [class*="col-"]:not(.hide) {
  display: flex;
  align-items: center;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header) > [class="row"] > [class*="col-"].align-value-right,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header) > [class="row"] > [class*="col-"].align-value-right,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header) > [class="row"] > [class*="col-"].align-value-right,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header) > [class="row"] > [class*="col-"].align-value-right,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header) > [class="row"] > [class*="col-"].align-value-right,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:not(.list-group-header) > [class="row"] > [class*="col-"].align-value-right {
  text-align: end;
  justify-content: end;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) {
  padding: var(--top-padding, 0px) var(--inline-padding, 0px) var(--bottom-padding, calc(30px - 2rem)) !important;
  border-radius: var(--border-radius, 0px) !important;
  overflow-x: initial;
  max-width: 100%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component-columns.row:not(.vertical-align-bottom, .vertical-align-center),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component-columns.row:not(.vertical-align-bottom, .vertical-align-center),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component-columns.row:not(.vertical-align-bottom, .vertical-align-center),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component-columns.row:not(.vertical-align-bottom, .vertical-align-center),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component-columns.row:not(.vertical-align-bottom, .vertical-align-center),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component-columns.row:not(.vertical-align-bottom, .vertical-align-center) {
  align-items: stretch;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns {
  gap: 24px;
  margin-inline: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > [ref^="column-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > [ref^="column-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > [ref^="column-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > [ref^="column-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > [ref^="column-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > [ref^="column-"] {
  max-width: initial;
  padding: 0px;
  width: 100%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > [class^="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > [class^="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > [class^="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > [class^="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > [class^="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > [class^="col-"] {
  padding-inline: 0px;
  padding-block: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode)::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode)::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode)::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode)::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode)::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode)::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode)::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode)::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode)::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode)::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode)::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode)::after {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) .formio-errors,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) .formio-errors,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) .formio-errors,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) .formio-errors,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) .formio-errors,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) .formio-errors {
  flex: 0 0 100%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) .formio-errors:empty,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) .formio-errors:empty,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) .formio-errors:empty,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) .formio-errors:empty,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) .formio-errors:empty,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) .formio-errors:empty {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-2,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-2,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-2,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-2,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-2,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-2 {
  grid-column: span 2;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-3,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-3,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-3,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-3,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-3,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-3 {
  grid-column: span 3;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-4,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-4,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-4,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-4,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-4,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-4 {
  grid-column: span 4;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-5,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-5,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-5,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-5,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-5,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-5 {
  grid-column: span 5;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-6,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-6,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-6,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-6,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-6,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-6 {
  grid-column: span 6;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-7,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-7,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-7,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-7,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-7,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-7 {
  grid-column: span 7;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-8,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-8,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-8,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-8,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-8,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-8 {
  grid-column: span 8;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-9,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-9,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-9,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-9,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-9,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-9 {
  grid-column: span 9;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-10,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-10,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-10,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-10,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-10,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-10 {
  grid-column: span 10;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-11,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-11,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-11,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-11,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-11,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-11 {
  grid-column: span 11;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-12,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-12,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-12,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-12,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-12,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-columns:not(.hide, .formio-hidden, .hide-on-edit-mode) > .col-md-12 {
  grid-column: span 12;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-table,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-table,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-table,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-table,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-table,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-table {
  margin-inline: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-table td:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-table td:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-table td:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-table td:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-table td:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-table td:first-child {
  padding-inline-start: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-table td:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-table td:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-table td:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-table td:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-table td:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component.formio-component-table td:last-child {
  padding-inline-end: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .hide-on-edit-mode,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .hide-on-edit-mode,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .hide-on-edit-mode,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .hide-on-edit-mode,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .hide-on-edit-mode,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .hide-on-edit-mode {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component-file .list-group-item .row > [class^="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component-file .list-group-item .row > [class^="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component-file .list-group-item .row > [class^="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component-file .list-group-item .row > [class^="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component-file .list-group-item .row > [class^="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component-file .list-group-item .row > [class^="col-"] {
  border: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component-file .list-group-item:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component-file .list-group-item:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component-file .list-group-item:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component-file .list-group-item:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component-file .list-group-item:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component-file .list-group-item:last-child {
  border-bottom: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) :is(.list-group-header, .list-group-footer) .hide-on-edit-mode,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) :is(.list-group-header, .list-group-footer) .hide-on-edit-mode,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) :is(.list-group-header, .list-group-footer) .hide-on-edit-mode,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) :is(.list-group-header, .list-group-footer) .hide-on-edit-mode,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) :is(.list-group-header, .list-group-footer) .hide-on-edit-mode,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) :is(.list-group-header, .list-group-footer) .hide-on-edit-mode {
  display: block;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) + .formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) + .formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) + .formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) + .formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) + .formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) .formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) + .formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) {
  --custom-top-margin-value: 3rem;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) > [class="row"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) > [class="row"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) > [class="row"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) > [class="row"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) > [class="row"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) > [class="row"] {
  background-color: var(--eg-add-edit-entry-row-bgcolor);
  box-shadow: var(--eg-add-edit-entry-row-shadow);
  margin-bottom: 30px;
  font-weight: 600;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) > [class="row"] .eg-action-col .btn-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) > [class="row"] .eg-action-col .btn-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) > [class="row"] .eg-action-col .btn-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) > [class="row"] .eg-action-col .btn-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) > [class="row"] .eg-action-col .btn-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) > [class="row"] .eg-action-col .btn-group {
  background-color: var(--eg-add-edit-entry-row-bgcolor);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) > [class="row"] .removeRow,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) > [class="row"] .removeRow,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) > [class="row"] .removeRow,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) > [class="row"] .removeRow,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) > [class="row"] .removeRow,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) > [class="row"] .removeRow {
  font-family: var(--fontawesome-font);
  --icon-code: "\f1f8";
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) > [class="row"] .hide-on-edit-mode,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) > [class="row"] .hide-on-edit-mode,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) > [class="row"] .hide-on-edit-mode,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) > [class="row"] .hide-on-edit-mode,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) > [class="row"] .hide-on-edit-mode,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry, .editing-entry) > [class="row"] .hide-on-edit-mode {
  display: block;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry):not(.editing-entry),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry):not(.editing-entry),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry):not(.editing-entry),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry):not(.editing-entry),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry):not(.editing-entry),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry):not(.editing-entry) {
  --bottom-padding: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry):not(.editing-entry) > [class="row"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry):not(.editing-entry) > [class="row"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry):not(.editing-entry) > [class="row"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry):not(.editing-entry) > [class="row"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry):not(.editing-entry) > [class="row"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:is(.new-entry):not(.editing-entry) > [class="row"] {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry {
  --top-padding: 24px;
  --inline-padding: 24px;
  background-color: var(--color-gray-75);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry:not(:last-child) > :last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry:not(:last-child) > :last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry:not(:last-child) > :last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry:not(:last-child) > :last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry:not(:last-child) > :last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry:not(:last-child) > :last-child {
  margin-bottom: 30px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry:not(.new-entry),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry:not(.new-entry),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry:not(.new-entry),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry:not(.new-entry),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry:not(.new-entry),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry:not(.new-entry) {
  --top-padding: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry > [class="row"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry > [class="row"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry > [class="row"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry > [class="row"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry > [class="row"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry > [class="row"] {
  margin-inline: calc(0px - 24px);
  overflow: hidden;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry > .formio-component-panel > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry > .formio-component-panel > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry > .formio-component-panel > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry > .formio-component-panel > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry > .formio-component-panel > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry > .formio-component-panel > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry > .formio-component-columns > [class*="col-"] > .formio-component-panel > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry > .formio-component-columns > [class*="col-"] > .formio-component-panel > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry > .formio-component-columns > [class*="col-"] > .formio-component-panel > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry > .formio-component-columns > [class*="col-"] > .formio-component-panel > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry > .formio-component-columns > [class*="col-"] > .formio-component-panel > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry > .formio-component-columns > [class*="col-"] > .formio-component-panel > .card {
  border-color: var(--color-gray-200);
  border-width: 1px;
  border-style: solid;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry > .formio-component-panel > .card > .card-header,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry > .formio-component-panel > .card > .card-header,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry > .formio-component-panel > .card > .card-header,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry > .formio-component-panel > .card > .card-header,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry > .formio-component-panel > .card > .card-header,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry > .formio-component-panel > .card > .card-header,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry > .formio-component-columns > [class*="col-"] > .formio-component-panel > .card > .card-header,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry > .formio-component-columns > [class*="col-"] > .formio-component-panel > .card > .card-header,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry > .formio-component-columns > [class*="col-"] > .formio-component-panel > .card > .card-header,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry > .formio-component-columns > [class*="col-"] > .formio-component-panel > .card > .card-header,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry > .formio-component-columns > [class*="col-"] > .formio-component-panel > .card > .card-header,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.editing-entry > .formio-component-columns > [class*="col-"] > .formio-component-panel > .card > .card-header {
  border-bottom-color: var(--color-gray-200);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:has(.eg-action-col) > :is([class="row"], [class="row "]) :is([class*="col-"]:last-child, .eg-action-col),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:has(.eg-action-col) > :is([class="row"], [class="row "]) :is([class*="col-"]:last-child, .eg-action-col),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:has(.eg-action-col) > :is([class="row"], [class="row "]) :is([class*="col-"]:last-child, .eg-action-col),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:has(.eg-action-col) > :is([class="row"], [class="row "]) :is([class*="col-"]:last-child, .eg-action-col),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:has(.eg-action-col) > :is([class="row"], [class="row "]) :is([class*="col-"]:last-child, .eg-action-col),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:has(.eg-action-col) > :is([class="row"], [class="row "]) :is([class*="col-"]:last-child, .eg-action-col),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:has(.eg-action-col) ~ .list-group-footer > [class="row"] :is([class*="col-"]:last-child, .eg-action-col),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:has(.eg-action-col) ~ .list-group-footer > [class="row"] :is([class*="col-"]:last-child, .eg-action-col),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:has(.eg-action-col) ~ .list-group-footer > [class="row"] :is([class*="col-"]:last-child, .eg-action-col),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:has(.eg-action-col) ~ .list-group-footer > [class="row"] :is([class*="col-"]:last-child, .eg-action-col),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:has(.eg-action-col) ~ .list-group-footer > [class="row"] :is([class*="col-"]:last-child, .eg-action-col),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item:has(.eg-action-col) ~ .list-group-footer > [class="row"] :is([class*="col-"]:last-child, .eg-action-col) {
  position: sticky;
  inset-inline-end: 0;
  padding: 0 !important;
  display: var(--actions-visibility, flex);
  justify-content: flex-end;
  min-width: 64px;
  max-width: 64px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.saved-entry .removeRow,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.saved-entry .removeRow,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.saved-entry .removeRow,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.saved-entry .removeRow,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.saved-entry .removeRow,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.saved-entry .removeRow {
  font-family: var(--fontawesome-font);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.saved-entry .removeRow::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.saved-entry .removeRow::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.saved-entry .removeRow::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.saved-entry .removeRow::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.saved-entry .removeRow::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.saved-entry .removeRow::before {
  content: var(--icon-code);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.saved-entry .removeRow,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.saved-entry .removeRow,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.saved-entry .removeRow,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.saved-entry .removeRow,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.saved-entry .removeRow,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.saved-entry .removeRow {
  --icon-code: "\f1f8";
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.saved-entry .editRow,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.saved-entry .editRow,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.saved-entry .editRow,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.saved-entry .editRow,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.saved-entry .editRow,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup li.list-group-item.saved-entry .editRow {
  --button-border-width: 1px;
  --button-border-color: var(--color-primary-600);
  color: var(--color-primary-600);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup:has(.list-group-header + .editing-entry + .list-group-footer) .editing-entry,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup:has(.list-group-header + .editing-entry + .list-group-footer) .editing-entry,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup:has(.list-group-header + .editing-entry + .list-group-footer) .editing-entry,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup:has(.list-group-header + .editing-entry + .list-group-footer) .editing-entry,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup:has(.list-group-header + .editing-entry + .list-group-footer) .editing-entry,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup:has(.list-group-header + .editing-entry + .list-group-footer) .editing-entry {
  --top-padding: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup::-webkit-scrollbar,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup::-webkit-scrollbar,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup::-webkit-scrollbar,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup::-webkit-scrollbar,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup::-webkit-scrollbar,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup::-webkit-scrollbar-thumb,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup::-webkit-scrollbar-thumb,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup::-webkit-scrollbar-thumb,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup::-webkit-scrollbar-thumb,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup::-webkit-scrollbar-thumb,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--color-gray-500);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid ul.editgrid-listgroup::-webkit-scrollbar-track,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid ul.editgrid-listgroup::-webkit-scrollbar-track,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid ul.editgrid-listgroup::-webkit-scrollbar-track,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid ul.editgrid-listgroup::-webkit-scrollbar-track,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid ul.editgrid-listgroup::-webkit-scrollbar-track,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid ul.editgrid-listgroup::-webkit-scrollbar-track {
  background-color: rgba(172, 176, 198, 0.3);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid > button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid > button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid > button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid > button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid > button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid > button.btn {
  border: none;
  border-radius: 4px !important;
  color: var(--color-gray-800);
  background-color: #EDEEF7 !important;
  padding: 10px 16px;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  align-items: center;
  transition-duration: 0.3s;
  height: 32px;
  margin-inline: auto;
  gap: 8px;
  position: relative;
  z-index: 1;
  margin-top: 20px;
  --button-shadow: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid > button.btn > i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid > button.btn > i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid > button.btn > i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid > button.btn > i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid > button.btn > i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid > button.btn > i {
  font-weight: 400;
  font-family: var(--fontawesome-font);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid > button.btn:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid > button.btn:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid > button.btn:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid > button.btn:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid > button.btn:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid > button.btn:hover {
  color: white;
  background-color: var(--color-primary-600) !important;
  box-shadow: 0 9px 20px rgba(70, 81, 170, 0.4) !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid.has-open-row > ul.editgrid-listgroup,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid.has-open-row > ul.editgrid-listgroup,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid.has-open-row > ul.editgrid-listgroup,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid.has-open-row > ul.editgrid-listgroup,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid.has-open-row > ul.editgrid-listgroup,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid.has-open-row > ul.editgrid-listgroup {
  overflow-x: initial;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid.has-open-row > ul.editgrid-listgroup > li.list-group-footer,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid.has-open-row > ul.editgrid-listgroup > li.list-group-footer,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid.has-open-row > ul.editgrid-listgroup > li.list-group-footer,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid.has-open-row > ul.editgrid-listgroup > li.list-group-footer,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid.has-open-row > ul.editgrid-listgroup > li.list-group-footer,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid.has-open-row > ul.editgrid-listgroup > li.list-group-footer {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid.has-open-row > ul.editgrid-listgroup > li.list-group-item:not(.new-entry, .editing-entry),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid.has-open-row > ul.editgrid-listgroup > li.list-group-item:not(.new-entry, .editing-entry),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid.has-open-row > ul.editgrid-listgroup > li.list-group-item:not(.new-entry, .editing-entry),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid.has-open-row > ul.editgrid-listgroup > li.list-group-item:not(.new-entry, .editing-entry),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid.has-open-row > ul.editgrid-listgroup > li.list-group-item:not(.new-entry, .editing-entry),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid.has-open-row > ul.editgrid-listgroup > li.list-group-item:not(.new-entry, .editing-entry) {
  max-width: 100%;
  overflow-x: hidden;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid .editgrid-actions,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid .editgrid-actions,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid .editgrid-actions,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid .editgrid-actions,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid .editgrid-actions,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid .editgrid-actions {
  margin-bottom: var(--bottom-margin, 0px);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid .editgrid-actions .btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid .editgrid-actions .btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid .editgrid-actions .btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid .editgrid-actions .btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid .editgrid-actions .btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid .editgrid-actions .btn {
  transition-duration: 0.3s;
  background: transparent;
  color: var(--color-primary-600);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid .editgrid-actions .btn.btn-primary,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid .editgrid-actions .btn.btn-primary,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid .editgrid-actions .btn.btn-primary,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid .editgrid-actions .btn.btn-primary,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid .editgrid-actions .btn.btn-primary,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid .editgrid-actions .btn.btn-primary {
  background: var(--color-primary-600);
  color: white;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid .editgrid-actions .btn.btn-primary:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid .editgrid-actions .btn.btn-primary:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid .editgrid-actions .btn.btn-primary:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid .editgrid-actions .btn.btn-primary:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid .editgrid-actions .btn.btn-primary:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid .editgrid-actions .btn.btn-primary:hover {
  opacity: 0.9;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid .editgrid-actions .btn + .btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid .editgrid-actions .btn + .btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid .editgrid-actions .btn + .btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid .editgrid-actions .btn + .btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid .editgrid-actions .btn + .btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid .editgrid-actions .btn + .btn {
  border: none;
  --button-shadow: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid .editgrid-actions .btn + .btn:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid .editgrid-actions .btn + .btn:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid .editgrid-actions .btn + .btn:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid .editgrid-actions .btn + .btn:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid .editgrid-actions .btn + .btn:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid .editgrid-actions .btn + .btn:hover {
  background-color: #EDEEF7;
  color: var(--color-primary-600);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid.search-result-list .eg-action-col .btn-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid.search-result-list .eg-action-col .btn-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid.search-result-list .eg-action-col .btn-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid.search-result-list .eg-action-col .btn-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid.search-result-list .eg-action-col .btn-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid.search-result-list .eg-action-col .btn-group {
  background: transparent;
  transition: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid.disabled-eg ul.list-group li.list-group-item > [class="row"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid.disabled-eg ul.list-group li.list-group-item > [class="row"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid.disabled-eg ul.list-group li.list-group-item > [class="row"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid.disabled-eg ul.list-group li.list-group-item > [class="row"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid.disabled-eg ul.list-group li.list-group-item > [class="row"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid.disabled-eg ul.list-group li.list-group-item > [class="row"] {
  display: grid;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid.disabled-eg ul.list-group li.list-group-item > [class="row"] > div:last-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid.disabled-eg ul.list-group li.list-group-item > [class="row"] > div:last-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid.disabled-eg ul.list-group li.list-group-item > [class="row"] > div:last-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid.disabled-eg ul.list-group li.list-group-item > [class="row"] > div:last-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid.disabled-eg ul.list-group li.list-group-item > [class="row"] > div:last-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid.disabled-eg ul.list-group li.list-group-item > [class="row"] > div:last-of-type {
  max-width: initial;
  width: initial;
  text-align: start;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid.disabled-eg ul.list-group li.list-group-item.list-group-header > [class="row"] > div:last-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid.disabled-eg ul.list-group li.list-group-item.list-group-header > [class="row"] > div:last-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid.disabled-eg ul.list-group li.list-group-item.list-group-header > [class="row"] > div:last-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid.disabled-eg ul.list-group li.list-group-item.list-group-header > [class="row"] > div:last-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid.disabled-eg ul.list-group li.list-group-item.list-group-header > [class="row"] > div:last-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid.disabled-eg ul.list-group li.list-group-item.list-group-header > [class="row"] > div:last-of-type {
  padding-inline: 12px !important;
  padding-block: 10px !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid.disabled-eg ul.list-group li.list-group-item:not(.list-group-header, .list-group-footer) > [class="row"] > div:last-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid.disabled-eg ul.list-group li.list-group-item:not(.list-group-header, .list-group-footer) > [class="row"] > div:last-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid.disabled-eg ul.list-group li.list-group-item:not(.list-group-header, .list-group-footer) > [class="row"] > div:last-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid.disabled-eg ul.list-group li.list-group-item:not(.list-group-header, .list-group-footer) > [class="row"] > div:last-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid.disabled-eg ul.list-group li.list-group-item:not(.list-group-header, .list-group-footer) > [class="row"] > div:last-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid.disabled-eg ul.list-group li.list-group-item:not(.list-group-header, .list-group-footer) > [class="row"] > div:last-of-type {
  padding-inline: 12px !important;
  padding-block: 16px !important;
  position: initial;
  display: initial;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid.formio-virtual-scroll .editgrid-listgroup-container,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid.formio-virtual-scroll .editgrid-listgroup-container,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid.formio-virtual-scroll .editgrid-listgroup-container,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid.formio-virtual-scroll .editgrid-listgroup-container,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid.formio-virtual-scroll .editgrid-listgroup-container,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid.formio-virtual-scroll .editgrid-listgroup-container {
  box-shadow: 0px 40px 0px #FFFFFF, 0px 41px 0px #EDEFF2;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid.formio-virtual-scroll .editgrid-listgroup-container ul.editgrid-listgroup::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid.formio-virtual-scroll .editgrid-listgroup-container ul.editgrid-listgroup::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid.formio-virtual-scroll .editgrid-listgroup-container ul.editgrid-listgroup::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid.formio-virtual-scroll .editgrid-listgroup-container ul.editgrid-listgroup::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid.formio-virtual-scroll .editgrid-listgroup-container ul.editgrid-listgroup::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid.formio-virtual-scroll .editgrid-listgroup-container ul.editgrid-listgroup::after {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid.formio-virtual-scroll .editgrid-listgroup-container ul.editgrid-listgroup li.list-group-item.list-group-header:only-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid.formio-virtual-scroll .editgrid-listgroup-container ul.editgrid-listgroup li.list-group-item.list-group-header:only-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid.formio-virtual-scroll .editgrid-listgroup-container ul.editgrid-listgroup li.list-group-item.list-group-header:only-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid.formio-virtual-scroll .editgrid-listgroup-container ul.editgrid-listgroup li.list-group-item.list-group-header:only-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid.formio-virtual-scroll .editgrid-listgroup-container ul.editgrid-listgroup li.list-group-item.list-group-header:only-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid.formio-virtual-scroll .editgrid-listgroup-container ul.editgrid-listgroup li.list-group-item.list-group-header:only-child {
  display: flex;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid.formio-virtual-scroll.has-open-row > button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid.formio-virtual-scroll.has-open-row > button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid.formio-virtual-scroll.has-open-row > button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid.formio-virtual-scroll.has-open-row > button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid.formio-virtual-scroll.has-open-row > button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid.formio-virtual-scroll.has-open-row > button {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid.has-form-validation-error :is(.new-entry, .editing-entry),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid.has-form-validation-error :is(.new-entry, .editing-entry),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid.has-form-validation-error :is(.new-entry, .editing-entry),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid.has-form-validation-error :is(.new-entry, .editing-entry),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid.has-form-validation-error :is(.new-entry, .editing-entry),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid.has-form-validation-error :is(.new-entry, .editing-entry) {
  --border-radius: 1px;
  outline-offset: 6px;
  outline-style: solid;
  outline-width: 6px;
  outline-color: hsl(0, 0%, 100%);
  animation: hglightOutline 1s ease-out 0.15s;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid.has-form-validation-error .editgrid-actions .btn-primary,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid.has-form-validation-error .editgrid-actions .btn-primary,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid.has-form-validation-error .editgrid-actions .btn-primary,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid.has-form-validation-error .editgrid-actions .btn-primary,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid.has-form-validation-error .editgrid-actions .btn-primary,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid.has-form-validation-error .editgrid-actions .btn-primary {
  position: relative;
  transition-duration: 0s;
  animation: shake 0.25s ease-in-out;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid.no-border,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid.no-border,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid.no-border,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid.no-border,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid.no-border,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid.no-border {
  --editgrid-border-width: 0;
  --editgrid-padding: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid .no-rows-message,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid .no-rows-message,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid .no-rows-message,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid .no-rows-message,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid .no-rows-message,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid .no-rows-message {
  background-image: url(../../../images/empty-editgrid.svg);
  background-repeat: no-repeat;
  background-position: center top;
  padding-top: 86px;
  text-align: center;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid .no-rows-message::first-line,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid .no-rows-message::first-line,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid .no-rows-message::first-line,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid .no-rows-message::first-line,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid .no-rows-message::first-line,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid .no-rows-message::first-line {
  font-weight: 600;
  font-size: 16px;
  line-height: 2.5;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid:where(.has-error, .formio-error-wrapper),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid:where(.has-error, .formio-error-wrapper),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid:where(.has-error, .formio-error-wrapper),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid:where(.has-error, .formio-error-wrapper),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid:where(.has-error, .formio-error-wrapper),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid:where(.has-error, .formio-error-wrapper) {
  border-radius: 6px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid:where(.has-error, .formio-error-wrapper) .formio-errors .form-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid:where(.has-error, .formio-error-wrapper) .formio-errors .form-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid:where(.has-error, .formio-error-wrapper) .formio-errors .form-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid:where(.has-error, .formio-error-wrapper) .formio-errors .form-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid:where(.has-error, .formio-error-wrapper) .formio-errors .form-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid:where(.has-error, .formio-error-wrapper) .formio-errors .form-text {
  font-size: 14px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid:where(.has-message, .has-error, .formio-error-wrapper) .editgrid-row-error,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid:where(.has-message, .has-error, .formio-error-wrapper) .editgrid-row-error,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid:where(.has-message, .has-error, .formio-error-wrapper) .editgrid-row-error,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid:where(.has-message, .has-error, .formio-error-wrapper) .editgrid-row-error,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid:where(.has-message, .has-error, .formio-error-wrapper) .editgrid-row-error,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid:where(.has-message, .has-error, .formio-error-wrapper) .editgrid-row-error {
  font-size: 14px;
  margin-top: 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid:where(.has-message, .has-error, .formio-error-wrapper):has(.list-group-footer) :is(.new-entry, .editing-entry):nth-last-child(2),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid:where(.has-message, .has-error, .formio-error-wrapper):has(.list-group-footer) :is(.new-entry, .editing-entry):nth-last-child(2),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid:where(.has-message, .has-error, .formio-error-wrapper):has(.list-group-footer) :is(.new-entry, .editing-entry):nth-last-child(2),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid:where(.has-message, .has-error, .formio-error-wrapper):has(.list-group-footer) :is(.new-entry, .editing-entry):nth-last-child(2),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid:where(.has-message, .has-error, .formio-error-wrapper):has(.list-group-footer) :is(.new-entry, .editing-entry):nth-last-child(2),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid:where(.has-message, .has-error, .formio-error-wrapper):has(.list-group-footer) :is(.new-entry, .editing-entry):nth-last-child(2) {
  border-bottom: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid:where(.has-message, .has-error, .formio-error-wrapper) :is(.new-entry, .editing-entry) .has-error,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid:where(.has-message, .has-error, .formio-error-wrapper) :is(.new-entry, .editing-entry) .has-error,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid:where(.has-message, .has-error, .formio-error-wrapper) :is(.new-entry, .editing-entry) .has-error,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid:where(.has-message, .has-error, .formio-error-wrapper) :is(.new-entry, .editing-entry) .has-error,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid:where(.has-message, .has-error, .formio-error-wrapper) :is(.new-entry, .editing-entry) .has-error,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid:where(.has-message, .has-error, .formio-error-wrapper) :is(.new-entry, .editing-entry) .has-error {
  margin-bottom: 8px !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid:where(.has-message, .has-error, .formio-error-wrapper) > .formio-errors,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid:where(.has-message, .has-error, .formio-error-wrapper) > .formio-errors,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid:where(.has-message, .has-error, .formio-error-wrapper) > .formio-errors,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid:where(.has-message, .has-error, .formio-error-wrapper) > .formio-errors,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid:where(.has-message, .has-error, .formio-error-wrapper) > .formio-errors,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid:where(.has-message, .has-error, .formio-error-wrapper) > .formio-errors {
  margin-top: 24px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid:where(.has-message, .has-error, .formio-error-wrapper) > .formio-errors .form-text.error,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid:where(.has-message, .has-error, .formio-error-wrapper) > .formio-errors .form-text.error,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid:where(.has-message, .has-error, .formio-error-wrapper) > .formio-errors .form-text.error,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid:where(.has-message, .has-error, .formio-error-wrapper) > .formio-errors .form-text.error,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid:where(.has-message, .has-error, .formio-error-wrapper) > .formio-errors .form-text.error,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid:where(.has-message, .has-error, .formio-error-wrapper) > .formio-errors .form-text.error {
  border-radius: 6px;
  padding: 15px 24px;
  background-color: #FEE7E7;
  color: var(--color-gray-800);
  font-size: 16px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid .formio-component-file li.list-group-item,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid .formio-component-file li.list-group-item,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid .formio-component-file li.list-group-item,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid .formio-component-file li.list-group-item,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid .formio-component-file li.list-group-item,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid .formio-component-file li.list-group-item {
  border-bottom: none !important;
  box-shadow: none !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid .formio-component-file.formio-component-multiple.has-files:not(.value-label-style, .formio-disabled-component) ul.list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid .formio-component-file.formio-component-multiple.has-files:not(.value-label-style, .formio-disabled-component) ul.list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid .formio-component-file.formio-component-multiple.has-files:not(.value-label-style, .formio-disabled-component) ul.list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid .formio-component-file.formio-component-multiple.has-files:not(.value-label-style, .formio-disabled-component) ul.list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid .formio-component-file.formio-component-multiple.has-files:not(.value-label-style, .formio-disabled-component) ul.list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid .formio-component-file.formio-component-multiple.has-files:not(.value-label-style, .formio-disabled-component) ul.list-group {
  margin-bottom: 30px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid .formio-component-file.formio-component-multiple.has-files:not(.value-label-style, .formio-disabled-component) li.list-group-item > .row > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid .formio-component-file.formio-component-multiple.has-files:not(.value-label-style, .formio-disabled-component) li.list-group-item > .row > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid .formio-component-file.formio-component-multiple.has-files:not(.value-label-style, .formio-disabled-component) li.list-group-item > .row > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid .formio-component-file.formio-component-multiple.has-files:not(.value-label-style, .formio-disabled-component) li.list-group-item > .row > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid .formio-component-file.formio-component-multiple.has-files:not(.value-label-style, .formio-disabled-component) li.list-group-item > .row > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid .formio-component-file.formio-component-multiple.has-files:not(.value-label-style, .formio-disabled-component) li.list-group-item > .row > div {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid .formio-component-file.formio-component-multiple.has-files.value-label-style .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid .formio-component-file.formio-component-multiple.has-files.value-label-style .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid .formio-component-file.formio-component-multiple.has-files.value-label-style .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid .formio-component-file.formio-component-multiple.has-files.value-label-style .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid .formio-component-file.formio-component-multiple.has-files.value-label-style .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid .formio-component-file.formio-component-multiple.has-files.value-label-style .fileSelector {
  height: calc(100% + 60px);
  padding-inline-start: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid .formio-component-file.formio-component-multiple.has-files.value-label-style .fileSelector > i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid .formio-component-file.formio-component-multiple.has-files.value-label-style .fileSelector > i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid .formio-component-file.formio-component-multiple.has-files.value-label-style .fileSelector > i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid .formio-component-file.formio-component-multiple.has-files.value-label-style .fileSelector > i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid .formio-component-file.formio-component-multiple.has-files.value-label-style .fileSelector > i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid .formio-component-file.formio-component-multiple.has-files.value-label-style .fileSelector > i {
  inset-inline-end: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid .formio-component-file:is(.value-label-style, .formio-disabled-component) li.list-group-item:not(.list-group-header) + .list-group-item,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid .formio-component-file:is(.value-label-style, .formio-disabled-component) li.list-group-item:not(.list-group-header) + .list-group-item,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid .formio-component-file:is(.value-label-style, .formio-disabled-component) li.list-group-item:not(.list-group-header) + .list-group-item,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid .formio-component-file:is(.value-label-style, .formio-disabled-component) li.list-group-item:not(.list-group-header) + .list-group-item,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid .formio-component-file:is(.value-label-style, .formio-disabled-component) li.list-group-item:not(.list-group-header) + .list-group-item,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid .formio-component-file:is(.value-label-style, .formio-disabled-component) li.list-group-item:not(.list-group-header) + .list-group-item {
  margin-top: 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid .formio-component-file:is(.value-label-style, .formio-disabled-component) li.list-group-item > .row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid .formio-component-file:is(.value-label-style, .formio-disabled-component) li.list-group-item > .row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid .formio-component-file:is(.value-label-style, .formio-disabled-component) li.list-group-item > .row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid .formio-component-file:is(.value-label-style, .formio-disabled-component) li.list-group-item > .row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid .formio-component-file:is(.value-label-style, .formio-disabled-component) li.list-group-item > .row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid .formio-component-file:is(.value-label-style, .formio-disabled-component) li.list-group-item > .row {
  column-gap: 12px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid .formio-component-file:is(.value-label-style, .formio-disabled-component) li.list-group-item > .row > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid .formio-component-file:is(.value-label-style, .formio-disabled-component) li.list-group-item > .row > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid .formio-component-file:is(.value-label-style, .formio-disabled-component) li.list-group-item > .row > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid .formio-component-file:is(.value-label-style, .formio-disabled-component) li.list-group-item > .row > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid .formio-component-file:is(.value-label-style, .formio-disabled-component) li.list-group-item > .row > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid .formio-component-file:is(.value-label-style, .formio-disabled-component) li.list-group-item > .row > div {
  padding: 0px !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid.no-rows.hide-if-empty,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid.no-rows.hide-if-empty,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid.no-rows.hide-if-empty,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid.no-rows.hide-if-empty,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid.no-rows.hide-if-empty,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid.no-rows.hide-if-empty {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid:is(
            .align-save-cancel-right,
            .align-save-cancel-center,
            .invert-save-cancel-order
          ) :is(.editing-entry, .new-entry) .editgrid-actions,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid:is(
            .align-save-cancel-right,
            .align-save-cancel-center,
            .invert-save-cancel-order
          ) :is(.editing-entry, .new-entry) .editgrid-actions,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid:is(
            .align-save-cancel-right,
            .align-save-cancel-center,
            .invert-save-cancel-order
          ) :is(.editing-entry, .new-entry) .editgrid-actions,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid:is(
            .align-save-cancel-right,
            .align-save-cancel-center,
            .invert-save-cancel-order
          ) :is(.editing-entry, .new-entry) .editgrid-actions,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid:is(
            .align-save-cancel-right,
            .align-save-cancel-center,
            .invert-save-cancel-order
          ) :is(.editing-entry, .new-entry) .editgrid-actions,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid:is(
            .align-save-cancel-right,
            .align-save-cancel-center,
            .invert-save-cancel-order
          ) :is(.editing-entry, .new-entry) .editgrid-actions {
  display: flex;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid.align-save-cancel-center :is(.editing-entry, .new-entry) .editgrid-actions,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid.align-save-cancel-center :is(.editing-entry, .new-entry) .editgrid-actions,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid.align-save-cancel-center :is(.editing-entry, .new-entry) .editgrid-actions,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid.align-save-cancel-center :is(.editing-entry, .new-entry) .editgrid-actions,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid.align-save-cancel-center :is(.editing-entry, .new-entry) .editgrid-actions,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid.align-save-cancel-center :is(.editing-entry, .new-entry) .editgrid-actions {
  justify-content: center;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid.align-save-cancel-center.invert-save-cancel-order :is(.editing-entry, .new-entry) .editgrid-actions,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid.align-save-cancel-center.invert-save-cancel-order :is(.editing-entry, .new-entry) .editgrid-actions,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid.align-save-cancel-center.invert-save-cancel-order :is(.editing-entry, .new-entry) .editgrid-actions,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid.align-save-cancel-center.invert-save-cancel-order :is(.editing-entry, .new-entry) .editgrid-actions,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid.align-save-cancel-center.invert-save-cancel-order :is(.editing-entry, .new-entry) .editgrid-actions,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid.align-save-cancel-center.invert-save-cancel-order :is(.editing-entry, .new-entry) .editgrid-actions {
  justify-content: center;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid.align-save-cancel-right :is(.editing-entry, .new-entry) .editgrid-actions,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid.align-save-cancel-right :is(.editing-entry, .new-entry) .editgrid-actions,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid.align-save-cancel-right :is(.editing-entry, .new-entry) .editgrid-actions,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid.align-save-cancel-right :is(.editing-entry, .new-entry) .editgrid-actions,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid.align-save-cancel-right :is(.editing-entry, .new-entry) .editgrid-actions,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid.align-save-cancel-right :is(.editing-entry, .new-entry) .editgrid-actions {
  justify-content: flex-end;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid.align-save-cancel-right.invert-save-cancel-order :is(.editing-entry, .new-entry) .editgrid-actions,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid.align-save-cancel-right.invert-save-cancel-order :is(.editing-entry, .new-entry) .editgrid-actions,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid.align-save-cancel-right.invert-save-cancel-order :is(.editing-entry, .new-entry) .editgrid-actions,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid.align-save-cancel-right.invert-save-cancel-order :is(.editing-entry, .new-entry) .editgrid-actions,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid.align-save-cancel-right.invert-save-cancel-order :is(.editing-entry, .new-entry) .editgrid-actions,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid.align-save-cancel-right.invert-save-cancel-order :is(.editing-entry, .new-entry) .editgrid-actions {
  justify-content: flex-start;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-editgrid.invert-save-cancel-order :is(.editing-entry, .new-entry) .editgrid-actions,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-editgrid.invert-save-cancel-order :is(.editing-entry, .new-entry) .editgrid-actions,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-editgrid.invert-save-cancel-order :is(.editing-entry, .new-entry) .editgrid-actions,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid.invert-save-cancel-order :is(.editing-entry, .new-entry) .editgrid-actions,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid.invert-save-cancel-order :is(.editing-entry, .new-entry) .editgrid-actions,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid.invert-save-cancel-order :is(.editing-entry, .new-entry) .editgrid-actions {
  justify-content: flex-end;
  flex-direction: row-reverse;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid > .col-form-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid > .col-form-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid > .col-form-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid > .col-form-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid > .col-form-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid > .col-form-label {
  --label-font-size: 16px;
  --label-text-color: var(--color-gray-600);
  --label-bottom-margin: 24px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table {
  border: none;
  width: 100%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table thead,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table thead,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table thead,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table thead,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table thead,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table thead {
  opacity: 1;
  z-index: 10;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table thead tr th,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th {
  border: none;
  background-color: var(--color-gray-100);
  color: var(--color-primary-600);
  font-weight: 600;
  font-size: 12px;
  letter-spacing: initial;
  text-transform: none;
  padding-block: 6px;
  padding-inline-start: 14px;
  padding-inline-end: 0px;
  vertical-align: bottom;
  min-width: calc(100% / var(--eg-cols));
  display: table-cell;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table thead tr th:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th:first-child {
  border-radius: var(--cell-heading-border-radius, 6px 0 0 6px);
  padding-block: 6px;
  padding-inline-start: 14px;
  padding-inline-end: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th:first-child:empty,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th:first-child:empty,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table thead tr th:first-child:empty,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th:first-child:empty,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th:first-child:empty,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th:first-child:empty {
  padding: 0 !important;
  width: 32px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table thead tr th:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th:last-child {
  border-radius: var(--cell-heading-border-radius, 0 6px 6px 0);
  padding-inline-end: 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th i.fa-question-circle::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th i.fa-question-circle::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table thead tr th i.fa-question-circle::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th i.fa-question-circle::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th i.fa-question-circle::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th i.fa-question-circle::before {
  font-size: 15px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th:not([class]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th:not([class]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table thead tr th:not([class]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th:not([class]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th:not([class]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th:not([class]) {
  width: 50px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.dg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.dg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table thead tr th.dg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.dg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.dg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.dg-action-col {
  width: 50px;
  min-width: 40px;
  padding: 0;
  vertical-align: top;
  text-align: end;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.dg-action-col .formio-button-more,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.dg-action-col .formio-button-more,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table thead tr th.dg-action-col .formio-button-more,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.dg-action-col .formio-button-more,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.dg-action-col .formio-button-more,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.dg-action-col .formio-button-more {
  padding: 2px 12px;
  background: transparent;
  min-height: 30px;
  box-shadow: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.checkbox label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.checkbox label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table thead tr th.checkbox label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.checkbox label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.checkbox label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.checkbox label {
  text-align: center;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table thead tr th.checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) {
  padding-inline: 7px;
  text-align: center;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.checkbox input[type="checkbox"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.checkbox input[type="checkbox"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table thead tr th.checkbox input[type="checkbox"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.checkbox input[type="checkbox"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.checkbox input[type="checkbox"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.checkbox input[type="checkbox"] {
  width: auto;
  margin-inline-start: calc(50% - 13px/2);
  position: relative;
  height: 27px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th:is(.datagrid-hide-column-label, .button),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th:is(.datagrid-hide-column-label, .button),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table thead tr th:is(.datagrid-hide-column-label, .button),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th:is(.datagrid-hide-column-label, .button),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th:is(.datagrid-hide-column-label, .button),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th:is(.datagrid-hide-column-label, .button) {
  text-indent: -100vw;
  overflow: hidden;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.select,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.select,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table thead tr th.select,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.select,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.select,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.select {
  min-width: 100px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.radio label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.radio label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table thead tr th.radio label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.radio label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.radio label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.radio label {
  padding-left: 0;
  min-height: auto;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.field-required label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.field-required label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table thead tr th.field-required label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.field-required label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.field-required label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.field-required label {
  padding-inline-end: 24px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.field-required label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.field-required label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table thead tr th.field-required label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.field-required label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.field-required label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.field-required label span {
  display: inline-flex;
  align-items: center;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.field-required label span::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.field-required label span::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table thead tr th.field-required label span::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.field-required label span::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.field-required label span::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.field-required label span::after {
  display: inline-block;
  content: " ";
  background-color: rgba(255, 0, 59, 0.7);
  width: 6px;
  height: 6px;
  border-radius: 5px;
  min-width: 6px;
  margin-inline-start: 6px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.field-required::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.field-required::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table thead tr th.field-required::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.field-required::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.field-required::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.field-required::after {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table thead tr th.button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th.button {
  box-shadow: none !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th > label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th > label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table thead tr th > label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th > label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th > label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th > label span {
  line-break: anywhere;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th input[ref*="-searchField"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th input[ref*="-searchField"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table thead tr th input[ref*="-searchField"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th input[ref*="-searchField"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th input[ref*="-searchField"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th input[ref*="-searchField"] {
  margin-top: 4px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th .formio-button-reset,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th .formio-button-reset,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table thead tr th .formio-button-reset,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th .formio-button-reset,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th .formio-button-reset,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table thead tr th .formio-button-reset {
  padding: 0;
  min-height: 16px;
  width: 16px;
  box-shadow: none;
  bottom: 3.5px;
  inset-inline-end: 3.5px;
  color: transparent;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table thead tr:has(.formio-button-reset) th,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table thead tr:has(.formio-button-reset) th,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table thead tr:has(.formio-button-reset) th,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table thead tr:has(.formio-button-reset) th,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table thead tr:has(.formio-button-reset) th,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table thead tr:has(.formio-button-reset) th {
  vertical-align: top;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr {
  background-color: transparent;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td {
  background-color: transparent;
  border: none;
  padding: 0.75rem 0 0.75rem 14px;
  vertical-align: top;
  display: table-cell;
  min-width: calc(100% / var(--eg-cols));
  width: 100%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([class*="width"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([class*="width"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td:not([class*="width"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([class*="width"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([class*="width"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([class*="width"]) {
  width: calc(100% / var(--eg-cols));
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td > .formio-component:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td > .formio-component:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td > .formio-component:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td > .formio-component:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td > .formio-component:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td > .formio-component:first-child {
  margin-top: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.radio .form-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.radio .form-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.radio .form-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.radio .form-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.radio .form-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.radio .form-radio {
  padding-top: 10px;
  margin-bottom: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.radio label input[type="radio"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.radio label input[type="radio"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.radio label input[type="radio"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.radio label input[type="radio"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.radio label input[type="radio"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.radio label input[type="radio"] {
  padding: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.radio-switch .form-radio.radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.radio-switch .form-radio.radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.radio-switch .form-radio.radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.radio-switch .form-radio.radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.radio-switch .form-radio.radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.radio-switch .form-radio.radio {
  padding-top: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style .input-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style .input-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style .input-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style .input-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style .input-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style .input-group {
  height: auto;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style .input-group :where(.input-group-append, .input-group-prepend),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style .input-group :where(.input-group-append, .input-group-prepend),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style .input-group :where(.input-group-append, .input-group-prepend),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style .input-group :where(.input-group-append, .input-group-prepend),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style .input-group :where(.input-group-append, .input-group-prepend),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style .input-group :where(.input-group-append, .input-group-prepend) {
  border: none;
  height: 15px;
  line-height: 15px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style .input-group :where(.input-group-append, .input-group-prepend) .input-group-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style .input-group :where(.input-group-append, .input-group-prepend) .input-group-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style .input-group :where(.input-group-append, .input-group-prepend) .input-group-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style .input-group :where(.input-group-append, .input-group-prepend) .input-group-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style .input-group :where(.input-group-append, .input-group-prepend) .input-group-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style .input-group :where(.input-group-append, .input-group-prepend) .input-group-text {
  padding: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style .input-group .input-group-prepend .input-group-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style .input-group .input-group-prepend .input-group-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style .input-group .input-group-prepend .input-group-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style .input-group .input-group-prepend .input-group-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style .input-group .input-group-prepend .input-group-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style .input-group .input-group-prepend .input-group-text {
  padding-inline-end: 4px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style .input-group input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style .input-group input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style .input-group input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style .input-group input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style .input-group input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style .input-group input.form-control {
  padding-inline-start: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style input.form-control {
  height: 20px;
  border: none !important;
  border-radius: 0 !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style input.form-control:disabled,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style input.form-control:disabled,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style input.form-control:disabled,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style input.form-control:disabled,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style input.form-control:disabled,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style input.form-control:disabled {
  background-color: transparent !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style.formio-component-datetime .input-group .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style.formio-component-datetime .input-group .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style.formio-component-datetime .input-group .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style.formio-component-datetime .input-group .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style.formio-component-datetime .input-group .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component.value-label-style.formio-component-datetime .input-group .input-group-append {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component-checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component-checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component-checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component-checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component-checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component-checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check-label span {
  color: transparent;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  width: 14px;
  display: block;
  margin: 0 auto;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component-checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check-label span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component-checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check-label span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component-checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check-label span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component-checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check-label span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component-checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check-label span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component-checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check-label span::before {
  inset-inline-start: calc(50% - 7px);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component-checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check-label input.form-check-input:checked + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component-checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check-label input.form-check-input:checked + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component-checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check-label input.form-check-input:checked + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component-checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check-label input.form-check-input:checked + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component-checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check-label input.form-check-input:checked + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component-checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check-label input.form-check-input:checked + span {
  color: transparent !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component-checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check-label .form-check.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component-checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check-label .form-check.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component-checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check-label .form-check.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component-checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check-label .form-check.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component-checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check-label .form-check.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component-checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check-label .form-check.checkbox {
  padding: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component-checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component-checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component-checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component-checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component-checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component-checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check.checkbox {
  padding: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component:is(.formio-component-select) span.value,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component:is(.formio-component-select) span.value,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component:is(.formio-component-select) span.value,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component:is(.formio-component-select) span.value,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component:is(.formio-component-select) span.value,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component:is(.formio-component-select) span.value {
  font-size: 16px;
  min-height: 40px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) {
  width: 50px;
  text-align: center;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-edit-row),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-edit-row),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-edit-row),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-edit-row),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-edit-row),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-edit-row) {
  background-color: transparent;
  padding: 0 12px;
  margin-inline-start: auto;
  display: flex;
  margin-inline-end: 0;
  height: 40px;
  align-items: center;
  border: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-edit-row) i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-edit-row) i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-edit-row) i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-edit-row) i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-edit-row) i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-edit-row) i {
  color: rgba(70, 81, 170, 0.4);
  font-size: 16px;
  font-weight: 400;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-edit-row):hover i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-edit-row):hover i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-edit-row):hover i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-edit-row):hover i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-edit-row):hover i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-edit-row):hover i {
  color: var(--color-primary-600);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-remove-row, .formio-drag-button),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-remove-row, .formio-drag-button),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-remove-row, .formio-drag-button),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-remove-row, .formio-drag-button),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-remove-row, .formio-drag-button),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-remove-row, .formio-drag-button) {
  background-color: transparent;
  padding: 0 14px;
  margin-inline-start: auto;
  display: flex;
  margin-inline-end: 0;
  height: 40px;
  align-items: center;
  border: none;
  box-shadow: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-remove-row, .formio-drag-button) i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-remove-row, .formio-drag-button) i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-remove-row, .formio-drag-button) i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-remove-row, .formio-drag-button) i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-remove-row, .formio-drag-button) i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-remove-row, .formio-drag-button) i {
  color: rgba(255, 0, 59, 0.3);
  font-size: 16px;
  font-family: var(--fontawesome-font);
  font-weight: 400;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-remove-row, .formio-drag-button) i::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-remove-row, .formio-drag-button) i::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-remove-row, .formio-drag-button) i::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-remove-row, .formio-drag-button) i::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-remove-row, .formio-drag-button) i::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-remove-row, .formio-drag-button) i::before {
  content: "\F00D";
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-remove-row, .formio-drag-button):hover i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-remove-row, .formio-drag-button):hover i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-remove-row, .formio-drag-button):hover i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-remove-row, .formio-drag-button):hover i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-remove-row, .formio-drag-button):hover i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:not([ref]) :where(.formio-button-remove-row, .formio-drag-button):hover i {
  color: #F75F5F;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:first-child {
  padding-inline-start: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:first-child.value-label-style ~ td[ref].value-label-style ~ td.dg-action-col .formio-button-remove-row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:first-child.value-label-style ~ td[ref].value-label-style ~ td.dg-action-col .formio-button-remove-row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td:first-child.value-label-style ~ td[ref].value-label-style ~ td.dg-action-col .formio-button-remove-row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:first-child.value-label-style ~ td[ref].value-label-style ~ td.dg-action-col .formio-button-remove-row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:first-child.value-label-style ~ td[ref].value-label-style ~ td.dg-action-col .formio-button-remove-row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td:first-child.value-label-style ~ td[ref].value-label-style ~ td.dg-action-col .formio-button-remove-row {
  height: auto;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style {
  vertical-align: middle;
  padding-block: 5px 0px !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style:not(:first-child) .formio-component.value-label-style input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style:not(:first-child) .formio-component.value-label-style input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style:not(:first-child) .formio-component.value-label-style input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style:not(:first-child) .formio-component.value-label-style input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style:not(:first-child) .formio-component.value-label-style input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style:not(:first-child) .formio-component.value-label-style input.form-control {
  padding-inline-start: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style:first-child .formio-component.value-label-style input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style:first-child .formio-component.value-label-style input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style:first-child .formio-component.value-label-style input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style:first-child .formio-component.value-label-style input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style:first-child .formio-component.value-label-style input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style:first-child .formio-component.value-label-style input.form-control {
  padding-inline-start: 14px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style.select .formio-choices .form-control.selection,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style.select .formio-choices .form-control.selection,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style.select .formio-choices .form-control.selection,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style.select .formio-choices .form-control.selection,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style.select .formio-choices .form-control.selection,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style.select .formio-choices .form-control.selection {
  height: auto;
  min-height: 40px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style.select .formio-disabled-component .choices__button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style.select .formio-disabled-component .choices__button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style.select .formio-disabled-component .choices__button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style.select .formio-disabled-component .choices__button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style.select .formio-disabled-component .choices__button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style.select .formio-disabled-component .choices__button {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style.select .formio-select-autocomplete-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style.select .formio-select-autocomplete-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style.select .formio-select-autocomplete-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style.select .formio-select-autocomplete-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style.select .formio-select-autocomplete-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style.select .formio-select-autocomplete-input {
  top: calc(100% - 2px);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style ~ .button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style ~ .button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style ~ .button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style ~ .button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style ~ .button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.value-label-style ~ .button {
  padding-block: 5px 0px !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component-textarea:not(.formio-disabled-component) .card.card-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component-textarea:not(.formio-disabled-component) .card.card-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component-textarea:not(.formio-disabled-component) .card.card-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component-textarea:not(.formio-disabled-component) .card.card-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component-textarea:not(.formio-disabled-component) .card.card-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td .formio-component-textarea:not(.formio-disabled-component) .card.card-body {
  margin-bottom: 0px !important;
  box-shadow: none;
  border: solid 1px rgba(110, 114, 150, 0.15);
  border-radius: 6px !important;
  font-weight: 600;
  min-height: 40px;
  letter-spacing: 0.4pt;
  text-align: start;
  background: #efefef !important;
  padding: 8.5px 14px;
  line-height: 1.5;
  font-size: 16px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox {
  vertical-align: middle;
  padding-block: 5px 0 !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) {
  padding-inline: 7px !important;
  text-align: center;
  vertical-align: middle;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check-label {
  margin: 0;
  padding: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) .form-check-input {
  width: auto;
  margin-inline: auto;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label):not(.datagrid-checkbox-hide-label) .form-check.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label):not(.datagrid-checkbox-hide-label) .form-check.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label):not(.datagrid-checkbox-hide-label) .form-check.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label):not(.datagrid-checkbox-hide-label) .form-check.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label):not(.datagrid-checkbox-hide-label) .form-check.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label):not(.datagrid-checkbox-hide-label) .form-check.checkbox {
  padding-top: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label).horizontal-align-center .form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label).horizontal-align-center .form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label).horizontal-align-center .form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label).horizontal-align-center .form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label).horizontal-align-center .form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label).horizontal-align-center .form-check-label {
  width: auto !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:not(.datagrid-checkbox-hide-label) .form-check.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:not(.datagrid-checkbox-hide-label) .form-check.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:not(.datagrid-checkbox-hide-label) .form-check.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:not(.datagrid-checkbox-hide-label) .form-check.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:not(.datagrid-checkbox-hide-label) .form-check.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:not(.datagrid-checkbox-hide-label) .form-check.checkbox {
  padding-top: 0px;
  justify-content: center;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:not(.datagrid-checkbox-hide-label) .form-check.checkbox .form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:not(.datagrid-checkbox-hide-label) .form-check.checkbox .form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:not(.datagrid-checkbox-hide-label) .form-check.checkbox .form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:not(.datagrid-checkbox-hide-label) .form-check.checkbox .form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:not(.datagrid-checkbox-hide-label) .form-check.checkbox .form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox:not(.datagrid-checkbox-hide-label) .form-check.checkbox .form-check-label {
  width: auto !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox.horizontal-align-center .form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox.horizontal-align-center .form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox.horizontal-align-center .form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox.horizontal-align-center .form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox.horizontal-align-center .form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox.horizontal-align-center .form-check-label {
  width: auto !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox .formio-component-label-hidden .form-check-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox .formio-component-label-hidden .form-check-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox .formio-component-label-hidden .form-check-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox .formio-component-label-hidden .form-check-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox .formio-component-label-hidden .form-check-input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.checkbox .formio-component-label-hidden .form-check-input {
  width: auto;
  margin-inline: auto;
  margin-top: 8px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.datetime .form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.datetime .form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.datetime .form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.datetime .form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.datetime .form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.datetime .form-control {
  min-height: 40px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.vertical-align-center,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.vertical-align-center,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.vertical-align-center,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.vertical-align-center,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.vertical-align-center,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.vertical-align-center {
  vertical-align: middle;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.horizontal-align-right,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.horizontal-align-right,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.horizontal-align-right,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.horizontal-align-right,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.horizontal-align-right,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.horizontal-align-right {
  text-align: end;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td label.field-required::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td label.field-required::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td label.field-required::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td label.field-required::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td label.field-required::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td label.field-required::after {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.button {
  box-shadow: none !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.button .formio-component-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.button .formio-component-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.button .formio-component-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.button .formio-component-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.button .formio-component-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.button .formio-component-button {
  line-height: 1;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.textarea textarea.form-control[rows="1"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.textarea textarea.form-control[rows="1"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.textarea textarea.form-control[rows="1"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.textarea textarea.form-control[rows="1"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.textarea textarea.form-control[rows="1"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.textarea textarea.form-control[rows="1"] {
  min-height: var(--field-height);
  font-size: var(--field-font-size);
  line-height: 1.5;
  padding-top: 8px;
  field-sizing: content;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.textarea.value-label-style textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.textarea.value-label-style textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.textarea.value-label-style textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.textarea.value-label-style textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.textarea.value-label-style textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.textarea.value-label-style textarea.form-control {
  padding-block: 8px;
  line-height: 1.5;
  font-size: 16px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.textarea.value-label-style .formio-component-textarea.formio-disabled-component .formio-editor-read-only-content,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.textarea.value-label-style .formio-component-textarea.formio-disabled-component .formio-editor-read-only-content,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.textarea.value-label-style .formio-component-textarea.formio-disabled-component .formio-editor-read-only-content,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.textarea.value-label-style .formio-component-textarea.formio-disabled-component .formio-editor-read-only-content,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.textarea.value-label-style .formio-component-textarea.formio-disabled-component .formio-editor-read-only-content,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.textarea.value-label-style .formio-component-textarea.formio-disabled-component .formio-editor-read-only-content {
  border: none;
  background: transparent;
  padding-inline: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file {
  padding: 8px 10px;
  min-height: 42px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file .fileSelector {
  padding: 8px 10px;
  font-size: 10px;
  align-items: center;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file .fileSelector i.fa-cloud-upload,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file .fileSelector i.fa-cloud-upload,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file .fileSelector i.fa-cloud-upload,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file .fileSelector i.fa-cloud-upload,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file .fileSelector i.fa-cloud-upload,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file .fileSelector i.fa-cloud-upload {
  inset-inline-end: 12px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file .fileSelector a.browse,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file .fileSelector a.browse,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file .fileSelector a.browse,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file .fileSelector a.browse,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file .fileSelector a.browse,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file .fileSelector a.browse {
  font-size: 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.has-files,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.has-files,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.has-files,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.has-files,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.has-files,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.has-files {
  padding-block: 0;
  padding-inline: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.has-files.formio-component-multiple .list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.has-files.formio-component-multiple .list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.has-files.formio-component-multiple .list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.has-files.formio-component-multiple .list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.has-files.formio-component-multiple .list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.has-files.formio-component-multiple .list-group {
  margin-bottom: 30px;
  justify-content: center;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.has-files.formio-component-multiple .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.has-files.formio-component-multiple .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.has-files.formio-component-multiple .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.has-files.formio-component-multiple .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.has-files.formio-component-multiple .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.has-files.formio-component-multiple .fileSelector {
  align-items: flex-end;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.has-files .list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.has-files .list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.has-files .list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.has-files .list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.has-files .list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.has-files .list-group {
  margin-bottom: 0px;
  min-height: 40px;
  align-items: center;
  display: flex;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.has-files .list-group .col-md-9,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.has-files .list-group .col-md-9,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.has-files .list-group .col-md-9,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.has-files .list-group .col-md-9,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.has-files .list-group .col-md-9,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.has-files .list-group .col-md-9 {
  padding: 8px 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.formio-component-multiple .list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.formio-component-multiple .list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.formio-component-multiple .list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.formio-component-multiple .list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.formio-component-multiple .list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.formio-component-multiple .list-group {
  flex-direction: column;
  align-items: flex-start;
  padding-inline: 12px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.formio-component-multiple .list-group .list-group-item,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.formio-component-multiple .list-group .list-group-item,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.formio-component-multiple .list-group .list-group-item,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.formio-component-multiple .list-group .list-group-item,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.formio-component-multiple .list-group .list-group-item,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.formio-component-multiple .list-group .list-group-item {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.formio-component-multiple .fileSelector i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.formio-component-multiple .fileSelector i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.formio-component-multiple .fileSelector i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.formio-component-multiple .fileSelector i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.formio-component-multiple .fileSelector i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file .formio-component-file.formio-component-multiple .fileSelector i {
  bottom: 11px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.file label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.file label.field-required.control-label--hidden::after {
  display: block;
  inset-inline-end: 48px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.columns .formio-component-columns,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.columns .formio-component-columns,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.columns .formio-component-columns,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.columns .formio-component-columns,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.columns .formio-component-columns,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.columns .formio-component-columns {
  margin-inline: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.columns .formio-component-columns > [class^="col-"]:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.columns .formio-component-columns > [class^="col-"]:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.columns .formio-component-columns > [class^="col-"]:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.columns .formio-component-columns > [class^="col-"]:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.columns .formio-component-columns > [class^="col-"]:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.columns .formio-component-columns > [class^="col-"]:first-child {
  padding-inline-start: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.columns .formio-component-columns > [class^="col-"]:is(:last-child, .cols-grid-end),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.columns .formio-component-columns > [class^="col-"]:is(:last-child, .cols-grid-end),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.columns .formio-component-columns > [class^="col-"]:is(:last-child, .cols-grid-end),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.columns .formio-component-columns > [class^="col-"]:is(:last-child, .cols-grid-end),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.columns .formio-component-columns > [class^="col-"]:is(:last-child, .cols-grid-end),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.columns .formio-component-columns > [class^="col-"]:is(:last-child, .cols-grid-end) {
  padding-inline-end: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.editgrid .open-modal-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.editgrid .open-modal-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr td.editgrid .open-modal-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.editgrid .open-modal-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.editgrid .open-modal-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr td.editgrid .open-modal-button {
  --button-text-color: var(--button-bg-color);
  --button-text-color-hover: var(--button-bg-color);
  --button-border-width: 1px;
  --button-border-color: var(--button-bg-color);
  background-color: var(--button-outline-bg-color);
  border-color: var(--button-border-color);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr:first-child td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr:first-child td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr:first-child td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr:first-child td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr:first-child td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr:first-child td {
  padding-top: 1.5rem;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr:has(.has-files, .formio-component-textfield, .formio-component-textarea, .radio-switch) td.value-label-style,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr:has(.has-files, .formio-component-textfield, .formio-component-textarea, .radio-switch) td.value-label-style,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr:has(.has-files, .formio-component-textfield, .formio-component-textarea, .radio-switch) td.value-label-style,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr:has(.has-files, .formio-component-textfield, .formio-component-textarea, .radio-switch) td.value-label-style,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr:has(.has-files, .formio-component-textfield, .formio-component-textarea, .radio-switch) td.value-label-style,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr:has(.has-files, .formio-component-textfield, .formio-component-textarea, .radio-switch) td.value-label-style {
  vertical-align: top;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr:has(.has-files, .formio-component-textfield, .formio-component-textarea, .radio-switch) td.value-label-style .value-label-style input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr:has(.has-files, .formio-component-textfield, .formio-component-textarea, .radio-switch) td.value-label-style .value-label-style input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody tr:has(.has-files, .formio-component-textfield, .formio-component-textarea, .radio-switch) td.value-label-style .value-label-style input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr:has(.has-files, .formio-component-textfield, .formio-component-textarea, .radio-switch) td.value-label-style .value-label-style input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr:has(.has-files, .formio-component-textfield, .formio-component-textarea, .radio-switch) td.value-label-style .value-label-style input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody tr:has(.has-files, .formio-component-textfield, .formio-component-textarea, .radio-switch) td.value-label-style .value-label-style input.form-control {
  height: 40px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tbody:first-child tr:first-child td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tbody:first-child tr:first-child td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tbody:first-child tr:first-child td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tbody:first-child tr:first-child td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tbody:first-child tr:first-child td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tbody:first-child tr:first-child td {
  padding-top: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tfoot tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tfoot tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tfoot tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tfoot tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tfoot tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tfoot tr td {
  padding: 0.75rem 0 0;
  border: none;
  text-align: center;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tfoot tr td button.btn.formio-button-add-row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tfoot tr td button.btn.formio-button-add-row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tfoot tr td button.btn.formio-button-add-row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tfoot tr td button.btn.formio-button-add-row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tfoot tr td button.btn.formio-button-add-row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tfoot tr td button.btn.formio-button-add-row {
  border: none;
  border-radius: 4px !important;
  color: var(--color-primary-600);
  background-color: rgba(70, 81, 170, 0.1);
  padding: 0.9rem;
  height: 32px;
  font-weight: 600;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  transition-duration: 0.3s;
  gap: 8px;
  margin-top: 6px;
  --button-shadow: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tfoot tr td button.btn.formio-button-add-row > i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tfoot tr td button.btn.formio-button-add-row > i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tfoot tr td button.btn.formio-button-add-row > i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tfoot tr td button.btn.formio-button-add-row > i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tfoot tr td button.btn.formio-button-add-row > i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tfoot tr td button.btn.formio-button-add-row > i {
  font-weight: 400;
  font-family: var(--fontawesome-font);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table tfoot tr td button.btn.formio-button-add-row:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table tfoot tr td button.btn.formio-button-add-row:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table tfoot tr td button.btn.formio-button-add-row:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table tfoot tr td button.btn.formio-button-add-row:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table tfoot tr td button.btn.formio-button-add-row:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table tfoot tr td button.btn.formio-button-add-row:hover {
  color: white;
  background-color: var(--color-primary-600);
  box-shadow: 0 9px 20px rgba(70, 81, 170, 0.4) !important;
  z-index: 999;
  position: relative;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table.table-striped tbody tr:nth-of-type(odd) td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table.table-striped tbody tr:nth-of-type(odd) td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table.table-striped tbody tr:nth-of-type(odd) td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table.table-striped tbody tr:nth-of-type(odd) td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table.table-striped tbody tr:nth-of-type(odd) td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table.table-striped tbody tr:nth-of-type(odd) td {
  background-color: rgba(70, 81, 170, 0.05);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table.table-hover tbody tr:hover td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table.table-hover tbody tr:hover td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table.table-hover tbody tr:hover td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table.table-hover tbody tr:hover td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table.table-hover tbody tr:hover td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table.table-hover tbody tr:hover td {
  background-color: rgba(70, 81, 170, 0.05);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm thead tr th,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm thead tr th,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table.table-sm thead tr th,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm thead tr th,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm thead tr th,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm thead tr th {
  padding-inline-start: 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td {
  padding: 1px 0;
  text-align: start;
  vertical-align: middle;
  padding-inline-start: 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td button:not(.choices__button),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td button:not(.choices__button),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td button:not(.choices__button),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td button:not(.choices__button),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td button:not(.choices__button),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td button:not(.choices__button) {
  width: 100%;
  max-width: 100%;
  white-space: normal;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td button:not(.choices__button):hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td button:not(.choices__button):hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td button:not(.choices__button):hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td button:not(.choices__button):hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td button:not(.choices__button):hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td button:not(.choices__button):hover {
  box-shadow: none !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td .formio-component.value-label-style .input-group :where(.input-group-append, .input-group-prepend),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td .formio-component.value-label-style .input-group :where(.input-group-append, .input-group-prepend),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td .formio-component.value-label-style .input-group :where(.input-group-append, .input-group-prepend),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td .formio-component.value-label-style .input-group :where(.input-group-append, .input-group-prepend),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td .formio-component.value-label-style .input-group :where(.input-group-append, .input-group-prepend),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td .formio-component.value-label-style .input-group :where(.input-group-append, .input-group-prepend) {
  margin-top: 16px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td .formio-component.value-label-style input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td .formio-component.value-label-style input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td .formio-component.value-label-style input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td .formio-component.value-label-style input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td .formio-component.value-label-style input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td .formio-component.value-label-style input.form-control {
  height: 36px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td .formio-component .form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td .formio-component .form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td .formio-component .form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td .formio-component .form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td .formio-component .form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td .formio-component .form-control {
  padding-inline: 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td.datetime .form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td.datetime .form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td.datetime .form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td.datetime .form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td.datetime .form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td.datetime .form-control {
  min-height: 36px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td.textarea textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td.textarea textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td.textarea textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td.textarea textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td.textarea textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td.textarea textarea.form-control {
  padding-block: 6px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td.textarea.value-label-style textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td.textarea.value-label-style textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td.textarea.value-label-style textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td.textarea.value-label-style textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td.textarea.value-label-style textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td.textarea.value-label-style textarea.form-control {
  padding-inline: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td.dg-action-col .formio-button-remove-row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td.dg-action-col .formio-button-remove-row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td.dg-action-col .formio-button-remove-row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td.dg-action-col .formio-button-remove-row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td.dg-action-col .formio-button-remove-row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td.dg-action-col .formio-button-remove-row {
  --default-button-height: 36px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm tbody tr td:first-child {
  padding-inline-start: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm thead + tbody tr:first-child td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm thead + tbody tr:first-child td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table.table-sm thead + tbody tr:first-child td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm thead + tbody tr:first-child td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm thead + tbody tr:first-child td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table.table-sm thead + tbody tr:first-child td {
  padding-top: 5px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table[style*="table-layout: fixed;"] thead tr th:not([class*="width"], .dg-action-col),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table[style*="table-layout: fixed;"] thead tr th:not([class*="width"], .dg-action-col),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table[style*="table-layout: fixed;"] thead tr th:not([class*="width"], .dg-action-col),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table[style*="table-layout: fixed;"] thead tr th:not([class*="width"], .dg-action-col),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table[style*="table-layout: fixed;"] thead tr th:not([class*="width"], .dg-action-col),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table[style*="table-layout: fixed;"] thead tr th:not([class*="width"], .dg-action-col) {
  width: calc(100% / var(--eg-cols));
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table[style*="table-layout: fixed;"] thead tr th.dg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table[style*="table-layout: fixed;"] thead tr th.dg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table[style*="table-layout: fixed;"] thead tr th.dg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table[style*="table-layout: fixed;"] thead tr th.dg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table[style*="table-layout: fixed;"] thead tr th.dg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table[style*="table-layout: fixed;"] thead tr th.dg-action-col {
  width: 50px;
  padding: 0;
  vertical-align: top;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table[style*="--eg-cols:0;"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table[style*="--eg-cols:0;"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table[style*="--eg-cols:0;"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table[style*="--eg-cols:0;"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table[style*="--eg-cols:0;"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table[style*="--eg-cols:0;"] {
  table-layout: fixed;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid table.table.datagrid-table[style*="--eg-cols:0;"] tbody tr td[ref],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid table.table.datagrid-table[style*="--eg-cols:0;"] tbody tr td[ref],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid table.table.datagrid-table[style*="--eg-cols:0;"] tbody tr td[ref],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid table.table.datagrid-table[style*="--eg-cols:0;"] tbody tr td[ref],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid table.table.datagrid-table[style*="--eg-cols:0;"] tbody tr td[ref],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid table.table.datagrid-table[style*="--eg-cols:0;"] tbody tr td[ref] {
  width: 100%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.has-footer table.table.table-sm tfoot tr td button.btn.formio-button-add-row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.has-footer table.table.table-sm tfoot tr td button.btn.formio-button-add-row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.has-footer table.table.table-sm tfoot tr td button.btn.formio-button-add-row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.has-footer table.table.table-sm tfoot tr td button.btn.formio-button-add-row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.has-footer table.table.table-sm tfoot tr td button.btn.formio-button-add-row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.has-footer table.table.table-sm tfoot tr td button.btn.formio-button-add-row {
  margin-bottom: -115px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.has-footer tfoot tr td button.btn.formio-button-add-row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.has-footer tfoot tr td button.btn.formio-button-add-row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.has-footer tfoot tr td button.btn.formio-button-add-row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.has-footer tfoot tr td button.btn.formio-button-add-row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.has-footer tfoot tr td button.btn.formio-button-add-row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.has-footer tfoot tr td button.btn.formio-button-add-row {
  margin-bottom: -125px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.has-footer tfoot tr td[colspan],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.has-footer tfoot tr td[colspan],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.has-footer tfoot tr td[colspan],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.has-footer tfoot tr td[colspan],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.has-footer tfoot tr td[colspan],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.has-footer tfoot tr td[colspan] {
  display: block;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid :is(th, td).width5,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid :is(th, td).width5,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid :is(th, td).width5,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid :is(th, td).width5,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid :is(th, td).width5,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid :is(th, td).width5 {
  width: 5%;
  min-width: 5%;
  max-width: 5%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid :is(th, td).width10,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid :is(th, td).width10,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid :is(th, td).width10,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid :is(th, td).width10,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid :is(th, td).width10,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid :is(th, td).width10 {
  width: 10%;
  min-width: 10%;
  max-width: 10%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid :is(th, td).width15,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid :is(th, td).width15,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid :is(th, td).width15,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid :is(th, td).width15,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid :is(th, td).width15,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid :is(th, td).width15 {
  width: 15%;
  min-width: 15%;
  max-width: 15%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid :is(th, td).width20,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid :is(th, td).width20,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid :is(th, td).width20,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid :is(th, td).width20,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid :is(th, td).width20,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid :is(th, td).width20 {
  width: 20%;
  min-width: 20%;
  max-width: 20%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid :is(th, td).width25,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid :is(th, td).width25,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid :is(th, td).width25,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid :is(th, td).width25,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid :is(th, td).width25,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid :is(th, td).width25 {
  width: 25%;
  min-width: 25%;
  max-width: 25%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid :is(th, td).width30,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid :is(th, td).width30,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid :is(th, td).width30,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid :is(th, td).width30,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid :is(th, td).width30,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid :is(th, td).width30 {
  width: 30%;
  min-width: 30%;
  max-width: 30%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid :is(th, td).width35,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid :is(th, td).width35,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid :is(th, td).width35,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid :is(th, td).width35,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid :is(th, td).width35,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid :is(th, td).width35 {
  width: 35%;
  min-width: 35%;
  max-width: 35%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid :is(th, td).width40,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid :is(th, td).width40,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid :is(th, td).width40,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid :is(th, td).width40,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid :is(th, td).width40,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid :is(th, td).width40 {
  width: 40%;
  min-width: 40%;
  max-width: 40%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid :is(th, td).width45,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid :is(th, td).width45,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid :is(th, td).width45,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid :is(th, td).width45,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid :is(th, td).width45,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid :is(th, td).width45 {
  width: 45%;
  min-width: 45%;
  max-width: 45%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid :is(th, td).width50,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid :is(th, td).width50,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid :is(th, td).width50,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid :is(th, td).width50,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid :is(th, td).width50,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid :is(th, td).width50 {
  width: 50%;
  min-width: 50%;
  max-width: 50%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid :is(th, td).width55,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid :is(th, td).width55,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid :is(th, td).width55,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid :is(th, td).width55,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid :is(th, td).width55,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid :is(th, td).width55 {
  width: 55%;
  min-width: 55%;
  max-width: 55%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid :is(th, td).width60,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid :is(th, td).width60,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid :is(th, td).width60,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid :is(th, td).width60,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid :is(th, td).width60,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid :is(th, td).width60 {
  width: 60%;
  min-width: 60%;
  max-width: 60%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid :is(th, td).width65,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid :is(th, td).width65,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid :is(th, td).width65,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid :is(th, td).width65,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid :is(th, td).width65,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid :is(th, td).width65 {
  width: 65%;
  min-width: 65%;
  max-width: 65%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid :is(th, td).width70,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid :is(th, td).width70,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid :is(th, td).width70,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid :is(th, td).width70,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid :is(th, td).width70,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid :is(th, td).width70 {
  width: 70%;
  min-width: 70%;
  max-width: 70%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid :is(th, td).width75,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid :is(th, td).width75,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid :is(th, td).width75,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid :is(th, td).width75,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid :is(th, td).width75,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid :is(th, td).width75 {
  width: 75%;
  min-width: 75%;
  max-width: 75%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid :is(th, td).width80,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid :is(th, td).width80,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid :is(th, td).width80,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid :is(th, td).width80,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid :is(th, td).width80,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid :is(th, td).width80 {
  width: 80%;
  min-width: 80%;
  max-width: 80%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid :is(th, td).width85,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid :is(th, td).width85,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid :is(th, td).width85,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid :is(th, td).width85,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid :is(th, td).width85,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid :is(th, td).width85 {
  width: 85%;
  min-width: 85%;
  max-width: 85%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid :is(th, td).width90,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid :is(th, td).width90,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid :is(th, td).width90,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid :is(th, td).width90,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid :is(th, td).width90,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid :is(th, td).width90 {
  width: 90%;
  min-width: 90%;
  max-width: 90%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid :is(th, td).width95,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid :is(th, td).width95,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid :is(th, td).width95,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid :is(th, td).width95,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid :is(th, td).width95,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid :is(th, td).width95 {
  width: 95%;
  min-width: 95%;
  max-width: 95%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid :is(th, td).width100,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid :is(th, td).width100,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid :is(th, td).width100,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid :is(th, td).width100,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid :is(th, td).width100,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid :is(th, td).width100 {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid:has(thead) + .formio-component-datagrid:has(tbody:first-child) table.table.datagrid-table tbody tr td.dg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid:has(thead) + .formio-component-datagrid:has(tbody:first-child) table.table.datagrid-table tbody tr td.dg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid:has(thead) + .formio-component-datagrid:has(tbody:first-child) table.table.datagrid-table tbody tr td.dg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid:has(thead) + .formio-component-datagrid:has(tbody:first-child) table.table.datagrid-table tbody tr td.dg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid:has(thead) + .formio-component-datagrid:has(tbody:first-child) table.table.datagrid-table tbody tr td.dg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid:has(thead) + .formio-component-datagrid:has(tbody:first-child) table.table.datagrid-table tbody tr td.dg-action-col {
  min-width: 40px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.no-rows.hide-if-empty,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.no-rows.hide-if-empty,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.no-rows.hide-if-empty,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.no-rows.hide-if-empty,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.no-rows.hide-if-empty,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.no-rows.hide-if-empty {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.adding-rows-disabled.removing-rows-disabled table.table.datagrid-table :is(thead, tbody) tr :is(th, td).dg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.adding-rows-disabled.removing-rows-disabled table.table.datagrid-table :is(thead, tbody) tr :is(th, td).dg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.adding-rows-disabled.removing-rows-disabled table.table.datagrid-table :is(thead, tbody) tr :is(th, td).dg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.adding-rows-disabled.removing-rows-disabled table.table.datagrid-table :is(thead, tbody) tr :is(th, td).dg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.adding-rows-disabled.removing-rows-disabled table.table.datagrid-table :is(thead, tbody) tr :is(th, td).dg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.adding-rows-disabled.removing-rows-disabled table.table.datagrid-table :is(thead, tbody) tr :is(th, td).dg-action-col {
  padding: 0;
  min-width: 0px;
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.adding-rows-disabled.removing-rows-disabled table.table.datagrid-table :is(thead, tbody) tr :is(th, td).dg-action-col .formio-button-more,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.adding-rows-disabled.removing-rows-disabled table.table.datagrid-table :is(thead, tbody) tr :is(th, td).dg-action-col .formio-button-more,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.adding-rows-disabled.removing-rows-disabled table.table.datagrid-table :is(thead, tbody) tr :is(th, td).dg-action-col .formio-button-more,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.adding-rows-disabled.removing-rows-disabled table.table.datagrid-table :is(thead, tbody) tr :is(th, td).dg-action-col .formio-button-more,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.adding-rows-disabled.removing-rows-disabled table.table.datagrid-table :is(thead, tbody) tr :is(th, td).dg-action-col .formio-button-more,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.adding-rows-disabled.removing-rows-disabled table.table.datagrid-table :is(thead, tbody) tr :is(th, td).dg-action-col .formio-button-more {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.adding-rows-disabled.removing-rows-disabled:has(.dg-action-col) table.table.datagrid-table thead tr th:nth-last-of-type(2),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.adding-rows-disabled.removing-rows-disabled:has(.dg-action-col) table.table.datagrid-table thead tr th:nth-last-of-type(2),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.adding-rows-disabled.removing-rows-disabled:has(.dg-action-col) table.table.datagrid-table thead tr th:nth-last-of-type(2),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.adding-rows-disabled.removing-rows-disabled:has(.dg-action-col) table.table.datagrid-table thead tr th:nth-last-of-type(2),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.adding-rows-disabled.removing-rows-disabled:has(.dg-action-col) table.table.datagrid-table thead tr th:nth-last-of-type(2),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.adding-rows-disabled.removing-rows-disabled:has(.dg-action-col) table.table.datagrid-table thead tr th:nth-last-of-type(2) {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td {
  padding-top: 10px;
  padding-bottom: 0px;
  vertical-align: top;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td :is(.form-control, .choices__item),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td :is(.form-control, .choices__item),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td :is(.form-control, .choices__item),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td :is(.form-control, .choices__item),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td :is(.form-control, .choices__item),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td :is(.form-control, .choices__item) {
  font-size: 14px;
  --field-height: 36px;
  --field-font-size: 14px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td .formio-component:is(.formio-component-select) .form-control.selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td .formio-component:is(.formio-component-select) .form-control.selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td .formio-component:is(.formio-component-select) .form-control.selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td .formio-component:is(.formio-component-select) .form-control.selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td .formio-component:is(.formio-component-select) .form-control.selection.dropdown,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td .formio-component:is(.formio-component-select) .form-control.selection.dropdown {
  padding-inline: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td .formio-component:is(.formio-component-select) .value,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td .formio-component:is(.formio-component-select) .value,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td .formio-component:is(.formio-component-select) .value,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td .formio-component:is(.formio-component-select) .value,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td .formio-component:is(.formio-component-select) .value,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td .formio-component:is(.formio-component-select) .value {
  font-size: 14px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.checkbox {
  vertical-align: top;
  padding-top: 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.checkbox .form-check.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.checkbox .form-check.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.checkbox .form-check.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.checkbox .form-check.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.checkbox .form-check.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.checkbox .form-check.checkbox {
  min-height: 36px;
  align-items: center;
  display: flex;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.checkbox:not(.datagrid-checkbox-hide-label) .form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.checkbox:not(.datagrid-checkbox-hide-label) .form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.checkbox:not(.datagrid-checkbox-hide-label) .form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.checkbox:not(.datagrid-checkbox-hide-label) .form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.checkbox:not(.datagrid-checkbox-hide-label) .form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.checkbox:not(.datagrid-checkbox-hide-label) .form-check-label span {
  line-height: 20px;
  font-size: 14px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.checkbox:not(.datagrid-checkbox-hide-label) .form-check-label span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.checkbox:not(.datagrid-checkbox-hide-label) .form-check-label span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.checkbox:not(.datagrid-checkbox-hide-label) .form-check-label span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.checkbox:not(.datagrid-checkbox-hide-label) .form-check-label span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.checkbox:not(.datagrid-checkbox-hide-label) .form-check-label span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.checkbox:not(.datagrid-checkbox-hide-label) .form-check-label span::before {
  top: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.checkbox.datagrid-checkbox-hide-label .form-check-label span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.checkbox.datagrid-checkbox-hide-label .form-check-label span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.checkbox.datagrid-checkbox-hide-label .form-check-label span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.checkbox.datagrid-checkbox-hide-label .form-check-label span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.checkbox.datagrid-checkbox-hide-label .form-check-label span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.checkbox.datagrid-checkbox-hide-label .form-check-label span::before {
  top: 0px;
  line-height: 18px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.radio .form-radio.radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.radio .form-radio.radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.radio .form-radio.radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.radio .form-radio.radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.radio .form-radio.radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.radio .form-radio.radio {
  --field-height: 36px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.radio .form-radio.radio .form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.radio .form-radio.radio .form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.radio .form-radio.radio .form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.radio .form-radio.radio .form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.radio .form-radio.radio .form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.radio .form-radio.radio .form-check-label span {
  font-size: 14px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.textarea textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.textarea textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.textarea textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.textarea textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.textarea textarea.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.textarea textarea.form-control {
  padding-block: 4px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.textarea .formio-component-textarea:not(.formio-disabled-component) .card.card-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.textarea .formio-component-textarea:not(.formio-disabled-component) .card.card-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.textarea .formio-component-textarea:not(.formio-disabled-component) .card.card-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.textarea .formio-component-textarea:not(.formio-disabled-component) .card.card-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.textarea .formio-component-textarea:not(.formio-disabled-component) .card.card-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.textarea .formio-component-textarea:not(.formio-disabled-component) .card.card-body {
  font-size: 14px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.textarea .formio-editor-read-only-content,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.textarea .formio-editor-read-only-content,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.textarea .formio-editor-read-only-content,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.textarea .formio-editor-read-only-content,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.textarea .formio-editor-read-only-content,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.textarea .formio-editor-read-only-content {
  --field-height: 36px;
  line-height: 1.5;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file {
  min-height: 36px;
  padding: 4px 3px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file.has-files ul.list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file.has-files ul.list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file.has-files ul.list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file.has-files ul.list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file.has-files ul.list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file.has-files ul.list-group {
  min-height: 24px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file.has-files ul.list-group :is(.col-md-2, .col-md-9, .col-md-1),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file.has-files ul.list-group :is(.col-md-2, .col-md-9, .col-md-1),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file.has-files ul.list-group :is(.col-md-2, .col-md-9, .col-md-1),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file.has-files ul.list-group :is(.col-md-2, .col-md-9, .col-md-1),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file.has-files ul.list-group :is(.col-md-2, .col-md-9, .col-md-1),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file.has-files ul.list-group :is(.col-md-2, .col-md-9, .col-md-1) {
  padding: 3px 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file.has-files a[ref="fileLink"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file.has-files a[ref="fileLink"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file.has-files a[ref="fileLink"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file.has-files a[ref="fileLink"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file.has-files a[ref="fileLink"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file.has-files a[ref="fileLink"] {
  --field-font-size: 14px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file.has-files.value-label-style ul.list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file.has-files.value-label-style ul.list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file.has-files.value-label-style ul.list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file.has-files.value-label-style ul.list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file.has-files.value-label-style ul.list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file.has-files.value-label-style ul.list-group {
  min-height: 28px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file .fileSelector {
  padding: 8px 12px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file:not(.has-files) .fileSelector i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file:not(.has-files) .fileSelector i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file:not(.has-files) .fileSelector i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file:not(.has-files) .fileSelector i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file:not(.has-files) .fileSelector i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file .formio-component-file:not(.has-files) .fileSelector i {
  bottom: 8px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file.value-label-style .formio-component-file,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file.value-label-style .formio-component-file,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file.value-label-style .formio-component-file,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file.value-label-style .formio-component-file,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file.value-label-style .formio-component-file,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.file.value-label-style .formio-component-file {
  padding: 4px 1px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.datetime,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.datetime,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.datetime,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.datetime,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.datetime,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.datetime {
  --field-height: 36px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.datetime .form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.datetime .form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.datetime .form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.datetime .form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.datetime .form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.datetime .form-control {
  min-height: 36px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.select .formio-choices .form-control.selection,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.select .formio-choices .form-control.selection,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.select .formio-choices .form-control.selection,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.select .formio-choices .form-control.selection,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.select .formio-choices .form-control.selection,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.select .formio-choices .form-control.selection {
  min-height: 36px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.button .formio-component-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.button .formio-component-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.button .formio-component-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.button .formio-component-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.button .formio-component-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.button .formio-component-button {
  margin-top: 2px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.button button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.button button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.button button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.button button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.button button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.text-small table.table.datagrid-table tbody tr td.button button.btn {
  --default-button-font-size: 14px;
  --xs-button-font-size: 14px;
  --sm-button-font-size: 14px;
  --md-button-font-size: 14px;
  --lg-button-font-size: 14px;
  --xl-button-font-size: 14px;
  --default-button-height: 36px;
  --xs-button-height: 36px;
  --sm-button-height: 36px;
  --md-button-height: 36px;
  --lg-button-height: 36px;
  --xl-button-height: 36px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.text-small table.table.datagrid-table.table-sm tbody tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.text-small table.table.datagrid-table.table-sm tbody tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.text-small table.table.datagrid-table.table-sm tbody tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.text-small table.table.datagrid-table.table-sm tbody tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.text-small table.table.datagrid-table.table-sm tbody tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.text-small table.table.datagrid-table.table-sm tbody tr td {
  padding-top: 6px;
  padding-bottom: 0px;
  vertical-align: top;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.formio-virtual-scroll tbody.scroll-padding td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.formio-virtual-scroll tbody.scroll-padding td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.formio-virtual-scroll tbody.scroll-padding td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.formio-virtual-scroll tbody.scroll-padding td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.formio-virtual-scroll tbody.scroll-padding td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.formio-virtual-scroll tbody.scroll-padding td {
  padding: 0 !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.formio-virtual-scroll .table > tbody + tbody,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.formio-virtual-scroll .table > tbody + tbody,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.formio-virtual-scroll .table > tbody + tbody,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.formio-virtual-scroll .table > tbody + tbody,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.formio-virtual-scroll .table > tbody + tbody,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.formio-virtual-scroll .table > tbody + tbody {
  border-top: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.formio-virtual-scroll .table-container,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.formio-virtual-scroll .table-container,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.formio-virtual-scroll .table-container,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.formio-virtual-scroll .table-container,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.formio-virtual-scroll .table-container,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.formio-virtual-scroll .table-container {
  overflow-x: hidden;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.modal-edit-row .dg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.modal-edit-row .dg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.modal-edit-row .dg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.modal-edit-row .dg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.modal-edit-row .dg-action-col,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.modal-edit-row .dg-action-col {
  width: 80px !important;
  min-width: 80px !important;
  font-size: 0px !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.modal-edit-row .dg-action-col button:is(.formio-button-edit-row, .formio-button-remove-row),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.modal-edit-row .dg-action-col button:is(.formio-button-edit-row, .formio-button-remove-row),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.modal-edit-row .dg-action-col button:is(.formio-button-edit-row, .formio-button-remove-row),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.modal-edit-row .dg-action-col button:is(.formio-button-edit-row, .formio-button-remove-row),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.modal-edit-row .dg-action-col button:is(.formio-button-edit-row, .formio-button-remove-row),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.modal-edit-row .dg-action-col button:is(.formio-button-edit-row, .formio-button-remove-row) {
  width: 50%;
  padding: 0 !important;
  margin: 0 !important;
  display: inline-flex !important;
  box-shadow: none;
  justify-content: center;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.modal-edit-row .dg-action-col button:is(.formio-button-edit-row, .formio-button-remove-row) span[ref="tooltip"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.modal-edit-row .dg-action-col button:is(.formio-button-edit-row, .formio-button-remove-row) span[ref="tooltip"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.modal-edit-row .dg-action-col button:is(.formio-button-edit-row, .formio-button-remove-row) span[ref="tooltip"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.modal-edit-row .dg-action-col button:is(.formio-button-edit-row, .formio-button-remove-row) span[ref="tooltip"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.modal-edit-row .dg-action-col button:is(.formio-button-edit-row, .formio-button-remove-row) span[ref="tooltip"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.modal-edit-row .dg-action-col button:is(.formio-button-edit-row, .formio-button-remove-row) span[ref="tooltip"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-datagrid.modal-edit-row .dg-action-col button:is(.formio-button-edit-row, .formio-button-remove-row) span[ref="tooltip"]::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-datagrid.modal-edit-row .dg-action-col button:is(.formio-button-edit-row, .formio-button-remove-row) span[ref="tooltip"]::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-datagrid.modal-edit-row .dg-action-col button:is(.formio-button-edit-row, .formio-button-remove-row) span[ref="tooltip"]::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid.modal-edit-row .dg-action-col button:is(.formio-button-edit-row, .formio-button-remove-row) span[ref="tooltip"]::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid.modal-edit-row .dg-action-col button:is(.formio-button-edit-row, .formio-button-remove-row) span[ref="tooltip"]::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid.modal-edit-row .dg-action-col button:is(.formio-button-edit-row, .formio-button-remove-row) span[ref="tooltip"]::after {
  display: none !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-tabs,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-tabs,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-tabs,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-tabs,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-tabs,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-tabs {
  scroll-margin: 68px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-tabs > .card > .card-body.tab-pane > .formio-component.formio-component-datagrid + .formio-component-datagrid tbody:only-child tr:first-child td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-tabs > .card > .card-body.tab-pane > .formio-component.formio-component-datagrid + .formio-component-datagrid tbody:only-child tr:first-child td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-tabs > .card > .card-body.tab-pane > .formio-component.formio-component-datagrid + .formio-component-datagrid tbody:only-child tr:first-child td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-tabs > .card > .card-body.tab-pane > .formio-component.formio-component-datagrid + .formio-component-datagrid tbody:only-child tr:first-child td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-tabs > .card > .card-body.tab-pane > .formio-component.formio-component-datagrid + .formio-component-datagrid tbody:only-child tr:first-child td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-tabs > .card > .card-body.tab-pane > .formio-component.formio-component-datagrid + .formio-component-datagrid tbody:only-child tr:first-child td {
  padding-top: 0.75rem;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-fieldset:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-fieldset:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-fieldset:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-fieldset:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-fieldset:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-fieldset:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) {
  --panel-bg-color: white;
  background-color: var(--panel-bg-color);
  border-radius: 6px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-fieldset:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) > fieldset,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-fieldset:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) > fieldset,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-fieldset:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) > fieldset,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-fieldset:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) > fieldset,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-fieldset:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) > fieldset,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-fieldset:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) > fieldset {
  background: transparent;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-fieldset.background-red,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-fieldset.background-red,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-fieldset.background-red,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-fieldset.background-red,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-fieldset.background-red,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-fieldset.background-red {
  --panel-bg-color: #FEE7E7;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-fieldset.background-blue,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-fieldset.background-blue,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-fieldset.background-blue,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-fieldset.background-blue,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-fieldset.background-blue,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-fieldset.background-blue {
  --panel-bg-color: #E4F2FB;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-fieldset.background-green,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-fieldset.background-green,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-fieldset.background-green,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-fieldset.background-green,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-fieldset.background-green,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-fieldset.background-green {
  --panel-bg-color: #DBF5EC;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-fieldset.background-orange,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-fieldset.background-orange,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-fieldset.background-orange,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-fieldset.background-orange,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-fieldset.background-orange,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-fieldset.background-orange {
  --panel-bg-color: #FFF1ED;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-fieldset.background-gray,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-fieldset.background-gray,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-fieldset.background-gray,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-fieldset.background-gray,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-fieldset.background-gray,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-fieldset.background-gray {
  --panel-bg-color: var(--color-gray-75);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-fieldset.background-primary,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-fieldset.background-primary,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-fieldset.background-primary,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-fieldset.background-primary,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-fieldset.background-primary,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-fieldset.background-primary {
  --panel-bg-color: rgba(70, 81, 170, 0.075);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-fieldset.background-white,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-fieldset.background-white,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-fieldset.background-white,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-fieldset.background-white,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-fieldset.background-white,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-fieldset.background-white {
  --panel-bg-color: #FFFFFF;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-fieldset fieldset,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-fieldset fieldset,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-fieldset fieldset,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-fieldset fieldset,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-fieldset fieldset,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-fieldset fieldset {
  padding: 24px 24px 24px;
  position: relative;
  border-radius: 6px;
  outline: solid 4px var(--color-gray-100);
  border: none;
  background-color: white;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-fieldset fieldset legend,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-fieldset fieldset legend,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-fieldset fieldset legend,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-fieldset fieldset legend,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-fieldset fieldset legend,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-fieldset fieldset legend {
  margin: 0;
  text-transform: initial;
  font-size: 18px;
  font-weight: 600;
  color: var(--color-gray-800);
  background: transparent;
  padding: 0;
  float: left;
  width: 100%;
  margin-bottom: 20px;
  line-height: 1.3;
}
@media screen and (max-width: 580px) {
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-fieldset fieldset,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-fieldset fieldset,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-fieldset fieldset,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-fieldset fieldset,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-fieldset fieldset,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-fieldset fieldset {
    padding: 20px 20px 20px;
  }
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-fieldset fieldset > .fieldset-body::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-fieldset fieldset > .fieldset-body::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-fieldset fieldset > .fieldset-body::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-fieldset fieldset > .fieldset-body::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-fieldset fieldset > .fieldset-body::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-fieldset fieldset > .fieldset-body::before {
  content: " ";
  display: block;
  height: 0px;
  clear: both;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-fieldset.no-border > fieldset,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-fieldset.no-border > fieldset,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-fieldset.no-border > fieldset,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-fieldset.no-border > fieldset,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-fieldset.no-border > fieldset,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-fieldset.no-border > fieldset {
  outline: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-fieldset.full-height > fieldset,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-fieldset.full-height > fieldset,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-fieldset.full-height > fieldset,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-fieldset.full-height > fieldset,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-fieldset.full-height > fieldset,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-fieldset.full-height > fieldset {
  height: 100%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file {
  border: dashed 2px rgba(27, 37, 60, 0.1);
  border-radius: 6px;
  padding: 24px;
  position: relative;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file label.col-form-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file label.col-form-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file label.col-form-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file label.col-form-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file label.col-form-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file label.col-form-label {
  font-size: 16px;
  color: var(--color-gray-800);
  margin-bottom: 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file label.col-form-label + div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file label.col-form-label + div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file label.col-form-label + div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file label.col-form-label + div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file label.col-form-label + div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file label.col-form-label + div {
  margin-bottom: 20px;
  position: relative;
  z-index: 9;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file label.col-form-label + div div:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file label.col-form-label + div div:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file label.col-form-label + div div:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file label.col-form-label + div div:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file label.col-form-label + div div:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file label.col-form-label + div div:last-child {
  margin-bottom: 36px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file label.col-form-label + div span i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file label.col-form-label + div span i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file label.col-form-label + div span i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file label.col-form-label + div span i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file label.col-form-label + div span i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file label.col-form-label + div span i {
  cursor: pointer;
  color: #ff003b;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file label.col-form-label .fa-question-circle,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file label.col-form-label .fa-question-circle,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file label.col-form-label .fa-question-circle,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file label.col-form-label .fa-question-circle,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file label.col-form-label .fa-question-circle,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file label.col-form-label .fa-question-circle {
  position: relative;
  z-index: 9;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file label.control-label--hidden ~ .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file label.control-label--hidden ~ .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file label.control-label--hidden ~ .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file label.control-label--hidden ~ .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file label.control-label--hidden ~ .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file label.control-label--hidden ~ .fileSelector {
  align-items: center;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file label.control-label--hidden.field-required::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file label.control-label--hidden.field-required::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file label.control-label--hidden.field-required::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file label.control-label--hidden.field-required::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file label.control-label--hidden.field-required::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file label.control-label--hidden.field-required::after {
  inset-inline-end: 31px;
  top: -12px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file ul.list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file ul.list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file ul.list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file ul.list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file ul.list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file ul.list-group {
  border: none;
  width: 100%;
  position: relative;
  z-index: 9;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file ul.list-group:nth-last-child(2),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file ul.list-group:nth-last-child(2),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file ul.list-group:nth-last-child(2),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file ul.list-group:nth-last-child(2),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file ul.list-group:nth-last-child(2),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file ul.list-group:nth-last-child(2) {
  margin-bottom: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file ul.list-group:nth-last-child(n+4) + .fileSelector i.fa-cloud-upload,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file ul.list-group:nth-last-child(n+4) + .fileSelector i.fa-cloud-upload,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file ul.list-group:nth-last-child(n+4) + .fileSelector i.fa-cloud-upload,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file ul.list-group:nth-last-child(n+4) + .fileSelector i.fa-cloud-upload,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file ul.list-group:nth-last-child(n+4) + .fileSelector i.fa-cloud-upload,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file ul.list-group:nth-last-child(n+4) + .fileSelector i.fa-cloud-upload {
  top: initial;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file ul.list-group li.list-group-item,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file ul.list-group li.list-group-item,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file ul.list-group li.list-group-item,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file ul.list-group li.list-group-item,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file ul.list-group li.list-group-item,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file ul.list-group li.list-group-item {
  border: none;
  box-shadow: inset 0 -2px 0 #E2E7E7;
  padding: 0;
  background-color: transparent;
  border-radius: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file ul.list-group li.list-group-item .row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file ul.list-group li.list-group-item .row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file ul.list-group li.list-group-item .row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file ul.list-group li.list-group-item .row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file ul.list-group li.list-group-item .row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file ul.list-group li.list-group-item .row {
  border: none;
  display: grid;
  grid-template-columns: auto auto 1fr;
  font-size: 12px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file ul.list-group li.list-group-item .row::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file ul.list-group li.list-group-item .row::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file ul.list-group li.list-group-item .row::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file ul.list-group li.list-group-item .row::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file ul.list-group li.list-group-item .row::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file ul.list-group li.list-group-item .row::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file ul.list-group li.list-group-item .row::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file ul.list-group li.list-group-item .row::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file ul.list-group li.list-group-item .row::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file ul.list-group li.list-group-item .row::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file ul.list-group li.list-group-item .row::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file ul.list-group li.list-group-item .row::after {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file ul.list-group li.list-group-item .row > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file ul.list-group li.list-group-item .row > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file ul.list-group li.list-group-item .row > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file ul.list-group li.list-group-item .row > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file ul.list-group li.list-group-item .row > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file ul.list-group li.list-group-item .row > div {
  padding: 10px 12px;
  text-transform: none;
  color: var(--color-primary-600);
  min-width: 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file ul.list-group li.list-group-item .row > div.col-md-1,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file ul.list-group li.list-group-item .row > div.col-md-1,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file ul.list-group li.list-group-item .row > div.col-md-1,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file ul.list-group li.list-group-item .row > div.col-md-1,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file ul.list-group li.list-group-item .row > div.col-md-1,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file ul.list-group li.list-group-item .row > div.col-md-1 {
  order: 3;
  width: auto;
  justify-content: flex-end;
  color: #ff003b;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file ul.list-group li.list-group-item .row > div:where(.col-md-9, .col-md-2),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file ul.list-group li.list-group-item .row > div:where(.col-md-9, .col-md-2),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file ul.list-group li.list-group-item .row > div:where(.col-md-9, .col-md-2),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file ul.list-group li.list-group-item .row > div:where(.col-md-9, .col-md-2),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file ul.list-group li.list-group-item .row > div:where(.col-md-9, .col-md-2),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file ul.list-group li.list-group-item .row > div:where(.col-md-9, .col-md-2) {
  white-space: nowrap;
  width: auto !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file ul.list-group li.list-group-item .row > div:where(.col-md-9, .col-md-2) a[href],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file ul.list-group li.list-group-item .row > div:where(.col-md-9, .col-md-2) a[href],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file ul.list-group li.list-group-item .row > div:where(.col-md-9, .col-md-2) a[href],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file ul.list-group li.list-group-item .row > div:where(.col-md-9, .col-md-2) a[href],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file ul.list-group li.list-group-item .row > div:where(.col-md-9, .col-md-2) a[href],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file ul.list-group li.list-group-item .row > div:where(.col-md-9, .col-md-2) a[href] {
  display: block;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file ul.list-group li.list-group-item .row > div.col-md-9 a,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file ul.list-group li.list-group-item .row > div.col-md-9 a,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file ul.list-group li.list-group-item .row > div.col-md-9 a,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file ul.list-group li.list-group-item .row > div.col-md-9 a,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file ul.list-group li.list-group-item .row > div.col-md-9 a,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file ul.list-group li.list-group-item .row > div.col-md-9 a {
  white-space: normal;
  line-break: anywhere;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file ul.list-group li.list-group-item .row > div:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file ul.list-group li.list-group-item .row > div:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file ul.list-group li.list-group-item .row > div:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file ul.list-group li.list-group-item .row > div:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file ul.list-group li.list-group-item .row > div:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file ul.list-group li.list-group-item .row > div:first-child {
  display: flex;
  align-items: center;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file ul.list-group li.list-group-item .row > div:first-child i.fa-remove,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file ul.list-group li.list-group-item .row > div:first-child i.fa-remove,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file ul.list-group li.list-group-item .row > div:first-child i.fa-remove,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file ul.list-group li.list-group-item .row > div:first-child i.fa-remove,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file ul.list-group li.list-group-item .row > div:first-child i.fa-remove,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file ul.list-group li.list-group-item .row > div:first-child i.fa-remove {
  font-family: var(--fontawesome-font);
  font-weight: 300;
  cursor: pointer;
  margin: 0;
  font-size: 16px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file ul.list-group li.list-group-item .row > div:first-child + .col-md-9,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file ul.list-group li.list-group-item .row > div:first-child + .col-md-9,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file ul.list-group li.list-group-item .row > div:first-child + .col-md-9,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file ul.list-group li.list-group-item .row > div:first-child + .col-md-9,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file ul.list-group li.list-group-item .row > div:first-child + .col-md-9,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file ul.list-group li.list-group-item .row > div:first-child + .col-md-9 {
  width: 60%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file ul.list-group li.list-group-item .row:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file ul.list-group li.list-group-item .row:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file ul.list-group li.list-group-item .row:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file ul.list-group li.list-group-item .row:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file ul.list-group li.list-group-item .row:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file ul.list-group li.list-group-item .row:hover {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file ul.list-group li.list-group-item.list-group-header,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file ul.list-group li.list-group-item.list-group-header,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file ul.list-group li.list-group-item.list-group-header,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file ul.list-group li.list-group-item.list-group-header,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file ul.list-group li.list-group-item.list-group-header,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file ul.list-group li.list-group-item.list-group-header {
  background: #EDEEF7;
  border: none;
  border-radius: 6px;
  padding: 0;
  display: none;
  z-index: 5;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file ul.list-group li.list-group-item.list-group-header .row > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file ul.list-group li.list-group-item.list-group-header .row > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file ul.list-group li.list-group-item.list-group-header .row > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file ul.list-group li.list-group-item.list-group-header .row > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file ul.list-group li.list-group-item.list-group-header .row > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file ul.list-group li.list-group-item.list-group-header .row > div {
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file ul.list-group li.list-group-item.list-group-header .row > div strong,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file ul.list-group li.list-group-item.list-group-header .row > div strong,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file ul.list-group li.list-group-item.list-group-header .row > div strong,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file ul.list-group li.list-group-item.list-group-header .row > div strong,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file ul.list-group li.list-group-item.list-group-header .row > div strong,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file ul.list-group li.list-group-item.list-group-header .row > div strong {
  font-weight: 600;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file ul.list-group li.list-group-item:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file ul.list-group li.list-group-item:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file ul.list-group li.list-group-item:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file ul.list-group li.list-group-item:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file ul.list-group li.list-group-item:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file ul.list-group li.list-group-item:hover {
  box-shadow: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file ul.list-group li.list-group-item:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file ul.list-group li.list-group-item:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file ul.list-group li.list-group-item:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file ul.list-group li.list-group-item:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file ul.list-group li.list-group-item:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file ul.list-group li.list-group-item:last-child {
  box-shadow: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file ul.list-group li.list-group-header > .row > div.radio label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file ul.list-group li.list-group-header > .row > div.radio label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file ul.list-group li.list-group-header > .row > div.radio label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file ul.list-group li.list-group-header > .row > div.radio label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file ul.list-group li.list-group-header > .row > div.radio label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file ul.list-group li.list-group-header > .row > div.radio label {
  padding-inline-start: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file ul.list-group tbody tr,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file ul.list-group tbody tr,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file ul.list-group tbody tr,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file ul.list-group tbody tr,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file ul.list-group tbody tr,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file ul.list-group tbody tr {
  background-color: transparent;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file ul.list-group tbody tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file ul.list-group tbody tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file ul.list-group tbody tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file ul.list-group tbody tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file ul.list-group tbody tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file ul.list-group tbody tr td {
  background-color: transparent;
  border: none;
  padding: 1.5rem 12px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file ul.list-group tbody tr td:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file ul.list-group tbody tr td:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file ul.list-group tbody tr td:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file ul.list-group tbody tr td:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file ul.list-group tbody tr td:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file ul.list-group tbody tr td:first-child {
  border-radius: 6px 0 0 6px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file ul.list-group tbody tr td:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file ul.list-group tbody tr td:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file ul.list-group tbody tr td:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file ul.list-group tbody tr td:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file ul.list-group tbody tr td:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file ul.list-group tbody tr td:last-child {
  border-radius: 0 6px 6px 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file ul.list-group tbody tr td a.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file ul.list-group tbody tr td a.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file ul.list-group tbody tr td a.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file ul.list-group tbody tr td a.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file ul.list-group tbody tr td a.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file ul.list-group tbody tr td a.btn {
  background-color: transparent;
  border: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file ul.list-group tbody tr td a.btn span.glyphicon-remove,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file ul.list-group tbody tr td a.btn span.glyphicon-remove,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file ul.list-group tbody tr td a.btn span.glyphicon-remove,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file ul.list-group tbody tr td a.btn span.glyphicon-remove,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file ul.list-group tbody tr td a.btn span.glyphicon-remove,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file ul.list-group tbody tr td a.btn span.glyphicon-remove {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  color: rgba(255, 0, 59, 0.5);
  font-size: 16px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file ul.list-group tbody tr td a.btn span.glyphicon-remove::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file ul.list-group tbody tr td a.btn span.glyphicon-remove::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file ul.list-group tbody tr td a.btn span.glyphicon-remove::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file ul.list-group tbody tr td a.btn span.glyphicon-remove::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file ul.list-group tbody tr td a.btn span.glyphicon-remove::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file ul.list-group tbody tr td a.btn span.glyphicon-remove::before {
  content: "\f00d";
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file ul.list-group tbody tr td a.btn span.glyphicon-remove:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file ul.list-group tbody tr td a.btn span.glyphicon-remove:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file ul.list-group tbody tr td a.btn span.glyphicon-remove:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file ul.list-group tbody tr td a.btn span.glyphicon-remove:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file ul.list-group tbody tr td a.btn span.glyphicon-remove:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file ul.list-group tbody tr td a.btn span.glyphicon-remove:hover {
  color: #ff003b;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file ul.list-group tbody tr:hover td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file ul.list-group tbody tr:hover td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file ul.list-group tbody tr:hover td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file ul.list-group tbody tr:hover td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file ul.list-group tbody tr:hover td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file ul.list-group tbody tr:hover td {
  background-color: var(--color-gray-75);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file .fileSelector {
  margin-bottom: 0;
  width: 100%;
  border: none;
  border-radius: 6px;
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  align-items: flex-end;
  height: 100%;
  padding: 23px;
  gap: 6px;
  font-size: 12px;
  transition-duration: 0.2s;
  line-height: 1.3;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file .fileSelector:not([hidden]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file .fileSelector:not([hidden]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file .fileSelector:not([hidden]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file .fileSelector:not([hidden]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file .fileSelector:not([hidden]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file .fileSelector:not([hidden]) {
  display: flex;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file .fileSelector i.fa-cloud-upload,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file .fileSelector i.fa-cloud-upload,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file .fileSelector i.fa-cloud-upload,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file .fileSelector i.fa-cloud-upload,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file .fileSelector i.fa-cloud-upload,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file .fileSelector i.fa-cloud-upload {
  display: block;
  position: absolute;
  inset-inline-end: 23px;
  font-size: 16px;
  margin: 0;
  top: calc(50% - 8px);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file .fileSelector i.fa-cloud-upload::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file .fileSelector i.fa-cloud-upload::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file .fileSelector i.fa-cloud-upload::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file .fileSelector i.fa-cloud-upload::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file .fileSelector i.fa-cloud-upload::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file .fileSelector i.fa-cloud-upload::before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  content: "\f56f";
  color: var(--color-gray-800);
  font-size: 16px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file .fileSelector i.fa-cloud-upload ~ *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file .fileSelector i.fa-cloud-upload ~ *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file .fileSelector i.fa-cloud-upload ~ *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file .fileSelector i.fa-cloud-upload ~ *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file .fileSelector i.fa-cloud-upload ~ *,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file .fileSelector i.fa-cloud-upload ~ * {
  color: var(--color-gray-800);
  font-size: 12px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file .fileSelector.fileDragOver,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file .fileSelector.fileDragOver,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file .fileSelector.fileDragOver,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file .fileSelector.fileDragOver,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file .fileSelector.fileDragOver,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file .fileSelector.fileDragOver {
  box-shadow: 0 0 0 2px rgba(70, 81, 170, 0.5);
  background: rgba(70, 81, 170, 0.05);
  z-index: 99;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file .fileSelector ~ .file,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file .fileSelector ~ .file,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file .fileSelector ~ .file,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file .fileSelector ~ .file,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file .fileSelector ~ .file,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file .fileSelector ~ .file {
  display: flex;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file .fileSelector ~ .file > .row:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file .fileSelector ~ .file > .row:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file .fileSelector ~ .file > .row:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file .fileSelector ~ .file > .row:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file .fileSelector ~ .file > .row:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file .fileSelector ~ .file > .row:first-child {
  min-width: 100px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file .fileSelector ~ .file > .row:first-child .fileName,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file .fileSelector ~ .file > .row:first-child .fileName,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file .fileSelector ~ .file > .row:first-child .fileName,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file .fileSelector ~ .file > .row:first-child .fileName,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file .fileSelector ~ .file > .row:first-child .fileName,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file .fileSelector ~ .file > .row:first-child .fileName {
  flex: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file .fileSelector ~ .file > .row:first-child .fileName i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file .fileSelector ~ .file > .row:first-child .fileName i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file .fileSelector ~ .file > .row:first-child .fileName i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file .fileSelector ~ .file > .row:first-child .fileName i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file .fileSelector ~ .file > .row:first-child .fileName i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file .fileSelector ~ .file > .row:first-child .fileName i {
  position: absolute;
  inset-inline-end: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file .fileSelector ~ .file > .row:first-child .fileSize,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file .fileSelector ~ .file > .row:first-child .fileSize,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file .fileSelector ~ .file > .row:first-child .fileSize,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file .fileSelector ~ .file > .row:first-child .fileSize,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file .fileSelector ~ .file > .row:first-child .fileSize,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file .fileSelector ~ .file > .row:first-child .fileSize {
  flex: 0;
  white-space: nowrap;
  margin-inline-end: 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file .fileSelector ~ .file > .row:first-child + .row .progress,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file .fileSelector ~ .file > .row:first-child + .row .progress,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file .fileSelector ~ .file > .row:first-child + .row .progress,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file .fileSelector ~ .file > .row:first-child + .row .progress,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file .fileSelector ~ .file > .row:first-child + .row .progress,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file .fileSelector ~ .file > .row:first-child + .row .progress {
  margin-inline-end: 36px;
  height: 4px;
  margin-top: 4px;
  margin-bottom: 0;
  background-color: rgba(27, 37, 60, 0.1);
  box-shadow: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file .fileSelector ~ .file > .row:first-child + .row .progress .progress-bar,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file .fileSelector ~ .file > .row:first-child + .row .progress .progress-bar,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file .fileSelector ~ .file > .row:first-child + .row .progress .progress-bar,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file .fileSelector ~ .file > .row:first-child + .row .progress .progress-bar,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file .fileSelector ~ .file > .row:first-child + .row .progress .progress-bar,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file .fileSelector ~ .file > .row:first-child + .row .progress .progress-bar {
  background-color: #29bc8f;
  border-radius: 5px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file .fileSelector ~ .file:nth-last-child(2),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file .fileSelector ~ .file:nth-last-child(2),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file .fileSelector ~ .file:nth-last-child(2),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file .fileSelector ~ .file:nth-last-child(2),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file .fileSelector ~ .file:nth-last-child(2),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file .fileSelector ~ .file:nth-last-child(2) {
  margin-bottom: 40px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file.formio-component-multiple .fileSelector i.fa-cloud-upload,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file.formio-component-multiple .fileSelector i.fa-cloud-upload,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file.formio-component-multiple .fileSelector i.fa-cloud-upload,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file.formio-component-multiple .fileSelector i.fa-cloud-upload,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file.formio-component-multiple .fileSelector i.fa-cloud-upload,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file.formio-component-multiple .fileSelector i.fa-cloud-upload {
  top: initial;
  bottom: 26px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file.has-files,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file.has-files,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file.has-files,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file.has-files,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file.has-files,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file.has-files {
  background-color: rgba(41, 188, 143, 0.03);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file.has-files .list-group-item > .row > [class^="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file.has-files .list-group-item > .row > [class^="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file.has-files .list-group-item > .row > [class^="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file.has-files .list-group-item > .row > [class^="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file.has-files .list-group-item > .row > [class^="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file.has-files .list-group-item > .row > [class^="col-"] {
  --custom-min-width: auto;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file.has-files label.control-label--hidden ~ .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file.has-files label.control-label--hidden ~ .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file.has-files label.control-label--hidden ~ .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file.has-files label.control-label--hidden ~ .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file.has-files label.control-label--hidden ~ .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file.has-files label.control-label--hidden ~ .fileSelector {
  align-items: flex-end;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file.has-message .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file.has-message .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file.has-message .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file.has-message .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file.has-message .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file.has-message .fileSelector {
  padding-bottom: 44px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file.has-message .formio-errors,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file.has-message .formio-errors,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file.has-message .formio-errors,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file.has-message .formio-errors,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file.has-message .formio-errors,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file.has-message .formio-errors {
  margin-top: 34px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file .has-preview,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file .has-preview,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file .has-preview,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file .has-preview,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file .has-preview,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file .has-preview {
  grid-template-columns: auto 1fr !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file .has-preview a,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file .has-preview a,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file .has-preview a,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file .has-preview a,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file .has-preview a,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file .has-preview a {
  display: grid !important;
  gap: 8px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file .has-preview img,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file .has-preview img,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file .has-preview img,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file .has-preview img,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file .has-preview img,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file .has-preview img {
  max-width: 200px;
  border: solid 1px hsl(0, 0%, 90%);
  border-radius: 6px;
  padding: 5px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file .has-preview .col-md-1,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file .has-preview .col-md-1,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file .has-preview .col-md-1,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file .has-preview .col-md-1,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file .has-preview .col-md-1,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file .has-preview .col-md-1 {
  grid-column-start: 2;
  grid-row: 1;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file .has-preview .col-md-9,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file .has-preview .col-md-9,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file .has-preview .col-md-9,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file .has-preview .col-md-9,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file .has-preview .col-md-9,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file .has-preview .col-md-9 {
  padding-bottom: 0px !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file .has-preview .col-md-2,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file .has-preview .col-md-2,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file .has-preview .col-md-2,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file .has-preview .col-md-2,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file .has-preview .col-md-2,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file .has-preview .col-md-2 {
  padding-top: 0 !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file a[ref="fileLink"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file a[ref="fileLink"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file a[ref="fileLink"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file a[ref="fileLink"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file a[ref="fileLink"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file a[ref="fileLink"] {
  color: var(--main-text-color);
  text-decoration: none;
  border-bottom: solid 1px;
  line-height: 1.5;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file a[ref="fileLink"]:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file a[ref="fileLink"]:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file a[ref="fileLink"]:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file a[ref="fileLink"]:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file a[ref="fileLink"]:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file a[ref="fileLink"]:hover {
  color: black;
  font-weight: 500;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file .browse,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file .browse,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file .browse,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file .browse,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file .browse,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file .browse {
  text-decoration: none;
  box-shadow: inset 0px -1px 0 var(--main-text-color);
  line-height: 1.3;
  position: relative;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file:is(.value-label-style, .formio-disabled-component),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) {
  border: none;
  background-color: transparent;
  padding: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) a[ref="fileLink"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) a[ref="fileLink"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file:is(.value-label-style, .formio-disabled-component) a[ref="fileLink"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) a[ref="fileLink"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) a[ref="fileLink"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) a[ref="fileLink"] {
  font-size: var(--field-font-size);
  position: relative;
  padding-inline-start: 24px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) a[ref="fileLink"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) a[ref="fileLink"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file:is(.value-label-style, .formio-disabled-component) a[ref="fileLink"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) a[ref="fileLink"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) a[ref="fileLink"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) a[ref="fileLink"]::before {
  content: "\f15b";
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component).hide-link-text a[ref="fileLink"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component).hide-link-text a[ref="fileLink"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file:is(.value-label-style, .formio-disabled-component).hide-link-text a[ref="fileLink"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component).hide-link-text a[ref="fileLink"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component).hide-link-text a[ref="fileLink"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component).hide-link-text a[ref="fileLink"] {
  overflow: hidden;
  width: 20px;
  white-space: nowrap !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) :where(.file-extension-png, .file-extension-jpg, .file-extension-jpeg, .file-extension-gif, .file-extension-bmp, .file-extension-pdf, .file-extension-doc, .file-extension-docx, .file-extension-txt) a[ref="fileLink"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) :where(.file-extension-png, .file-extension-jpg, .file-extension-jpeg, .file-extension-gif, .file-extension-bmp, .file-extension-pdf, .file-extension-doc, .file-extension-docx, .file-extension-txt) a[ref="fileLink"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file:is(.value-label-style, .formio-disabled-component) :where(.file-extension-png, .file-extension-jpg, .file-extension-jpeg, .file-extension-gif, .file-extension-bmp, .file-extension-pdf, .file-extension-doc, .file-extension-docx, .file-extension-txt) a[ref="fileLink"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) :where(.file-extension-png, .file-extension-jpg, .file-extension-jpeg, .file-extension-gif, .file-extension-bmp, .file-extension-pdf, .file-extension-doc, .file-extension-docx, .file-extension-txt) a[ref="fileLink"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) :where(.file-extension-png, .file-extension-jpg, .file-extension-jpeg, .file-extension-gif, .file-extension-bmp, .file-extension-pdf, .file-extension-doc, .file-extension-docx, .file-extension-txt) a[ref="fileLink"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) :where(.file-extension-png, .file-extension-jpg, .file-extension-jpeg, .file-extension-gif, .file-extension-bmp, .file-extension-pdf, .file-extension-doc, .file-extension-docx, .file-extension-txt) a[ref="fileLink"]::before {
  position: absolute;
  font-family: "Font Awesome 6 Pro";
  width: 20px;
  font-size: calc(var(--field-font-size) + 2px);
  inset-inline-start: 0;
  line-height: 18px;
  top: 2px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) :where(.file-extension-png, .file-extension-jpg, .file-extension-jpeg, .file-extension-gif, .file-extension-bmp) a[ref="fileLink"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) :where(.file-extension-png, .file-extension-jpg, .file-extension-jpeg, .file-extension-gif, .file-extension-bmp) a[ref="fileLink"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file:is(.value-label-style, .formio-disabled-component) :where(.file-extension-png, .file-extension-jpg, .file-extension-jpeg, .file-extension-gif, .file-extension-bmp) a[ref="fileLink"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) :where(.file-extension-png, .file-extension-jpg, .file-extension-jpeg, .file-extension-gif, .file-extension-bmp) a[ref="fileLink"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) :where(.file-extension-png, .file-extension-jpg, .file-extension-jpeg, .file-extension-gif, .file-extension-bmp) a[ref="fileLink"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) :where(.file-extension-png, .file-extension-jpg, .file-extension-jpeg, .file-extension-gif, .file-extension-bmp) a[ref="fileLink"]::before {
  content: "\f1c5";
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) .file-extension-pdf a[ref="fileLink"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) .file-extension-pdf a[ref="fileLink"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file:is(.value-label-style, .formio-disabled-component) .file-extension-pdf a[ref="fileLink"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) .file-extension-pdf a[ref="fileLink"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) .file-extension-pdf a[ref="fileLink"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) .file-extension-pdf a[ref="fileLink"]::before {
  content: "\f1c1";
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) :where(.file-extension-doc, .file-extension-docx) a[ref="fileLink"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) :where(.file-extension-doc, .file-extension-docx) a[ref="fileLink"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file:is(.value-label-style, .formio-disabled-component) :where(.file-extension-doc, .file-extension-docx) a[ref="fileLink"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) :where(.file-extension-doc, .file-extension-docx) a[ref="fileLink"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) :where(.file-extension-doc, .file-extension-docx) a[ref="fileLink"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) :where(.file-extension-doc, .file-extension-docx) a[ref="fileLink"]::before {
  content: "\f1c2";
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) .file-extension-txt a[ref="fileLink"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) .file-extension-txt a[ref="fileLink"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file:is(.value-label-style, .formio-disabled-component) .file-extension-txt a[ref="fileLink"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) .file-extension-txt a[ref="fileLink"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) .file-extension-txt a[ref="fileLink"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) .file-extension-txt a[ref="fileLink"]::before {
  content: "\f15c";
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) .list-group-item > .row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) .list-group-item > .row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file:is(.value-label-style, .formio-disabled-component) .list-group-item > .row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) .list-group-item > .row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) .list-group-item > .row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) .list-group-item > .row {
  margin-inline: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) .list-group-item > .row:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) .list-group-item > .row:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file:is(.value-label-style, .formio-disabled-component) .list-group-item > .row:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) .list-group-item > .row:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) .list-group-item > .row:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) .list-group-item > .row:hover {
  background-color: transparent !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) .col-md-9,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) .col-md-9,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file:is(.value-label-style, .formio-disabled-component) .col-md-9,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) .col-md-9,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) .col-md-9,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) .col-md-9 {
  padding: 0px !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) .col-md-2,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) .col-md-2,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file:is(.value-label-style, .formio-disabled-component) .col-md-2,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) .col-md-2,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) .col-md-2,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file:is(.value-label-style, .formio-disabled-component) .col-md-2 {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file.required.formio-component-label-hidden label.field-required.control-label--hidden,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file.required.formio-component-label-hidden label.field-required.control-label--hidden,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file.required.formio-component-label-hidden label.field-required.control-label--hidden,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file.required.formio-component-label-hidden label.field-required.control-label--hidden,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file.required.formio-component-label-hidden label.field-required.control-label--hidden,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file.required.formio-component-label-hidden label.field-required.control-label--hidden {
  width: calc(100% - 24px) !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file.value-label-style .list-group:has(.list-group-header:only-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file.value-label-style .list-group:has(.list-group-header:only-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file.value-label-style .list-group:has(.list-group-header:only-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file.value-label-style .list-group:has(.list-group-header:only-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file.value-label-style .list-group:has(.list-group-header:only-child),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file.value-label-style .list-group:has(.list-group-header:only-child) {
  margin-bottom: 40px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file.value-label-style .list-group:has(.list-group-header:only-child) + .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file.value-label-style .list-group:has(.list-group-header:only-child) + .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file.value-label-style .list-group:has(.list-group-header:only-child) + .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file.value-label-style .list-group:has(.list-group-header:only-child) + .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file.value-label-style .list-group:has(.list-group-header:only-child) + .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file.value-label-style .list-group:has(.list-group-header:only-child) + .fileSelector {
  height: 68px;
  padding-inline: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file.value-label-style.has-files.formio-component-multiple:not(.formio-disabled-component),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file.value-label-style.has-files.formio-component-multiple:not(.formio-disabled-component),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file.value-label-style.has-files.formio-component-multiple:not(.formio-disabled-component),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file.value-label-style.has-files.formio-component-multiple:not(.formio-disabled-component),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file.value-label-style.has-files.formio-component-multiple:not(.formio-disabled-component),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file.value-label-style.has-files.formio-component-multiple:not(.formio-disabled-component) {
  display: flow-root;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file.value-label-style.has-files.formio-component-multiple:not(.formio-disabled-component) .list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file.value-label-style.has-files.formio-component-multiple:not(.formio-disabled-component) .list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file.value-label-style.has-files.formio-component-multiple:not(.formio-disabled-component) .list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file.value-label-style.has-files.formio-component-multiple:not(.formio-disabled-component) .list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file.value-label-style.has-files.formio-component-multiple:not(.formio-disabled-component) .list-group,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file.value-label-style.has-files.formio-component-multiple:not(.formio-disabled-component) .list-group {
  margin-bottom: 30px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-file.value-label-style.has-files.formio-component-multiple:not(.formio-disabled-component) .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-file.value-label-style.has-files.formio-component-multiple:not(.formio-disabled-component) .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-file.value-label-style.has-files.formio-component-multiple:not(.formio-disabled-component) .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-file.value-label-style.has-files.formio-component-multiple:not(.formio-disabled-component) .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-file.value-label-style.has-files.formio-component-multiple:not(.formio-disabled-component) .fileSelector,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-file.value-label-style.has-files.formio-component-multiple:not(.formio-disabled-component) .fileSelector {
  padding-inline: 0;
  padding-bottom: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table {
  margin-inline-start: -12px;
  margin-inline-end: -12px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr > td input:where([type="text"], [type="email"], [type="phoneNumber"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table table.table > tbody > tr > td input:where([type="text"], [type="email"], [type="phoneNumber"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table table.table > tbody > tr > td input:where([type="text"], [type="email"], [type="phoneNumber"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table table.table > tbody > tr > td input:where([type="text"], [type="email"], [type="phoneNumber"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table table.table > tbody > tr > td input:where([type="text"], [type="email"], [type="phoneNumber"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table table.table > tbody > tr > td input:where([type="text"], [type="email"], [type="phoneNumber"]) {
  height: var(--field-height) !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio.radio-switch .input-group ng-form .radio-inline,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio.radio-switch .input-group ng-form .radio-inline,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio.radio-switch .input-group ng-form .radio-inline,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio.radio-switch .input-group ng-form .radio-inline,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio.radio-switch .input-group ng-form .radio-inline,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio.radio-switch .input-group ng-form .radio-inline {
  margin-top: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio.radio-switch label.control-label + .input-group .radio-inline,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio.radio-switch label.control-label + .input-group .radio-inline,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio.radio-switch label.control-label + .input-group .radio-inline,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio.radio-switch label.control-label + .input-group .radio-inline,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio.radio-switch label.control-label + .input-group .radio-inline,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio.radio-switch label.control-label + .input-group .radio-inline {
  margin-top: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio label.control-label + .input-group .radio-inline,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio label.control-label + .input-group .radio-inline,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio label.control-label + .input-group .radio-inline,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio label.control-label + .input-group .radio-inline,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio label.control-label + .input-group .radio-inline,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio label.control-label + .input-group .radio-inline {
  margin-top: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio label.control-label + .input-group .radio:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio label.control-label + .input-group .radio:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio label.control-label + .input-group .radio:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio label.control-label + .input-group .radio:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio label.control-label + .input-group .radio:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio label.control-label + .input-group .radio:first-child {
  margin-top: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio .input-group:first-child .radio-inline,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio .input-group:first-child .radio-inline,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio .input-group:first-child .radio-inline,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio .input-group:first-child .radio-inline,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio .input-group:first-child .radio-inline,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio .input-group:first-child .radio-inline {
  margin-top: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio.remove-space-top .input-group .radio-inline,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio.remove-space-top .input-group .radio-inline,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio.remove-space-top .input-group .radio-inline,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio.remove-space-top .input-group .radio-inline,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio.remove-space-top .input-group .radio-inline,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio.remove-space-top .input-group .radio-inline {
  margin-top: 12px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio.remove-space-top .input-group .radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio.remove-space-top .input-group .radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio.remove-space-top .input-group .radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio.remove-space-top .input-group .radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio.remove-space-top .input-group .radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio.remove-space-top .input-group .radio {
  margin-top: 12px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio.remove-space-top .input-group .radio:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio.remove-space-top .input-group .radio:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio.remove-space-top .input-group .radio:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio.remove-space-top .input-group .radio:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio.remove-space-top .input-group .radio:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table table.table > tbody > tr > td .form-group.form-field-type-radio.remove-space-top .input-group .radio:first-child {
  margin-top: 13px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr > td .form-group:where(.form-field-type-textfield, .form-field-type-number, .form-field-type-email, .form-field-type-phoneNumber).field-plus-button input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table table.table > tbody > tr > td .form-group:where(.form-field-type-textfield, .form-field-type-number, .form-field-type-email, .form-field-type-phoneNumber).field-plus-button input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table table.table > tbody > tr > td .form-group:where(.form-field-type-textfield, .form-field-type-number, .form-field-type-email, .form-field-type-phoneNumber).field-plus-button input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table table.table > tbody > tr > td .form-group:where(.form-field-type-textfield, .form-field-type-number, .form-field-type-email, .form-field-type-phoneNumber).field-plus-button input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table table.table > tbody > tr > td .form-group:where(.form-field-type-textfield, .form-field-type-number, .form-field-type-email, .form-field-type-phoneNumber).field-plus-button input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table table.table > tbody > tr > td .form-group:where(.form-field-type-textfield, .form-field-type-number, .form-field-type-email, .form-field-type-phoneNumber).field-plus-button input.form-control {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr > td fieldset legend,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table table.table > tbody > tr > td fieldset legend,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table table.table > tbody > tr > td fieldset legend,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table table.table > tbody > tr > td fieldset legend,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table table.table > tbody > tr > td fieldset legend,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table table.table > tbody > tr > td fieldset legend {
  line-height: 34px;
  display: block;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr > td .formio-component-textfield,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table table.table > tbody > tr > td .formio-component-textfield,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table table.table > tbody > tr > td .formio-component-textfield,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table table.table > tbody > tr > td .formio-component-textfield,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table table.table > tbody > tr > td .formio-component-textfield,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table table.table > tbody > tr > td .formio-component-textfield {
  display: flex;
  flex-direction: column;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr.row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table table.table > tbody > tr.row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table table.table > tbody > tr.row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table table.table > tbody > tr.row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table table.table > tbody > tr.row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table table.table > tbody > tr.row {
  display: table-row;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr.row::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table table.table > tbody > tr.row::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table table.table > tbody > tr.row::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table table.table > tbody > tr.row::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table table.table > tbody > tr.row::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table table.table > tbody > tr.row::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr.row::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table table.table > tbody > tr.row::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table table.table > tbody > tr.row::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table table.table > tbody > tr.row::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table table.table > tbody > tr.row::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table table.table > tbody > tr.row::before {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr.row td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table table.table > tbody > tr.row td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table table.table > tbody > tr.row td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table table.table > tbody > tr.row td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table table.table > tbody > tr.row td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table table.table > tbody > tr.row td {
  display: table-cell;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr.row td:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table table.table > tbody > tr.row td:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table table.table > tbody > tr.row td:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table table.table > tbody > tr.row td:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table table.table > tbody > tr.row td:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table table.table > tbody > tr.row td:first-child {
  padding-inline-start: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr.row td:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table table.table > tbody > tr.row td:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table table.table > tbody > tr.row td:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table table.table > tbody > tr.row td:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table table.table > tbody > tr.row td:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table table.table > tbody > tr.row td:last-child {
  padding-inline-end: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr:first-child > td > .form-group:first-child:not(.ng-hide),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table table.table > tbody > tr:first-child > td > .form-group:first-child:not(.ng-hide),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table table.table > tbody > tr:first-child > td > .form-group:first-child:not(.ng-hide),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table table.table > tbody > tr:first-child > td > .form-group:first-child:not(.ng-hide),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table table.table > tbody > tr:first-child > td > .form-group:first-child:not(.ng-hide),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table table.table > tbody > tr:first-child > td > .form-group:first-child:not(.ng-hide) {
  margin-top: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group:first-child {
  margin-top: 3rem;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group.top-label-space:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group.top-label-space:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group.top-label-space:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group.top-label-space:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group.top-label-space:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group.top-label-space:first-child {
  margin-top: calc(3rem + 3px + 29px) !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group.formio-hidden + .formio-component:not(.formio-hidden),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group.formio-hidden + .formio-component:not(.formio-hidden),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group.formio-hidden + .formio-component:not(.formio-hidden),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group.formio-hidden + .formio-component:not(.formio-hidden),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group.formio-hidden + .formio-component:not(.formio-hidden),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group.formio-hidden + .formio-component:not(.formio-hidden) {
  margin-top: 3rem !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group.form-field-type-checkbox .checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group.form-field-type-checkbox .checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group.form-field-type-checkbox .checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group.form-field-type-checkbox .checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group.form-field-type-checkbox .checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group.form-field-type-checkbox .checkbox {
  margin-top: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group.form-field-type-checkbox.top-label-space .checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group.form-field-type-checkbox.top-label-space .checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group.form-field-type-checkbox.top-label-space .checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group.form-field-type-checkbox.top-label-space .checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group.form-field-type-checkbox.top-label-space .checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group.form-field-type-checkbox.top-label-space .checkbox {
  margin-top: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group.form-field-type-radio .input-group:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group.form-field-type-radio .input-group:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group.form-field-type-radio .input-group:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group.form-field-type-radio .input-group:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group.form-field-type-radio .input-group:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group.form-field-type-radio .input-group:first-child {
  margin-top: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group.form-field-type-radio.top-label-space .input-group:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group.form-field-type-radio.top-label-space .input-group:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group.form-field-type-radio.top-label-space .input-group:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group.form-field-type-radio.top-label-space .input-group:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group.form-field-type-radio.top-label-space .input-group:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table table.table > tbody > tr:first-child ~ tr > td > .form-group.form-field-type-radio.top-label-space .input-group:first-child {
  padding-top: 34px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table table.table > tbody > tr:last-child > td .formio-component.formio-component-panel:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table table.table > tbody > tr:last-child > td .formio-component.formio-component-panel:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table table.table > tbody > tr:last-child > td .formio-component.formio-component-panel:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table table.table > tbody > tr:last-child > td .formio-component.formio-component-panel:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table table.table > tbody > tr:last-child > td .formio-component.formio-component-panel:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table table.table > tbody > tr:last-child > td .formio-component.formio-component-panel:last-child {
  margin-bottom: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table table.table.table-bordered > tbody > tr > td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table table.table.table-bordered > tbody > tr > td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table table.table.table-bordered > tbody > tr > td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table table.table.table-bordered > tbody > tr > td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table table.table.table-bordered > tbody > tr > td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table table.table.table-bordered > tbody > tr > td {
  border: solid 1px var(--color-gray-200) !important;
  padding: 16px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table table.table.table-bordered > tbody > tr ~ tr > td .formio-component:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table table.table.table-bordered > tbody > tr ~ tr > td .formio-component:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table table.table.table-bordered > tbody > tr ~ tr > td .formio-component:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table table.table.table-bordered > tbody > tr ~ tr > td .formio-component:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table table.table.table-bordered > tbody > tr ~ tr > td .formio-component:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table table.table.table-bordered > tbody > tr ~ tr > td .formio-component:first-child {
  margin-top: 0px !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table.vertical-align-center table.table > tbody > tr > td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table.vertical-align-center table.table > tbody > tr > td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table.vertical-align-center table.table > tbody > tr > td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table.vertical-align-center table.table > tbody > tr > td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table.vertical-align-center table.table > tbody > tr > td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table.vertical-align-center table.table > tbody > tr > td {
  vertical-align: middle;
}
@media screen and (max-width: 768px) {
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table.table-responsive,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table.table-responsive,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table.table-responsive,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table.table-responsive,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table.table-responsive,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table.table-responsive {
    border: none;
  }
}
@media screen and (max-width: 580px) {
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table.table-responsive table.table tbody tr td,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table.table-responsive table.table tbody tr td,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table.table-responsive table.table tbody tr td,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table.table-responsive table.table tbody tr td,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table.table-responsive table.table tbody tr td,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table.table-responsive table.table tbody tr td {
    display: table;
    width: 100%;
  }
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table.table-responsive table.table tbody tr td + td,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table.table-responsive table.table tbody tr td + td,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table.table-responsive table.table tbody tr td + td,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table.table-responsive table.table tbody tr td + td,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table.table-responsive table.table tbody tr td + td,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table.table-responsive table.table tbody tr td + td {
    margin-top: 3rem;
  }
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table.table-responsive table.table tbody tr td + td > .formio-component:first-child,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table.table-responsive table.table tbody tr td + td > .formio-component:first-child,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table.table-responsive table.table tbody tr td + td > .formio-component:first-child,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table.table-responsive table.table tbody tr td + td > .formio-component:first-child,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table.table-responsive table.table tbody tr td + td > .formio-component:first-child,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table.table-responsive table.table tbody tr td + td > .formio-component:first-child {
    margin-top: 0;
  }
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table.table-responsive table.table tbody tr td .formio-component-textfield.field-plus-button input.form-control,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table.table-responsive table.table tbody tr td .formio-component-textfield.field-plus-button input.form-control,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table.table-responsive table.table tbody tr td .formio-component-textfield.field-plus-button input.form-control,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table.table-responsive table.table tbody tr td .formio-component-textfield.field-plus-button input.form-control,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table.table-responsive table.table tbody tr td .formio-component-textfield.field-plus-button input.form-control,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table.table-responsive table.table tbody tr td .formio-component-textfield.field-plus-button input.form-control {
    border-radius: 6px !important;
  }
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table.table-responsive table.table tbody tr td .formio-component-button.field-plus-button button.btn,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table.table-responsive table.table tbody tr td .formio-component-button.field-plus-button button.btn,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table.table-responsive table.table tbody tr td .formio-component-button.field-plus-button button.btn,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table.table-responsive table.table tbody tr td .formio-component-button.field-plus-button button.btn,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table.table-responsive table.table tbody tr td .formio-component-button.field-plus-button button.btn,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table.table-responsive table.table tbody tr td .formio-component-button.field-plus-button button.btn {
    border-radius: 6px !important;
    margin-inline-start: 0;
  }
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table.table-responsive table.table tbody tr td:empty,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table.table-responsive table.table tbody tr td:empty,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table.table-responsive table.table tbody tr td:empty,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table.table-responsive table.table tbody tr td:empty,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table.table-responsive table.table tbody tr td:empty,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table.table-responsive table.table tbody tr td:empty {
    display: none;
  }
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table.table-responsive table.table tbody tr + tr td:first-child,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table.table-responsive table.table tbody tr + tr td:first-child,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table.table-responsive table.table tbody tr + tr td:first-child,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table.table-responsive table.table tbody tr + tr td:first-child,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table.table-responsive table.table tbody tr + tr td:first-child,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table.table-responsive table.table tbody tr + tr td:first-child {
    margin-top: 3rem;
  }
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-table:has(.table-bordered),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-table:has(.table-bordered),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-table:has(.table-bordered),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-table:has(.table-bordered),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-table:has(.table-bordered),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-table:has(.table-bordered) {
  margin-inline: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns {
  clear: both;
  margin-inline-start: -12px;
  margin-inline-end: -12px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns:not(.formio-hidden),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns:not(.formio-hidden),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns:not(.formio-hidden),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns:not(.formio-hidden),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns:not(.formio-hidden),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns:not(.formio-hidden) {
  display: flex;
  flex-wrap: wrap;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns.datagrid-custom-footer,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns.datagrid-custom-footer,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns.datagrid-custom-footer,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns.datagrid-custom-footer,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns.datagrid-custom-footer,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns.datagrid-custom-footer {
  margin-top: -26px !important;
  margin-bottom: 79px;
  padding-inline-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 50px;
  border: solid 1px var(--color-gray-300);
  border-radius: 6px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns.datagrid-custom-footer > div[class^="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns.datagrid-custom-footer > div[class^="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns.datagrid-custom-footer > div[class^="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns.datagrid-custom-footer > div[class^="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns.datagrid-custom-footer > div[class^="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns.datagrid-custom-footer > div[class^="col-"] {
  padding: 0;
  flex: 1;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns.datagrid-custom-footer > div[class^="col-"] :where(.formio-component-textfield, .formio-component-number) label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns.datagrid-custom-footer > div[class^="col-"] :where(.formio-component-textfield, .formio-component-number) label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns.datagrid-custom-footer > div[class^="col-"] :where(.formio-component-textfield, .formio-component-number) label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns.datagrid-custom-footer > div[class^="col-"] :where(.formio-component-textfield, .formio-component-number) label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns.datagrid-custom-footer > div[class^="col-"] :where(.formio-component-textfield, .formio-component-number) label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns.datagrid-custom-footer > div[class^="col-"] :where(.formio-component-textfield, .formio-component-number) label {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns.datagrid-custom-footer > div[class^="col-"] :where(.formio-component-textfield, .formio-component-number) input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns.datagrid-custom-footer > div[class^="col-"] :where(.formio-component-textfield, .formio-component-number) input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns.datagrid-custom-footer > div[class^="col-"] :where(.formio-component-textfield, .formio-component-number) input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns.datagrid-custom-footer > div[class^="col-"] :where(.formio-component-textfield, .formio-component-number) input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns.datagrid-custom-footer > div[class^="col-"] :where(.formio-component-textfield, .formio-component-number) input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns.datagrid-custom-footer > div[class^="col-"] :where(.formio-component-textfield, .formio-component-number) input {
  border: none;
  font-weight: 600;
  background-color: transparent;
  background-image: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns.datagrid-custom-footer > div[class^="col-"] :where(.formio-component-textfield, .formio-component-number) input[disabled],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns.datagrid-custom-footer > div[class^="col-"] :where(.formio-component-textfield, .formio-component-number) input[disabled],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns.datagrid-custom-footer > div[class^="col-"] :where(.formio-component-textfield, .formio-component-number) input[disabled],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns.datagrid-custom-footer > div[class^="col-"] :where(.formio-component-textfield, .formio-component-number) input[disabled],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns.datagrid-custom-footer > div[class^="col-"] :where(.formio-component-textfield, .formio-component-number) input[disabled],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns.datagrid-custom-footer > div[class^="col-"] :where(.formio-component-textfield, .formio-component-number) input[disabled] {
  background-color: transparent !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns.datagrid-custom-footer > div[class^="col-"]:not(:first-child) input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns.datagrid-custom-footer > div[class^="col-"]:not(:first-child) input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns.datagrid-custom-footer > div[class^="col-"]:not(:first-child) input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns.datagrid-custom-footer > div[class^="col-"]:not(:first-child) input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns.datagrid-custom-footer > div[class^="col-"]:not(:first-child) input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns.datagrid-custom-footer > div[class^="col-"]:not(:first-child) input {
  padding-inline-start: 28px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns.datagrid-custom-footer.datagrid-add-remove-disabled,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns.datagrid-custom-footer.datagrid-add-remove-disabled,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns.datagrid-custom-footer.datagrid-add-remove-disabled,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns.datagrid-custom-footer.datagrid-add-remove-disabled,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns.datagrid-custom-footer.datagrid-add-remove-disabled,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns.datagrid-custom-footer.datagrid-add-remove-disabled {
  padding-inline-end: 0;
  margin-top: 6px !important;
  margin-bottom: 0;
  margin-inline-end: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns.datagrid-custom-footer.datagrid-condensed,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns.datagrid-custom-footer.datagrid-condensed,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns.datagrid-custom-footer.datagrid-condensed,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns.datagrid-custom-footer.datagrid-condensed,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns.datagrid-custom-footer.datagrid-condensed,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns.datagrid-custom-footer.datagrid-condensed {
  margin-top: -32px !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns.datagrid-custom-footer.datagrid-condensed > div[class^="col-"]:not(:first-child) input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns.datagrid-custom-footer.datagrid-condensed > div[class^="col-"]:not(:first-child) input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns.datagrid-custom-footer.datagrid-condensed > div[class^="col-"]:not(:first-child) input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns.datagrid-custom-footer.datagrid-condensed > div[class^="col-"]:not(:first-child) input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns.datagrid-custom-footer.datagrid-condensed > div[class^="col-"]:not(:first-child) input,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns.datagrid-custom-footer.datagrid-condensed > div[class^="col-"]:not(:first-child) input {
  padding-inline-start: 14px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns > div[class^="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns > div[class^="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns > div[class^="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns > div[class^="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns > div[class^="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns > div[class^="col-"] {
  padding-inline-start: 12px;
  padding-inline-end: 12px;
  min-height: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns > div[class^="col-"].col-md-12 ~ [class^="col-"] .formio-component:first-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns > div[class^="col-"].col-md-12 ~ [class^="col-"] .formio-component:first-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns > div[class^="col-"].col-md-12 ~ [class^="col-"] .formio-component:first-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns > div[class^="col-"].col-md-12 ~ [class^="col-"] .formio-component:first-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns > div[class^="col-"].col-md-12 ~ [class^="col-"] .formio-component:first-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns > div[class^="col-"].col-md-12 ~ [class^="col-"] .formio-component:first-of-type {
  margin-top: 30px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns > div[class^="col-"].col-md-3:first-of-type + :where(.col-md-9, .col-md-3 + .col-md-3 + .col-md-3) ~ [class^="col-"] > .formio-component:not(.formio-hidden, .hide, .formio-component-hidden):first-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns > div[class^="col-"].col-md-3:first-of-type + :where(.col-md-9, .col-md-3 + .col-md-3 + .col-md-3) ~ [class^="col-"] > .formio-component:not(.formio-hidden, .hide, .formio-component-hidden):first-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns > div[class^="col-"].col-md-3:first-of-type + :where(.col-md-9, .col-md-3 + .col-md-3 + .col-md-3) ~ [class^="col-"] > .formio-component:not(.formio-hidden, .hide, .formio-component-hidden):first-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns > div[class^="col-"].col-md-3:first-of-type + :where(.col-md-9, .col-md-3 + .col-md-3 + .col-md-3) ~ [class^="col-"] > .formio-component:not(.formio-hidden, .hide, .formio-component-hidden):first-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns > div[class^="col-"].col-md-3:first-of-type + :where(.col-md-9, .col-md-3 + .col-md-3 + .col-md-3) ~ [class^="col-"] > .formio-component:not(.formio-hidden, .hide, .formio-component-hidden):first-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns > div[class^="col-"].col-md-3:first-of-type + :where(.col-md-9, .col-md-3 + .col-md-3 + .col-md-3) ~ [class^="col-"] > .formio-component:not(.formio-hidden, .hide, .formio-component-hidden):first-of-type {
  margin-top: 3rem;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns > div[class^="col-"].col-md-3:first-of-type + :where(.col-md-9, .col-md-3 + .col-md-3 + .col-md-3) ~ [class^="col-"] > .formio-component:not(.formio-hidden, .hide, .formio-component-hidden).formio-component-columns > div[class^="col-"] > .formio-component:not(.formio-hidden, .hide, .formio-component-hidden):first-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns > div[class^="col-"].col-md-3:first-of-type + :where(.col-md-9, .col-md-3 + .col-md-3 + .col-md-3) ~ [class^="col-"] > .formio-component:not(.formio-hidden, .hide, .formio-component-hidden).formio-component-columns > div[class^="col-"] > .formio-component:not(.formio-hidden, .hide, .formio-component-hidden):first-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns > div[class^="col-"].col-md-3:first-of-type + :where(.col-md-9, .col-md-3 + .col-md-3 + .col-md-3) ~ [class^="col-"] > .formio-component:not(.formio-hidden, .hide, .formio-component-hidden).formio-component-columns > div[class^="col-"] > .formio-component:not(.formio-hidden, .hide, .formio-component-hidden):first-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns > div[class^="col-"].col-md-3:first-of-type + :where(.col-md-9, .col-md-3 + .col-md-3 + .col-md-3) ~ [class^="col-"] > .formio-component:not(.formio-hidden, .hide, .formio-component-hidden).formio-component-columns > div[class^="col-"] > .formio-component:not(.formio-hidden, .hide, .formio-component-hidden):first-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns > div[class^="col-"].col-md-3:first-of-type + :where(.col-md-9, .col-md-3 + .col-md-3 + .col-md-3) ~ [class^="col-"] > .formio-component:not(.formio-hidden, .hide, .formio-component-hidden).formio-component-columns > div[class^="col-"] > .formio-component:not(.formio-hidden, .hide, .formio-component-hidden):first-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns > div[class^="col-"].col-md-3:first-of-type + :where(.col-md-9, .col-md-3 + .col-md-3 + .col-md-3) ~ [class^="col-"] > .formio-component:not(.formio-hidden, .hide, .formio-component-hidden).formio-component-columns > div[class^="col-"] > .formio-component:not(.formio-hidden, .hide, .formio-component-hidden):first-of-type {
  margin-top: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns > div[class^="col-"].col-md-4:first-of-type + .col-md-8 ~ [class^="col-"] .formio-component:first-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns > div[class^="col-"].col-md-4:first-of-type + .col-md-8 ~ [class^="col-"] .formio-component:first-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns > div[class^="col-"].col-md-4:first-of-type + .col-md-8 ~ [class^="col-"] .formio-component:first-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns > div[class^="col-"].col-md-4:first-of-type + .col-md-8 ~ [class^="col-"] .formio-component:first-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns > div[class^="col-"].col-md-4:first-of-type + .col-md-8 ~ [class^="col-"] .formio-component:first-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns > div[class^="col-"].col-md-4:first-of-type + .col-md-8 ~ [class^="col-"] .formio-component:first-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns > div[class^="col-"].col-md-4:first-of-type + .col-md-4 + .col-md-4 ~ [class^="col-"] .formio-component:first-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns > div[class^="col-"].col-md-4:first-of-type + .col-md-4 + .col-md-4 ~ [class^="col-"] .formio-component:first-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns > div[class^="col-"].col-md-4:first-of-type + .col-md-4 + .col-md-4 ~ [class^="col-"] .formio-component:first-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns > div[class^="col-"].col-md-4:first-of-type + .col-md-4 + .col-md-4 ~ [class^="col-"] .formio-component:first-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns > div[class^="col-"].col-md-4:first-of-type + .col-md-4 + .col-md-4 ~ [class^="col-"] .formio-component:first-of-type,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns > div[class^="col-"].col-md-4:first-of-type + .col-md-4 + .col-md-4 ~ [class^="col-"] .formio-component:first-of-type {
  margin-top: 30px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns > div[class^="col-"] > .formio-component-panel:has(> .card > [aria-expanded="false"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns > div[class^="col-"] > .formio-component-panel:has(> .card > [aria-expanded="false"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns > div[class^="col-"] > .formio-component-panel:has(> .card > [aria-expanded="false"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns > div[class^="col-"] > .formio-component-panel:has(> .card > [aria-expanded="false"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns > div[class^="col-"] > .formio-component-panel:has(> .card > [aria-expanded="false"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns > div[class^="col-"] > .formio-component-panel:has(> .card > [aria-expanded="false"]) {
  height: initial;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns > div[class^="col-"] > .formio-component-panel + .formio-component-panel,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns > div[class^="col-"] > .formio-component-panel + .formio-component-panel,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns > div[class^="col-"] > .formio-component-panel + .formio-component-panel,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns > div[class^="col-"] > .formio-component-panel + .formio-component-panel,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns > div[class^="col-"] > .formio-component-panel + .formio-component-panel,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns > div[class^="col-"] > .formio-component-panel + .formio-component-panel {
  --custom-panel-top-margin: -4px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns > div[class^="col-"].cols-grid-end ~ [class^="col-"] > .formio-component:not(.margin-top-zero, .top-space, .top-space-2x, .top-space-3x, .top-label-space):is(:first-of-type, .is-first),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns > div[class^="col-"].cols-grid-end ~ [class^="col-"] > .formio-component:not(.margin-top-zero, .top-space, .top-space-2x, .top-space-3x, .top-label-space):is(:first-of-type, .is-first),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns > div[class^="col-"].cols-grid-end ~ [class^="col-"] > .formio-component:not(.margin-top-zero, .top-space, .top-space-2x, .top-space-3x, .top-label-space):is(:first-of-type, .is-first),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns > div[class^="col-"].cols-grid-end ~ [class^="col-"] > .formio-component:not(.margin-top-zero, .top-space, .top-space-2x, .top-space-3x, .top-label-space):is(:first-of-type, .is-first),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns > div[class^="col-"].cols-grid-end ~ [class^="col-"] > .formio-component:not(.margin-top-zero, .top-space, .top-space-2x, .top-space-3x, .top-label-space):is(:first-of-type, .is-first),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns > div[class^="col-"].cols-grid-end ~ [class^="col-"] > .formio-component:not(.margin-top-zero, .top-space, .top-space-2x, .top-space-3x, .top-label-space):is(:first-of-type, .is-first) {
  margin-top: 30px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns.formio-component-totalPrice,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns.formio-component-totalPrice,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns.formio-component-totalPrice,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns.formio-component-totalPrice,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns.formio-component-totalPrice,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns.formio-component-totalPrice {
  display: flex;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns.formio-component-totalPrice .formio-component-htmlelement.panel-title h4,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns.formio-component-totalPrice .formio-component-htmlelement.panel-title h4,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns.formio-component-totalPrice .formio-component-htmlelement.panel-title h4,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns.formio-component-totalPrice .formio-component-htmlelement.panel-title h4,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns.formio-component-totalPrice .formio-component-htmlelement.panel-title h4,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns.formio-component-totalPrice .formio-component-htmlelement.panel-title h4 {
  font-size: 16px;
  font-weight: 600;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns.formio-component-totalPrice .formio-component-htmlelement[class*="total"] p[ref="html"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns.formio-component-totalPrice .formio-component-htmlelement[class*="total"] p[ref="html"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns.formio-component-totalPrice .formio-component-htmlelement[class*="total"] p[ref="html"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns.formio-component-totalPrice .formio-component-htmlelement[class*="total"] p[ref="html"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns.formio-component-totalPrice .formio-component-htmlelement[class*="total"] p[ref="html"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns.formio-component-totalPrice .formio-component-htmlelement[class*="total"] p[ref="html"] {
  display: flex;
  justify-content: end;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns.formio-component-totalPrice .formio-component-content > [ref="html"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns.formio-component-totalPrice .formio-component-content > [ref="html"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns.formio-component-totalPrice .formio-component-content > [ref="html"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns.formio-component-totalPrice .formio-component-content > [ref="html"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns.formio-component-totalPrice .formio-component-content > [ref="html"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns.formio-component-totalPrice .formio-component-content > [ref="html"] {
  display: flex;
  justify-content: end;
  gap: 4px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns.i-swear .form-check.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns.i-swear .form-check.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns.i-swear .form-check.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns.i-swear .form-check.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns.i-swear .form-check.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns.i-swear .form-check.checkbox {
  padding-top: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns.i-swear .col-md-1,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns.i-swear .col-md-1,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns.i-swear .col-md-1,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns.i-swear .col-md-1,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns.i-swear .col-md-1,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns.i-swear .col-md-1 {
  flex: 0;
  width: auto;
  padding-inline-end: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns.vertical-align-top,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns.vertical-align-top,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns.vertical-align-top,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns.vertical-align-top,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns.vertical-align-top,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns.vertical-align-top {
  align-items: flex-start;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns.vertical-align-center,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns.vertical-align-center,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns.vertical-align-center,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns.vertical-align-center,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns.vertical-align-center,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns.vertical-align-center {
  align-items: center;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns.vertical-align-bottom,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns.vertical-align-bottom,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns.vertical-align-bottom,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns.vertical-align-bottom,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns.vertical-align-bottom,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns.vertical-align-bottom {
  align-items: flex-end;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) {
  --columns-bg-color: transparent;
  background-color: var(--columns-bg-color);
  border-radius: 6px;
  margin-inline: 0;
  padding: 24px 12px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns.background-red,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns.background-red,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns.background-red,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns.background-red,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns.background-red,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns.background-red {
  --columns-bg-color: #FEE7E7;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns.background-blue,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns.background-blue,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns.background-blue,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns.background-blue,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns.background-blue,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns.background-blue {
  --columns-bg-color: #E4F2FB;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns.background-green,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns.background-green,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns.background-green,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns.background-green,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns.background-green,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns.background-green {
  --columns-bg-color: #DBF5EC;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns.background-orange,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns.background-orange,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns.background-orange,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns.background-orange,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns.background-orange,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns.background-orange {
  --columns-bg-color: #FFF1ED;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns.background-gray,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns.background-gray,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns.background-gray,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns.background-gray,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns.background-gray,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns.background-gray {
  --columns-bg-color: var(--color-gray-75);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns.background-primary,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns.background-primary,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns.background-primary,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns.background-primary,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns.background-primary,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns.background-primary {
  --columns-bg-color: rgba(70, 81, 170, 0.075);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns.background-white,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns.background-white,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns.background-white,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns.background-white,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns.background-white,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns.background-white {
  --columns-bg-color: #FFFFFF;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns.top-line,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns.top-line,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns.top-line,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns.top-line,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns.top-line,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns.top-line {
  border-top: solid 1px var(--color-gray-200);
  padding-top: 10px;
  padding-bottom: 10px;
  margin-inline: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns.top-line > [class*="col-"]:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns.top-line > [class*="col-"]:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns.top-line > [class*="col-"]:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns.top-line > [class*="col-"]:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns.top-line > [class*="col-"]:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns.top-line > [class*="col-"]:first-child {
  padding-inline-start: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-columns.top-line > [class*="col-"]:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-columns.top-line > [class*="col-"]:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-columns.top-line > [class*="col-"]:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns.top-line > [class*="col-"]:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns.top-line > [class*="col-"]:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-columns.top-line > [class*="col-"]:last-child {
  padding-inline-end: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-panel[class*="formio-component-paymentPagePayments"] .card .card-body .formio-component-columns.default-pay-form-payments,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-panel[class*="formio-component-paymentPagePayments"] .card .card-body .formio-component-columns.default-pay-form-payments,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-panel[class*="formio-component-paymentPagePayments"] .card .card-body .formio-component-columns.default-pay-form-payments,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-panel[class*="formio-component-paymentPagePayments"] .card .card-body .formio-component-columns.default-pay-form-payments,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-panel[class*="formio-component-paymentPagePayments"] .card .card-body .formio-component-columns.default-pay-form-payments,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-panel[class*="formio-component-paymentPagePayments"] .card .card-body .formio-component-columns.default-pay-form-payments {
  padding-bottom: 0;
  margin-bottom: 12px;
  display: flex;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-panel[class*="formio-component-paymentPagePayments"] .card .card-body .formio-component-columns.default-pay-form-payments .formio-component.formio-component-checkbox .checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-panel[class*="formio-component-paymentPagePayments"] .card .card-body .formio-component-columns.default-pay-form-payments .formio-component.formio-component-checkbox .checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-panel[class*="formio-component-paymentPagePayments"] .card .card-body .formio-component-columns.default-pay-form-payments .formio-component.formio-component-checkbox .checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-panel[class*="formio-component-paymentPagePayments"] .card .card-body .formio-component-columns.default-pay-form-payments .formio-component.formio-component-checkbox .checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-panel[class*="formio-component-paymentPagePayments"] .card .card-body .formio-component-columns.default-pay-form-payments .formio-component.formio-component-checkbox .checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-panel[class*="formio-component-paymentPagePayments"] .card .card-body .formio-component-columns.default-pay-form-payments .formio-component.formio-component-checkbox .checkbox {
  padding-top: 0;
  cursor: pointer;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-panel[class*="formio-component-paymentPagePayments"] .card .card-body .formio-component-columns.default-pay-form-payments .formio-component.formio-component-checkbox .checkbox input::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-panel[class*="formio-component-paymentPagePayments"] .card .card-body .formio-component-columns.default-pay-form-payments .formio-component.formio-component-checkbox .checkbox input::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-panel[class*="formio-component-paymentPagePayments"] .card .card-body .formio-component-columns.default-pay-form-payments .formio-component.formio-component-checkbox .checkbox input::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-panel[class*="formio-component-paymentPagePayments"] .card .card-body .formio-component-columns.default-pay-form-payments .formio-component.formio-component-checkbox .checkbox input::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-panel[class*="formio-component-paymentPagePayments"] .card .card-body .formio-component-columns.default-pay-form-payments .formio-component.formio-component-checkbox .checkbox input::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-panel[class*="formio-component-paymentPagePayments"] .card .card-body .formio-component-columns.default-pay-form-payments .formio-component.formio-component-checkbox .checkbox input::before {
  cursor: pointer;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-panel[class*="formio-component-paymentPagePayments"] .card .card-body .formio-component-columns.default-pay-form-payments .formio-component.formio-component-htmlelement,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-panel[class*="formio-component-paymentPagePayments"] .card .card-body .formio-component-columns.default-pay-form-payments .formio-component.formio-component-htmlelement,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-panel[class*="formio-component-paymentPagePayments"] .card .card-body .formio-component-columns.default-pay-form-payments .formio-component.formio-component-htmlelement,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-panel[class*="formio-component-paymentPagePayments"] .card .card-body .formio-component-columns.default-pay-form-payments .formio-component.formio-component-htmlelement,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-panel[class*="formio-component-paymentPagePayments"] .card .card-body .formio-component-columns.default-pay-form-payments .formio-component.formio-component-htmlelement,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-panel[class*="formio-component-paymentPagePayments"] .card .card-body .formio-component-columns.default-pay-form-payments .formio-component.formio-component-htmlelement {
  margin: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-panel[class*="formio-component-paymentPagePayments"] .card .card-body .formio-component-columns.default-pay-form-payments .formio-component.formio-component-htmlelement .tile-text label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-panel[class*="formio-component-paymentPagePayments"] .card .card-body .formio-component-columns.default-pay-form-payments .formio-component.formio-component-htmlelement .tile-text label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-panel[class*="formio-component-paymentPagePayments"] .card .card-body .formio-component-columns.default-pay-form-payments .formio-component.formio-component-htmlelement .tile-text label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-panel[class*="formio-component-paymentPagePayments"] .card .card-body .formio-component-columns.default-pay-form-payments .formio-component.formio-component-htmlelement .tile-text label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-panel[class*="formio-component-paymentPagePayments"] .card .card-body .formio-component-columns.default-pay-form-payments .formio-component.formio-component-htmlelement .tile-text label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-panel[class*="formio-component-paymentPagePayments"] .card .card-body .formio-component-columns.default-pay-form-payments .formio-component.formio-component-htmlelement .tile-text label {
  margin: 0;
  display: flex;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-panel[class*="formio-component-paymentPagePayments"] .card .card-body .formio-component-columns.default-pay-form-payments:nth-last-child(2),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-panel[class*="formio-component-paymentPagePayments"] .card .card-body .formio-component-columns.default-pay-form-payments:nth-last-child(2),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-panel[class*="formio-component-paymentPagePayments"] .card .card-body .formio-component-columns.default-pay-form-payments:nth-last-child(2),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-panel[class*="formio-component-paymentPagePayments"] .card .card-body .formio-component-columns.default-pay-form-payments:nth-last-child(2),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-panel[class*="formio-component-paymentPagePayments"] .card .card-body .formio-component-columns.default-pay-form-payments:nth-last-child(2),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-panel[class*="formio-component-paymentPagePayments"] .card .card-body .formio-component-columns.default-pay-form-payments:nth-last-child(2) {
  margin-bottom: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-panel.payment-form-component .formio-component-totalPrice,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-panel.payment-form-component .formio-component-totalPrice,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-panel.payment-form-component .formio-component-totalPrice,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-panel.payment-form-component .formio-component-totalPrice,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-panel.payment-form-component .formio-component-totalPrice,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-panel.payment-form-component .formio-component-totalPrice {
  border-top: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-panel.payment-form-component .formio-component-totalPrice::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-panel.payment-form-component .formio-component-totalPrice::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-panel.payment-form-component .formio-component-totalPrice::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-panel.payment-form-component .formio-component-totalPrice::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-panel.payment-form-component .formio-component-totalPrice::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-panel.payment-form-component .formio-component-totalPrice::before {
  display: block;
  height: 1px;
  background-color: var(--color-gray-300);
  position: absolute;
  top: 0;
  width: calc(100% - 24px);
  inset-inline-start: 12px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-panel.payment-form-component [class*="formio-component-price_"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-panel.payment-form-component [class*="formio-component-price_"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-panel.payment-form-component [class*="formio-component-price_"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-panel.payment-form-component [class*="formio-component-price_"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-panel.payment-form-component [class*="formio-component-price_"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-panel.payment-form-component [class*="formio-component-price_"] {
  margin-top: 0px !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-panel.payment-form-component [class*="formio-component-price_"] :is(p, span),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-panel.payment-form-component [class*="formio-component-price_"] :is(p, span),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-panel.payment-form-component [class*="formio-component-price_"] :is(p, span),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-panel.payment-form-component [class*="formio-component-price_"] :is(p, span),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-panel.payment-form-component [class*="formio-component-price_"] :is(p, span),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-panel.payment-form-component [class*="formio-component-price_"] :is(p, span) {
  font-size: 16px !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-panel > .card > .card-body > .formio-component.formio-component-editgrid .list-group.has-open-row .list-group-item:only-child > .formio-component:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-panel > .card > .card-body > .formio-component.formio-component-editgrid .list-group.has-open-row .list-group-item:only-child > .formio-component:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-panel > .card > .card-body > .formio-component.formio-component-editgrid .list-group.has-open-row .list-group-item:only-child > .formio-component:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-panel > .card > .card-body > .formio-component.formio-component-editgrid .list-group.has-open-row .list-group-item:only-child > .formio-component:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-panel > .card > .card-body > .formio-component.formio-component-editgrid .list-group.has-open-row .list-group-item:only-child > .formio-component:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-panel > .card > .card-body > .formio-component.formio-component-editgrid .list-group.has-open-row .list-group-item:only-child > .formio-component:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > [class*="col-"] > .formio-component-editgrid .list-group.has-open-row .list-group-item:only-child > .formio-component:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > [class*="col-"] > .formio-component-editgrid .list-group.has-open-row .list-group-item:only-child > .formio-component:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > [class*="col-"] > .formio-component-editgrid .list-group.has-open-row .list-group-item:only-child > .formio-component:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > [class*="col-"] > .formio-component-editgrid .list-group.has-open-row .list-group-item:only-child > .formio-component:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > [class*="col-"] > .formio-component-editgrid .list-group.has-open-row .list-group-item:only-child > .formio-component:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-panel > .card > .card-body > .formio-component.formio-component-columns > [class*="col-"] > .formio-component-editgrid .list-group.has-open-row .list-group-item:only-child > .formio-component:first-child {
  margin-top: 0px !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-panel.limited-height > .card > .card-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-panel.limited-height > .card > .card-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-panel.limited-height > .card > .card-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-panel.limited-height > .card > .card-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-panel.limited-height > .card > .card-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-panel.limited-height > .card > .card-body {
  max-height: 10cm;
  overflow-y: auto;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-panel.full-height > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-panel.full-height > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-panel.full-height > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-panel.full-height > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-panel.full-height > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-panel.full-height > .card {
  height: 100%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-panel.notice,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-panel.notice,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-panel.notice,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-panel.notice,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-panel.notice,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-panel.notice {
  border-inline-start: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-panel.notice > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-panel.notice > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-panel.notice > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-panel.notice > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-panel.notice > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-panel.notice > .card {
  background-color: #EDEEF7 !important;
  border-inline-start: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-panel.notice > .card > .card-header,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-panel.notice > .card > .card-header,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-panel.notice > .card > .card-header,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-panel.notice > .card > .card-header,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-panel.notice > .card > .card-header,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-panel.notice > .card > .card-header {
  background-color: transparent !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) {
  --panel-bg-color: white;
  --notice-color-rgb: 243, 244, 245;
  background-color: var(--panel-bg-color);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) > .card {
  background: transparent;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) > .card > .card-header::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) > .card > .card-header::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) > .card > .card-header::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) > .card > .card-header::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) > .card > .card-header::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) > .card > .card-header::after {
  background-color: rgba(var(--notice-color-rgb), 0.15);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) > .card .formio-component-panel > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) > .card .formio-component-panel > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) > .card .formio-component-panel > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) > .card .formio-component-panel > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) > .card .formio-component-panel > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) > .card .formio-component-panel > .card {
  background-color: var(--custom-bg-color, transparent) !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white).highlight-block > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white).highlight-block > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white).highlight-block > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white).highlight-block > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white).highlight-block > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white).highlight-block > .card {
  --highlight-border-color: rgba(var(--notice-color-rgb), 1);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-panel.background-red,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-panel.background-red,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-panel.background-red,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-panel.background-red,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-panel.background-red,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-panel.background-red {
  --panel-bg-color: #FEE7E7;
  --notice-color-rgb: 247, 95, 95;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-panel.background-blue,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-panel.background-blue,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-panel.background-blue,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-panel.background-blue,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-panel.background-blue,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-panel.background-blue {
  --panel-bg-color: #E4F2FB;
  --notice-color-rgb: 76, 170, 229;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-panel.background-white,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-panel.background-white,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-panel.background-white,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-panel.background-white,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-panel.background-white,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-panel.background-white {
  --panel-bg-color: #FFFFFF;
  --notice-color-rgb: 255, 255, 255;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-panel.background-green,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-panel.background-green,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-panel.background-green,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-panel.background-green,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-panel.background-green,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-panel.background-green {
  --panel-bg-color: #DBF5EC;
  --notice-color-rgb: 16, 185, 129;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-panel.background-orange,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-panel.background-orange,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-panel.background-orange,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-panel.background-orange,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-panel.background-orange,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-panel.background-orange {
  --panel-bg-color: #FFF1ED;
  --notice-color-rgb: 255, 163, 133;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-panel.background-gray,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-panel.background-gray,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-panel.background-gray,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-panel.background-gray,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-panel.background-gray,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-panel.background-gray {
  --panel-bg-color: var(--color-gray-75);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-panel.background-primary,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-panel.background-primary,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-panel.background-primary,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-panel.background-primary,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-panel.background-primary,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-panel.background-primary {
  --panel-bg-color: rgba(70, 81, 170, 0.075);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-hidden:first-child + .formio-component,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-hidden:first-child + .formio-component,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-hidden:first-child + .formio-component,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-hidden:first-child + .formio-component,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-hidden:first-child + .formio-component,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-hidden:first-child + .formio-component {
  margin-top: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-leafletmap .map-container,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-leafletmap .map-container,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-leafletmap .map-container,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-leafletmap .map-container,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-leafletmap .map-container,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-leafletmap .map-container {
  border: solid 10px white;
  border-radius: 6px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.05);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-leafletmap .leaflet-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-leafletmap .leaflet-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-leafletmap .leaflet-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-leafletmap .leaflet-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-leafletmap .leaflet-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-leafletmap .leaflet-control {
  border-radius: 6px;
  border: 5px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-leafletmap :is(.leaflet-control-zoom-in, .leaflet-control-zoom-out),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-leafletmap :is(.leaflet-control-zoom-in, .leaflet-control-zoom-out),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-leafletmap :is(.leaflet-control-zoom-in, .leaflet-control-zoom-out),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-leafletmap :is(.leaflet-control-zoom-in, .leaflet-control-zoom-out),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-leafletmap :is(.leaflet-control-zoom-in, .leaflet-control-zoom-out),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-leafletmap :is(.leaflet-control-zoom-in, .leaflet-control-zoom-out) {
  text-decoration: none;
  color: var(--color-gray-800);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-leafletmap .leaflet-control-zoom-in,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-leafletmap .leaflet-control-zoom-in,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-leafletmap .leaflet-control-zoom-in,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-leafletmap .leaflet-control-zoom-in,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-leafletmap .leaflet-control-zoom-in,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-leafletmap .leaflet-control-zoom-in {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-leafletmap .leaflet-control-zoom-out,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-leafletmap .leaflet-control-zoom-out,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-leafletmap .leaflet-control-zoom-out,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-leafletmap .leaflet-control-zoom-out,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-leafletmap .leaflet-control-zoom-out,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-leafletmap .leaflet-control-zoom-out {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-leafletmap .leaflet-control-geocoder,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-leafletmap .leaflet-control-geocoder,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-leafletmap .leaflet-control-geocoder,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-leafletmap .leaflet-control-geocoder,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-leafletmap .leaflet-control-geocoder,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-leafletmap .leaflet-control-geocoder {
  display: grid;
  grid-auto-flow: column;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-leafletmap .leaflet-control-geocoder-icon,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-leafletmap .leaflet-control-geocoder-icon,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-leafletmap .leaflet-control-geocoder-icon,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-leafletmap .leaflet-control-geocoder-icon,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-leafletmap .leaflet-control-geocoder-icon,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-leafletmap .leaflet-control-geocoder-icon {
  grid-column: 1;
  background: no-repeat;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-leafletmap .leaflet-control-geocoder-icon::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-leafletmap .leaflet-control-geocoder-icon::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-leafletmap .leaflet-control-geocoder-icon::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-leafletmap .leaflet-control-geocoder-icon::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-leafletmap .leaflet-control-geocoder-icon::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-leafletmap .leaflet-control-geocoder-icon::before {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  font-size: 14px;
  content: "\f002";
  color: var(--color-gray-800);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-leafletmap .leaflet-control-geocoder-form,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-leafletmap .leaflet-control-geocoder-form,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-leafletmap .leaflet-control-geocoder-form,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-leafletmap .leaflet-control-geocoder-form,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-leafletmap .leaflet-control-geocoder-form,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-leafletmap .leaflet-control-geocoder-form {
  grid-column: 2;
  height: 30px;
  font-size: 12px;
  font-family: "Open Sans";
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-leafletmap .leaflet-control-geocoder-expanded .leaflet-control-geocoder-form,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-leafletmap .leaflet-control-geocoder-expanded .leaflet-control-geocoder-form,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-leafletmap .leaflet-control-geocoder-expanded .leaflet-control-geocoder-form,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-leafletmap .leaflet-control-geocoder-expanded .leaflet-control-geocoder-form,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-leafletmap .leaflet-control-geocoder-expanded .leaflet-control-geocoder-form,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-leafletmap .leaflet-control-geocoder-expanded .leaflet-control-geocoder-form {
  display: flex;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-leafletmap .leaflet-control-geocoder-form-no-error,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-leafletmap .leaflet-control-geocoder-form-no-error,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-leafletmap .leaflet-control-geocoder-form-no-error,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-leafletmap .leaflet-control-geocoder-form-no-error,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-leafletmap .leaflet-control-geocoder-form-no-error,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-leafletmap .leaflet-control-geocoder-form-no-error {
  grid-column: 1 / 3;
  grid-row: 2;
  font-size: 12px;
  font-family: "Open Sans";
  padding-inline: 10px;
  padding-block: 6px;
  font-weight: 600;
  margin: 0 !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-leafletmap .leaflet-control-geocoder-alternatives,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-leafletmap .leaflet-control-geocoder-alternatives,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-leafletmap .leaflet-control-geocoder-alternatives,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-leafletmap .leaflet-control-geocoder-alternatives,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-leafletmap .leaflet-control-geocoder-alternatives,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-leafletmap .leaflet-control-geocoder-alternatives {
  width: 100%;
  font-size: 14px;
  font-family: "Open Sans";
  grid-row: 2;
  grid-column: 1/3;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-leafletmap .leaflet-control-geocoder-options-open :is(.leaflet-control-geocoder-icon, .leaflet-control-geocoder-form),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-leafletmap .leaflet-control-geocoder-options-open :is(.leaflet-control-geocoder-icon, .leaflet-control-geocoder-form),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-leafletmap .leaflet-control-geocoder-options-open :is(.leaflet-control-geocoder-icon, .leaflet-control-geocoder-form),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-leafletmap .leaflet-control-geocoder-options-open :is(.leaflet-control-geocoder-icon, .leaflet-control-geocoder-form),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-leafletmap .leaflet-control-geocoder-options-open :is(.leaflet-control-geocoder-icon, .leaflet-control-geocoder-form),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-leafletmap .leaflet-control-geocoder-options-open :is(.leaflet-control-geocoder-icon, .leaflet-control-geocoder-form) {
  background-color: #EFEFEF;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-signature .signature-pad-refresh i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-signature .signature-pad-refresh i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-signature .signature-pad-refresh i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-signature .signature-pad-refresh i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-signature .signature-pad-refresh i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-signature .signature-pad-refresh i {
  font-family: var(--fontawesome-font);
  font-weight: 400;
  font-size: 14px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-signature .signature-pad-refresh i::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-signature .signature-pad-refresh i::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-signature .signature-pad-refresh i::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-signature .signature-pad-refresh i::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-signature .signature-pad-refresh i::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-signature .signature-pad-refresh i::before {
  content: "\f304";
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-signature .signature-pad-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-signature .signature-pad-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-signature .signature-pad-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-signature .signature-pad-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-signature .signature-pad-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-signature .signature-pad-body {
  border: solid 1px var(--color-gray-100);
  border-radius: 6px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-signature .signature-pad-body canvas,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-signature .signature-pad-body canvas,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-signature .signature-pad-body canvas,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-signature .signature-pad-body canvas,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-signature .signature-pad-body canvas,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-signature .signature-pad-body canvas {
  cursor: crosshair;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-signature [value="No"] ~ .signature-pad-body :where(canvas, img[ref="signatureImage"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-signature [value="No"] ~ .signature-pad-body :where(canvas, img[ref="signatureImage"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-signature [value="No"] ~ .signature-pad-body :where(canvas, img[ref="signatureImage"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-signature [value="No"] ~ .signature-pad-body :where(canvas, img[ref="signatureImage"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-signature [value="No"] ~ .signature-pad-body :where(canvas, img[ref="signatureImage"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-signature [value="No"] ~ .signature-pad-body :where(canvas, img[ref="signatureImage"]) {
  cursor: not-allowed;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-signature [value^="data:"] ~ .signature-pad-body i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-signature [value^="data:"] ~ .signature-pad-body i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-signature [value^="data:"] ~ .signature-pad-body i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-signature [value^="data:"] ~ .signature-pad-body i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-signature [value^="data:"] ~ .signature-pad-body i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-signature [value^="data:"] ~ .signature-pad-body i {
  color: #F75F5F;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-signature [value^="data:"] ~ .signature-pad-body i::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-signature [value^="data:"] ~ .signature-pad-body i::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-signature [value^="data:"] ~ .signature-pad-body i::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-signature [value^="data:"] ~ .signature-pad-body i::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-signature [value^="data:"] ~ .signature-pad-body i::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-signature [value^="data:"] ~ .signature-pad-body i::before {
  content: "\f2ed";
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-signature img[ref="signatureImage"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-signature img[ref="signatureImage"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-signature img[ref="signatureImage"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-signature img[ref="signatureImage"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-signature img[ref="signatureImage"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-signature img[ref="signatureImage"] {
  filter: contrast(1.1) brightness(1.1);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-cubanproductlist .modal-content .modal-header,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-cubanproductlist .modal-content .modal-header,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-cubanproductlist .modal-content .modal-header,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-cubanproductlist .modal-content .modal-header,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-cubanproductlist .modal-content .modal-header,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-cubanproductlist .modal-content .modal-header {
  background-color: var(--color-gray-75);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.formio-component-cubanproductlist .modal-content .modal-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.formio-component-cubanproductlist .modal-content .modal-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.formio-component-cubanproductlist .modal-content .modal-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-cubanproductlist .modal-content .modal-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-cubanproductlist .modal-content .modal-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-cubanproductlist .modal-content .modal-body {
  background-color: var(--color-gray-75);
  padding-top: 15px !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table {
  table-layout: auto;
  border: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td {
  padding: 12px 0 0;
  border: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td:first-child {
  width: 100%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td:first-child + td button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td:first-child + td button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td:first-child + td button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td:first-child + td button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td:first-child + td button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td:first-child + td button.btn {
  background-color: transparent;
  height: 40px;
  font-size: 20px;
  line-height: 1;
  border: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td:first-child + td button.btn[ref="removeRow"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td:first-child + td button.btn[ref="removeRow"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td:first-child + td button.btn[ref="removeRow"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td:first-child + td button.btn[ref="removeRow"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td:first-child + td button.btn[ref="removeRow"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td:first-child + td button.btn[ref="removeRow"] {
  color: #F75F5F;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td:first-child + td button.btn[ref="removeRow"] i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td:first-child + td button.btn[ref="removeRow"] i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td:first-child + td button.btn[ref="removeRow"] i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td:first-child + td button.btn[ref="removeRow"] i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td:first-child + td button.btn[ref="removeRow"] i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td:first-child + td button.btn[ref="removeRow"] i {
  font-family: var(--fontawesome-font);
  font-weight: 300;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td:first-child + td button.btn[ref="removeRow"] i::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td:first-child + td button.btn[ref="removeRow"] i::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td:first-child + td button.btn[ref="removeRow"] i::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td:first-child + td button.btn[ref="removeRow"] i::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td:first-child + td button.btn[ref="removeRow"] i::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td:first-child + td button.btn[ref="removeRow"] i::before {
  content: "\F057";
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td:first-child + td button.btn[ref="removeRow"]:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td:first-child + td button.btn[ref="removeRow"]:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td:first-child + td button.btn[ref="removeRow"]:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td:first-child + td button.btn[ref="removeRow"]:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td:first-child + td button.btn[ref="removeRow"]:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr td:first-child + td button.btn[ref="removeRow"]:hover {
  background-color: rgba(247, 95, 95, 0.05);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr:last-child td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr:last-child td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr:last-child td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr:last-child td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr:last-child td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr:last-child td {
  padding-top: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr:last-child td button.btn.formio-button-add-another,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr:last-child td button.btn.formio-button-add-another,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr:last-child td button.btn.formio-button-add-another,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr:last-child td button.btn.formio-button-add-another,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr:last-child td button.btn.formio-button-add-another,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr:last-child td button.btn.formio-button-add-another {
  border: solid 1px var(--color-primary-600);
  background-color: transparent;
  border-radius: 6px !important;
  color: var(--color-primary-600);
  padding-inline-start: 25px;
  padding-inline-end: 25px;
  font-weight: 600;
  font-size: 12px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  transition-duration: 0.3s;
  margin-top: 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr:last-child td button.btn.formio-button-add-another > i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr:last-child td button.btn.formio-button-add-another > i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr:last-child td button.btn.formio-button-add-another > i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr:last-child td button.btn.formio-button-add-another > i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr:last-child td button.btn.formio-button-add-another > i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr:last-child td button.btn.formio-button-add-another > i {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr:last-child td button.btn.formio-button-add-another:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr:last-child td button.btn.formio-button-add-another:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr:last-child td button.btn.formio-button-add-another:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr:last-child td button.btn.formio-button-add-another:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr:last-child td button.btn.formio-button-add-another:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr:last-child td button.btn.formio-button-add-another:hover {
  color: white;
  background-color: var(--color-primary-600);
  box-shadow: 0 9px 20px rgba(70, 81, 170, 0.4) !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr:first-child td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr:first-child td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr:first-child td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr:first-child td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr:first-child td,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address).formio-component-multiple table.table tbody tr:first-child td {
  padding-top: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-textfield, .formio-component-number, .formio-component-textarea).notice,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-textfield, .formio-component-number, .formio-component-textarea).notice,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-textfield, .formio-component-number, .formio-component-textarea).notice,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-textfield, .formio-component-number, .formio-component-textarea).notice,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-textfield, .formio-component-number, .formio-component-textarea).notice,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-textfield, .formio-component-number, .formio-component-textarea).notice {
  background: rgba(27, 35, 49, 0.03) !important;
  border-inline-start: solid 3px;
  border-radius: 0 6px 6px 0;
  padding: 2rem;
  border-left-color: var(--color-primary-600);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-textfield, .formio-component-number, .formio-component-textarea).notice :is(input, textarea),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-textfield, .formio-component-number, .formio-component-textarea).notice :is(input, textarea),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-textfield, .formio-component-number, .formio-component-textarea).notice :is(input, textarea),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-textfield, .formio-component-number, .formio-component-textarea).notice :is(input, textarea),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-textfield, .formio-component-number, .formio-component-textarea).notice :is(input, textarea),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-textfield, .formio-component-number, .formio-component-textarea).notice :is(input, textarea) {
  background-color: transparent;
  border: none !important;
  padding: 0;
  height: auto;
  font-weight: 600;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.formio-component-textfield, .formio-component-number, .formio-component-textarea).notice :is(input, textarea)[disabled],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.formio-component-textfield, .formio-component-number, .formio-component-textarea).notice :is(input, textarea)[disabled],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.formio-component-textfield, .formio-component-number, .formio-component-textarea).notice :is(input, textarea)[disabled],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.formio-component-textfield, .formio-component-number, .formio-component-textarea).notice :is(input, textarea)[disabled],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.formio-component-textfield, .formio-component-number, .formio-component-textarea).notice :is(input, textarea)[disabled],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.formio-component-textfield, .formio-component-number, .formio-component-textarea).notice :is(input, textarea)[disabled] {
  background-color: transparent !important;
  margin-bottom: -13px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.text-right :where(label, input, textarea),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.text-right :where(label, input, textarea),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.text-right :where(label, input, textarea),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.text-right :where(label, input, textarea),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.text-right :where(label, input, textarea),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.text-right :where(label, input, textarea) {
  text-align: end !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.text-right.formio-component-panel > .card > .card-header .card-title,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.text-right.formio-component-panel > .card > .card-header .card-title,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.text-right.formio-component-panel > .card > .card-header .card-title,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.text-right.formio-component-panel > .card > .card-header .card-title,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.text-right.formio-component-panel > .card > .card-header .card-title,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.text-right.formio-component-panel > .card > .card-header .card-title {
  flex-direction: row-reverse;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.text-right.formio-component-panel > .card > .card-header .card-title .formio-collapse-icon,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.text-right.formio-component-panel > .card > .card-header .card-title .formio-collapse-icon,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.text-right.formio-component-panel > .card > .card-header .card-title .formio-collapse-icon,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.text-right.formio-component-panel > .card > .card-header .card-title .formio-collapse-icon,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.text-right.formio-component-panel > .card > .card-header .card-title .formio-collapse-icon,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.text-right.formio-component-panel > .card > .card-header .card-title .formio-collapse-icon {
  inset-inline-start: 0;
  inset-inline-end: initial;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.text-right.formio-component-panel > .card > .card-header .card-title .formio-collapse-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.text-right.formio-component-panel > .card > .card-header .card-title .formio-collapse-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.text-right.formio-component-panel > .card > .card-header .card-title .formio-collapse-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.text-right.formio-component-panel > .card > .card-header .card-title .formio-collapse-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.text-right.formio-component-panel > .card > .card-header .card-title .formio-collapse-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.text-right.formio-component-panel > .card > .card-header .card-title .formio-collapse-text {
  margin-inline-end: auto;
  margin-inline-start: 24px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.align-value-right :where(input, textarea):not(.choices__input),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.align-value-right :where(input, textarea):not(.choices__input),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.align-value-right :where(input, textarea):not(.choices__input),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.align-value-right :where(input, textarea):not(.choices__input),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.align-value-right :where(input, textarea):not(.choices__input),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.align-value-right :where(input, textarea):not(.choices__input) {
  text-align: end !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.align-value-right.required :where(input, textarea):not(.choices__input),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.align-value-right.required :where(input, textarea):not(.choices__input),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.align-value-right.required :where(input, textarea):not(.choices__input),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.align-value-right.required :where(input, textarea):not(.choices__input),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.align-value-right.required :where(input, textarea):not(.choices__input),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.align-value-right.required :where(input, textarea):not(.choices__input) {
  padding-inline-end: 20px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.has-suffix:not(.formio-component-datetime).required label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.has-suffix:not(.formio-component-datetime).required label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.has-suffix:not(.formio-component-datetime).required label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.has-suffix:not(.formio-component-datetime).required label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.has-suffix:not(.formio-component-datetime).required label.field-required.control-label--hidden::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.has-suffix:not(.formio-component-datetime).required label.field-required.control-label--hidden::after {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.has-suffix:not(.formio-component-datetime).required label.field-required.control-label--hidden + [ref="element"] .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.has-suffix:not(.formio-component-datetime).required label.field-required.control-label--hidden + [ref="element"] .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.has-suffix:not(.formio-component-datetime).required label.field-required.control-label--hidden + [ref="element"] .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.has-suffix:not(.formio-component-datetime).required label.field-required.control-label--hidden + [ref="element"] .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.has-suffix:not(.formio-component-datetime).required label.field-required.control-label--hidden + [ref="element"] .input-group-append,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.has-suffix:not(.formio-component-datetime).required label.field-required.control-label--hidden + [ref="element"] .input-group-append {
  position: relative;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.has-suffix:not(.formio-component-datetime).required label.field-required.control-label--hidden + [ref="element"] .input-group-append::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.has-suffix:not(.formio-component-datetime).required label.field-required.control-label--hidden + [ref="element"] .input-group-append::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.has-suffix:not(.formio-component-datetime).required label.field-required.control-label--hidden + [ref="element"] .input-group-append::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.has-suffix:not(.formio-component-datetime).required label.field-required.control-label--hidden + [ref="element"] .input-group-append::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.has-suffix:not(.formio-component-datetime).required label.field-required.control-label--hidden + [ref="element"] .input-group-append::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.has-suffix:not(.formio-component-datetime).required label.field-required.control-label--hidden + [ref="element"] .input-group-append::before {
  content: " ";
  display: inline-block;
  background-color: rgba(255, 0, 59, 0.7);
  vertical-align: unset;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  position: absolute;
  top: calc(50% - 3px);
  inset-inline-start: -16px;
  min-width: 6px;
  z-index: 9;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.horizontal-align-left,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.horizontal-align-left,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.horizontal-align-left,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.horizontal-align-left,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.horizontal-align-left,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.horizontal-align-left {
  text-align: start;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.horizontal-align-center,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.horizontal-align-center,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.horizontal-align-center,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.horizontal-align-center,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.horizontal-align-center,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.horizontal-align-center {
  text-align: center;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.horizontal-align-center.formio-component-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.horizontal-align-center.formio-component-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.horizontal-align-center.formio-component-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.horizontal-align-center.formio-component-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.horizontal-align-center.formio-component-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.horizontal-align-center.formio-component-radio {
  display: grid;
  place-content: center;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.horizontal-align-center:is(.formio-component-content, .formio-component-htmlelement) .image,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.horizontal-align-center:is(.formio-component-content, .formio-component-htmlelement) .image,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.horizontal-align-center:is(.formio-component-content, .formio-component-htmlelement) .image,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.horizontal-align-center:is(.formio-component-content, .formio-component-htmlelement) .image,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.horizontal-align-center:is(.formio-component-content, .formio-component-htmlelement) .image,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.horizontal-align-center:is(.formio-component-content, .formio-component-htmlelement) .image {
  margin-inline: auto;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.horizontal-align-right,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.horizontal-align-right,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.horizontal-align-right,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.horizontal-align-right,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.horizontal-align-right,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.horizontal-align-right {
  text-align: end;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.horizontal-align-right.formio-component-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.horizontal-align-right.formio-component-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.horizontal-align-right.formio-component-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.horizontal-align-right.formio-component-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.horizontal-align-right.formio-component-radio,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.horizontal-align-right.formio-component-radio {
  display: grid;
  place-content: end;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.size-xs, .size-sm, .size-md, .size-lg) .input-group-text i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.size-xs, .size-sm, .size-md, .size-lg) .input-group-text i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.size-xs, .size-sm, .size-md, .size-lg) .input-group-text i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.size-xs, .size-sm, .size-md, .size-lg) .input-group-text i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.size-xs, .size-sm, .size-md, .size-lg) .input-group-text i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.size-xs, .size-sm, .size-md, .size-lg) .input-group-text i,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.size-xs, .size-sm, .size-md, .size-lg) span.value,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.size-xs, .size-sm, .size-md, .size-lg) span.value,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.size-xs, .size-sm, .size-md, .size-lg) span.value,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.size-xs, .size-sm, .size-md, .size-lg) span.value,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.size-xs, .size-sm, .size-md, .size-lg) span.value,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.size-xs, .size-sm, .size-md, .size-lg) span.value,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.size-xs, .size-sm, .size-md, .size-lg) .formio-choices::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.size-xs, .size-sm, .size-md, .size-lg) .formio-choices::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.size-xs, .size-sm, .size-md, .size-lg) .formio-choices::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.size-xs, .size-sm, .size-md, .size-lg) .formio-choices::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.size-xs, .size-sm, .size-md, .size-lg) .formio-choices::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.size-xs, .size-sm, .size-md, .size-lg) .formio-choices::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.size-xs, .size-sm, .size-md, .size-lg) .input-group-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.size-xs, .size-sm, .size-md, .size-lg) .input-group-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.size-xs, .size-sm, .size-md, .size-lg) .input-group-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.size-xs, .size-sm, .size-md, .size-lg) .input-group-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.size-xs, .size-sm, .size-md, .size-lg) .input-group-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.size-xs, .size-sm, .size-md, .size-lg) .input-group-text {
  font-size: var(--field-font-size);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.size-xs, .size-sm, .size-md, .size-lg) .choices[data-type*=select-one] .choices__button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.size-xs, .size-sm, .size-md, .size-lg) .choices[data-type*=select-one] .choices__button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.size-xs, .size-sm, .size-md, .size-lg) .choices[data-type*=select-one] .choices__button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.size-xs, .size-sm, .size-md, .size-lg) .choices[data-type*=select-one] .choices__button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.size-xs, .size-sm, .size-md, .size-lg) .choices[data-type*=select-one] .choices__button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.size-xs, .size-sm, .size-md, .size-lg) .choices[data-type*=select-one] .choices__button {
  background-size: var(--clear-selection-icon-size);
  margin-inline-end: var(--clear-selection-icon-margin) !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component:is(.size-xs, .size-sm, .size-md, .size-lg) input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component:is(.size-xs, .size-sm, .size-md, .size-lg) input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog:is(.size-xs, .size-sm, .size-md, .size-lg) input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component:is(.size-xs, .size-sm, .size-md, .size-lg) input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component:is(.size-xs, .size-sm, .size-md, .size-lg) input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component:is(.size-xs, .size-sm, .size-md, .size-lg) input.form-control {
  padding-inline: 1rem;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.size-xs,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.size-xs,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.size-xs,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.size-xs,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.size-xs,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.size-xs {
  --field-height: 22px;
  --field-font-size: 10px;
  --clear-selection-icon-size: 6px;
  --clear-selection-icon-margin: 15px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.size-sm,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.size-sm,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.size-sm,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.size-sm,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.size-sm,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.size-sm {
  --field-height: 32px;
  --field-font-size: 12px;
  --clear-selection-icon-size: 8px;
  --clear-selection-icon-margin: 18px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.size-md,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.size-md,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.size-md,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.size-md,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.size-md,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.size-md {
  --clear-selection-icon-size: 8px;
  --clear-selection-icon-margin: 25px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.size-lg,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.size-lg,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.size-lg,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.size-lg,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.size-lg,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.size-lg {
  --field-height: 48px;
  --field-font-size: 18px;
  --clear-selection-icon-size: 10px;
  --clear-selection-icon-margin: 30px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component.size-lg input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component.size-lg input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog.size-lg input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.size-lg input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.size-lg input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.size-lg input.form-control {
  padding-inline: 1.4rem;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component .btn-green,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component .btn-green,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog .btn-green,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .btn-green,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component .btn-green,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component .btn-green {
  --button-bg-color: #10B981;
  --button-secondary-bg-color: rgba(16, 185, 129, 0.35);
  --status-bg-color: #4BC394;
  --status-secondary-bg-color: #E1F0EE;
  --status-text-color: white;
  --status-secondary-text-color: #10B981;
  --status-secondary-outline-border-color: #9CCEC7;
  --status-outline-border-color: #10B981;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component .btn-blue,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component .btn-blue,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog .btn-blue,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .btn-blue,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component .btn-blue,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component .btn-blue {
  --button-bg-color: #4CAAE5;
  --button-secondary-bg-color: rgba(76, 170, 229, 0.35);
  --status-bg-color: #4CAAE5;
  --status-secondary-bg-color: #E4F2FB;
  --status-text-color: white;
  --status-secondary-text-color: #1D86C8;
  --status-secondary-outline-border-color: #8CC7EE;
  --status-outline-border-color: #1D86C8;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component .btn-orange,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component .btn-orange,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog .btn-orange,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .btn-orange,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component .btn-orange,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component .btn-orange {
  --button-bg-color: #FFA385;
  --button-secondary-bg-color: rgba(255, 163, 133, 0.5);
  --status-bg-color: #FFA385;
  --status-secondary-bg-color: #FFF0EB;
  --status-text-color: white;
  --status-secondary-text-color: #FF7233;
  --status-secondary-outline-border-color: #FFA385;
  --status-outline-border-color: #FF7233;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component .btn-red,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component .btn-red,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog .btn-red,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .btn-red,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component .btn-red,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component .btn-red {
  --button-bg-color: #F75F5F;
  --button-secondary-bg-color: rgba(247, 95, 95, 0.35);
  --status-bg-color: #F75F5F;
  --status-secondary-bg-color: #FEE7E7;
  --status-text-color: white;
  --status-secondary-text-color: #F53D3D;
  --status-secondary-outline-border-color: #FA8585;
  --status-outline-border-color: #F53D3D;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component .color-neutral,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component .color-neutral,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog .color-neutral,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .color-neutral,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component .color-neutral,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component .color-neutral {
  --button-bg-color: var(--color-gray-900);
  --button-secondary-bg-color: var(--color-gray-200);
  --status-bg-color: var(--color-gray-900);
  --status-secondary-bg-color: var(--color-gray-200);
  --status-text-color: white;
  --status-secondary-text-color: var(--color-gray-900);
  --status-secondary-outline-border-color: hsla(221, 39%, 11%, 0.5);
  --status-outline-border-color: var(--color-gray-900);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component button.btn {
  padding-inline: var(--default-button-inline-padding);
  padding-block: var(--default-button-block-padding);
  font-size: var(--default-button-font-size);
  min-height: var(--default-button-height);
  border-radius: var(--default-button-border-radius);
  line-height: 1;
  border: solid var(--button-border-width, 0px) var(--button-border-color, transparent);
  box-shadow: var(--button-shadow, none);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component button.btn:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component button.btn:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog button.btn:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component button.btn:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component button.btn:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component button.btn:hover {
  --button-shadow: var(--button-shadow-hover);
  opacity: 0.9;
  color: var(--button-text-color-hover);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component button.btn.btn-xs,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component button.btn.btn-xs,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog button.btn.btn-xs,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component button.btn.btn-xs,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component button.btn.btn-xs,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component button.btn.btn-xs {
  box-shadow: none;
  padding-inline: var(--xs-button-inline-padding);
  padding-block: var(--xs-button-block-padding);
  font-size: var(--xs-button-font-size);
  min-height: var(--xs-button-height);
  border-radius: var(--xs-button-border-radius);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component button.btn.btn-xs.button-status,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component button.btn.btn-xs.button-status,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog button.btn.btn-xs.button-status,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component button.btn.btn-xs.button-status,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component button.btn.btn-xs.button-status,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component button.btn.btn-xs.button-status {
  --button-inline-padding: 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component button.btn:is(.btn-sm, [ref*="-saveRow"], [ref*="-cancelRow"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component button.btn:is(.btn-sm, [ref*="-saveRow"], [ref*="-cancelRow"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog button.btn:is(.btn-sm, [ref*="-saveRow"], [ref*="-cancelRow"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component button.btn:is(.btn-sm, [ref*="-saveRow"], [ref*="-cancelRow"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component button.btn:is(.btn-sm, [ref*="-saveRow"], [ref*="-cancelRow"]),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component button.btn:is(.btn-sm, [ref*="-saveRow"], [ref*="-cancelRow"]) {
  padding-inline: var(--sm-button-inline-padding);
  padding-block: var(--sm-button-block-padding);
  font-size: var(--sm-button-font-size);
  min-height: var(--sm-button-height);
  border-radius: var(--sm-button-border-radius);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component button.btn:is(.btn-sm, [ref*="-saveRow"], [ref*="-cancelRow"]).button-status,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component button.btn:is(.btn-sm, [ref*="-saveRow"], [ref*="-cancelRow"]).button-status,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog button.btn:is(.btn-sm, [ref*="-saveRow"], [ref*="-cancelRow"]).button-status,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component button.btn:is(.btn-sm, [ref*="-saveRow"], [ref*="-cancelRow"]).button-status,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component button.btn:is(.btn-sm, [ref*="-saveRow"], [ref*="-cancelRow"]).button-status,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component button.btn:is(.btn-sm, [ref*="-saveRow"], [ref*="-cancelRow"]).button-status {
  --button-inline-padding: 16px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component button.btn.btn-md,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component button.btn.btn-md,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog button.btn.btn-md,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component button.btn.btn-md,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component button.btn.btn-md,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component button.btn.btn-md {
  padding-inline: var(--md-button-inline-padding);
  padding-block: var(--md-button-block-padding);
  font-size: var(--md-button-font-size);
  min-height: var(--md-button-height);
  border-radius: var(--md-button-border-radius);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component button.btn.btn-md.button-status,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component button.btn.btn-md.button-status,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog button.btn.btn-md.button-status,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component button.btn.btn-md.button-status,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component button.btn.btn-md.button-status,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component button.btn.btn-md.button-status {
  --button-inline-padding: 16px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component button.btn.btn-lg,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component button.btn.btn-lg,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog button.btn.btn-lg,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component button.btn.btn-lg,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component button.btn.btn-lg,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component button.btn.btn-lg {
  padding-inline: var(--lg-button-inline-padding);
  padding-block: var(--lg-button-block-padding);
  font-size: var(--lg-button-font-size);
  min-height: var(--lg-button-height);
  border-radius: var(--lg-button-border-radius);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component button.btn.btn-lg.button-status,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component button.btn.btn-lg.button-status,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog button.btn.btn-lg.button-status,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component button.btn.btn-lg.button-status,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component button.btn.btn-lg.button-status,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component button.btn.btn-lg.button-status {
  --button-inline-padding: 24px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component button.btn.btn-xl,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component button.btn.btn-xl,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog button.btn.btn-xl,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component button.btn.btn-xl,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component button.btn.btn-xl,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component button.btn.btn-xl {
  padding-inline: var(--xl-button-inline-padding);
  padding-block: var(--xl-button-block-padding);
  font-size: var(--xl-button-font-size);
  min-height: var(--xl-button-height);
  border-radius: var(--xl-button-border-radius);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component button.btn.btn-xl.button-status,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component button.btn.btn-xl.button-status,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog button.btn.btn-xl.button-status,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component button.btn.btn-xl.button-status,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component button.btn.btn-xl.button-status,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component button.btn.btn-xl.button-status {
  --button-inline-padding: 24px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component button.btn.no-shadow,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component button.btn.no-shadow,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog button.btn.no-shadow,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component button.btn.no-shadow,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component button.btn.no-shadow,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component button.btn.no-shadow {
  box-shadow: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component :is(.btn-sm, .btn-xs, .btn-lg).button-status,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component :is(.btn-sm, .btn-xs, .btn-lg).button-status,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog :is(.btn-sm, .btn-xs, .btn-lg).button-status,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component :is(.btn-sm, .btn-xs, .btn-lg).button-status,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component :is(.btn-sm, .btn-xs, .btn-lg).button-status,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component :is(.btn-sm, .btn-xs, .btn-lg).button-status {
  --button-border-radius: 50px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component[style*="label-flex"] .field-wrapper,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component[style*="label-flex"] .field-wrapper,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog[style*="label-flex"] .field-wrapper,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component[style*="label-flex"] .field-wrapper,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component[style*="label-flex"] .field-wrapper,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component[style*="label-flex"] .field-wrapper {
  gap: calc(1% * var(--label-flex-margin, 0));
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component[style*="label-flex"] .field-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component[style*="label-flex"] .field-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog[style*="label-flex"] .field-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component[style*="label-flex"] .field-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component[style*="label-flex"] .field-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component[style*="label-flex"] .field-label {
  flex: calc(100 - var(--label-flex, 1) - var(--label-flex-margin, 0));
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component[style*="label-flex"] .field-content,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component[style*="label-flex"] .field-content,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog[style*="label-flex"] .field-content,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component[style*="label-flex"] .field-content,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component[style*="label-flex"] .field-content,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component[style*="label-flex"] .field-content {
  flex: var(--label-flex, 1);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-component-modal-wrapper .formio-component .field-label--right :where(label, .col-form-label),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-component .field-label--right :where(label, .col-form-label),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form .formio-dialog .field-label--right :where(label, .col-form-label),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .field-label--right :where(label, .col-form-label),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component .field-label--right :where(label, .col-form-label),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component .field-label--right :where(label, .col-form-label) {
  --label-text-align: end;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form {
  margin-top: 9px;
  display: flex !important;
  flex-wrap: wrap;
  gap: 24px;
  margin-inline: 0px !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form::before {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > .formio-errors,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > .formio-errors,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > .formio-errors,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > .formio-errors,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > .formio-errors,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > .formio-errors {
  width: 100%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"] {
  align-items: normal;
  align-self: stretch;
  min-width: 320px;
  flex: 1;
  padding-inline: 0px !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) {
  margin-inline-end: 36px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-htmlelement.formio-component-mandatoryRegistrationsContent,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-htmlelement.formio-component-mandatoryRegistrationsContent,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-htmlelement.formio-component-mandatoryRegistrationsContent,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-htmlelement.formio-component-mandatoryRegistrationsContent,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-htmlelement.formio-component-mandatoryRegistrationsContent,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-htmlelement.formio-component-mandatoryRegistrationsContent {
  margin: 0 0 1rem !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-htmlelement.formio-component-mandatoryRegistrationsContent p,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-htmlelement.formio-component-mandatoryRegistrationsContent p,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-htmlelement.formio-component-mandatoryRegistrationsContent p,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-htmlelement.formio-component-mandatoryRegistrationsContent p,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-htmlelement.formio-component-mandatoryRegistrationsContent p,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-htmlelement.formio-component-mandatoryRegistrationsContent p {
  font-size: 13px !important;
  line-height: 1.1;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-htmlelement:where(.formio-hidden, .hide) + .formio-component-checkbox .form-check.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-htmlelement:where(.formio-hidden, .hide) + .formio-component-checkbox .form-check.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-htmlelement:where(.formio-hidden, .hide) + .formio-component-checkbox .form-check.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-htmlelement:where(.formio-hidden, .hide) + .formio-component-checkbox .form-check.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-htmlelement:where(.formio-hidden, .hide) + .formio-component-checkbox .form-check.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-htmlelement:where(.formio-hidden, .hide) + .formio-component-checkbox .form-check.checkbox {
  padding-top: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-htmlelement:where(.formio-hidden, .hide) + .formio-component-checkbox .form-check.checkbox label.form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-htmlelement:where(.formio-hidden, .hide) + .formio-component-checkbox .form-check.checkbox label.form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-htmlelement:where(.formio-hidden, .hide) + .formio-component-checkbox .form-check.checkbox label.form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-htmlelement:where(.formio-hidden, .hide) + .formio-component-checkbox .form-check.checkbox label.form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-htmlelement:where(.formio-hidden, .hide) + .formio-component-checkbox .form-check.checkbox label.form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-htmlelement:where(.formio-hidden, .hide) + .formio-component-checkbox .form-check.checkbox label.form-check-label {
  margin-top: -3px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-checkbox {
  margin-top: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-checkbox .form-check.checkbox label.form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-checkbox .form-check.checkbox label.form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-checkbox .form-check.checkbox label.form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-checkbox .form-check.checkbox label.form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-checkbox .form-check.checkbox label.form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-checkbox .form-check.checkbox label.form-check-label span {
  line-height: 20px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-checkbox .form-check.checkbox label.form-check-label input[type="checkbox"].form-check-input:checked + span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-checkbox .form-check.checkbox label.form-check-label input[type="checkbox"].form-check-input:checked + span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-checkbox .form-check.checkbox label.form-check-label input[type="checkbox"].form-check-input:checked + span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-checkbox .form-check.checkbox label.form-check-label input[type="checkbox"].form-check-input:checked + span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-checkbox .form-check.checkbox label.form-check-label input[type="checkbox"].form-check-input:checked + span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) .formio-component-checkbox .form-check.checkbox label.form-check-label input[type="checkbox"].form-check-input:checked + span::before {
  color: var(--color-primary-600);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-registrationsPanel .formio-component-checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-registrationsPanel .formio-component-checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-registrationsPanel .formio-component-checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-registrationsPanel .formio-component-checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-registrationsPanel .formio-component-checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-registrationsPanel .formio-component-checkbox {
  margin-top: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel > .card > .card-body .formio-component-feesDescription,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel > .card > .card-body .formio-component-feesDescription,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel > .card > .card-body .formio-component-feesDescription,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel > .card > .card-body .formio-component-feesDescription,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel > .card > .card-body .formio-component-feesDescription,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel > .card > .card-body .formio-component-feesDescription {
  border: none;
  padding-top: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel > .card > .card-body .formio-component-feesDescription .formio-component-columns[class*="formio-component-priceRow"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel > .card > .card-body .formio-component-feesDescription .formio-component-columns[class*="formio-component-priceRow"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel > .card > .card-body .formio-component-feesDescription .formio-component-columns[class*="formio-component-priceRow"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel > .card > .card-body .formio-component-feesDescription .formio-component-columns[class*="formio-component-priceRow"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel > .card > .card-body .formio-component-feesDescription .formio-component-columns[class*="formio-component-priceRow"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel > .card > .card-body .formio-component-feesDescription .formio-component-columns[class*="formio-component-priceRow"] {
  margin-top: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel > .card > .card-body .formio-component-feesDescription .formio-component-columns[class*="formio-component-priceRow"] > .col-md-6:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel > .card > .card-body .formio-component-feesDescription .formio-component-columns[class*="formio-component-priceRow"] > .col-md-6:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel > .card > .card-body .formio-component-feesDescription .formio-component-columns[class*="formio-component-priceRow"] > .col-md-6:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel > .card > .card-body .formio-component-feesDescription .formio-component-columns[class*="formio-component-priceRow"] > .col-md-6:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel > .card > .card-body .formio-component-feesDescription .formio-component-columns[class*="formio-component-priceRow"] > .col-md-6:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel > .card > .card-body .formio-component-feesDescription .formio-component-columns[class*="formio-component-priceRow"] > .col-md-6:first-child {
  flex: auto;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel > .card > .card-body .formio-component-feesDescription .formio-component-columns[class*="formio-component-priceRow"] > .col-md-6:first-child + .col-md-6,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel > .card > .card-body .formio-component-feesDescription .formio-component-columns[class*="formio-component-priceRow"] > .col-md-6:first-child + .col-md-6,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel > .card > .card-body .formio-component-feesDescription .formio-component-columns[class*="formio-component-priceRow"] > .col-md-6:first-child + .col-md-6,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel > .card > .card-body .formio-component-feesDescription .formio-component-columns[class*="formio-component-priceRow"] > .col-md-6:first-child + .col-md-6,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel > .card > .card-body .formio-component-feesDescription .formio-component-columns[class*="formio-component-priceRow"] > .col-md-6:first-child + .col-md-6,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel > .card > .card-body .formio-component-feesDescription .formio-component-columns[class*="formio-component-priceRow"] > .col-md-6:first-child + .col-md-6 {
  flex: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel > .card > .card-body .formio-component-feesDescription .formio-component-columns[class*="formio-component-priceRow"] .formio-component-htmlelement,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel > .card > .card-body .formio-component-feesDescription .formio-component-columns[class*="formio-component-priceRow"] .formio-component-htmlelement,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel > .card > .card-body .formio-component-feesDescription .formio-component-columns[class*="formio-component-priceRow"] .formio-component-htmlelement,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel > .card > .card-body .formio-component-feesDescription .formio-component-columns[class*="formio-component-priceRow"] .formio-component-htmlelement,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel > .card > .card-body .formio-component-feesDescription .formio-component-columns[class*="formio-component-priceRow"] .formio-component-htmlelement,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel > .card > .card-body .formio-component-feesDescription .formio-component-columns[class*="formio-component-priceRow"] .formio-component-htmlelement {
  margin: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel > .card > .card-body .formio-component-feesDescription .formio-component-columns[class*="formio-component-priceRow"] .formio-component-htmlelement :where(p, p span),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel > .card > .card-body .formio-component-feesDescription .formio-component-columns[class*="formio-component-priceRow"] .formio-component-htmlelement :where(p, p span),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel > .card > .card-body .formio-component-feesDescription .formio-component-columns[class*="formio-component-priceRow"] .formio-component-htmlelement :where(p, p span),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel > .card > .card-body .formio-component-feesDescription .formio-component-columns[class*="formio-component-priceRow"] .formio-component-htmlelement :where(p, p span),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel > .card > .card-body .formio-component-feesDescription .formio-component-columns[class*="formio-component-priceRow"] .formio-component-htmlelement :where(p, p span),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel > .card > .card-body .formio-component-feesDescription .formio-component-columns[class*="formio-component-priceRow"] .formio-component-htmlelement :where(p, p span) {
  font-size: 14px !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel.formio-hidden + .formio-component-requiredDocumentsPanel,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel.formio-hidden + .formio-component-requiredDocumentsPanel,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel.formio-hidden + .formio-component-requiredDocumentsPanel,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel.formio-hidden + .formio-component-requiredDocumentsPanel,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel.formio-hidden + .formio-component-requiredDocumentsPanel,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel.formio-hidden + .formio-component-requiredDocumentsPanel {
  margin-top: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel .formio-component-htmlelement:is([class*="price_"]) [ref="html"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel .formio-component-htmlelement:is([class*="price_"]) [ref="html"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel .formio-component-htmlelement:is([class*="price_"]) [ref="html"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel .formio-component-htmlelement:is([class*="price_"]) [ref="html"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel .formio-component-htmlelement:is([class*="price_"]) [ref="html"],
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel .formio-component-htmlelement:is([class*="price_"]) [ref="html"] {
  direction: ltr;
  display: flex;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-requiredDocumentsPanel > .card > .card-body .formio-component-requiredDocumentsContainer,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-requiredDocumentsPanel > .card > .card-body .formio-component-requiredDocumentsContainer,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-requiredDocumentsPanel > .card > .card-body .formio-component-requiredDocumentsContainer,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-requiredDocumentsPanel > .card > .card-body .formio-component-requiredDocumentsContainer,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-requiredDocumentsPanel > .card > .card-body .formio-component-requiredDocumentsContainer,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-requiredDocumentsPanel > .card > .card-body .formio-component-requiredDocumentsContainer {
  border: none;
  margin-top: -1px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-requiredDocumentsPanel > .card > .card-body .formio-component-requiredDocumentsContainer .formio-component-htmlelement,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-requiredDocumentsPanel > .card > .card-body .formio-component-requiredDocumentsContainer .formio-component-htmlelement,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-requiredDocumentsPanel > .card > .card-body .formio-component-requiredDocumentsContainer .formio-component-htmlelement,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-requiredDocumentsPanel > .card > .card-body .formio-component-requiredDocumentsContainer .formio-component-htmlelement,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-requiredDocumentsPanel > .card > .card-body .formio-component-requiredDocumentsContainer .formio-component-htmlelement,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-requiredDocumentsPanel > .card > .card-body .formio-component-requiredDocumentsContainer .formio-component-htmlelement {
  line-height: 1.2;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: solid 1px var(--color-gray-300);
  margin-top: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-requiredDocumentsPanel > .card > .card-body .formio-component-requiredDocumentsContainer .formio-component-htmlelement li div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-requiredDocumentsPanel > .card > .card-body .formio-component-requiredDocumentsContainer .formio-component-htmlelement li div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-requiredDocumentsPanel > .card > .card-body .formio-component-requiredDocumentsContainer .formio-component-htmlelement li div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-requiredDocumentsPanel > .card > .card-body .formio-component-requiredDocumentsContainer .formio-component-htmlelement li div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-requiredDocumentsPanel > .card > .card-body .formio-component-requiredDocumentsContainer .formio-component-htmlelement li div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-requiredDocumentsPanel > .card > .card-body .formio-component-requiredDocumentsContainer .formio-component-htmlelement li div {
  line-height: 1.5;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-requiredDocumentsPanel > .card > .card-body .formio-component-requiredDocumentsContainer .formio-component-htmlelement li div a,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-requiredDocumentsPanel > .card > .card-body .formio-component-requiredDocumentsContainer .formio-component-htmlelement li div a,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-requiredDocumentsPanel > .card > .card-body .formio-component-requiredDocumentsContainer .formio-component-htmlelement li div a,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-requiredDocumentsPanel > .card > .card-body .formio-component-requiredDocumentsContainer .formio-component-htmlelement li div a,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-requiredDocumentsPanel > .card > .card-body .formio-component-requiredDocumentsContainer .formio-component-htmlelement li div a,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-requiredDocumentsPanel > .card > .card-body .formio-component-requiredDocumentsContainer .formio-component-htmlelement li div a {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-requiredDocumentsPanel > .card > .card-body .formio-component-requiredDocumentsContainer .formio-component-htmlelement:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-requiredDocumentsPanel > .card > .card-body .formio-component-requiredDocumentsContainer .formio-component-htmlelement:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-requiredDocumentsPanel > .card > .card-body .formio-component-requiredDocumentsContainer .formio-component-htmlelement:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-requiredDocumentsPanel > .card > .card-body .formio-component-requiredDocumentsContainer .formio-component-htmlelement:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-requiredDocumentsPanel > .card > .card-body .formio-component-requiredDocumentsContainer .formio-component-htmlelement:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-requiredDocumentsPanel > .card > .card-body .formio-component-requiredDocumentsContainer .formio-component-htmlelement:last-child {
  border: none;
  padding: 0;
  margin: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-requiredDocumentsPanel > .card > .card-body .formio-component-requiredDocumentsContainer .formio-component-htmlelement:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-requiredDocumentsPanel > .card > .card-body .formio-component-requiredDocumentsContainer .formio-component-htmlelement:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-requiredDocumentsPanel > .card > .card-body .formio-component-requiredDocumentsContainer .formio-component-htmlelement:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-requiredDocumentsPanel > .card > .card-body .formio-component-requiredDocumentsContainer .formio-component-htmlelement:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-requiredDocumentsPanel > .card > .card-body .formio-component-requiredDocumentsContainer .formio-component-htmlelement:first-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-requiredDocumentsPanel > .card > .card-body .formio-component-requiredDocumentsContainer .formio-component-htmlelement:first-child {
  margin-top: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel.formio-hidden + .formio-component.formio-component-requiredDocumentsPanel:not(.formio-hidden) ~ .formio-component.formio-component-button:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel.formio-hidden + .formio-component.formio-component-requiredDocumentsPanel:not(.formio-hidden) ~ .formio-component.formio-component-button:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel.formio-hidden + .formio-component.formio-component-requiredDocumentsPanel:not(.formio-hidden) ~ .formio-component.formio-component-button:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel.formio-hidden + .formio-component.formio-component-requiredDocumentsPanel:not(.formio-hidden) ~ .formio-component.formio-component-button:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel.formio-hidden + .formio-component.formio-component-requiredDocumentsPanel:not(.formio-hidden) ~ .formio-component.formio-component-button:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel.formio-hidden + .formio-component.formio-component-requiredDocumentsPanel:not(.formio-hidden) ~ .formio-component.formio-component-button:last-child {
  margin-bottom: auto !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel.formio-hidden + .formio-component.formio-component-requiredDocumentsPanel.formio-hidden + .formio-component.formio-component-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel.formio-hidden + .formio-component.formio-component-requiredDocumentsPanel.formio-hidden + .formio-component.formio-component-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel.formio-hidden + .formio-component.formio-component-requiredDocumentsPanel.formio-hidden + .formio-component.formio-component-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel.formio-hidden + .formio-component.formio-component-requiredDocumentsPanel.formio-hidden + .formio-component.formio-component-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel.formio-hidden + .formio-component.formio-component-requiredDocumentsPanel.formio-hidden + .formio-component.formio-component-button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel.formio-hidden + .formio-component.formio-component-requiredDocumentsPanel.formio-hidden + .formio-component.formio-component-button {
  margin-top: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel:not(.formio-hidden) ~ .formio-component.formio-component-button:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel:not(.formio-hidden) ~ .formio-component.formio-component-button:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel:not(.formio-hidden) ~ .formio-component.formio-component-button:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel:not(.formio-hidden) ~ .formio-component.formio-component-button:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel:not(.formio-hidden) ~ .formio-component.formio-component-button:last-child,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"] > .formio-component.formio-component-panel.formio-component-FeesPanel:not(.formio-hidden) ~ .formio-component.formio-component-button:last-child {
  margin-bottom: auto !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"].col-md-8,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"].col-md-8,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"].col-md-8,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"].col-md-8,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"].col-md-8,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"].col-md-8 {
  flex: 2;
  margin-inline-end: 30px;
  align-self: flex-start;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 {
  flex: 1;
  position: sticky;
  top: calc(var(--service-bar-height) + 10px);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 :where(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 :where(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 :where(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 :where(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 :where(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 :where(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) {
  margin-inline: 0px;
  margin-top: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 :where(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 :where(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 :where(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 :where(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 :where(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 :where(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) > .card {
  border: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 :where(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) > .card > .card-header,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 :where(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) > .card > .card-header,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 :where(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) > .card > .card-header,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 :where(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) > .card > .card-header,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 :where(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) > .card > .card-header,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 :where(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) > .card > .card-header {
  padding-inline: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 :where(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) > .card > .card-header > .card-title,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 :where(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) > .card > .card-header > .card-title,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 :where(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) > .card > .card-header > .card-title,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 :where(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) > .card > .card-header > .card-title,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 :where(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) > .card > .card-header > .card-title,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 :where(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) > .card > .card-header > .card-title {
  text-transform: uppercase;
  font-weight: 700 !important;
  color: var(--color-gray-400) !important;
  font-size: 13px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 :where(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) > .card > .card-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 :where(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) > .card > .card-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 :where(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) > .card > .card-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 :where(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) > .card > .card-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 :where(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) > .card > .card-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 :where(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) > .card > .card-body {
  padding: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-registrationsPanel,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-registrationsPanel,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-registrationsPanel,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-registrationsPanel,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-registrationsPanel,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-registrationsPanel {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-registrationsPanel .formio-collapse-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-registrationsPanel .formio-collapse-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-registrationsPanel .formio-collapse-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-registrationsPanel .formio-collapse-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-registrationsPanel .formio-collapse-text,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-registrationsPanel .formio-collapse-text {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-registrationsPanel:not(.formio-hidden) + .formio-component-FeesPanel,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-registrationsPanel:not(.formio-hidden) + .formio-component-FeesPanel,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-registrationsPanel:not(.formio-hidden) + .formio-component-FeesPanel,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-registrationsPanel:not(.formio-hidden) + .formio-component-FeesPanel,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-registrationsPanel:not(.formio-hidden) + .formio-component-FeesPanel,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-registrationsPanel:not(.formio-hidden) + .formio-component-FeesPanel {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top: solid 2px var(--color-gray-75);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-registrationsPanel:not(.formio-hidden) + .formio-component-FeesPanel > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-registrationsPanel:not(.formio-hidden) + .formio-component-FeesPanel > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-registrationsPanel:not(.formio-hidden) + .formio-component-FeesPanel > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-registrationsPanel:not(.formio-hidden) + .formio-component-FeesPanel > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-registrationsPanel:not(.formio-hidden) + .formio-component-FeesPanel > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-registrationsPanel:not(.formio-hidden) + .formio-component-FeesPanel > .card {
  border-radius: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 :is(.formio-component-FeesPanel, .formio-component-requiredDocumentsPanel),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 :is(.formio-component-FeesPanel, .formio-component-requiredDocumentsPanel),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 :is(.formio-component-FeesPanel, .formio-component-requiredDocumentsPanel),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 :is(.formio-component-FeesPanel, .formio-component-requiredDocumentsPanel),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 :is(.formio-component-FeesPanel, .formio-component-requiredDocumentsPanel),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 :is(.formio-component-FeesPanel, .formio-component-requiredDocumentsPanel) {
  margin-top: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-mandatoryRegistrationsPanel,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-mandatoryRegistrationsPanel,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-mandatoryRegistrationsPanel,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-mandatoryRegistrationsPanel,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-mandatoryRegistrationsPanel,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-mandatoryRegistrationsPanel {
  border-radius: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-mandatoryRegistrationsPanel > .card > .card-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-mandatoryRegistrationsPanel > .card > .card-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-mandatoryRegistrationsPanel > .card > .card-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-mandatoryRegistrationsPanel > .card > .card-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-mandatoryRegistrationsPanel > .card > .card-body,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-mandatoryRegistrationsPanel > .card > .card-body {
  border-radius: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4:has(.formio-component-requiredDocumentsPanel.formio-hidden) .formio-component-FeesPanel,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4:has(.formio-component-requiredDocumentsPanel.formio-hidden) .formio-component-FeesPanel,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4:has(.formio-component-requiredDocumentsPanel.formio-hidden) .formio-component-FeesPanel,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4:has(.formio-component-requiredDocumentsPanel.formio-hidden) .formio-component-FeesPanel,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4:has(.formio-component-requiredDocumentsPanel.formio-hidden) .formio-component-FeesPanel,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4:has(.formio-component-requiredDocumentsPanel.formio-hidden) .formio-component-FeesPanel {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top: solid 2px var(--color-gray-75);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4:has(.formio-component-requiredDocumentsPanel:not(.formio-hidden)) .formio-component-FeesPanel,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4:has(.formio-component-requiredDocumentsPanel:not(.formio-hidden)) .formio-component-FeesPanel,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4:has(.formio-component-requiredDocumentsPanel:not(.formio-hidden)) .formio-component-FeesPanel,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4:has(.formio-component-requiredDocumentsPanel:not(.formio-hidden)) .formio-component-FeesPanel,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4:has(.formio-component-requiredDocumentsPanel:not(.formio-hidden)) .formio-component-FeesPanel,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4:has(.formio-component-requiredDocumentsPanel:not(.formio-hidden)) .formio-component-FeesPanel {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-requiredDocumentsPanel:not(.formio-hidden),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-requiredDocumentsPanel:not(.formio-hidden),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-requiredDocumentsPanel:not(.formio-hidden),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-requiredDocumentsPanel:not(.formio-hidden),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-requiredDocumentsPanel:not(.formio-hidden),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"].col-md-8 + .col-md-4 .formio-component-requiredDocumentsPanel:not(.formio-hidden) {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top: solid 2px var(--color-gray-75);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"].col-md-5,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"].col-md-5,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"].col-md-5,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"].col-md-5,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"].col-md-5,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"].col-md-5 {
  flex: 2;
  align-self: flex-start;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"].col-md-3,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"].col-md-3,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"].col-md-3,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"].col-md-3,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"].col-md-3,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"].col-md-3 {
  flex: 1.3;
  position: sticky;
  top: calc(var(--service-bar-height) + 10px);
  align-self: flex-start;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"].col-md-3 + .col-md-4,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"].col-md-3 + .col-md-4,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"].col-md-3 + .col-md-4,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"].col-md-3 + .col-md-4,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"].col-md-3 + .col-md-4,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"].col-md-3 + .col-md-4 {
  flex: 1.3;
  position: sticky;
  top: calc(var(--service-bar-height) + 10px);
  align-self: flex-start;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"].col-md-4::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"].col-md-4::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"].col-md-4::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"].col-md-4::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"].col-md-4::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"].col-md-4::before {
  inset-inline-start: -50px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form .formio-component-requiredDocumentsPanel ~ .formio-component-button button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form .formio-component-requiredDocumentsPanel ~ .formio-component-button button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form .formio-component-requiredDocumentsPanel ~ .formio-component-button button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form .formio-component-requiredDocumentsPanel ~ .formio-component-button button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form .formio-component-requiredDocumentsPanel ~ .formio-component-button button,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form .formio-component-requiredDocumentsPanel ~ .formio-component-button button {
  width: 100%;
}
@media screen and (max-width: 1073px) {
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form [class*="RegistrationsPanel"],
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form [class*="RegistrationsPanel"],
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form [class*="RegistrationsPanel"],
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form [class*="RegistrationsPanel"],
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form [class*="RegistrationsPanel"],
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form [class*="RegistrationsPanel"] {
    margin-inline-end: 0px !important;
  }
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form > div[class^="col-"]:nth-last-of-type(2)::before,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.guide-form > div[class^="col-"]:nth-last-of-type(2)::before,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.guide-form > div[class^="col-"]:nth-last-of-type(2)::before,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component .guide-form > div[class^="col-"]:nth-last-of-type(2)::before,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component .guide-form > div[class^="col-"]:nth-last-of-type(2)::before,
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .guide-form > div[class^="col-"]:nth-last-of-type(2)::before {
    display: none;
  }
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.formio-component-htmlelement:where(.notice, .info-notice, .success-notice, .warning-notice, .error-notice),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.formio-component-htmlelement:where(.notice, .info-notice, .success-notice, .warning-notice, .error-notice),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-htmlelement:where(.notice, .info-notice, .success-notice, .warning-notice, .error-notice) {
  background: rgba(255, 255, 255, 0.7) !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.formio-component-columns.datagrid-custom-footer,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.formio-component-columns.datagrid-custom-footer,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-columns.datagrid-custom-footer {
  margin-top: -56px !important;
  margin-bottom: 49px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.formio-component-editgrid :where(.new-entry, .editing-entry),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.formio-component-editgrid :where(.new-entry, .editing-entry),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid :where(.new-entry, .editing-entry) {
  display: inherit;
  flex-direction: inherit;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.formio-component-editgrid :where(.new-entry, .editing-entry) > .formio-component-panel > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.formio-component-editgrid :where(.new-entry, .editing-entry) > .formio-component-panel > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid :where(.new-entry, .editing-entry) > .formio-component-panel > .card {
  border-radius: 6px !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.formio-component-editgrid :where(.new-entry, .editing-entry) .formio-component-columns.row:not(.vertical-align-bottom, .vertical-align-center),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.formio-component-editgrid :where(.new-entry, .editing-entry) .formio-component-columns.row:not(.vertical-align-bottom, .vertical-align-center),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid :where(.new-entry, .editing-entry) .formio-component-columns.row:not(.vertical-align-bottom, .vertical-align-center) {
  align-items: stretch;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.formio-component-editgrid .new-entry > .formio-component-panel > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.formio-component-editgrid .new-entry > .formio-component-panel > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid .new-entry > .formio-component-panel > .card {
  border: solid 1px var(--color-gray-200);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.formio-component-editgrid .editing-entry > .formio-component-panel > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-form > .formio-component.formio-component-editgrid .editing-entry > .formio-component-panel > .card,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid .editing-entry > .formio-component-panel > .card {
  border: solid 1px var(--color-gray-300);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page {
  padding-top: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form .wizard-page > .formio-component.guide-form {
  margin-top: 0;
  display: flex !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).pagename-off .formio-form > .formio-component.formio-component-panel[class*="roleapplicant"] {
  display: contents;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).pagename-off .formio-form > .formio-component.formio-component-panel[class*="roleapplicant"] > .card {
  display: contents;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).pagename-off .formio-form > .formio-component.formio-component-panel[class*="roleapplicant"] > .card > .card-body:only-child {
  display: contents;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).pagename-off .formio-form > .formio-component.formio-component-panel[class*="roleapplicant"] > .card > .card-body:only-child > .formio-component {
  margin-top: 30px !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).pagename-off .wizard-page {
  padding-top: 36px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).pagename-off > .formio-component-form {
  margin-top: calc(30px * 2);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form {
  margin-top: 30px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-panel.payment-form-component .formio-component-columns {
  margin-top: 0;
  align-items: center;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-panel.payment-form-component .formio-component-columns[class*="cost"] .formio-component-htmlelement {
  margin: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-panel.payment-form-component .formio-component-columns[class*="cost"] .formio-component-htmlelement:first-child {
  margin: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-panel.payment-form-component .formio-component-columns[class*="cost"] .formio-component-htmlelement p {
  margin-bottom: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-panel.payment-form-component .formio-component-columns.formio-component-totalPrice .formio-component-htmlelement:first-child {
  margin: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-panel.payment-form-component .formio-component-columns.formio-component-totalPrice .formio-component-htmlelement h4 {
  font-weight: 600;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-panel.payment-form-component .formio-component-columns.formio-component-totalPrice .formio-component-htmlelement p span {
  font-weight: 600;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-panel.payment-form-component ~ .formio-component.formio-component-columns.formio-component-conclusionRow {
  padding-inline-start: 0 !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-panel .formio-component.formio-component-columns.default-pay-form-payments .radio .form-check {
  padding-top: 0;
  cursor: pointer;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-panel .formio-component.formio-component-columns.default-pay-form-payments .formio-component.formio-component-htmlelement {
  display: flex;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-panel .formio-component.formio-component-columns.default-pay-form-payments .formio-component.formio-component-htmlelement .tile-text {
  display: flex;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-panel .formio-component.formio-component-columns.default-pay-form-payments .formio-component.formio-component-htmlelement .tile-text label {
  margin-bottom: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-panel .formio-component.formio-component-columns.default-pay-form-payments > [class*="col-"] {
  display: flex;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-panel .formio-component.formio-component-columns.overlay-simple {
  margin-top: 0;
  min-height: 30px;
  align-items: center;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-panel .formio-component.formio-component-columns.overlay-simple .col-md-1 {
  flex: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-panel .formio-component.formio-component-columns.overlay-simple .col-md-1 .formio-component-checkbox .form-check.checkbox {
  padding-top: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-panel .formio-component.formio-component-columns.overlay-simple .col-md-1 .formio-component-checkbox .form-check.checkbox label.form-check-label {
  width: 14px !important;
  height: 14px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-panel .formio-component.formio-component-columns.overlay-simple .col-md-1 .formio-component-checkbox .form-check.checkbox label.form-check-label input.form-check-input {
  margin: 0;
  opacity: 1;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-panel .formio-component.formio-component-columns.overlay-simple .col-md-11 {
  flex: auto;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-panel.formio-component-applicantPaymentFees .formio-component-columns {
  margin-top: 0;
  align-items: center;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-panel.formio-component-applicantPaymentFees .formio-component-columns .formio-component-htmlelement {
  margin-top: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-panel.formio-component-applicantPaymentFees .formio-component-columns.formio-component-totalPrice .formio-component-htmlelement:first-child {
  margin: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-panel.formio-component-applicantPaymentFees .formio-component-columns.formio-component-totalPrice .formio-component-htmlelement h4 {
  font-weight: 600;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-panel.formio-component-applicantPaymentFees .formio-component-columns.formio-component-totalPrice .formio-component-htmlelement p span {
  font-weight: 600;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-panel.formio-component-applicantPaymentPayments > .card > .card-body > .formio-component-columns {
  padding-bottom: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-panel.formio-component-applicantPaymentPayments > .card > .card-body > .formio-component-columns .formio-component-checkbox .checkbox {
  margin-top: -5px;
  padding-top: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-panel.formio-component-applicantPaymentPayments > .card > .card-body > .formio-component-columns .formio-component-checkbox .checkbox .form-check-label {
  margin-top: 0;
  cursor: pointer;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-panel.formio-component-applicantPaymentPayments > .card > .card-body > .formio-component-columns .formio-component-checkbox .checkbox .form-check-label input[type="radio"] {
  appearance: none;
  margin-top: 0;
  cursor: pointer;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-panel.formio-component-applicantPaymentPayments > .card > .card-body > .formio-component-columns .formio-component-checkbox .checkbox .form-check-label input[type="radio"]::before {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  font-size: 16px;
  content: "\f111";
  color: rgba(70, 81, 170, 0.5);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-panel.formio-component-applicantPaymentPayments > .card > .card-body > .formio-component-columns .formio-component-checkbox .checkbox .form-check-label input[type="radio"]:checked::before {
  content: "\f192";
  font-weight: 900;
  color: var(--color-primary-600);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-panel.formio-component-applicantPaymentPayments > .card > .card-body > .formio-component-columns .formio-component-checkbox .checkbox .form-check-label input[type="radio"]:focus {
  outline: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-panel.formio-component-applicantPaymentPayments > .card > .card-body > .formio-component-columns .formio-component-checkbox .checkbox .form-check-label input[type="radio"]:hover::before {
  color: var(--color-primary-600);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-panel.formio-component-applicantPaymentPayments + .formio-component-button button.btn {
  border-radius: 6px !important;
  background-color: var(--color-primary-600);
  padding-inline-start: 25px;
  padding-inline-end: 25px;
  font-weight: 600;
  text-transform: none;
  position: relative;
  z-index: 1;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-panel.formio-component-applicantPaymentPayments + .formio-component-button button.btn:hover {
  box-shadow: 0 9px 20px rgba(70, 81, 170, 0.4) !important;
  opacity: 0.9;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-columns.default-pay-form-payments + .overlay-simple {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-part-b-documents-tab + .formio-component {
  margin-top: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-part-b-documents-tab ~ .formio-component-panel .card {
  background-color: transparent;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-part-b-documents-tab ~ .formio-component-panel .card .card-header {
  padding: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) > .formio-component-form > .formio-form .formio-component.formio-component-part-b-documents-tab ~ .formio-component-panel .card .card-body {
  padding-top: 2.5rem !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) > .alert.alert-success {
  position: fixed !important;
  top: 19px;
  inset-inline-end: 0;
  z-index: 99999;
  width: auto;
  max-width: 320px;
  background: #1C8C6E !important;
  color: white !important;
  padding: 1rem 2rem !important;
  font-size: 16px !important;
  inset-inline-end: 16px;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) > .alert.alert-success::after {
  display: none !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) > .alert.alert-danger {
  --alert-color: 247, 95, 95;
  bottom: auto;
  top: 156px;
  z-index: 99999;
  transition-duration: 0.2s;
  padding-inline-start: 24px !important;
  border-color: rgb(var(--alert-color)) !important;
  box-shadow: 0 5px 20px rgba(var(--alert-color), 0.4);
  inset-inline-end: 2.5%;
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  max-width: 360px;
  flex-direction: row-reverse;
  justify-content: space-between;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) > .alert.alert-danger button.close-button {
  color: transparent;
  position: relative;
  top: -10px;
  inset-inline-end: -10px;
  border-radius: 6px;
  height: 36px;
  float: none;
  width: 40px;
  padding: 0;
  text-align: center;
  flex: 0 0 40px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) > .alert.alert-danger button.close-button::before {
  font-family: var(--fontawesome-font);
  font-weight: 300;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  content: "\F00D";
  color: var(--color-gray-800);
  position: absolute;
  font-size: 16px;
  top: calc(50% - 8px);
  width: 14px;
  inset-inline-start: calc(50% - 7px);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) > .alert.alert-danger button.close-button:hover {
  background: rgba(70, 81, 170, 0.1);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) > .alert.alert-danger p {
  font-weight: 600;
  color: rgb(var(--alert-color));
  line-height: 1.3 !important;
  flex: 0 0 calc(100% - 40px);
  margin-bottom: 12px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) > .alert.alert-danger ul {
  padding-inline-start: 14px;
  overflow-y: auto;
  width: 100%;
  padding-inline-end: 16px;
  max-height: min(calc(100vh - 200px), 320px);
  margin-top: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) > .alert.alert-danger ul li {
  margin-bottom: 8px;
  font-weight: 500;
  transition-duration: 0.15s;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) > .alert.alert-danger ul li:last-child {
  margin-bottom: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) > .alert.alert-danger ul li:hover {
  padding-inline-start: 0;
  background: no-repeat;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) > .alert.alert-danger ul li:hover::marker {
  content: "\f0a9";
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) > .alert.alert-danger ul li:hover span::after {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) > .alert.alert-danger ul li span {
  margin-inline-start: 8px;
  text-decoration: none;
  display: block;
  color: var(--color-gray-900);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) > .alert.alert-danger ul li span:hover {
  text-decoration: underline;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) > .alert.alert-danger ul li::marker {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: var(--fontawesome-font);
  font-weight: 400;
  content: "\f06a";
  color: rgb(var(--alert-color));
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) > .alert.alert-danger ul::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) > .alert.alert-danger ul::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(var(--alert-color), 0.8);
  transition-duration: 0.15s;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) > .alert.alert-danger ul::-webkit-scrollbar-track {
  background-color: rgba(var(--alert-color), 0.3);
}
@media screen and (max-width: 1200px) {
  body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) > .alert.alert-danger {
    inset-inline-end: 2.4rem;
  }
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .tooltip .tooltip-inner {
  max-width: 240px;
  white-space: break-spaces;
  width: max-content;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .part-b-processing-tab .formio-component-modal-wrapper {
  margin-bottom: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .part-b-processing-tab .formio-component-modal-wrapper > [ref="openModalWrapper"] button.btn {
  --button-bg-color: var(--color-primary-600);
  --button-secondary-bg-color: rgba(70, 81, 170, 0.1);
  --button-text-color: white;
  --button-text-color-hover: white;
  --button-outline-bg-color: transparent;
  background-color: var(--button-bg-color);
  font-weight: 500;
  color: var(--button-text-color);
  text-transform: none;
  position: relative;
  z-index: 1;
  line-height: 1;
  text-align: center;
  gap: 8px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .part-b-processing-tab .formio-component-modal-wrapper > [ref="openModalWrapper"] button.btn .fa-cog {
  position: absolute;
  animation: none;
  background: inherit;
  width: 100%;
  z-index: 9;
  color: transparent;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .part-b-processing-tab .formio-component-modal-wrapper > [ref="openModalWrapper"] button.btn .fa-cog + span {
  position: absolute !important;
  top: -7px;
  z-index: 99;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
  color: #ffffff;
  font-size: 10px;
  margin: 0 auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .part-b-processing-tab .formio-component-modal-wrapper > [ref="openModalWrapper"] button.btn .fa-cog + span::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .part-b-processing-tab .formio-component-modal-wrapper > [ref="openModalWrapper"] button.btn .fa-cog + span::before {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
  content: '';
  position: absolute;
  top: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .part-b-processing-tab .formio-component-modal-wrapper > [ref="openModalWrapper"] button.btn .fa-cog + span::before {
  inset-inline-start: -16px;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .part-b-processing-tab .formio-component-modal-wrapper > [ref="openModalWrapper"] button.btn .fa-cog + span::after {
  inset-inline-start: 16px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .part-b-processing-tab .formio-component-modal-wrapper > [ref="openModalWrapper"] button.btn:hover {
  --button-shadow: var(--button-shadow-hover);
  opacity: 0.9;
  color: var(--button-text-color-hover);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .part-b-processing-tab .formio-component-modal-wrapper > [ref="openModalWrapper"] button.btn .fa-question-circle {
  color: white;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .part-b-processing-tab .formio-component-modal-wrapper > [ref="openModalWrapper"] button.btn .fa-question-circle::before {
  color: inherit;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .part-b-processing-tab .formio-component-modal-wrapper > [ref="openModalWrapper"] button.btn:is(.btn-secondary, .light-color) {
  --button-text-color: var(--color-gray-800);
  --button-text-color-hover: var(--color-gray-800);
  background-color: var(--button-secondary-bg-color);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .part-b-processing-tab .formio-component-modal-wrapper > [ref="openModalWrapper"] button.btn.outline-button {
  --button-text-color: var(--button-bg-color);
  --button-text-color-hover: var(--button-bg-color);
  --button-border-width: 1px;
  --button-border-color: var(--button-bg-color);
  background-color: var(--button-outline-bg-color);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .part-b-processing-tab .formio-component-modal-wrapper > [ref="openModalWrapper"] button.btn.outline-button:is(.btn-secondary, .light-color) {
  --button-border-color: var(--button-secondary-bg-color);
  --status-secondary-outline-border-color: rgba(70, 81, 170, 0.5);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .part-b-processing-tab .formio-component-modal-wrapper > [ref="modalWrapper"] .formio-dialog-close {
  padding: 0 !important;
  box-shadow: none !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .part-b-processing-tab .formio-component-modal-wrapper > [ref="modalWrapper"] [ref="modalContents"] > .formio-component.formio-component-panel:first-child {
  margin: 0;
  --wrapper-panel-margin: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .part-b-processing-tab .formio-component-modal-wrapper > [ref="modalWrapper"] [ref="modalContents"] > .formio-component.formio-component-panel:first-child > .card {
  background-color: transparent !important;
  margin-bottom: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .part-b-processing-tab .formio-component-modal-wrapper > [ref="modalWrapper"] [ref="modalContents"] > .formio-component.formio-component-panel:first-child > .card .card-header {
  padding: 0 0 30px;
  border-bottom: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .part-b-processing-tab .formio-component-modal-wrapper > [ref="modalWrapper"] [ref="modalContents"] > .formio-component.formio-component-panel:first-child > .card .card-body {
  padding: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .part-b-processing-tab .formio-component-modal-wrapper > [ref="modalWrapper"] [ref="modalContents"] > .formio-component.formio-component-panel:first-child > .card .card-body .formio-component-select[class*="back"] .field-wrapper {
  display: block;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .part-b-processing-tab .formio-component-modal-wrapper > [ref="modalWrapper"] [ref="modalContents"] > .formio-component.formio-component-panel:first-child > .card .card-body .formio-component-select[class*="back"] .field-wrapper label.col-form-label {
  display: block;
  margin-bottom: 8px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .part-b-processing-tab .formio-component-modal-wrapper > [ref="modalWrapper"] [ref="modalContents"] > .formio-component.formio-component-panel:first-child > .card .card-body .formio-component-conclusionRow {
  margin-bottom: 0 !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .part-b-processing-tab .formio-component-modal-wrapper > [ref="modalWrapper"] [ref="modalContents"] > .formio-component.formio-component-panel:first-child > .card .card-body [class*="formio-component-conclusionRow"] .col-md-9 {
  flex: auto;
  width: initial;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .part-b-processing-tab .formio-component-modal-wrapper > [ref="modalWrapper"] [ref="modalContents"] > .formio-component.formio-component-panel:first-child > .card .card-body [class*="formio-component-conclusionRow"] .col-md-3 {
  min-width: fit-content;
  width: initial;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .part-b-processing-tab .formio-component-modal-wrapper > [ref="modalWrapper"] [ref="modalContents"] > .formio-component.formio-component-panel:first-child > .card .card-body [class*="formio-component-conclusionRow"] .formio-errors {
  width: 100%;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .part-b-processing-tab .formio-component-modal-wrapper > [ref="modalWrapper"] [ref="modalContents"] > .formio-component.formio-component-panel:first-child > .card::after {
  display: none !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .part-b-processing-tab .formio-component-modal-wrapper > [ref="modalWrapper"] [ref="modalContents"] > .formio-dialog-buttons {
  display: none;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .part-b-processing-tab .formio-component-tabs .formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary) {
  --custom-sibling-panel-top-margin: 0;
  --custom-panel-top-margin: 0;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .part-b-processing-tab .formio-component-tabs .formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary) > .card {
  margin-inline: 0 !important;
  padding: 28px !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .part-b-processing-tab .formio-component-tabs .formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary) > .card::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .part-b-processing-tab .formio-component-tabs .formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary) > .card::after {
  display: none !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .part-b-processing-tab .readonly-forms-block {
  margin-inline: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .part-b-processing-tab .readonly-forms-block .formio-component-panel {
  margin-inline: 0px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form nav ul.pagination li span.page-link {
  color: var(--wizard-page-link-text-color, #333);
  background-color: var(--wizard-page-link-bg-color, transparent);
  font-size: var(--wizard-page-link-text-size, 24px);
  font-weight: var(--wizard-page-link-text-weight, 500);
  padding: var(--wizard-page-link-block-padding, 8px) var(--wizard-page-link-inline-padding, 28px);
  border-radius: var(--wizard-page-link-border-radius);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form nav ul.pagination li span.page-link::before {
  font-size: 24px !important;
  border: none !important;
  font-weight: 500 !important;
  color: var(--color-primary-600);
  line-height: 22px !important;
  margin-inline-end: 4px !important;
  color: var(--color-gray-400) !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form nav ul.pagination li span.page-link.progress-complete::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: var(--fontawesome-font);
  font-weight: 400;
  color: var(--color-primary-600) !important;
  content: "\F058" !important;
  font-size: 21px !important;
  margin-inline-end: 9px !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form nav ul.pagination li span.page-link svg {
  display: none !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-form nav ul.pagination li:is(:hover, .active) span.page-link {
  color: var(--wizard-page-link-active-text-color, #000);
  background-color: var(--wizard-page-link-active-bg-color, #CCC);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-component label.col-form-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .wizard-page .formio-component label.col-form-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component label.col-form-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component label.col-form-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component label.col-form-label {
  font-size: var(--label-font-size, 14px) !important;
  margin-bottom: var(--label-bottom-margin, 10px) !important;
  color: var(--label-text-color, var(--color-gray-600)) !important;
  font-weight: 500 !important;
  font-family: var(--main-font-family, 'Open Sans', sans-serif);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-component input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .wizard-page .formio-component input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component input.form-control,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component input.form-control {
  letter-spacing: 0 !important;
  font-size: var(--field-font-size);
  font-weight: var(--field-font-weight, 400) !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-component.formio-component-editgrid > button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .wizard-page .formio-component.formio-component-editgrid > button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid > button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid > button.btn,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid > button.btn {
  background-color: rgba(70, 81, 170, 0.1);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-component.formio-component-editgrid > button.btn:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .wizard-page .formio-component.formio-component-editgrid > button.btn:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid > button.btn:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid > button.btn:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid > button.btn:hover {
  background-color: var(--color-primary-600);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-component.formio-component-editgrid .list-group .list-group-item > .row:not(.formio-component-columns) > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .wizard-page .formio-component.formio-component-editgrid .list-group .list-group-item > .row:not(.formio-component-columns) > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-editgrid .list-group .list-group-item > .row:not(.formio-component-columns) > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-editgrid .list-group .list-group-item > .row:not(.formio-component-columns) > div,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-editgrid .list-group .list-group-item > .row:not(.formio-component-columns) > div {
  padding-bottom: 10px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-component.formio-component-datagrid > table.table.datagrid-table :is(th, td):is(.checkbox, .radio, .button),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .wizard-page .formio-component.formio-component-datagrid > table.table.datagrid-table :is(th, td):is(.checkbox, .radio, .button),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid > table.table.datagrid-table :is(th, td):is(.checkbox, .radio, .button),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid > table.table.datagrid-table :is(th, td):is(.checkbox, .radio, .button),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid > table.table.datagrid-table :is(th, td):is(.checkbox, .radio, .button) {
  display: table-cell;
  box-shadow: none !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-component.formio-component-datagrid > table.table.datagrid-table td.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .wizard-page .formio-component.formio-component-datagrid > table.table.datagrid-table td.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid > table.table.datagrid-table td.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid > table.table.datagrid-table td.checkbox,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid > table.table.datagrid-table td.checkbox {
  padding-inline: 28px !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-component.formio-component-datagrid > table.table.datagrid-table tfoot tr td button.btn.formio-button-add-row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .wizard-page .formio-component.formio-component-datagrid > table.table.datagrid-table tfoot tr td button.btn.formio-button-add-row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid > table.table.datagrid-table tfoot tr td button.btn.formio-button-add-row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid > table.table.datagrid-table tfoot tr td button.btn.formio-button-add-row,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid > table.table.datagrid-table tfoot tr td button.btn.formio-button-add-row {
  background-color: rgba(70, 81, 170, 0.1);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-component.formio-component-datagrid > table.table.datagrid-table tfoot tr td button.btn.formio-button-add-row:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .wizard-page .formio-component.formio-component-datagrid > table.table.datagrid-table tfoot tr td button.btn.formio-button-add-row:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-datagrid > table.table.datagrid-table tfoot tr td button.btn.formio-button-add-row:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-datagrid > table.table.datagrid-table tfoot tr td button.btn.formio-button-add-row:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-datagrid > table.table.datagrid-table tfoot tr td button.btn.formio-button-add-row:hover {
  background-color: var(--color-primary-600);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-component.formio-component-checkbox .form-check.checkbox label.form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .wizard-page .formio-component.formio-component-checkbox .form-check.checkbox label.form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-checkbox .form-check.checkbox label.form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-checkbox .form-check.checkbox label.form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-checkbox .form-check.checkbox label.form-check-label span {
  font-size: 16px;
  color: var(--color-gray-800);
  font-weight: 400;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-component.formio-component-checkbox .form-check.checkbox label.form-check-label input.form-check-input:checked + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .wizard-page .formio-component.formio-component-checkbox .form-check.checkbox label.form-check-label input.form-check-input:checked + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-checkbox .form-check.checkbox label.form-check-label input.form-check-input:checked + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-checkbox .form-check.checkbox label.form-check-label input.form-check-input:checked + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-checkbox .form-check.checkbox label.form-check-label input.form-check-input:checked + span {
  color: var(--color-gray-800);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-component.formio-component-checkbox .form-check.checkbox label.form-check-label input.form-check-input:checked + span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .wizard-page .formio-component.formio-component-checkbox .form-check.checkbox label.form-check-label input.form-check-input:checked + span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-checkbox .form-check.checkbox label.form-check-label input.form-check-input:checked + span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-checkbox .form-check.checkbox label.form-check-label input.form-check-input:checked + span::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-checkbox .form-check.checkbox label.form-check-label input.form-check-input:checked + span::before {
  color: var(--color-primary-600) !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-component.formio-component-checkbox .form-check-input:disabled,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .wizard-page .formio-component.formio-component-checkbox .form-check-input:disabled,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-checkbox .form-check-input:disabled,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-checkbox .form-check-input:disabled,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-checkbox .form-check-input:disabled {
  background-color: transparent;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio label.form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .wizard-page .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio label.form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio label.form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio label.form-check-label span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio label.form-check-label span {
  font-size: 16px;
  font-weight: 500;
  color: rgba(70, 81, 170, 0.5);
  margin-inline-start: 6px;
  position: relative;
  top: 2px;
  line-height: 14px;
  display: block;
  margin-block: -3px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio label.form-check-label input.form-check-input[type="radio"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .wizard-page .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio label.form-check-label input.form-check-input[type="radio"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio label.form-check-label input.form-check-input[type="radio"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio label.form-check-label input.form-check-input[type="radio"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio label.form-check-label input.form-check-input[type="radio"]::before {
  font-size: 16px;
  content: "\f111";
  color: rgba(70, 81, 170, 0.5);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio label.form-check-label input.form-check-input[type="radio"]:disabled,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .wizard-page .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio label.form-check-label input.form-check-input[type="radio"]:disabled,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio label.form-check-label input.form-check-input[type="radio"]:disabled,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio label.form-check-label input.form-check-input[type="radio"]:disabled,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio label.form-check-label input.form-check-input[type="radio"]:disabled {
  background: transparent !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio label.form-check-label input.form-check-input[type="radio"]:disabled + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .wizard-page .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio label.form-check-label input.form-check-input[type="radio"]:disabled + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio label.form-check-label input.form-check-input[type="radio"]:disabled + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio label.form-check-label input.form-check-input[type="radio"]:disabled + span,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio label.form-check-label input.form-check-input[type="radio"]:disabled + span {
  pointer-events: none;
  color: var(--color-gray-500) !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio label.form-check-label:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .wizard-page .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio label.form-check-label:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio label.form-check-label:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio label.form-check-label:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio label.form-check-label:hover {
  color: var(--color-primary-600);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .form-check.radio-selected label.form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .wizard-page .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .form-check.radio-selected label.form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .form-check.radio-selected label.form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .form-check.radio-selected label.form-check-label,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .form-check.radio-selected label.form-check-label {
  color: var(--color-primary-600);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .form-check.radio-selected label.form-check-label :is(span, input, input::before),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .wizard-page .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .form-check.radio-selected label.form-check-label :is(span, input, input::before),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .form-check.radio-selected label.form-check-label :is(span, input, input::before),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .form-check.radio-selected label.form-check-label :is(span, input, input::before),
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-radio:not(.radio-switch) .form-radio.radio .form-check.radio-selected label.form-check-label :is(span, input, input::before) {
  color: inherit !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-component.formio-component-panel > .card > .card-header > .card-title,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .wizard-page .formio-component.formio-component-panel > .card > .card-header > .card-title,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-panel > .card > .card-header > .card-title,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-panel > .card > .card-header > .card-title,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-panel > .card > .card-header > .card-title {
  color: var(--color-gray-800) !important;
  font-weight: 600 !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-component.formio-component-select span.key,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .wizard-page .formio-component.formio-component-select span.key,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select span.key,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select span.key,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select span.key {
  margin-inline-end: 8px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-component.formio-component-select span.value,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .wizard-page .formio-component.formio-component-select span.value,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select span.value,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select span.value,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select span.value {
  font-size: 16px;
  letter-spacing: 0;
  font-weight: var(--field-font-weight);
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-component.formio-component-select.value-label-style span.value[ref="tooltip"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .wizard-page .formio-component.formio-component-select.value-label-style span.value[ref="tooltip"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select.value-label-style span.value[ref="tooltip"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select.value-label-style span.value[ref="tooltip"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select.value-label-style span.value[ref="tooltip"]::before,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-component.formio-component-select.value-label-style span.value[ref="tooltip"]::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .wizard-page .formio-component.formio-component-select.value-label-style span.value[ref="tooltip"]::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component.formio-component-select.value-label-style span.value[ref="tooltip"]::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component.formio-component-select.value-label-style span.value[ref="tooltip"]::after,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component.formio-component-select.value-label-style span.value[ref="tooltip"]::after {
  display: none !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-component button.btn.formio-button-add-another,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .wizard-page .formio-component button.btn.formio-button-add-another,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component button.btn.formio-button-add-another,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component button.btn.formio-button-add-another,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component button.btn.formio-button-add-another {
  border: solid 1px var(--color-gray-800) !important;
  color: var(--color-gray-800) !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]):not(.new-formio-part-b) .formio-component button.btn.formio-button-add-another:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .wizard-page .formio-component button.btn.formio-button-add-another:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component button.btn.formio-button-add-another:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]).new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component button.btn.formio-button-add-another:hover,
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content .formio-component button.btn.formio-button-add-another:hover {
  color: white !important;
  background-color: var(--color-gray-800) !important;
  box-shadow: 0 9px 20px rgba(27, 35, 49, 0.4) !important;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) .formio-dialog-content {
  background-color: white;
  padding: 24px;
  border-radius: 6px;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) > .modal-edit-row > .formio-dialog {
  place-content: center;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) > .modal-edit-row > .formio-dialog .formio-dialog-close {
  background-color: white;
}
body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) > .modal-edit-row > .formio-dialog .formio-dialog-buttons > button.btn.formio-dialog-button {
  --button-bg-color: var(--color-primary-600);
  --button-text-color: white;
  --button-text-color-hover: white;
  background-color: var(--button-bg-color);
  font-weight: 500;
  color: var(--button-text-color);
  padding-inline: var(--md-button-inline-padding);
  padding-block: var(--md-button-block-padding);
  font-size: var(--md-button-font-size);
  min-height: var(--md-button-height);
  border-radius: var(--md-button-border-radius);
  line-height: 1;
  margin-top: 24px;
  text-transform: initial;
}
body.modern-theme > [class*="editgrid-row-modal-"] > .formio-dialog {
  place-content: center;
}
body.modern-theme > [class*="editgrid-row-modal-"] .formio-dialog-content > button.btn.btn-primary {
  --button-bg-color: var(--color-primary-600);
  --button-text-color: white;
  --button-text-color-hover: white;
  background-color: var(--button-bg-color);
  font-weight: 500;
  color: var(--button-text-color);
  padding-inline: var(--md-button-inline-padding);
  padding-block: var(--md-button-block-padding);
  font-size: var(--md-button-font-size);
  min-height: var(--md-button-height);
  border-radius: var(--md-button-border-radius);
  line-height: 1;
  margin-top: 24px;
  margin-inline-start: auto;
  display: flex;
}
body.modern-theme > [class*="editgrid-row-modal-confirmation"] .formio-dialog-content {
  width: fit-content;
}
body.modern-theme > [class*="editgrid-row-modal-confirmation"] .formio-dialog-content [ref="dialogHeader"] {
  padding-inline-end: 35px;
  margin-bottom: 24px;
}
body.modern-theme > [class*="editgrid-row-modal-confirmation"] .formio-dialog-content [ref="dialogCancelButton"] + [ref="dialogYesButton"] {
  margin-inline-start: 10px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel,
body.modern-theme .formio-component-panel {
  margin-bottom: 0px;
  margin-inline: 0px;
  --panel-bg-color: white;
  background-color: var(--panel-bg-color);
  border-radius: 6px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel > .card,
body.modern-theme .formio-component-panel > .card {
  box-shadow: none;
  background-color: transparent;
  border-radius: 6px;
  margin-bottom: 0;
  border: solid 1px var(--color-gray-200);
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel > .card > .card-header,
body.modern-theme .formio-component-panel > .card > .card-header {
  padding-top: 24px;
  padding-bottom: 24px;
  padding-inline-start: 24px;
  padding-inline-end: 24px;
  border-bottom: solid 2px var(--color-gray-75);
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel > .card > .card-header:has(.formio-collapse-icon),
body.modern-theme .formio-component-panel > .card > .card-header:has(.formio-collapse-icon) {
  cursor: pointer;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel > .card > .card-body,
body.modern-theme .formio-component-panel > .card > .card-body {
  padding-top: 24px;
  padding-bottom: 24px;
  padding-inline-start: 24px;
  padding-inline-end: 24px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .card-header[aria-expanded="false"],
body.modern-theme .formio-component-panel .card-header[aria-expanded="false"] {
  border-bottom: none;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .card-header[aria-expanded="false"] .formio-collapse-icon::before,
body.modern-theme .formio-component-panel .card-header[aria-expanded="false"] .formio-collapse-icon::before {
  content: "\F107";
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .card-title,
body.modern-theme .formio-component-panel .card-title {
  font-size: 20px;
  font-weight: 600;
  color: var(--color-gray-800);
  min-height: 20px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .card-title:has(.formio-collapse-text),
body.modern-theme .formio-component-panel .card-title:has(.formio-collapse-text) {
  display: grid;
  grid-template-columns: 1fr auto 13px;
  gap: 8px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .card-title i.formio-collapse-icon,
body.modern-theme .formio-component-panel .card-title i.formio-collapse-icon {
  font-weight: 400;
  margin: 0;
  color: var(--main-text-color);
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .card-title i.formio-collapse-icon::before,
body.modern-theme .formio-component-panel .card-title i.formio-collapse-icon::before {
  content: "\F106";
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .card-title i.fa-question-circle,
body.modern-theme .formio-component-panel .card-title i.fa-question-circle {
  position: relative;
  margin-inline-start: 8px;
  display: flex;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-collapse-text,
body.modern-theme .formio-component-panel .formio-collapse-text {
  font-size: 16px;
  font-weight: normal;
  margin-inline-end: 24px;
  margin-inline-start: auto;
}
@media screen and (max-width: 480px) {
  body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-collapse-text,
  body.modern-theme .formio-component-panel .formio-collapse-text {
    display: none;
  }
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel.highlight-block > .card,
body.modern-theme .formio-component-panel.highlight-block > .card {
  --highlight-border-color: #4CAAE5;
  outline: solid 1px var(--highlight-border-color);
  box-shadow: 0px 0px 20px rgba(76, 170, 229, 0.2);
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel.highlight-block > .card > .card-header,
body.modern-theme .formio-component-panel.highlight-block > .card > .card-header {
  border-bottom: solid 1px var(--highlight-border-color, var(--color-gray-75));
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel.highlight-block > .card > .card-header > .card-title,
body.modern-theme .formio-component-panel.highlight-block > .card > .card-header > .card-title {
  color: var(--highlight-border-color);
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel.highlight-block > .card > .card-header:last-child,
body.modern-theme .formio-component-panel.highlight-block > .card > .card-header:last-child {
  border-bottom: none;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel.background-gray > .card > .card-header,
body.modern-theme .formio-component-panel.background-gray > .card > .card-header {
  border-bottom-color: var(--color-gray-100);
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel:is(.block-header-gray, .block-header-blue, .block-header-green, .block-header-orange, .block-header-red, .block-header-white, .block-header-primary),
body.modern-theme .formio-component-panel:is(.block-header-gray, .block-header-blue, .block-header-green, .block-header-orange, .block-header-red, .block-header-white, .block-header-primary) {
  --header-bgcolor: white;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel:is(.block-header-gray, .block-header-blue, .block-header-green, .block-header-orange, .block-header-red, .block-header-white, .block-header-primary) > .card > .card-header,
body.modern-theme .formio-component-panel:is(.block-header-gray, .block-header-blue, .block-header-green, .block-header-orange, .block-header-red, .block-header-white, .block-header-primary) > .card > .card-header {
  border-radius: 6px 6px 0 0;
  background-color: var(--header-bgcolor);
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel.block-header-gray,
body.modern-theme .formio-component-panel.block-header-gray {
  --header-bgcolor: var(--color-gray-75);
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel.block-header-blue,
body.modern-theme .formio-component-panel.block-header-blue {
  --header-bgcolor: var(--color-blue-50);
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel.block-header-green,
body.modern-theme .formio-component-panel.block-header-green {
  --header-bgcolor: var(--color-green-50);
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel.block-header-orange,
body.modern-theme .formio-component-panel.block-header-orange {
  --header-bgcolor: var(--color-orange-50);
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel.block-header-red,
body.modern-theme .formio-component-panel.block-header-red {
  --header-bgcolor: var(--color-red-50);
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel.block-header-white,
body.modern-theme .formio-component-panel.block-header-white {
  --header-bgcolor: white;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel.padding-off > .card.border,
body.modern-theme .formio-component-panel.padding-off > .card.border {
  border: none;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel.padding-off > .card.border > .card-body,
body.modern-theme .formio-component-panel.padding-off > .card.border > .card-body {
  padding-bottom: 0px;
  padding-inline: 0px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel.padding-off > .card.border > .card-body:only-child,
body.modern-theme .formio-component-panel.padding-off > .card.border > .card-body:only-child {
  padding-top: 0px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel.padding-off > .card.border > .card-header,
body.modern-theme .formio-component-panel.padding-off > .card.border > .card-header {
  padding-top: 0px;
  padding-inline: 0px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel.padding-off > .card.border > .card-header[aria-expanded="false"],
body.modern-theme .formio-component-panel.padding-off > .card.border > .card-header[aria-expanded="false"] {
  padding-bottom: 0px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel.mini-block > .card > .card-header,
body.modern-theme .formio-component-panel.mini-block > .card > .card-header {
  padding-top: calc(24px / 2);
  padding-bottom: calc(24px / 2);
  padding-inline-start: calc(24px / 2);
  padding-inline-end: calc(24px / 2);
  border-bottom: solid 2px var(--color-gray-75);
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel.mini-block > .card > .card-header .card-title,
body.modern-theme .formio-component-panel.mini-block > .card > .card-header .card-title {
  font-size: calc(20px - 4px);
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel.mini-block > .card > .card-body,
body.modern-theme .formio-component-panel.mini-block > .card > .card-body {
  padding-top: calc(24px / 2);
  padding-bottom: calc(24px / 2);
  padding-inline-start: calc(24px / 2);
  padding-inline-end: calc(24px / 2);
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel.mini-block > .card > .card-body .formio-component-panel:not(.root-block, .formio-hidden, .hide),
body.modern-theme .formio-component-panel.mini-block > .card > .card-body .formio-component-panel:not(.root-block, .formio-hidden, .hide) {
  margin-inline: -12px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel.mini-block > .card > .card-body .formio-component-panel:not(.root-block, .formio-hidden, .hide) > .card > .card-header .card-title,
body.modern-theme .formio-component-panel.mini-block > .card > .card-body .formio-component-panel:not(.root-block, .formio-hidden, .hide) > .card > .card-header .card-title {
  font-size: calc(20px - 4px);
}
body.modern-theme .formio-component-panel:where(.formio-component-FeesPanel, .formio-component-requiredDocumentsPanel) .formio-collapse-text {
  display: none;
}
body.modern-theme .formio-component-panel:where(.formio-component-FeesPanel, .formio-component-requiredDocumentsPanel) .card-title span:not(.title-text, .title-total, .title-total-amount, .currency-suffixed, .currency-prefixed) {
  font-size: 13px;
  margin-inline: 0px;
}
body.modern-theme .formio-component-panel:where(.formio-component-FeesPanel, .formio-component-requiredDocumentsPanel) .title-text {
  margin-inline-end: 6px;
}
body.modern-theme .formio-component-panel:where(.formio-component-FeesPanel, .formio-component-requiredDocumentsPanel) .title-total {
  display: flex;
  align-items: baseline;
  direction: ltr;
}
body.modern-theme .formio-component-panel:where(.formio-component-FeesPanel, .formio-component-requiredDocumentsPanel) .title-total .currency-suffixed {
  margin-inline-start: 4px;
}
body.modern-theme .formio-component-panel:where(.formio-component-FeesPanel, .formio-component-requiredDocumentsPanel) .title-total .currency-prefixed {
  margin-inline-end: 4px;
}
body.modern-theme .formio-component-panel:where(.formio-component-FeesPanel, .formio-component-requiredDocumentsPanel) .title-count {
  font-size: 11px;
  min-width: 17px;
  height: 17px;
  padding: 2px;
  background-color: var(--color-gray-400);
  color: white;
  font-weight: 700;
  border-radius: 10px;
  text-align: center;
  margin-inline: 6px;
}
body.modern-theme .formio-component-panel.documents-tab-wrapping-panel > .card > .card-body {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
body.modern-theme .formio-component-panel.documents-tab-wrapping-panel > .card > .card-body::after,
body.modern-theme .formio-component-panel.documents-tab-wrapping-panel > .card > .card-body::before {
  display: none;
}
body.modern-theme .formio-component-panel.documents-tab-wrapping-panel .formio-component-panel {
  margin-top: 0 !important;
  margin-bottom: 0;
  margin-inline: 0;
  min-width: 260px;
  flex-grow: 1;
  flex-basis: calc(50% - 2rem);
}
body.modern-theme .formio-component-panel.documents-tab-wrapping-panel .formio-component-panel:first-child {
  margin-top: 0;
}
body.modern-theme .formio-component-panel.documents-tab-wrapping-panel .formio-component-panel:last-child {
  margin-bottom: 0;
}
body.modern-theme .formio-component-panel.documents-tab-wrapping-panel .formio-component-panel > .card {
  border: none;
}
body.modern-theme .formio-component-panel.documents-tab-wrapping-panel .formio-component-panel .card-body {
  padding: 0;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] {
  background-color: transparent;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] > .card > .card-header {
  border-bottom: none;
  padding-top: 0px;
  padding-bottom: 24px;
  padding-inline-start: 0px;
  padding-inline-end: 0px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] > .card > .card-body {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-inline-start: 0px;
  padding-inline-end: 0px;
}
body.modern-theme .formio-component-panel.container-block {
  margin-inline: 0px;
}
body.modern-theme .formio-component-panel.container-block > .card {
  border-top: none !important;
}
body.modern-theme .formio-component-panel.container-block > .card > .card-header {
  border-bottom: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
}
body.modern-theme .formio-component-panel.container-block > .card > .card-header > .card-title {
  min-height: 0;
}
body.modern-theme .formio-component-panel.container-block > .card > .card-header > .card-title :is(.formio-collapse-text, .formio-collapse-icon) {
  display: none;
}
body.modern-theme .formio-component-panel.container-block > .card .card-body {
  padding-top: 0;
  padding-bottom: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
}
body.modern-theme .formio-component-panel.readonly-forms-block .card > .card-body,
body.modern-theme .formio-component-panel.readonly-forms-block .formio-component-panel .card > .card-body,
body.modern-theme .formio-component-panel.readonly-forms-block ~ .formio-component-panel .card > .card-body {
  padding-top: 10px;
  padding-inline: 0px;
  padding-bottom: 0px;
}
body.modern-theme .formio-component-panel:is(.review-documents-container, .review-certificates-container) .card-body {
  padding: 0px;
}
body.modern-theme .formio-component-panel.block-border > .card {
  border: solid 1px var(--color-gray-200) !important;
}
body.modern-theme [role="tabpanel"] > .formio-component-panel > .card,
body.modern-theme [role="tabpanel"] > .formio-component-columns > [class*="col-"] > .formio-component-panel:not(.formio-component-label-hidden) > .card {
  border: solid 1px var(--color-gray-200);
}
body.modern-theme .formio-component-editgrid .new-entry > .formio-component-panel > .card {
  border: solid 1px var(--color-gray-200);
}
body.modern-theme .formio-component-editgrid .editing-entry > .formio-component-panel > .card {
  border: solid 1px var(--color-gray-300) !important;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.root-block, .formio-hidden, .hide),
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.root-block, .formio-hidden, .hide),
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.root-block, .formio-hidden, .hide) {
  margin-inline: -24px;
  border-radius: 0px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.root-block, .formio-hidden, .hide) > .card,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.root-block, .formio-hidden, .hide) > .card,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.root-block, .formio-hidden, .hide) > .card {
  border-top: solid 4px var(--color-gray-75);
  border-bottom: solid 4px var(--color-gray-75);
  border-inline: none;
  border-radius: 0px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.root-block, .formio-hidden, .hide) > .card > .card-header,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.root-block, .formio-hidden, .hide) > .card > .card-header,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.root-block, .formio-hidden, .hide) > .card > .card-header {
  border-bottom: none;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.root-block, .formio-hidden, .hide) > .card > .card-header[aria-expanded="true"],
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.root-block, .formio-hidden, .hide) > .card > .card-header[aria-expanded="true"],
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.root-block, .formio-hidden, .hide) > .card > .card-header[aria-expanded="true"] {
  padding-bottom: 0px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.root-block, .formio-hidden, .hide) > .card > .card-header :where(.formio-collapse-text, .formio-collapse-icon),
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.root-block, .formio-hidden, .hide) > .card > .card-header :where(.formio-collapse-text, .formio-collapse-icon),
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.root-block, .formio-hidden, .hide) > .card > .card-header :where(.formio-collapse-text, .formio-collapse-icon) {
  font-size: 14px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.root-block, .formio-hidden, .hide) .card-title,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.root-block, .formio-hidden, .hide) .card-title,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.root-block, .formio-hidden, .hide) .card-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.root-block, .formio-hidden, .hide) + .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow),
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.root-block, .formio-hidden, .hide) + .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow),
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.root-block, .formio-hidden, .hide) + .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow),
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.root-block, .formio-hidden, .hide).is-adjacent:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow),
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.root-block, .formio-hidden, .hide).is-adjacent:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow),
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.root-block, .formio-hidden, .hide).is-adjacent:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) {
  margin-top: 0px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.root-block, .formio-hidden, .hide) + .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) > .card,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.root-block, .formio-hidden, .hide) + .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) > .card,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.root-block, .formio-hidden, .hide) + .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) > .card,
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.root-block, .formio-hidden, .hide).is-adjacent:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) > .card,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.root-block, .formio-hidden, .hide).is-adjacent:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) > .card,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.root-block, .formio-hidden, .hide).is-adjacent:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) > .card {
  border-top: none;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.root-block, .formio-hidden, .hide):is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white).padding-off > .card.border,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.root-block, .formio-hidden, .hide):is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white).padding-off > .card.border,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.root-block, .formio-hidden, .hide):is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white).padding-off > .card.border {
  border: none;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.root-block, .formio-hidden, .hide):is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white).padding-off > .card.border > .card-body,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.root-block, .formio-hidden, .hide):is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white).padding-off > .card.border > .card-body,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.root-block, .formio-hidden, .hide):is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white).padding-off > .card.border > .card-body {
  padding: 0px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.root-block, .formio-hidden, .hide, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow):is(:first-child, .is-first) > .card,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.root-block, .formio-hidden, .hide, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow):is(:first-child, .is-first) > .card,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.root-block, .formio-hidden, .hide, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow):is(:first-child, .is-first) > .card {
  border-top: none;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.root-block, .formio-hidden, .hide, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow):is(:first-child, .is-first) > .card > .card-header,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.root-block, .formio-hidden, .hide, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow):is(:first-child, .is-first) > .card > .card-header,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.root-block, .formio-hidden, .hide, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow):is(:first-child, .is-first) > .card > .card-header {
  padding-top: 0px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.root-block, .formio-hidden, .hide, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow):is(:first-child, .is-first) > .card > .card-body:only-child,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.root-block, .formio-hidden, .hide, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow):is(:first-child, .is-first) > .card > .card-body:only-child,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.root-block, .formio-hidden, .hide, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow):is(:first-child, .is-first) > .card > .card-body:only-child {
  padding-top: 0;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.root-block, .formio-hidden, .hide, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow):is(:last-child, .is-last) > .card,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.root-block, .formio-hidden, .hide, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow):is(:last-child, .is-last) > .card,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.root-block, .formio-hidden, .hide, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow):is(:last-child, .is-last) > .card {
  border-bottom: none;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.root-block, .formio-hidden, .hide, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow):is(:last-child, .is-last) > .card > .card-body,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.root-block, .formio-hidden, .hide, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow):is(:last-child, .is-last) > .card > .card-body,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.root-block, .formio-hidden, .hide, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow):is(:last-child, .is-last) > .card > .card-body {
  padding-bottom: 0px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.root-block, .formio-hidden, .hide, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow):is(:last-child, .is-last) > .card > .card-header[aria-expanded="false"],
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.root-block, .formio-hidden, .hide, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow):is(:last-child, .is-last) > .card > .card-header[aria-expanded="false"],
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.root-block, .formio-hidden, .hide, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow):is(:last-child, .is-last) > .card > .card-header[aria-expanded="false"] {
  padding-bottom: 0;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-columns [class^="col-"]:first-child > .formio-component-panel,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-columns [class^="col-"]:first-child > .formio-component-panel,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-columns [class^="col-"]:first-child > .formio-component-panel {
  border-bottom-left-radius: 6px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-columns [class^="col-"] > .formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white):first-child,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-columns [class^="col-"] > .formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white):first-child,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-columns [class^="col-"] > .formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white):first-child {
  margin-top: 0px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-columns [class^="col-"] > .formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white):last-child,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-columns [class^="col-"] > .formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white):last-child,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-columns [class^="col-"] > .formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white):last-child {
  margin-bottom: 0px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-columns .formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white),
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-columns .formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white),
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-columns .formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) {
  margin-inline: 0px;
  margin-bottom: 0px;
  border-radius: 6px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-columns .formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) > .card,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-columns .formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) > .card,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-columns .formio-component-panel:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) > .card {
  border-top: none;
  border-bottom: none;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component-panel:not(.formio-hidden, .hide),
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component-panel:not(.formio-hidden, .hide),
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component-panel:not(.formio-hidden, .hide) {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component-panel:not(.formio-hidden, .hide) > .card,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component-panel:not(.formio-hidden, .hide) > .card,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component-panel:not(.formio-hidden, .hide) > .card {
  border-top: none;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component-panel:not(.formio-hidden, .hide) > .card > .card-header,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component-panel:not(.formio-hidden, .hide) > .card > .card-header,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component-panel:not(.formio-hidden, .hide) > .card > .card-header {
  padding-top: 0px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component-panel:not(.formio-hidden, .hide) ~ .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow),
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component-panel:not(.formio-hidden, .hide) ~ .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow),
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component-panel:not(.formio-hidden, .hide) ~ .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) {
  margin-top: 30px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component-panel:not(.formio-hidden, .hide) ~ .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) > .card,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component-panel:not(.formio-hidden, .hide) ~ .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) > .card,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component-panel:not(.formio-hidden, .hide) ~ .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) > .card {
  border-top: solid 4px var(--color-gray-75);
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component-panel:not(.formio-hidden, .hide) ~ .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) > .card > .card-header,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component-panel:not(.formio-hidden, .hide) ~ .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) > .card > .card-header,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component-panel:not(.formio-hidden, .hide) ~ .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) > .card > .card-header {
  padding-top: 24px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component-panel:not(.formio-hidden, .hide) ~ .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) + .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white),
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component-panel:not(.formio-hidden, .hide) ~ .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) + .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white),
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component-panel:not(.formio-hidden, .hide) ~ .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) + .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white),
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component-panel:not(.formio-hidden, .hide) ~ .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow).is-adjacent:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow),
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component-panel:not(.formio-hidden, .hide) ~ .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow).is-adjacent:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow),
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component-panel:not(.formio-hidden, .hide) ~ .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow).is-adjacent:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) {
  margin-top: 0px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component-panel:not(.formio-hidden, .hide) ~ .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) + .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) > .card,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component-panel:not(.formio-hidden, .hide) ~ .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) + .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) > .card,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component-panel:not(.formio-hidden, .hide) ~ .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) + .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) > .card,
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component-panel:not(.formio-hidden, .hide) ~ .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow).is-adjacent:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) > .card,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component-panel:not(.formio-hidden, .hide) ~ .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow).is-adjacent:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) > .card,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component-panel:not(.formio-hidden, .hide) ~ .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow).is-adjacent:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) > .card {
  border-top: none;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component-panel:not(.formio-hidden, .hide) + .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow),
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component-panel:not(.formio-hidden, .hide) + .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow),
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component-panel:not(.formio-hidden, .hide) + .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) {
  margin-top: 0px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component-panel:not(.formio-hidden, .hide) + .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) > .card,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component-panel:not(.formio-hidden, .hide) + .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) > .card,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component-panel:not(.formio-hidden, .hide) + .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) > .card {
  border-top: none;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component:not(.formio-component-panel, .formio-hidden, .hide) ~ .formio-component-panel:not(.formio-hidden, .hide) > .card,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component:not(.formio-component-panel, .formio-hidden, .hide) ~ .formio-component-panel:not(.formio-hidden, .hide) > .card,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component:not(.formio-component-panel, .formio-hidden, .hide) ~ .formio-component-panel:not(.formio-hidden, .hide) > .card {
  border-top: solid 4px var(--color-gray-75);
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component:not(.formio-component-panel, .formio-hidden, .hide) ~ .formio-component-panel:not(.formio-hidden, .hide) > .card > .card-header,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component:not(.formio-component-panel, .formio-hidden, .hide) ~ .formio-component-panel:not(.formio-hidden, .hide) > .card > .card-header,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) :is(.formio-hidden, .hide, .formio-component-hidden):first-child ~ .formio-component:not(.formio-component-panel, .formio-hidden, .hide) ~ .formio-component-panel:not(.formio-hidden, .hide) > .card > .card-header {
  padding-top: 24px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component:not(.formio-hidden, .hide, .formio-component-hidden, .formio-component-panel) + .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) > .card,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component:not(.formio-hidden, .hide, .formio-component-hidden, .formio-component-panel) + .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) > .card,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component:not(.formio-hidden, .hide, .formio-component-hidden, .formio-component-panel) + .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) > .card {
  border-top: solid 4px var(--color-gray-75);
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.formio-hidden, .hide):first-child ~ :is(.formio-hidden, .hide, .formio-component-hidden) ~ .formio-component-panel,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.formio-hidden, .hide):first-child ~ :is(.formio-hidden, .hide, .formio-component-hidden) ~ .formio-component-panel,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.formio-hidden, .hide):first-child ~ :is(.formio-hidden, .hide, .formio-component-hidden) ~ .formio-component-panel {
  margin-top: 0px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.formio-hidden, .hide):first-child ~ :is(.formio-hidden, .hide, .formio-component-hidden) ~ .formio-component-panel > .card,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.formio-hidden, .hide):first-child ~ :is(.formio-hidden, .hide, .formio-component-hidden) ~ .formio-component-panel > .card,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.formio-hidden, .hide):first-child ~ :is(.formio-hidden, .hide, .formio-component-hidden) ~ .formio-component-panel > .card {
  border-top: none;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.formio-hidden, .hide):first-child ~ :is(.formio-hidden, .hide, .formio-component-hidden) ~ .formio-component-panel ~ .formio-component-panel,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.formio-hidden, .hide):first-child ~ :is(.formio-hidden, .hide, .formio-component-hidden) ~ .formio-component-panel ~ .formio-component-panel,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.formio-hidden, .hide):first-child ~ :is(.formio-hidden, .hide, .formio-component-hidden) ~ .formio-component-panel ~ .formio-component-panel {
  margin-top: 30px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.formio-hidden, .hide):first-child ~ :is(.formio-hidden, .hide, .formio-component-hidden) ~ .formio-component-panel + .formio-component-panel:not(.readonly-forms-block),
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.formio-hidden, .hide):first-child ~ :is(.formio-hidden, .hide, .formio-component-hidden) ~ .formio-component-panel + .formio-component-panel:not(.readonly-forms-block),
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.formio-hidden, .hide):first-child ~ :is(.formio-hidden, .hide, .formio-component-hidden) ~ .formio-component-panel + .formio-component-panel:not(.readonly-forms-block) {
  margin-top: 0px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.formio-hidden, .hide):first-child ~ :is(.formio-hidden, .hide, .formio-component-hidden) ~ .formio-component-panel + .formio-component-panel:not(.readonly-forms-block) > .card,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.formio-hidden, .hide):first-child ~ :is(.formio-hidden, .hide, .formio-component-hidden) ~ .formio-component-panel + .formio-component-panel:not(.readonly-forms-block) > .card,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.formio-hidden, .hide):first-child ~ :is(.formio-hidden, .hide, .formio-component-hidden) ~ .formio-component-panel + .formio-component-panel:not(.readonly-forms-block) > .card {
  border-top: none;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.formio-hidden, .hide) + :is(.formio-hidden, .hide, .formio-component-hidden) ~ .formio-component-panel,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.formio-hidden, .hide) + :is(.formio-hidden, .hide, .formio-component-hidden) ~ .formio-component-panel,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.formio-hidden, .hide) + :is(.formio-hidden, .hide, .formio-component-hidden) ~ .formio-component-panel {
  margin-top: 0px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.formio-hidden, .hide) + :is(.formio-hidden, .hide, .formio-component-hidden) ~ .formio-component-panel > .card,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.formio-hidden, .hide) + :is(.formio-hidden, .hide, .formio-component-hidden) ~ .formio-component-panel > .card,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.formio-hidden, .hide) + :is(.formio-hidden, .hide, .formio-component-hidden) ~ .formio-component-panel > .card {
  border-top: none;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.formio-hidden, .hide) + .formio-component-panel:not(.formio-hidden, .hide, .root-block),
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.formio-hidden, .hide) + .formio-component-panel:not(.formio-hidden, .hide, .root-block),
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.formio-hidden, .hide) + .formio-component-panel:not(.formio-hidden, .hide, .root-block) {
  margin-top: 0px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.formio-hidden, .hide) + .formio-component-panel:not(.formio-hidden, .hide, .root-block) > .card,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.formio-hidden, .hide) + .formio-component-panel:not(.formio-hidden, .hide, .root-block) > .card,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.formio-hidden, .hide) + .formio-component-panel:not(.formio-hidden, .hide, .root-block) > .card {
  border-top: none;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.root-block).container-block,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.root-block).container-block,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.root-block).container-block {
  margin-inline: 0px;
  margin-bottom: 0px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.root-block).container-block > .card,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.root-block).container-block > .card,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.root-block).container-block > .card {
  border-top: none;
  border-bottom: none;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.root-block).container-block > .card > .card-header,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.root-block).container-block > .card > .card-header,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.root-block).container-block > .card > .card-header {
  border-bottom: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.root-block).container-block > .card > .card-header > .card-title,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.root-block).container-block > .card > .card-header > .card-title,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.root-block).container-block > .card > .card-header > .card-title {
  min-height: 0;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.root-block).container-block > .card > .card-header > .card-title :is(.formio-collapse-text, .formio-collapse-icon),
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.root-block).container-block > .card > .card-header > .card-title :is(.formio-collapse-text, .formio-collapse-icon),
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.root-block).container-block > .card > .card-header > .card-title :is(.formio-collapse-text, .formio-collapse-icon) {
  display: none;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.root-block).container-block > .card .card-body,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.root-block).container-block > .card .card-body,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.root-block).container-block > .card .card-body {
  padding-top: 0;
  padding-bottom: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.root-block).review-documents-container,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.root-block).review-documents-container,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.root-block).review-documents-container {
  margin-inline: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.root-block).review-documents-container > .card > .card-body,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.root-block).review-documents-container > .card > .card-body,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.root-block).review-documents-container > .card > .card-body {
  padding: 0px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.root-block):is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow),
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.root-block):is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow),
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.root-block):is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) {
  margin-inline: 0;
  border-radius: 6px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.root-block):is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) > .card,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.root-block):is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) > .card,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.root-block):is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) > .card {
  border-radius: 6px;
  border-top: none;
  border-bottom: none;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.root-block).block-border > .card,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.root-block).block-border > .card,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.root-block).block-border > .card {
  border: solid 1px var(--color-gray-200) !important;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel:not(.root-block).block-shadow,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel:not(.root-block).block-shadow,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel:not(.root-block).block-shadow {
  box-shadow: 0 2px 40px rgba(0, 0, 0, 0.08);
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel.formio-component-feesDescription,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel.formio-component-feesDescription,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel.formio-component-feesDescription {
  margin-inline: 0px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-panel.formio-component-feesDescription .card-body,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-panel.formio-component-feesDescription .card-body,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-panel.formio-component-feesDescription .card-body {
  padding: 0;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel tr + tr .formio-component-panel:not(.root-block).review-documents-container,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel tr + tr .formio-component-panel:not(.root-block).review-documents-container,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) tr + tr .formio-component-panel:not(.root-block).review-documents-container {
  margin-top: 30px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-datagrid.formio-component-documentsTabDocuments td > .formio-component-panel:not(.root-block),
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-datagrid.formio-component-documentsTabDocuments td > .formio-component-panel:not(.root-block),
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-datagrid.formio-component-documentsTabDocuments td > .formio-component-panel:not(.root-block) {
  margin-inline: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-datagrid.formio-component-documentsTabDocuments td > .formio-component-panel:not(.root-block) > .card > .card-body,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-datagrid.formio-component-documentsTabDocuments td > .formio-component-panel:not(.root-block) > .card > .card-body,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-datagrid.formio-component-documentsTabDocuments td > .formio-component-panel:not(.root-block) > .card > .card-body {
  padding: 0px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-datagrid.formio-component-documentsTabDocuments tr + tr td > .formio-component-panel:not(.root-block),
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-datagrid.formio-component-documentsTabDocuments tr + tr td > .formio-component-panel:not(.root-block),
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-datagrid.formio-component-documentsTabDocuments tr + tr td > .formio-component-panel:not(.root-block) {
  margin-top: 30px;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel :is(.formio-component-part-b-documents-tab, .formio-component-part-b-data-tab) ~ .formio-component-panel,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel :is(.formio-component-part-b-documents-tab, .formio-component-part-b-data-tab) ~ .formio-component-panel,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) :is(.formio-component-part-b-documents-tab, .formio-component-part-b-data-tab) ~ .formio-component-panel,
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel :is(.formio-component-part-b-documents-tab, .formio-component-part-b-data-tab) ~ :is(.formio-component-container, .formio-component-panel) .formio-component-panel,
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel :is(.formio-component-part-b-documents-tab, .formio-component-part-b-data-tab) ~ :is(.formio-component-container, .formio-component-panel) .formio-component-panel,
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) :is(.formio-component-part-b-documents-tab, .formio-component-part-b-data-tab) ~ :is(.formio-component-container, .formio-component-panel) .formio-component-panel {
  margin-inline: 0px;
  margin-bottom: 0px !important;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel :is(.formio-component-part-b-documents-tab, .formio-component-part-b-data-tab) ~ .formio-component-panel:not(.margin-top-zero, .top-space-2x, .top-space-3x, .top-label-space),
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel :is(.formio-component-part-b-documents-tab, .formio-component-part-b-data-tab) ~ .formio-component-panel:not(.margin-top-zero, .top-space-2x, .top-space-3x, .top-label-space),
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) :is(.formio-component-part-b-documents-tab, .formio-component-part-b-data-tab) ~ .formio-component-panel:not(.margin-top-zero, .top-space-2x, .top-space-3x, .top-label-space),
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel :is(.formio-component-part-b-documents-tab, .formio-component-part-b-data-tab) ~ :is(.formio-component-container, .formio-component-panel) .formio-component-panel:not(.margin-top-zero, .top-space-2x, .top-space-3x, .top-label-space),
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel :is(.formio-component-part-b-documents-tab, .formio-component-part-b-data-tab) ~ :is(.formio-component-container, .formio-component-panel) .formio-component-panel:not(.margin-top-zero, .top-space-2x, .top-space-3x, .top-label-space),
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) :is(.formio-component-part-b-documents-tab, .formio-component-part-b-data-tab) ~ :is(.formio-component-container, .formio-component-panel) .formio-component-panel:not(.margin-top-zero, .top-space-2x, .top-space-3x, .top-label-space) {
  margin-top: 0;
}
body.modern-theme .formio-component-panel[class*="formio-component-tab"] .formio-component-panel .formio-component-previewcontainer:has(.readonly-forms-block) .formio-component-panel:not(.root-block, .formio-hidden, .hide),
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-component-previewcontainer:has(.readonly-forms-block) .formio-component-panel:not(.root-block, .formio-hidden, .hide),
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-previewcontainer:has(.readonly-forms-block) .formio-component-panel:not(.root-block, .formio-hidden, .hide) {
  margin-inline: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}
body.modern-theme .formio-component-tabs .formio-component-panel:not(.root-block) {
  padding-inline: 0px;
}
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > [class="row"] + .formio-component-panel {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > [class="row"] + .formio-component-panel > .card > .card-body:only-child {
  padding-top: 0px;
}
body.modern-theme .formio-component-editgrid :is(.new-entry, .editing-entry) > [class="row"] + .formio-component-panel > .card > .card-body > .formio-component-panel:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
body.modern-theme .formio-component-panel > .card.mb-2 {
  margin-bottom: 0px !important;
}
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel {
  margin-bottom: 0px;
  margin-inline: 0px;
  --panel-bg-color: white;
  background-color: var(--panel-bg-color);
  border-radius: 6px;
}
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel > .card {
  box-shadow: none;
  background-color: transparent;
  border-radius: 6px;
  margin-bottom: 0;
  border: solid 1px var(--color-gray-200);
}
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel > .card > .card-header {
  padding-top: 24px;
  padding-bottom: 24px;
  padding-inline-start: 24px;
  padding-inline-end: 24px;
  border-bottom: solid 2px var(--color-gray-75);
}
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel > .card > .card-header:has(.formio-collapse-icon) {
  cursor: pointer;
}
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel > .card > .card-body {
  padding-top: 24px;
  padding-bottom: 24px;
  padding-inline-start: 24px;
  padding-inline-end: 24px;
}
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .card-header[aria-expanded="false"] {
  border-bottom: none;
}
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .card-header[aria-expanded="false"] .formio-collapse-icon::before {
  content: "\F107";
}
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .card-title {
  font-size: 20px;
  font-weight: 600;
  color: var(--color-gray-800);
  min-height: 20px;
}
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .card-title:has(.formio-collapse-text) {
  display: grid;
  grid-template-columns: 1fr auto 13px;
  gap: 8px;
}
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .card-title i.formio-collapse-icon {
  font-weight: 400;
  margin: 0;
  color: var(--main-text-color);
}
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .card-title i.formio-collapse-icon::before {
  content: "\F106";
}
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .card-title i.fa-question-circle {
  position: relative;
  margin-inline-start: 8px;
  display: flex;
}
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-collapse-text {
  font-size: 16px;
  font-weight: normal;
  margin-inline-end: 24px;
  margin-inline-start: auto;
}
@media screen and (max-width: 480px) {
  body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel .formio-collapse-text {
    display: none;
  }
}
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel.highlight-block > .card {
  --highlight-border-color: #4CAAE5;
  outline: solid 1px var(--highlight-border-color);
  box-shadow: 0px 0px 20px rgba(76, 170, 229, 0.2);
}
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel.highlight-block > .card > .card-header {
  border-bottom: solid 1px var(--highlight-border-color, var(--color-gray-75));
}
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel.highlight-block > .card > .card-header > .card-title {
  color: var(--highlight-border-color);
}
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel.highlight-block > .card > .card-header:last-child {
  border-bottom: none;
}
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel.background-gray > .card > .card-header {
  border-bottom-color: var(--color-gray-100);
}
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel:is(.block-header-gray, .block-header-blue, .block-header-green, .block-header-orange, .block-header-red, .block-header-white, .block-header-primary) {
  --header-bgcolor: white;
}
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel:is(.block-header-gray, .block-header-blue, .block-header-green, .block-header-orange, .block-header-red, .block-header-white, .block-header-primary) > .card > .card-header {
  border-radius: 6px 6px 0 0;
  background-color: var(--header-bgcolor);
}
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel.block-header-gray {
  --header-bgcolor: var(--color-gray-75);
}
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel.block-header-blue {
  --header-bgcolor: var(--color-blue-50);
}
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel.block-header-green {
  --header-bgcolor: var(--color-green-50);
}
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel.block-header-orange {
  --header-bgcolor: var(--color-orange-50);
}
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel.block-header-red {
  --header-bgcolor: var(--color-red-50);
}
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel.block-header-white {
  --header-bgcolor: white;
}
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel.padding-off > .card.border {
  border: none;
}
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel.padding-off > .card.border > .card-body {
  padding-bottom: 0px;
  padding-inline: 0px;
}
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel.padding-off > .card.border > .card-body:only-child {
  padding-top: 0px;
}
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel.padding-off > .card.border > .card-header {
  padding-top: 0px;
  padding-inline: 0px;
}
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel.padding-off > .card.border > .card-header[aria-expanded="false"] {
  padding-bottom: 0px;
}
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel.mini-block > .card > .card-header {
  padding-top: calc(24px / 2);
  padding-bottom: calc(24px / 2);
  padding-inline-start: calc(24px / 2);
  padding-inline-end: calc(24px / 2);
  border-bottom: solid 2px var(--color-gray-75);
}
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel.mini-block > .card > .card-header .card-title {
  font-size: calc(20px - 4px);
}
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel.mini-block > .card > .card-body {
  padding-top: calc(24px / 2);
  padding-bottom: calc(24px / 2);
  padding-inline-start: calc(24px / 2);
  padding-inline-end: calc(24px / 2);
}
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel.mini-block > .card > .card-body .formio-component-panel:not(.root-block, .formio-hidden, .hide) {
  margin-inline: -12px;
}
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel.mini-block > .card > .card-body .formio-component-panel:not(.root-block, .formio-hidden, .hide) > .card > .card-header .card-title {
  font-size: calc(20px - 4px);
}
body.modern-theme .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel + .formio-component-panel {
  margin-top: 30px;
}
body.modern-theme .formio-component-tabs {
  --validation-tab-dot-display: inline-block;
  --tab-pill-top-border-style: none;
  --tab-pill-right-border-style: none;
  --tab-pill-bottom-border-style: none;
  --tab-pill-left-border-style: none;
  --tab-pill-active-top-border-style: none;
  --tab-pill-active-right-border-style: none;
  --tab-pill-active-bottom-border-style: none;
  --tab-pill-active-left-border-style: none;
  --tab-pill-top-border-width: 0px;
  --tab-pill-right-border-width: 0px;
  --tab-pill-bottom-border-width: 0px;
  --tab-pill-left-border-width: 0px;
  --tab-pill-active-top-border-width: 0px;
  --tab-pill-active-right-border-width: 0px;
  --tab-pill-active-bottom-border-width: 0px;
  --tab-pill-active-left-border-width: 0px;
  --tab-pill-top-border-color: transparent;
  --tab-pill-right-border-color: transparent;
  --tab-pill-bottom-border-color: transparent;
  --tab-pill-left-border-color: transparent;
  --tab-pill-active-top-border-color: transparent;
  --tab-pill-active-right-border-color: transparent;
  --tab-pill-active-bottom-border-color: transparent;
  --tab-pill-active-left-border-color: transparent;
  --tab-pill-active-bg: white;
  --tab-body-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  --tab-body-bg-color: #FFFFFF;
  --tab-body-border-radius: 6px;
  --tab-body-top-padding: 24px;
  --tab-body-right-padding: 24px;
  --tab-body-bottom-padding: 24px;
  --tab-body-left-padding: 24px;
}
body.modern-theme .formio-component-tabs.vertical-tabs {
  --tab-layout: row;
  --tab-heading-body-gap: 24px;
  --tab-pills-layout: column;
}
body.modern-theme .formio-component-tabs.underline-style {
  --tab-pill-active-shadow: none;
  --tab-body-shadow: none;
  --tab-pill-active-bottom-border-style: solid;
  --tab-pill-active-bottom-border-width: 2px;
  --tab-pill-active-bottom-border-color: var(--color-gray-900);
  --tab-body-border-radius: 0px;
  --tab-body-bg-color: transparent;
  --tab-body-top-padding: 24px;
  --tab-body-right-padding: 0px;
  --tab-body-bottom-padding: 0px;
  --tab-body-left-padding: 0px;
}
body.modern-theme .formio-component-tabs.underline-style > .card {
  --tab-pill-active-bg: transparent;
  --tab-pill-font-weight: 400;
}
body.modern-theme .formio-component-tabs.tab-style {
  --tab-pill-active-shadow: 0px 16px 20px rgba(0, 0, 0, 0.03);
  --tab-body-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  --tab-pill-active-bottom-border-style: none;
  --tab-pill-active-bottom-border-width: 0px;
  --tab-pill-active-bottom-border-color: transparent;
  --tab-body-border-radius: 6px;
  --tab-body-bg-color: #FFFFFF;
  --tab-body-top-padding: calc(24px + 24px / 2);
  --tab-body-right-padding: 24px;
  --tab-body-bottom-padding: 24px;
  --tab-body-left-padding: 24px;
}
body.modern-theme .formio-component-tabs.tab-style > .card {
  --tab-pill-font-weight: 600;
}
body.modern-theme .formio-component-tabs.affix-tab-header > .card.card-vertical > .card-header {
  display: block;
}
body.modern-theme .formio-component-tabs.affix-tab-header > .card.card-vertical > .card-header .tab-expand-collapse-button {
  float: inline-end;
  height: calc(100% - 9px - var(--x-top));
  margin-top: 9px;
}
body.modern-theme .formio-component-tabs.affix-tab-header > .card.card-vertical > .card-header :is(.tab-expand-collapse-button, .nav-tabs-vertical) {
  top: var(--x-top);
}
body.modern-theme .formio-component-tabs > .card {
  --tab-layout: column;
  --tab-pills-layout: row;
  --tab-heading-body-gap: 0px;
  --tab-pill-font-size: 20px;
  --tab-pill-font-weight: 600;
  --tab-pill-text-color: var(--color-gray-400);
  --tab-pill-bg: transparent;
  --tab-pill-shadow: none;
  --tab-pill-active-text-color: var(--color-gray-900);
  --tab-pill-active-bg: #FFFFFF;
  --tab-pill-active-shadow: 0px 16px 20px rgba(0, 0, 0, 0.03);
  --tab-pill-top-left-radius: calc(6px + 2px);
  --tab-pill-top-right-radius: calc(6px + 2px);
  --tab-pill-bottom-left-radius: 0px;
  --tab-pill-bottom-right-radius: 0px;
  --tab-pill-top-padding: 22px;
  --tab-pill-right-padding: 24px;
  --tab-pill-bottom-padding: 20px;
  --tab-pill-left-padding: 24px;
  --tab-pill-text-align: center;
  background-color: transparent;
  box-shadow: none;
  border-radius: 6px !important;
  padding: 0px;
  display: flex;
  flex-direction: var(--tab-layout, column);
  gap: var(--tab-heading-body-gap, 0px);
}
body.modern-theme .formio-component-tabs > .card.card-vertical {
  --tab-layout: row;
  --tab-pills-layout: column;
  --tab-heading-body-gap: 24px;
  --tab-pill-top-padding: 10px;
  --tab-pill-right-padding: 16px;
  --tab-pill-bottom-padding: 10px;
  --tab-pill-left-padding: 16px;
  --tab-pill-text-align: start;
  --tab-pill-top-left-radius: 4px;
  --tab-pill-top-right-radius: 4px;
  --tab-pill-bottom-left-radius: 4px;
  --tab-pill-bottom-right-radius: 4px;
  --tab-pill-font-size: 16px;
  --root-font-weight: 600;
  --tab-pill-active-shadow: none;
  --tab-pill-text-color: var(--color-gray-800);
  --tab-tree-line-color: hsl(216, 12%, 84%);
  --tab-tree-line-width: 1px;
  --tab-tree-line-curve-radius: 6px;
}
body.modern-theme .formio-component-tabs > .card.card-vertical > .card-header {
  width: 25%;
  min-width: 200px;
  max-width: 300px;
  flex-direction: row-reverse;
  display: flex;
  transition: width 200ms, min-width 200ms;
  margin-bottom: 0;
}
body.modern-theme .formio-component-tabs > .card.card-vertical > .card-header.collapsed {
  width: 26px;
  min-width: 26px;
  contain: size;
  overflow: hidden;
}
body.modern-theme .formio-component-tabs > .card.card-vertical > .card-header.collapsed .nav-tabs {
  transition: opacity 200ms, transform 200ms;
  transform: translateX(-274px);
  opacity: 0;
}
body.modern-theme .formio-component-tabs > .card.card-vertical > .card-header.collapsed .tab-expand-collapse-button {
  --fontawesome-icon-content: "\f054";
  margin-inline-start: 0px;
}
body.modern-theme .formio-component-tabs > .card.card-vertical > .card-header .tab-expand-collapse-button {
  appearance: none;
  background: transparent;
  border: none;
  min-width: 26px;
  padding: 0;
  position: relative;
  margin-inline-start: 6px;
  transition: outline 0.15s;
  --toggler-border-color: hsl(214, 14%, 90%);
  --fontawesome-icon-content: "\f053";
  --line-color: hsl(220, 10%, 94%);
}
body.modern-theme .formio-component-tabs > .card.card-vertical > .card-header .tab-expand-collapse-button::before {
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  background-color: var(--line-color);
  left: 12px;
  top: 0px;
  transition: all 0.15s;
}
body.modern-theme .formio-component-tabs > .card.card-vertical > .card-header .tab-expand-collapse-button::after {
  font-family: var(--fontawesome-font);
  content: var(--fontawesome-icon-content);
  font-weight: 400;
  width: 26px;
  height: 100%;
  max-height: 26px;
  overflow: hidden;
  display: grid;
  place-content: center;
  border-radius: 20px;
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 12px;
  background-color: white;
  color: hsl(219, 42%, 11%);
  transition: all 0.15s;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1), inset 0 0 0 1px var(--toggler-border-color);
}
body.modern-theme .formio-component-tabs > .card.card-vertical > .card-header .tab-expand-collapse-button:hover {
  --toggler-border-color: hsl(219, 42%, 11%);
  --line-color: hsl(220, 10%, 85%);
}
body.modern-theme .formio-component-tabs > .card.card-vertical > .card-header .tab-expand-collapse-button + .nav-tabs {
  max-width: 268px;
}
body.modern-theme .formio-component-tabs > .card.card-vertical > .card-header .nav-tabs {
  transition: opacity 200ms, transform 200ms, display 200ms;
  overflow: visible;
  opacity: 1;
}
body.modern-theme .formio-component-tabs > .card.card-vertical > .card-header .nav-tabs > .nav-item:last-child .nav-item {
  --list-item-bottom-margin: 0px;
}
body.modern-theme .formio-component-tabs > .card.card-vertical > .card-header a.nav-link {
  --sub-tab-pill-active-bg: transparent;
  --list-item-bottom-margin: 2px;
  --tab-pill-font-weight: 400;
  white-space: initial !important;
  line-height: 1.5 !important;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 6px !important;
}
body.modern-theme .formio-component-tabs > .card.card-vertical > .card-header a.nav-link:last-child {
  --sub-tab-pill-active-bg: transparent;
}
body.modern-theme .formio-component-tabs > .card.card-vertical > .card-header a.nav-link:hover {
  --hover-shadow: none;
  border-radius: 0;
  --sub-tab-pill-active-bg: var(--color-gray-75);
  --tab-pill-bg: var(--color-gray-75);
}
body.modern-theme .formio-component-tabs > .card.card-vertical > .card-header a.nav-link.active:last-child {
  --tab-pill-active-text-color: var(--color-primary-600);
  --sub-tab-pill-active-bg: var(--color-primary-50);
  border-radius: 0px 6px 6px 0px !important;
  box-shadow: inset 3px 0px var(--wizard-page-link-active-bg-color, #222);
}
body.modern-theme .formio-component-tabs > .card.card-vertical > .card-header .nav-sub-tabs {
  padding-left: var(--tab-pill-left-padding);
}
body.modern-theme .formio-component-tabs > .card.card-vertical > .card-header .nav-sub-tabs .nav-item {
  border-left: solid var(--custom-border-width, var(--tab-tree-line-width)) var(--custom-border-color, var(--tab-tree-line-color));
  padding-left: var(--tab-pill-left-padding);
  display: flow-root;
}
body.modern-theme .formio-component-tabs > .card.card-vertical > .card-header .nav-sub-tabs .nav-item > .nav-link::before {
  content: " ";
  width: 12px;
  height: 50%;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: calc(0px - var(--tab-tree-line-width) - var(--tab-pill-left-padding));
  border-bottom-left-radius: var(--tab-tree-line-curve-radius, 6px);
  border-left: solid var(--tab-tree-line-width) var(--tab-tree-line-color);
  border-bottom: solid var(--tab-tree-line-width) var(--tab-tree-line-color);
}
body.modern-theme .formio-component-tabs > .card.card-vertical > .card-header .nav-sub-tabs .nav-item:last-child {
  border-left-color: transparent;
}
body.modern-theme .formio-component-tabs > .card.card-vertical > .card-header .item-group {
  --icon-code: "\f077";
}
body.modern-theme .formio-component-tabs > .card.card-vertical > .card-header .item-group > .nav-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  cursor: pointer;
  position: relative;
}
body.modern-theme .formio-component-tabs > .card.card-vertical > .card-header .item-group > .nav-link::after {
  width: 14px;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1.5;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: var(--icon-code);
  align-self: flex-start;
  transform: translateY(1px);
}
body.modern-theme .formio-component-tabs > .card.card-vertical > .card-header .item-group > .nav-link:has(i) {
  justify-content: flex-start;
}
body.modern-theme .formio-component-tabs > .card.card-vertical > .card-header .item-group > .nav-link:has(i)::after {
  margin-inline-start: auto;
}
body.modern-theme .formio-component-tabs > .card.card-vertical > .card-header .item-group > .nav-link.icon-position-top::after {
  position: absolute;
  inset-inline-end: 16px;
  top: calc(50% - 6px);
}
body.modern-theme .formio-component-tabs > .card.card-vertical > .card-header .item-group.collapsed {
  --icon-code: "\f078";
}
body.modern-theme .formio-component-tabs > .card.card-vertical > .card-header + .card-body.tab-pane.active {
  border-top-left-radius: var(--tab-body-border-radius);
}
body.modern-theme .formio-component-tabs > .card.card-vertical > .card-body.tab-pane {
  padding: 0px !important;
  background-color: transparent;
}
body.modern-theme .formio-component-tabs > .card.card-vertical > .card-body.tab-pane > .formio-component-panel > .card > .card-header > .card-title {
  --custom-panel-title-size: 20px;
}
body.modern-theme .formio-component-tabs > .card::after,
body.modern-theme .formio-component-tabs > .card::before {
  display: none;
}
body.modern-theme .formio-component-tabs > .card > .card-header {
  height: auto;
  margin-bottom: -1px;
  z-index: 99;
  position: relative;
}
body.modern-theme .formio-component-tabs > .card > .card-header .card-header-tabs {
  background-color: transparent;
  height: auto;
  max-width: 100%;
  min-width: 100px;
  overflow-y: auto;
  flex-direction: var(--tab-pills-layout, row);
}
body.modern-theme .formio-component-tabs > .card > .card-header .card-header-tabs .nav-item {
  flex: 0;
  height: auto;
  margin-bottom: 0px;
}
body.modern-theme .formio-component-tabs > .card > .card-header .card-header-tabs .nav-item::before {
  display: none;
}
body.modern-theme .formio-component-tabs > .card > .card-header .card-header-tabs .nav-link {
  font-size: var(--tab-pill-font-size, 20px);
  font-weight: var(--tab-pill-font-weight, 600);
  color: var(--tab-pill-text-color, #AAA) !important;
  background-color: var(--tab-pill-bg, #FFF);
  padding-top: var(--tab-pill-top-padding, 16px);
  padding-inline-end: var(--tab-pill-right-padding, 16px);
  padding-bottom: var(--tab-pill-bottom-padding, 16px);
  padding-inline-start: var(--nested-left-padding, var(--tab-pill-left-padding, 16px));
  border-top-left-radius: var(--tab-pill-top-left-radius, 0px);
  border-top-right-radius: var(--tab-pill-top-right-radius, 0px);
  border-bottom-left-radius: var(--tab-pill-bottom-left-radius, 0px);
  border-bottom-right-radius: var(--tab-pill-bottom-right-radius, 0px);
  line-height: 1;
  text-decoration: none !important;
  white-space: nowrap;
  text-transform: none;
  text-align: var(--tab-pill-text-align, center);
  box-shadow: none;
  border-style: none;
  border-width: 0px;
  border-color: transparent;
  margin-bottom: var(--list-item-bottom-margin, 0px);
  margin-inline: 0px;
}
body.modern-theme .formio-component-tabs > .card > .card-header .card-header-tabs .nav-link.active {
  font-size: var(--tab-pill-font-size, 20px);
  color: var(--tab-pill-active-text-color, black) !important;
  background-color: var(--sub-tab-pill-active-bg, var(--tab-pill-active-bg, #FFF));
  border-style: none;
  border-width: 0px;
  border-color: transparent;
  box-shadow: none;
}
body.modern-theme .formio-component-tabs > .card > .card-header .card-header-tabs .nav-link:hover {
  box-shadow: var(--hover-shadow, inset 0 -2px transparent);
}
body.modern-theme .formio-component-tabs > .card > .card-header .card-header-tabs .nav-link.tab-error {
  display: flex;
  align-items: center;
  gap: 6px;
}
body.modern-theme .formio-component-tabs > .card > .card-header .card-header-tabs .nav-link.tab-error::after {
  content: ' ';
  display: var(--validation-tab-dot-display);
  height: 6px;
  min-width: 6px;
  background: rgba(255, 0, 59, 0.7);
  border-radius: 6px;
}
body.modern-theme .formio-component-tabs > .card > .card-header .card-header-tabs .nav-link:is(.icon-position-left, .icon-position-right, .icon-position-top) {
  display: flex;
  gap: 10px;
}
body.modern-theme .formio-component-tabs > .card > .card-header .card-header-tabs .nav-link.icon-position-right {
  flex-direction: row-reverse;
}
body.modern-theme .formio-component-tabs > .card > .card-header .card-header-tabs .nav-link.icon-position-top {
  flex-direction: column;
}
body.modern-theme .formio-component-tabs > .card > .card-header .card-header-tabs .nav-link-vertical {
  font-weight: var(--root-font-weight, 600);
}
body.modern-theme .formio-component-tabs > .card > .card-header .card-header-tabs .nav-link-vertical.icon-position-right.nav-link-vertical::after {
  order: -1;
  margin-inline-start: auto;
}
body.modern-theme .formio-component-tabs > .card > .card-header .card-header-tabs::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
body.modern-theme .formio-component-tabs > .card > .card-header .card-header-tabs::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(70, 81, 170, 0.4);
}
body.modern-theme .formio-component-tabs > .card > .card-header .card-header-tabs::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05);
}
body.modern-theme .formio-component-tabs > .card > .card-header .card-header-tabs:hover::-webkit-scrollbar-thumb {
  background-color: rgba(70, 81, 170, 0.7);
}
body.modern-theme .formio-component-tabs > .card > .card-header .card-header-tabs.nav-tabs-vertical {
  margin-inline-end: 0px;
}
body.modern-theme .formio-component-tabs > .card > .card-header + .card-body.tab-pane.active {
  border-top-left-radius: 0px;
}
body.modern-theme .formio-component-tabs > .card > .card-body.tab-pane {
  border-style: none;
  border-width: 0px;
  border-color: transparent;
  background-color: var(--tab-body-bg-color, transparent);
  box-shadow: none;
  border-radius: var(--tab-body-border-radius, 0px);
  padding: var(--tab-body-top-padding, 24px) var(--tab-body-right-padding, 24px) var(--tab-body-bottom-padding, 24px) var(--tab-body-left-padding, 24px) !important;
  margin-top: 0;
  flex-direction: column;
  min-width: 320px;
}
body.modern-theme .formio-component-tabs > .card > .card-body.tab-pane.active {
  display: flex !important;
}
body.modern-theme .formio-component-tabs > .card > .card-body.tab-pane::before,
body.modern-theme .formio-component-tabs > .card > .card-body.tab-pane::after {
  display: none;
}
body.modern-theme :where(.formio-component-tabs, .formio-component-panel) .formio-component-tabs > .card > .card-header .card-header-tabs .nav-link {
  border-style: solid solid none;
  border-width: 1px;
  border-color: transparent;
}
body.modern-theme :where(.formio-component-tabs, .formio-component-panel) .formio-component-tabs > .card > .card-header .card-header-tabs .nav-link.active {
  border-style: solid solid none;
  border-width: 1px;
  border-color: var(--color-gray-200);
}
body.modern-theme :where(.formio-component-tabs, .formio-component-panel) .formio-component-tabs > .card > .card-body.tab-pane {
  border-style: solid;
  border-width: 1px;
  border-color: var(--color-gray-200);
}
body.modern-theme #formio-form-wizard .formio-component.formio-component-tabs {
  --validation-tab-dot-display: none;
}
body.modern-theme .new-formio-part-b .formio-form .formio-component-tabs > .card > .card-header {
  margin-block: 30px;
}
body.modern-theme .new-formio-part-b .formio-form .formio-component-tabs > .card > .card-header .card-header-tabs {
  gap: var(--wizard-bar-items-gap, 0px);
}
body.modern-theme .new-formio-part-b .formio-form .formio-component-tabs > .card > .card-header .card-header-tabs::after,
body.modern-theme .new-formio-part-b .formio-form .formio-component-tabs > .card > .card-header .card-header-tabs::before {
  display: none;
}
body.modern-theme .new-formio-part-b .formio-form .formio-component-tabs > .card > .card-header .card-header-tabs .nav-link {
  color: var(--wizard-page-link-text-color, #000) !important;
  background-color: var(--wizard-page-link-bg-color, #CCC);
  font-size: var(--wizard-page-link-text-size, 24px);
  font-weight: var(--wizard-page-link-text-weight, 500);
  padding: var(--wizard-page-link-block-padding, 8px) var(--wizard-page-link-inline-padding, 28px);
  border-radius: var(--wizard-page-link-border-radius);
}
body.modern-theme .new-formio-part-b .formio-form .formio-component-tabs > .card > .card-header .card-header-tabs .nav-link:is(.active, :hover) {
  background-color: var(--wizard-page-link-active-bg-color, #CCC);
  color: var(--wizard-page-link-active-text-color, #000) !important;
}
body.modern-theme .new-formio-part-b .formio-form .formio-component-tabs > .card > .card-body.tab-pane:nth-child(2).active {
  border-radius: 6px !important;
}
body.modern-theme .new-formio-part-b .formio-form .formio-component-tabs .readonly-forms-components-container .formio-component-panel {
  background-color: transparent;
}
body.modern-theme .new-formio-part-b .formio-form .formio-component-tabs .part-b-document-tab .formio-component-panel:has(.validation-headers) {
  background-color: transparent;
}
body.modern-theme .new-formio-part-b .formio-form .formio-component-tabs .part-b-document-tab .validation-headers .row {
  margin: 0px;
}
body.modern-theme .new-formio-part-b .formio-form .formio-component-tabs .part-b-document-tab .validation-headers .col-sm-1 {
  padding: 0px;
}
body.modern-theme .formio-component-modal-wrapper-editgrid .formio-dialog-theme-default,
body.modern-theme .formio-component-modal-wrapper-datagrid .formio-dialog-theme-default {
  padding-bottom: 15px;
  padding-top: 0px;
  display: flex;
}
body.modern-theme .formio-component-modal-wrapper-editgrid .formio-dialog-theme-default .formio-dialog-content,
body.modern-theme .formio-component-modal-wrapper-datagrid .formio-dialog-theme-default .formio-dialog-content {
  width: calc(100% - 130px);
  font-family: var(--main-font-family);
  background-color: white;
  padding: 24px;
  align-self: center;
  max-height: 80vh;
}
body.modern-theme .formio-component-modal-wrapper-editgrid .formio-dialog-theme-default .formio-dialog-content .formio-component-datagrid,
body.modern-theme .formio-component-modal-wrapper-datagrid .formio-dialog-theme-default .formio-dialog-content .formio-component-datagrid,
body.modern-theme .formio-component-modal-wrapper-editgrid .formio-dialog-theme-default .formio-dialog-content .formio-component-editgrid,
body.modern-theme .formio-component-modal-wrapper-datagrid .formio-dialog-theme-default .formio-dialog-content .formio-component-editgrid {
  margin-bottom: 24px;
}
body.modern-theme .formio-component-modal-wrapper-editgrid .formio-dialog-theme-default .formio-dialog-content .formio-component-datagrid > table,
body.modern-theme .formio-component-modal-wrapper-datagrid .formio-dialog-theme-default .formio-dialog-content .formio-component-datagrid > table,
body.modern-theme .formio-component-modal-wrapper-editgrid .formio-dialog-theme-default .formio-dialog-content .formio-component-editgrid > table,
body.modern-theme .formio-component-modal-wrapper-datagrid .formio-dialog-theme-default .formio-dialog-content .formio-component-editgrid > table {
  margin-top: 15px;
}
body.modern-theme .formio-component-modal-wrapper-editgrid .formio-dialog-theme-default .formio-dialog-content .btn.btn-success.formio-dialog-button,
body.modern-theme .formio-component-modal-wrapper-datagrid .formio-dialog-theme-default .formio-dialog-content .btn.btn-success.formio-dialog-button {
  border-radius: 6px !important;
  background-color: var(--color-primary-600);
  padding-inline-start: 25px;
  padding-inline-end: 25px;
  font-weight: 500;
  text-transform: none;
  position: relative;
  z-index: 1;
  padding-top: 12px;
  padding-bottom: 12px;
  line-height: 1;
  color: white;
  text-align: center;
  font-size: 16px;
  gap: 8px;
}
body.modern-theme .formio-component-modal-wrapper-editgrid .formio-dialog-theme-default .formio-dialog-content .btn.btn-success.formio-dialog-button:hover,
body.modern-theme .formio-component-modal-wrapper-datagrid .formio-dialog-theme-default .formio-dialog-content .btn.btn-success.formio-dialog-button:hover {
  box-shadow: 0 9px 20px rgba(70, 81, 170, 0.4) !important;
  opacity: 0.9;
}
body.modern-theme .formio-component-modal-wrapper-editgrid .formio-dialog-theme-default .formio-dialog-content .formio-dialog-close,
body.modern-theme .formio-component-modal-wrapper-datagrid .formio-dialog-theme-default .formio-dialog-content .formio-dialog-close {
  padding: 0 !important;
  box-shadow: none !important;
}
body.modern-theme .formio-component-modal-wrapper-editgrid .formio-dialog-theme-default .formio-dialog-content .formio-dialog-close:hover,
body.modern-theme .formio-component-modal-wrapper-datagrid .formio-dialog-theme-default .formio-dialog-content .formio-dialog-close:hover {
  background-color: var(--color-gray-100);
  color: var(--color-black) !important;
}
body.modern-theme .formio-component-modal-wrapper-editgrid .formio-dialog-theme-default .formio-dialog-content .formio-dialog-close:hover::before,
body.modern-theme .formio-component-modal-wrapper-datagrid .formio-dialog-theme-default .formio-dialog-content .formio-dialog-close:hover::before {
  color: inherit !important;
}
body.modern-theme .formio-component-modal-wrapper-editgrid .formio-dialog-theme-default .formio-dialog-content > .sr-only,
body.modern-theme .formio-component-modal-wrapper-datagrid .formio-dialog-theme-default .formio-dialog-content > .sr-only {
  width: 0px !important;
}
body.modern-theme .formio-component-modal-wrapper-editgrid {
  margin-bottom: 0px;
}
body.modern-theme .formio-component-modal-wrapper-editgrid .formio-dialog-content {
  padding: 56px 24px 16px !important;
}
body.modern-theme .editgrid-pagination {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
body.modern-theme .editgrid-pagination ul {
  list-style: none;
  padding: 0;
  display: flex;
  margin: 0;
  margin-inline-end: 10px;
  height: 36px;
  align-items: center;
}
body.modern-theme .editgrid-pagination .pager-button {
  margin-inline-end: 3px;
  border-radius: 6px;
  width: 36px;
  justify-content: center;
}
body.modern-theme .editgrid-pagination .pager-button:last-child {
  margin-inline-end: 0px;
}
body.modern-theme .editgrid-pagination .pager-button.disabled {
  opacity: 0.5;
  pointer-events: none;
}
body.modern-theme .editgrid-pagination li {
  padding: 0 10px;
  display: flex;
  height: 100%;
  align-items: center;
  cursor: pointer;
  background-color: #F5F6F9;
  font-size: 12px;
  font-weight: 600;
  color: var(--color-gray-800);
}
body.modern-theme .editgrid-pagination li:hover {
  background-color: #E5E9EF;
}
body.modern-theme .editgrid-pagination .current-page {
  background-color: #E5E9EF;
  font-weight: 900;
}
body.modern-theme .editgrid-pagination .first-page {
  border-radius: 6px 0 0 6px;
}
body.modern-theme .editgrid-pagination .last-page {
  border-radius: 0 6px 6px 0;
  margin-inline-end: 3px;
}
body.modern-theme .editgrid-pagination .results-selector {
  display: flex;
  align-items: center;
  background-color: #f5f6f9;
  padding-inline-start: 14px;
  border-radius: 6px;
  color: var(--color-gray-800);
  font-size: 12px;
  font-weight: 600;
}
body.modern-theme .editgrid-pagination .results-selector .dropdown-toggle {
  padding-inline-start: 10px;
  padding-inline-end: 10px;
  border-radius: 0 6px 6px 0;
  background-color: transparent;
  color: var(--color-gray-800);
  font-size: 12px;
  font-weight: 900;
}
body.modern-theme .editgrid-pagination .results-selector .dropdown-toggle::after {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f078";
  margin-inline-start: 10px;
}
body.modern-theme .editgrid-pagination .results-selector .dropdown-toggle:hover {
  background-color: #E5E9EF;
}
body.modern-theme .editgrid-pagination .results-selector .dropdown.open .dropdown-toggle {
  border-radius: 0 6px 3px 0;
  background-color: #E5E9EF;
}
body.modern-theme .editgrid-pagination .results-selector .dropdown.open .dropdown-menu {
  border-radius: 3px 3px 6px 6px;
}
body.modern-theme .editgrid-pagination .results-selector .dropdown-menu {
  min-width: 40px;
  padding: 0;
  width: 100%;
  background-color: #f5f6f9;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05);
  border: none;
}
body.modern-theme .editgrid-pagination .results-selector .dropdown-menu .dropdown-item {
  display: block;
  padding: 0 10px;
  min-height: 28px;
  text-decoration: none !important;
  color: var(--color-gray-800);
}
body.modern-theme .editgrid-pagination .results-selector .dropdown-menu .dropdown-item:hover {
  background-color: #E5E9EF;
}
body.modern-theme .editgrid-pagination .results-selector .dropdown-menu .dropdown-item:first-child {
  border-radius: 6px 6px 0 0;
}
body.modern-theme .editgrid-pagination .results-selector .dropdown-menu .dropdown-item:last-child {
  border-radius: 0 0 6px 6px;
}
body.modern-theme #select-business-entity {
  background-color: rgba(0, 0, 0, 0.05);
}
body.modern-theme #select-business-entity .modal-dialog .modal-content {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}
body.modern-theme #select-business-entity .modal-dialog .panel-heading {
  border-radius: 6px 6px 0 0;
  border-bottom: solid 2px #F3F4F5;
  padding: 16px 23px;
}
body.modern-theme #select-business-entity .modal-dialog .panel-heading .panel-title {
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
body.modern-theme #select-business-entity .modal-dialog .panel-heading .panel-title .close {
  font-size: 28px;
  font-weight: 400;
  opacity: 0.7;
  line-height: 16px;
  display: flex;
}
body.modern-theme #select-business-entity .modal-dialog .panel-heading .panel-title .close:hover {
  opacity: 1;
}
body.modern-theme #select-business-entity .modal-dialog .panel {
  border-radius: 0 0 6px 6px;
  background-color: white;
  box-shadow: none;
}
body.modern-theme #select-business-entity .modal-dialog .panel .panel-body {
  color: var(--color-gray-800);
  font-size: 16px;
  padding: 16px 23px;
}
body.modern-theme #select-business-entity .modal-dialog .panel .panel-body .radio label {
  margin-top: 0;
}
body.modern-theme #select-business-entity .modal-dialog .panel .panel-body .radio label::before {
  display: none;
}
body.modern-theme #select-business-entity .modal-dialog .panel .panel-body .radio label input[type="radio"] {
  appearance: inherit;
}
body.modern-theme #select-business-entity .modal-dialog .panel .panel-body .radio label input[type="radio"]::before {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  font-size: 16px;
  content: "\f111";
  color: rgba(70, 81, 170, 0.5);
}
body.modern-theme #select-business-entity .modal-dialog .panel .panel-body .radio label input[type="radio"] + span {
  font-size: 16px;
  font-weight: 600;
  color: rgba(70, 81, 170, 0.5);
  margin-inline-start: 3px;
  position: relative;
  top: 1px;
  line-height: 14px;
}
body.modern-theme #select-business-entity .modal-dialog .panel .panel-body .radio label input[type="radio"]:checked::before {
  content: "\f192";
  font-weight: 900;
  color: var(--color-primary-600);
}
body.modern-theme #select-business-entity .modal-dialog .panel .panel-body .radio label input[type="radio"]:checked + span {
  color: var(--color-primary-600);
}
body.modern-theme #select-business-entity .modal-dialog .panel .panel-body .radio label input[type="radio"]:focus {
  outline: none;
}
body.modern-theme #select-business-entity .modal-dialog .panel .panel-body .radio label:hover input[type="radio"] {
  appearance: inherit;
}
body.modern-theme #select-business-entity .modal-dialog .panel .panel-body .radio label:hover input[type="radio"]::before {
  color: var(--color-primary-600);
}
body.modern-theme #select-business-entity .modal-dialog .panel .panel-body .radio label:hover input[type="radio"] + span {
  color: var(--color-primary-600);
}
body.modern-theme #select-business-entity .modal-dialog .panel .panel-body input.form-control {
  height: var(--field-height);
  border: solid 1px var(--color-gray-300);
  border-radius: 6px !important;
  font-weight: 600;
  box-shadow: none;
}
body.modern-theme #select-business-entity .modal-dialog .panel .panel-body input.form-control:where(:hover, :focus) {
  outline: none !important;
}
body.modern-theme #select-business-entity .modal-dialog .panel .panel-footer {
  background-color: transparent;
  border: none;
  padding: 0 23px 16px;
}
body.modern-theme #select-business-entity .modal-dialog .panel .panel-footer a {
  border-radius: 6px !important;
  background-color: var(--color-primary-600);
  padding-inline-start: 25px;
  padding-inline-end: 25px;
  font-weight: 600;
  text-transform: none;
  position: relative;
  z-index: 1;
  padding-top: 12px;
  padding-bottom: 12px;
  line-height: 1;
  color: white;
  text-align: center;
  font-size: 16px;
  border: none;
}
body.modern-theme :is(.readonly-forms-block, .readonly-forms-block ~ .formio-component-panel, .formio-component-previewcontainer ~ .formio-component-panel, .formio-component-previewcontainer .formio-component-panel) .card {
  background-color: transparent;
  border: none !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  box-shadow: none;
}
body.modern-theme :is(.readonly-forms-block, .readonly-forms-block ~ .formio-component-panel, .formio-component-previewcontainer ~ .formio-component-panel, .formio-component-previewcontainer .formio-component-panel) .card > .card-header {
  margin: 0 !important;
  padding: 0 !important;
  background-color: transparent !important;
  border-bottom: none !important;
}
body.modern-theme :is(.readonly-forms-block, .readonly-forms-block ~ .formio-component-panel, .formio-component-previewcontainer ~ .formio-component-panel, .formio-component-previewcontainer .formio-component-panel) .card > .card-header > .card-title {
  font-size: 18px !important;
  font-weight: 600;
  color: var(--color-gray-800);
}
body.modern-theme :is(.readonly-forms-block, .readonly-forms-block ~ .formio-component-panel, .formio-component-previewcontainer ~ .formio-component-panel, .formio-component-previewcontainer .formio-component-panel) .card > .card-body {
  padding-top: 10px;
  padding-bottom: 0px;
  padding-inline: 0px;
}
body.modern-theme :is(.readonly-forms-block, .readonly-forms-block ~ .formio-component-panel, .formio-component-previewcontainer ~ .formio-component-panel, .formio-component-previewcontainer .formio-component-panel) .card > .card-body .formio-component-panel + .formio-component-panel {
  margin-top: 35px;
}
body.modern-theme :is(.readonly-forms-block, .readonly-forms-block ~ .formio-component-panel, .formio-component-previewcontainer ~ .formio-component-panel, .formio-component-previewcontainer .formio-component-panel) ~ .readonly-forms-block {
  margin-top: 35px;
}
body.modern-theme :is(.readonly-forms-block, .readonly-forms-block ~ .formio-component-panel, .formio-component-previewcontainer ~ .formio-component-panel, .formio-component-previewcontainer .formio-component-panel) .formio-component-panel {
  margin-inline: 0px !important;
}
body.modern-theme .formio-component-part-b-data-tab + .formio-component-previewcontainer {
  margin-top: 0px;
}
body.modern-theme .formio-component-part-b-data-tab + .formio-component-button[class*="print"] + .formio-component-previewcontainer {
  margin-top: -26px !important;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns {
  align-items: center;
  margin-top: 0;
  border: solid 1px var(--color-gray-200);
  min-height: 39px;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns a[ref="fileLink"] {
  color: var(--main-text-color);
  border-bottom: solid 1px;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns:focus-within {
  z-index: 99;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns:nth-child(odd) {
  background-color: var(--color-gray-75);
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns:last-child {
  border-bottom: solid 1px #e3e3e6 !important;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns + .formio-component.formio-component-columns {
  border-top: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns + .formio-component.formio-component-columns.readonly-form-columns {
  margin-top: 0px !important;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns.formio-hidden + .formio-component.formio-component-columns {
  border-top: solid 1px #e3e3e6;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns:not(.formio-hidden) + .formio-hidden + .formio-component.formio-component-columns {
  border-top: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div {
  height: 100%;
  padding: 6px 12px;
  min-height: 39px;
  display: flex;
  align-items: center;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type {
  border-inline-end: solid 1px #e3e3e6;
  flex: 0 0 25%;
  height: auto;
  align-self: stretch;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div {
  margin-inline-start: -1px;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div .formio-component-htmlelement p,
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div .formio-component-htmlelement h1,
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div .formio-component-htmlelement h2,
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div .formio-component-htmlelement h3,
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div .formio-component-htmlelement h4,
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div .formio-component-htmlelement h5,
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div .formio-component-htmlelement h6 {
  font-size: 15px;
  font-weight: 400;
  color: #404451;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div .formio-component-htmlelement p:first-child:empty + :is(blockquote, p),
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div .formio-component-htmlelement h1:first-child:empty + :is(blockquote, p),
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div .formio-component-htmlelement h2:first-child:empty + :is(blockquote, p),
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div .formio-component-htmlelement h3:first-child:empty + :is(blockquote, p),
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div .formio-component-htmlelement h4:first-child:empty + :is(blockquote, p),
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div .formio-component-htmlelement h5:first-child:empty + :is(blockquote, p),
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div .formio-component-htmlelement h6:first-child:empty + :is(blockquote, p) {
  margin: 0;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div .formio-component-htmlelement p:last-of-type,
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div .formio-component-htmlelement h1:last-of-type,
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div .formio-component-htmlelement h2:last-of-type,
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div .formio-component-htmlelement h3:last-of-type,
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div .formio-component-htmlelement h4:last-of-type,
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div .formio-component-htmlelement h5:last-of-type,
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div .formio-component-htmlelement h6:last-of-type {
  margin-bottom: 0;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div .formio-component-htmlelement blockquote {
  margin-inline-start: 0;
  margin-inline-end: 0;
  background: transparent !important;
  padding: 0 !important;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div .formio-component-htmlelement blockquote::after {
  display: none !important;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div .formio-component-htmlelement.notice {
  padding: 0 !important;
  background: transparent !important;
  border: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div.col-md-10:nth-last-child(3) {
  flex: auto;
  margin-inline-end: 46px;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div.col-md-10:nth-last-child(3) + .col-md-2:nth-last-child(2) {
  flex: 0;
  order: 3;
  margin-inline-start: auto;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div.col-md-10:nth-last-child(3) + .col-md-2:nth-last-child(2) > .formio-component-columns {
  flex-direction: row-reverse;
  gap: 12px;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div.col-md-10:nth-last-child(3) + .col-md-2:nth-last-child(2) > .formio-component-columns::before,
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div.col-md-10:nth-last-child(3) + .col-md-2:nth-last-child(2) > .formio-component-columns::after {
  display: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div + .col-md-2 {
  min-width: fit-content;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div + .col-md-2 .formio-component-columns {
  align-items: center;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div + .col-md-2 .formio-component-columns .formio-errors {
  display: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div + .col-md-2 .formio-component-columns > div:first-child {
  flex: 0;
  padding: 0;
  min-width: fit-content;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div + .col-md-2 .formio-component-columns > div:first-child + div {
  padding: 0;
  flex: auto;
  position: absolute;
  width: fit-content;
  inset-inline-end: calc(100% + 14px);
  isolation: isolate;
  z-index: 9;
  min-height: 0px;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div + .col-md-2 .formio-component-columns > div:first-child + div:focus-within {
  width: clamp(320px, 30vw, 30vw);
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div + .col-md-2 .formio-component-columns > div:first-child + div [class*="_validation_reason"] > div {
  display: flex;
  justify-content: flex-end;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div + .col-md-2 .formio-component-columns > div:first-child + div [class*="_validation_reason"] > div textarea {
  margin: 0 !important;
  resize: vertical;
  width: 24px;
  height: 24px !important;
  min-height: 24px;
  transition-duration: 0.15s;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div + .col-md-2 .formio-component-columns > div:first-child + div [class*="_validation_reason"] > div textarea:focus {
  width: 100%;
  height: auto !important;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div + .col-md-2 .formio-component-columns > div:first-child + div [class*="_validation_reason"]:not(.formio-hidden) > div[ref="element"] {
  padding: 10px;
  border-radius: 6px;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div + .col-md-2 .formio-component-columns > div:first-child + div [class*="_validation_reason"]:not(.formio-hidden) > div[ref="element"]::after {
  content: "\f4a6";
  position: absolute;
  z-index: 99;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  display: grid;
  place-items: center;
  cursor: pointer;
  outline: solid 4px white;
  font-family: var(--fontawesome-font);
  font-weight: 400;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-size: 18px;
  background: white;
  padding: 2px;
  color: #d56d72;
  transition-duration: 0.15s;
  transition-property: color, background, outline;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div + .col-md-2 .formio-component-columns > div:first-child + div [class*="_validation_reason"]:not(.formio-hidden) > div[ref="element"]::before {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 3px;
  transform: rotate(45deg);
  inset-inline-end: -6px;
  z-index: -1;
  display: none;
  background: white;
  top: calc(50% - 7px);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.4);
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div + .col-md-2 .formio-component-columns > div:first-child + div [class*="_validation_reason"]:not(.formio-hidden) > div[ref="element"]:focus-within {
  background: white;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div + .col-md-2 .formio-component-columns > div:first-child + div [class*="_validation_reason"]:not(.formio-hidden) > div[ref="element"]:focus-within::after {
  z-index: -1;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div + .col-md-2 .formio-component-columns > div:first-child + div [class*="_validation_reason"]:not(.formio-hidden) > div[ref="element"]:focus-within::before {
  display: block;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div + .col-md-2 .formio-component-columns > div:first-child + div [class*="_validation_reason"]:not(.formio-hidden) > div[ref="element"]:hover::after {
  color: #ffffff;
  background: #a3a3a3;
  outline: solid 4px #a3a3a3;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div + .col-md-2 .formio-component-columns > div:first-child + div [class*="_validation_reason"].required label.field-required {
  position: absolute;
  inset-inline-end: 6px;
  width: 6px !important;
  margin: 0;
  height: 6px;
  background: #ff003b;
  border-radius: 6px;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div + .col-md-2 .formio-component-columns > div:first-child + div [class*="_validation_reason"].required label.field-required::after {
  display: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div .formio-component-datagrid {
  margin-top: 0;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div .formio-component-datagrid [class*="_validation_reason"].required label.field-required {
  position: absolute;
  inset-inline-end: 6px;
  width: 6px !important;
  margin: 0;
  height: 6px;
  background: #ff003b;
  border-radius: 6px;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-2:first-of-type + div .formio-component-datagrid [class*="_validation_reason"].required label.field-required::after {
  display: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-10:first-of-type:nth-last-of-type(3) {
  flex: auto;
  margin-inline-end: 46px;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-10:first-of-type:nth-last-of-type(3) + .col-md-6 {
  flex: 0;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-10:first-of-type + .col-md-2:nth-last-of-type(2) {
  flex: 0;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-10:first-of-type + .col-md-2:nth-last-of-type(2) .hide-validation {
  flex-direction: row-reverse;
  align-items: center;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-10:first-of-type + .col-md-2:nth-last-of-type(2) .hide-validation::before {
  display: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-10:first-of-type + .col-md-2:nth-last-of-type(2) .hide-validation .formio-errors {
  display: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-10:first-of-type + .col-md-2:nth-last-of-type(2) .hide-validation .col-md-2:first-child {
  flex: 1;
  padding-inline-end: 0;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-10:first-of-type + .col-md-2:nth-last-of-type(2) .hide-validation .col-md-2:nth-last-of-type(2) {
  padding: 0;
  flex: auto;
  position: absolute;
  width: fit-content;
  inset-inline-end: calc(100% + 14px);
  isolation: isolate;
  z-index: 9;
  min-height: 0px;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-10:first-of-type + .col-md-2:nth-last-of-type(2) .hide-validation .col-md-2:nth-last-of-type(2):focus-within {
  width: clamp(320px, 30vw, 30vw);
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-10:first-of-type + .col-md-2:nth-last-of-type(2) .hide-validation .col-md-2:nth-last-of-type(2) [class*="_validation_reason"] > div {
  display: flex;
  justify-content: flex-end;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-10:first-of-type + .col-md-2:nth-last-of-type(2) .hide-validation .col-md-2:nth-last-of-type(2) [class*="_validation_reason"] > div textarea {
  margin: 0 !important;
  resize: vertical;
  width: 24px;
  height: 24px !important;
  min-height: 24px;
  transition-duration: 0.15s;
  resize: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-10:first-of-type + .col-md-2:nth-last-of-type(2) .hide-validation .col-md-2:nth-last-of-type(2) [class*="_validation_reason"] > div textarea:focus {
  width: 100%;
  height: auto !important;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-10:first-of-type + .col-md-2:nth-last-of-type(2) .hide-validation .col-md-2:nth-last-of-type(2) [class*="_validation_reason"]:not(.formio-hidden) > div[ref="element"] {
  padding: 10px;
  border-radius: 6px;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-10:first-of-type + .col-md-2:nth-last-of-type(2) .hide-validation .col-md-2:nth-last-of-type(2) [class*="_validation_reason"]:not(.formio-hidden) > div[ref="element"]::after {
  content: "\f4a6";
  position: absolute;
  z-index: 99;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  display: grid;
  place-items: center;
  cursor: pointer;
  outline: solid 4px white;
  font-family: var(--fontawesome-font);
  font-weight: 400;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-size: 18px;
  background: white;
  padding: 2px;
  color: #d56d72;
  transition-duration: 0.15s;
  transition-property: color, background, outline;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-10:first-of-type + .col-md-2:nth-last-of-type(2) .hide-validation .col-md-2:nth-last-of-type(2) [class*="_validation_reason"]:not(.formio-hidden) > div[ref="element"]::before {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 3px;
  transform: rotate(45deg);
  inset-inline-end: -6px;
  z-index: -1;
  display: none;
  background: white;
  top: calc(50% - 7px);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.4);
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-10:first-of-type + .col-md-2:nth-last-of-type(2) .hide-validation .col-md-2:nth-last-of-type(2) [class*="_validation_reason"]:not(.formio-hidden) > div[ref="element"]:focus-within {
  background: white;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-10:first-of-type + .col-md-2:nth-last-of-type(2) .hide-validation .col-md-2:nth-last-of-type(2) [class*="_validation_reason"]:not(.formio-hidden) > div[ref="element"]:focus-within::after {
  z-index: -1;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-10:first-of-type + .col-md-2:nth-last-of-type(2) .hide-validation .col-md-2:nth-last-of-type(2) [class*="_validation_reason"]:not(.formio-hidden) > div[ref="element"]:focus-within::before {
  display: block;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-10:first-of-type + .col-md-2:nth-last-of-type(2) .hide-validation .col-md-2:nth-last-of-type(2) [class*="_validation_reason"]:not(.formio-hidden) > div[ref="element"]:hover::after {
  color: #ffffff;
  background: #a3a3a3;
  outline: solid 4px #a3a3a3;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-10:first-of-type + .col-md-2:nth-last-of-type(2) .hide-validation .col-md-2:nth-last-of-type(2) [class*="_validation_reason"].required label.field-required {
  position: absolute;
  inset-inline-end: 6px;
  width: 6px !important;
  margin: 0;
  height: 6px;
  background: #ff003b;
  border-radius: 6px;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-10:first-of-type + .col-md-2:nth-last-of-type(2) .hide-validation .col-md-2:nth-last-of-type(2) [class*="_validation_reason"].required label.field-required::after {
  display: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.formio-errors {
  display: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div.col-md-12 {
  flex: 0 0 100%;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns input {
  background: transparent !important;
  border: none;
  font-size: 15px !important;
  color: var(--color-gray-800);
  -webkit-text-fill-color: var(--color-gray-800);
  padding-top: 0;
  padding-bottom: 0;
  padding-inline-start: 0;
  height: 27px;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns input:hover {
  outline: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns input:placeholder-shown {
  display: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group) {
  width: 100%;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-htmlelement p {
  font-size: 14px;
  font-weight: 600;
  color: var(--color-gray-800);
  word-break: break-word;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-radio .form-radio {
  margin-top: 0;
  padding-top: 0 !important;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-radio .form-radio :where(.form-check, .form-check-inline) {
  display: none;
  padding-bottom: 0 !important;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-radio .form-radio :where(.form-check, .form-check-inline).radio-selected {
  display: block;
  margin: 0 !important;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-radio .form-radio :where(.form-check, .form-check-inline).radio-selected label.form-check-label {
  margin-top: 0;
  padding-inline-start: 0;
  font-size: 15px;
  cursor: text;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-radio .form-radio :where(.form-check, .form-check-inline).radio-selected label.form-check-label input.form-check-input {
  display: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-radio .form-radio :where(.form-check, .form-check-inline).radio-selected label.form-check-label span {
  margin-inline-start: 0 !important;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-radio .form-text.text-muted {
  display: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-radio.radio-switch .form-radio {
  border: none !important;
  height: auto !important;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-radio.radio-switch .form-radio :where(.form-check, .form-check-inline) {
  display: none !important;
  padding-bottom: 0 !important;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-radio.radio-switch .form-radio :where(.form-check, .form-check-inline).radio-selected {
  display: block !important;
  margin: 0 !important;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-radio.radio-switch .form-radio :where(.form-check, .form-check-inline).radio-selected label.form-check-label {
  display: inline-block !important;
  margin-top: 0 !important;
  padding-inline-start: 0 !important;
  font-size: 15px !important;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-radio.radio-switch .form-radio :where(.form-check, .form-check-inline).radio-selected label.form-check-label input.form-check-input {
  display: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-radio.radio-switch .form-radio :where(.form-check, .form-check-inline).radio-selected label.form-check-label span {
  margin-inline-start: 0 !important;
  display: block !important;
  height: auto !important;
  background: none !important;
  color: var(--color-gray-800) !important;
  padding: 0 !important;
  border: none !important;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datetime input.form-control {
  font-weight: 400;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datetime .input-group {
  display: flex;
  align-items: center;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datetime .input-group .input-group-append {
  display: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datetime .form-text.text-muted {
  display: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-textarea .card.card-body {
  padding: 0;
  box-shadow: none;
  background: transparent;
  margin: 0;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-textarea .card.card-body .formio-editor-read-only-content {
  font-size: 15px;
  font-weight: 400;
  color: #404451;
  white-space: initial;
  text-align: start;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-textarea .card.card-body.mb-3 {
  margin-bottom: 0 !important;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-textarea .form-text.text-muted {
  display: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-textarea[class*="_validation_reason"] {
  height: auto;
  padding: 0;
  background: transparent;
  border: none !important;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-textarea[class*="_validation_reason"] textarea.form-control {
  border: solid 1px rgba(64, 68, 81, 0.16);
  outline: none;
  border-radius: 6px !important;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-textarea[class*="_validation_reason"] textarea.form-control:where(:focus, :active) {
  outline: none;
  box-shadow: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-textarea[class*="_validation_reason"]:hover {
  outline: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-select .formio-choices .form-control.dropdown {
  background: transparent;
  border: none;
  outline: none;
  height: 100%;
  min-height: 26px;
  line-height: 1;
  display: flex;
  align-items: center;
  padding: 0;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-select .formio-choices .form-control.dropdown .choices__list .choices__item span {
  white-space: break-spaces;
  font-size: 15px !important;
  font-weight: 400;
  color: #404451;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-select .formio-choices .form-control.dropdown .choices__list .choices__item.choices__item--selectable {
  padding-inline-end: 0;
  min-height: 30px;
  display: flex;
  align-items: center;
  cursor: text;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-select .formio-select-autocomplete-input {
  display: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-select select {
  border: none;
  background: transparent;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-select .form-text.text-muted {
  display: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-select.formio-component-multiple .form-control.selection .choices__list--multiple {
  flex: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-select.formio-component-multiple .form-control.selection .choices__list--multiple .choices__item {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  min-height: 24px !important;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-select.formio-component-multiple .form-control.selection .choices__list--multiple .choices__item::before {
  content: " ";
  width: 6px;
  height: 2px;
  border-radius: 6px;
  background: #404451;
  margin-inline-end: 6px;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-select.formio-component-multiple .form-control.selection .choices__list--multiple .choices__item[data-value="[]"] {
  display: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-select.formio-component-multiple .form-control.selection .choices__input {
  flex: 0;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datagrid > table.table.datagrid-table > tbody > tr > td {
  border: none;
  padding: 0 !important;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datagrid > table.table.datagrid-table > tbody > tr > td .formio-component-table {
  padding: 0 !important;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datagrid > table.table.datagrid-table > tbody > tr > td .formio-component-table > table.table > tbody > tr {
  align-items: center;
  margin: 0;
  grid-auto-columns: 25% auto min-content;
  grid-auto-flow: column;
  position: relative;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datagrid > table.table.datagrid-table > tbody > tr > td .formio-component-table > table.table > tbody > tr:not(.formio-hidden) {
  display: grid;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datagrid > table.table.datagrid-table > tbody > tr > td .formio-component-table > table.table > tbody > tr > td {
  display: flex;
  align-items: center;
  border: none;
  border-inline-end: solid 1px #e3e3e6 !important;
  border-bottom: solid 1px #e3e3e6 !important;
  padding: 0 1.2rem !important;
  align-self: stretch;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datagrid > table.table.datagrid-table > tbody > tr > td .formio-component-table > table.table > tbody > tr > td > .formio-component:not(.formio-hidden) {
  min-height: 39px;
  place-items: center;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datagrid > table.table.datagrid-table > tbody > tr > td .formio-component-table > table.table > tbody > tr > td:first-child {
  flex: 0 0 25%;
  height: auto;
  align-self: stretch;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datagrid > table.table.datagrid-table > tbody > tr > td .formio-component-table > table.table > tbody > tr > td:first-child + td {
  padding: 0 !important;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datagrid > table.table.datagrid-table > tbody > tr > td .formio-component-table > table.table > tbody > tr > td:first-child + td:nth-last-child(3) {
  min-width: initial;
  max-width: initial;
  border-inline-end: none !important;
  padding-inline-end: 36px !important;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datagrid > table.table.datagrid-table > tbody > tr > td .formio-component-table > table.table > tbody > tr > td:first-child + td:nth-last-child(3) > .formio-component:not(.formio-hidden) {
  display: flex;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datagrid > table.table.datagrid-table > tbody > tr > td .formio-component-table > table.table > tbody > tr > td:first-child + td + td:nth-last-child(2) {
  order: 3;
  width: fit-content;
  margin-inline-start: auto;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datagrid > table.table.datagrid-table > tbody > tr > td .formio-component-table > table.table > tbody > tr > td:first-child + td + td:nth-last-child(2) > .formio-component-checkbox {
  justify-content: flex-end;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datagrid > table.table.datagrid-table > tbody > tr > td .formio-component-table > table.table > tbody > tr > td:first-child + td .formio-component-htmlelement p {
  font-size: 15px;
  font-weight: 500;
  color: #404451;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datagrid > table.table.datagrid-table > tbody > tr > td .formio-component-table > table.table > tbody > tr > td:first-child + td > .formio-component:not(.formio-component-datagrid) {
  margin-inline-start: 1.2rem;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datagrid > table.table.datagrid-table > tbody > tr > td .formio-component-table > table.table > tbody > tr > td:first-child + td:last-child > .formio-component:not(.formio-hidden) {
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datagrid > table.table.datagrid-table > tbody > tr > td .formio-component-table > table.table > tbody > tr > td:first-child + td:last-child > .formio-component:where(.formio-component-radio, .formio-component-selectboxes) .form-radio {
  margin-top: 0;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datagrid > table.table.datagrid-table > tbody > tr > td .formio-component-table > table.table > tbody > tr > td:first-child + td:last-child > .formio-component:where(.formio-component-radio, .formio-component-selectboxes) .form-radio .form-check:first-child {
  margin-top: 0;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datagrid > table.table.datagrid-table > tbody > tr > td .formio-component-table > table.table > tbody > tr > td:first-child > .formio-component:not(.formio-hidden) {
  display: flex;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datagrid > table.table.datagrid-table > tbody > tr > td .formio-component-table > table.table > tbody > tr > td:nth-of-type(4) {
  padding: 0 !important;
  min-width: 0px;
  flex: auto;
  position: absolute;
  width: fit-content;
  inset-inline-end: 80px;
  isolation: isolate;
  z-index: 9;
  min-height: 0px;
  border: none !important;
  transform: translateY(-50%);
  top: 50%;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datagrid > table.table.datagrid-table > tbody > tr > td .formio-component-table > table.table > tbody > tr > td:nth-of-type(4) [class*="_validation_reason"]:not(:empty) {
  margin: 0;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datagrid > table.table.datagrid-table > tbody > tr > td .formio-component-table > table.table > tbody > tr > td:nth-of-type(4) [class*="_validation_reason"]:not(:empty) textarea {
  margin: 0 !important;
  resize: vertical;
  width: 24px;
  height: 24px !important;
  transition-duration: 0.15s;
  transform: scale(0);
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datagrid > table.table.datagrid-table > tbody > tr > td .formio-component-table > table.table > tbody > tr > td:nth-of-type(4) [class*="_validation_reason"]:not(:empty) textarea:focus {
  width: 100%;
  height: auto !important;
  transform: scale(1);
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datagrid > table.table.datagrid-table > tbody > tr > td .formio-component-table > table.table > tbody > tr > td:nth-of-type(4) [class*="_validation_reason"]:not(.formio-hidden) > div[ref="element"] {
  padding: 10px;
  border-radius: 6px;
  position: relative;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datagrid > table.table.datagrid-table > tbody > tr > td .formio-component-table > table.table > tbody > tr > td:nth-of-type(4) [class*="_validation_reason"]:not(.formio-hidden) > div[ref="element"]::after {
  content: "\f4a6";
  position: absolute;
  z-index: 99;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  display: grid;
  place-items: center;
  cursor: pointer;
  outline: solid 4px white;
  font-family: var(--fontawesome-font);
  font-weight: 400;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-size: 18px;
  background: white;
  padding: 2px;
  color: #d56d72;
  transition-duration: 0.15s;
  transition-property: color, background, outline;
  inset-inline-start: calc(50% - 12px);
  inset-block-start: calc(50% - 12px);
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datagrid > table.table.datagrid-table > tbody > tr > td .formio-component-table > table.table > tbody > tr > td:nth-of-type(4) [class*="_validation_reason"]:not(.formio-hidden) > div[ref="element"]::before {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 3px;
  transform: rotate(45deg);
  inset-inline-end: -6px;
  z-index: -1;
  display: none;
  background: white;
  top: calc(50% - 7px);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.4);
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datagrid > table.table.datagrid-table > tbody > tr > td .formio-component-table > table.table > tbody > tr > td:nth-of-type(4) [class*="_validation_reason"]:not(.formio-hidden) > div[ref="element"]:focus-within {
  background: white;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  margin-inline-end: 10px;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datagrid > table.table.datagrid-table > tbody > tr > td .formio-component-table > table.table > tbody > tr > td:nth-of-type(4) [class*="_validation_reason"]:not(.formio-hidden) > div[ref="element"]:focus-within::after {
  z-index: -1;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datagrid > table.table.datagrid-table > tbody > tr > td .formio-component-table > table.table > tbody > tr > td:nth-of-type(4) [class*="_validation_reason"]:not(.formio-hidden) > div[ref="element"]:focus-within::before {
  display: block;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datagrid > table.table.datagrid-table > tbody > tr > td .formio-component-table > table.table > tbody > tr > td:nth-of-type(4) [class*="_validation_reason"]:not(.formio-hidden) > div[ref="element"]:hover::after {
  color: #ffffff;
  background: #a3a3a3;
  outline: solid 4px #a3a3a3;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datagrid > table.table.datagrid-table > tbody > tr > td .formio-component-table > table.table > tbody > tr > td:nth-of-type(4):focus-within {
  width: clamp(320px, 30vw, 30vw);
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datagrid > table.table.datagrid-table > tbody > tr > td .formio-component-table > table.table > tbody > tr > td input:hover {
  outline: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datagrid > table.table.datagrid-table > tbody > tr > td .formio-component-table > table.table > tbody > tr > td .formio-component {
  margin-top: 0;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datagrid > table.table.datagrid-table > tbody > tr > td .formio-component-table > table.table > tbody > tr > td:last-child {
  border-inline-end: none !important;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datagrid > table.table.datagrid-table > tbody > tr > td .formio-component-table > table.table > tbody > tr > td .formio-component-htmlelement p {
  text-align: start;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datagrid > table.table.datagrid-table > tbody > tr > td .formio-component-table > table.table > tbody > tr > td .checkbox + .checkbox {
  margin-top: 0;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datagrid > table.table.datagrid-table > tbody > tr > td .formio-component-table > table.table > tbody > tr:last-child > td {
  border-bottom: solid 3px #e3e3e6 !important;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datagrid > table.table.datagrid-table > tbody > tr > td .formio-component-table > table.table > tbody > tr:focus-within {
  z-index: 99;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-datagrid > table.table.datagrid-table > tbody > tr:last-child > td .formio-component-table > table.table > tbody > tr:last-child > td {
  border-bottom: none !important;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group):where(.formio-component-textfield, .formio-component-number, .formio-component-currency, .formio-component-datetime) .input-group {
  height: auto;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group):where(.formio-component-textfield, .formio-component-number, .formio-component-currency, .formio-component-datetime) .input-group :where(.input-group-prepend, .input-group-append) {
  background: transparent;
  border: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group):where(.formio-component-textfield, .formio-component-number, .formio-component-currency, .formio-component-datetime) .input-group :where(.input-group-prepend, .input-group-append) .input-group-text {
  padding: 0;
  font-size: 15px;
  color: #404451;
  margin-inline-end: 6px;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group):where(.formio-component-textfield, .formio-component-number, .formio-component-currency, .formio-component-datetime) .field-wrapper > div[style*="margin-inline-start: 33%;"] {
  margin-inline-start: 0 !important;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group):where(.formio-component-textfield, .formio-component-number, .formio-component-currency, .formio-component-datetime) .form-text.text-muted {
  display: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group):where(.formio-component-textfield, .formio-component-number, .formio-component-currency, .formio-component-datetime) :where(input, .input-group input).form-control:where([type="text"], [type="search"], [type="phoneNumber"], [type="email"]) {
  height: 28px !important;
  border: none !important;
  background-color: transparent !important;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group):where(.formio-component-textfield, .formio-component-number, .formio-component-currency, .formio-component-datetime) :where(input, .input-group input).form-control:where([type="text"], [type="search"], [type="phoneNumber"], [type="email"]):where(:focus, :hover) {
  outline: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group):where(.formio-component-textfield, .formio-component-number, .formio-component-currency, .formio-component-datetime) :where(input, .input-group input).form-control:where([type="text"], [type="search"], [type="phoneNumber"], [type="email"]):disabled {
  background-color: transparent !important;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-selectboxes .form-radio {
  margin-top: 0;
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  gap: 20px;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-selectboxes .form-radio :where(.form-check, .form-check-inline) {
  display: flex;
  margin-bottom: 0;
  margin-inline-end: 0;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-selectboxes .form-radio :where(.form-check, .form-check-inline):not(.radio-selected) {
  display: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-selectboxes .form-radio :where(.form-check, .form-check-inline) label.form-check-label {
  margin: 0;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-selectboxes .form-radio :where(.form-check, .form-check-inline) label.form-check-label input {
  display: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-selectboxes .form-radio :where(.form-check, .form-check-inline) label.form-check-label input + span {
  display: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-selectboxes .form-radio :where(.form-check, .form-check-inline) label.form-check-label input[checked="true"] + span {
  display: flex;
  font-size: 15px;
  font-weight: 500;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-selectboxes .form-radio :where(.form-check, .form-check-inline) label.form-check-label::before {
  display: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-selectboxes .form-radio .checkbox + .checkbox {
  margin-top: 0;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-selectboxes > label {
  display: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-checkbox.check-switch .form-check.checkbox {
  margin-top: 0;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-file > label {
  display: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group).formio-component-file ul.list-group {
  width: 100%;
  padding-inline-end: 1.2rem;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group) > .formio-component.formio-component-table table.table {
  table-layout: auto;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group) select.formio-multiple-mask-select {
  appearance: none;
  background: transparent;
  border: none;
  width: auto;
  padding-inline-start: 0;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :where(.formio-component, .form-group) select.formio-multiple-mask-select:hover {
  outline: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div[class^="col-"] > .formio-component.formio-component-table {
  margin-inline-start: -12px;
  margin-inline-end: -12px;
  margin-top: -7px;
  margin-bottom: -7px;
  width: calc(100% + 24px);
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div[class^="col-"] > .formio-component.formio-component-table table.table {
  table-layout: auto;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div[class^="col-"] > .formio-component.formio-component-table table.table tbody tr td {
  padding: 6px 1.2rem;
  border: solid 1px #e3e3e6 !important;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div[class^="col-"] > .formio-component.formio-component-table table.table tbody tr td:first-child {
  vertical-align: middle;
  width: 30%;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div[class^="col-"] > .formio-component.formio-component-table table.table tbody tr td:first-child .formio-component-htmlelement p {
  text-align: start;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div[class^="col-"] > .formio-component.formio-component-table table.table tbody tr td .formio-component {
  margin: 0;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns > div[class^="col-"] > .formio-component.formio-component-table table.table tbody tr td .formio-component.formio-component-table {
  margin-inline-start: -12px !important;
  margin-inline-end: -12px !important;
  margin-top: -7px !important;
  margin-bottom: -7px !important;
  width: calc(100% + 24px);
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns.type-selectboxes > .col-md-10:nth-last-child(3) {
  min-width: 200px !important;
  flex: 1 !important;
  max-width: 40% !important;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns label.field-required {
  display: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns label.field-required::after {
  display: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns.type-editgrid > .col-md-10 + .col-md-6.cols-grid-end,
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns.type-editgrid > .col-md-12 + .col-md-6 {
  display: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns.type-editgrid:has(.col-md-6.cols-grid-end) .col-md-10 {
  width: 100%;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns.type-editgrid > .col-md-10:first-child {
  margin-inline-end: 0 !important;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns.type-editgrid > .col-md-10:first-child .formio-component-datagrid {
  margin-top: 0px;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns.type-editgrid > .col-md-10:first-child .formio-component-datagrid .form-check.checkbox {
  margin-top: 0px;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns.type-editgrid > .col-md-10:first-child .formio-component-datagrid [class*="_validation_reason"] {
  inset-inline-end: 20px;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns.type-datagrid .formio-component-datagrid .formio-component-table td:first-child .formio-component-htmlelement p {
  margin-top: 0px;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns.type-datagrid .formio-component-datagrid .formio-component-table .input-value {
  font-weight: 400;
  font-size: 15px;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns:is(.type-datagrid, .type-editgrid) {
  flex-wrap: wrap;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns:is(.type-datagrid, .type-editgrid) > .col-md-2:first-child {
  flex: 0 0 100%;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns:is(.type-datagrid, .type-editgrid) > .col-md-2:first-child + .col-md-10 {
  flex: 0 0 100%;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns:is(.type-datagrid, .type-editgrid) table.datagrid-table table.table {
  table-layout: auto;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns:is(.type-datagrid, .type-editgrid) table.datagrid-table table.table td + td {
  border-inline-start: solid 1px #e3e3e6;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns:is(.type-datagrid, .type-editgrid) table.datagrid-table table.table td {
  font-size: 14px;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns:is(.type-datagrid, .type-editgrid) table.datagrid-table table.table td[ref*="table-"] {
  border: solid 1px #e3e3e6 !important;
  padding: 6px 12px;
  height: 40px;
  vertical-align: middle;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns:is(.type-datagrid, .type-editgrid) table.datagrid-table table.table td[ref*="table-"]:first-child {
  width: 25%;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns:is(.type-datagrid, .type-editgrid) table.datagrid-table table.table td[ref*="table-"] .formio-component {
  margin-top: 0px;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns:is(.type-datagrid, .type-editgrid) table.datagrid-table table.table td[ref*="table-"] .formio-component.formio-component-radio .form-radio {
  margin-top: 0px;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns:is(.type-datagrid, .type-editgrid) table.datagrid-table table.table td[ref*="table-"] .formio-component.formio-component-file .list-group {
  padding: 0;
  margin: 0;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns:is(.type-datagrid, .type-editgrid) table.datagrid-table .dg-action-col {
  display: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns:is(.type-datagrid, .type-editgrid) table.datagrid-table td.table {
  padding: 0;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns:is(.type-datagrid, .type-editgrid) table.datagrid-table .formio-component-htmlelement {
  margin-top: 0px !important;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns.type-file strong {
  font-weight: 500;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns.type-file ul.list-group {
  padding-inline-end: 0px;
  margin-bottom: 0px;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns:is(.type-htmlelement, .type-content) strong {
  font-weight: 700;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns:is(.type-htmlelement, .type-content) .text-right {
  text-align: start !important;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns:is(.type-htmlelement, .type-content) .text-lg {
  font-size: 100%;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns.type-select .value {
  line-height: 1.3;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns.type-select .value[ref="tooltip"]::after,
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns.type-select .value[ref="tooltip"]::before {
  display: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns.type-select .key {
  margin-inline-end: 5px;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns.type-textarea .card.card-body {
  padding: 0 !important;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns.type-hidden {
  display: none;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns .input-prefix {
  margin-inline-end: 5px;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns .input-suffix {
  margin-inline-start: 5px;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns .currency-prefixed {
  margin-inline-end: 5px;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns .currency-suffixed:not(:empty) {
  margin-inline-start: 5px;
}
body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns p[ref="html"]:has(.currency-prefixed, .currency-suffixed) {
  display: flex;
}
body.modern-theme .error-page-container {
  width: 90%;
  max-width: 500px;
  margin: 0 auto;
  justify-content: space-between;
  background: white;
  border-radius: 6px;
  padding: 24px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  display: flex;
  gap: 12px;
}
body.modern-theme .error-page-container h1 {
  margin-top: 0;
}
body.modern-theme .error-page-container .error-body {
  font-size: 14pt;
  line-height: 20pt;
}
body.modern-theme .error-page-container a {
  border-radius: 6px !important;
  background-color: var(--color-primary-600);
  padding: 12px 25px;
  font-weight: 600;
  line-height: 1;
  color: white;
  text-align: center;
  font-size: 16px;
  display: inline-block;
  margin-top: auto;
  width: fit-content;
}
body.modern-theme .error-page-container a:hover {
  text-decoration: none;
  box-shadow: 0 9px 20px rgba(70, 81, 170, 0.4) !important;
  opacity: 0.9;
}
body.modern-theme .error-page-container .error-page-message {
  display: flex;
  flex-direction: column;
  flex: 2.5;
}
body.modern-theme .error-page-container .error-page-illustration {
  flex: 1;
  position: relative;
}
body.modern-theme .error-page-container .error-page-illustration span {
  position: absolute;
  font-size: clamp(18pt, 3.5vw, 30pt);
  font-weight: 600;
  color: #334bb3;
  text-align: center;
  width: 86%;
  height: 32%;
  display: flex;
  align-items: center;
  justify-content: center;
}
body.modern-theme .error-page-container .error-page-illustration img {
  height: 100%;
}
body.modern-theme .formio-component-panel.readonly-forms-block {
  margin-bottom: 0;
}
body.modern-theme .formio-component-panel.readonly-forms-block + .formio-component-panel {
  margin-top: 35px !important;
}
body.modern-theme .formio-component-panel.readonly-forms-block .formio-component-panel {
  margin-bottom: 0px;
}
body.modern-theme .formio-component-panel.readonly-forms-block .formio-component-panel + .formio-component-panel {
  margin-top: 35px;
}
body.modern-theme .part-b-data-tab .field-wrapper [style*="margin"] {
  margin-inline: 0px !important;
}
body.modern-theme .part-b-data-tab .readonly-forms-components-container:first-child {
  margin-top: 0 !important;
}
body.modern-theme .part-b-data-tab .readonly-forms-block {
  margin-inline: 0px !important;
}
body.modern-theme .part-b-data-tab .data-review-validation-column {
  flex-wrap: wrap;
}
body.modern-theme .part-b-data-tab .data-review-validation-column > [class*="col-"]:first-child {
  flex: initial;
  width: fit-content;
}
body.modern-theme .part-b-data-tab .data-review-validation-column .cols-grid-end {
  flex: auto;
  width: initial;
}
body.modern-theme .part-b-data-tab .data-review-validation-column > .formio-errors {
  flex: 0 0 100%;
  width: initial;
}
body.modern-theme .part-b-data-tab .data-review-validation-column .validator-radio .col-form-label {
  font-size: 18px;
  font-weight: 600;
  color: var(--color-gray-800);
  line-height: 1;
}
body.modern-theme .part-b-data-tab .data-review-validation-column .validator-radio .col-form-label::after {
  display: none;
}
body.modern-theme .part-b-data-tab .data-review-validation-column .validator-radio .radio.form-check-inline {
  font-weight: 500;
}
body.modern-theme .part-b-data-tab .data-review-validation-column .validator-radio .radio.form-check-inline:is(:hover, .radio-selected) span {
  color: white;
}
body.modern-theme .part-b-data-tab .data-review-validation-column .validator-radio .radio.form-check-inline:first-child {
  background-color: rgba(70, 81, 170, 0.1);
  border-radius: 6px 0 0 6px;
}
body.modern-theme .part-b-data-tab .data-review-validation-column .validator-radio .radio.form-check-inline:first-child:hover {
  background-color: #80c49b;
  color: white;
}
body.modern-theme .part-b-data-tab .data-review-validation-column .validator-radio .radio.form-check-inline:first-child.radio-selected {
  background-color: #80c49b;
  color: white;
}
body.modern-theme .part-b-data-tab .data-review-validation-column .validator-radio .radio.form-check-inline:last-child {
  background-color: rgba(70, 81, 170, 0.05);
  border-radius: 0 6px 6px 0;
}
body.modern-theme .part-b-data-tab .data-review-validation-column .validator-radio .radio.form-check-inline:last-child:hover {
  background-color: #d56d72;
  color: white;
}
body.modern-theme .part-b-data-tab .data-review-validation-column .validator-radio .radio.form-check-inline:last-child.radio-selected {
  background-color: #d56d72;
  color: white;
}
body.modern-theme .part-b-data-tab .data-review-validation-column .formio-component-textarea .col-form-label {
  color: var(--color-gray-600);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  line-height: 19px;
  margin-top: 6px;
}
body.modern-theme .part-b-data-tab .data-review-validation-column .formio-component-textarea .col-form-label::after {
  content: " ";
  display: inline-block;
  background-color: rgba(255, 0, 59, 0.7);
  vertical-align: unset;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  position: relative;
  top: -1px;
  inset-inline-start: 2px;
  min-width: 6px;
}
body.modern-theme .part-b-data-tab .data-review-validation-column .formio-component-textarea textarea {
  border: solid 1px var(--color-gray-300);
  border-radius: 6px !important;
}
body.modern-theme .part-b-data-tab .data-review-validation-column .formio-component-textarea:not(.formio-modified) textarea {
  border: solid 1px #F75F5F;
}
body.modern-theme .part-b-data-tab .formio-component-validationtable table.table {
  table-layout: auto;
}
body.modern-theme .part-b-data-tab .formio-component-validationtable td:first-child {
  flex: 2;
  padding: 0 !important;
  width: 90%;
}
body.modern-theme .part-b-data-tab .formio-component-validationtable .formio-component-htmlelement {
  margin-top: 6px;
}
body.modern-theme .part-b-data-tab .formio-component-validationtable .check-switch .form-check {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  align-items: center;
  display: flex;
  gap: 12px;
  margin-inline-end: 32px;
}
body.modern-theme .part-b-data-tab .formio-component-validationtable .check-switch .form-check span {
  color: #ffa076;
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
}
body.modern-theme .part-b-data-tab .formio-component-validationtable #datarejectionreason {
  flex: auto;
}
body.modern-theme .part-b-data-tab .formio-component-validationtable #datarejectionreason label {
  font-size: 15px;
}
body.modern-theme .part-b-data-tab .formio-component-validationtable #datarejectionreason label::after {
  background-color: rgba(255, 0, 59, 0.7);
  border-radius: 5px;
  content: " ";
  display: inline-block;
  height: 6px;
  inset-inline-start: 4px;
  min-width: 6px;
  position: relative;
  top: -1px;
  vertical-align: unset;
  width: 6px;
}
body.modern-theme .part-b-data-tab .formio-component-validationtable #datarejectionreason textarea {
  font-size: 14px;
  border-color: rgba(255, 0, 59, 0.7) !important;
  color: #1b2331;
  border-radius: 6px;
  min-height: 64px;
  padding: 4px 10px;
  width: 100%;
}
body.modern-theme .part-b-data-tab .formio-component-validationtable #datarejectionreason textarea:focus {
  outline: solid 4px rgba(255, 0, 59, 0.2);
}
body.modern-theme select:is(.form-control, .flatpickr-monthDropdown-months, .flatpickr-monthDropdown-months:hover) {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath d='M310.6 246.6l-127.1 128C176.4 380.9 168.2 384 160 384s-16.38-3.125-22.63-9.375l-127.1-128C.2244 237.5-2.516 223.7 2.438 211.8S19.07 192 32 192h255.1c12.94 0 24.62 7.781 29.58 19.75S319.8 237.5 310.6 246.6z' fill='%234651aa'/%3E%3C/svg%3E");
  background-size: 8px;
  background-repeat: no-repeat;
  background-position: right 12px center;
  padding-inline-end: 32px;
}
body.modern-theme select option {
  background: white;
  font-weight: normal;
}
body.modern-theme .search-field {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxMyAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDVDMCAyLjIzODU5IDIuMjM4NTMgMCA1IDBDNy43NjE0NyAwIDEwIDIuMjM4NTkgMTAgNUMxMCA1LjkyNzYxIDkuNzQ3NDQgNi43OTYyNiA5LjMwNzI1IDcuNTQwODNMMTIuNTUzNSAxMC4wODIzTDExLjkzNyAxMC44Njk3TDguNzE0NiA4LjM0NjkyQzcuNzk5NDQgOS4zNjE5NCA2LjQ3NDI0IDEwIDUgMTBDMi4yMzg1MyAxMCAwIDcuNzYxNDEgMCA1Wk0xIDVDMSAyLjc5MDgzIDIuNzkwODkgMSA1IDFDNy4yMDkxMSAxIDkgMi43OTA4MyA5IDVDOSA3LjIwOTE3IDcuMjA5MTEgOSA1IDlDMi43OTA4OSA5IDEgNy4yMDkxNyAxIDVaIiBmaWxsPSIjMzczNzM3Ii8+Cjwvc3ZnPgo=);
  background-repeat: no-repeat;
  background-position: center right 10px;
}
body.modern-theme .choices__list--dropdown,
body.modern-theme .choices__list[aria-expanded] {
  z-index: 100;
}
body.modern-theme .warning-list[hidden].modern-warning-list {
  background-color: var(--color-gray-100);
  box-shadow: -1200px 0 0 var(--color-gray-100), -2400px 0 0 var(--color-gray-100), 1200px 0 0 var(--color-gray-100), 2400px 0 0 var(--color-gray-100);
}
body.modern-theme .warning-list[hidden].modern-warning-list li.several-msg span {
  color: var(--color-primary-600);
  font-weight: 500;
}
body.modern-theme .warning-list[hidden].modern-warning-list li.several-msg strong {
  color: var(--color-primary-600);
}
body.modern-theme .service-bar-user-lang-menu ul li a {
  font-weight: 400 !important;
}
body.modern-theme #header .secondary-menu {
  background-color: var(--menu-bar-bg-color);
  border-block: solid 1px var(--color-gray-200);
}
body.modern-theme #header .secondary-menu .contain-lg nav.secondary-nav-container .services-select {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
body.modern-theme #header .secondary-menu .contain-lg nav.secondary-nav-container .services-select.active .text {
  opacity: 1;
}
body.modern-theme #header .secondary-menu .contain-lg nav.secondary-nav-container .services-select.active .search:focus + .text {
  opacity: 0.15;
}
body.modern-theme #header .secondary-menu .contain-lg nav.secondary-nav-container .services-select .menu .item {
  font-size: 14px;
  letter-spacing: 0;
}
body.modern-theme #header .secondary-menu .contain-lg nav.secondary-nav-container .all-roles-menu ul.secondary-nav li.active a {
  background-color: rgba(255, 255, 255, 0.1);
  font-size: 14px !important;
}
body.modern-theme #header .secondary-menu .contain-lg nav.secondary-nav-container .all-roles-menu ul.secondary-nav li.togglers label.menu-toggler span.count-roles {
  background-color: var(--color-primary-600);
}
body.modern-theme #header .secondary-menu .contain-lg nav.secondary-nav-container .all-roles-menu ul.secondary-nav li.role-menu-item a span {
  font-size: 14px;
}
body.modern-theme #header .secondary-menu .contain-lg nav.secondary-nav-container .all-roles-menu ul.secondary-nav .bots-toggler span {
  font-size: 14px !important;
}
body.modern-theme #base #content .demo-mode-heading {
  background-color: var(--color-gray-100) !important;
}
body.modern-theme #base #content .demo-mode-heading span {
  font-weight: 500 !important;
  color: var(--color-primary-600);
}
body.modern-theme #base #content .section-body .contain-lg .dashboard #tab-applications #services-list .services-title-search-container .services-search-icon {
  z-index: -1;
}
body.modern-theme #base #content .section-body .contain-lg .dashboard #tab-applications #services-list .services-title-search-container h2.big-title {
  color: var(--custom-color, var(--color-gray-900));
  font-weight: 500;
}
body.modern-theme #base #content .section-body .contain-lg .dashboard #tab-applications #services-list .services-title-search-container #available-services-filter {
  box-shadow: none;
  transition-duration: 0.15s;
}
body.modern-theme #base #content .section-body .contain-lg .dashboard #tab-applications #services-list .services-title-search-container #available-services-filter:where(:focus, :hover) {
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 0 0 1px var(--color-primary-600);
}
body.modern-theme #base #content .section-body .contain-lg .dashboard #tab-applications #services-list .services-title-search-container #available-services-filter:hover {
  opacity: 0.8;
}
body.modern-theme #base #content .section-body .contain-lg .dashboard #tab-applications #services-list .services-title-search-container #available-services-filter:where(:focus, :focus:hover) {
  opacity: 1;
}
body.modern-theme #base #content .section-body .contain-lg .dashboard #tab-applications #services-list .services-title-search-container:focus-within .services-search-icon {
  z-index: 1;
}
body.modern-theme #base #content .section-body .contain-lg .dashboard #tab-applications #services-list .dashboard-tiles .dashboard-tile .service-icon span {
  display: none;
}
body.modern-theme #base #content .section-body .contain-lg .dashboard #tab-applications #services-list .dashboard-tiles .dashboard-tile .service-name {
  color: var(--custom-color, var(--color-gray-900));
}
body.modern-theme #base #content .section-body .contain-lg #entreprise-grid .table-responsive-container table thead tr th {
  letter-spacing: 0;
}
body.modern-theme #base #content .section-body .contain-lg #entreprise-grid .table-responsive-container table tbody tr td :is(span.label-status, span.label) {
  letter-spacing: 0.6px;
}
body.modern-theme #base #content .section-body .contain-lg .overview-panel table.part-b-single-file-resume thead tr th {
  letter-spacing: 0;
}
body.modern-theme #base #content .section-body #formio-form-role .formio-form > .formio-component-tabs > .card .formio-component-part-b-documents-tab ~ .formio-component #validationHeader .form-field-type-mycounter span.bullet {
  line-height: 16px;
}
body.modern-theme #base #content .section-body #formio-form-role .formio-form > .formio-component-tabs > .card .formio-component-part-b-documents-tab ~ .formio-component #validationHeader [id^="document-"] span.label {
  padding: 4px 8px;
}
body.modern-theme #base #content .section-body #formio-form-role .formio-form > .formio-component-tabs > .card .formio-component-costtable table.table tbody tr td {
  padding: 5px 0;
}
body.modern-theme #base #content .section-body #formio-form-role .formio-form > .formio-component-tabs > .card .formio-component-costtable table.table tbody tr td h4 {
  font-size: 16px;
  font-weight: 600;
}
body.modern-theme #base #content .section-body #formio-form-role .formio-form > .formio-component-tabs > .card .formio-component-costtable table.table tbody tr td [class*="totalPrice"] p {
  font-weight: 600;
}
body.modern-theme #base #content .section-body #formio-form-role .formio-form > .formio-component-tabs > .card .formio-component-costtable ~ .formio-component-columns {
  margin-top: 3rem !important;
}
body.modern-theme #base #content .section-body #formio-form-role .formio-form > .formio-component-tabs > .card .formio-component-costtable ~ .formio-component-confirmationRow {
  padding: 0 !important;
}
body.modern-theme #base #content .section-body #formio-form-role .formio-form > .formio-component-tabs > .card .formio-component-costtable ~ .formio-component-confirmationRow article.margin-bottom-xxl {
  margin-bottom: 0 !important;
}
body.modern-theme #base #content .section-body .readonly-form-columns p:only-child {
  margin-top: 0;
  margin-bottom: 0;
}
body.modern-theme #base #content:has(.choices__list--dropdown.is-active) .affix-menu-size-container:not(.affix) ~ .section-body {
  z-index: 1051;
}
body.modern-theme #select-business-entity .modal-dialog .panel-heading .panel-title {
  color: var(--color-gray-800);
}
body.modern-theme #select-business-entity .modal-dialog .panel-body .radio label input[type="radio"]:checked::before {
  color: var(--color-primary-600);
}
body.modern-theme #select-business-entity .modal-dialog .panel-body .radio label input[type="radio"] + span {
  color: var(--color-gray-800) !important;
}
body.modern-theme #select-business-entity .modal-dialog .panel .panel-footer a {
  background-color: var(--color-primary-600);
}
body.modern-theme .radio + .radio,
body.modern-theme .checkbox + .checkbox {
  margin-top: initial;
}
body.modern-theme :not(:defined) {
  visibility: initial;
}
body.modern-theme .open-modal-button {
  --button-bg-color: var(--color-primary-600);
  --button-text-color: white;
  --button-text-color-hover: white;
  width: initial;
  background-color: var(--button-bg-color);
  font-weight: 500;
  color: var(--button-text-color) !important;
  gap: 10px;
  display: inline-flex;
  align-items: center;
}
body.modern-theme .open-modal-button:hover {
  color: var(--button-text-color-hover);
}
body.modern-theme .column-gap {
  column-gap: var(--gap-value, 0px);
}
body.modern-theme .flex-wrap {
  flex-wrap: wrap;
}
body.modern-theme #content > .section-body:has(.formio-component-modal-wrapper) {
  z-index: 2;
}
body .formio-hidden {
  display: none;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--color-primary-600);
}
::-webkit-scrollbar-track {
  background-color: rgba(70, 81, 170, 0.4);
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes buttonProgress {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes buttonFeedback {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  90% {
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes hglightComp {
  0% {
    background-color: rgba(255, 0, 59, 0.15);
    box-shadow: 0 0 0 12px rgba(255, 0, 59, 0.15);
  }
  100% {
    background-color: rgba(255, 0, 59, 0);
    box-shadow: 0 0 0 12px rgba(255, 0, 59, 0);
  }
}
@keyframes hglightOutline {
  0% {
    outline-color: hsl(346.26, 100%, 65%);
  }
  100% {
    outline-color: hsl(0, 0%, 100%);
  }
}
@keyframes shake {
  0% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0px);
  }
}
.choose-product-btn {
  align-items: center !important;
  background-color: rgba(70, 81, 170, 0.1) !important;
  border: none !important;
  border-radius: 6px !important;
  color: var(--color-gray-800) !important;
  display: inline-flex;
  font-size: 16px;
  font-weight: 500 !important;
  height: 40px;
  padding-inline: 24px;
  padding-block: 10px;
  transition-duration: 0.3s;
}
.choose-product-btn:hover {
  background-color: rgba(70, 81, 170, 0.1) !important;
  box-shadow: var(--button-shadow-hover) !important;
  color: var(--color-gray-800) !important;
  opacity: 0.9;
}
:root {
  --tab-content-items-gap: 3rem;
}
body.modern-theme {
  height: auto;
}
body.modern-theme .secondary-menu {
  background-color: var(--menu-bar-bg-color);
  border-block: solid 1px var(--color-gray-200);
  z-index: 1050;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container {
  display: flex;
  background: transparent;
  align-items: center;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container :where(.part-b-menu-services, .part-b-menu-roles) :where(.counter, .active-role-files-count, .files-count) {
  background: var(--color-gray-400);
  color: var(--color-white);
  border-radius: 20px;
  padding-inline: 6px;
  padding-block: 2px;
  margin-inline-start: 5px;
  font-size: 10px;
  height: 15px;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .counter {
  top: 1px;
  position: relative;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .part-b-menu-services {
  background-color: var(--part-b-services-menu-bg-color, #CCC);
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .part-b-menu-roles {
  background-color: var(--part-b-roles-menu-bg-color, #EEE);
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .part-b-menu-roles.file-view .roles-ui-dropdown .menu .item {
  padding: 0 !important;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .part-b-menu-roles.file-view .roles-ui-dropdown .menu .item a {
  padding: 14px 16px !important;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  color: black;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .part-b-menu-roles.file-view .roles-ui-dropdown .menu .item a:where(:hover, :active, :focus) {
  text-decoration: none;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .part-b-menu-title {
  font-size: 10px;
  text-transform: uppercase;
  padding-inline-start: 20px;
  padding-block-start: 10px;
  margin-block-end: -6px;
  line-height: 1;
  position: relative;
  z-index: 1002;
  display: flex;
  align-items: center;
  color: var(--color-gray-900);
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .part-b-menu-title a {
  display: flex;
  margin-inline-start: auto;
  margin-inline-end: 12px;
  align-items: center;
  gap: 4px;
  border-radius: 2px;
  color: var(--color-gray-900);
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .part-b-menu-title a:where(:hover, :active, :focus) {
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2);
  text-decoration: none;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .part-b-menu-title a span {
  text-transform: none;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container ul.secondary-nav li a {
  background: transparent;
  height: 40px;
  display: flex;
  align-items: center;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container ul.secondary-nav li a:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container ul.secondary-nav li.active a {
  background-color: rgba(0, 0, 0, 0.1);
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container :where(.services-select, .roles-ui-dropdown) {
  background-color: transparent !important;
  color: var(--color-gray-900);
  height: 100%;
  display: flex;
  align-items: center;
  padding-inline-end: 32px !important;
  padding-inline-start: 18px !important;
  min-height: 56px !important;
  min-width: 240px;
  border-inline-start: solid 1px transparent !important;
  border-inline-end: solid 1px transparent !important;
  animation: fadeIn 150ms forwards;
  margin-top: -24px;
  padding-top: 22px !important;
  padding-bottom: 0 !important;
  border-radius: 0;
  border: none;
  float: none;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container :where(.services-select, .roles-ui-dropdown) i.dropdown {
  padding: 0 12px;
  inset-inline-end: 0px !important;
  margin: 0;
  top: 23px;
  height: 32px;
  font-family: var(--fontawesome-font);
  font-weight: 300;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-size: 16px;
  align-items: center;
  display: inline-flex;
  right: initial;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container :where(.services-select, .roles-ui-dropdown) i.dropdown::before {
  content: "\F107";
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container :where(.services-select, .roles-ui-dropdown) input {
  height: 32px;
  top: 20px;
  padding-block: 0;
  padding-inline-start: 18px !important;
  padding-inline-end: 34px;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container :where(.services-select, .roles-ui-dropdown) .menu {
  border: none;
  border-radius: 6px !important;
  max-height: 340px;
  top: calc(100% + 6px);
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container :where(.services-select, .roles-ui-dropdown) .menu .item {
  text-align: start;
  font-size: 14px;
  line-height: 1.3;
  padding: 14px 16px !important;
  font-weight: 400;
  opacity: 0.8;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container :where(.services-select, .roles-ui-dropdown) .menu .item.active {
  opacity: 1;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container :where(.services-select, .roles-ui-dropdown) .menu .item .files-count {
  font-size: 9px;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container :where(.services-select, .roles-ui-dropdown).active {
  border: none !important;
  border-inline-start: solid 1px transparent !important;
  border-inline-end: solid 1px transparent !important;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container :where(.services-select, .roles-ui-dropdown).active .text {
  color: var(--color-gray-900) !important;
  opacity: 0.55;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container :where(.services-select, .roles-ui-dropdown).active input {
  color: var(--color-gray-900);
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container :where(.services-select, .roles-ui-dropdown).active i {
  color: var(--color-gray-900);
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container :where(.services-select, .roles-ui-dropdown) .text {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 2;
  color: var(--color-gray-900);
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container :where(.services-select, .roles-ui-dropdown) .text span {
  font-weight: 400;
}
@media screen and (max-width: 1080px) {
  body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container :where(.services-select, .roles-ui-dropdown) {
    width: 100%;
    padding-inline-start: 18px !important;
    margin-inline-end: 0;
  }
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .roles-ui-dropdown .text {
  display: flex;
  align-items: center;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .roles-ui-dropdown .text > :where(span, a):only-child {
  display: contents;
  color: inherit;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .roles-ui-dropdown .menu .item > span {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .roles-ui-dropdown .menu.hide-bots .item.bot {
  display: none;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .roles-ui-dropdown .menu.hide-bots .fa-toggle-off {
  display: block;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .roles-ui-dropdown .menu.hide-bots .fa-toggle-on {
  display: none;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .roles-ui-dropdown :where(.text .item-role-name, .active-role-name) {
  overflow: hidden;
  text-overflow: ellipsis;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .roles-ui-dropdown :where(.text .files-count, .active-role-files-count) {
  height: 15px;
  line-height: 1;
  background: var(--color-gray-400);
  border-radius: 20px;
  padding-inline: 6px;
  padding-block: 2px;
  margin-inline-start: 5px;
  font-size: 10px;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .active-role-files-count {
  height: 15px;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .bots-toggler {
  display: flex;
  padding: 14px 16px;
  align-items: center;
  flex: 1;
  cursor: pointer;
  font-weight: 500;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .bots-toggler i {
  color: var(--color-primary-600);
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .bots-toggler i.fa-toggle-off {
  display: none;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .bots-toggler span {
  margin-inline-start: 1rem;
  line-height: 1;
  height: 100%;
  align-items: center;
  display: flex;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .bots-toggler:hover {
  background: rgba(0, 0, 0, 0.05);
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .bots-toggler-entry {
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: 99;
  border-top: solid 1px #E8EAE9;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio[hidden] {
  display: block;
  margin-inline-start: auto;
  margin-inline-end: -1.5rem;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio[hidden] .headerbar-right {
  height: 57px;
  flex-direction: row-reverse;
  min-height: 57px;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio[hidden] .headerbar-right ul#language_selector_container > li.dropdown-user a.dropdown-toggle {
  padding: 0 1.5rem;
  height: 57px;
  display: flex;
  align-items: center;
  gap: 6px;
  line-height: 1;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio[hidden] .headerbar-right ul#language_selector_container > li.dropdown-user a.dropdown-toggle i {
  font-size: 16px;
  margin-inline-start: 0.8rem;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio[hidden] .headerbar-right ul#language_selector_container > li.dropdown-user.open ul.dropdown-menu {
  background-color: white;
  padding: 0;
  inset-inline-end: inherit;
  border-radius: 0 6px 6px 6px;
  min-width: 100%;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio[hidden] .headerbar-right ul#language_selector_container > li.dropdown-user.open ul.dropdown-menu li a {
  font-weight: 600;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio[hidden] .headerbar-right ul#language_selector_container > li.dropdown-user.open ul.dropdown-menu li:first-child a {
  border-radius: 0 6px 0 0;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio[hidden] .headerbar-right ul#language_selector_container > li.dropdown-user.open ul.dropdown-menu li:last-child a {
  border-radius: 0 0 6px 6px;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio[hidden] .headerbar-right ul#connect {
  height: 100%;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio[hidden] .headerbar-right ul#connect li.dropdown.user {
  height: 100%;
  padding-inline-start: 12px;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio[hidden] .headerbar-right ul#connect li.dropdown.user a {
  text-transform: none;
  font-weight: 600;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio[hidden] .headerbar-right ul#connect li.dropdown.user a.dropdown-toggle.left-part {
  margin: 0;
  border: none;
  padding-inline-start: 0;
  padding-inline-end: 0;
  border-radius: 0;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio[hidden] .headerbar-right ul#connect li.dropdown.user a.dropdown-toggle.left-part i.user-icon {
  display: none;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio[hidden] .headerbar-right ul#connect li.dropdown.user a.dropdown-toggle.left-part span.user-name-text {
  padding: 0;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio[hidden] .headerbar-right ul#connect li.dropdown.user a.dropdown-toggle.left-part:hover {
  text-decoration: underline;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio[hidden] .headerbar-right ul#connect li.dropdown.user a.dropdown-toggle.left-part .user-initials {
  display: none;
}
@media screen and (max-width: 768px) {
  body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio[hidden] .headerbar-right ul#connect li.dropdown.user a.dropdown-toggle.left-part {
    display: block;
  }
  body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio[hidden] .headerbar-right ul#connect li.dropdown.user a.dropdown-toggle.left-part .user-name-text {
    display: block;
  }
  body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio[hidden] .headerbar-right ul#connect li.dropdown.user a.dropdown-toggle.left-part .user-name-text .user-initials {
    display: block;
  }
  body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio[hidden] .headerbar-right ul#connect li.dropdown.user a.dropdown-toggle.left-part .user-name-text .user-initials span {
    display: inline;
  }
  body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio[hidden] .headerbar-right ul#connect li.dropdown.user a.dropdown-toggle.left-part .user-name-text .user-fullname {
    display: none;
  }
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio[hidden] .headerbar-right ul#connect li.dropdown.user a.dropdown-toggle.user-dropdown-toggle {
  border: none;
  padding-inline-end: 1.3rem;
  padding-inline-start: 1.3rem;
  border-radius: 0;
  cursor: pointer;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio[hidden] .headerbar-right ul#connect li.dropdown.user a.dropdown-toggle.user-dropdown-toggle i.angle-icon {
  margin-inline-end: 0;
  font-weight: 300;
  font-size: 16px;
}
@media screen and (max-width: 768px) {
  body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio[hidden] .headerbar-right ul#connect li.dropdown.user a.dropdown-toggle.user-dropdown-toggle {
    height: 100%;
  }
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio[hidden] .headerbar-right ul#connect li.dropdown.user.open ul.dropdown-menu {
  width: 100%;
  min-width: fit-content;
  padding: 0;
  border-radius: 0 0 6px 6px;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio[hidden] .headerbar-right ul#connect li.dropdown.user.open ul.dropdown-menu li.divider {
  margin: 0;
  background-color: rgba(70, 81, 170, 0.1);
  height: 1px;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio[hidden] .headerbar-right ul#connect li.dropdown.user.open ul.dropdown-menu li.divider:first-child {
  display: none;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio[hidden] .headerbar-right ul#connect li.dropdown.user.open ul.dropdown-menu li a {
  padding: 7px 20px;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio[hidden] .headerbar-right ul#connect li.dropdown.user.open ul.dropdown-menu li:last-child a {
  border-radius: 0 0 6px 6px !important;
}
@media screen and (max-width: 768px) {
  body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio[hidden] .headerbar-right ul#connect li.dropdown.user {
    padding: 0 0 0 12px;
    align-items: center;
  }
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio[hidden] .headerbar-right ul#connect li:not(.dropdown) {
  height: 100%;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio[hidden] .headerbar-right ul#connect li:not(.dropdown) a {
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 0 !important;
  background: transparent;
  margin: 0 !important;
  padding: 0 2rem;
  text-transform: none;
  font-weight: 600;
  box-shadow: none !important;
}
@media screen and (max-width: 1200px) {
  body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio[hidden] {
    margin-inline-end: 0;
  }
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .responsive-menu-toggler {
  display: none;
  background-color: transparent;
  background-image: none;
  border: none;
  font-size: 20px;
  margin-inline-start: auto;
  width: 62px;
  padding: 0;
  height: 57px;
  min-width: 62px;
}
body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container.partb-menubar {
  flex-wrap: wrap;
}
@media screen and (max-width: 1080px) {
  body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .all-roles-menu ul.secondary-nav {
    inset-inline-start: 0;
  }
  body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio {
    margin-inline-start: auto !important;
    margin-inline-end: 8px;
  }
}
@media screen and (max-width: 680px) {
  body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio[hidden] {
    display: none;
  }
  body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio[hidden].responsive-open {
    display: block;
    position: absolute;
    background: #4651aa;
    inset-inline-end: 8px;
    top: calc(100% + 4px);
    padding-inline: 10px;
    border-radius: 6px;
  }
  body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio[hidden].responsive-open .headerbar-right {
    height: 57px;
  }
  body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio[hidden] .headerbar-right ul#language_selector_container > li.dropdown-user {
    padding-block: 0;
  }
  body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio[hidden] .headerbar-right ul#language_selector_container > li.dropdown-user a.dropdown-toggle {
    height: 57px;
  }
  body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .responsive-menu-toggler {
    display: grid;
    place-content: center;
  }
}
@media screen and (max-width: 640px) {
  body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .services-select {
    margin-inline-end: auto;
    margin-inline-start: 0;
    max-height: 200px;
  }
  body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio {
    position: relative;
  }
  body.modern-theme .secondary-menu .contain-lg nav.secondary-nav-container .service-bar-user-lang-menu.partb-newformio .headerbar-right ul#language_selector_container li.dropdown-user {
    padding: 0;
  }
}
body.modern-theme .secondary-menu.affix {
  position: fixed;
  z-index: 1050;
}
body.modern-theme .secondary-menu.affix ~ #base #content {
  padding-top: 59px;
}
body.modern-theme .secondary-menu:not(.affix) ~ #base #content .section-body .contain-lg #formio-form-role > .alert.alert-danger {
  top: 144px;
}
body.modern-theme td.panel {
  box-shadow: none;
  background-color: transparent;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel {
  padding: 24px;
  background: white;
  border: none;
  border-radius: 6px;
  box-shadow: none;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table thead {
  opacity: 1;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table thead tr.responsive-sort {
  display: none;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table thead tr.responsive-sort td {
  padding: 0 0 10px;
  display: flex;
  align-items: center;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table thead tr.responsive-sort td .sorting-label {
  background: var(--color-gray-75);
  color: var(--color-gray-600);
  font-weight: 600;
  padding: 6px 12px;
  border-radius: 6px;
  display: inline-flex;
  white-space: nowrap;
  transition-duration: 0.3s;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table thead tr.responsive-sort td .sorting-label .sort_by_label ~ span {
  margin-inline-start: 6px;
  font-weight: 600;
  position: relative;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table thead tr.responsive-sort td .sorting-label .sort_by_label ~ span::after {
  content: "\f354";
  font-family: var(--fontawesome-font);
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-size: 1.1rem;
  margin-inline-start: 6px;
  border: none;
  opacity: 0.8;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table thead tr.responsive-sort td .sorting-label .sort_by_label ~ span.reverse-ordering::after {
  content: "\f357";
  color: var(--color-gray-600);
  font-weight: 900;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table thead tr.responsive-sort td .sorting-label:hover {
  background: #EDEEF7;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table thead tr.responsive-sort td .cells-filter-toggler {
  background: #F5F6FA;
  color: var(--color-primary-600);
  padding: 6px 12px;
  border-radius: 6px;
  transition-duration: 0.3s;
  display: inline-flex;
  height: 37px;
  align-items: center;
  margin-inline-start: 12px;
  cursor: pointer;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table thead tr.responsive-sort td .cells-filter-toggler:hover {
  background: #EDEEF7;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table thead tr:first-child + tr th {
  background-color: var(--color-gray-75);
  text-transform: none;
  color: var(--color-gray-600);
  font-size: 14px;
  font-weight: 500;
  padding: 10px 12px;
  border: none !important;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table thead tr:first-child + tr th:first-child {
  border-radius: 6px 0 0 6px;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table thead tr:first-child + tr th:last-child {
  border-radius: var(--cell-heading-border-radius, 0 6px 6px 0);
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table thead tr:first-child + tr th a {
  text-transform: none;
  color: var(--color-gray-600);
  font-size: 14px;
  letter-spacing: 0;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table thead tr:first-child + tr th .sortorder {
  top: 0;
  height: 100%;
  inset-inline-end: auto;
  margin-inline-start: 12px;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table thead tr:first-child + tr th .sortorder::after {
  content: "\f176";
  font-weight: 400;
  font-size: 14px;
  color: var(--color-gray-600);
  top: calc(50% - 7.5px);
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table thead tr:first-child + tr th .sortorder.reverse::after {
  content: "\f175";
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table thead tr.filters td {
  border-top: none;
  padding: 0;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table thead tr.filters td :where(select, input) {
  border: solid 1px rgba(70, 81, 170, 0.1);
  background-color: transparent;
  border-radius: 6px;
  margin-top: 18px;
  height: 34px;
  box-shadow: none;
  margin-inline-end: 20px;
  width: calc(100% - 20px);
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 13px;
  color: var(--color-gray-400);
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table thead tr.filters td select {
  padding-inline-start: 8px;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table thead tr.filters td option {
  color: var(--color-gray-800);
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table tbody tr td {
  color: var(--color-gray-800);
  font-size: 15px;
  padding: 15px 12px;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table tbody tr td span:where(.label-status, .label) {
  font-weight: 600;
  background-color: transparent;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table tbody tr td span:where(.label-status, .label):not(:empty) {
  display: flex;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table tbody tr td span:where(.label-status, .label):where(.success, .label-success) {
  color: #10B981;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table tbody tr td span:where(.label-status, .label):where(.info, .label-info) {
  color: #1D86C8;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table tbody tr td span:where(.label-status, .label):where(.warning, .label-warning) {
  color: #FF7233;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table tbody tr td span:where(.label-status, .label):where(.danger, .label-danger) {
  color: #F53D3D;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table tbody tr td.date-column {
  font-size: 12px;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table tbody tr td file-status {
  display: inline-flex;
  margin-inline-end: 0;
  position: relative;
  top: -1px;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table tbody tr td file-status span.label {
  padding: 3px 5px !important;
  font-size: 12px;
  margin-inline-end: 6px;
  font-weight: 600;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table tbody tr td file-status:empty {
  display: none !important;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table tbody tr:nth-child(odd) {
  background-color: transparent;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table tbody tr:nth-child(odd) td {
  background-color: transparent;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table tbody tr:hover td {
  background-color: rgba(27, 35, 49, 0.02);
}
@media screen and (max-width: 768px) {
  body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table thead {
    display: block !important;
    position: absolute;
    z-index: 99;
  }
  body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table thead tr.responsive-sort {
    display: block;
  }
  body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table thead tr.responsive-sort + tr {
    display: none;
    background-color: #EDEEF7;
    position: absolute;
    z-index: 999;
    border-radius: 0 0 6px 6px;
    transition-duration: 0.3s;
    overflow: hidden;
    flex-direction: column;
  }
  body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table thead tr.responsive-sort + tr th {
    flex: 1;
    width: 100%;
    background-color: transparent;
    padding: 0;
  }
  body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table thead tr.responsive-sort + tr th:where(.reg-status, :last-child) {
    display: none;
  }
  body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table thead tr.responsive-sort + tr th input {
    display: none;
  }
  body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table thead tr.responsive-sort + tr th > a {
    display: block;
    padding: 10px 12px;
  }
  body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table thead tr.responsive-sort + tr th > a:hover {
    background-color: rgba(70, 81, 170, 0.1);
  }
  body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table thead tr.responsive-sort + tr th > span {
    background-color: transparent;
    border-radius: 0 !important;
    padding: 10px 12px;
  }
  body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table thead tr.responsive-sort + tr th > span:hover {
    background-color: rgba(70, 81, 170, 0.1);
  }
  body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table thead tr.responsive-sort + tr th .sortorder {
    display: none;
  }
  body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table thead tr.filters {
    display: none;
  }
  body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table.show-sorting thead tr.responsive-sort + tr {
    display: flex;
    transform: translateY(-10px);
  }
  body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table.show-sorting thead tr.responsive-sort {
    display: block;
  }
  body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table.show-sorting thead tr.responsive-sort td span.sorting-label {
    border-radius: 6px 6px 0 0;
    background-color: #EDEEF7;
  }
  body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table.show-search thead tr.filters {
    display: flex;
    transform: translateY(-10px);
    width: 244px;
    flex-direction: column;
    background-color: #EDEEF7;
    border-radius: 6px 0 6px 6px;
  }
  body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table.show-search thead tr.filters td span {
    display: none;
  }
  body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table.show-search thead tr.filters td select {
    margin: 12px 12px 0;
    background-color: white;
  }
  body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table.show-search thead tr.filters td input {
    display: block;
    margin: 12px !important;
    background: white;
    padding-inline-start: 12px;
    width: calc(100% - 24px);
  }
  body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table.show-search thead tr.filters td:first-child input {
    margin: 12px 12px 0 !important;
  }
  body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table.show-search thead tr.filters td:last-child {
    display: none;
  }
  body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table.show-search thead tr.responsive-sort td span.cells-filter-toggler {
    border-radius: 6px 6px 0 0;
    background-color: rgba(70, 81, 170, 0.1);
  }
  body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table tbody {
    display: grid;
    grid-auto-flow: column;
    max-width: 100%;
    gap: 12px;
    margin-top: 50px;
  }
  body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table tbody tr {
    display: grid;
    border-radius: 6px;
    border: solid 1px rgba(70, 81, 170, 0.1);
    grid-template-rows: auto auto auto auto auto;
    grid-template-columns: 1fr;
    min-width: 240px;
  }
  body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table tbody tr td {
    width: auto;
    border: none;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-weight: 600;
  }
  body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container table tbody tr td .responsive-label[hidden] {
    display: block;
    font-size: 12px;
    font-weight: 400;
  }
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container .entry-status-legend {
  gap: 20px;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container .entry-status-legend > div {
  display: flex;
  align-items: center;
  gap: 5px;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-responsive-container .entry-status-legend > div span {
  line-height: 1;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-paging-container {
  background-color: transparent;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-paging-container dir-pagination-controls {
  display: flex;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-paging-container ul.pagination {
  background-color: rgba(70, 81, 170, 0.05);
  border-radius: 6px;
  overflow: hidden;
  margin-top: 32px;
  margin-bottom: 0;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-paging-container ul.pagination li {
  display: inline-flex;
  margin-inline-end: 1px;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-paging-container ul.pagination li a {
  background-color: transparent;
  height: 46px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  color: var(--color-gray-800);
  font-weight: 600;
  font-size: 12px;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-paging-container ul.pagination li:where(.active, :hover) a {
  background-color: rgba(70, 81, 170, 0.1);
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-paging-container ul.pagination li:where(:first-child, :last-child) a {
  color: transparent;
  position: relative;
  width: 38px;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-paging-container ul.pagination li:where(:first-child, :last-child) a::before {
  font-family: var(--fontawesome-font);
  font-weight: 300;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-size: 22px;
  color: var(--color-gray-800);
  position: absolute;
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-paging-container ul.pagination li:first-child a::before {
  content: "\f104";
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body .table-paging-container ul.pagination li:last-child a::before {
  content: "\f105";
}
body.modern-theme #base #content .section-body .contain-lg > .row > .col-lg-12 > .panel > .panel-body #entreprise-grid {
  overflow: hidden;
}
body.modern-theme #base #content .section-body .contain-lg > div > div {
  /* Part B Role form preview new Formio */
}
body.modern-theme #base #content .section-body .contain-lg > div > div > div.panel {
  padding: 24px;
  background: white;
  border: none;
  border-radius: 6px;
  box-shadow: none;
}
body.modern-theme #base #content .section-body .contain-lg > div > div > div.panel .panel-body table thead {
  opacity: 1;
}
body.modern-theme #base #content .section-body .contain-lg > div > div > div.panel .panel-body table thead tr:first-child th {
  background-color: rgba(70, 81, 170, 0.05);
  text-transform: none;
  color: var(--color-primary-600);
  font-size: 14px;
  font-weight: 500;
  padding: 10px 12px;
  border: none !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div > div.panel .panel-body table thead tr:first-child th:first-child {
  border-radius: var(--cell-heading-border-radius, 6px 0 0 6px);
}
body.modern-theme #base #content .section-body .contain-lg > div > div > div.panel .panel-body table thead tr:first-child th:last-child {
  border-radius: var(--cell-heading-border-radius, 0 6px 6px 0);
}
body.modern-theme #base #content .section-body .contain-lg > div > div > div.panel .panel-body table thead tr:first-child th a {
  text-transform: none;
  color: var(--color-primary-600);
  font-size: 14px;
  letter-spacing: 0;
}
@media screen and (max-width: 768px) {
  body.modern-theme #base #content .section-body .contain-lg > div > div > div.panel .panel-body table thead {
    display: none;
  }
  body.modern-theme #base #content .section-body .contain-lg > div > div > div.panel .panel-body table tbody {
    display: block;
  }
  body.modern-theme #base #content .section-body .contain-lg > div > div > div.panel .panel-body table tbody tr {
    display: grid;
  }
  body.modern-theme #base #content .section-body .contain-lg > div > div > div.panel .panel-body table tbody tr td {
    display: flex;
    flex-direction: column;
    text-align: start !important;
    padding-bottom: 1rem;
  }
  body.modern-theme #base #content .section-body .contain-lg > div > div > div.panel .panel-body table tbody tr td:first-child {
    padding-top: 1.5rem;
  }
  body.modern-theme #base #content .section-body .contain-lg > div > div > div.panel .panel-body table tbody tr td span {
    text-align: start;
  }
  body.modern-theme #base #content .section-body .contain-lg > div > div > div.panel .panel-body table tbody tr td span.partb-file-resume-responsive-table-label[hidden] {
    display: block;
    color: var(--color-primary-600);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.125rem;
  }
}
body.modern-theme #base #content .section-body .contain-lg > div > div > div.panel .panel-body table.part-b-single-file-resume thead tr th:where(.date-col, .status-col) {
  width: 200px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div > div.panel .panel-body table.part-b-single-file-resume tbody tr td {
  padding: 15px 12px;
  vertical-align: middle;
  line-height: 1;
  font-size: 15px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div > div.panel .panel-body table.part-b-single-file-resume tbody tr td.date-col {
  font-size: 12px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div > div.panel .panel-body table.part-b-single-file-resume tbody tr td span.label.label-status {
  font-weight: 500;
  padding-bottom: 0 !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div > div.panel .panel-body table.part-b-single-file-resume tbody tr td span.label.label-status.filepending {
  color: #4678aa;
}
body.modern-theme #base #content .section-body .contain-lg > div > div > div.panel .panel-body table.part-b-single-file-resume tbody tr td span.label.label-status.filevalidated {
  color: #389f8f;
}
body.modern-theme #base #content .section-body .contain-lg > div > div > div.panel .panel-body table.part-b-single-file-resume tbody tr td span.label.label-status.filedecline {
  color: #FFA076;
}
body.modern-theme #base #content .section-body .contain-lg > div > div > div.panel .panel-body table.part-b-single-file-resume tbody tr td span.label.label-status.filereject {
  color: #e84f73;
}
body.modern-theme #base #content .section-body .contain-lg > div > div > div.panel .panel-body table.part-b-single-file-resume tbody tr td file-status {
  display: inline-flex;
  margin-inline-end: 0;
  position: relative;
  top: 1px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div > div.panel .panel-body table.part-b-single-file-resume tbody tr td file-status:empty {
  display: none;
}
body.modern-theme #base #content .section-body .contain-lg > div > div > div.panel .panel-body table.part-b-single-file-resume tbody tr td strong {
  font-weight: 500;
}
body.modern-theme #base #content .section-body .contain-lg > div > div > div.row .panel {
  padding: 24px;
  background: white;
  border: none;
  border-radius: 6px;
  box-shadow: none;
}
body.modern-theme #base #content .section-body .contain-lg > div > div > div.row .panel .panel-heading {
  padding: 0 !important;
  align-items: center;
}
body.modern-theme #base #content .section-body .contain-lg > div > div > div.row .panel .panel-heading h3 {
  padding: 0;
}
body.modern-theme #base #content .section-body .contain-lg > div > div > div.row .panel .panel-heading .restart-process-box {
  margin-top: 0 !important;
}
@media screen and (max-width: 560px) {
  body.modern-theme #base #content .section-body .contain-lg > div > div > div.row .panel .panel-heading .restart-process-box {
    display: grid;
    margin-inline-start: 0 !important;
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }
  body.modern-theme #base #content .section-body .contain-lg > div > div > div.row .panel .panel-heading .restart-process-box button + button {
    margin-inline-end: 0 !important;
  }
  body.modern-theme #base #content .section-body .contain-lg > div > div > div.row .panel .panel-heading .restart-process-box label {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}
@media screen and (max-width: 768px) {
  body.modern-theme #base #content .section-body .contain-lg > div > div > div.row .panel .panel-heading {
    flex-direction: column;
    align-items: flex-start !important;
  }
  body.modern-theme #base #content .section-body .contain-lg > div > div > div.row .panel .panel-heading h3 {
    margin-bottom: 12px;
  }
}
body.modern-theme #base #content .section-body .contain-lg > div > div > div.row .panel .panel-body {
  padding: 2rem 0 0 !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div > div.row .panel .panel-body > .row {
  margin: 0;
}
body.modern-theme #base #content .section-body .contain-lg > div > div > div.row .panel .panel-body > .row > .col-sm-12 {
  padding: 0;
}
@media screen and (max-width: 768px) {
  body.modern-theme #base #content .section-body .contain-lg > div > div > div.row .panel .panel-body {
    max-width: calc(100vw - 118px);
  }
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .part-b-forms-tabs ul.nav.nav-tabs > li {
  box-shadow: none;
  height: auto;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .part-b-forms-tabs ul.nav.nav-tabs > li a {
  font-size: 23px;
  color: rgba(70, 81, 170, 0.4);
  font-weight: 600;
  padding-inline-start: 42px;
  padding-inline-end: 42px;
  border-top: none;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .part-b-forms-tabs ul.nav.nav-tabs > li.active a {
  background-color: rgba(70, 81, 170, 0.1);
  color: var(--color-primary-600);
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content {
  padding: 24px !important;
  background: white;
  border: none;
  border-radius: 0 6px 6px 6px;
  box-shadow: none;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content #form-type-B6 {
  background: transparent;
  box-shadow: none;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content #form-type-B6 .tab-description {
  margin: 0;
  padding: 0 0 20px;
  line-height: 1;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content #form-type-B6 .tab-description span {
  color: var(--color-gray-800);
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content #form-type-B6 .tab-description span.tab-description-back-to-list::before {
  top: 0;
  line-height: 15px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content #form-type-B6 .bg-gray {
  background-color: transparent;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content #form-type-B6 .bg-gray.px-2.pb-1 {
  padding: 0 !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content #form-type-B6 .document-revision-list .validation-headers form.formio-form .form-group.form-field-type-columns {
  margin-top: 0;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content #form-type-B6 .document-revision-list .validation-headers form.formio-form .form-group.form-field-type-columns .form-field-type-button button {
  background-color: transparent;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content #form-type-B6 .document-revision-list .validation-headers form.formio-form .form-group.form-field-type-columns .form-field-type-button button:hover {
  box-shadow: none !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content #form-type-B6 .document-revision-list .validation-headers form.formio-form .form-group.form-field-type-columns .form-field-type-mycounter span.bullet {
  font-size: 13px;
  display: block;
  line-height: 1.3em;
  background: var(--color-primary-600);
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content #form-type-B6 #carouselRevision .carousel-inner .item .documents-validator .carousel-selected-index {
  inset-inline-start: 80px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content #form-type-B6 #carouselRevision .carousel-inner .item .documents-validator form > .form-group.form-field-type-panel {
  margin-top: 0;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content #form-type-B6 #carouselRevision .carousel-inner .item .documents-validator form > .form-group.form-field-type-panel > .panel {
  box-shadow: none !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content #form-type-B6 #carouselRevision .carousel-inner .item .documents-validator form > .form-group.form-field-type-panel > .panel > .panel-heading {
  margin: 0;
  border-radius: 6px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content #form-type-B6 #carouselRevision .carousel-inner .item .documents-validator form > .form-group.form-field-type-panel > .panel > .panel-body {
  padding: 0;
  box-shadow: none;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content #form-type-B6 #carouselRevision .carousel-inner .item .documents-validator form > .form-group.form-field-type-panel > .panel > .panel-body .formio-component-rejectionrow {
  margin: 2rem 0 !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content #form-type-B6 #carouselRevision .carousel-inner .item .documents-validator form > .form-group.form-field-type-panel > .panel > .panel-body .formio-component-rejectionrow .form-field-type-checkbox.check-switch .checkbox {
  margin-top: 15px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content #form-type-B6 #carouselRevision .carousel-inner .item .carousel-controls .carousel-control.left {
  inset-inline-start: 0;
  border-radius: 6px 0 0 6px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content #form-type-B6 #carouselRevision .carousel-inner .item .carousel-controls .carousel-control.right {
  inset-inline-start: 34px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content #form-type-B6 #carouselRevision .carousel-inner .item .img-thumbnail object {
  display: block;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content #form-type-B6 #carouselRevision .carousel-inner .item .revision-doc-info-column form > .form-group.form-field-type-panel:first-of-type {
  margin-top: 20px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content #form-type-B6 #carouselRevision .carousel-inner .item .revision-doc-info-column form > .form-group.form-field-type-panel > .panel {
  box-shadow: none !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content #form-type-B6 #carouselRevision .carousel-inner .item .revision-doc-info-column form > .form-group.form-field-type-panel > .panel > .panel-body {
  padding: 1rem;
  box-shadow: none !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content #form-type-B6 #carouselRevision .carousel-inner .item.active.single-doc .carousel-selected-index {
  inset-inline-start: 23px !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content .part-b-tabs-tab-data {
  margin: -35px;
  background: transparent;
  box-shadow: none;
  border-radius: 6px;
  overflow: hidden;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content .part-b-tabs-tab-data .zone_revision .revision-block form .form-group.form-field-type-mytabs {
  margin-top: 0;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content .part-b-tabs-tab-data .zone_revision .revision-block form .form-group.form-field-type-mytabs .tabs-above .nav-tabs {
  background-color: transparent;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content .part-b-tabs-tab-data .zone_revision .revision-block form .form-group.form-field-type-mytabs .tabs-above .nav-tabs li.active a {
  background-color: transparent;
  box-shadow: inset 0 -2px 0 var(--color-primary-600) !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content .part-b-tabs-tab-data .zone_revision .revision-block form .form-group.form-field-type-mytabs .tabs-above .nav-tabs li a {
  color: var(--color-primary-600);
  text-decoration: none;
  font-weight: 600;
  padding: 0 35px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content .part-b-tabs-tab-data .zone_revision .revision-block form .form-group.form-field-type-mytabs .tabs-above .nav-tabs li:hover a {
  border-bottom: none;
  box-shadow: inset 0 -2px 0 var(--color-primary-600) !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content .part-b-tabs-tab-data .zone_revision .revision-block form .form-group.form-field-type-mytabs .tabs-above .tab-content .tab-pane .card .card-body > .form-field-type-panel {
  box-shadow: none;
  margin-top: 0;
  margin-bottom: 0 !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content .part-b-tabs-tab-data .zone_revision .revision-block form .form-group.form-field-type-mytabs .tabs-above .tab-content .tab-pane .card .card-body > .form-field-type-panel > .panel {
  background-color: transparent;
  padding: 0 35px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content .part-b-tabs-tab-data .zone_revision .revision-block form .form-group.form-field-type-mytabs .tabs-above .tab-content .tab-pane .card .card-body > .form-field-type-panel > .panel > .panel-heading {
  padding-inline-start: 0;
  padding-inline-end: 0;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content .part-b-tabs-tab-data .zone_revision .revision-block form .form-group.form-field-type-mytabs .tabs-above .tab-content .tab-pane .card .card-body > .form-field-type-panel > .panel > .panel-body {
  padding-inline-start: 0;
  padding-inline-end: 0;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content .part-b-tabs-tab-data .zone_revision .revision-block form .form-group.form-field-type-mytabs .tabs-above .tab-content .tab-pane .card .card-body > .form-field-type-panel > .panel > .panel-body .form-field-type-panel {
  background-color: white;
  box-shadow: none;
  padding: 0;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content .part-b-tabs-tab-data .zone_revision .revision-block form .form-group.form-field-type-mytabs .tabs-above .tab-content .tab-pane .card .card-body > .form-field-type-panel > .panel > .panel-body .form-field-type-panel .panel {
  padding: 0;
  background-color: white;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content .part-b-tabs-tab-data .zone_revision .revision-block form .form-group.form-field-type-mytabs .tabs-above .tab-content .tab-pane .card .card-body > .form-field-type-panel > .panel > .panel-body .form-field-type-panel .panel .panel-heading {
  background-color: white;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content .part-b-tabs-tab-data .zone_revision .revision-block form .form-group.form-field-type-mytabs .tabs-above .tab-content .tab-pane .card .card-body > .form-field-type-panel > .panel > .panel-body .form-field-type-panel .panel .panel-heading h3.panel-title {
  font-weight: 600;
  text-transform: none;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content .part-b-tabs-tab-data .zone_revision .revision-block form .form-group.form-field-type-mytabs .tabs-above .tab-content .tab-pane .card .card-body > .form-field-type-panel > .panel > .panel-body .form-field-type-panel .panel .panel-body .form-field-type-mycardtable table tbody tr td h2 {
  margin: 0;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content .part-b-tabs-tab-data .zone_revision .revision-block form .form-group.form-field-type-mytabs .tabs-above .tab-content .tab-pane .card .card-body > .form-field-type-panel > .panel > .panel-body .form-field-type-panel:last-of-type > .panel > .panel-body .form-field-type-mycardtable:last-of-type {
  margin-bottom: 3rem;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content .part-b-tabs-tab-data .zone_revision .revision-block form .form-group.form-field-type-mytabs .tabs-above .tab-content .tab-pane .card .card-body > .form-field-type-panel > .panel > .panel-body > .form-field-type-mycardtable:last-of-type {
  margin-bottom: 35px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content .part-b-tabs-tab-data .zone_revision .revision-block form .form-group.form-field-type-mytabs .tabs-above .tab-content .tab-pane .card .card-body > .form-field-type-panel.small-padding {
  padding: 0;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content .part-b-tabs-tab-data .zone_revision .revision-block form .form-group.form-field-type-mytabs .tabs-above .tab-content .tab-pane .card .card-body > .form-field-type-panel + .form-field-type-button {
  display: none;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content .part-b-tabs-tab-data .zone_revision .revision-block form .form-group.form-field-type-mytabs .tabs-above .tab-content .tab-pane .card .card-body > .form-field-type-button {
  display: none;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content .part-b-tabs-tab-data .zone_revision .revision-block form > .form-field-type-button {
  margin-top: 11px !important;
  margin-inline-end: 35px !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content .part-b-tabs-tab-data .zone_revision .revision-block form > .form-field-type-button button {
  line-height: 1;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content .part-b-tabs-tab-data .zone_revision .revision-block form > .form-field-type-button button:hover {
  box-shadow: none;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content .part-b-tabs-tab-data #callout-data form .form-field-type-table {
  margin-top: 35px !important;
  margin-bottom: 0 !important;
  margin-inline-start: 35px !important;
  margin-inline-end: 0 !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content .part-b-tabs-tab-data #callout-data form .form-field-type-table tr {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  flex: auto;
  gap: 2rem;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content .part-b-tabs-tab-data #callout-data form .form-field-type-table tr td {
  padding: 0;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content .part-b-tabs-tab-data #callout-data form .form-field-type-table tr td .form-field-type-myswitch.formio-component-FORMDATAVALIDATIONSTATUS {
  margin-top: 0 !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content .part-b-tabs-tab-data #callout-data form .form-field-type-table tr td:first-child {
  flex: auto;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content #form-type-role div[form="conclusion"] {
  padding: 0 !important;
  background: transparent;
  box-shadow: none !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content #form-type-role div[form="conclusion"] .form-field-type-columns {
  margin: 0 !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content #form-type-role .processing-tab form {
  box-shadow: none;
  background: transparent;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content #form-type-role .processing-tab form .form-field-type-hidden:first-of-type + .form-field-type-panel {
  margin-top: 0;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content #form-type-role .processing-tab form .form-field-type-panel > .panel {
  box-shadow: none !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div #tabs-zone > .panel > .panel-body.tab-content #documents {
  background: transparent;
  box-shadow: none;
  padding: 0 !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form {
  z-index: 999;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane:nth-child(2).active,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane:nth-child(2).active {
  border-radius: var(--tab-pane-border-radius, 0 6px 6px 6px);
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-data-tab ~ :where(.formio-component-panel, .formio-component-container > div > .formio-component-panel) .card .card-body .formio-component-rejectionrow,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-data-tab ~ :where(.formio-component-panel, .formio-component-container > div > .formio-component-panel) .card .card-body .formio-component-rejectionrow {
  margin: 0 !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-data-tab ~ :where(.formio-component-panel, .formio-component-container > div > .formio-component-panel) .card .card-body .formio-component-rejectionrow .formio-component-checkbox .form-check,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-data-tab ~ :where(.formio-component-panel, .formio-component-container > div > .formio-component-panel) .card .card-body .formio-component-rejectionrow .formio-component-checkbox .form-check {
  margin-top: 0;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-data-tab ~ :where(.formio-component-panel, .formio-component-container > div > .formio-component-panel) .card .card-body .formio-component-rejectionrow .formio-component-checkbox .form-check label span,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-data-tab ~ :where(.formio-component-panel, .formio-component-container > div > .formio-component-panel) .card .card-body .formio-component-rejectionrow .formio-component-checkbox .form-check label span {
  font-weight: 600;
  font-size: 15px;
  line-height: 12px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-data-tab ~ :where(.formio-component-panel, .formio-component-container > div > .formio-component-panel) .card .card-body .formio-component-rejectionrow .formio-component-select label,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-data-tab ~ :where(.formio-component-panel, .formio-component-container > div > .formio-component-panel) .card .card-body .formio-component-rejectionrow .formio-component-select label {
  font-weight: 600 !important;
  color: rgba(27, 35, 49, 0.7);
  font-size: 13px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-data-tab ~ :where(.formio-component-panel, .formio-component-container > div > .formio-component-panel) .card .card-body .formio-component-rejectionrow .formio-component-select .formio-choices .selection.dropdown,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-data-tab ~ :where(.formio-component-panel, .formio-component-container > div > .formio-component-panel) .card .card-body .formio-component-rejectionrow .formio-component-select .formio-choices .selection.dropdown {
  border: solid 1px var(--color-gray-300);
  border-radius: 6px !important;
  height: 40px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-data-tab ~ :where(.formio-component-panel, .formio-component-container > div > .formio-component-panel) .card .card-body .formio-component-rejectionrow .formio-component-select .formio-choices .selection.dropdown:where(:hover, :focus),
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-data-tab ~ :where(.formio-component-panel, .formio-component-container > div > .formio-component-panel) .card .card-body .formio-component-rejectionrow .formio-component-select .formio-choices .selection.dropdown:where(:hover, :focus) {
  outline: none;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-data-tab ~ :where(.formio-component-panel, .formio-component-container > div > .formio-component-panel) .card .card-body .formio-component-rejectionrow .formio-component-select .formio-choices.is-open .selection.dropdown,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-data-tab ~ :where(.formio-component-panel, .formio-component-container > div > .formio-component-panel) .card .card-body .formio-component-rejectionrow .formio-component-select .formio-choices.is-open .selection.dropdown {
  border-radius: 6px 6px 0 0 !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-data-tab ~ :where(.formio-component-panel, .formio-component-container > div > .formio-component-panel) .card .card-body .formio-component-rejectionrow .formio-component-select .formio-choices::after,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-data-tab ~ :where(.formio-component-panel, .formio-component-container > div > .formio-component-panel) .card .card-body .formio-component-rejectionrow .formio-component-select .formio-choices::after {
  color: rgba(70, 81, 170, 0.4);
  content: "\F078";
  font-family: var(--fontawesome-font);
  font-weight: 300;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  font-size: 16px;
  width: auto;
  border: none;
  margin-top: 0;
  height: 8px;
  line-height: 7px;
  top: calc(50% - 4px);
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-data-tab ~ :where(.formio-component-panel, .formio-component-container > div > .formio-component-panel) .card .card-body .formio-component-rejectionrow .formio-component-select .formio-choices:hover::after,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-data-tab ~ :where(.formio-component-panel, .formio-component-container > div > .formio-component-panel) .card .card-body .formio-component-rejectionrow .formio-component-select .formio-choices:hover::after {
  color: var(--color-primary-600);
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-data-tab ~ :where(.formio-component-panel, .formio-component-container > div > .formio-component-panel) .card .card-body .formio-component-rejectionrow .formio-component-select .formio-choices .choices__list.choices__list--dropdown,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-data-tab ~ :where(.formio-component-panel, .formio-component-container > div > .formio-component-panel) .card .card-body .formio-component-rejectionrow .formio-component-select .formio-choices .choices__list.choices__list--dropdown {
  border: solid 1px var(--color-gray-300);
  border-radius: 0 0 6px 6px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-data-tab ~ :where(.formio-component-panel, .formio-component-container > div > .formio-component-panel) .card .card-body .formio-component-rejectionrow .formio-component-select .formio-choices .choices__list.choices__list--dropdown input.choices__input,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-data-tab ~ :where(.formio-component-panel, .formio-component-container > div > .formio-component-panel) .card .card-body .formio-component-rejectionrow .formio-component-select .formio-choices .choices__list.choices__list--dropdown input.choices__input {
  border-radius: 0 !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-data-tab ~ :where(.formio-component-panel, .formio-component-container > div > .formio-component-panel) .card .card-body .formio-component-rejectionrow .formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-data-tab ~ :where(.formio-component-panel, .formio-component-container > div > .formio-component-panel) .card .card-body .formio-component-rejectionrow .formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice {
  padding: 10px 20px;
  font-size: 12px;
  font-weight: 600;
  color: var(--color-gray-800);
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-data-tab ~ :where(.formio-component-panel, .formio-component-container > div > .formio-component-panel) .card .card-body .formio-component-rejectionrow .formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice:where(.is-selected, :hover),
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-data-tab ~ :where(.formio-component-panel, .formio-component-container > div > .formio-component-panel) .card .card-body .formio-component-rejectionrow .formio-component-select .formio-choices .choices__list.choices__list--dropdown .choices__item.choices__item--choice:where(.is-selected, :hover) {
  background-color: rgba(70, 81, 170, 0.05);
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-data-tab ~ :where(.formio-component-panel, .formio-component-container > div > .formio-component-panel) .card .card-body .formio-component-panel .card .card-body > .formio-component-checkbox,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-data-tab ~ :where(.formio-component-panel, .formio-component-container > div > .formio-component-panel) .card .card-body .formio-component-panel .card .card-body > .formio-component-checkbox {
  display: none;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-data-tab + .formio-component-button[class*="print"],
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-data-tab + .formio-component-button[class*="print"] {
  display: flex;
  justify-content: flex-end;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-data-tab + .formio-component-button[class*="print"] button,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-data-tab + .formio-component-button[class*="print"] button {
  border-radius: 6px !important;
  background-color: var(--color-primary-600);
  padding-inline-start: 25px;
  padding-inline-end: 25px;
  font-weight: 600;
  text-transform: none;
  position: relative;
  z-index: 1;
  height: 26px;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 12px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-data-tab + .formio-component-button[class*="print"] button:hover,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-data-tab + .formio-component-button[class*="print"] button:hover {
  box-shadow: 0 9px 20px rgba(70, 81, 170, 0.4) !important;
  opacity: 0.9;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-data-tab + .formio-component-container,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-data-tab + .formio-component-container {
  margin-top: 0;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel .card,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel .card {
  background-color: transparent;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel .card .card-header,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel .card .card-header {
  padding: 0;
  background-color: transparent;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel .card .card-body,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel .card .card-body {
  padding: 2.7rem 0 0 !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel .card .card-body .formio-component-textarea .card-body,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel .card .card-body .formio-component-textarea .card-body {
  padding-top: 0 !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel .card .card-body textarea.required,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel .card .card-body textarea.required {
  color: var(--color-gray-800);
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel .card .card-body textarea.required:empty,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel .card .card-body textarea.required:empty {
  border-color: rgba(255, 0, 59, 0.7) !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel .card .card-body textarea.required::after,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel .card .card-body textarea.required::after {
  content: " ";
  display: inline-block;
  background-color: rgba(255, 0, 59, 0.7);
  vertical-align: unset;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  position: relative;
  top: -1px;
  inset-inline-start: 2px;
  min-width: 6px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel .card .carousel-inner .block-previewcontainer .card-body,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel .card .carousel-inner .block-previewcontainer .card-body {
  padding-top: 1rem !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel .card .carousel-inner .block-previewcontainer .formio-component-textarea .card-body,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel .card .carousel-inner .block-previewcontainer .formio-component-textarea .card-body {
  padding-top: 0 !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel:last-child .card,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel:last-child .card {
  margin-bottom: 0 !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel:last-child .card::after,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel:last-child .card::after {
  display: none;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"],
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] {
  margin-top: 0;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body {
  padding-top: 0 !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body {
  padding-top: 0 !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement {
  margin-top: 0;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .validation-headers .form-field-type-columns .row,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .validation-headers .form-field-type-columns .row {
  align-items: center;
  line-height: 1;
  transition-duration: 0.3s;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .validation-headers .form-field-type-columns .row .col-sm-1,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .validation-headers .form-field-type-columns .row .col-sm-1 {
  flex: 0;
  width: auto;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .validation-headers .form-field-type-columns .row .col-sm-1 .form-field-type-mycounter .bullet,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .validation-headers .form-field-type-columns .row .col-sm-1 .form-field-type-mycounter .bullet {
  font-size: 14px;
  line-height: 17px;
  display: block;
  background-color: var(--color-primary-600);
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .validation-headers .form-field-type-columns .row .col-sm-8,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .validation-headers .form-field-type-columns .row .col-sm-8 {
  flex: auto;
  z-index: 2;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .validation-headers .form-field-type-columns .row .col-sm-8 .form-field-type-button button,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .validation-headers .form-field-type-columns .row .col-sm-8 .form-field-type-button button {
  background: transparent;
  box-shadow: none !important;
  font-weight: 600;
  text-transform: none;
  width: calc(100% + 110px);
  z-index: 9;
  display: flex;
  max-width: calc(100% + 110px);
  padding-inline-end: 110px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .validation-headers .form-field-type-columns .row .col-sm-8 .form-field-type-button button:where(:hover, :focus),
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .validation-headers .form-field-type-columns .row .col-sm-8 .form-field-type-button button:where(:hover, :focus) {
  color: initial;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .validation-headers .form-field-type-columns .row .col-sm-3,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .validation-headers .form-field-type-columns .row .col-sm-3 {
  z-index: 1;
  min-width: fit-content;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .validation-headers .form-field-type-columns .row .col-sm-3 .form-field-type-htmlelement,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .validation-headers .form-field-type-columns .row .col-sm-3 .form-field-type-htmlelement {
  margin: 0;
  line-height: 1;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .validation-headers .form-field-type-columns .row .col-sm-3 .form-field-type-htmlelement .label,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .validation-headers .form-field-type-columns .row .col-sm-3 .form-field-type-htmlelement .label {
  display: inline-block;
  padding: 5px 8px 2px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .validation-headers .form-field-type-columns .row:hover,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .validation-headers .form-field-type-columns .row:hover {
  background: rgba(70, 81, 170, 0.1);
  padding: 0 15px;
}
@media screen and (max-width: 768px) {
  body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .validation-headers .form-field-type-columns .row,
  body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .validation-headers .form-field-type-columns .row {
    flex-direction: row;
  }
  body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .validation-headers .form-field-type-columns .row > div + div,
  body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .validation-headers .form-field-type-columns .row > div + div {
    margin-top: 0;
  }
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .validation-headers.document-open .tab-description-back-to-list,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .validation-headers.document-open .tab-description-back-to-list {
  display: flex;
  margin-bottom: 1rem;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .validation-headers.document-open > .form-field-type-columns,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .validation-headers.document-open > .form-field-type-columns {
  display: none;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .validation-headers.document-open + .carousel#carouselRevision,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .validation-headers.document-open + .carousel#carouselRevision {
  display: block;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .tab-description-back-to-list,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .tab-description-back-to-list {
  font-size: 18px;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  height: 34px;
  min-width: 40px;
  transition-duration: 0.15s;
  margin-inline-end: -12px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .tab-description-back-to-list:hover,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .tab-description-back-to-list:hover {
  background: rgba(70, 81, 170, 0.1);
  color: #222222;
  padding-inline-start: 12px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .tab-description-back-to-list i,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .tab-description-back-to-list i {
  display: block;
  width: 16px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision {
  display: none;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item {
  flex-wrap: wrap;
  gap: 3rem 0;
  align-items: center;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row:first-child,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row:first-child {
  flex: initial;
  max-width: 50%;
  padding-inline-end: 20px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row:last-child,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row:last-child {
  flex: 0 0 100%;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item .documents-validator,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item .documents-validator {
  min-height: 37px;
  align-items: center;
  display: flex;
  gap: 8px;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.3;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item .documents-validator .carousel-selected-index,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item .documents-validator .carousel-selected-index {
  inset-inline-start: 0;
  top: 0;
  position: relative;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row .formio-component-checkbox.check-switch,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row .formio-component-checkbox.check-switch {
  margin-top: 0;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row .formio-component-checkbox.check-switch .checkbox input[type="checkbox"] + span::before,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row .formio-component-checkbox.check-switch .checkbox input[type="checkbox"] + span::before {
  top: 0;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row .formio-component-checkbox.check-switch .form-check,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row .formio-component-checkbox.check-switch .form-check {
  display: flex;
  align-items: center;
  gap: 12px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row .formio-component-checkbox.check-switch .form-check.mt-3,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row .formio-component-checkbox.check-switch .form-check.mt-3 {
  margin-top: 0 !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row .formio-component-checkbox.check-switch .form-check.mb-3,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row .formio-component-checkbox.check-switch .form-check.mb-3 {
  margin-bottom: 0 !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row .formio-component-checkbox.check-switch .form-check > span,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row .formio-component-checkbox.check-switch .form-check > span {
  font-size: 18px;
  font-weight: 600;
  color: var(--color-gray-800);
  line-height: 1;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row .formio-component-checkbox.check-switch textarea,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row .formio-component-checkbox.check-switch textarea {
  border: solid 1px var(--color-gray-300);
  border-radius: 6px !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row .formio-component-checkbox.check-switch textarea:where(:hover, :focus),
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row .formio-component-checkbox.check-switch textarea:where(:hover, :focus) {
  outline: none;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row .formio-component-checkbox.check-switch textarea::-webkit-resizer,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row .formio-component-checkbox.check-switch textarea::-webkit-resizer {
  display: none;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row .formio-component-checkbox.check-switch label,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row .formio-component-checkbox.check-switch label {
  width: 100%;
  max-width: 320px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row .formio-component-checkbox.check-switch label span,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row .formio-component-checkbox.check-switch label span {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 7px;
  display: block;
  margin-top: 2px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row .formio-component-checkbox.check-switch select,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row .formio-component-checkbox.check-switch select {
  border: solid 1px var(--color-gray-300);
  border-radius: 6px !important;
  height: 34px;
  padding: 0 6px;
  width: 100%;
  max-width: 320px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row .formio-component-checkbox.check-switch select:focus,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row .formio-component-checkbox.check-switch select:focus {
  outline: none;
  box-shadow: none;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row .formio-component-checkbox.check-switch > div:first-child,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row .formio-component-checkbox.check-switch > div:first-child {
  margin-top: 8px;
  margin-inline-end: 32px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row .formio-component-checkbox.check-switch > div:first-child + div,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row .formio-component-checkbox.check-switch > div:first-child + div {
  flex: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row .formio-component-checkbox.check-switch > div:first-child + div textarea,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row .formio-component-checkbox.check-switch > div:first-child + div textarea {
  flex: 1 1 auto;
  padding: 4px 10px;
}
@media screen and (max-width: 768px) {
  body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row .formio-component-checkbox.check-switch,
  body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row .formio-component-checkbox.check-switch {
    flex-direction: column;
  }
  body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row .formio-component-checkbox.check-switch > div + div,
  body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row .formio-component-checkbox.check-switch > div + div {
    margin-top: 2rem;
  }
  body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row .formio-component-checkbox.check-switch > div + div select,
  body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row .formio-component-checkbox.check-switch > div + div select {
    width: 100%;
  }
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row > .col-md-6:last-child .formio-component.formio-component-panel:first-child,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row > .col-md-6:last-child .formio-component.formio-component-panel:first-child {
  margin-top: 0;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row > .col-md-6:last-child .formio-component.formio-component-panel > .card,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row > .col-md-6:last-child .formio-component.formio-component-panel > .card {
  border: none;
  margin-top: 0;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row > .col-md-6:last-child .formio-component.formio-component-panel > .card > .card-body > .formio-component-panel .card,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row > .col-md-6:last-child .formio-component.formio-component-panel > .card > .card-body > .formio-component-panel .card {
  margin: 0;
  border: none;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row > .col-md-6:last-child .formio-component.formio-component-panel > .card > .card-body > .formio-component-panel .card .card-header,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row > .col-md-6:last-child .formio-component.formio-component-panel > .card > .card-body > .formio-component-panel .card .card-header {
  margin: 0 0 1rem;
  background-color: transparent;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row > .col-md-6:last-child .formio-component.formio-component-panel > .card > .card-body > .formio-component-panel .card .card-body,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row > .col-md-6:last-child .formio-component.formio-component-panel > .card > .card-body > .formio-component-panel .card .card-body {
  padding-top: 0 !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row > .col-md-6:last-child .formio-component.formio-component-panel > .card > .card-body > .formio-component-panel .card .card-body .formio-component-panel:first-child .card .card-body,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row > .col-md-6:last-child .formio-component.formio-component-panel > .card > .card-body > .formio-component-panel .card .card-body .formio-component-panel:first-child .card .card-body {
  padding-top: 0 !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row > .col-md-6:last-child .formio-component.formio-component-panel > .card > .card-body > .formio-component-panel.readonly-forms-block .card .card-body,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row > .col-md-6:last-child .formio-component.formio-component-panel > .card > .card-body > .formio-component-panel.readonly-forms-block .card .card-body {
  padding-top: 1rem !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row > .col-md-6:last-child .block-previewcontainer,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row > .col-md-6:last-child .block-previewcontainer {
  flex: 1;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row > .col-md-6:last-child .block-previewcontainer > .formio-component-container:first-child,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row > .col-md-6:last-child .block-previewcontainer > .formio-component-container:first-child {
  margin-top: 0;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row > .col-md-6:last-child .block-previewcontainer:empty,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row > .col-md-6:last-child .block-previewcontainer:empty {
  display: none;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row:nth-child(3) ~ .row,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row:nth-child(3) ~ .row {
  margin-top: 3rem;
}
@media screen and (max-width: 991px) {
  body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row,
  body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row {
    flex-direction: column;
  }
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row:last-child,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row:last-child {
  margin: 0;
  gap: 3rem;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row:last-child::after,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row:last-child::after,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row:last-child::before,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row:last-child::before {
  display: none;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row:last-child > .col-md-6,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row:last-child > .col-md-6 {
  padding: 0;
  flex: 1;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row:last-child > .col-md-6:last-child,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item > .row:last-child > .col-md-6:last-child {
  display: contents;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item.item .img-thumbnail img,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item.item .img-thumbnail img {
  margin: 0 auto;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item:where(.active, .prev, .next, .right, .left),
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item:where(.active, .prev, .next, .right, .left) {
  display: flex;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item .readonly-form-columns.type-file .col-md-10,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item .readonly-form-columns.type-file .col-md-10 {
  width: 100%;
  border-inline-end: none;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item .readonly-form-columns.type-file .col-md-6,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-item .readonly-form-columns.type-file .col-md-6 {
  display: none;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-control,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-control {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 40px;
  min-width: 40px;
  transition-duration: 0.15s;
  border-radius: 6px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-control span,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-control span {
  display: block;
  font-size: 34px;
  color: #333333;
  text-shadow: none;
  width: 100%;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-control:hover,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component-part-b-documents-tab + .formio-component-panel[class*="formio-component-documentTab"] > .card > .card-body > .formio-component-panel > .card > .card-body > .formio-component-htmlelement .carousel#carouselRevision .carousel-inner .carousel-control:hover {
  background: rgba(70, 81, 170, 0.1);
  padding: 0 10px;
  color: #222222;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-applicantPaymentFees .card,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-applicantPaymentFees .card {
  background-color: transparent;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-applicantPaymentFees .card .card-header,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-applicantPaymentFees .card .card-header {
  padding: 0;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-applicantPaymentFees .card .card-body,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-applicantPaymentFees .card .card-body {
  padding-top: 2.5rem !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-applicantPaymentFees .card .card-body .formio-component-columns,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-applicantPaymentFees .card .card-body .formio-component-columns {
  margin-top: 0;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-applicantPaymentFees .card .card-body .formio-component-columns .formio-component-htmlelement,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-applicantPaymentFees .card .card-body .formio-component-columns .formio-component-htmlelement {
  margin-top: 0;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-applicantPaymentFees .card .card-body .formio-component-columns.formio-component-totalPrice,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-applicantPaymentFees .card .card-body .formio-component-columns.formio-component-totalPrice {
  align-items: center;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-applicantPaymentFees .card .card-body .formio-component-columns.formio-component-totalPrice .formio-component-htmlelement h4,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-applicantPaymentFees .card .card-body .formio-component-columns.formio-component-totalPrice .formio-component-htmlelement h4 {
  font-weight: 600;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-applicantPaymentFees .card .card-body .formio-component-columns.formio-component-totalPrice .formio-component-htmlelement p span,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-applicantPaymentFees .card .card-body .formio-component-columns.formio-component-totalPrice .formio-component-htmlelement p span {
  font-weight: 600;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-applicantPaymentPayments,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-applicantPaymentPayments {
  margin-bottom: 0;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-applicantPaymentPayments .card,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-applicantPaymentPayments .card {
  background-color: transparent;
  margin-bottom: 0 !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-applicantPaymentPayments .card .card-header,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-applicantPaymentPayments .card .card-header {
  padding: 0;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-applicantPaymentPayments .card .card-body,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-applicantPaymentPayments .card .card-body {
  padding-top: 2.5rem !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-applicantPaymentPayments .card .card-body .formio-component-columns .col-md-1,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-applicantPaymentPayments .card .card-body .formio-component-columns .col-md-1 {
  display: none;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-applicantPaymentPayments .card .card-body .formio-component-columns .col-md-11,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-applicantPaymentPayments .card .card-body .formio-component-columns .col-md-11 {
  padding-inline-start: 0;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-applicantPaymentPayments .card .card-body .formio-component-columns .formio-component-checkbox input[disabled],
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-applicantPaymentPayments .card .card-body .formio-component-columns .formio-component-checkbox input[disabled] {
  display: none;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-applicantPaymentPayments + .formio-component-button,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-applicantPaymentPayments + .formio-component-button {
  display: none;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-table.formio-component-documentsTabTable,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-table.formio-component-documentsTabTable {
  margin-top: 0;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-table.formio-component-documentsTabTable table tbody tr.row .formio-component-datagrid,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-table.formio-component-documentsTabTable table tbody tr.row .formio-component-datagrid {
  margin-top: 0px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-table.formio-component-documentsTabTable table tbody tr.row .formio-component-datagrid table.datagrid-table tbody tr td,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-table.formio-component-documentsTabTable table tbody tr.row .formio-component-datagrid table.datagrid-table tbody tr td {
  padding: 0;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-table.formio-component-documentsTabTable table tbody tr.row .formio-component-datagrid table.datagrid-table tbody tr td .formio-component-panel,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-table.formio-component-documentsTabTable table tbody tr.row .formio-component-datagrid table.datagrid-table tbody tr td .formio-component-panel {
  margin-bottom: 0;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-table.formio-component-documentsTabTable table tbody tr.row .formio-component-datagrid table.datagrid-table tbody tr td .formio-component-panel .card,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-table.formio-component-documentsTabTable table tbody tr.row .formio-component-datagrid table.datagrid-table tbody tr td .formio-component-panel .card {
  margin-bottom: 0 !important;
  border-radius: 6px;
  padding: 12px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-table.formio-component-documentsTabTable table tbody tr.row .formio-component-datagrid table.datagrid-table tbody tr td .formio-component-panel .card .card-body .formio-component-htmlelement p,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-table.formio-component-documentsTabTable table tbody tr.row .formio-component-datagrid table.datagrid-table tbody tr td .formio-component-panel .card .card-body .formio-component-htmlelement p {
  line-height: 1.2;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-table.formio-component-documentsTabTable table tbody tr.row .formio-component-datagrid table.datagrid-table tbody tr td .formio-component-panel .card .card-body .formio-component-htmlelement p a,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-table.formio-component-documentsTabTable table tbody tr.row .formio-component-datagrid table.datagrid-table tbody tr td .formio-component-panel .card .card-body .formio-component-htmlelement p a {
  display: block;
  margin-bottom: 2rem;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-table.formio-component-documentsTabTable table tbody tr.row .formio-component-datagrid table.datagrid-table tbody tr td .formio-component-panel .card .card-body .formio-component-htmlelement p a:last-child,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-table.formio-component-documentsTabTable table tbody tr.row .formio-component-datagrid table.datagrid-table tbody tr td .formio-component-panel .card .card-body .formio-component-htmlelement p a:last-child {
  margin-bottom: 0;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-table.formio-component-documentsTabTable table tbody tr.row .formio-component-datagrid table.datagrid-table tbody tr td .formio-component-panel .card .card-body .formio-component-htmlelement p span.bullet,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-table.formio-component-documentsTabTable table tbody tr.row .formio-component-datagrid table.datagrid-table tbody tr td .formio-component-panel .card .card-body .formio-component-htmlelement p span.bullet {
  vertical-align: initial;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-table.formio-component-documentsTabTable table tbody tr.row .formio-component-datagrid table.datagrid-table tbody tr td .formio-component-panel .card .card-body .formio-component-htmlelement p span.bullet ~ a,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-table.formio-component-documentsTabTable table tbody tr.row .formio-component-datagrid table.datagrid-table tbody tr td .formio-component-panel .card .card-body .formio-component-htmlelement p span.bullet ~ a {
  margin-top: 10px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-table.formio-component-documentsTabTable table tbody tr:not(.row) > td,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-table.formio-component-documentsTabTable table tbody tr:not(.row) > td {
  padding-inline-start: 0;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-table.formio-component-documentsTabTable table tbody tr:not(.row) > td .formio-component-datagrid,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-table.formio-component-documentsTabTable table tbody tr:not(.row) > td .formio-component-datagrid {
  margin-top: 0;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-table.formio-component-documentsTabTable table tbody tr:not(.row) > td > .formio-component-panel > .card .card-body .formio-component-htmlelement,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-table.formio-component-documentsTabTable table tbody tr:not(.row) > td > .formio-component-panel > .card .card-body .formio-component-htmlelement {
  margin-top: 0;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-table.formio-component-documentsTabTable table tbody tr:not(.row) > td > .formio-component-panel > .card .card-body .formio-component-htmlelement p,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-table.formio-component-documentsTabTable table tbody tr:not(.row) > td > .formio-component-panel > .card .card-body .formio-component-htmlelement p {
  line-height: 1.2;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-table.formio-component-documentsTabTable table tbody tr:not(.row) > td > .formio-component-panel > .card .card-body .formio-component-htmlelement p a,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-table.formio-component-documentsTabTable table tbody tr:not(.row) > td > .formio-component-panel > .card .card-body .formio-component-htmlelement p a {
  display: block;
  margin-bottom: 2rem;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-table.formio-component-documentsTabTable table tbody tr:not(.row) > td > .formio-component-panel > .card .card-body .formio-component-htmlelement p a:last-child,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-table.formio-component-documentsTabTable table tbody tr:not(.row) > td > .formio-component-panel > .card .card-body .formio-component-htmlelement p a:last-child {
  margin-bottom: 0;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-table.formio-component-documentsTabTable table tbody tr:not(.row) > td > .formio-component-panel > .card .card-body .formio-component-htmlelement p span.bullet,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-table.formio-component-documentsTabTable table tbody tr:not(.row) > td > .formio-component-panel > .card .card-body .formio-component-htmlelement p span.bullet {
  vertical-align: initial;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-table.formio-component-documentsTabTable table tbody tr:not(.row) > td.dg-action-col,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-table.formio-component-documentsTabTable table tbody tr:not(.row) > td.dg-action-col {
  display: none;
}
@media screen and (max-width: 768px) {
  body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-table.formio-component-documentsTabTable table.table tbody tr.row,
  body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane > .formio-component.formio-component-table.formio-component-documentsTabTable table.table tbody tr.row {
    flex-direction: column;
    margin: 0;
  }
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-modal-wrapper .formio-form .formio-component-tabs .card .card-body.tab-pane #datarejectionreason textarea,
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .formio-component-form .formio-form .formio-component-tabs .card .card-body.tab-pane #datarejectionreason textarea {
  font-size: 14px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .validator-radio {
  display: inline-flex;
  align-items: center;
  flex: 0;
  width: fit-content;
  cursor: pointer;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .validator-radio input {
  display: none;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .validator-radio input.valid:checked ~ label[for*="-yes"] {
  background-color: #80c49b;
  color: white;
  opacity: 1;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .validator-radio input.valid:checked ~ label[for*="-yes"] + label {
  background-color: rgba(70, 81, 170, 0.1);
  opacity: 0.6;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .validator-radio input.valid:checked ~ label[for*="-yes"] + label:hover {
  background-color: #d56d72;
  color: white;
  opacity: 1;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .validator-radio input.invalid:checked ~ label[for*="-yes"] {
  opacity: 0.6;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .validator-radio input.invalid:checked ~ label[for*="-yes"] + label {
  background-color: #d56d72;
  color: white;
  opacity: 1;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .validator-radio input.invalid:checked ~ label[for*="-yes"]:hover {
  opacity: 1;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .validator-radio .radio.form-check-inline {
  margin: 0;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .validator-radio .field-wrapper {
  gap: 10px;
  align-items: center;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .validator-radio .form-radio.radio {
  margin-top: 0;
  display: flex;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .validator-radio label {
  margin-bottom: 0;
  padding: 0.6rem 1rem;
  cursor: pointer;
  transition-duration: 0.3s;
  white-space: nowrap;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .validator-radio label.col-form-label {
  padding: 0;
  cursor: default;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .validator-radio label:is([for*="-yes"], [for*="-true"]) {
  background-color: rgba(70, 81, 170, 0.1);
  border-radius: 6px 0 0 6px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .validator-radio label:is([for*="-yes"], [for*="-true"]):hover {
  background-color: #80c49b;
  color: white;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .validator-radio label:is([for*="-no"], [for*="-false"]) {
  background-color: rgba(70, 81, 170, 0.05);
  border-radius: 0 6px 6px 0;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .validator-radio label:is([for*="-no"], [for*="-false"]):hover {
  background-color: #d56d72;
  color: white;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .validator-radio .radio-selected label:is([for*="-yes"], [for*="-true"]) {
  background-color: #80c49b;
  color: white;
}
body.modern-theme #base #content .section-body .contain-lg > div > div .new-formio-part-b .validator-radio .radio-selected label:is([for*="-no"], [for*="-false"]) {
  background-color: #d56d72;
  color: white;
}
body.modern-theme #base #content .section-body .contain-lg > div > div label[for*="-documentsTab"] {
  margin-bottom: 0;
}
body.modern-theme #base #content .section-body .contain-lg > div > div label[for*="-documentsTab"] h3 {
  margin: 0 0 18px;
  padding: 0;
  font-family: inherit;
  font-weight: 600;
  font-size: 18px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div.part-b-preview-new-formio #tabs-zone .panel .part-b-forms-tabs {
  z-index: 1;
  position: relative;
}
body.modern-theme #base #content .section-body .contain-lg > div > div.part-b-preview-new-formio #tabs-zone .panel .part-b-forms-tabs ul.card-head {
  background-color: transparent;
}
body.modern-theme #base #content .section-body .contain-lg > div > div.part-b-preview-new-formio #tabs-zone .panel .part-b-forms-tabs ul.card-head > li {
  box-shadow: none;
  height: auto;
}
body.modern-theme #base #content .section-body .contain-lg > div > div.part-b-preview-new-formio #tabs-zone .panel .part-b-forms-tabs ul.card-head > li a {
  font-size: 23px;
  color: rgba(70, 81, 170, 0.4);
  font-weight: 600;
  padding-inline-start: 24px;
  padding-inline-end: 24px;
  border-top: none;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  box-shadow: none;
}
body.modern-theme #base #content .section-body .contain-lg > div > div.part-b-preview-new-formio #tabs-zone .panel .part-b-forms-tabs ul.card-head > li.active a {
  background-color: white;
  color: var(--color-primary-600);
  box-shadow: 0 11px 20px rgba(0, 0, 0, 0.05);
}
@media screen and (max-width: 680px) {
  body.modern-theme #base #content .section-body .contain-lg > div > div.part-b-preview-new-formio #tabs-zone .panel .part-b-forms-tabs ul.card-head > li a {
    padding: 2rem;
    line-height: 1;
    font-size: 18px;
  }
}
@media screen and (max-width: 480px) {
  body.modern-theme #base #content .section-body .contain-lg > div > div.part-b-preview-new-formio #tabs-zone .panel .part-b-forms-tabs ul.card-head > li a {
    padding: 1rem;
    font-size: 16px;
  }
}
body.modern-theme #base #content .section-body .contain-lg > div > div.part-b-preview-new-formio #tabs-zone .panel .panel-body.tab-content {
  position: relative;
  z-index: 2;
  box-shadow: 0 11px 20px rgba(0, 0, 0, 0.05);
  padding: 23px !important;
}
body.modern-theme #base #content .section-body .contain-lg > div > div.part-b-preview-new-formio #tabs-zone #form-type-role .formio-component-radio input.form-check-input {
  margin: 0;
  height: 20px;
}
body.modern-theme #base #content .section-body .contain-lg > div > div.part-b-preview-new-formio #tabs-zone #form-type-role .formio-component-radio input.form-check-input::before {
  margin-top: 3px;
}
body.modern-theme #base #content .section-body .contain-lg [class*="formio-component-panelsendbacktocorrections"] + .formio-dialog-buttons button[ref="modalSave"] {
  display: none;
}
body.modern-theme #base #content .section-body .contain-lg [class*="formio-component-panelsendbacktocorrections"] .form-control.dropdown {
  padding-inline-end: 34px;
  padding-inline-start: 14px;
}
body.modern-theme #base #content .section-body .contain-lg [class*="formio-component-panelsendbacktocorrections"] .choices__item {
  line-height: 2em;
}
body.modern-theme #base #content .section-body .contain-lg [class*="formio-component-panelsendbacktocorrections"] .choices__item--selectable {
  padding-inline-end: 20px;
}
body.modern-theme #base #content .section-body .contain-lg [class*="formio-component-panelsendbacktocorrections"] .choices__item--selectable .choices__button {
  margin-inline-end: 0 !important;
}
body.modern-theme #base #content .section-body .contain-lg [class*="formio-component-panelsendbacktocorrections"] .choices__item [data-tooltip]::before,
body.modern-theme #base #content .section-body .contain-lg [class*="formio-component-panelsendbacktocorrections"] .choices__item [data-tooltip]::after {
  display: none;
}
body.modern-theme #base #content .section-body .contain-lg [class*="formio-component-panelsendbacktocorrections"] .formio-component-select .choices__button {
  margin-right: initial !important;
}
body.modern-theme #base #content .section-body .contain-lg .formio-component-modal-wrapper .formio-dialog.formio-dialog-theme-default.component-rendering-hidden {
  z-index: -1;
}
body.modern-theme #base #content .section-body .contain-lg .formio-component-modal-wrapper .formio-dialog.formio-dialog-theme-default.component-rendering-hidden button {
  transition-duration: 0s;
}
@media screen and (max-width: 992px) {
  body.modern-theme #base #content .section-body .contain-lg #refreshJwt + .row.mt-2 {
    margin-top: 0 !important;
    justify-content: flex-end;
  }
}
body.modern-theme #base #content .section-body .contain-lg #refreshJwt + .row.mt-2 + .row > div[class^="col-"] {
  width: 100%;
}
body.modern-theme #base #content .section-body .contain-lg #formio-form-role > .alert {
  font-size: 14px !important;
}
body.modern-theme #base #content .section-body .contain-lg #formio-form-role > .alert.alert-success {
  position: fixed !important;
  top: 19px;
  inset-inline-end: 0;
  z-index: 99999;
  width: auto;
  max-width: 320px;
  background: #1C8C6E !important;
  color: white !important;
  padding: 1rem 2rem !important;
  font-size: 16px !important;
  inset-inline-end: 16px;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}
body.modern-theme #base #content .section-body .contain-lg #formio-form-role > .alert.alert-success::after {
  display: none !important;
}
body.modern-theme #base #content .section-body .contain-lg #formio-form-role > .alert.alert-danger {
  --alert-color: 247, 95, 95;
  bottom: auto;
  top: 144px;
  z-index: 99999;
  transition-duration: 0.2s;
  padding-inline-start: 1.5rem !important;
  border-color: rgb(var(--alert-color)) !important;
  box-shadow: 0 5px 20px rgba(var(--alert-color), 0.4);
  inset-inline-end: calc(((100vw - 1200px) / 2) - 10px);
  width: fit-content;
}
body.modern-theme #base #content .section-body .contain-lg #formio-form-role > .alert.alert-danger button.close-button {
  color: transparent;
  position: relative;
  top: -10px;
  inset-inline-end: -10px;
  border-radius: 0 6px 0 0;
  height: 37px;
}
body.modern-theme #base #content .section-body .contain-lg #formio-form-role > .alert.alert-danger button.close-button::before {
  font-family: var(--fontawesome-font);
  font-weight: 300;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  content: "\F00D";
  color: var(--color-gray-800);
  position: absolute;
  font-size: 16px;
  top: calc(50% - 8px);
}
body.modern-theme #base #content .section-body .contain-lg #formio-form-role > .alert.alert-danger button.close-button:hover {
  background: rgba(70, 81, 170, 0.1);
}
body.modern-theme #base #content .section-body .contain-lg #formio-form-role > .alert.alert-danger p {
  font-weight: 600;
  color: rgb(var(--alert-color));
  line-height: 1.3 !important;
}
body.modern-theme #base #content .section-body .contain-lg #formio-form-role > .alert.alert-danger ul {
  padding-inline-start: 14px;
  overflow-y: auto;
  width: 100%;
  padding-inline-end: 16px;
  max-height: min(calc(100vh - 200px), 320px);
}
body.modern-theme #base #content .section-body .contain-lg #formio-form-role > .alert.alert-danger ul li {
  margin-bottom: 8px;
  font-weight: 600;
  transition-duration: 0.15s;
}
body.modern-theme #base #content .section-body .contain-lg #formio-form-role > .alert.alert-danger ul li:last-child {
  margin-bottom: 0;
}
body.modern-theme #base #content .section-body .contain-lg #formio-form-role > .alert.alert-danger ul li:hover {
  padding-inline-start: 0;
  background: no-repeat;
}
body.modern-theme #base #content .section-body .contain-lg #formio-form-role > .alert.alert-danger ul li:hover::marker {
  content: "\f0a9";
}
body.modern-theme #base #content .section-body .contain-lg #formio-form-role > .alert.alert-danger ul li:hover span::after {
  display: none;
}
body.modern-theme #base #content .section-body .contain-lg #formio-form-role > .alert.alert-danger ul li span {
  margin-inline-start: 8px;
  text-decoration: none;
  display: block;
}
body.modern-theme #base #content .section-body .contain-lg #formio-form-role > .alert.alert-danger ul li span:hover {
  text-decoration: underline;
}
body.modern-theme #base #content .section-body .contain-lg #formio-form-role > .alert.alert-danger ul li::marker {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: var(--fontawesome-font);
  font-weight: 400;
  content: "\f06a";
  color: rgb(var(--alert-color));
}
body.modern-theme #base #content .section-body .contain-lg #formio-form-role > .alert.alert-danger ul::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
body.modern-theme #base #content .section-body .contain-lg #formio-form-role > .alert.alert-danger ul::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(var(--alert-color), 0.8);
  transition-duration: 0.15s;
}
body.modern-theme #base #content .section-body .contain-lg #formio-form-role > .alert.alert-danger ul::-webkit-scrollbar-track {
  background-color: rgba(var(--alert-color), 0.3);
}
@media screen and (max-width: 1200px) {
  body.modern-theme #base #content .section-body .contain-lg #formio-form-role > .alert.alert-danger {
    inset-inline-end: 2.4rem;
  }
}
body.modern-theme #base #content .section-body .contain-lg #formio-form-role .part-b-document-tab > .formio-component-panel > .card {
  border: none;
}
body.modern-theme #base #content .section-body .contain-lg #formio-form-role .part-b-document-tab > .formio-component-panel > .card .formio-component-panel {
  margin-inline: 0px;
}
body.modern-theme.sticky-menu #base #content .section-body .contain-lg #formio-form-role > .alert.alert-danger {
  top: 64px;
}
body.modern-theme .popup {
  font-size: 14px;
}
body.modern-theme .ui.popup {
  border-radius: 6px;
}
body.modern-theme .daterangepicker .ranges li.active,
body.modern-theme .daterangepicker .drp-buttons .btn.btn-primary,
body.modern-theme .daterangepicker td.active:not(.off),
body.modern-theme .daterangepicker td.active:not(.off):hover {
  background-color: var(--color-primary-600);
}
body.modern-theme .formio-dialog.formio-dialog-theme-default .formio-dialog-close {
  inset-inline-end: 10px !important;
  top: 10px;
  right: auto;
  width: 38px;
  height: 38px;
  padding: 0 0.6rem;
}
body.modern-theme .formio-dialog-content [ref=dialogHeader] {
  padding-right: initial;
  padding-inline-end: 15px;
}
body.modern-theme .animated-placeholder-loading-entry {
  overflow: hidden;
  position: relative;
  background-color: var(--color-gray-75);
  height: 44px;
  border-radius: 6px;
}
body.modern-theme .animated-placeholder-loading-entry + .animated-placeholder-loading-entry {
  margin-top: 8px;
}
body.modern-theme .animated-placeholder-loading-entry::before {
  content: "";
  position: absolute;
  background: linear-gradient(90deg, var(--color-gray-75) 0%, var(--color-gray-100) 50%, var(--color-gray-75) 100%);
  height: 100%;
  width: 100%;
  z-index: 1;
  animation: placeHolderShimmer 1s infinite;
  animation-delay: var(--loop-delay);
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes buttonProgress {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes placeHolderShimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
html[dir="rtl"] body.modern-theme #header .secondary-menu .contain-lg nav.secondary-nav-container :where(.services-select, .roles-ui-dropdown) i.dropdown {
  left: 0;
  right: auto;
}
.services-select {
  z-index: 1000 !important;
}
.roles-ui-dropdown {
  z-index: 1000 !important;
}
#part-b-listing-loading-spinner {
  position: absolute;
  top: 5px;
  right: 5px;
}
body.modern-theme .secondary-menu.inspector-menu {
  background-color: var(--menu-bar-bg-color);
  border-block: solid 1px var(--color-gray-200);
}
body.modern-theme .secondary-menu.inspector-menu .contain-lg {
  display: flex;
}
body.modern-theme .secondary-menu.inspector-menu .contain-lg > nav {
  min-height: 40px;
}
body.modern-theme .secondary-menu.inspector-menu .contain-lg > nav ul {
  height: 100%;
}
body.modern-theme .secondary-menu.inspector-menu .contain-lg > nav ul li {
  color: white;
  height: 100%;
  font-weight: 600;
  font-size: 18px;
}
body.modern-theme .secondary-menu.inspector-menu .contain-lg > nav ul li a {
  display: flex;
  height: 100%;
  padding: 0;
  background: transparent;
  font-size: 18px;
}
body.modern-theme .secondary-menu.inspector-menu .contain-lg > nav ul li a[href] {
  color: white;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 9px;
  margin-block: 0;
  margin-inline-start: -9px;
  margin-inline-end: 6px;
}
body.modern-theme .secondary-menu.inspector-menu .contain-lg > nav ul li a[href]:hover,
body.modern-theme .secondary-menu.inspector-menu .contain-lg > nav ul li a[href]:active {
  background-color: rgba(0, 0, 0, 0.1);
  text-decoration: none;
}
body.modern-theme .secondary-menu.inspector-menu .contain-lg > nav ul li a > span {
  height: 100%;
  align-items: center;
  display: flex;
}
body.modern-theme .secondary-menu.inspector-menu .contain-lg > nav ul li a.inspector-text .fa-chevron-right[hidden] {
  display: flex;
  font-size: 10px;
  margin-inline-end: 12px;
  align-items: center;
}
body.modern-theme .secondary-menu.inspector-menu .contain-lg > nav ul li .fa-chevron-right {
  font-size: 10px;
  margin-inline-end: 12px;
}
body.modern-theme .secondary-menu.inspector-menu .contain-lg .service-bar-user-lang-menu[hidden] {
  display: block;
  margin-inline-start: auto;
  margin-inline-end: -1.5rem;
}
body.modern-theme .secondary-menu.inspector-menu .contain-lg .service-bar-user-lang-menu[hidden] .headerbar-right {
  height: 40px;
  flex-direction: row-reverse;
}
body.modern-theme .secondary-menu.inspector-menu .contain-lg .service-bar-user-lang-menu[hidden] .headerbar-right ul#language_selector_container > li.dropdown-user a.dropdown-toggle {
  padding: 0 1.5rem;
  height: 40px;
  display: flex;
  align-items: center;
  gap: 6px;
  line-height: 1;
}
body.modern-theme .secondary-menu.inspector-menu .contain-lg .service-bar-user-lang-menu[hidden] .headerbar-right ul#language_selector_container > li.dropdown-user a.dropdown-toggle i {
  font-size: 16px;
  margin-inline-start: 0.8rem;
}
body.modern-theme .secondary-menu.inspector-menu .contain-lg .service-bar-user-lang-menu[hidden] .headerbar-right ul#language_selector_container > li.dropdown-user.open ul.dropdown-menu {
  padding: 0;
  inset-inline-end: inherit;
  border-radius: 0 6px 6px 6px;
  min-width: 100%;
}
body.modern-theme .secondary-menu.inspector-menu .contain-lg .service-bar-user-lang-menu[hidden] .headerbar-right ul#language_selector_container > li.dropdown-user.open ul.dropdown-menu li:first-child a {
  border-radius: 0 6px 0 0;
}
body.modern-theme .secondary-menu.inspector-menu .contain-lg .service-bar-user-lang-menu[hidden] .headerbar-right ul#language_selector_container > li.dropdown-user.open ul.dropdown-menu li:last-child a {
  border-radius: 0 0 6px 6px;
}
body.modern-theme .secondary-menu.inspector-menu .contain-lg .service-bar-user-lang-menu[hidden] .headerbar-right ul#connect {
  height: 100%;
}
body.modern-theme .secondary-menu.inspector-menu .contain-lg .service-bar-user-lang-menu[hidden] .headerbar-right ul#connect li.dropdown.user {
  height: 100%;
}
body.modern-theme .secondary-menu.inspector-menu .contain-lg .service-bar-user-lang-menu[hidden] .headerbar-right ul#connect li.dropdown.user a {
  text-transform: none;
  font-weight: 600;
}
body.modern-theme .secondary-menu.inspector-menu .contain-lg .service-bar-user-lang-menu[hidden] .headerbar-right ul#connect li.dropdown.user a.dropdown-toggle.left-part {
  margin: 0;
  border: none;
  padding-inline-start: 0;
  padding-inline-end: 0;
  border-radius: 0;
}
body.modern-theme .secondary-menu.inspector-menu .contain-lg .service-bar-user-lang-menu[hidden] .headerbar-right ul#connect li.dropdown.user a.dropdown-toggle.left-part i.user-icon {
  display: none;
}
body.modern-theme .secondary-menu.inspector-menu .contain-lg .service-bar-user-lang-menu[hidden] .headerbar-right ul#connect li.dropdown.user a.dropdown-toggle.left-part span.user-name-text {
  padding: 0;
}
body.modern-theme .secondary-menu.inspector-menu .contain-lg .service-bar-user-lang-menu[hidden] .headerbar-right ul#connect li.dropdown.user a.dropdown-toggle.left-part:hover {
  text-decoration: underline;
}
body.modern-theme .secondary-menu.inspector-menu .contain-lg .service-bar-user-lang-menu[hidden] .headerbar-right ul#connect li.dropdown.user a.dropdown-toggle.user-dropdown-toggle {
  border: none;
  padding-inline-end: 1.3rem;
  padding-inline-start: 1.3rem;
  border-radius: 0;
}
body.modern-theme .secondary-menu.inspector-menu .contain-lg .service-bar-user-lang-menu[hidden] .headerbar-right ul#connect li.dropdown.user a.dropdown-toggle.user-dropdown-toggle i.angle-icon {
  margin-inline-end: 0;
  font-weight: 300;
  font-size: 16px;
}
body.modern-theme .secondary-menu.inspector-menu .contain-lg .service-bar-user-lang-menu[hidden] .headerbar-right ul#connect li.dropdown.user.open ul.dropdown-menu {
  width: 100%;
  min-width: fit-content;
  padding: 0;
  border-radius: 0 0 6px 6px;
}
body.modern-theme .secondary-menu.inspector-menu .contain-lg .service-bar-user-lang-menu[hidden] .headerbar-right ul#connect li.dropdown.user.open ul.dropdown-menu li.divider {
  margin: 0;
  background-color: rgba(70, 81, 170, 0.1);
  height: 1px;
}
body.modern-theme .secondary-menu.inspector-menu .contain-lg .service-bar-user-lang-menu[hidden] .headerbar-right ul#connect li.dropdown.user.open ul.dropdown-menu li.divider:first-child {
  display: none;
}
body.modern-theme .secondary-menu.inspector-menu .contain-lg .service-bar-user-lang-menu[hidden] .headerbar-right ul#connect li.dropdown.user.open ul.dropdown-menu li a {
  padding: 7px 20px;
}
body.modern-theme .secondary-menu.inspector-menu .contain-lg .service-bar-user-lang-menu[hidden] .headerbar-right ul#connect li.dropdown.user.open ul.dropdown-menu li a:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
body.modern-theme .secondary-menu.inspector-menu .contain-lg .service-bar-user-lang-menu[hidden] .headerbar-right ul#connect li.dropdown.user.open ul.dropdown-menu li:last-child a {
  border-radius: 0 0 6px 6px !important;
}
body.modern-theme .secondary-menu.inspector-menu .contain-lg .service-bar-user-lang-menu[hidden] .headerbar-right ul#connect li:not(.dropdown) {
  height: 100%;
}
body.modern-theme .secondary-menu.inspector-menu .contain-lg .service-bar-user-lang-menu[hidden] .headerbar-right ul#connect li:not(.dropdown) a {
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 0 !important;
  margin: 0 !important;
  padding: 0 2rem;
  text-transform: none;
  font-weight: 600;
  box-shadow: none !important;
}
@media screen and (max-width: 1200px) {
  body.modern-theme .secondary-menu.inspector-menu .contain-lg .service-bar-user-lang-menu[hidden] {
    margin-inline-end: 0;
  }
}
body.modern-theme #base {
  background-color: var(--color-gray-75);
}
body.modern-theme #base #content .section-body .contain-lg .row .panel .panel-body #entreprise-grid.inspector-board table thead tr:first-child th {
  padding-inline-start: 20px;
  padding-inline-end: 20px;
}
body.modern-theme #base #content .section-body .contain-lg .row .panel .panel-body #entreprise-grid.inspector-board table thead tr:first-child th.service-column {
  width: 33%;
}
body.modern-theme #base #content .section-body .contain-lg .row .panel .panel-body #entreprise-grid.inspector-board table thead tr:first-child th:first-child {
  width: 33%;
}
body.modern-theme #base #content .section-body .contain-lg .row .panel .panel-body #entreprise-grid.inspector-board table thead tr:first-child th .sortorder {
  top: calc(50% - 10px);
}
body.modern-theme #base #content .section-body .contain-lg .row .panel .panel-body #entreprise-grid.inspector-board table thead tr.filters td {
  padding-inline-start: 0;
  padding-inline-end: 0;
  border: none;
}
body.modern-theme #base #content .section-body .contain-lg .row .panel .panel-body #entreprise-grid.inspector-board table thead tr.filters td input,
body.modern-theme #base #content .section-body .contain-lg .row .panel .panel-body #entreprise-grid.inspector-board table thead tr.filters td select {
  border: solid 1px var(--fields-border-color, var(--color-gray-300));
  background-color: transparent;
  border-radius: 6px;
  margin-top: 18px;
  height: 34px;
  box-shadow: none;
  margin-inline-end: 20px;
  width: calc(100% - 20px);
  margin-bottom: 12px;
  outline: none;
  padding: 4px 20px;
  font-weight: 600;
  font-size: 13px;
  color: var(--color-gray-800);
}
body.modern-theme #base #content .section-body .contain-lg .row .panel .panel-body #entreprise-grid.inspector-board table thead tr.filters td select {
  padding-block: 4px;
  padding-inline-start: 15px;
  padding-inline-end: 32px;
}
body.modern-theme #base #content .section-body .contain-lg .row .panel .panel-body #entreprise-grid.inspector-board table thead tr.filters td #reportrange {
  margin-inline-start: 20px;
}
body.modern-theme #base #content .section-body .contain-lg .row .panel .panel-body #entreprise-grid.inspector-board table tbody tr {
  background-color: transparent;
  transition: all 0.2s;
}
body.modern-theme #base #content .section-body .contain-lg .row .panel .panel-body #entreprise-grid.inspector-board table tbody tr:hover {
  transition: all 0.2s;
  background-color: rgba(70, 81, 170, 0.05);
}
body.modern-theme #base #content .section-body .contain-lg .row .panel .panel-body #entreprise-grid.inspector-board table tbody tr td {
  background-color: transparent;
  border: none;
  border-bottom: solid 1px rgba(70, 81, 170, 0.1);
  padding-inline-start: 20px;
  padding-inline-end: 20px;
  font-size: var(--custom-font-size, 15px);
  font-weight: 500;
  color: var(--color-gray-800);
}
body.modern-theme #base #content .section-body .contain-lg .row .panel .panel-body #entreprise-grid.inspector-board table tbody tr td.date-col {
  font-size: 12px;
  font-weight: 600;
  text-align: start;
}
body.modern-theme #base #content .section-body .contain-lg .row .panel .panel-body #entreprise-grid.inspector-board table tbody tr td.text-center {
  text-align: start;
}
body.modern-theme #base #content .section-body .contain-lg .row .panel .panel-body #entreprise-grid.inspector-board table tbody tr td span.badge {
  background: transparent;
  color: var(--color-gray-800);
  padding: 0;
  display: flex;
  font-weight: 500;
}
body.modern-theme #base #content .section-body .contain-lg .row .panel .panel-body #entreprise-grid.inspector-board table tbody tr td a.invisible-link {
  display: block;
  padding: 1.5rem 2rem;
  color: inherit;
}
body.modern-theme #base #content .section-body .contain-lg .row .panel .panel-body #entreprise-grid.inspector-board table .date-column {
  width: 15%;
}
body.modern-theme #base #content .section-body .contain-lg .row .panel .panel-body #entreprise-grid.inspector-board table .actions-column {
  width: 20%;
  text-align: end;
}
body.modern-theme #base #content .section-body .contain-lg .row .panel .panel-body #entreprise-grid.inspector-board table .actions-column a {
  border: solid 1px var(--link-color, rgba(70, 81, 170, 0.3));
  color: var(--link-hover-color, var(--link-color, #4651aa));
  background-color: var(--link-bg, white);
  border-radius: 4px;
}
body.modern-theme #base #content .section-body .contain-lg .row .panel .panel-body #entreprise-grid.inspector-board table .actions-column .view-data-history {
  margin-inline-start: 6px;
}
body.modern-theme #base #content .section-body .contain-lg .row .panel .panel-body #entreprise-grid.inspector-board table :is(.company-column, .service-column) {
  width: 25%;
}
body.modern-theme #base #content .section-body .contain-lg .row .panel .panel-body #entreprise-grid.inspector-board .table-paging-container {
  text-align: start;
  background-color: transparent;
}
body.modern-theme #base #content .section-body .contain-lg .row .panel .panel-body #entreprise-grid.inspector-board .table-paging-container dir-pagination-controls {
  display: flex;
}
body.modern-theme #base #content .section-body .contain-lg .row .panel .panel-body #entreprise-grid.inspector-board .table-paging-container dir-pagination-controls ul.pagination {
  background-color: rgba(70, 81, 170, 0.05);
  border-radius: 6px;
  overflow: hidden;
  margin-top: 32px;
  margin-bottom: 0;
}
body.modern-theme #base #content .section-body .contain-lg .row .panel .panel-body #entreprise-grid.inspector-board .table-paging-container dir-pagination-controls ul.pagination li {
  display: inline-flex;
  margin-inline-end: 1px;
}
body.modern-theme #base #content .section-body .contain-lg .row .panel .panel-body #entreprise-grid.inspector-board .table-paging-container dir-pagination-controls ul.pagination li a {
  background-color: transparent;
  height: 46px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  color: var(--color-gray-800);
  font-weight: 600;
  font-size: 12px;
}
body.modern-theme #base #content .section-body .contain-lg .row .panel .panel-body #entreprise-grid.inspector-board .table-paging-container dir-pagination-controls ul.pagination li.active a,
body.modern-theme #base #content .section-body .contain-lg .row .panel .panel-body #entreprise-grid.inspector-board .table-paging-container dir-pagination-controls ul.pagination li:hover a {
  background-color: rgba(70, 81, 170, 0.1);
}
body.modern-theme #base #content .section-body .contain-lg .row .panel .panel-body #entreprise-grid.inspector-board .table-paging-container dir-pagination-controls ul.pagination li:first-child a,
body.modern-theme #base #content .section-body .contain-lg .row .panel .panel-body #entreprise-grid.inspector-board .table-paging-container dir-pagination-controls ul.pagination li:last-child a {
  color: transparent;
  position: relative;
  width: 38px;
}
body.modern-theme #base #content .section-body .contain-lg .row .panel .panel-body #entreprise-grid.inspector-board .table-paging-container dir-pagination-controls ul.pagination li:first-child a::before,
body.modern-theme #base #content .section-body .contain-lg .row .panel .panel-body #entreprise-grid.inspector-board .table-paging-container dir-pagination-controls ul.pagination li:last-child a::before {
  font-family: var(--fontawesome-font);
  font-weight: 300;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-size: 22px;
  color: var(--color-gray-800);
  position: absolute;
}
body.modern-theme #base #content .section-body .contain-lg .row .panel .panel-body #entreprise-grid.inspector-board .table-paging-container dir-pagination-controls ul.pagination li:first-child a::before {
  content: "\f104";
}
body.modern-theme #base #content .section-body .contain-lg .row .panel .panel-body #entreprise-grid.inspector-board .table-paging-container dir-pagination-controls ul.pagination li:last-child a::before {
  content: "\f105";
}
.filters .dropdown-menu {
  max-height: 256px;
  overflow-y: auto;
  padding: 0;
}
.files-header {
  padding: 42px;
  background: #f8e69e;
  margin-top: 24px;
  margin-bottom: 24px;
  border-radius: 6px;
  background-image: url('/assets/images/archive1.png');
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
}
.files-header p {
  font-weight: 500;
  font-size: 14px;
}
.tble-file-icon {
  color: #EEC00C;
}
body.modern-theme #base {
  background-color: var(--color-gray-75);
}
body.modern-theme #base #content .section-body:has(.application-details-view-part-a) {
  margin-top: 0px;
}
body.modern-theme #base #content .section-body .contain-lg .current-service-container.finished-service-bar {
  height: 40px;
}
body.modern-theme #base #content .section-body .contain-lg .current-service-container.finished-service-bar .service-bar-user-lang-menu {
  margin-inline-start: auto;
  margin-inline-end: -1.5rem;
}
body.modern-theme #base #content .section-body .contain-lg .current-service-container.finished-service-bar .service-bar-user-lang-menu .headerbar-right {
  height: 40px;
  flex-direction: row-reverse;
}
body.modern-theme #base #content .section-body .contain-lg .current-service-container.finished-service-bar .service-bar-user-lang-menu .headerbar-right ul#language_selector_container > li.dropdown-user a.dropdown-toggle {
  padding: 0 1.5rem;
  height: 40px;
  display: flex;
  align-items: center;
  gap: 6px;
  line-height: 1;
}
body.modern-theme #base #content .section-body .contain-lg .current-service-container.finished-service-bar .service-bar-user-lang-menu .headerbar-right ul#language_selector_container > li.dropdown-user a.dropdown-toggle i {
  font-size: 16px;
  margin-inline-start: 0.8rem;
}
body.modern-theme #base #content .section-body .contain-lg .current-service-container.finished-service-bar .service-bar-user-lang-menu .headerbar-right ul#language_selector_container > li.dropdown-user.open ul.dropdown-menu {
  padding: 0;
  inset-inline-end: inherit;
  border-radius: 0 6px 6px 6px;
  min-width: 100%;
}
body.modern-theme #base #content .section-body .contain-lg .current-service-container.finished-service-bar .service-bar-user-lang-menu .headerbar-right ul#language_selector_container > li.dropdown-user.open ul.dropdown-menu li:first-child a {
  border-radius: 0 6px 0 0;
}
body.modern-theme #base #content .section-body .contain-lg .current-service-container.finished-service-bar .service-bar-user-lang-menu .headerbar-right ul#language_selector_container > li.dropdown-user.open ul.dropdown-menu li:last-child a {
  border-radius: 0 0 6px 6px;
}
@media screen and (max-width: 768px) {
  body.modern-theme #base #content .section-body .contain-lg .current-service-container.finished-service-bar .service-bar-user-lang-menu .headerbar-right ul#language_selector_container > li.dropdown-user {
    padding: 0;
  }
}
body.modern-theme #base #content .section-body .contain-lg .current-service-container.finished-service-bar .service-bar-user-lang-menu .headerbar-right ul#connect {
  height: 100%;
}
body.modern-theme #base #content .section-body .contain-lg .current-service-container.finished-service-bar .service-bar-user-lang-menu .headerbar-right ul#connect li.dropdown.user {
  height: 100%;
}
body.modern-theme #base #content .section-body .contain-lg .current-service-container.finished-service-bar .service-bar-user-lang-menu .headerbar-right ul#connect li.dropdown.user a {
  text-transform: none;
  font-weight: 600;
}
body.modern-theme #base #content .section-body .contain-lg .current-service-container.finished-service-bar .service-bar-user-lang-menu .headerbar-right ul#connect li.dropdown.user a.dropdown-toggle.left-part {
  margin: 0;
  border: none;
  padding-inline-start: 1.5rem;
  padding-inline-end: 0;
  border-radius: 0;
}
body.modern-theme #base #content .section-body .contain-lg .current-service-container.finished-service-bar .service-bar-user-lang-menu .headerbar-right ul#connect li.dropdown.user a.dropdown-toggle.left-part i.user-icon {
  display: none;
}
body.modern-theme #base #content .section-body .contain-lg .current-service-container.finished-service-bar .service-bar-user-lang-menu .headerbar-right ul#connect li.dropdown.user a.dropdown-toggle.left-part span.user-name-text {
  padding: 0;
}
@media screen and (max-width: 768px) {
  body.modern-theme #base #content .section-body .contain-lg .current-service-container.finished-service-bar .service-bar-user-lang-menu .headerbar-right ul#connect li.dropdown.user a.dropdown-toggle.left-part span.user-name-text {
    display: block;
  }
  body.modern-theme #base #content .section-body .contain-lg .current-service-container.finished-service-bar .service-bar-user-lang-menu .headerbar-right ul#connect li.dropdown.user a.dropdown-toggle.left-part span.user-name-text .user-initials[hidden] {
    display: inline-flex;
  }
  body.modern-theme #base #content .section-body .contain-lg .current-service-container.finished-service-bar .service-bar-user-lang-menu .headerbar-right ul#connect li.dropdown.user a.dropdown-toggle.left-part span.user-name-text .user-initials[hidden] span {
    display: block;
  }
}
body.modern-theme #base #content .section-body .contain-lg .current-service-container.finished-service-bar .service-bar-user-lang-menu .headerbar-right ul#connect li.dropdown.user a.dropdown-toggle.user-dropdown-toggle {
  border: none;
  padding-inline-end: 1.3rem;
  padding-inline-start: 1.3rem;
  border-radius: 0;
}
body.modern-theme #base #content .section-body .contain-lg .current-service-container.finished-service-bar .service-bar-user-lang-menu .headerbar-right ul#connect li.dropdown.user a.dropdown-toggle.user-dropdown-toggle i.angle-icon {
  margin-inline-end: 0;
  font-weight: 300;
  font-size: 16px;
}
body.modern-theme #base #content .section-body .contain-lg .current-service-container.finished-service-bar .service-bar-user-lang-menu .headerbar-right ul#connect li.dropdown.user.open ul.dropdown-menu {
  width: 100%;
  min-width: fit-content;
  padding: 0;
  border-radius: 0 0 6px 6px;
}
body.modern-theme #base #content .section-body .contain-lg .current-service-container.finished-service-bar .service-bar-user-lang-menu .headerbar-right ul#connect li.dropdown.user.open ul.dropdown-menu li.divider {
  margin: 0;
  background-color: rgba(70, 81, 170, 0.1);
  height: 1px;
}
body.modern-theme #base #content .section-body .contain-lg .current-service-container.finished-service-bar .service-bar-user-lang-menu .headerbar-right ul#connect li.dropdown.user.open ul.dropdown-menu li.divider:first-child {
  display: none;
}
body.modern-theme #base #content .section-body .contain-lg .current-service-container.finished-service-bar .service-bar-user-lang-menu .headerbar-right ul#connect li.dropdown.user.open ul.dropdown-menu li a {
  padding: 7px 20px;
  font-size: 13px;
  font-weight: 600;
}
body.modern-theme #base #content .section-body .contain-lg .current-service-container.finished-service-bar .service-bar-user-lang-menu .headerbar-right ul#connect li.dropdown.user.open ul.dropdown-menu li:last-child a {
  border-radius: 0 0 6px 6px !important;
}
@media screen and (max-width: 768px) {
  body.modern-theme #base #content .section-body .contain-lg .current-service-container.finished-service-bar .service-bar-user-lang-menu .headerbar-right ul#connect li.dropdown.user {
    padding: 0;
  }
}
body.modern-theme #base #content .section-body .contain-lg .current-service-container.finished-service-bar .service-bar-user-lang-menu .headerbar-right ul#connect li:not(.dropdown) {
  height: 100%;
}
body.modern-theme #base #content .section-body .contain-lg .current-service-container.finished-service-bar .service-bar-user-lang-menu .headerbar-right ul#connect li:not(.dropdown) a {
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 0 !important;
  margin: 0 !important;
  padding: 0 2rem;
  text-transform: none;
  font-weight: 600;
  box-shadow: none !important;
}
@media screen and (max-width: 768px) {
  body.modern-theme #base #content .section-body .contain-lg .current-service-container.finished-service-bar .service-bar-user-lang-menu .headerbar-right {
    min-height: 40px;
  }
}
@media screen and (max-width: 1200px) {
  body.modern-theme #base #content .section-body .contain-lg .current-service-container.finished-service-bar .service-bar-user-lang-menu {
    margin-inline-end: 0;
  }
}
body.modern-theme #base #content .section-body .contain-lg .current-service-container.finished-service-bar.affix {
  z-index: 1050;
  top: 0;
  margin: 0;
  width: 100%;
  max-width: inherit;
}
body.modern-theme #base #content .section-body .contain-lg .current-service-container.finished-service-bar.affix + div {
  padding-top: 40px;
}
body.modern-theme #base #content .section-body .contain-lg .application-details-view-part-a {
  background-color: var(--menu-bar-bg-color);
  border-block: solid 1px var(--color-gray-200);
  --go-offset: 50%;
}
body.modern-theme #base #content .section-body .contain-lg .application-details-view-part-a::after,
body.modern-theme #base #content .section-body .contain-lg .application-details-view-part-a::before {
  content: "";
  height: 64px;
  position: absolute;
  width: var(--go-offset);
  background-color: var(--menu-bar-bg-color);
  z-index: -1;
  border-block: solid 1px var(--color-gray-200);
}
body.modern-theme #base #content .section-body .contain-lg .application-details-view-part-a::after {
  right: calc(0% - var(--go-offset));
}
body.modern-theme #base #content .section-body .contain-lg .application-details-view-part-a::before {
  left: calc(0% - var(--go-offset));
}
body.modern-theme #base #content .section-body .contain-lg .application-details-view-part-a + div {
  margin-top: 30px;
}
body.modern-theme #base #content .section-body .contain-lg .application-details-view-part-a.affix {
  width: calc(100% - 4.8rem - (100% - var(--page-wrapper-content-width, 1200px)));
  z-index: 1060;
  top: 0px;
}
body.modern-theme #base #content .section-body .contain-lg .application-details-view-part-a.affix + div {
  margin-top: 94px;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms > .row > .col-sm-12 > .panel,
body.modern-theme #base #content .section-body .contain-lg div > .row > .col-sm-12 > .panel {
  padding: 24px;
  background: white;
  border: none;
  border-radius: 6px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms > .row > .col-sm-12 > .panel .panel-heading,
body.modern-theme #base #content .section-body .contain-lg div > .row > .col-sm-12 > .panel .panel-heading {
  padding: 0 !important;
  align-items: center;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms > .row > .col-sm-12 > .panel .panel-heading h3,
body.modern-theme #base #content .section-body .contain-lg div > .row > .col-sm-12 > .panel .panel-heading h3 {
  padding: 0;
  font-size: 21px;
  color: var(--color-gray-900);
  font-weight: 600;
  font-family: var(--main-font-family);
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms > .row > .col-sm-12 > .panel .panel-heading .process-box-toggler,
body.modern-theme #base #content .section-body .contain-lg div > .row > .col-sm-12 > .panel .panel-heading .process-box-toggler {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms > .row > .col-sm-12 > .panel .panel-heading .process-box-toggler .fa-times,
body.modern-theme #base #content .section-body .contain-lg div > .row > .col-sm-12 > .panel .panel-heading .process-box-toggler .fa-times {
  display: none;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms > .row > .col-sm-12 > .panel .panel-heading .process-box-toggler span,
body.modern-theme #base #content .section-body .contain-lg div > .row > .col-sm-12 > .panel .panel-heading .process-box-toggler span {
  color: var(--color-gray-800);
  line-height: 1.2;
  border-bottom: solid 1px;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms > .row > .col-sm-12 > .panel .panel-heading .process-box-toggler:not(.closed) .fa-times,
body.modern-theme #base #content .section-body .contain-lg div > .row > .col-sm-12 > .panel .panel-heading .process-box-toggler:not(.closed) .fa-times {
  display: inline-block;
  margin-inline-end: 10px;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms > .row > .col-sm-12 > .panel .panel-heading .process-box-toggler:not(.closed) .fa-cog,
body.modern-theme #base #content .section-body .contain-lg div > .row > .col-sm-12 > .panel .panel-heading .process-box-toggler:not(.closed) .fa-cog {
  display: none;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms > .row > .col-sm-12 > .panel .panel-heading .process-box-toggler:not(.closed) span,
body.modern-theme #base #content .section-body .contain-lg div > .row > .col-sm-12 > .panel .panel-heading .process-box-toggler:not(.closed) span {
  display: none;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms > .row > .col-sm-12 > .panel .panel-heading .restart-process-box,
body.modern-theme #base #content .section-body .contain-lg div > .row > .col-sm-12 > .panel .panel-heading .restart-process-box {
  margin-top: 0 !important;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms > .row > .col-sm-12 > .panel .panel-heading .restart-process-box.closed,
body.modern-theme #base #content .section-body .contain-lg div > .row > .col-sm-12 > .panel .panel-heading .restart-process-box.closed {
  display: none;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms > .row > .col-sm-12 > .panel .panel-body,
body.modern-theme #base #content .section-body .contain-lg div > .row > .col-sm-12 > .panel .panel-body {
  padding: 2rem 0 0 !important;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms > .row > .col-sm-12 > .panel .panel-body > .row,
body.modern-theme #base #content .section-body .contain-lg div > .row > .col-sm-12 > .panel .panel-body > .row {
  margin: 0;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms > .row > .col-sm-12 > .panel .panel-body > .row > .col-sm-12,
body.modern-theme #base #content .section-body .contain-lg div > .row > .col-sm-12 > .panel .panel-body > .row > .col-sm-12 {
  padding: 0;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms > .row > .col-sm-12 > .panel .panel-body > .row > .col-sm-12 table.table.table-history,
body.modern-theme #base #content .section-body .contain-lg div > .row > .col-sm-12 > .panel .panel-body > .row > .col-sm-12 table.table.table-history {
  display: block;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms > .row > .col-sm-12 > .panel .panel-body > .row > .col-sm-12 table.table.table-history tbody,
body.modern-theme #base #content .section-body .contain-lg div > .row > .col-sm-12 > .panel .panel-body > .row > .col-sm-12 table.table.table-history tbody {
  display: flex;
  padding: 1rem 0;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms > .row > .col-sm-12 > .panel .panel-body > .row > .col-sm-12 table.table.table-history tbody tr,
body.modern-theme #base #content .section-body .contain-lg div > .row > .col-sm-12 > .panel .panel-body > .row > .col-sm-12 table.table.table-history tbody tr {
  flex: 1;
  display: grid;
  position: relative;
  padding: 20px;
  transition-duration: 0.3s;
  max-width: 230px;
  min-width: 150px;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms > .row > .col-sm-12 > .panel .panel-body > .row > .col-sm-12 table.table.table-history tbody tr td,
body.modern-theme #base #content .section-body .contain-lg div > .row > .col-sm-12 > .panel .panel-body > .row > .col-sm-12 table.table.table-history tbody tr td {
  text-align: center;
  padding: 0 !important;
  min-width: 150px;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms > .row > .col-sm-12 > .panel .panel-body > .row > .col-sm-12 table.table.table-history tbody tr td:nth-child(2),
body.modern-theme #base #content .section-body .contain-lg div > .row > .col-sm-12 > .panel .panel-body > .row > .col-sm-12 table.table.table-history tbody tr td:nth-child(2) {
  margin-bottom: 4rem;
  font-size: 12px;
  font-weight: 500;
  color: rgba(27, 35, 49, 0.8);
  min-height: 18px;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms > .row > .col-sm-12 > .panel .panel-body > .row > .col-sm-12 table.table.table-history tbody tr td:nth-child(2)::before,
body.modern-theme #base #content .section-body .contain-lg div > .row > .col-sm-12 > .panel .panel-body > .row > .col-sm-12 table.table.table-history tbody tr td:nth-child(2)::before {
  content: "";
  display: block;
  position: absolute;
  top: calc(50% - 1px);
  inset-inline-start: 0;
  z-index: 1;
  width: 100%;
  height: 2px;
  background-color: #acb0c6;
  transition-duration: 0.3s;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms > .row > .col-sm-12 > .panel .panel-body > .row > .col-sm-12 table.table.table-history tbody tr td:nth-child(2)::after,
body.modern-theme #base #content .section-body .contain-lg div > .row > .col-sm-12 > .panel .panel-body > .row > .col-sm-12 table.table.table-history tbody tr td:nth-child(2)::after {
  content: "";
  display: block;
  position: absolute;
  top: calc(50% - 6px);
  inset-inline-start: calc(50% - 6px);
  z-index: 2;
  border: solid 2px #acb0c6;
  background-color: white;
  width: 12px;
  height: 12px;
  border-radius: 8px;
  transition-duration: 0.3s;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms > .row > .col-sm-12 > .panel .panel-body > .row > .col-sm-12 table.table.table-history tbody tr td:nth-child(2) small,
body.modern-theme #base #content .section-body .contain-lg div > .row > .col-sm-12 > .panel .panel-body > .row > .col-sm-12 table.table.table-history tbody tr td:nth-child(2) small {
  font-size: 100%;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms > .row > .col-sm-12 > .panel .panel-body > .row > .col-sm-12 table.table.table-history tbody tr td:nth-child(3),
body.modern-theme #base #content .section-body .contain-lg div > .row > .col-sm-12 > .panel .panel-body > .row > .col-sm-12 table.table.table-history tbody tr td:nth-child(3) {
  grid-row: 4;
  min-height: 24px;
  overflow: hidden;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms > .row > .col-sm-12 > .panel .panel-body > .row > .col-sm-12 table.table.table-history tbody tr td:nth-child(3) span,
body.modern-theme #base #content .section-body .contain-lg div > .row > .col-sm-12 > .panel .panel-body > .row > .col-sm-12 table.table.table-history tbody tr td:nth-child(3) span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-inline: 6px;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms > .row > .col-sm-12 > .panel .panel-body > .row > .col-sm-12 table.table.table-history tbody tr td span.label,
body.modern-theme #base #content .section-body .contain-lg div > .row > .col-sm-12 > .panel .panel-body > .row > .col-sm-12 table.table.table-history tbody tr td span.label {
  background-color: transparent;
  text-transform: none;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-gray-800);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms > .row > .col-sm-12 > .panel .panel-body > .row > .col-sm-12 table.table.table-history tbody tr td span.text-light,
body.modern-theme #base #content .section-body .contain-lg div > .row > .col-sm-12 > .panel .panel-body > .row > .col-sm-12 table.table.table-history tbody tr td span.text-light {
  font-size: 12px;
  font-weight: 500;
  color: rgba(27, 35, 49, 0.8);
  margin-top: 6px;
  display: block;
  text-transform: capitalize;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms > .row > .col-sm-12 > .panel .panel-body > .row > .col-sm-12 table.table.table-history tbody tr:first-child td:nth-child(2)::before,
body.modern-theme #base #content .section-body .contain-lg div > .row > .col-sm-12 > .panel .panel-body > .row > .col-sm-12 table.table.table-history tbody tr:first-child td:nth-child(2)::before {
  inset-inline-start: 50%;
  width: 50%;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms > .row > .col-sm-12 > .panel .panel-body > .row > .col-sm-12 table.table.table-history tbody tr:last-child,
body.modern-theme #base #content .section-body .contain-lg div > .row > .col-sm-12 > .panel .panel-body > .row > .col-sm-12 table.table.table-history tbody tr:last-child {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(172, 176, 198, 0.3);
  border-radius: 6px;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms > .row > .col-sm-12 > .panel .panel-body > .row > .col-sm-12 table.table.table-history tbody tr:last-child td:nth-child(2)::before,
body.modern-theme #base #content .section-body .contain-lg div > .row > .col-sm-12 > .panel .panel-body > .row > .col-sm-12 table.table.table-history tbody tr:last-child td:nth-child(2)::before {
  inset-inline-start: -1px;
  width: 50%;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms > .row > .col-sm-12 > .panel .panel-body > .row > .col-sm-12 table.table.table-history tbody tr:last-child td:nth-child(2)::after,
body.modern-theme #base #content .section-body .contain-lg div > .row > .col-sm-12 > .panel .panel-body > .row > .col-sm-12 table.table.table-history tbody tr:last-child td:nth-child(2)::after {
  background-color: #acb0c6;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms > .row > .col-sm-12 > .panel .panel-body > .row > .col-sm-12 table.table.table-history tbody tr:hover,
body.modern-theme #base #content .section-body .contain-lg div > .row > .col-sm-12 > .panel .panel-body > .row > .col-sm-12 table.table.table-history tbody tr:hover {
  background-color: transparent;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms > .row > .col-sm-12 > .panel .panel-body > .row > .col-sm-12 table.table.table-history tbody tr:hover td,
body.modern-theme #base #content .section-body .contain-lg div > .row > .col-sm-12 > .panel .panel-body > .row > .col-sm-12 table.table.table-history tbody tr:hover td {
  background-color: transparent;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms > .row > .col-sm-12 > .panel .panel-body.collapse,
body.modern-theme #base #content .section-body .contain-lg div > .row > .col-sm-12 > .panel .panel-body.collapse {
  padding-top: 0 !important;
  display: none;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms > .row > .col-sm-12 > .panel .panel-body.collapse.in,
body.modern-theme #base #content .section-body .contain-lg div > .row > .col-sm-12 > .panel .panel-body.collapse.in {
  padding-top: 2rem !important;
  display: flex;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card {
  background-color: transparent;
  box-shadow: none;
  border-radius: 6px !important;
  padding: 0px;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card > .card-header,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card > .card-header {
  height: auto;
  margin-bottom: -2px;
  z-index: 99;
  position: relative;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card > .card-header ul.card-header-tabs,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card > .card-header ul.card-header-tabs {
  background-color: transparent;
  height: auto;
  max-width: 100%;
  min-width: 100px;
  overflow-y: auto;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card > .card-header ul.card-header-tabs li.nav-item,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card > .card-header ul.card-header-tabs li.nav-item {
  flex: 0;
  height: auto;
  margin-bottom: 0;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card > .card-header ul.card-header-tabs li.nav-item::before,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card > .card-header ul.card-header-tabs li.nav-item::before {
  display: none;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card > .card-header ul.card-header-tabs li.nav-item a.nav-link,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card > .card-header ul.card-header-tabs li.nav-item a.nav-link {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.3);
  font-weight: 600;
  padding-inline-start: 24px;
  padding-inline-end: 24px;
  border-top-left-radius: calc(6px + 2px);
  border-top-right-radius: calc(6px + 2px);
  line-height: 1;
  padding-top: 22px;
  padding-bottom: 20px;
  text-decoration: none;
  white-space: nowrap;
  text-transform: none;
  border-style: solid;
  border-width: 0px;
  border-color: transparent;
  box-shadow: none;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card > .card-header ul.card-header-tabs li.nav-item a.nav-link.active,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card > .card-header ul.card-header-tabs li.nav-item a.nav-link.active {
  font-size: 20px;
  font-weight: 600;
  color: var(--color-primary-600);
  background-color: #FFFFFF;
  border-color: #edeff2;
  border-bottom-color: transparent;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card > .card-header ul.card-header-tabs li.nav-item a.nav-link:hover,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card > .card-header ul.card-header-tabs li.nav-item a.nav-link:hover {
  box-shadow: inset 0 -2px white;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card > .card-header ul.card-header-tabs li.nav-item.active,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card > .card-header ul.card-header-tabs li.nav-item.active {
  box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.03);
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card > .card-header ul.card-header-tabs::-webkit-scrollbar,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card > .card-header ul.card-header-tabs::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card > .card-header ul.card-header-tabs::-webkit-scrollbar-thumb,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card > .card-header ul.card-header-tabs::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(70, 81, 170, 0.4);
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card > .card-header ul.card-header-tabs::-webkit-scrollbar-track,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card > .card-header ul.card-header-tabs::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05);
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card > .card-header ul.card-header-tabs:hover::-webkit-scrollbar-thumb,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card > .card-header ul.card-header-tabs:hover::-webkit-scrollbar-thumb {
  background-color: rgba(70, 81, 170, 0.7);
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card > .card-header + .card-body.tab-pane.active,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card > .card-header + .card-body.tab-pane.active {
  border-top-left-radius: 0;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane {
  background-color: #FFFFFF;
  box-shadow: none;
  border-radius: 6px;
  padding: calc(24px + 24px / 2) 24px 24px;
  margin-top: 0;
  flex-direction: column;
  gap: 3rem;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-panel,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-panel,
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-container > div > .formio-component-panel,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-container > div > .formio-component-panel {
  margin-top: 0;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-panel .card,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-panel .card,
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-container > div > .formio-component-panel .card,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-container > div > .formio-component-panel .card {
  background-color: transparent;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-panel .card .card-header,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-panel .card .card-header,
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-container > div > .formio-component-panel .card .card-header,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-container > div > .formio-component-panel .card .card-header {
  padding: 0;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-panel .card .card-body:not(:first-child),
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-panel .card .card-body:not(:first-child),
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-container > div > .formio-component-panel .card .card-body:not(:first-child),
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-container > div > .formio-component-panel .card .card-body:not(:first-child) {
  padding: 1rem 0 0 !important;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-panel .card .card-body:first-child,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-panel .card .card-body:first-child,
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-container > div > .formio-component-panel .card .card-body:first-child,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-container > div > .formio-component-panel .card .card-body:first-child {
  padding: 0 !important;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-panel .card .card-body .formio-component-file .list-group,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-panel .card .card-body .formio-component-file .list-group,
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-container > div > .formio-component-panel .card .card-body .formio-component-file .list-group,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-container > div > .formio-component-panel .card .card-body .formio-component-file .list-group {
  margin-bottom: 0;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-panel .card .card-body > .formio-component-panel .card,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-panel .card .card-body > .formio-component-panel .card,
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-container > div > .formio-component-panel .card .card-body > .formio-component-panel .card,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-container > div > .formio-component-panel .card .card-body > .formio-component-panel .card {
  margin: 0;
  border: none;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-panel .card .card-body > .formio-component-panel .card .card-header,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-panel .card .card-body > .formio-component-panel .card .card-header,
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-container > div > .formio-component-panel .card .card-body > .formio-component-panel .card .card-header,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-container > div > .formio-component-panel .card .card-body > .formio-component-panel .card .card-header {
  margin: 0 0 1rem;
  background-color: transparent;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-panel .card .card-body > .formio-component-panel .card .card-body,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-panel .card .card-body > .formio-component-panel .card .card-body,
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-container > div > .formio-component-panel .card .card-body > .formio-component-panel .card .card-body,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-container > div > .formio-component-panel .card .card-body > .formio-component-panel .card .card-body {
  padding-top: 0;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-panel .card .card-body > .formio-component-panel .card .card-body .formio-component-panel:first-child .card .card-body,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-panel .card .card-body > .formio-component-panel .card .card-body .formio-component-panel:first-child .card .card-body,
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-container > div > .formio-component-panel .card .card-body > .formio-component-panel .card .card-body .formio-component-panel:first-child .card .card-body,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-container > div > .formio-component-panel .card .card-body > .formio-component-panel .card .card-body .formio-component-panel:first-child .card .card-body {
  padding-top: 0;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-panel .card .card-body .readonly-form-columns .formio-component-textarea .card.card-body,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-panel .card .card-body .readonly-form-columns .formio-component-textarea .card.card-body,
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-container > div > .formio-component-panel .card .card-body .readonly-form-columns .formio-component-textarea .card.card-body,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-container > div > .formio-component-panel .card .card-body .readonly-form-columns .formio-component-textarea .card.card-body {
  padding-top: 0 !important;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-panel .card::after,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-panel .card::after,
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-container > div > .formio-component-panel .card::after,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-container > div > .formio-component-panel .card::after {
  display: none;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-panel:last-child,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-panel:last-child,
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-container > div > .formio-component-panel:last-child,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-container > div > .formio-component-panel:last-child {
  margin-bottom: 0;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-panel:last-child > .card,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-panel:last-child > .card,
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-container > div > .formio-component-panel:last-child > .card,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-container > div > .formio-component-panel:last-child > .card {
  margin-bottom: 0 !important;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-container,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-container {
  margin-top: 0;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-container .readonly-forms-block ~ .readonly-forms-block,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-container .readonly-forms-block ~ .readonly-forms-block {
  margin-top: 2.5rem;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-table.formio-component-documentsTabTable,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-table.formio-component-documentsTabTable {
  margin-top: 0px;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-table.formio-component-documentsTabTable > table > tbody > tr > td:first-child,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-table.formio-component-documentsTabTable > table > tbody > tr > td:first-child {
  padding-inline-start: 0;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-table.formio-component-documentsTabTable > table > tbody > tr > td:last-child,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-table.formio-component-documentsTabTable > table > tbody > tr > td:last-child {
  padding-inline-end: 0;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-table.formio-component-documentsTabTable > table > tbody > tr > td td,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-table.formio-component-documentsTabTable > table > tbody > tr > td td {
  padding: 1.2rem;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-table.formio-component-documentsTabTable > table > tbody > tr > td td.review-certificates-container,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-table.formio-component-documentsTabTable > table > tbody > tr > td td.review-certificates-container {
  padding: 0px;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-table.formio-component-documentsTabTable td .formio-component-datagrid:only-child,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-table.formio-component-documentsTabTable td .formio-component-datagrid:only-child {
  margin-top: 0px;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-table.formio-component-documentsTabTable td .formio-component-datagrid .card,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-table.formio-component-documentsTabTable td .formio-component-datagrid .card {
  margin-bottom: 0px;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-table.formio-component-documentsTabTable td.dg-action-col,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-table.formio-component-documentsTabTable td.dg-action-col {
  display: none;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-table.formio-component-documentsTabTable table.datagrid-table .formio-component-panel:only-child,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-table.formio-component-documentsTabTable table.datagrid-table .formio-component-panel:only-child {
  margin-block: 0px;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-button.formio-component-printEntirePartAButton,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-button.formio-component-printEntirePartAButton {
  text-align: end;
  margin-bottom: -24px !important;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-button.formio-component-printEntirePartAButton button.btn,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-button.formio-component-printEntirePartAButton button.btn {
  border-radius: 6px !important;
  background-color: var(--color-primary-600);
  padding-inline-start: 25px;
  padding-inline-end: 25px;
  font-weight: 600;
  text-transform: none;
  position: relative;
  z-index: 1;
  height: 30px;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-button.formio-component-printEntirePartAButton button.btn:hover,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-button.formio-component-printEntirePartAButton button.btn:hover {
  box-shadow: 0 9px 20px rgba(70, 81, 170, 0.4) !important;
  opacity: 0.9;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-button.formio-component-printEntirePartAButton + .formio-component-container > [ref="nested-previewcontainer"] > .readonly-forms-block:first-child > .card > .card-body:first-child,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane > .formio-component-button.formio-component-printEntirePartAButton + .formio-component-container > [ref="nested-previewcontainer"] > .readonly-forms-block:first-child > .card > .card-body:first-child {
  margin-top: 24px;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane.active,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane.active {
  display: flex !important;
  border-style: solid;
  border-width: 0px;
  border-color: #edeff2;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane::before,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane::before,
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane::after,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card .card-body.tab-pane::after {
  display: none;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms #rolereview .formio-component-form .formio-form .formio-component-tabs > .card.card-vertical > .card-header + .card-body.tab-pane.active,
body.modern-theme #base #content .section-body .contain-lg div #rolereview .formio-component-form .formio-form .formio-component-tabs > .card.card-vertical > .card-header + .card-body.tab-pane.active {
  border-top-left-radius: 6px;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms .panel.overview-panel,
body.modern-theme #base #content .section-body .contain-lg div .panel.overview-panel {
  padding: 24px;
  border: solid 1px white;
  margin-bottom: 24px;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms .panel.overview-panel .panel-heading,
body.modern-theme #base #content .section-body .contain-lg div .panel.overview-panel .panel-heading {
  padding: 0;
  cursor: pointer;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms .panel.overview-panel .panel-heading .panel-title,
body.modern-theme #base #content .section-body .contain-lg div .panel.overview-panel .panel-heading .panel-title {
  padding: 0;
  font-size: 20px;
  color: var(--color-gray-900);
  font-weight: 600;
  font-family: inherit;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms .panel.overview-panel .panel-heading .panel-title i,
body.modern-theme #base #content .section-body .contain-lg div .panel.overview-panel .panel-heading .panel-title i {
  height: 26px;
  font-size: 27px;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms .panel.overview-panel .panel-heading + .panel-body.collapsing,
body.modern-theme #base #content .section-body .contain-lg div .panel.overview-panel .panel-heading + .panel-body.collapsing,
body.modern-theme #base #content .section-body .contain-lg .finished-service-forms .panel.overview-panel .panel-heading + .panel-body.in,
body.modern-theme #base #content .section-body .contain-lg div .panel.overview-panel .panel-heading + .panel-body.in {
  padding-top: 2rem !important;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-tabs ul.nav.nav-tabs.card-head > li {
  box-shadow: none;
  height: auto;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-tabs ul.nav.nav-tabs.card-head > li a {
  font-size: 20px;
  color: var(--color-gray-900);
  font-weight: 600;
  padding-inline-start: 42px;
  padding-inline-end: 42px;
  border-top: none;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-tabs ul.nav.nav-tabs.card-head > li.active a {
  background-color: rgba(70, 81, 170, 0.1);
  color: var(--color-primary-600);
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-tabs .panel-body.tab-content {
  background: white;
  border: none;
  border-radius: 6px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-tabs .panel-body.tab-content .tab-pane {
  box-shadow: none;
  background-color: white;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-tabs .panel-body.tab-content .tab-pane#documents {
  padding: 35px !important;
  border-radius: 0 6px 6px 6px;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-tabs .panel-body.tab-content .tab-pane#documents #documents-list h3,
body.modern-theme #base #content .section-body .contain-lg .finished-service-tabs .panel-body.tab-content .tab-pane#documents #certificats-list h3 {
  margin-top: 0;
  padding-top: 0;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-tabs .panel-body.tab-content .tab-pane#file_cata {
  border-radius: 6px;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-tabs .panel-body.tab-content .tab-pane#file_cata .zone_revision .revision-block form > .form-field-type-button {
  margin-inline-end: 35px;
  margin-top: 21px;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-tabs .panel-body.tab-content .tab-pane#file_cata .zone_revision .revision-block form > .form-field-type-mytabs .tabs-above ul.nav-tabs {
  background-color: white;
  border-radius: 6px 6px 0 0;
  margin-top: 14px;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-tabs .panel-body.tab-content .tab-pane#file_cata .zone_revision .revision-block form > .form-field-type-mytabs .tabs-above ul.nav-tabs li:first-of-type a {
  border-radius: 6px 0 0 0;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-tabs .panel-body.tab-content .tab-pane#file_cata .zone_revision .revision-block form > .form-field-type-mytabs .tabs-above ul.nav-tabs li a {
  color: var(--color-primary-600);
  text-decoration: none;
  font-weight: 600;
  padding: 0 35px;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-tabs .panel-body.tab-content .tab-pane#file_cata .zone_revision .revision-block form > .form-field-type-mytabs .tabs-above ul.nav-tabs li:hover a {
  border-bottom: none;
  box-shadow: inset 0 -2px 0 var(--color-primary-600) !important;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-tabs .panel-body.tab-content .tab-pane#file_cata .zone_revision .revision-block form > .form-field-type-mytabs .tabs-above ul.nav-tabs li.active {
  box-shadow: none;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-tabs .panel-body.tab-content .tab-pane#file_cata .zone_revision .revision-block form > .form-field-type-mytabs .tabs-above ul.nav-tabs li.active a {
  background-color: transparent;
  box-shadow: inset 0 -2px 0 var(--color-primary-600) !important;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-tabs .panel-body.tab-content .tab-pane#file_cata .zone_revision .revision-block form > .form-field-type-mytabs .tabs-above .tab-content .tab-pane .card .card-body > .form-field-type-panel > .panel > .panel-body {
  padding: 35px;
  background: white;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-tabs .panel-body.tab-content .tab-pane#file_cata .zone_revision .revision-block form > .form-field-type-mytabs .tabs-above .tab-content .tab-pane .card .card-body > .form-field-type-panel > .panel > .panel-body .form-field-type-panel .panel {
  background-color: white;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-tabs .panel-body.tab-content .tab-pane#file_cata .zone_revision .revision-block form > .form-field-type-mytabs .tabs-above .tab-content .tab-pane .card .card-body > .form-field-type-panel > .panel > .panel-body .form-field-type-panel .panel .panel-heading {
  background-color: transparent;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-tabs .panel-body.tab-content .tab-pane > .card > .card-body > .form-field-type-panel .panel {
  background-color: white;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-tabs .panel-body.tab-content .tab-pane > .card > .card-body > .form-field-type-panel .panel .form-field-type-panel .panel {
  margin: 0;
  padding: 0;
  border: none;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-tabs .panel-body.tab-content .tab-pane > .card > .card-body > .form-field-type-panel .panel .form-field-type-panel .panel .panel-heading {
  margin: 0;
  padding: 0;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-tabs .panel-body.tab-content .tab-pane > .card > .card-body > .form-field-type-panel .panel .form-field-type-panel .panel .panel-body {
  padding: 2.7rem 0 2.5rem;
}
body.modern-theme #base #content .section-body .contain-lg .finished-service-tabs .panel-body.tab-content .tab-pane > .card > .card-body > .form-field-type-panel .panel > .panel-heading {
  padding-inline-start: 35px;
  padding-inline-end: 35px;
}
body.modern-theme #base #rolereview .part-b-document-tab .formio-component-documentsTabTable .formio-component-datagrid td.review-documents-container {
  padding: 0px !important;
}
body.modern-theme #base #rolereview .part-b-document-tab .formio-component-documentsTabTable .formio-component-datagrid td.review-documents-container .card {
  background-color: transparent;
}
body.modern-theme #base #rolereview .part-b-document-tab .formio-component-documentsTabTable .formio-component-datagrid tr + tr .formio-component-panel.review-documents-container {
  margin-top: 30px !important;
}
body.modern-theme #base :is(.review-document-item, .review-certificate-item) {
  border: solid 1px var(--color-gray-200);
  border-radius: 8px;
  padding: 16px;
}
body.modern-theme #base :is(.review-document-item, .review-certificate-item) [ref="html"] {
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 12px;
}
body.modern-theme #base :is(.review-document-item, .review-certificate-item) .document-file-container {
  flex: 0 0 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
body.modern-theme #base :is(.review-document-item, .review-certificate-item) .document-file-link {
  margin-bottom: 0px !important;
}
body.modern-theme #base :is(.review-document-item, .review-certificate-item) .document-file-link:hover .document-file-image {
  outline: solid 3px var(--color-blue-200);
}
body.modern-theme #base :is(.review-document-item, .review-certificate-item) .document-file-image {
  max-width: 96px;
  width: 96px;
  height: 76px;
  max-height: 76px;
  object-fit: cover;
  object-position: top center;
  border-radius: 4px;
}
body.modern-theme #base :is(.review-document-item, .review-certificate-item) .document-number {
  color: var(--color-gray-900);
  font-size: 14px;
  font-weight: 600;
}
body.modern-theme #base :is(.review-document-item, .review-certificate-item) .document-name {
  font-weight: 600;
  flex: 1;
}
body.modern-theme #base :is(.review-document-item, .review-certificate-item):hover {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
}
body.modern-theme #base :is(.review-document-item, .review-certificate-item) .datetime {
  font-size: 13px;
  font-weight: 500;
  color: var(--color-gray-500);
}
body.modern-theme #base .review-certificate-item a i.fa-file {
  background-image: url(../../../images/file-pdf.png);
  margin: 16px;
  width: 126px;
  height: 100px;
  background-size: cover;
}
body.modern-theme #base .review-certificate-item a i.fa-file::before {
  display: none;
}
body.modern-theme #base :is([ref="datagrid-documentsTabCertificates-tbody"], [ref="datagrid-documentsTabDocuments-tbody"]) :is(.review-documents-container, .review-certificates-container) .alert-danger p {
  --custom-text-color: #ff003b;
}
body.modern-theme #base :is([ref="datagrid-documentsTabCertificates-tbody"], [ref="datagrid-documentsTabDocuments-tbody"]) :is(.review-documents-container, .review-certificates-container) .alert-danger span {
  color: inherit !important;
}
body.modern-theme #base :is([ref="datagrid-documentsTabCertificates-tbody"], [ref="datagrid-documentsTabDocuments-tbody"]) :is(.review-documents-container, .review-certificates-container) .alert:not(.formio-hidden) ~ :is(.review-document-item, .review-certificate-item) {
  margin-top: 1rem !important;
}
body.modern-theme #base :is([ref="datagrid-documentsTabCertificates-tbody"], [ref="datagrid-documentsTabDocuments-tbody"]) > tr + tr :is(.review-documents-container, .review-certificates-container) {
  margin-top: 30px !important;
}
body.modern-theme #base .history-status-cell {
  white-space: nowrap;
  text-overflow: ellipsis;
  transition-duration: 0.3s;
  line-height: 1;
  height: 26px;
  max-width: 100%;
  --custom-bg-color: rgba(27, 35, 49, 0.05);
  --custom-text-color: var(--color-gray-800);
}
body.modern-theme #base .history-status-cell span:empty {
  display: none;
}
body.modern-theme #base .history-status-cell span.status-tooltip-toggler {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  height: 26px;
}
body.modern-theme #base .history-status-cell span.status-tooltip {
  position: absolute;
  opacity: 0;
  z-index: -99;
  background: white !important;
  border: solid 1px var(--color-gray-300);
  border-radius: 6px;
  font-size: 12px;
  font-weight: 600;
  inset-inline-start: 2%;
  width: 96%;
  text-overflow: initial;
  transition-duration: 0.5s;
  max-height: 51px;
  overflow-y: auto;
  overflow-x: hidden;
  top: -50px;
  padding: 5px;
}
body.modern-theme #base .history-status-cell span.status-tooltip.open {
  opacity: 1;
  z-index: 99;
  transition-duration: 0.5s;
  overflow-y: hidden;
  overflow-x: hidden;
  top: 2%;
  max-height: 100%;
  display: flex;
}
body.modern-theme #base .history-status-cell span.status-tooltip div {
  white-space: normal;
  overflow-y: auto;
  overflow-x: auto;
  display: block;
  line-height: 1.4;
  word-break: break-word;
  width: 100%;
}
body.modern-theme #base .history-status-cell span.status-tooltip div ng-container {
  display: block;
  text-align: left;
}
body.modern-theme #base .history-status-cell span.status-tooltip div::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
body.modern-theme #base .history-status-cell span.status-tooltip div::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 6px;
}
body.modern-theme #base .history-status-cell span.status-tooltip div::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
}
body.modern-theme #base .history-status-cell span.status-tooltip i {
  position: absolute;
  width: 20px;
  height: 20px;
  line-height: 1;
  top: 4px;
  inset-inline-end: 0;
  display: block;
  color: #F75F5F;
  background: white;
  border-radius: 50%;
  display: flex;
  z-index: 99;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
body.modern-theme #base .history-status-cell span.status-tooltip i:hover {
  background-color: #FEEFEF;
}
body.modern-theme #base .history-status-cell span.white-text {
  color: white;
}
body.modern-theme #base .history-status-cell.status-filevalidated {
  --custom-bg-color: rgba(16, 185, 129, 0.05);
  --custom-text-color: #10B981;
  color: var(--custom-text-color);
}
body.modern-theme #base .history-status-cell.status-filepending {
  --custom-bg-color: rgba(76, 170, 229, 0.05);
  --custom-text-color: #4CAAE5;
  color: var(--custom-text-color);
}
body.modern-theme #base .history-status-cell.status-filedecline {
  --custom-bg-color: rgba(255, 163, 133, 0.05);
  --custom-text-color: #FFA385;
  color: var(--custom-text-color);
}
body.modern-theme #base .history-status-cell.status-filereject {
  --custom-bg-color: rgba(247, 95, 95, 0.05);
  --custom-text-color: #F75F5F;
  color: var(--custom-text-color);
}
body.modern-theme #base .history-status-cell.status-cancelled {
  --custom-bg-color: rgba(247, 95, 95, 0.05);
  --custom-text-color: #F75F5F;
  color: var(--custom-text-color);
}
body.modern-theme #base .file-rejection-attention {
  margin-inline-start: 22px;
  line-height: 27px;
  color: #FF7233;
}
body.modern-theme #base .file-rejection-attention i {
  margin-inline-end: 4px;
}
body.modern-theme #base .file-rejection-attention:nth-last-child(2) {
  margin-inline-end: auto;
  margin-inline-start: 8px;
}
body.modern-theme #base .file-rejection-attention + .file-rejection-attention i {
  display: none;
}
body.modern-theme #base .file-rejection-attention + .file-rejection-attention::before {
  content: " ";
  width: 1px;
  height: 10px;
  display: inline-block;
  background-color: var(--color-gray-500);
  margin-inline-end: 5px;
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) .big-title {
  font-weight: 600;
  color: var(--color-primary-600);
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) .list-container {
  padding: 24px;
  background: white;
  border: none;
  border-radius: 6px;
  box-shadow: 0px 9px 20px rgba(0, 0, 0, 0.02);
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) .list-container table.business-list-table {
  border-collapse: initial;
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) .list-container table.business-list-table thead {
  opacity: 1;
  position: relative;
  top: -5px;
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) .list-container table.business-list-table thead tr th {
  background-color: rgba(70, 81, 170, 0.05);
  text-transform: none;
  color: var(--color-primary-600);
  font-size: 14px;
  font-weight: 600;
  padding: 10px 20px;
  white-space: nowrap;
  border: none;
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) .list-container table.business-list-table thead tr th.row-toggler {
  padding: 0;
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) .list-container table.business-list-table thead tr th:first-child {
  border-radius: 6px 0 0 6px;
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) .list-container table.business-list-table thead tr th:last-child {
  border-radius: 0 6px 6px 0;
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) .list-container table.business-list-table thead tr th .sortorder {
  inset-inline-end: initial;
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) .list-container table.business-list-table tbody tr td {
  transition-duration: 0.2s;
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) .list-container table.business-list-table tbody tr td:not(.row-toggler) {
  padding: 15px 20px;
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) .list-container table.business-list-table tbody tr td.row-toggler {
  border-radius: 6px 0 0 6px;
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) .list-container table.business-list-table tbody tr td.row-toggler + td {
  font-weight: 500;
  font-size: 15px;
  color: var(--color-gray-800);
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) .list-container table.business-list-table tbody tr td.date-col {
  font-weight: 600;
  font-size: 12px;
  color: var(--color-gray-800);
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) .list-container table.business-list-table tbody tr td:last-child {
  border-radius: 0 6px 6px 0;
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) .list-container table.business-list-table tbody tr:hover td {
  background-color: rgba(0, 0, 0, 0.02);
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) .list-container table.business-list-table tbody tr.active td {
  background-color: white;
  border-top: solid 1px rgba(70, 81, 170, 0.2);
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) .list-container table.business-list-table tbody tr.active td:first-child {
  border-inline-start: solid 1px rgba(70, 81, 170, 0.2);
  border-radius: 6px 0 0 0;
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) .list-container table.business-list-table tbody tr.active td:last-child {
  border-inline-end: solid 1px rgba(70, 81, 170, 0.2);
  border-radius: 0 6px 0 0;
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) .list-container table.business-list-table tbody tr.active + tr > td {
  border-style: none solid solid;
  border-width: 1px;
  border-color: rgba(70, 81, 170, 0.2);
  background-color: white !important;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) .list-container table.business-list-table tbody tr.active + tr > td table tbody tr td {
  background-color: white;
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) .list-container table.business-list-table tbody tr.active + tr > td table tbody tr td .label.label-status {
  font-weight: 600;
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) .list-container table.business-list-table tbody tr.active + tr > td table tbody tr td .label.label-status.label-info {
  color: #4678aa;
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) .list-container table.business-list-table tbody tr.active + tr > td table tbody tr td .label.label-status.label-default {
  color: rgba(27, 35, 49, 0.5);
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) .list-container table.business-list-table tbody tr.active + tr > td table tbody tr td .label.label-status.label-warning {
  color: #FFA076;
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) .list-container table.business-list-table tbody tr.active + tr > td table tbody tr td .label.label-status.label-success {
  color: #389f8f;
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) .list-container table.business-list-table tbody tr.active + tr > td table tbody tr td .label.label-status.label-danger {
  color: #e84f73;
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) .list-container table.business-list-table tbody tr.active + tr > td table tbody tr:hover td {
  background-color: rgba(0, 0, 0, 0.02);
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) .editable-page-title-edit-zone {
  width: clamp(320px, 50%, 100%);
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) .editable-page-title-edit-zone input {
  height: 40px;
  font-weight: 600;
  color: var(--color-primary-600);
  font-size: 24px;
  padding-inline-end: 72px;
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) .editable-page-title-edit-zone i {
  top: 5px;
  padding: 0 12px;
  height: 30px;
  display: flex;
  align-items: center;
  transition-duration: 0.2s;
  border-radius: 6px;
  cursor: pointer;
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) .editable-page-title-edit-zone i.fa-check {
  inset-inline-end: 0;
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) .editable-page-title-edit-zone i.fa-times {
  inset-inline-end: 38px;
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) .editable-page-title-edit-zone i:hover {
  background-color: rgba(70, 81, 170, 0.1);
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) table.users-list-table thead {
  opacity: 1;
  position: relative;
  top: -5px;
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) table.users-list-table thead tr th {
  background-color: rgba(70, 81, 170, 0.05);
  text-transform: none;
  color: var(--color-primary-600);
  font-size: 14px;
  font-weight: 600;
  padding: 10px 20px;
  white-space: nowrap;
  border: none;
  letter-spacing: 0;
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) table.users-list-table thead tr th:first-child {
  border-radius: 6px 0 0 6px;
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) table.users-list-table thead tr th:last-child {
  border-radius: 0 6px 6px 0;
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) table.users-list-table thead tr th i {
  margin-inline-start: 6px;
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) table.users-list-table tbody tr td {
  background-color: white;
  vertical-align: middle;
  transition-duration: 0.2s;
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) table.users-list-table tbody tr td:first-child {
  border-radius: 6px 0 0 6px;
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) table.users-list-table tbody tr td:last-child {
  border-radius: 0 6px 6px 0;
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) table.users-list-table tbody tr:hover td {
  background-color: rgba(0, 0, 0, 0.02);
}
body.modern-theme #base #content .section-body .contain-lg :where(.be-list-page, .manage-be-page) table.users-list-table tfoot button {
  line-height: 1;
  min-height: 37px;
}
/* ANCHOR Common */
body.modern-theme {
  background-color: var(--color-gray-75);
  font-family: var(--main-font-family, 'Open Sans', sans-serif);
  padding-inline-end: 0;
  padding-right: initial !important;
}
body.modern-theme h1,
body.modern-theme h2,
body.modern-theme h3,
body.modern-theme h4,
body.modern-theme h5 {
  font-family: var(--main-font-family, 'Open Sans', sans-serif);
}
body.modern-theme .border-none {
  border: none;
}
:root {
  --main-font-family: 'Open Sans', sans-serif;
  --fontawesome-font: 'Font Awesome 6 Pro';
  --color-primary-50: #EDEEF7;
  --color-primary-75: #E3E4F2;
  --color-primary-100: #C6C9E5;
  --color-primary-200: #AAAFD8;
  --color-primary-300: #8D94CB;
  --color-primary-400: #7179BE;
  --color-primary-500: #545EB0;
  --color-primary-600: #4651AA;
  --color-primary-700: #363E83;
  --color-primary-800: #2B3269;
  --color-primary-900: #20254F;
  --color-primary-1000: #161934;
  --color-primary-1100: #0B0C1A;
  --color-gray-50: #F9FAFB;
  --color-gray-75: #F6F7F8;
  --color-gray-100: #EDEEF1;
  --color-gray-200: #E5E7EB;
  --color-gray-300: #D1D5DB;
  --color-gray-400: #9CA3AF;
  --color-gray-500: #6B7280;
  --color-gray-600: #4B5563;
  --color-gray-700: #374151;
  --color-gray-800: #1F2937;
  --color-gray-900: #111827;
  --color-blue-50: hsl(229, 85%, 97%);
  --color-blue-100: hsl(227, 85%, 95%);
  --color-blue-200: hsl(224, 86%, 89%);
  --color-blue-300: hsl(224, 89%, 82%);
  --color-blue-400: hsl(222, 90%, 73%);
  --color-blue-500: hsl(217, 91%, 60%);
  --color-blue-600: hsl(217, 70%, 54%);
  --color-blue-700: hsl(217, 61%, 46%);
  --color-blue-800: hsl(217, 62%, 38%);
  --color-blue-900: hsl(217, 62%, 27%);
  --color-orange-50: hsl(14, 100%, 97%);
  --color-orange-100: hsl(15, 100%, 94%);
  --color-orange-200: hsl(10, 100%, 92%);
  --color-orange-300: hsl(15, 100%, 76%);
  --color-orange-400: hsl(19, 100%, 65%);
  --color-orange-500: hsl(19, 100%, 60%);
  --color-orange-600: hsl(18, 74%, 53%);
  --color-orange-700: hsl(18, 67%, 47%);
  --color-orange-800: hsl(18, 66%, 41%);
  --color-orange-900: hsl(18, 67%, 33%);
  --color-green-50: #e8f7f1;
  --color-green-100: #d2f0e4;
  --color-green-200: #a5e1c9;
  --color-green-300: #78d2af;
  --color-green-400: #4bc394;
  --color-green-500: #1fb57a;
  --color-green-600: #189061;
  --color-green-700: #126c49;
  --color-green-800: #0c4830;
  --color-green-900: #062418;
  --color-red-50: #fef2f2;
  --color-red-100: #fee2e2;
  --color-red-200: #fecaca;
  --color-red-300: #fca5a5;
  --color-red-400: #f87171;
  --color-red-500: #ef4444;
  --color-red-600: #dc2626;
  --color-red-700: #b91c1c;
  --color-red-800: #991b1b;
  --color-red-900: #7f1d1d;
  --color-amber-50: #FFFBEB;
  --color-amber-100: #FEF3C7;
  --color-amber-200: #FDE68A;
  --color-amber-300: #FCD34D;
  --color-amber-400: #FBBF24;
  --color-amber-500: #F59E0B;
  --color-amber-600: #D97706;
  --color-amber-700: #B45309;
  --color-amber-800: #92400E;
  --color-amber-900: #78350F;
  --main-text-color: var(--color-gray-800);
  --color-white: hsla(0, 0%, 100%);
  --color-black: hsla(0, 0%, 0%);
  --color-trash: hsl(0, 90%, 67%);
  --color-trash-hover: hsl(0, 100%, 57%);
  --wizard-page-menu-height: 68px;
  --wizard-page-menu-top-margin: 0px;
  --wizard-page-menu-bottom-margin: 36px;
  --wizard-bar-bg-color: var(--color-white);
  --wizard-page-link-text-color: var(--color-gray-400);
  --wizard-page-link-active-text-color: var(--color-white);
  --wizard-page-link-bg-color: transparent;
  --wizard-page-link-active-bg-color: var(--color-gray-900);
  --wizard-page-link-text-size: 20px;
  --wizard-page-link-text-weight: 600;
  --wizard-page-link-block-padding: 8px;
  --wizard-page-link-inline-padding: 24px;
  --wizard-page-link-border-radius: 40px;
  --wizard-bar-items-gap: 12px;
  --button-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  --button-shadow-hover: 0 3px 4px rgba(0, 0, 0, 0.2);
  --part-b-services-menu-bg-color: var(--color-gray-200);
  --part-b-roles-menu-bg-color: var(--color-gray-50);
  --menu-bar-page-name-size: 22px;
  --page-wrapper-header-width: 95%;
  --page-wrapper-menu-width: 95%;
  --page-wrapper-content-width: calc(95% + 4.8rem);
  --page-wrapper-wizard-width: 100%;
  --page-wrapper-form-width: calc(95% + 4.8rem);
  --colored-bg-content-padding-x: 24px;
  --eg-add-edit-entry-row-bgcolor: var(--color-white);
  --eg-add-edit-entry-row-shadow: 0 5px 15px #00000010;
  --xs-button-height: 30px;
  --sm-button-height: 36px;
  --md-button-height: 40px;
  --lg-button-height: 48px;
  --xl-button-height: 60px;
  --xs-button-inline-padding: 16px;
  --xs-button-block-padding: 8px;
  --sm-button-inline-padding: 20px;
  --sm-button-block-padding: 10px;
  --md-button-inline-padding: 24px;
  --md-button-block-padding: 10px;
  --lg-button-inline-padding: 32px;
  --lg-button-block-padding: 12px;
  --xl-button-inline-padding: 40px;
  --xl-button-block-padding: 16px;
  --xs-button-font-size: 14px;
  --sm-button-font-size: 14px;
  --md-button-font-size: 16px;
  --lg-button-font-size: 18px;
  --xl-button-font-size: 20px;
  --xs-button-border-radius: 4px;
  --sm-button-border-radius: 4px;
  --md-button-border-radius: 6px;
  --lg-button-border-radius: 6px;
  --xl-button-border-radius: 8px;
  --default-button-height: var(--md-button-height);
  --default-button-font-size: var(--md-button-font-size);
  --default-button-inline-padding: var(--md-button-inline-padding);
  --default-button-block-padding: var(--md-button-block-padding);
  --default-button-border-radius: var(--md-button-border-radius);
  --inline-space: 10px;
  --inline-space-2x: 20px;
  --inline-space-3x: 30px;
}
/* Background colors */
.white-bg {
  background-color: #ffffff;
}
.no-hover-bg {
  background: transparent;
}
.gray-bg {
  background-color: #FEFEFE;
}
/* Margins & paddings */
.mb-2 {
  margin-bottom: 2rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mt-4 {
  margin-top: 4rem;
}
.mt-5 {
  margin-top: 5rem;
}
.mt-1-5 {
  margin-top: 1.5rem;
}
.mr-1 {
  margin-inline-end: 1rem;
}
.ml-auto {
  margin-inline-start: auto !important;
}
.mr-auto {
  margin-inline-end: auto;
}
.p2 {
  padding: 2rem !important;
}
/* Text */
.strong {
  font-weight: 700;
}
.dark-text,
.dark-text-hover:hover,
.dark-text-hover:hover span,
.dark-text-hover:hover i {
  color: #3f4451 !important;
}
.upper-text {
  text-transform: uppercase !important;
}
.no-underline,
.no-underline:hover {
  text-decoration: none;
}
.text-1-2 {
  font-size: 1.2rem;
}
.text-center {
  text-align: center;
}
.text-normal {
  font-weight: 400;
}
.text-left {
  text-align: start;
}
/* Helpers */
.d-flex {
  display: flex;
}
.white-space-nowrap {
  white-space: nowrap;
}
.list-group-item div.row {
  align-items: center;
}
.bottom-sticky {
  position: sticky;
  bottom: 0;
}
.back-link {
  display: inline-flex;
  align-items: center;
  width: 100px;
  transition-duration: 0.2s;
}
.back-link:hover {
  text-decoration: none;
}
.back-link:hover .back-link-icon,
.back-link:hover .back-link-text {
  color: #3C8FB9;
}
.back-link:hover .back-link-icon {
  margin-inline-end: 16px;
}
.back-link .back-link-icon {
  margin-inline-end: 11px;
  font-size: 11px;
  transition-duration: 0.2s;
}
.back-link .back-link-text {
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 12px;
  transition-duration: 0.2s;
}
.full-width {
  width: 100%;
}
.flex-align-center {
  align-items: center;
}
.flex-auto {
  flex: auto;
}
.w-30 {
  width: 30%;
}
/* Confirm modal */
.confirmation-modal {
  position: fixed;
  width: 100%;
  height: 0;
  inset-inline-start: 0;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0);
  transition-duration: 0.1s;
  inset-inline-end: 0;
  top: -100px;
  z-index: 9999;
  display: flex;
  align-items: center;
}
.confirmation-modal .confirmation-modal-box {
  transition-duration: 0.3s;
  transition-delay: 0.1s;
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  padding: 2rem;
  max-height: 500px;
  overflow-y: auto;
  background-color: #FFFFFF;
  border-radius: 2px;
  box-shadow: 0 2px 3px rgba(50, 50, 50, 0.1);
  border-top: solid 4px #f45b53;
}
.confirmation-modal .confirmation-modal-box .confirmation-modal-heading {
  text-transform: uppercase;
  margin: 0;
  font-size: 15px;
}
.confirmation-modal .confirmation-modal-box .confirmation-modal-body {
  margin-top: 2rem;
  font-weight: normal;
}
.confirmation-modal .confirmation-modal-box .confirmation-modal-body .confirmation-modal-text {
  line-height: 1.5;
  font-size: 15px;
}
.confirmation-modal .confirmation-modal-box .confirmation-modal-body .confirmation-modal-change-detail-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
}
.confirmation-modal .confirmation-modal-box .confirmation-modal-body .confirmation-modal-change-detail-container .orig-name {
  border-inline-end: dotted 1px #AFAFAF;
}
.confirmation-modal .confirmation-modal-box .confirmation-modal-body .confirmation-modal-change-detail-container h6 {
  margin-top: 0;
  margin-bottom: 8px;
  font-weight: normal;
  color: #999;
}
.confirmation-modal .confirmation-modal-box .confirmation-modal-body .confirmation-modal-change-detail-container .name-value {
  font-size: 15px;
}
.confirmation-modal .confirmation-modal-box .confirmation-modal-buttons {
  padding: 1rem 0 0;
}
.confirmation-modal .confirmation-modal-box .confirmation-modal-buttons .confirmation-modal-btn {
  color: #999999;
  display: inline-block;
  font-size: 14px !important;
  cursor: pointer;
}
.confirmation-modal .confirmation-modal-box .confirmation-modal-buttons .confirmation-modal-btn.primary {
  color: #3C8FB9;
  margin-inline-end: 12px;
}
.confirmation-modal.show-modal {
  top: 0;
  height: 100%;
}
.overlay-simple {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}
.formio-select-autocomplete-input {
  display: none;
}
#carouselRevision .hide-validation {
  display: none;
}
ul.pagination li:first-child {
  margin-left: 1em;
}
ul.pagination li:last-child div:nth-of-type(2) {
  font-weight: bold;
  margin-left: 0.5em;
  margin-right: 1em;
}
:root[dir="rtl"] {
  --main-font-family: "Noto Sans Arabic", sans-serif;
}
html[dir="rtl"] {
  --corner-top-end-radius: 6px 0 0 0;
  --main-font-family: "Noto Sans Arabic", sans-serif;
  /* Modern Theme */
}
html[dir="rtl"] body.modern-theme {
  /*.daterangepicker.visible-calendars {
                    .calendar {
                        .calendar-table {
                            table {
                                th {
                                    &:is(.prev, .next) {
                                        i {
                                            transform: rotate(180deg);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }*/
}
html[dir="rtl"] body.modern-theme .radio label,
html[dir="rtl"] body.modern-theme .checkbox label {
  padding-left: initial;
  padding-inline-start: 20px;
}
html[dir="rtl"] body.modern-theme :where(.radio, .radio-inline, .checkbox, .checkbox-inline) :where(input[type="radio"], input[type="checkbox"]) {
  margin-left: initial;
  margin-inline-start: -20px;
}
html[dir="rtl"] body.modern-theme #my-account-page .formio-form :is(.wizard-page .formio-component, .formio-component).force-ltr .input-group,
html[dir="rtl"] body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form :is(.wizard-page .formio-component, .formio-component).force-ltr .input-group {
  flex-direction: row-reverse;
}
html[dir="rtl"] body.modern-theme #my-account-page .formio-form :is(.wizard-page .formio-component, .formio-component).force-ltr .input-group .input-group-prepend + .form-control,
html[dir="rtl"] body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form :is(.wizard-page .formio-component, .formio-component).force-ltr .input-group .input-group-prepend + .form-control {
  border-inline-end: none !important;
  border-inline-start: solid 1px var(--fields-border-color, var(--color-gray-300)) !important;
  text-align: end;
}
html[dir="rtl"] body.modern-theme #my-account-page .formio-form :is(.wizard-page .formio-component, .formio-component):not(.force-ltr).formio-component-button:is(.field-plus-button) button.btn,
html[dir="rtl"] body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form :is(.wizard-page .formio-component, .formio-component):not(.force-ltr).formio-component-button:is(.field-plus-button) button.btn {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
html[dir="rtl"] body.modern-theme #my-account-page .formio-form :is(.wizard-page .formio-component, .formio-component):not(.force-ltr).formio-component-select.field-plus-button .formio-choices .selection.dropdown,
html[dir="rtl"] body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form :is(.wizard-page .formio-component, .formio-component):not(.force-ltr).formio-component-select.field-plus-button .formio-choices .selection.dropdown {
  border-radius: 0 6px 6px 0 !important;
  flex-direction: row;
}
html[dir="rtl"] body.modern-theme #my-account-page .formio-form :is(.wizard-page .formio-component, .formio-component):not(.force-ltr).formio-component-textarea.field-plus-button textarea.form-control,
html[dir="rtl"] body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form :is(.wizard-page .formio-component, .formio-component):not(.force-ltr).formio-component-textarea.field-plus-button textarea.form-control {
  border-top-right-radius: 6px !important;
  border-top-left-radius: 0 !important;
}
html[dir="rtl"] body.modern-theme #my-account-page .formio-form :is(.wizard-page .formio-component, .formio-component):not(.force-ltr) :is(.fa-circle-arrow-right, .fa-circle-arrow-left),
html[dir="rtl"] body.modern-theme.formio-new #base #content .section-body .contain-lg #formio-form-wizard .formio-form :is(.wizard-page .formio-component, .formio-component):not(.force-ltr) :is(.fa-circle-arrow-right, .fa-circle-arrow-left) {
  transform: rotateY(180deg);
}
html[dir="rtl"] body.modern-theme .payment-fee .formio-component-htmlelement:is([class*="price_"], [class*="total"]) p[ref="html"] {
  direction: ltr;
  display: flex;
  justify-content: flex-start !important;
}
html[dir="rtl"] body.modern-theme .regs-docs-fees-cart :where(.no-cost-msg, .fees) {
  direction: ltr;
}
html[dir="rtl"] body.modern-theme .regs-docs-fees-cart.open :where(.no-cost-msg, .fees) {
  padding-inline-start: 50px !important;
  padding-inline-end: 10px !important;
}
html[dir="rtl"] body.modern-theme .cart-entry .currency {
  direction: ltr;
  order: 2;
}
html[dir="rtl"] body.modern-theme .cart-entry .currency + .reg-price {
  order: 1;
}
html[dir="rtl"] body.modern-theme .reg-price {
  order: 3;
}
html[dir="rtl"] body.modern-theme .drawer-section-header:has(.currency) .currency {
  order: 3;
  direction: ltr;
  margin-inline-end: 6px;
}
html[dir="rtl"] body.modern-theme .drawer-section-header:has(.currency) .currency:nth-last-of-type(2) {
  margin-inline-start: 16px;
}
html[dir="rtl"] body.modern-theme .drawer-section-header:has(.currency) .currency:nth-last-of-type(2) + span {
  margin-inline-end: 6px;
  direction: ltr;
}
html[dir="rtl"] body.modern-theme .drawer-section-header:has(.currency) span:not(.currency):nth-last-of-type(2) {
  order: 4;
  direction: ltr;
  margin-inline-start: 16px;
}
html[dir="rtl"] body.modern-theme .drawer-section-header:has(.currency) i {
  order: 5;
}
html[dir="rtl"] body.modern-theme .formio-component-tabs > .card.card-vertical > .card-header a.nav-link.active:last-child {
  border-radius: 6px 0 0 6px !important;
  box-shadow: inset -3px 0 var(--wizard-page-link-active-bg-color, #222);
}
html[dir="rtl"] body.modern-theme #reportrange {
  float: left;
}
html[dir="rtl"] body.modern-theme button.button.flying-button {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
html[dir="rtl"] body.modern-theme button.button.flying-button.draft-save {
  border-radius: 0 6px 6px 0 !important;
}
html[dir="rtl"] body.modern-theme button.button.flying-button.draft-save:hover {
  box-shadow: 0px -6px 20px rgba(70, 81, 170, 0.4) !important;
}
html[dir="rtl"] body.modern-theme :where(.modern-theme-dashboard-menu, .current-service-container) .current-service-text .fa-chevron-right {
  transform: rotateY(180deg);
}
html[dir="rtl"] body.modern-theme .modern-theme-be-list .contain-lg .dropdown.ml-auto .dropdown-menu {
  border-radius: 0 6px 6px 6px;
}
html[dir="rtl"] body.modern-theme .management_bar .contain-lg > ul.nav-pills li.nav-item a.nav-link {
  display: flex;
  align-items: center;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg .dashboard #tab-applications #applications-list > table thead tr th:first-child span:not(.sortorder) {
  border-radius: 0 6px 6px 0;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg .dashboard #tab-applications #applications-list > table thead tr th.opt-col::before {
  border-radius: 6px 0 0 6px;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg .dashboard #tab-applications #applications-list > table tbody td.app-status::before {
  border-radius: 2px 0 0 2px;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg .dashboard #tab-applications #applications-list .table-paging-container ul.pagination li:first-of-type a::before,
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg .dashboard #tab-applications #applications-list .table-paging-container ul.pagination li:last-of-type a::before {
  transform: rotateZ(180deg);
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg .dashboard #tab-documents #documents-others .alert::after {
  inset-inline-start: initial !important;
  inset-inline-end: 0;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg .dashboard #tab-documents #documents-others .table-paging-container .docs-pagination ul.pagination li:first-of-type a::before,
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg .dashboard #tab-documents #documents-others .table-paging-container .docs-pagination ul.pagination li:last-of-type a::before {
  transform: rotateZ(180deg);
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg .dashboard #tab-messages table.table tbody tr td.msg-status::before {
  border-radius: 2px 0 0 2px;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg .dashboard #tab-messages .table-paging-container .msgs-pagination ul.pagination li:first-of-type a::before,
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg .dashboard #tab-messages .table-paging-container .msgs-pagination ul.pagination li:last-of-type a::before {
  transform: rotateZ(180deg);
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg .be-list-page a.back-link i.back-link-icon {
  transform: rotateZ(180deg);
  position: relative;
  top: -1px;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg .be-list-page .list-container table.business-list-table tbody tr td.row-toggler {
  border-radius: 0 6px 6px 0;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg .be-list-page .list-container table.business-list-table tbody tr.active td:last-child {
  border-radius: 6px 0 0 0;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg .manage-be-page a.back-link i.back-link-icon {
  transform: rotateZ(180deg);
  position: relative;
  top: -1px;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg .manage-be-page table.users-list-table tfoot tr th .input-group input.form-control {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg .manage-be-page table.users-list-table tfoot tr th .input-group button.btn {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-wizard .formio-form .formio-component:not(.force-ltr)-select .choices__list,
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-role .formio-form .formio-component:not(.force-ltr)-select .choices__list {
  direction: rtl;
  padding: 0;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-wizard .formio-form .formio-component:not(.force-ltr)-radio:not(.radio-switch) .form-radio .form-check .form-check-label:is(.label-position-right, .label-position-) span,
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-role .formio-form .formio-component:not(.force-ltr)-radio:not(.radio-switch) .form-radio .form-check .form-check-label:is(.label-position-right, .label-position-) span {
  width: 100%;
  display: block;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-wizard .formio-form .formio-component:not(.force-ltr)-radio.radio-switch .form-radio.radio .radio:first-child label.form-check-label span,
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-role .formio-form .formio-component:not(.force-ltr)-radio.radio-switch .form-radio.radio .radio:first-child label.form-check-label span {
  border-radius: 0 6px 6px 0 !important;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-wizard .formio-form .formio-component:not(.force-ltr)-radio.radio-switch .form-radio.radio .radio:last-child label.form-check-label span,
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-role .formio-form .formio-component:not(.force-ltr)-radio.radio-switch .form-radio.radio .radio:last-child label.form-check-label span {
  border-radius: 6px 0 0 6px !important;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-wizard .formio-form .formio-component:not(.force-ltr)-editgrid > button.btn,
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-role .formio-form .formio-component:not(.force-ltr)-editgrid > button.btn {
  display: flex !important;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-wizard .formio-form .formio-component:not(.force-ltr)-datagrid > table.table.datagrid-table thead tr th:first-child,
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-role .formio-form .formio-component:not(.force-ltr)-datagrid > table.table.datagrid-table thead tr th:first-child {
  border-radius: 0 6px 6px 0 !important;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-wizard .formio-form .formio-component:not(.force-ltr)-datagrid > table.table.datagrid-table thead tr th:last-child,
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-role .formio-form .formio-component:not(.force-ltr)-datagrid > table.table.datagrid-table thead tr th:last-child {
  border-radius: 6px 0 0 6px !important;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-wizard .formio-form .formio-component:not(.force-ltr)-htmlelement.alert.alert-danger,
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-role .formio-form .formio-component:not(.force-ltr)-htmlelement.alert.alert-danger {
  border-radius: 6px 0 0 6px !important;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-wizard .formio-form .formio-component:not(.force-ltr)-panel > .card > .card-header .card-title i.fa-question-circle,
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-role .formio-form .formio-component:not(.force-ltr)-panel > .card > .card-header .card-title i.fa-question-circle {
  position: relative;
  display: flex;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-wizard .formio-form .formio-component:not(.force-ltr)-panel:is(.formio-component-FeesPanel, .formio-component-requiredDocumentsPanel) .card .card-body [class*="formio-component-priceRow"]:not(.formio-hidden),
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-role .formio-form .formio-component:not(.force-ltr)-panel:is(.formio-component-FeesPanel, .formio-component-requiredDocumentsPanel) .card .card-body [class*="formio-component-priceRow"]:not(.formio-hidden) {
  display: flex;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-wizard .formio-form .formio-component:not(.force-ltr)-panel.notice > .card,
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-role .formio-form .formio-component:not(.force-ltr)-panel.notice > .card {
  border-radius: 6px 0 0 6px !important;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-wizard .formio-form .formio-component:not(.force-ltr)-panel.notice > .card > .card-header,
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-role .formio-form .formio-component:not(.force-ltr)-panel.notice > .card > .card-header {
  border-radius: 6px 0 0 0;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-wizard .formio-form .formio-component:not(.force-ltr)-panel.notice > .card > .card-body,
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-role .formio-form .formio-component:not(.force-ltr)-panel.notice > .card > .card-body {
  border-radius: 0 0 0 6px;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-wizard .formio-form .formio-component:not(.force-ltr)-panel.notice > .card > .card-body:only-child,
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-role .formio-form .formio-component:not(.force-ltr)-panel.notice > .card > .card-body:only-child {
  border-radius: 6px 0 0 6px;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-wizard .formio-form .formio-component:not(.force-ltr)-columns.payment-fee,
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-role .formio-form .formio-component:not(.force-ltr)-columns.payment-fee {
  display: flex !important;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-wizard .formio-form .formio-component:not(.force-ltr).formio-component-textfield .input-group.formio-multiple-mask-container .formio-multiple-mask-select,
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-role .formio-form .formio-component:not(.force-ltr).formio-component-textfield .input-group.formio-multiple-mask-container .formio-multiple-mask-select {
  border-radius: 0 6px 6px 0 !important;
  background-position: left 12px center;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-wizard .formio-form .formio-component:not(.force-ltr).formio-component-textfield .input-group.formio-multiple-mask-container .formio-multiple-mask-select + .form-control.formio-multiple-mask-input,
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-role .formio-form .formio-component:not(.force-ltr).formio-component-textfield .input-group.formio-multiple-mask-container .formio-multiple-mask-select + .form-control.formio-multiple-mask-input {
  border-radius: 6px 0 0 6px !important;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-wizard .formio-form .formio-component:not(.force-ltr):is(.notice, .info-notice, .success-notice, .warning-notice, .error-notice),
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-role .formio-form .formio-component:not(.force-ltr):is(.notice, .info-notice, .success-notice, .warning-notice, .error-notice) {
  --notice-corner-radius: 6px 0 0 6px;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-wizard .formio-form .formio-component:not(.force-ltr):is(.notice, .info-notice, .success-notice, .warning-notice, .error-notice).fill-background,
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-role .formio-form .formio-component:not(.force-ltr):is(.notice, .info-notice, .success-notice, .warning-notice, .error-notice).fill-background {
  --notice-corner-radius: 6px 0 0 6px;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-wizard .formio-form .formio-component:not(.force-ltr):is(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address).field-plus-button input.form-control:is([type="text"], [type="phoneNumber"], [type="email"]),
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-role .formio-form .formio-component:not(.force-ltr):is(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address).field-plus-button input.form-control:is([type="text"], [type="phoneNumber"], [type="email"]) {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-wizard .formio-form .formio-component:not(.force-ltr).formio-component-number .form-control,
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-role .formio-form .formio-component:not(.force-ltr).formio-component-number .form-control {
  text-align: end;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-wizard .formio-form .wizard-page .guide-form > div[class^="col-md-4"]::before,
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-role .formio-form .wizard-page .guide-form > div[class^="col-md-4"]::before {
  content: "\F104";
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-wizard .formio-form .wizard-page .formio-component:not(.force-ltr).formio-component-number [ref="input"],
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-role .formio-form .wizard-page .formio-component:not(.force-ltr).formio-component-number [ref="input"] {
  direction: ltr;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-wizard .formio-form .wizard-page .formio-component:not(.force-ltr).formio-component-number.required.formio-component-label-hidden .input-group-append,
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-role .formio-form .wizard-page .formio-component:not(.force-ltr).formio-component-number.required.formio-component-label-hidden .input-group-append {
  position: initial;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-wizard .formio-form .wizard-page .formio-component:not(.force-ltr).formio-component-number.required.formio-component-label-hidden.has-suffix:not(.has-prefix) .input-group-append,
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-role .formio-form .wizard-page .formio-component:not(.force-ltr).formio-component-number.required.formio-component-label-hidden.has-suffix:not(.has-prefix) .input-group-append {
  position: initial;
  border-radius: 6px 0px 0px 6px !important;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-wizard .formio-form .wizard-page .formio-component:not(.force-ltr).formio-component-number.required.formio-component-label-hidden.has-suffix:not(.has-prefix) .input-group-append::before,
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-role .formio-form .wizard-page .formio-component:not(.force-ltr).formio-component-number.required.formio-component-label-hidden.has-suffix:not(.has-prefix) .input-group-append::before {
  inset-inline-start: 10px;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-wizard .formio-form .wizard-page .formio-component:not(.force-ltr).formio-component-number.required.formio-component-label-hidden.has-prefix:not(.has-suffix) label.field-required.control-label--hidden::after,
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-role .formio-form .wizard-page .formio-component:not(.force-ltr).formio-component-number.required.formio-component-label-hidden.has-prefix:not(.has-suffix) label.field-required.control-label--hidden::after {
  display: none;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-wizard .formio-form .wizard-page .formio-component:not(.force-ltr).formio-component-number.required.formio-component-label-hidden.has-prefix:not(.has-suffix) .input-group,
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-role .formio-form .wizard-page .formio-component:not(.force-ltr).formio-component-number.required.formio-component-label-hidden.has-prefix:not(.has-suffix) .input-group {
  position: relative;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-wizard .formio-form .wizard-page .formio-component:not(.force-ltr).formio-component-number.required.formio-component-label-hidden.has-prefix:not(.has-suffix) .input-group::before,
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-role .formio-form .wizard-page .formio-component:not(.force-ltr).formio-component-number.required.formio-component-label-hidden.has-prefix:not(.has-suffix) .input-group::before {
  content: " ";
  display: inline-block;
  background-color: rgba(255, 0, 59, 0.7);
  vertical-align: unset;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  position: absolute;
  top: calc(50% - 3px);
  inset-inline-end: 10px;
  min-width: 6px;
  z-index: 9;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-wizard .formio-form .wizard-page .formio-component:not(.force-ltr).formio-component-number.required.formio-component-label-hidden.has-prefix.has-suffix .input-group-append::before,
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-role .formio-form .wizard-page .formio-component:not(.force-ltr).formio-component-number.required.formio-component-label-hidden.has-prefix.has-suffix .input-group-append::before {
  display: none;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-wizard .formio-form .wizard-page .formio-component:not(.force-ltr).formio-component-number.required.formio-component-label-hidden.has-prefix.has-suffix .input-group-prepend,
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-role .formio-form .wizard-page .formio-component:not(.force-ltr).formio-component-number.required.formio-component-label-hidden.has-prefix.has-suffix .input-group-prepend {
  position: relative;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-wizard .formio-form .wizard-page .formio-component:not(.force-ltr).formio-component-number.required.formio-component-label-hidden.has-prefix.has-suffix .input-group-prepend::before,
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-role .formio-form .wizard-page .formio-component:not(.force-ltr).formio-component-number.required.formio-component-label-hidden.has-prefix.has-suffix .input-group-prepend::before {
  content: " ";
  display: inline-block;
  background-color: rgba(255, 0, 59, 0.7);
  vertical-align: unset;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  position: absolute;
  top: calc(50% - 3px);
  inset-inline-end: -16px;
  min-width: 6px;
  z-index: 9;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-wizard .formio-form .wizard-page .formio-component:not(.force-ltr).formio-component-datetime .input-group input.form-control.input,
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-role .formio-form .wizard-page .formio-component:not(.force-ltr).formio-component-datetime .input-group input.form-control.input {
  border-radius: 0 6px 6px 0 !important;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-wizard .formio-form .wizard-page .formio-component:not(.force-ltr).formio-component-datetime .input-group .input-group-append,
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-role .formio-form .wizard-page .formio-component:not(.force-ltr).formio-component-datetime .input-group .input-group-append {
  border-radius: 6px 0 0 6px !important;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-wizard .formio-form .wizard-page .formio-component:not(.force-ltr).formio-component-datetime .input-group .input-group-append .input-group-text,
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-role .formio-form .wizard-page .formio-component:not(.force-ltr).formio-component-datetime .input-group .input-group-append .input-group-text {
  border-radius: 6px 0 0 6px;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-wizard .formio-form .wizard-page .formio-component:not(.force-ltr).formio-component-htmlelement.alert.alert-danger,
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-role .formio-form .wizard-page .formio-component:not(.force-ltr).formio-component-htmlelement.alert.alert-danger {
  border-radius: 6px 0 0 6px !important;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-wizard .tabbed-modern-nav > .formio-form > div > nav .pagination li.page-item.active,
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg #formio-form-role .tabbed-modern-nav > .formio-form > div > nav .pagination li.page-item.active {
  inset-inline: auto;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg :is(.dg-action-col, th:last-child) {
  --cell-heading-border-radius: 6px 0 0 6px;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg th:first-child {
  --cell-heading-border-radius: 0 6px 6px 0;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg .tab-pane.active {
  --tab-pane-border-radius: 6px 0 6px 6px;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg .radio-switch :where(.form-check-inline, .form-check):first-child label.form-check-label span {
  --radio-switch-first-border-radius: 0 6px 6px 0;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg .radio-switch :where(.form-check-inline, .form-check):last-child label.form-check-label span {
  --radio-switch-last-border-radius: 6px 0 0 6px;
}
html[dir="rtl"] body.modern-theme #base #content .section-body #entreprise-grid .table-responsive-container table.table thead tr th:first-child {
  border-radius: 0 6px 6px 0;
}
html[dir="rtl"] body.modern-theme #base #content .section-body #entreprise-grid .table-responsive-container table.table thead tr th:last-child {
  border-radius: 6px 0 0 6px;
}
html[dir="rtl"] body.modern-theme #base #content .section-body #entreprise-grid .table-responsive-container table.table thead tr.filters td select.form-control {
  background-position: left 12px center;
}
html[dir="rtl"] body.modern-theme #base #content .section-body #entreprise-grid .table-paging-container ul.pagination li:first-child a::before {
  content: "\F105";
}
html[dir="rtl"] body.modern-theme #base #content .section-body #entreprise-grid .table-paging-container ul.pagination li:last-child a::before {
  content: "\F104";
}
html[dir="rtl"] body.modern-theme #base #content .section-body .new-formio-part-b-history .panel-heading .restart-process-box select.form-control {
  background-position: left 7px center;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .new-formio-part-b .formio-component-tabs .carousel .carousel-inner .carousel-item .documents-validator .tab-description-back-to-list i {
  transform: rotate(180deg);
}
html[dir="rtl"] body.modern-theme #base #content .section-body .new-formio-part-b .formio-component-tabs .carousel .carousel-inner .carousel-item .documents-validator .carousel-control span {
  transform: rotate(180deg);
}
html[dir="rtl"] body.modern-theme #base #content .section-body .new-formio-part-b .formio-component-tabs .validator-radio label[for*="-no"] {
  border-radius: 6px 0 0 6px;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .new-formio-part-b .formio-component-tabs .validator-radio label[for*="-yes"] {
  border-radius: 0 6px 6px 0;
}
@media (min-width: 992px) {
  html[dir="rtl"] body.modern-theme :where(.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12):not(td) {
    float: right;
  }
}
html[dir="rtl"] body.modern-theme #rolereview .formio-component-tabs .card-header + .card-body.tab-pane.active {
  border-radius: 6px 0 6px 6px !important;
}
html[dir="rtl"] body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns :is(.choices__list--single, .choices__item) {
  padding-inline: 0;
}
html[dir="rtl"] body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns .input-value:only-child {
  display: flex;
}
html[dir="rtl"] body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns p:has(.input-prefix) {
  display: flex;
}
html[dir="rtl"] body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns.type-number > div.col-md-2:first-of-type + div .formio-component-htmlelement {
  direction: ltr;
  text-align: end;
}
html[dir="rtl"] body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns.type-number .input-value:only-child {
  justify-content: end;
}
html[dir="rtl"] body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns.type-number p:has(.input-prefix) {
  justify-content: end;
}
html[dir="rtl"] body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns.type-editgrid.type-number {
  direction: ltr;
}
html[dir="rtl"] body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns.type-editgrid.type-number p[ref="html"] {
  justify-content: end;
}
html[dir="rtl"] body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns.type-htmlelement .type-number:is([class*="price_"], [class*="total"]) p {
  direction: ltr;
  justify-content: flex-end;
}
html[dir="rtl"] body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns .formio-component-htmlelement.type-number {
  direction: ltr;
}
html[dir="rtl"] body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns .formio-component-htmlelement.type-number p[ref="html"] {
  justify-content: end !important;
  display: flex;
}
html[dir="rtl"] body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns .formio-component-htmlelement.type-number:is([class*="formio-component-price_"], [class*="formio-component-totalCost"], [class*="formio-component-totalPrice"]) {
  direction: rtl;
}
html[dir="rtl"] body.modern-theme .row.formio-component.formio-component-columns.readonly-form-columns .formio-component-htmlelement.type-number:is([class*="formio-component-price_"], [class*="formio-component-totalCost"], [class*="formio-component-totalPrice"]) p[ref="html"] {
  justify-content: start;
}
html[dir="rtl"] body.modern-theme .popover {
  text-align: start;
}
html[dir="rtl"] body.modern-theme .popover.top > .arrow {
  margin-left: initial;
  left: initial;
  inset-inline-start: calc(50% - 11px);
}
html[dir="rtl"] body.modern-theme .popover.top > .arrow::after {
  inset-inline-start: calc(50% - 10px);
}
html[dir="rtl"] body.modern-theme .search-field {
  background-position: center left 10px;
}
@media (min-width: 730px) {
  html[dir="rtl"] body.modern-theme .daterangepicker .ranges {
    float: right;
  }
}
html[dir="rtl"] body.modern-theme .daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left: none;
}
html[dir="rtl"] body.modern-theme .daterangepicker.show-ranges.ltr .drp-calendar.right {
  border-right: 1px solid #ddd;
}
html[dir="rtl"] body.modern-theme .vnotify-container.vn-bottom-right {
  left: 16px;
  right: initial;
}
html[dir="rtl"] body.modern-theme .vnotify-container.vn-bottom-right .vnotify-text {
  border-radius: calc(6px - 1px) 0 0 calc(6px - 1px);
}
html[dir="rtl"] body.modern-theme .vnotify-container.vn-bottom-right .vnotify-item .vn-close {
  left: 4px;
  right: auto;
}
html[dir="rtl"] body.modern-theme #base #content .section-body .contain-lg :is(#formio-form-wizard,#form-type-role,#formio-form-role):not(.new-formio-part-b) .formio-form .formio-component :where(label,label.col-form-label) i.fa-question-circle {
  margin-inline-end: -16px;
  transform: scaleX(-1) translateX(20px);
}
html[dir="rtl"] body.modern-theme select:is(.form-control, .flatpickr-monthDropdown-months, .flatpickr-monthDropdown-months:hover) {
  background-position: left 12px center;
}
html[dir="rtl"] body.modern-theme .files-header {
  transform: scaleX(-1);
}
html[dir="rtl"] body.modern-theme .files-header > * {
  transform: scaleX(-1);
}
html[dir="rtl"] body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address):not(.value-label-style).has-suffix:not(.has-prefix) .input-group > input.form-control {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-inline-start: none !important;
  border-inline-end: solid 1px var(--fields-border-color, var(--color-gray-300)) !important;
}
html[dir="rtl"] body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address):not(.value-label-style).has-suffix:not(.has-prefix) .input-group > input.form-control:focus {
  border-color: var(--color-gray-400) !important;
}
html[dir="rtl"] body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address):not(.value-label-style).has-prefix:not(.has-suffix) .input-group > input.form-control {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
  border-inline-start: none;
  border-inline-end: solid 1px var(--fields-border-color, var(--color-gray-300)) !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
html[dir="rtl"] body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address):not(.value-label-style).has-prefix:not(.has-suffix) .input-group > input.form-control:focus {
  border-color: var(--color-gray-400) !important;
}
html[dir="rtl"] body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address):not(.value-label-style).has-prefix:not(.has-suffix) .input-group-prepend {
  border-radius: 0px 6px 6px 0px !important;
}
html[dir="rtl"] body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address):not(.value-label-style).has-prefix:not(.has-suffix).formio-component-number .input-group > input.form-control {
  border-inline-start: solid 1px var(--fields-border-color, var(--color-gray-300)) !important;
  border-inline-end: none !important;
}
html[dir="rtl"] body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address):not(.value-label-style).has-prefix .input-group > .input-group-prepend {
  border-radius: 0px 6px 6px 0px !important;
}
html[dir="rtl"] body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address):not(.value-label-style).has-suffix.formio-component-label-hidden.formio-component-number.required .input-group-append::before {
  inset-inline-start: initial !important;
  transform: translateX(16px);
}
html[dir="rtl"] body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address):not(.value-label-style).has-suffix .input-group > .input-group-append {
  border-radius: 6px 0 0 6px !important;
}
html[dir="rtl"] body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address):not(.value-label-style).has-suffix.has-prefix .input-group > input.form-control {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-inline-start: none !important;
  border-inline-end: none !important;
}
html[dir="rtl"] body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address):not(.value-label-style).has-suffix.has-prefix.formio-component-label-hidden.formio-component-number.required .input-group-prepend::before {
  display: none !important;
}
html[dir="rtl"] body.modern-theme :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page):not(.new-formio-part-b) .formio-form .formio-component:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address):not(.value-label-style).has-suffix.has-prefix.formio-component-label-hidden.formio-component-number.required .input-group-append::before {
  content: " " !important;
  display: inline-block !important;
  background-color: rgba(255, 0, 59, 0.7);
  vertical-align: unset;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  position: absolute;
  top: calc(50% - 3px);
  inset-inline-end: initial;
  transform: translateX(16px);
  min-width: 6px;
  z-index: 9;
}
html[dir="rtl"] body.modern-theme .formio-component-tabs > .card.card-vertical > .card-header.collapsed .tab-expand-collapse-button {
  --fontawesome-icon-content: "\f053";
}
html[dir="rtl"] body.modern-theme .formio-component-tabs > .card.card-vertical > .card-header .tab-expand-collapse-button {
  --fontawesome-icon-content: "\f054";
}
/* Common */
body.modern-theme {
  font-family: 'Inter', sans-serif;
}
